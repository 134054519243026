import { createTheme, darkScrollbar, ThemeOptions } from '@mui/material';
import { IThemeAction, IThemeState } from './theme.interfaces';
import { themeActionType } from './themeActionTypes';

export type ThemeNames = 'device' | 'light' | 'dark';
export type ThemesTypes = 'light';

interface BrandColors {
  base?: string;
  0?: string;
  50?: string;
  100?: string;
  200?: string;
  300?: string;
  400?: string;
  500?: string;
  600?: string;
  700?: string;
  800?: string;
  850?: string;
  900?: string;
  1000?: string;
  light1?: string;
  light2?: string;
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    themeName?: ThemeNames;
    border?: Palette['primary'];
    shadow?: Palette['primary'];
    textLight?: Palette['text'];
    others?: { [key: string]: string };
    brand: {
      primary: BrandColors;
      secondary: BrandColors;
      background: BrandColors;
      neutral: BrandColors;
      black: BrandColors;
      gray: BrandColors;
      success: BrandColors;
      attention: BrandColors;
      error: BrandColors;
      info: BrandColors;
    };
  }
  interface PaletteOptions {
    themeName?: ThemeNames;
    border?: PaletteOptions['primary'];
    shadow?: PaletteOptions['primary'];
    textLight?: PaletteOptions['text'];
    others?: { [key: string]: string };
    brand: {
      primary: BrandColors;
      secondary: BrandColors;
      background: BrandColors;
      neutral: BrandColors;
      black: BrandColors;
      gray: BrandColors;
      success: BrandColors;
      attention: BrandColors;
      error: BrandColors;
      info: BrandColors;
    };
  }
}

interface TFontWeight {
  [key: string]: number;
}

interface TFontSize {
  [key: string]: number;
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobileS: true;
    mobileM: true;
    mobileL: true;
    tablet: true;
    laptop: true;
    laptopL: true;
    desktop: true;
  }

  interface TypographyVariants {
    fontWeight?: TFontWeight;
    customfontSize?: TFontSize;
  }

  interface TypographyVariantsOptions {
    fontWeight?: TFontWeight;
    customfontSize?: TFontSize;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    fontWeight: true;
    customfontSize: true;
  }
}

export const themes: { [key in ThemesTypes]: ThemeOptions } = {
  light: createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            //   scrollbarColor: '#7664FA #EDEDED',
            //   '&::-webkit-scrollbar': {
            //     width: '7px',
            //   },
            //   /* Track */
            //   '&::-webkit-scrollbar-track': {
            //     background: '#EDEDED',
            //   },

            //   /* Handle */
            //   '&::-webkit-scrollbar-thumb': {
            //     background: '#7664FA',
            //   },

            //   /* Handle on hover */
            //   '&::-webkit-scrollbar-thumb:hover': {
            //     background: '#7664FA',
            //   },
            // },
            ...darkScrollbar(),
          },
        },
      },
    },
    typography: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: {
        XB: 800,
        B: 700,
        Sb: 600,
        Md: 500,
        Rg: 400,
      },
      customfontSize: {
        M: 18,
        G: 20,
      },
    },
    breakpoints: {
      values: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 1024,
        laptopL: 1440,
        desktop: 2560,
      },
    },
    palette: {
      themeName: 'light',
      others: {
        red: '#FF8A8A',
      },
      brand: {
        primary: {
          base: 'var(--primary)',
        },
        secondary: {
          base: '#161616',
        },
        background: {
          base: '#F5F5F5',
        },
        neutral: {
          base: '#fff',
        },
        gray: {
          50: '#FAFAFA',
          100: '#F5F5F5',
          200: '#EEEEEE',
          300: '#E0E0E0',
          400: '#BDBDBD',
          500: '#9E9E9E',
          600: '#757575',
          700: '#616161',
          800: '#424242',
          850: '#2C2C2C',
          900: '#202020',
        },
        black: {
          1000: '#000',
        },
        success: {
          100: '#EAF8EC',
          200: '#246B30',
          300: '#B8F1C1',
          500: '#00CC83',
          700: '#246B40',
          900: '#187B28',
        },
        attention: {
          100: '#FFFAEE',
          200: '#FDD9B8',
          300: '#FDEAB8',
          500: '#F96F34',
          700: '#8D4908',
          900: '#F97800',
        },
        error: {
          100: '#FEF4F4',
          300: '#FFC5C5',
          500: '#EC4A5E',
          700: '#9F0B26',
          900: '#F50404',
          1000: '#9F0B0B',
        },
        info: {
          100: '#2C619E',
          300: '#2179E1',
          500: '#C1D9F7',
          700: '#C1D9F7',
        },
      },
    },
  }),
};

export const themeInitialState: IThemeState = {
  theme: themes,
  logo: null,
};

export const themeReducer = (
  state: IThemeState = themeInitialState,
  action: IThemeAction,
) => {
  switch (action.type) {
    case themeActionType.SET_THEME:
      return {
        ...state,
        theme: {
          ...state.theme,
          light: {
            ...state.theme.light,
            palette: {
              ...state.theme.light.palette,
              brand: {
                ...state?.theme?.light?.palette?.brand,
                primary: {
                  base: action.payload.primary,
                },
                secondary: {
                  base: action.payload.secondary,
                },
                background: {
                  base: action.payload.background,
                },
              },
            },
          },
        },
      };
    case themeActionType.SET_LOGO:
      return {
        ...state,
        logo: action.payload,
      };
    default:
      return state;
  }
};
