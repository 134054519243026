import styled from '@emotion/styled';

export const FundingsSelector = styled.div`
  width: fit-content;
`;

export const HeadingArea = styled.div`
  background-color: ${({ theme }) => theme.palette?.brand.background.base};
  min-height: 100px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px 28px;
  display: flex;
  align-items: center;
`;

export const CessionsDetailsPageWrapper = styled.div`
  & > section {
    padding: 0px;
    border: 1px solid #DBD6FE;
    padding-bottom: 40px;
  }
`;

export const TitleArea = styled.div`
  display: flex;
  margin-top: 42px;
  align-items: center;
`;

export const CessionDetailsWrapper = styled.div`
  padding: 24px 48px;
`;

export const CessionDetailsTableArea = styled.div`
  margin-top: 32px;
  position: relative;

  .MuiTableContainer-root {
    overflow-x: visible !important;
  }

  th:nth-of-type(1) {
    max-width: 100px;
  }

  td:nth-of-type(1) {
    max-width: 100px;
  }
`;

export const CessionDetailsDocuments = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 32px;
  gap: 24px;
`;

export const CessionDetailsDocument = styled.div<{ isDisabled?: boolean }>`
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
  max-width: 400px;
  border-radius: 20px;
  width: 100%;
  border: 1px solid #E6E5EA;
  cursor: pointer;
  
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};

  &:hover {
    box-shadow: ${({ isDisabled }) =>
      isDisabled ? 'none' : '0px 4px 10px 0px rgba(15, 33, 43, 0.05)'};
  }
`;
