import styled from '@emotion/styled';

export const ChargesSimulatorSection = styled.div`
  padding: 24px 0px;
`;

export const ChargesSimulatorSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
  padding: 16px 0px;
  border: 1px solid #EDEDED;
  border-radius: 10px;
  margin-top: 16px;
`;

export const TableWrapper = styled.div`
  margin-top: 16px;
  
  th:nth-of-type(10) {
    border-left: 1px solid #E6E5EA;
    max-width: 90px;
  }
  td:nth-of-type(10) {
    border-left: 1px solid #E6E5EA;
    max-width: 90px;
  }
`;
