import React from 'react';

const AttentionRequiredDocuments = ({ ...props }) => (
  <svg
    width={22}
    height={20}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    { ...props }
  >
    <path
      d="M9.53279 1.38649C10.1849 0.204667 11.8151 0.204668 12.4672 1.38649L21.2705 17.341C21.9226 18.5229 21.1075 20.0001 19.8033 20.0001H2.19672C0.892529 20.0001 0.0774117 18.5229 0.729506 17.341L9.53279 1.38649Z"
      fill="#F97800"
    />
    <path
      d="M9.7539 7.5625C9.71792 6.9868 10.1751 6.50012 10.752 6.50012H11.248C11.8249 6.50012 12.2821 6.9868 12.2461 7.5625L11.9296 12.6268C11.8989 13.1177 11.4918 13.5001 11 13.5001C10.5082 13.5001 10.1011 13.1177 10.0704 12.6268L9.7539 7.5625Z"
      fill="white"
    />
    <circle cx="11" cy="15.6876" r="1.3125" fill="white" />
  </svg>
);

export default AttentionRequiredDocuments;
