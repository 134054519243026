import React, { createContext, useContext, useReducer } from 'react';
import { IQualificationsContext } from './qualifications.interfaces';
import {
  qualificationsInitialState,
  qualificationsReducer,
} from './qualificationsReducer';

export const QualificationsContext = createContext<
  IQualificationsContext | undefined
>(undefined);

export interface IQualificationsProviderProps {
  children?: React.ReactNode;
}

export const QualificationsProvider = ({
  children,
}: IQualificationsProviderProps) => {
  const [state, dispatch] = useReducer(
    qualificationsReducer,
    qualificationsInitialState,
  );

  const value = { state, dispatch };
  return (
    <QualificationsContext.Provider value={value}>
      {children}
    </QualificationsContext.Provider>
  );
};

export const useQualificationsContext = () => {
  const context = useContext(QualificationsContext);

  if (context === undefined) {
    throw new Error(
      'useQualificationsContext must be used within a QualificationsProvider',
    );
  }
  return context;
};
