const PencilIcon = ({ color }: { color: string }) => (
  <svg
    width={18}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m12.728 7.186-1.414-1.414L2 15.086V16.5h1.414l9.314-9.314Zm1.414-1.414 1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414ZM4.242 18.5H0v-4.243L13.435.822a1 1 0 0 1 1.414 0l2.829 2.83a1 1 0 0 1 0 1.413L4.243 18.5h-.001Z"
      fill={color}
    />
  </svg>
)

export default PencilIcon
