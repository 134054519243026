import React, { useEffect, useState } from 'react';
import {
  CancelButton,
  CloseButton,
  ConfirmButton,
  DocumentTypeFileName,
  DocumentTypeSelectBox,
  DocumentsModalContainer,
  DocumentsModalContent,
  DocumentsModalFooter,
  DocumentsModalHeader,
} from './styles';
import {
  BaseModal,
  CustomSelect,
  Heading4,
  TCustomValue,
  TextM,
} from 'celcoin-design-system';
import {
  CloseIcon,
  CustomCircularIndicator,
  DocIcon,
  PdfIcon,
} from 'app/components';
import { DOCUMENT_TYPE } from 'app/helpers/documentTypes';
import { CSVIcon, DocumentIcon, JPEGIcon } from 'app/components/Icons';
import { useTheme } from '@mui/material';

export const iconByFileType: { [type: string]: React.ReactNode } = {
  pdf: <PdfIcon />,
  doc: <DocIcon />,
  xls: <CSVIcon />,
  csv: <CSVIcon />,
  jpg: <JPEGIcon />,
  jpeg: <JPEGIcon />,
};

export type IDocumentsModalProps = {
  name: string;
  isOpen: boolean;
  isLoading?: boolean;
  excludeTypes: DOCUMENT_TYPE[];
  documentTypes?: { [key: string]: string };
  handleConfirm: (_documentType: DOCUMENT_TYPE) => void;
  handleClose: () => void;
  handleCancel?: () => void;
};

const handleFileExtension = (fileName: string) => {
  const nameSplitted = fileName?.split('.');
  return nameSplitted[nameSplitted?.length - 1 || 0];
};

const DocumentsModal = ({
  name,
  isOpen,
  isLoading,
  documentTypes,
  excludeTypes,
  handleConfirm,
  handleClose,
  handleCancel,
}: IDocumentsModalProps) => {
  const theme = useTheme();
  const [documentType, updateDocumentType] = useState<TCustomValue>();

  useEffect(() => {
    updateDocumentType(undefined);
  }, [isOpen]);

  const isInvalid =
    documentType &&
    excludeTypes.includes(documentType.value as DOCUMENT_TYPE) &&
    documentType.value !== DOCUMENT_TYPE.OTHER;
  return (
    <BaseModal open={isOpen} onClose={handleClose}>
      <DocumentsModalContainer>
        <DocumentsModalHeader>
          <CloseButton onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </CloseButton>
        </DocumentsModalHeader>
        <DocumentsModalContent>
          <DocumentIcon color={theme.palette.brand.secondary.base as string} />
          <Heading4
            style={{
              color: theme.palette.brand.secondary.base,
              paddingBottom: '16px',
              paddingTop: '20px',
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
            fontWeight="B"
          >
            Qual o tipo de documento a ser enviado?
          </Heading4>
          <DocumentTypeFileName>
            <div>{iconByFileType[handleFileExtension(name)]}</div>
            <TextM
              style={{
                color: theme.palette.brand.secondary.base,
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
              weight="Bold"
            >
              {name}
            </TextM>
          </DocumentTypeFileName>
          {documentTypes && (
            <DocumentTypeSelectBox>
              <CustomSelect
                name="documentType"
                placeholder="Selecionar o tipo"
                value={documentType}
                handleInputChange={(e) =>
                  updateDocumentType(e.currentTarget.value)
                }
                options={Object.keys(documentTypes).map((type) => {
                  return {
                    label: documentTypes[
                      type as keyof typeof documentTypes
                    ] as string,
                    value: type,
                  };
                })}
                validationError={
                  isInvalid
                    ? 'Esse tipo de documento já está cadastrado'
                    : undefined
                }
              />
            </DocumentTypeSelectBox>
          )}
        </DocumentsModalContent>
        <DocumentsModalFooter isColumnButtons={true} isLoading={!!isLoading}>
          <CancelButton
            disabled={isLoading}
            onClick={handleCancel ?? handleClose}
          >
            Cancelar
          </CancelButton>
          <ConfirmButton
            onClick={() =>
              !isLoading && handleConfirm(documentType?.value as DOCUMENT_TYPE)
            }
            disabled={(!documentType && !isLoading) || isInvalid}
            color="primary"
            style={{
              marginBottom: '10px',
              opacity: isLoading ? 0.7 : 1,
              cursor: isLoading ? 'default' : 'pointer',
            }}
          >
            {!isLoading ? (
              'Enviar documento'
            ) : (
              <>
                <span style={{ paddingRight: '12px' }}>Enviando...</span>
                <CustomCircularIndicator size={20} sx={{ color: '#49FADE' }} />
              </>
            )}
          </ConfirmButton>
        </DocumentsModalFooter>
      </DocumentsModalContainer>
    </BaseModal>
  );
};

export default DocumentsModal;
