export default {
  auth: {
    base: 'auth',
    login: 'auth/login',
    newPassoword: 'auth/new-password',
    passwordRecovery: 'auth/password-recovery',
    passwordRecoveryConfirmation: 'auth/password-recovery-confirmation',
    twoFactor: 'auth/two-factor-password',
    enableTwoFactor: 'auth/enable-two-factor',
  },
  home: {
    base: 'home',
  },
  qualification: {
    new: 'qualification/new',
  },
  products: {
    base: 'products',
    main: 'products/main',
    contracts: 'products/contracts',
    variables: 'products/variables',
    detail: 'products/detail/:id/:tabRoute?',
    applicationRequest: 'products/application',
    applicationDetails: 'products/application/:id/:tabRoute?',
    contractsDetails: 'products/detail/:productId/contract/:id/:tabRoute?',
  },
  records: {
    base: 'records',
    main: 'records/natural',
    natural: 'records/natural',
    naturalNew: '/records/natural/new',
    naturalView: 'records/natural/:id/:tabRoute?',
    naturalScr: 'records/natural/:id/scr/:scrId',
    legal: 'records/legal',
    legalList: 'records/legal/list',
    legalNew: '/records/legal/new',
    legalView: 'records/legal/:id/:tabRoute?/:type?',
    legalScr: 'records/legal/:id/scr/:scrId',
  },
  operations: {
    base: 'operations',
    main: 'operations/main',
    requests: 'operations/requests',
    loans: 'operations/loans',
  },
  originators: {
    base: 'originators',
    main: 'originators/main',
  },
  simulation: {
    base: 'simulation',
    main: 'simulation',
  },
  disbursement: {
    balance: '/disbursement/:id/balance',
    statement: '/disbursement/:id/statement',
  },
  cession: {
    cnab444: '/cession/:id/cnab444',
    cessions: '/cession/:id/cessions',
    cessionsDetails: '/cession/:id/cessions/:cessionId',
  },
  management: {
    access: '/access-management',
    accessNew: '/access-management/new',
    accessView: 'access-management/:id/:tabRoute?',
  },
};
