import { useTheme } from '@mui/material';
import {
  BoxSection,
  ButtonDropdown,
  Heading3,
  Spinner,
  TextM,
} from 'app/components';
import {
  ButtonArea,
  FilterArea,
  HeaderArea,
  MenuDropdownContent,
  NaturalPersonListPageContainer,
  TableArea,
} from './styles';
import { useState, useEffect, useRef, useCallback } from 'react';
import { UploadCsvHandler } from '../';
import { usePersonService } from 'modules/customer/services';
import { useNavigate } from 'react-router-dom';
import { routingPath } from 'app/routes';
import { formatCPF } from 'app/helpers';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { usePermify } from 'modules/auth/context';
import SearchFilter, {
  TFilterItems,
} from 'app/components/SearchFilter/SearchFilter';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { Table, TextL, TextS } from 'celcoin-design-system';
import {
  useQueryParam,
  NumberParam,
  StringParam,
  JsonParam,
  withDefault,
} from 'use-query-params';

const tableHeadContent = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Pessoas</TextL>
      </TableHeadCellContainer>
    ),
  },

  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Data de cadastro</TextL>
      </TableHeadCellContainer>
    ),
  },
];

const filterItems: TFilterItems[] = [
  {
    name: 'Nome',
    parameterName: 'full_name',
  },
  {
    name: 'CPF',
    parameterName: 'taxpayer_id',
  },
  {
    name: 'Email',
    parameterName: 'email_address',
  },
];

const NaturalPersonListPageContent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { getPersonList, personListLoading } = usePersonService();
  const [isLoading, toggleIsLoading] = useState(false);
  const [searchInputValue, setSearchInputValue] = useQueryParam(
    'search',
    withDefault(StringParam, ''),
  );
  const [addDropdownActive, setAddDropdownActive] = useState(false);
  const [modalCSVOpen, setModalCSVOpen] = useState(false);
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );
  const prevSelectedFilter = useRef<string>('');
  const prevFilterValue = useRef<string>('');
  const [selectedFilter, setSelectedFilter] = useQueryParam<TFilterItems>(
    'filter',
    withDefault(JsonParam, filterItems[0]),
  );
  const { isProfileAuthorized } = usePermify();
  const [tableDataContent, updateTableDataContent] = useState<
    {
      id: string;
      name: string;
      register: string;
      createdAt: string;
    }[]
  >([]);

  const handleAdd = () => {
    setAddDropdownActive(true);
  };

  useEffect(() => {
    setAddDropdownActive(() => false);
  }, [modalCSVOpen]);

  const getPersonListWithPage = useCallback(
    async (
      page: number,
      parameterName?: string,
      parameterValue?: string,
      itemsPerPage?: number,
    ) => {
      try {
        const personList = await getPersonList(
          page,
          parameterName,
          parameterValue,
          itemsPerPage,
        );
        if (personList) {
          updateTableDataContent([]);
          setTotalItemsQty(personList.total_elements || 0);
          const personMap = personList.content.map((person) => ({
            id: person.id,
            name: person.full_name,
            register: formatCPF(person.taxpayer_id),
            createdAt: formatDateFromBackend(
              person.created_at || '',
            ).toLocaleDateString('pt-br'),
          }));

          if (personMap.length > 0) {
            if (
              prevSelectedFilter.current === parameterName &&
              prevFilterValue.current === parameterValue
            ) {
              updateTableDataContent((state) =>
                state.concat(
                  personMap.filter((content) => {
                    const inx = state.findIndex(
                      (stateContent) => stateContent.id === content.id,
                    );
                    return inx < 0;
                  }),
                ),
              );
            } else {
              updateTableDataContent(() => personMap);
              prevSelectedFilter.current = parameterName || '';
              prevFilterValue.current = parameterValue || '';
            }
          } else {
            updateTableDataContent(() => []);
            prevSelectedFilter.current = parameterName || '';
            prevFilterValue.current = parameterValue || '';
          }
          toggleIsLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [tableDataContent],
  );

  useEffect(() => {
    getPersonListWithPage(
      currentPage,
      selectedFilter.parameterName,
      searchInputValue,
      linesPerPage,
    );
  }, [linesPerPage, currentPage]);

  const handleSearch = () => {
    setCurrentPage(0);
    setLinesPerPage(10);
    setTimeout(() =>
      getPersonListWithPage(
        0,
        selectedFilter.parameterName,
        searchInputValue,
        10,
      ),
    );
  };

  return (
    <>
      <NaturalPersonListPageContainer>
        <BoxSection>
          <HeaderArea>
            <div style={{ width: '100%' }}>
              <Heading3 style={{ color: theme.palette?.brand.gray[800] }}>
                Cadastros
              </Heading3>
              <FilterArea>
                <SearchFilter
                  inputValue={searchInputValue}
                  handleClearInput={() => {
                    setCurrentPage(0);
                    setLinesPerPage(10);
                    setSearchInputValue(() => '');
                    setTimeout(() =>
                      getPersonListWithPage(0, undefined, undefined, 10),
                    );
                  }}
                  handleInputChange={(evt) =>
                    setSearchInputValue(() => evt.target.value)
                  }
                  filterItems={filterItems}
                  handleSelectFilterItem={(selectedFilterValue) => {
                    setSelectedFilter(() => selectedFilterValue);
                  }}
                  selectedFilter={selectedFilter?.name}
                  handleSearch={handleSearch}
                />
                <ButtonArea>
                  {isProfileAuthorized(
                    EAccountPermissions.CREATE_PERSON_BUSINESS,
                  ) && (
                    <ButtonDropdown
                      isOpen={addDropdownActive}
                      handleClose={() => setAddDropdownActive(false)}
                      handleClick={handleAdd}
                      title="Cadastrar Pessoas"
                    >
                      <MenuDropdownContent>
                        <TextM
                          weight="Bold"
                          style={{ color: theme.palette.brand.secondary.base }}
                          onClick={() =>
                            navigate(routingPath.records.naturalNew)
                          }
                        >
                          Nova Pessoa
                        </TextM>
                      </MenuDropdownContent>
                      <MenuDropdownContent>
                        <TextM
                          weight="Bold"
                          style={{ color: theme.palette.brand.secondary.base }}
                          onClick={() => {
                            setModalCSVOpen(true);
                          }}
                        >
                          Importar CSV
                        </TextM>
                      </MenuDropdownContent>
                    </ButtonDropdown>
                  )}
                </ButtonArea>
              </FilterArea>
            </div>
          </HeaderArea>
          <TableArea>
            {isLoading || personListLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignSelf: 'center',
                  width: '100%',
                }}
              >
                <Spinner />
              </div>
            ) : (
              <Table
                showPagination
                handlePageChange={(selectedPage) => {
                  setCurrentPage(selectedPage - 1);
                }}
                handleLinesPerPage={(selectedLinesPerPage) => {
                  setCurrentPage(0);
                  setLinesPerPage(
                    selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                  );
                }}
                totalElementsQty={totalItemsQty}
                currentPage={currentPage + 1}
                linesPerPage={linesPerPage}
                tableHeadCell={tableHeadContent}
                tableBodyContent={tableDataContent?.map((item) => {
                  return {
                    id: '1',
                    cells: [
                      {
                        id: '1',
                        content: (
                          <TableBodyCellContainer
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              navigate(`/records/natural/${item.id}/details`)
                            }
                          >
                            <TextS weight="Bold">{item.name}</TextS>
                            <TextS>{item.register}</TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '2',
                        content: (
                          <TableBodyCellContainer
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              navigate(`/records/natural/${item.id}/details`)
                            }
                          >
                            <TextS weight="Bold">{item.createdAt}</TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                    ],
                  };
                })}
              />
            )}
          </TableArea>
        </BoxSection>
      </NaturalPersonListPageContainer>
      <UploadCsvHandler
        handleClose={() => setModalCSVOpen(false)}
        isOpen={modalCSVOpen}
      />
    </>
  );
};

export default NaturalPersonListPageContent;
