import { CustomInput } from 'celcoin-design-system';
import { useFormik } from 'formik';
import { useState } from 'react';
import { formatCPF, formatPhone } from 'app/helpers';
import { StepContentInput, StepContentInputArea } from './styles';
import { UserAccess } from 'modules/management/interfaces/usersAccess';
import { ConfirmModal, EditSideSheet } from 'app/components';
import { infoStepSchema } from 'modules/customer/helpers/usersAccessValidation';
import AttentionCircularIcon from 'app/components/Icons/AttentionCircularIcon';

export interface IUsersAccessDetailsEditSideSheet {
  isOpen: boolean;
  userAccess: UserAccess;
  handleClose: () => void;
  updateUserAccess: (newUserAccess: UserAccess) => Promise<void>;
}

const UsersAccessDetailsEditSideSheet = ({
  isOpen,
  userAccess,
  handleClose,
  updateUserAccess,
}: IUsersAccessDetailsEditSideSheet) => {
  const [isLoading, toggleLoading] = useState(false);
  const [isConfirmSavingOpen, toggleConfirmSavingOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      full_name: userAccess.full_name || '',
      taxpayer_id: userAccess.taxpayer_id || '',
      phone: formatPhone(userAccess.phone || ''),
      email: userAccess.email || '',
    },
    enableReinitialize: true,
    validationSchema: infoStepSchema,
    onSubmit: (values) => {
      toggleLoading(true);
      const userAccessData: UserAccess = {
        ...userAccess,
        full_name: values.full_name,
        taxpayer_id: values.taxpayer_id.replace(/\D+/g, ''),
        phone: values.phone?.replace(/\D+/g, ''),
        email: values.email.trim(),
      };
      updateUserAccess(userAccessData)
        .then(() => {
          onClose();
        })
        .finally(() => toggleLoading(false));
    },
  });

  const onClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <EditSideSheet
      open={isOpen}
      handleClose={onClose}
      handleSave={() =>
        formik.validateForm().then((errors) => {
          if (Object.keys(errors).length === 0)
            return toggleConfirmSavingOpen(true);
          formik.setTouched(
            {
              email: true,
              full_name: true,
              phone: true,
              taxpayer_id: true,
            },
            true,
          );
        })
      }
      isLoading={isLoading}
      title="Editar dados"
    >
      <StepContentInputArea>
        <StepContentInput isFull={true}>
          <CustomInput
            name="full_name"
            value={formik.values.full_name}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Nome completo"
            labelValue="Nome"
            validationError={
              formik.touched.full_name ? formik.errors.full_name : ''
            }
          />
        </StepContentInput>
        <StepContentInput isFull={true}>
          <CustomInput
            name="email"
            value={formik.values.email}
            handleInputChange={formik.handleChange}
            placeholder="exemplo@email.com"
            labelValue="E-mail"
            validationError={formik.touched.email ? formik.errors.email : ''}
          />
        </StepContentInput>
        <StepContentInput isFull={true}>
          <CustomInput
            name="phone"
            value={formatPhone(formik.values.phone)}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="(00) 00000-0000"
            labelValue="Telefone"
            validationError={formik.touched.phone ? formik.errors.phone : ''}
          />
        </StepContentInput>
        <StepContentInput isFull={true}>
          <CustomInput
            name="taxpayer_id"
            value={formatCPF(formik.values.taxpayer_id)}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="000.000.000-00"
            labelValue="CPF"
            validationError={
              formik.touched.taxpayer_id ? formik.errors.taxpayer_id : ''
            }
          />
        </StepContentInput>
      </StepContentInputArea>
      <ConfirmModal
        isColumnButtons={true}
        isOpen={isConfirmSavingOpen}
        icon={<AttentionCircularIcon />}
        title="O email e senha atual vão parar de funcionar"
        btnConfirmText="Confirmar"
        handleConfirm={formik.submitForm}
        handleClose={() => toggleConfirmSavingOpen(false)}
        handleCancel={() => toggleConfirmSavingOpen(false)}
      >
        {`Ao alterar o email, a criação de uma nova senha para \
        ${userAccess.full_name} será solicitada no novo email, \
        e o email e senha anterior param de funcionar.`}
      </ConfirmModal>
    </EditSideSheet>
  );
};

export default UsersAccessDetailsEditSideSheet;
