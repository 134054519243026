import { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { ButtonPrimary, CloseIcon } from 'app/components';
import {
  ButtonArea,
  ModalHeader,
  ModalSection,
  SectionContent,
  WithdrawModalContainer,
} from './styles';
import { useTheme } from '@mui/material';
import * as yup from 'yup';
import {
  BaseModal,
  CustomInput,
  Heading3,
  TextL,
  TextS,
} from 'celcoin-design-system';
import { format } from 'date-fns';
import { WithdrawRequest } from 'modules/disbursement/interfaces/disbursement';
import { formatToCurrencyFloat } from 'app/utils/normalizer';
import { CustomCurrencyInput } from 'app/components/CustomCurrencyInput';
import { formatBRLCurrencyMaskInput } from 'app/utils/currency';

const withdrawModalValidation = yup.object({
  amount: yup
    .string()
    .required('Campo obrigatório')
    .test({
      name: 'amount',
      test: (value, { createError }) => {
        return !value || Number(formatToCurrencyFloat(value)) <= 0
          ? createError({
              message: 'Insira um valor maior que 0',
              path: 'amount',
            })
          : true;
      },
    }),
  description: yup
    .string()
    .max(140, 'Máximo 140 caracteres')
    .required('Campo obrigatório'),
});

export interface IWithdrawModal {
  isOpen: boolean;
  fundingId: string;
  handleClose: () => void;
  handleWithdraw: (params: WithdrawRequest) => void;
}

const WithdrawModal = ({
  isOpen,
  fundingId,
  handleClose,
  handleWithdraw,
}: IWithdrawModal) => {
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      amount: '0,00',
      description: '',
    },
    validationSchema: withdrawModalValidation,
    validateOnMount: true,
    onSubmit: (values) => {
      const date = format(new Date(), 'yyyyMMddHHmm');
      const identifier = `${fundingId}_${date}_${values.amount.replace(
        ',',
        '_',
      )}`;

      handleWithdraw({
        amount: Number(formatToCurrencyFloat(values.amount)),
        description: values.description,
        identifier,
      });
    },
  });

  useEffect(() => {
    if (isOpen) formik.resetForm();
  }, [isOpen]);

  const handleAmount = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      formik.setFieldValue(
        'amount',
        formatBRLCurrencyMaskInput(evt.target.value || ''),
      );
    },
    [],
  );

  return (
    <BaseModal open={isOpen} onClose={handleClose}>
      <WithdrawModalContainer>
        <ModalHeader>
          <Heading3>Saque</Heading3>
          <div onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </ModalHeader>
        <TextL style={{ marginTop: '15px' }}>
          Preencha os campos abaixo para realizar o saque do seu saldo.
        </TextL>
        <ModalSection>
          <SectionContent>
            <div style={{ display: 'flex', gap: '16px' }}>
              <CustomCurrencyInput
                labelValue="Valor"
                value={formik.values.amount}
                handleInputChange={handleAmount}
                onBlurCapture={formik.handleBlur}
                validationError={
                  formik.touched.amount ? formik.errors.amount : ''
                }
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                marginTop: '12px',
                position: 'relative',
              }}
            >
              <CustomInput
                name="description"
                multiline={true}
                minRows={5}
                value={formik.values.description ?? ''}
                handleInputChange={formik.handleChange}
                onBlur={formik.handleBlur}
                labelValue="Descrição"
                validationError={
                  formik.touched.description ? formik.errors.description : ''
                }
              />
              <TextS
                style={{
                  bottom:
                    formik.touched.description && formik.errors.description
                      ? -3
                      : -25,
                  right: 5,
                  position: 'absolute',
                  opacity: 0.8,
                }}
              >{`${formik.values.description.length}/140`}</TextS>
            </div>
          </SectionContent>
        </ModalSection>
        <ButtonArea>
          <ButtonPrimary
            typeVariant="outline"
            style={{ border: 'none' }}
            onClick={handleClose}
          >
            Cancelar
          </ButtonPrimary>
          <ButtonPrimary disabled={!formik.isValid} onClick={formik.submitForm}>
            Sacar
          </ButtonPrimary>
        </ButtonArea>
      </WithdrawModalContainer>
    </BaseModal>
  );
};

export default WithdrawModal;
