import { useCallback, useContext, useEffect, useState } from 'react';
import { ButtonDefault, ButtonPrimary, StepContent } from 'app/components';
import { QualificationRequestStepperContext } from '../QualificationRequest';
import { useProductsService } from 'modules/products/services/hooks';
import { TProducts, useProductsContext } from 'modules/products/context';
import {
  ListBox,
  ListBoxItem,
  ListBoxItemTitle,
} from '../../ApplicationRequest/components';
import { StepContentButtonsArea } from './styles';
import { EFunctions } from 'modules/customer/context/CustomerProvider/customer.interfaces';
import { ArrowBack } from '@mui/icons-material';

const ProductStep = () => {
  const {
    onBack,
    onForward,
    onChangeProductData,
    productData,
    borrowerData,
    isForEmployer,
    hasMultiplesFundings,
    onChangeBorrowerType,
    onSelectBorrowerType,
  } = useContext(QualificationRequestStepperContext);
  const [selectedProduct, setSelectedProduct] = useState<TProducts | null>(
    productData,
  );
  const [productList, setProductList] = useState<TProducts[]>([]);
  const { getProducts } = useProductsService();
  const {
    state: { content, has_next },
  } = useProductsContext();

  useEffect(() => {
    if (content) setProductList(content);
  }, [content]);

  useEffect(() => {
    if (selectedProduct?.id !== productData?.id)
      setSelectedProduct(productData);
  }, [productData]);

  useEffect(() => {
    getProducts();
  }, []);

  const onSearch = useCallback(
    (field: string, value: string) => {
      if (value === '') {
        setProductList(content);
        return;
      }

      const filteredProducts = content.filter((item: TProducts) => {
        if (
          item[field as keyof TProducts]
            ?.toString()
            ?.toLowerCase()
            ?.includes(value?.toLocaleLowerCase())
        ) {
          return item;
        }
      });

      setProductList(filteredProducts);
    },
    [content],
  );

  const onForwardRequest = useCallback(() => {
    onChangeProductData(selectedProduct);
    if (isForEmployer) {
      onForward();
      return;
    }

    if (!borrowerData) {
      if (selectedProduct?.borrower_type === 'BUSINESS') {
        onChangeBorrowerType(EFunctions.BORROWER);
        setTimeout(() => onForward());
        return;
      }
      onSelectBorrowerType();
      return;
    }

    const isBusiness = borrowerData.taxpayer_id.length > 11;
    if (selectedProduct?.borrower_type === 'PERSON') {
      onChangeBorrowerType(
        isBusiness ? EFunctions.EMPLOYER : EFunctions.BORROWER,
      );
      setTimeout(() => onForward());
      return;
    }
    if (selectedProduct?.borrower_type === 'BUSINESS' && isBusiness) {
      onChangeBorrowerType(EFunctions.BORROWER);
      setTimeout(() => onForward());
      return;
    }
    onSelectBorrowerType();
  }, [selectedProduct, borrowerData, isForEmployer]);

  return (
    <StepContent
      title="Produto"
      description="Selecione o produto para a linha de crédito"
    >
      <ListBox
        filterItems={[
          { name: 'Nome', parameterName: 'name' },
          { name: 'Descrição', parameterName: 'description' },
        ]}
        hasMoreItems={has_next}
        items={productList.filter((product) => {
          if (
            isForEmployer ||
            (borrowerData && borrowerData.taxpayer_id.length < 12)
          ) {
            return product.borrower_type === 'PERSON';
          }
          return true;
        })}
        onSearch={onSearch}
      >
        {(i) => {
          const item = i as TProducts;
          return (
            <ListBoxItem
              className={selectedProduct?.name === item.name ? 'selected' : ''}
              onClick={() => setSelectedProduct(item)}
              key={item.name}
            >
              <ListBoxItemTitle>{item.name}</ListBoxItemTitle>
              <span>{item.description}</span>
            </ListBoxItem>
          );
        }}
      </ListBox>
      <StepContentButtonsArea>
        {hasMultiplesFundings && (
          <ButtonDefault margin={true} onClick={() => onBack()}>
            <ArrowBack />
          </ButtonDefault>
        )}
        <ButtonPrimary disabled={!selectedProduct} onClick={onForwardRequest}>
          Avançar
        </ButtonPrimary>
      </StepContentButtonsArea>
    </StepContent>
  );
};

export default ProductStep;
