import React from 'react';
import { AuthContainer } from '../../components';
import { PasswordRecoveryConfirmationContent } from './components';

const PasswordRecoveryConfirmation = () => {
  return (
    <AuthContainer>
      <PasswordRecoveryConfirmationContent />
    </AuthContainer>
  );
};

export default PasswordRecoveryConfirmation;
