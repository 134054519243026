import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { IDocumentsContext } from './documents.interfaces';
import { DOCUMENT_TYPE } from 'app/helpers/documentTypes';
import DocumentsModal from 'modules/customer/components/DocumentsModal/DocumentsModal';

export const DocumentsContext = createContext<IDocumentsContext>({
  onCloseModal: () => {
    throw new Error('Método não implementado');
  },
  onOpenModal: () => {
    throw new Error('Método não implementado');
  },
  onSave: () => {
    throw new Error('Método não implementado');
  },
  onCancel: () => {
    throw new Error('Método não implementado');
  },
});

export interface IDocumentsProviderProps {
  children?: React.ReactNode;
}

export const DocumentsProvider = ({ children }: IDocumentsProviderProps) => {
  const [fileName, updateFileName] = useState<string>();
  const [documentTypes, updateDocumentTypes] = useState<{
    [key: string]: string;
  }>();
  const [excludeTypes, updateExcludeTypes] = useState<DOCUMENT_TYPE[]>([]);
  const [isModalOpen, toggleModalOpen] = useState(false);
  const [isLoading, toggleIsLoading] = useState(false);
  const promiseRef = useRef<{
    resolve?: (payload: DOCUMENT_TYPE | false) => void;
  }>({
    resolve: undefined,
  });
  const onOpenModal = useCallback(
    (
      _fileName: string,
      _documentTypes: {
        [key: string]: string;
      },
      _excludeTypes: DOCUMENT_TYPE[],
    ): Promise<DOCUMENT_TYPE | false> => {
      return new Promise<DOCUMENT_TYPE | false>((resolve) => {
        updateDocumentTypes(_documentTypes);
        updateExcludeTypes(_excludeTypes);
        updateFileName(_fileName);
        toggleModalOpen(true);
        promiseRef.current.resolve = resolve;
      });
    },
    [],
  );

  const onCloseModal = useCallback(() => {
    toggleModalOpen(false);
    toggleIsLoading(false);
  }, []);

  const onSave = useCallback(
    (_documentType: DOCUMENT_TYPE) => {
      if (promiseRef.current?.resolve) {
        toggleIsLoading(true);
        promiseRef.current.resolve(_documentType);
      }
    },
    [promiseRef],
  );

  const onCancel = useCallback(() => {
    if (promiseRef.current?.resolve) {
      promiseRef.current.resolve(false);
    }
    toggleModalOpen(false);
  }, []);

  return (
    <DocumentsContext.Provider
      value={{
        onOpenModal,
        onCloseModal,
        onSave,
        onCancel,
      }}
    >
      {children}
      <DocumentsModal
        isOpen={isModalOpen}
        name={fileName ?? ''}
        excludeTypes={excludeTypes}
        documentTypes={documentTypes}
        handleClose={onCancel}
        handleConfirm={onSave}
        handleCancel={onCancel}
        isLoading={isLoading}
      />
    </DocumentsContext.Provider>
  );
};

export const useDocumentsContext = () => {
  const context = useContext(DocumentsContext);

  if (context === undefined) {
    throw new Error(
      'useDocumentsContext must be used within a DocumentsProvider',
    );
  }
  return context;
};
