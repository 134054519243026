import { useTheme } from '@mui/material';
import { ButtonPrimary, Heading3, TextM } from 'app/components';
import { ArrowIcon, HyperlinkIcon } from 'app/components/Icons';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ApplicationDetailsHeader,
  ViewBoxContainer,
  ViewBoxContent,
  ViewBoxItem,
} from '../../styles';
import { TApplicationData } from '../../../LegalPersonViewApplicationTab/LegalPersonViewApplicationTab';
import { useSnackbar } from 'app/hooks/useSnackbar';

const buttonStyle = {
  padding: '11px 15px',
  width: 'fit-content',
};

export interface IProductContent {
  applicationData: TApplicationData | null;
}

const ProductContent = ({ applicationData }: IProductContent) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { showSnackbarCopyLink } = useSnackbar();

  const handleNavigate = () => {
    navigate(
      `/products/detail/${applicationData?.productData?.id}/applications?page=0`,
    );
  };

  const handleClipboard = useCallback(() => {
    navigator.clipboard.writeText(
      `${document.location.origin}/#/products/detail/${applicationData?.productData?.id}/applications?page=0`,
    );
    showSnackbarCopyLink();
  }, []);

  return (
    <>
      <ApplicationDetailsHeader>
        <Heading3>Produto</Heading3>

        <div style={{ display: 'flex', gap: '6px' }}>
          <ButtonPrimary
            typeVariant="outline"
            style={buttonStyle}
            onClick={handleClipboard}
          >
            <HyperlinkIcon color={theme.palette.brand.primary.base as string} />
          </ButtonPrimary>
          <ButtonPrimary
            typeVariant="outline"
            style={buttonStyle}
            onClick={handleNavigate}
          >
            <TextM
              weight="Semibold"
              style={{
                color: theme.palette.brand.primary.base,
                marginRight: '8px',
              }}
            >
              Ver página
            </TextM>
            <ArrowIcon color={theme.palette.brand.primary.base as string} />
          </ButtonPrimary>
        </div>
      </ApplicationDetailsHeader>
      <ViewBoxContainer>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Produto</TextM>
            </span>
            <span>
              <TextM>{applicationData?.productData?.name}</TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
      </ViewBoxContainer>
    </>
  );
};

export default ProductContent;
