import styled from '@emotion/styled';

export const UploadCsvLoadingModalContainer = styled.div`
  width: 100%;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 504px;
  margin-top: 50px;
`;
