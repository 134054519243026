import { IPersonCustomVariablesValues } from 'app/interfaces/customVariablesInterfaces';
import { TProducts } from '../../../products/context';
import {
  IBankAccount,
  IPixKey,
  TQualificationRequest,
} from '../CustomerProvider/customer.interfaces';
import { personActionType } from './personActionType';

export enum EMaritalPropertySystem {
  FULL_COMMUNION = 'FULL_COMMUNION',
  PARTIAL_COMMUNION = 'PARTIAL_COMMUNION',
  NO_COMMUNION = 'NO_COMMUNION',
  FINAL_COMMUNION = 'FINAL_COMMUNION',
}

export enum EPersonFunctions {
  BORROWER = 'BORROWER',
  GUARANTOR = 'GUARANTOR',
  CO_SIGNER = 'CO_SIGNER',
}

export enum EPersonFunctionProduct {
  BORROWER = 'borrower',
  CO_SIGNER = 'co_signers',
  GUARANTOR = 'guarantors',
}

export enum ESex {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum EMaritalStatus {
  MARRIED = 'MARRIED',
  WIDOWED = 'WIDOWED',
  SEPARATED = 'SEPARATED',
  DIVORCED = 'DIVORCED',
  SINGLE = 'SINGLE',
  OTHER = 'OTHER',
}

export enum EDocumentType {
  RG = 'RG',
  CNH = 'CNH',
  RNE = 'RNE',
  PASSPORT = 'PASSPORT',
  CLASS_ID = 'CLASS_ID',
  MILITARY_ID = 'MILITARY_ID',
}

export type TPersonDocument = {
  number: string;
  issuer: string;
  issue_date: string;
  type: {
    label: string;
    value: EDocumentType | undefined;
  };
};

export type TPersonDocumentDataRequest = {
  number: string;
  issuer: string;
  issue_date: string;
  type: EDocumentType | undefined;
};

export type TRegisterPersonData = {
  taxpayer_id: string;
  full_name: string;
  nationality: string;
  pep: {
    label: string;
    value: string | undefined;
  };
  phone: {
    country_code: string;
    area_code: string;
    number: string;
  };
  occupation: string;
  birth_date: string;
  marital_status?: {
    label: string;
    value: EMaritalStatus | undefined;
  };
  email_address: string;
  mothers_name?: string;
  marital_property_system?: {
    label: string;
    value: EMaritalPropertySystem | undefined;
  };
  sex?: {
    label: string;
    value: ESex | undefined;
  };
  custom_variables?: { [key: string]: string };
  address: {
    country_code: {
      label: string;
      value: string;
    };
    street_name: string;
    street_number: string;
    district: string;
    postal_code: string;
    city: {
      label: string;
      value: string;
    };
    state_code: {
      label: string;
      value: string;
    };
    extra_info: string;
  };
  external_bank_account?: IBankAccount;
  pix?: IPixKey;
  id_document?: TPersonDocument;
  spouse_full_name?: string;
  productId?: string;
  fundingId?: string;
  hasQualificationRequest?: boolean;
  qualification_request?: TQualificationRequest;
  role?: EPersonFunctions;
  employer?: {
    id?: string;
  };
  customVariables?: IPersonCustomVariablesValues[];
  birthplace?: string;
  spouse_taxpayer_id?: string;
  spouse_date_of_birth?: string;
  monthly_income?: number;
};

export type TBatchImportResponse = {
  id: string;
  status: 'PENDING' | 'ERROR';
  has_result_file: boolean;
  created_at: string;
  updated_at?: string;
};

export type TRegisterPersonDataRequest = {
  id?: string;
  taxpayer_id: string;
  full_name: string;
  nationality: string;
  pep?: boolean;
  phone: {
    country_code: string;
    area_code: string;
    number: string;
  };
  occupation: string;
  birth_date?: string;
  marital_status?: EMaritalStatus;
  email_address?: string;
  mothers_name?: string;
  marital_property_system?: EMaritalPropertySystem;
  sex?: ESex;
  custom_variables?: { [key: string]: string | null | undefined };
  address: {
    country_code: string;
    street_name: string;
    street_number: string;
    district: string;
    postal_code: string;
    city: string;
    state_code: string;
    extra_info: string;
  } | null;
  external_bank_account?: IBankAccount;
  pix?: IPixKey;
  id_document?: TPersonDocumentDataRequest;
  monthly_income_range?: number | null;
  income_range?: {
    income_range?: string;
    updated_at?: string;
  } | null;
  spouse_full_name?: string;
  qualification_request?: TQualificationRequest;
  role?: EPersonFunctions;
  spouse_taxpayer_id?: string | null | undefined;
  spouse_date_of_birth?: string;
  monthly_income?: number;
  birthplace?: string;
  employer?: {
    id?: string;
  };
  created_at?: string;
  updated_at?: string;
};

export type TCreatePersonDataRequest = {
  id?: string;
  taxpayer_id: string;
  full_name: string;
  nationality: string;
  pep?: boolean;
  phone: {
    country_code: string;
    area_code: string;
    number: string;
  };
  occupation: string;
  birth_date?: string;
  marital_status?: EMaritalStatus;
  email_address?: string;
  mothers_name?: string;
  marital_property_system?: EMaritalPropertySystem;
  sex?: ESex;
  custom_variables?: {
    values: {
      [key: string]: string | boolean | number | null;
    } | null;
  };
  address: {
    country_code: string;
    street_name: string;
    street_number: string;
    district: string;
    postal_code: string;
    city: string;
    state_code: string;
    extra_info: string;
  } | null;
  external_bank_account?: IBankAccount;
  pix?: IPixKey;
  id_document?: TPersonDocumentDataRequest;
  spouse_full_name?: string;
  qualification_request?: TQualificationRequest;
  role?: EPersonFunctions;
  employer?: {
    id?: string;
  };
  created_at?: string;
  spouse_taxpayer_id?: string | null | undefined;
  spouse_date_of_birth?: string;
  monthly_income?: number;
  birthplace?: string;
};

export interface IPersonState {
  registerData: TRegisterPersonData;
  product: TProducts;
  func: { label?: string; value?: EPersonFunctions };
}

export interface IPersonList {
  content: ({ id: string; created_at: string } & TRegisterPersonDataRequest)[];
  has_next?: boolean;
  total_elements?: number;
  total_pages?: number;
}

export type IDispatch = (action: IPersonAction) => void;

export interface IPersonContext {
  state: IPersonState;
  dispatch: IDispatch;
}

export type IPersonAction =
  | {
      type: personActionType.SET_REGISTER_PERSON_DATA;
      payload: Partial<TRegisterPersonData>;
    }
  | {
      type: personActionType.RESET_STATE;
    }
  | {
      type: personActionType.SET_PRODUCT_DATA;
      payload: Partial<TProducts>;
    }
  | {
      type: personActionType.SET_FUNCTION_TYPE;
      payload: Partial<{ label: string; value: EPersonFunctions }>;
    };
