import styled from '@emotion/styled';

export const Heading3RegularContainer = styled.h3`
  font-size: 36px;
  color: ${({ theme }) => theme.palette?.brand.gray[800]};
  font-family: 'Urbanist';
  font-weight: normal;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
    font-size: clamp(18px, 1.5vw, 36px);
  }
`;
