import styled from '@emotion/styled';

export const NaturalPersonViewCustomVariablesTabPopOver = styled.div`
  padding: 20px 20px;
`;

export const TableWrapper = styled.div`
  margin-top: 40px;
  th:nth-of-type(5) {
    border-left: 1px solid #E6E5EA;
    max-width: 30px;
  }

  td:nth-of-type(5) {
    border-left: 1px solid #E6E5EA;
    max-width: 30px;
  }
`;
