import styled from '@emotion/styled';

export const Heading3Container = styled.h3`
  font-size: 36px;
  color: ${({ theme }) => theme.palette?.brand?.secondary.base};
  font-weight: 700;
  font-family: 'Urbanist';

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
    font-size: clamp(18px, 1.5vw, 36px);
  }
`;
