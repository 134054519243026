import {
  EApplicationStatus,
  EApplicationStatusDescription,
  PaymentMethodDescription,
  TApplicationResponse,
  TQualificationRequestApplication,
} from 'modules/products/services/hooks/useApplicationsService';
import {
  BoxSection,
  ButtonPrimary,
  CustomTableSelect,
  EViewBoxColumnType,
  Heading3,
  ViewBox,
} from 'app/components';
import { StatusBox, StatusReason, TitleArea } from './styles';
import { useCallback, useEffect, useState } from 'react';
import {
  TProducts,
  TQualificationStatusOption,
} from 'modules/products/context';
import {
  useApplicationsService,
  useBeneficiaryService,
  useProductsService,
  useQualificationsService,
} from 'modules/products/services/hooks';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import {
  DownloadIcon,
  ErrorCircularIcon,
  HyperlinkIcon,
  PdfIcon,
} from 'app/components/Icons';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  TBorrowerLimitsRequestType,
  TBorrowerQualificationRequestType,
} from 'modules/products/services/hooks/intefaces';
import { EQualificationStatus } from 'modules/products/context/QualificationsProvider/qualifications.interfaces';
import { useAuthContext, usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { useSnackbar } from 'app/hooks/useSnackbar';
import {
  handleGetStaticStatusList,
  handleStatusListPermissions,
} from 'modules/products/helpers/statusList';
import { useQualificationChangeContext } from 'modules/products/context/QualificationChangeProvider/QualificationChangeProvider';
import { Heading4, TextL, TextM } from 'celcoin-design-system';
import { DocumentArea } from '../ApplicationSignaturesTab/styles';
import { useTheme } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ApplicationReceiptPDF from './ApplicationReceiptPDF';
import { routingPath } from 'app/routes';
import { TBeneficiaryAccount } from 'modules/products/interfaces/beneficiaryAccount';
import { PixType } from 'modules/customer/context/CustomerProvider/customer.interfaces';
import { SignatureCollectObject } from 'modules/products/constants/enums';
import { normalizeAddDigits } from 'app/utils/normalizer';
import {
  extractReceiptDataFromTemplate,
  receiptTemplateForExtractData,
} from '../InternalTransferReceipt/extractReceiptData';
import InternalTransferReceiptPDF from '../InternalTransferReceipt/InternalTransferReceiptPDF';
import { IInternalTransferReceiptPDF } from '../InternalTransferReceipt/interfaces';
import {
  RetryPaymentEditSidesheet,
  RetryPaymentViewSidesheet,
} from './RetryPaymentSidesheet';
import RetryPaymentConfirmationModal from './RetryPaymentSidesheet/RetryPaymentConfirmationModal';

export type TBorrowerQualificationRequestParsed = (
  | TBorrowerQualificationRequestType
  | TBorrowerLimitsRequestType
) & {
  available_payment_amount?: number;
  available_credit_limit?: number;
};

interface IApplicationDetailsTabProps {
  application: TApplicationResponse;
  updateApplicationData: (newApplicationValue: TApplicationResponse) => void;
}

export const getFormatedPix = (pixType?: PixType): EViewBoxColumnType => {
  if (pixType === PixType.TAXPAYER_ID) return EViewBoxColumnType.CPFCNPJ;
  if (pixType === PixType.PHONE_NUMBER) return EViewBoxColumnType.PHONE_PIX;
  return EViewBoxColumnType.DEFAULT;
};

const ApplicationDetailsTab = ({
  application,
  updateApplicationData,
}: IApplicationDetailsTabProps) => {
  const theme = useTheme();
  const { getProduct } = useProductsService();
  const { getPerson } = usePersonService();
  const { getBusiness } = useCustomerService();
  const { getBorrowerLimits } = useQualificationsService();
  const { showSnackbarCopyLink, showSnackbar } = useSnackbar();
  const [borrowerQualificationData, setBorrowerQualificationData] = useState<
    TBorrowerLimitsRequestType | undefined
  >();
  const [applicationStatus, setApplicationStatus] =
    useState<TQualificationStatusOption[]>();
  const [qualificationRequest, updateQualificationRequest] =
    useState<TQualificationRequestApplication | null>(null);
  const navigate = useNavigate();
  const [product, updateProduct] = useState<TProducts>();
  const [
    retryPaymentConfirmationModalOpen,
    updateRetryPaymentConfirmationModalOpen,
  ] = useState(false);
  const [retryPaymentOpen, updateRetryPaymentOpen] = useState(false);
  const [retryPaymentEditOpen, updateRetryPaymentEditOpen] = useState(false);
  const [borrower, updateBorrower] = useState<
    TRegisterPersonDataRequest | TRegisterBusinessDataRequest | null
  >();
  const { isProfileAuthorized } = usePermify();
  const { userInfo } = useAuthContext();
  const [parsedBorrowerQualificationData, setParsedBorrowerQualificationData] =
    useState<TBorrowerQualificationRequestParsed>();
  const { showSnackbarCopy } = useSnackbar();
  const {
    handleUpdateQualificationStatus,
    handleUpdateQualificationStatusLoading,
  } = useQualificationChangeContext();
  const {
    getQualificationStatusOptions,
    getQualificationRequestApplication,
    createDisbursementAttempt,
  } = useApplicationsService();
  const { getBeneficiaryAccounts } = useBeneficiaryService();
  const [beneficiaryAccount, updateBeneficiaryAccount] =
    useState<TBeneficiaryAccount | null>(null);

  const [internalTransferReceiptData, updateInternalTransferReceiptData] =
    useState<IInternalTransferReceiptPDF | null>(null);

  useEffect(() => {
    if (borrowerQualificationData) {
      const parsedBorrowerQualification: TBorrowerQualificationRequestParsed = {
        ...borrowerQualificationData,
        available_credit_limit: borrowerQualificationData?.condition
          ?.line_of_credit
          ? (borrowerQualificationData?.condition?.line_of_credit
              ?.credit_limit || 0) -
            (borrowerQualificationData?.condition?.line_of_credit
              ?.consumed_credit_limit || 0)
          : undefined,
        available_payment_amount: borrowerQualificationData?.condition
          ?.line_of_credit
          ? (borrowerQualificationData?.condition?.line_of_credit
              ?.max_payment_amount || 0) -
            (borrowerQualificationData?.condition?.line_of_credit
              ?.consumed_payment_amount || 0)
          : undefined,
      };

      setParsedBorrowerQualificationData(parsedBorrowerQualification);
    }
    if (application?.beneficiary_account) {
      if (
        !application.beneficiary_account?.holder &&
        application.beneficiary_account?.registered_account_id
      ) {
        getBeneficiaryAccounts(0, 50).then((accounts) => {
          const account = accounts?.content.find(
            (a) =>
              a.id === application?.beneficiary_account?.registered_account_id,
          );
          if (account) updateBeneficiaryAccount(account);
        });
      } else {
        updateBeneficiaryAccount(application?.beneficiary_account);
      }
    }
  }, [application, borrowerQualificationData]);

  const getStatusList = useCallback(() => {
    if (
      application.status === EApplicationStatus.PENDING_QUALIFICATION &&
      applicationStatus
    ) {
      return [
        {
          label: 'Aprovado',
          value: EQualificationStatus.APPROVED as string,
        },
      ]
        .concat(
          applicationStatus && applicationStatus?.length > 0
            ? applicationStatus.map((status) => ({
                label: status.description,
                value: status.identifier,
              }))
            : [],
        )
        .concat({
          label: 'Negado',
          value: EQualificationStatus.DENIED as string,
        });
    } else if (
      application.status === EApplicationStatus.PENDING_QUALIFICATION &&
      !applicationStatus
    ) {
      return [
        {
          label:
            EApplicationStatusDescription[EApplicationStatus.PENDING_RANDOM],
          value: EQualificationStatus.PENDING_RANDOM as string,
        },
        {
          label: EApplicationStatusDescription[EApplicationStatus.CANCELED],
          value: EQualificationStatus.CANCELED as string,
        },
      ];
    }

    return handleGetStaticStatusList(application.status);
  }, [application, applicationStatus]);

  const handleCopy = useCallback((value: string) => {
    navigator.clipboard.writeText(value);
    showSnackbarCopy();
  }, []);

  const getApplicationDetailsInformation = useCallback(() => {
    const {
      id,
      product: { id: productId },
      borrower: { id: borrowerId },
    } = application;
    getProduct(productId).then((productResponse) => {
      const productData: TProducts = productResponse?.data;
      updateProduct(productData);
      if (productData?.borrower_type === 'PERSON') {
        getPerson(borrowerId).then((person) => updateBorrower(person));
      }
      if (productData?.borrower_type === 'BUSINESS') {
        getBusiness(borrowerId).then((business) => updateBorrower(business));
      }
      getBorrowerLimits(productId, borrowerId).then((borrowerLimitsRes) => {
        setBorrowerQualificationData(borrowerLimitsRes?.content?.[0]);
      });
      getQualificationStatusOptions(productId).then((status) =>
        setApplicationStatus(status),
      );
      getQualificationRequestApplication(productId, 0, borrowerId, id).then(
        (qualification) => {
          updateQualificationRequest(qualification?.content?.[0] || null);
        },
      );
    });
  }, [application, retryPaymentOpen, retryPaymentEditOpen]);

  useEffect(() => {
    getApplicationDetailsInformation();
  }, []);

  const getFormatedIpocNumber = useCallback(() => {
    if (application.ipoc_number) {
      const borrowerTypeSize = product?.borrower_type === 'PERSON' ? 11 : 14;
      const issuerTaxpayerId = application.ipoc_number.substring(0, 8);
      const productType = application.ipoc_number.substring(8, 12);
      const borrowerType = application.ipoc_number.substring(12, 13);
      const borrowerTaxpayerId = application.ipoc_number.substring(
        13,
        borrowerTypeSize + 13,
      );
      const sequentialId = application.ipoc_number.substring(
        borrowerTypeSize + 13,
        application.ipoc_number.length,
      );
      return `${issuerTaxpayerId} ${productType} ${borrowerType} ${borrowerTaxpayerId} ${sequentialId}`;
    }
    return null;
  }, [application, product]);

  const handleCopyLink = useCallback(() => {
    const host = `${location.protocol}//${location.host}`;
    if (product?.borrower_type === 'PERSON') {
      navigator.clipboard.writeText(
        `${host}/#/records/natural/${borrower?.id}/details`,
      );
    } else {
      navigator.clipboard.writeText(
        `${host}/#/records/legal/${borrower?.id}/details`,
      );
    }
    showSnackbarCopyLink();
  }, [borrower]);

  const handleBorrowerPage = useCallback(() => {
    if (product?.borrower_type === 'PERSON') {
      navigate(`/records/natural/${borrower?.id}/details`);
    } else {
      navigate(`/records/legal/${borrower?.id}/details`);
    }
  }, [borrower, product]);

  const handleUpdateQualificationStatusAction = (identifier: string) => {
    handleUpdateQualificationStatus(
      application as TApplicationResponse,
      identifier,
      qualificationRequest ?? undefined,
    ).then((retn) => {
      if (retn) {
        getApplicationDetailsInformation();
        if (!retn.isQualification) {
          updateApplicationData({
            ...application,
            status: retn.newStatus as string,
          });
        }
      }
    });
  };

  const getFormatedInterestRate = () => {
    const interestRate = application?.interest_rate;
    const interestRateAnnual = application?.loan_details?.annual_interest_rate;
    const interestRatePercent =
      typeof interestRate === 'number'
        ? `${normalizeAddDigits(interestRate * 100, 2)
            .toString()
            .replace('.', ',')}%`
        : '-';
    const interestRateAnnualPercent =
      typeof interestRateAnnual === 'number'
        ? `${normalizeAddDigits(interestRateAnnual * 100, 2)
            .toString()
            .replace('.', ',')}%`
        : '-';
    return `${interestRatePercent} a.m. / ${interestRateAnnualPercent} a.a.`;
  };

  const handleRetryPayment = useCallback(() => {
    createDisbursementAttempt(application.id).then(() => {
      showSnackbar('Retentativa de pagamento iniciada com sucesso!', 'success');
    });
    updateRetryPaymentConfirmationModalOpen(false);
    updateRetryPaymentOpen(false);
  }, []);

  const getFormatedCet = () => {
    const cetRate = application?.loan_details?.monthly_effective_interest_rate;
    const cetRateAnnual =
      application?.loan_details?.annual_effective_interest_rate;
    const cetRatePercent =
      typeof cetRate === 'number'
        ? `${normalizeAddDigits(cetRate * 100, 2)
            .toString()
            .replace('.', ',')}%`
        : '-';
    const cetRateAnnualPercent =
      typeof cetRateAnnual === 'number'
        ? `${normalizeAddDigits(cetRateAnnual * 100, 2)
            .toString()
            .replace('.', ',')}%`
        : '-';
    return `${cetRatePercent} a.m. / ${cetRateAnnualPercent} a.a.`;
  };

  const getFormatedFundingName = () => {
    if (!application.funding) return '-';
    if (!application.funding.alias) return application.funding.legal_name;
    return `${application.funding.legal_name} (${application.funding.alias})`;
  };

  useEffect(() => {
    if (
      typeof application.disbursement?.receipt === 'string' &&
      application.disbursement?.receipt?.includes('\f')
    ) {
      const internalReceiptExtractedData = extractReceiptDataFromTemplate(
        application.disbursement?.receipt?.split('\f')?.[0],
        receiptTemplateForExtractData,
      ) as unknown as IInternalTransferReceiptPDF;

      updateInternalTransferReceiptData(internalReceiptExtractedData);
    }
  }, [application]);

  return (
    <>
      <BoxSection>
        {application?.status_description &&
          (application.status === EApplicationStatus.CANCELED ||
            application?.status ===
              EApplicationStatus.DISBURSEMENT_ATTEMPT_FAILED) && (
            <StatusReason>
              <div className="info">
                <div>
                  <ErrorCircularIcon />
                </div>
                <div>
                  <TextL weight="bold">
                    Ocorreu um problema com o pagamento
                  </TextL>
                  <TextM>{application?.status_description || '-'}</TextM>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  marginTop: '18px',
                }}
              >
                <ButtonPrimary
                  style={{
                    marginLeft: '48px',
                    width: '250px',
                  }}
                  onClick={() => {
                    navigate(`/${routingPath.products.applicationRequest}`, {
                      state: {
                        application,
                      },
                    });
                  }}
                >
                  Repetir a solicitação
                </ButtonPrimary>
                {application?.status ===
                  EApplicationStatus.DISBURSEMENT_ATTEMPT_FAILED && (
                  <ButtonPrimary
                    style={{
                      width: '250px',
                    }}
                    onClick={() => updateRetryPaymentOpen(true)}
                  >
                    Retentar pagamento
                  </ButtonPrimary>
                )}
              </div>
            </StatusReason>
          )}
        <StatusBox>
          <span>
            <strong>Status</strong>
          </span>
          <span>
            <CustomTableSelect
              selectItems={getStatusList().filter(
                handleStatusListPermissions(
                  application,
                  application?.status ===
                    EApplicationStatus.PENDING_QUALIFICATION
                    ? qualificationRequest?.status
                    : application.status,
                  userInfo,
                ),
              )}
              disabled={
                !isProfileAuthorized(
                  EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION,
                ) || !product
              }
              isLoading={
                handleUpdateQualificationStatusLoading === application?.id
              }
              handleChange={(identifier) =>
                handleUpdateQualificationStatusAction(identifier)
              }
              value={
                (application?.status ===
                EApplicationStatus.PENDING_QUALIFICATION
                  ? qualificationRequest?.status
                  : application.status) || ''
              }
            />
          </span>
        </StatusBox>

        <TitleArea>
          <Heading3>Financiamento</Heading3>
        </TitleArea>
        <ViewBox
          textAlign="right"
          values={
            {
              ...application,
              productName: !product ? 'DESCREDENCIADO' : product.name,
              fullInterestRate: getFormatedInterestRate(),
            } as unknown as {
              [key: string]: string | number;
            }
          }
          columns={[
            {
              path: 'requested_amount',
              label: 'Valor liberado',
              type: EViewBoxColumnType.CURRENCY,
            },
            {
              path: 'num_payments',
              label: 'Prazo',
            },
            {
              path: 'fullInterestRate',
              label: 'Taxa de Juros',
            },
            {
              path: 'tac_amount',
              label: 'TC',
              type: EViewBoxColumnType.CURRENCY,
            },
            {
              path: 'finance_fee',
              label: 'Outros custos',
              type: EViewBoxColumnType.CURRENCY,
            },
            {
              path: 'loan_details.financed_amount',
              label: 'Valor financiado',
              type: EViewBoxColumnType.CURRENCY,
            },
            {
              path: 'created_at',
              label: 'Data de início do desembolso',
              hidden: !product?.multiple_disbursements_dates,
              type: EViewBoxColumnType.DATE,
            },
            {
              path: 'disbursement_date',
              label: product?.multiple_disbursements_dates
                ? 'Data fim do desembolso'
                : 'Data de desembolso',
              type: EViewBoxColumnType.DATE,
            },
          ]}
        />

        <TitleArea>
          <Heading3>Custos</Heading3>
        </TitleArea>
        <ViewBox
          textAlign="right"
          values={
            {
              ...application,
              fullCet: getFormatedCet(),
              productName: !product ? 'DESCREDENCIADO' : product.name,
            } as unknown as {
              [key: string]: string | number;
            }
          }
          columns={[
            {
              path: 'loan_details.iof_amount',
              label: 'IOF',
              type: EViewBoxColumnType.CURRENCY,
            },
            {
              path: 'loan_details.iof_daily_rate',
              label: 'Taxa IOF (diário)',
              type: EViewBoxColumnType.PERCENT,
              digits: 4,
              x100: true,
            },
            {
              path: 'loan_details.iof_base_rate',
              label: 'Taxa IOF (base)',
              type: EViewBoxColumnType.PERCENT,
              x100: true,
            },
            {
              path: 'base_premium_amount',
              label: 'Ágio base',
              type: EViewBoxColumnType.CURRENCY,
            },
            {
              path: 'additional_premium_amount',
              label: 'Ágio adicional',
              type: EViewBoxColumnType.CURRENCY,
            },
            {
              path: 'fullCet',
              label: 'CET',
            },
            {
              path: 'issue_value',
              label: 'Saldo para liquidação',
              tooltip:
                'Este valor corresponde ao saldo necessário para liquidação da CCB',
              type: EViewBoxColumnType.CURRENCY,
            },
          ]}
        />

        {product?.type_product_emission === 'COMMERCIAL_PAPER' &&
          application.custodian && (
            <>
              <TitleArea>
                <Heading3>Custodiante</Heading3>
              </TitleArea>
              <ViewBox
                textAlign="right"
                values={
                  {
                    ...application.custodian,
                  } as unknown as {
                    [key: string]: string | number;
                  }
                }
                columns={[
                  {
                    path: 'taxpayer_id',
                    label: 'CNPJ',
                    type: EViewBoxColumnType.CNPJ,
                  },
                  {
                    path: 'legal_name',
                    label: 'Razão Social',
                  },
                  {
                    path: 'foundation_date',
                    label: 'Data de Constituição',
                    type: EViewBoxColumnType.DATE,
                  },
                  {
                    path: 'email_address',
                    label: 'E-mail',
                  },
                  {
                    path: 'phone',
                    label: 'Telefone',
                    type: EViewBoxColumnType.PHONE,
                  },
                  {
                    path: 'address.postal_code',
                    label: 'CEP',
                    type: EViewBoxColumnType.CEP,
                  },
                  {
                    path: 'address.street_name',
                    label: 'Rua/Avenida',
                    type: EViewBoxColumnType.CAPITALIZED,
                  },
                  {
                    path: 'address.street_number',
                    label: 'Número',
                  },
                  {
                    path: 'address.district',
                    label: 'Bairro',
                    type: EViewBoxColumnType.CAPITALIZED,
                  },
                  {
                    path: 'address.city',
                    label: 'Cidade',
                    type: EViewBoxColumnType.CAPITALIZED,
                  },
                  {
                    path: 'address.state_code',
                    label: 'Estado',
                    type: EViewBoxColumnType.STATE,
                  },
                  {
                    path: 'address.extra_info',
                    label: 'Complemento',
                  },
                ]}
              />
            </>
          )}

        <TitleArea>
          <Heading3>Repagamentos</Heading3>
        </TitleArea>
        <ViewBox
          textAlign="right"
          values={
            {
              ...application,
              productName: !product ? 'DESCREDENCIADO' : product.name,
            } as unknown as {
              [key: string]: string | number;
            }
          }
          columns={[
            {
              path: 'loan_details.payment_amount',
              label: 'Valor da parcela',
              type: EViewBoxColumnType.CURRENCY,
            },
            {
              path: 'first_payment_date',
              label: 'Primeiro vencimento',
              type: EViewBoxColumnType.DATE,
            },
            {
              path: 'loan_details.last_payment_date',
              label: 'Último vencimento',
              type: EViewBoxColumnType.DATE,
            },
            {
              path: 'loan_details.total_amount_owed',
              label: 'Saldo total',
              type: EViewBoxColumnType.CURRENCY,
            },
          ]}
        />

        <TitleArea>
          <Heading3>Registro</Heading3>
        </TitleArea>
        <ViewBox
          textAlign="right"
          values={
            {
              ...application,
              fundingName: getFormatedFundingName(),
              productName: !product ? 'DESCREDENCIADO' : product.name,
              ipoc_number: getFormatedIpocNumber(),
            } as unknown as {
              [key: string]: string | number;
            }
          }
          columns={[
            {
              path: 'created_at',
              label: 'Data de digitação',
              type: EViewBoxColumnType.DATETIME,
            },
            {
              path: 'sequential_id',
              label: 'CCB',
            },
            {
              path: 'productName',
              label: 'Produto',
              type: !product
                ? EViewBoxColumnType.ITALIC
                : EViewBoxColumnType.DEFAULT,
            },
            {
              path: 'fundingName',
              label: 'Funding',
            },
            {
              path: 'signature_collect_method',
              label: 'Método de assinatura',
              type: EViewBoxColumnType.ENUM,
              enum: SignatureCollectObject,
            },
            {
              path: 'disbursement.processed_at',
              label: 'Horário de Pagamento Pix',
              type: EViewBoxColumnType.DATETIME,
            },
            {
              path: 'canceled_at',
              label: 'Horário de Cancelamento',
              type: EViewBoxColumnType.DATE,
            },
            {
              path: 'payment_method',
              label: 'Método de pagamento',
              type: EViewBoxColumnType.ENUM,
              enum: PaymentMethodDescription,
            },
            {
              path: 'ipoc_number',
              label: 'IPOC',
              maxWidth: '75%',
              type: EViewBoxColumnType.COPY,
              onCopy: handleCopy,
            },
          ]}
        />

        {application.managed_account_for_issuer_disbursement && (
          <>
            <TitleArea>
              <Heading3>Conta para Desembolso</Heading3>
            </TitleArea>
            <ViewBox
              textAlign="right"
              size={2}
              values={{
                account_number:
                  application.managed_account_for_issuer_disbursement?.baas
                    ?.account_number ?? '-',
                pix:
                  application.managed_account_for_issuer_disbursement?.baas
                    ?.pix_key ?? '-',
              }}
              columns={[
                {
                  path: 'account_number',
                  label: 'Conta',
                },
                {
                  path: 'pix',
                  label: 'Chave Pix',
                },
              ]}
            />
          </>
        )}

        {beneficiaryAccount && (
          <>
            <TitleArea>
              <Heading3>Conta Beneficiária</Heading3>
            </TitleArea>
            <ViewBox
              textAlign="right"
              values={
                beneficiaryAccount as unknown as {
                  [key: string]: string | number;
                }
              }
              columns={[
                {
                  path: 'description',
                  label: 'Descrição',
                },
                {
                  path: 'external_bank_account.ispb_code',
                  label: 'Banco',
                  type: EViewBoxColumnType.BANK,
                },
                {
                  path: 'external_bank_account.bank_account_type',
                  label: 'Tipo',
                  type: EViewBoxColumnType.BANK_TYPE,
                },
                {
                  path: 'holder.name',
                  label: 'Nome',
                },
                {
                  path: 'external_bank_account',
                  label: 'Conta',
                  type: EViewBoxColumnType.BANK_ACCOUNT,
                },
                {
                  path: 'external_bank_account.bank_branch',
                  label: 'Agência',
                },
                {
                  path: 'holder.taxpayer_id',
                  label:
                    beneficiaryAccount.holder.taxpayer_id.length > 11
                      ? 'CNPJ'
                      : 'CPF',
                  type: EViewBoxColumnType.CPFCNPJ,
                },
                {
                  path: 'pix.key',
                  label: 'Pix',
                  type: getFormatedPix(
                    beneficiaryAccount.pix?.key_type as PixType,
                  ),
                },
              ]}
            />
          </>
        )}

        {typeof product?.name === 'string' && (
          <>
            <TitleArea>
              <Heading3>Tomador</Heading3>
              <ButtonPrimary
                style={{
                  backgroundColor: 'transparent',
                  marginLeft: '32px',
                  width: 'fit-content',
                }}
                typeVariant="outline"
                btnIcon={
                  <HyperlinkIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                }
                iconPosition="left"
                onClick={handleCopyLink}
              ></ButtonPrimary>
              <ButtonPrimary
                style={{
                  backgroundColor: 'transparent',
                  marginLeft: '10px',
                  width: 'fit-content',
                }}
                typeVariant="outline"
                btnIcon={<ArrowForward />}
                iconPosition="right"
                onClick={handleBorrowerPage}
              >
                <span style={{ paddingRight: '5px' }}>Ver página</span>
              </ButtonPrimary>
            </TitleArea>
            <ViewBox
              textAlign="right"
              values={
                {
                  borrower,
                  parsedBorrowerQualificationData,
                } as unknown as {
                  [key: string]: string | number;
                }
              }
              columns={[
                {
                  path:
                    product?.borrower_type === 'PERSON'
                      ? 'borrower.full_name'
                      : 'borrower.legal_name',
                  label: 'Nome',
                },
                {
                  path: 'borrower.email_address',
                  label: 'E-mail',
                },
                {
                  path: 'parsedBorrowerQualificationData.available_credit_limit',
                  label: 'Limite disponível',
                  type: EViewBoxColumnType.CURRENCY,
                },
                {
                  path: 'borrower.taxpayer_id',
                  label: product?.borrower_type === 'PERSON' ? 'CPF' : 'CNPJ',
                  type:
                    product?.borrower_type === 'PERSON'
                      ? EViewBoxColumnType.CPF
                      : EViewBoxColumnType.CNPJ,
                },
                {
                  path: 'borrower.phone',
                  label: 'Celular',
                  type: EViewBoxColumnType.PHONE,
                },
                {
                  path: 'parsedBorrowerQualificationData.available_payment_amount',
                  label: 'Limite da parcela disponível',
                  type: EViewBoxColumnType.CURRENCY,
                },
                {
                  path:
                    product?.borrower_type === 'PERSON'
                      ? 'borrower.birth_date'
                      : 'borrower.foundation_date',
                  label:
                    product?.borrower_type === 'PERSON'
                      ? 'Nascimento'
                      : 'Data Constituição',
                  type: EViewBoxColumnType.DATE,
                },
                {
                  path: 'borrower.created_at',
                  label: 'Cadastrado em',
                  type: EViewBoxColumnType.DATE,
                },
                {
                  path: 'parsedBorrowerQualificationData.employer.legal_name',
                  label: 'Empresa',
                  type: EViewBoxColumnType.EXTERNAL,
                  url: `/records/legal/${borrowerQualificationData?.employer?.id}/details`,
                },
              ]}
            />
          </>
        )}
        {internalTransferReceiptData && (
          <DocumentArea>
            <PdfIcon />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Heading4 style={{ verticalAlign: 'center' }}>
                Comprovante de Pagamento ao Tomador:
              </Heading4>
            </div>
            <PDFDownloadLink
              style={{
                textDecoration: 'none',
              }}
              fileName={`Comprovante_transferencia_${application.id}`}
              document={
                <InternalTransferReceiptPDF
                  data={internalTransferReceiptData}
                />
              }
            >
              {() => {
                return (
                  <ButtonPrimary
                    typeVariant="outline"
                    style={{
                      gap: '6px',
                      width: 'fit-content',
                      color: theme.palette.brand.primary.base,
                    }}
                  >
                    <DownloadIcon
                      color={theme.palette.brand.primary.base as string}
                    />
                    <TextM
                      weight="Semibold"
                      style={{ color: theme.palette.brand.primary.base }}
                    >
                      Baixar
                    </TextM>
                  </ButtonPrimary>
                );
              }}
            </PDFDownloadLink>
          </DocumentArea>
        )}
        {typeof application.disbursement?.receipt === 'string' && (
          <DocumentArea>
            <PdfIcon />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Heading4 style={{ verticalAlign: 'center' }}>
                Comprovante Detalhado:
              </Heading4>
            </div>
            <PDFDownloadLink
              style={{
                textDecoration: 'none',
              }}
              fileName={`Comprovante_${application.id}`}
              document={
                <ApplicationReceiptPDF
                  receipt={application.disbursement?.receipt}
                />
              }
            >
              {() => {
                return (
                  <ButtonPrimary
                    typeVariant="outline"
                    style={{
                      gap: '6px',
                      width: 'fit-content',
                      color: theme.palette.brand.primary.base,
                    }}
                  >
                    <DownloadIcon
                      color={theme.palette.brand.primary.base as string}
                    />
                    <TextM
                      weight="Semibold"
                      style={{ color: theme.palette.brand.primary.base }}
                    >
                      Baixar
                    </TextM>
                  </ButtonPrimary>
                );
              }}
            </PDFDownloadLink>
          </DocumentArea>
        )}
      </BoxSection>
      <RetryPaymentConfirmationModal
        isOpen={retryPaymentConfirmationModalOpen}
        handleClose={() => {
          updateRetryPaymentConfirmationModalOpen(false);
        }}
        handleConfirm={() => {
          handleRetryPayment();
          updateRetryPaymentConfirmationModalOpen(false);
        }}
      />
      {retryPaymentEditOpen && (
        <RetryPaymentEditSidesheet
          isOpen={retryPaymentEditOpen}
          productData={product}
          applicationData={application}
          borrowerData={borrower}
          handleClose={() => {
            updateRetryPaymentEditOpen(false);
            updateRetryPaymentOpen(true);
          }}
        />
      )}
      {retryPaymentOpen && (
        <RetryPaymentViewSidesheet
          isOpen={retryPaymentOpen}
          applicationData={application}
          productData={product}
          handleClose={() => {
            updateRetryPaymentOpen(false);
          }}
          handleEdit={() => {
            updateRetryPaymentOpen(false);
            updateRetryPaymentEditOpen(true);
          }}
          retryPayment={() => updateRetryPaymentConfirmationModalOpen(true)}
        />
      )}
    </>
  );
};

export default ApplicationDetailsTab;
