import styled from '@emotion/styled';

export const TabsContainer = styled.div`
  height: fit-content;
  padding: 10px;
  border: 2px solid #E6E5EA;
  display: flex;
  border-radius: 10px;
  gap: 12px;
  width: 100%;
`;
