const ErrorCircularIcon = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21" cy="21" r="21" fill="#F50404" />
    <path
      d="M26.9979 27.9992C26.8665 27.9994 26.7364 27.9738 26.6149 27.9236C26.4935 27.8735 26.3831 27.7999 26.29 27.707L20.999 22.409L15.706 27.707C15.5185 27.8946 15.2642 28 14.9991 28C14.734 28 14.4797 27.8946 14.2922 27.707C14.1048 27.5193 13.9995 27.2648 13.9995 26.9995C13.9995 26.7341 14.1048 26.4796 14.2922 26.2919L19.5853 20.994L14.2922 15.696C14.1101 15.5072 14.0093 15.2544 14.0116 14.9921C14.0139 14.7297 14.119 14.4787 14.3044 14.2931C14.4898 14.1076 14.7406 14.0023 15.0027 14C15.2649 13.9978 15.5174 14.0986 15.706 14.2809L20.999 19.5789L26.29 14.2809C26.4786 14.0986 26.7312 13.9978 26.9933 14C27.2555 14.0023 27.5062 14.1076 27.6916 14.2931C27.877 14.4787 27.9821 14.7297 27.9844 14.9921C27.9867 15.2544 27.8859 15.5072 27.7038 15.696L22.4127 20.994L27.7058 26.2899C27.846 26.4299 27.9415 26.6084 27.9802 26.8027C28.0189 26.9971 27.9991 27.1986 27.9233 27.3817C27.8475 27.5648 27.7191 27.7212 27.5543 27.8311C27.3896 27.9411 27.1959 27.9996 26.9979 27.9992Z"
      fill="#FDFDFD"
      stroke="#FDFDFD"
      strokeWidth="0.5"
    />
  </svg>
);

export default ErrorCircularIcon;
