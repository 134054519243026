import { BoxSection, ButtonPrimary, Heading3 } from 'app/components';
import { useFundingsService } from 'modules/products/services/hooks';
import { useEffect, useState, useContext } from 'react';
import { ApplicationRequestContext } from '../ApplicationRequest';
import { ListBox, ListBoxItem, ListBoxItemTitle } from '../components';
import { StepContentButtonsArea } from '../styles';
import { FundingStepContent } from './styles';
import { IFunding } from 'modules/products/services/hooks/intefaces';
import { useAuthContext } from 'modules/auth/context';
import { formatCNPJ } from 'app/helpers';

const FundingStep = () => {
  const { userInfo } = useAuthContext();
  const { getFundings } = useFundingsService();
  const [hasNext, updateHasNext] = useState(false);
  const [searchText, updateSearchText] = useState('');
  const [fundingList, setFundingList] = useState<IFunding[]>([]);
  const { funding, updateFunding, onForward } = useContext(
    ApplicationRequestContext,
  );
  const [selectedFunding, setSelectedFunding] = useState<string | null>();

  useEffect(() => {
    if (userInfo) {
      getFundings(userInfo.fundings).then((fundings) => {
        if (fundings) {
          setFundingList(fundings.content);
          updateHasNext(!!fundings.has_next);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!selectedFunding || funding !== selectedFunding) {
      setSelectedFunding(funding);
    }
  }, [funding]);

  return (
    <BoxSection boxVariant="rounded">
      <FundingStepContent>
        <Heading3>
          Qual o <strong>funding </strong>
          dessa solicitação?
        </Heading3>
        <ListBox
          filterItems={[{ name: 'Nome', parameterName: 'legal_name' }]}
          hasMoreItems={hasNext}
          items={fundingList?.filter(
            (fundingItem) =>
              !searchText ||
              fundingItem.legal_name
                .toLowerCase()
                .includes(searchText.toLowerCase()),
          )}
          onSearch={(field, value) => updateSearchText(value)}
        >
          {(i) => {
            const item = i as IFunding;
            return (
              <ListBoxItem
                className={selectedFunding === item.id ? 'selected' : ''}
                onClick={() => setSelectedFunding(item.id)}
                key={item.legal_name}
              >
                <ListBoxItemTitle>
                  {item.alias ?? item.legal_name}
                </ListBoxItemTitle>
                <span>{formatCNPJ(item.taxpayer_id)}</span>
              </ListBoxItem>
            );
          }}
        </ListBox>
        <StepContentButtonsArea>
          <ButtonPrimary
            disabled={!selectedFunding}
            onClick={() => {
              if (selectedFunding) {
                updateFunding(selectedFunding);
                setTimeout(() => onForward());
              }
            }}
          >
            Avançar
          </ButtonPrimary>
        </StepContentButtonsArea>
      </FundingStepContent>
    </BoxSection>
  );
};

export default FundingStep;
