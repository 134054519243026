const ACCOUNT_TYPE_ARRAY = [
  {
    label: 'Conta Corrente',
    value: 'CACC',
  },
  {
    label: 'Conta poupança',
    value: 'SVGS',
  },
  {
    label: 'Conta de pagamentos',
    value: 'TRAN',
  },
  {
    label: 'Conta salario',
    value: 'SLRY',
  },
];

const ACCOUNT_TYPE_OBJECT: { [key: string]: string } =
  ACCOUNT_TYPE_ARRAY.reduce((obj, accountType) => {
    return {
      ...obj,
      [accountType.value]: accountType.label,
    };
  }, {});

export { ACCOUNT_TYPE_ARRAY, ACCOUNT_TYPE_OBJECT };
