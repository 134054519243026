import styled from '@emotion/styled';

export const ColumnSettingsContent = styled.div`
  padding: 0px;
  width: 100%;
  max-width: 700px;
  min-width: 700px;

  .header-area {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .close-btn {
      cursor: pointer;
    }
  }

  .body-content {
    margin-top: 30px;
    display: flex;
    position: relative;
    min-height: 400px;


    .arrows-icon {
      position: absolute;
      left: 47%;
      top: 44%;
      z-index: 9999;
      background-color: white;
      svg {
        fill: ${({ theme }) => theme.palette?.brand?.gray[500]};
        font-size: 40px;
      }
    }

    .content-left {
      width: 47%;
      min-width: 300px;
      margin-right: auto;
      position: relative;
      padding: 0px 25px 0px 0px;
      &:after {
        height: 95%;
        top: 10px;
        right: -20px;
        position: absolute;
        width: 1px;
        content: " ";
        border-right: 1px solid ${({ theme }) =>
          theme.palette?.brand?.gray[300]};
      }
    }

    .content-right {
      width: 50%;
      min-width: 300px;
      position: relative;
      padding: 0px 0px 0px 50px; 
    }

    .copy-text {
      cursor: pointer;
    }
  }
  .footer-content {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ColumnSettingsEmpty = styled.div`
  color: ${({ theme }) => theme.palette?.brand?.gray[300]};
  width: 300px;
  display: flex;
  align-items: center;
  opacity: 0.7;
  text-transform: uppercase;
  justify-content: center;
  flex-direction: column;
  height: 60%;
  svg {
    font-size: 60px;
  }
`;

export const ColumnSettingsSearchContent = styled.div`
  padding: 20px 10px 10px 10px;
  border-bottom: 2px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
`;

export const ColumnSettingsDraggableListContent = styled.div`
  padding-top: 10px;
  max-height: 360px;
  overflow-y: auto;
  height: 100%;
`;
