import { useTheme } from '@mui/material';
import { CloseIcon, Spinner, Heading4, TextL } from 'app/components';
import { BaseModal, Heading3 } from 'celcoin-design-system';
import {
  ModalBody,
  ModalHeader,
  WithdrawLoadingModalContainer,
} from './styles';

export interface IWithdrawLoadingModal {
  isOpen: boolean;
  handleClose: () => void;
}

const WithdrawLoadingModal = ({
  isOpen,
  handleClose,
}: IWithdrawLoadingModal) => {
  const theme = useTheme();
  return (
    <BaseModal open={isOpen} onClose={handleClose}>
      <WithdrawLoadingModalContainer>
        <ModalHeader>
          <Heading3>Saque</Heading3>
          <div onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </ModalHeader>
        <ModalBody>
          <Spinner />
          <Heading4
            style={{
              color: theme.palette.brand.secondary.base,
              marginTop: '24px',
              textAlign: 'center',
            }}
            fontWeight="B"
          >
            Transação em processamento, aguarde nessa tela para obter o
            comprovante.
          </Heading4>
          <TextL
            style={{
              color: theme.palette.brand.secondary.base,
              textAlign: 'center',
              marginTop: '15px',
            }}
          >
            Fechar essa tela não interrompe o processamento do Saque. O Saque
            será registrado no seu Relatório de Conciliação
          </TextL>
        </ModalBody>
      </WithdrawLoadingModalContainer>
    </BaseModal>
  );
};

export default WithdrawLoadingModal;
