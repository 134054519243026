export function isJSON(str: string | null): boolean {
  try {
    if (typeof str === 'string') {
      JSON.parse(str);
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
}
