import { HTMLAttributes } from "react"

interface IChevronIcon extends HTMLAttributes<HTMLOrSVGElement> {
  width?: number,
  height?: number,
  color?: string
}

const ChevronIcon = ({width = 20, height = 12, color = "#1B104E", ...props}: IChevronIcon) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.032 1.034a1.24 1.24 0 0 0 0 1.757l8.266 8.252a.992.992 0 0 0 1.403 0l8.266-8.252a1.244 1.244 0 0 0-1.76-1.758l-7.213 7.19-7.212-7.2a1.241 1.241 0 0 0-1.75.01Z"
      fill="#1B104E"
    />
  </svg>
)

export default ChevronIcon
