import { DOCUMENT_TYPE } from 'app/helpers/documentTypes';
import { useCallback } from 'react';

export interface IFile {
  id?: string;
  name: string;
  uploaded?: boolean;
  file: File | null;
  error?: boolean;
  type: DOCUMENT_TYPE;
  created_at?: string;
  invalidSize?: boolean;
}

const useUploadRegisterCSV = () => {
  const handleUploadFiles = useCallback((files: File[]) => {
    const newUploadedFiles: IFile[] = files.map((file: File) => {
      return {
        file,
        type: DOCUMENT_TYPE.OTHER,
        name: file.name,
        uploaded: false,
        error: false,
      };
    });

    return newUploadedFiles;
  }, []);

  return { handleUploadFiles };
};

export default useUploadRegisterCSV;
