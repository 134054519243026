
const DocIcon = () => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C6.9 0 6 .9 6 2v28c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V8l-8-8H8Z"
      fill="#EEE"
    />
    <path d="M24 8h6l-8-8v6c0 1.1.9 2 2 2Z" fill="#BDBDBD" />
    <path d="m30 14-6-6h6v6Z" fill="#E0E0E0" />
    <path
      d="M26 26c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1V16c0-.55.45-1 1-1h22c.55 0 1 .45 1 1v10Z"
      fill="#50BEE8"
    />
    <path
      d="M5.786 24c-.264 0-.552-.145-.552-.496v-4.541c0-.288.288-.496.552-.496h1.831c3.654 0 3.573 5.533.071 5.533H5.786Zm.504-4.558v3.582h1.327c2.16 0 2.254-3.582 0-3.582H6.29ZM14.25 24.08c-1.479.064-3.015-.92-3.015-2.88 0-1.966 1.535-2.933 3.015-2.933 1.4.07 2.862 1.039 2.862 2.934 0 1.896-1.463 2.879-2.862 2.879Zm-.088-4.773c-.896 0-1.87.632-1.87 1.895 0 1.256.975 1.896 1.87 1.896.92 0 1.903-.64 1.903-1.896 0-1.264-.983-1.895-1.903-1.895ZM18.053 21.193c0-1.543.968-2.87 2.807-2.87.696 0 1.248.208 1.831.712.216.199.24.55.024.775a.543.543 0 0 1-.736-.024c-.327-.344-.663-.44-1.119-.44-1.23 0-1.822.873-1.822 1.848 0 .992.583 1.903 1.822 1.903.456 0 .88-.185 1.248-.512.247-.192.591-.097.735.096.128.176.191.472-.088.75-.56.522-1.23.626-1.896.626-1.934 0-2.806-1.321-2.806-2.864Z"
      fill="#fff"
    />
    <path
      d="M25 27H6v1h19c.55 0 1-.45 1-1v-1c0 .55-.45 1-1 1Z"
      fill="#E0E0E0"
    />
  </svg>
)

export default DocIcon
