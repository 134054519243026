import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .close-btn {
      cursor: pointer;
    }
  }

  .body {
    width: 100%;
    max-width: 300px;
    max-height: 400px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    strong {
      cursor: pointer;
      text-decoration: underline;
    }

    p {
      text-align: center;
    }
    
    .btn {
      margin-top: 40px;
    }
  }
`;
