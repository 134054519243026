import styled from '@emotion/styled';

export const BlockWrapper = styled.div`
  max-width: 502px;
  padding: 32px 20px;
  width: 50%;
  border-radius: 10px;
  margin: 0px 20px;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
    width: 100%;
  }
`;
