import { useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { Heading3, TextM } from '../../../../app/components';
import { StepContentContainer, StepContentContent } from './styles';

export interface IStepContentProps {
  title: string;
  description: string;
  children: ReactNode;
}

const StepContent = ({ title, description, children }: IStepContentProps) => {
  const theme = useTheme();
  return (
    <StepContentContainer>
      <Heading3
        fontWeight="Md"
        style={{ color: theme.palette.brand.secondary.base }}
      >
        {title}
      </Heading3>
      <TextM
        weight="Regular"
        style={{ color: theme.palette.brand.secondary.base }}
      >
        {description}
      </TextM>
      <StepContentContent>{children}</StepContentContent>
    </StepContentContainer>
  );
};

export default StepContent;
