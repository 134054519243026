import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useTheme } from '@mui/material';
import {
  ButtonDefault,
  ButtonPrimary,
  CustomCircularIndicator,
  Heading4,
} from '../../../../../app/components';
import { StepContent } from '../../../components';
import {
  StepContentButtonsArea,
  StepContentInput,
  StepContentInputArea,
} from './styles';
import { ArrowBack } from '@mui/icons-material';
import { NaturalPersonHandlerStepperContext } from '../NaturalPersonHandler';
import { usePerson } from '../../../hooks';
import { usePersonContext } from '../../../context';
import { bankStepSchema } from '../../../helpers/personValidation';
import {
  ACCOUNT_TYPE_ARRAY,
  ACCOUNT_TYPE_OBJECT,
  BANK_LIST_ARRAY,
  BANK_LIST_OBJECT,
} from '../../../../../app/helpers';
import {
  applyFormat,
  getPlaceholder,
  PixSelector,
} from '../../../components/PixSelector';
import {
  getErrorValidation,
  getPixTypeFromPixTypeToggle,
  PixTypeToggle,
} from '../../../components/PixSelector/PixSelector';
import { PixType } from '../../../context/CustomerProvider/customer.interfaces';
import { usePersonService } from '../../../services';
import { formatBankAccount } from 'app/utils/numbers';
import { CustomSelect, CustomInput } from 'celcoin-design-system';

const BankStep = () => {
  const theme = useTheme();
  const { onForward, onBack } = useContext(NaturalPersonHandlerStepperContext);
  const { setPersonData } = usePerson();
  const {
    state: { registerData },
  } = usePersonContext();
  const { external_bank_account, pix } = registerData;
  const { creatPersonLoading } = usePersonService();
  const [pixType, togglePixType] = useState<PixTypeToggle>(PixTypeToggle.CPF);
  const [pixKey, updatePixKey] = useState<string>('');
  const formik = useFormik({
    initialValues: {
      key: pixKey,
      ispb_code: external_bank_account?.ispb_code
        ? {
            value: external_bank_account?.ispb_code,
            label: BANK_LIST_OBJECT[external_bank_account?.ispb_code as string],
          }
        : undefined,
      bank_account: external_bank_account?.bank_account
        ? formatBankAccount(
            `${external_bank_account?.bank_account?.padStart(7, '0')}${
              external_bank_account?.bank_account_digit
            }`,
          )
        : '',
      bank_branch: external_bank_account?.bank_branch ?? '',
      bank_account_type: external_bank_account?.bank_account_type
        ? {
            value: external_bank_account?.bank_account_type,
            label:
              ACCOUNT_TYPE_OBJECT[
                external_bank_account?.bank_account_type as string
              ],
          }
        : undefined,
    },
    enableReinitialize: true,
    validationSchema: bankStepSchema,
    onSubmit: async (values) => {
      const bank_account = values.bank_account.replace(/[^\w\s]/gi, '');
      const keyType = getPixTypeFromPixTypeToggle(pixType);
      const key =
        keyType === PixType.PHONE_NUMBER
          ? `+55${values.key.replace(/[^\w\s]/gi, '').replace(/\s/g, '')}`
          : keyType === PixType.TAXPAYER_ID
          ? values.key.replace(/[^\w\s]/gi, '')
          : keyType === PixType.EMAIL
          ? values.key.toLowerCase()
          : values.key.trim();

      setPersonData({
        external_bank_account: {
          ispb_code: values.ispb_code?.value as string,
          bank_account: bank_account.slice(0, bank_account.length - 1),
          bank_account_digit: bank_account.slice(
            bank_account.length - 1,
            bank_account.length,
          ),
          bank_branch: values.bank_branch,
          bank_account_type: values.bank_account_type?.value as string,
        },
        pix: {
          key,
          key_type: keyType,
        },
      });

      onForward();
    },
  });

  const onSkip = async () => {
    setPersonData({
      external_bank_account: undefined,
      pix: undefined,
    });

    onForward();
  };

  const pixValidation =
    formik.values.key !== '' && getErrorValidation(pixType, formik.values.key);

  return (
    <StepContent title="Dados bancários" description="">
      <Heading4
        fontWeight="B"
        style={{
          color: theme.palette.brand.primary.base,
          marginBottom: '17px',
          marginTop: '32px',
        }}
      >
        Conta
      </Heading4>
      <StepContentInputArea>
        <StepContentInput>
          <CustomSelect
            labelValue="Banco"
            name="ispb_code"
            placeholder="Selecione o banco"
            value={formik.values.ispb_code}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={BANK_LIST_ARRAY}
            validationError={
              formik.touched?.ispb_code ? formik.errors?.ispb_code : ''
            }
          />
        </StepContentInput>
        <StepContentInput isHalf={true}>
          <CustomInput
            name="bank_branch"
            value={formik.values.bank_branch}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="0000"
            labelValue="Agência"
            validationError={
              formik.touched.bank_branch ? formik.errors.bank_branch : ''
            }
          />
        </StepContentInput>
        <StepContentInput isHalf={true}>
          <CustomInput
            name="bank_account"
            value={formatBankAccount(formik.values.bank_account)}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="000000-0"
            labelValue="Conta"
            validationError={
              formik.touched.bank_account ? formik.errors.bank_account : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Tipo de conta"
            name="bank_account_type"
            placeholder="Selecione o tipo"
            value={formik.values.bank_account_type}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={ACCOUNT_TYPE_ARRAY}
            validationError={
              formik.touched?.bank_account_type
                ? formik.errors?.bank_account_type
                : ''
            }
          />
        </StepContentInput>
      </StepContentInputArea>

      <Heading4
        fontWeight="B"
        style={{
          color: theme.palette.brand.primary.base,
          marginBottom: '32px',
          marginTop: '32px',
        }}
      >
        Pix
      </Heading4>
      <PixSelector
        onChange={(pixKeySelector, pixTypeSelector) => {
          formik.setFieldValue('key', '');
          updatePixKey(pixKeySelector);
          togglePixType(pixTypeSelector);
        }}
        pix={pix}
      />
      <StepContentInputArea>
        <StepContentInput>
          <CustomInput
            name="key"
            id="pixKey"
            value={applyFormat(formik.values.key, pixType)}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={getPlaceholder(pixType)}
            labelValue="Insira abaixo a chave Pix"
            validationError={formik.touched.key ? pixValidation || '' : ''}
          />
        </StepContentInput>
      </StepContentInputArea>
      <StepContentButtonsArea>
        <ButtonDefault margin={true} onClick={() => onBack()}>
          <ArrowBack />
        </ButtonDefault>
        {creatPersonLoading ? (
          <CustomCircularIndicator
            style={{ color: theme.palette.brand.primary.base }}
          />
        ) : (
          <>
            {formik.values.ispb_code?.value || formik.values.key !== '' ? (
              <ButtonPrimary
                disabled={creatPersonLoading || !!pixValidation}
                onClick={() => formik.submitForm()}
              >
                Avançar
              </ButtonPrimary>
            ) : (
              <ButtonPrimary disabled={creatPersonLoading} onClick={onSkip}>
                Pular
              </ButtonPrimary>
            )}
          </>
        )}
      </StepContentButtonsArea>
    </StepContent>
  );
};

export default BankStep;
