import styled from '@emotion/styled';

export const Cnab444ApplicationsWrapper = styled.div`
  padding: 24px 48px;
`;

export const Cnab444ApplicationsHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const Cnab444ApplicationsFilterArea = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      flex-direction: column;
  }

  .search-filter {
    max-width: 550px;
    width: 100%;
  }
`;

export const Cnab444ApplicationsButtonArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: flex-end;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      margin-top: 20px;
  }
`;

export const Cnab444ApplicationsTableArea = styled.div`
  margin-top: 32px;
  position: relative;

  .MuiTableContainer-root {
    overflow-x: visible !important;
  }

  th:nth-of-type(1) {
    max-width: 40px;
  }

  td:nth-of-type(1) {
    max-width: 40px;
  }
`;

export const Cnab444ApplicationsFiltersCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${({ theme }) => theme.palette?.brand.primary.base};
  font-size: 12px;
  line-height: 0px;
  border: 1px solid ${({ theme }) => theme.palette?.brand.primary.base};
  font-weight: 600;
  width: 26px;
  height: 26px;
  border-radius: 100px;
  margin-left: 10px;
  cursor: pointer;
  z-index: 99;
`;
