import UncheckIcon from './UncheckIcon';

const CheckIcon = ({ isChecked }: { isChecked?: boolean }) => {
  if (isChecked === false) {
    return <UncheckIcon />;
  }
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8301 28C12.0611 28 9.35439 27.1789 7.0521 25.6406C4.74981 24.1022 2.9554 21.9157 1.89577 19.3576C0.836144 16.7994 0.558897 13.9845 1.09909 11.2687C1.63928 8.55301 2.97265 6.05844 4.93059 4.10051C6.88852 2.14258 9.38308 0.809205 12.0988 0.269012C14.8146 -0.271181 17.6295 0.00606587 20.1877 1.06569C22.7458 2.12532 24.9323 3.91973 26.4707 6.22202C28.009 8.52431 28.8301 11.2311 28.8301 14C28.826 17.7118 27.3497 21.2704 24.7251 23.895C22.1004 26.5196 18.5419 27.9959 14.8301 28Z"
        fill="#00CC21"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9974 9.62248C19.1828 9.43464 19.4312 9.3307 19.689 9.33305C19.9468 9.3354 20.1934 9.44385 20.3757 9.63504C20.558 9.82624 20.6615 10.0849 20.6637 10.3553C20.6659 10.6256 20.5668 10.8861 20.3877 11.0806L20.3842 11.0844L13.4437 18.3632C13.3524 18.4591 13.2437 18.5355 13.1244 18.5875C13.0049 18.6395 12.8769 18.6663 12.7475 18.6663C12.4872 18.6663 12.2375 18.558 12.0532 18.3652L9.27306 15.4496C9.09394 15.2551 8.99487 14.9946 8.99711 14.7242C8.99935 14.4538 9.10276 14.1952 9.28507 14.004C9.46738 13.8128 9.714 13.7043 9.97181 13.702C10.2296 13.6996 10.478 13.8036 10.6635 13.9914L10.6671 13.9951L12.7475 16.1769L18.9974 9.62248Z"
        fill="#FDFDFD"
      />
    </svg>
  );
};

export default CheckIcon;
