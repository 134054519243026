import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { differenceInDays, format } from 'date-fns';
import {
  ButtonPrimary,
  ChevronIcon,
  CustomCircularIndicator,
  DeleteButton,
  DownloadButton,
  TextM,
} from '../../../../../app/components';
import {
  RequiredArea,
  RequiredAreaIcon,
  RequiredDocumentsList,
  RequiredDocumentsListItem,
  StepContent,
} from '../../../components';
import {
  StepContentButtonsArea,
  StepContentInputArea,
  TableBodyFile,
  TableFilesWrapper,
  TableHeadFile,
  UploadArea,
} from './styles';
import { NaturalPersonHandlerStepperContext } from '../NaturalPersonHandler';
import {
  TTableBodyContent,
  TTableHeadCell,
} from '../../../../../app/components';
import { Upload } from '../../../../../app/components';
import { useUploadPersonFiles } from '../../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from 'celcoin-design-system';
import { routingPath } from 'app/routes';
import { iconByFileType } from 'modules/customer/components/DocumentsModal/DocumentsModal';
import { usePersonContext } from 'modules/customer/context';
import {
  DOCUMENT_TYPE,
  PersonDocumentTypeLabel,
} from 'app/helpers/documentTypes';
import {
  EPersonFunctionProduct,
  EPersonFunctions,
} from 'modules/customer/context/PersonProvider/person.interfaces';
import { AttentionRequiredDocuments } from 'app/components/Icons';

type PersonDocumentTypeLabelType = typeof PersonDocumentTypeLabel;

const tableBodyFileStyle = {
  display: 'flex',
};

const tableHeadRaw = {
  id: '1',
  content: ['Arquivos', 'Tipo', 'Criado em', 'Baixar', 'Excluir'],
};

const PersonFunctionToProductVariables = {
  [EPersonFunctions.BORROWER]: EPersonFunctionProduct.BORROWER,
  [EPersonFunctions.CO_SIGNER]: EPersonFunctionProduct.CO_SIGNER,
  [EPersonFunctions.GUARANTOR]: EPersonFunctionProduct.GUARANTOR,
};

const DocumentsStep = () => {
  const theme = useTheme();
  const { createdPerson } = useContext(NaturalPersonHandlerStepperContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    state: { func, product },
  } = usePersonContext();
  const {
    handleUploadFiles,
    downloadPersonFile,
    handleDeletePersonFile,
    uploadedFiles,
    uploadedFilesLoading,
    deleteRequestLoading,
  } = useUploadPersonFiles();

  const [tableHeadContent, setTableHeadContent] = useState<
    TTableHeadCell[] | null
  >(null);
  const [tableBodyContent, setTableBodyContent] = useState<
    TTableBodyContent[] | null
  >(null);
  const [requiredDocuments, updateRequiredDocuments] = useState<
    DOCUMENT_TYPE[]
  >([]);

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    handleUploadFiles(acceptedFiles, createdPerson?.id || '');
  }, []);

  const handleRegister = () => {
    if (state?.applicationRequestFlow) {
      navigate(`/${routingPath.products.applicationRequest}`, {
        state: {
          ...state,
          borrower: createdPerson,
        },
      });
    } else {
      navigate('/records/natural');
    }
  };

  useEffect(() => {
    const personDocuments =
      product.variables_configuration?.[
        PersonFunctionToProductVariables[
          func.value as EPersonFunctions
        ] as EPersonFunctionProduct
      ].person_document;

    if (personDocuments)
      updateRequiredDocuments(
        personDocuments
          ?.filter((document) => {
            if (!document.required) return false;
            const hasUploaded = uploadedFiles.find(
              (uploadedFile) => uploadedFile.type === document.type,
            );
            return !hasUploaded;
          })
          .map((document) => document.type),
      );
  }, [product, func, uploadedFiles]);

  const renderCreationDate = (createdAt: string) => {
    const date = new Date(createdAt);
    const diffInDays = differenceInDays(new Date(), date);
    return (
      <TextM weight="Regular">
        {diffInDays > 2
          ? format(date, 'dd/MM/yyyy')
          : diffInDays === 0
          ? 'Hoje'
          : `${diffInDays} dia(s) atrás`}
      </TextM>
    );
  };

  useEffect(() => {
    const parsedHeadContent = tableHeadRaw.content.map((item, index) => {
      if (index === 0) {
        return {
          id: String(index),
          content: (
            <TableHeadFile>
              <TextM weight="Bold">Arquivo</TextM>
              <ChevronIcon />
            </TableHeadFile>
          ),
        };
      } else {
        return {
          id: String(index),
          content: <TextM weight="Bold">{item}</TextM>,
        };
      }
    });

    setTableHeadContent(parsedHeadContent);
  }, []);

  useEffect(() => {
    const parsedBodyContent = uploadedFiles.map((item, index) => {
      return {
        id: String(index),
        cells: [
          {
            id: '1',
            content: (
              <TableBodyFile style={tableBodyFileStyle}>
                <div>
                  {
                    iconByFileType[
                      item?.file_extension?.toLocaleLowerCase() || 'pdf'
                    ]
                  }
                </div>
                <TextM weight="Semibold">{item?.file_name}</TextM>
              </TableBodyFile>
            ),
          },
          {
            id: '2',
            content: <TextM weight="Regular">{item?.file_extension}</TextM>,
          },
          {
            id: '3',
            content: renderCreationDate(
              item?.created_at || new Date().toISOString(),
            ),
          },
          {
            id: '4',
            content: (
              <DownloadButton
                handleClick={() =>
                  downloadPersonFile(
                    createdPerson?.id || '',
                    item.id || '',
                    item,
                  )
                }
              />
            ),
          },
          {
            id: '5',
            content: (
              <DeleteButton
                handleClick={() => {
                  handleDeletePersonFile(
                    item.id || '',
                    createdPerson?.id || '',
                  );
                }}
              />
            ),
          },
        ],
      };
    });

    setTableBodyContent(parsedBodyContent);
  }, [uploadedFiles]);

  return (
    <StepContent
      title="Documentos"
      description="Envie documentos relacionados a esta pessoa (um por vez)."
    >
      <StepContentInputArea style={{ marginTop: '32px' }}>
        <TableFilesWrapper>
          <Table
            tableHeadCell={tableHeadContent}
            tableBodyContent={tableBodyContent}
          />
        </TableFilesWrapper>
        <UploadArea>
          <Upload handleDrop={handleDrop} loading={uploadedFilesLoading} />
        </UploadArea>
        {requiredDocuments.length > 0 && (
          <RequiredArea>
            <RequiredAreaIcon>
              <AttentionRequiredDocuments />
            </RequiredAreaIcon>
            <TextM weight="Bold">{`${requiredDocuments.length} documentos obrigatórios não foram enviados.`}</TextM>
            <RequiredDocumentsList>
              {requiredDocuments.map((document) => {
                return (
                  <RequiredDocumentsListItem key={document as string}>
                    {
                      PersonDocumentTypeLabel[
                        document as keyof PersonDocumentTypeLabelType
                      ]
                    }
                  </RequiredDocumentsListItem>
                );
              })}
            </RequiredDocumentsList>
          </RequiredArea>
        )}
      </StepContentInputArea>
      <StepContentButtonsArea style={{ marginTop: '50px' }}>
        {uploadedFilesLoading || deleteRequestLoading ? (
          <CustomCircularIndicator
            sx={{ color: theme.palette.brand.primary.base }}
          />
        ) : (
          <ButtonPrimary
            disabled={requiredDocuments.length > 0}
            onClick={handleRegister}
          >
            Concluir
          </ButtonPrimary>
        )}
      </StepContentButtonsArea>
    </StepContent>
  );
};

export default DocumentsStep;
