import { CSSProperties } from 'react';
import { ButtonPrimary } from '../ButtonPrimary';
import { TextButton } from '../typography';
import { TabsContainer } from './styles';

export interface ITabs {
  content: string[];
  selectedIndex: number;
  style?: CSSProperties;
  handleClick: (index: number) => void;
}

const Tabs = ({ content, selectedIndex, handleClick, style }: ITabs) => {
  const handleTabClick = (index: number) => {
    handleClick(index);
  };

  return (
    <TabsContainer>
      {content.length > 0 &&
        content.map((item, index) => (
          <ButtonPrimary
            typeVariant={selectedIndex === index ? 'fill' : 'ghost'}
            key={index}
            onClick={() => handleTabClick(index)}
            style={{
              padding: '16px',
              ...(style || {}),
            }}
          >
            <TextButton>{item}</TextButton>
          </ButtonPrimary>
        ))}
    </TabsContainer>
  );
};

export default Tabs;
