import Divider from '@mui/material/Divider';

const InfoDivider = () => {
  return (
    <Divider
      orientation="vertical"
      sx={{
        backgroundColor: '#E6E5EA',
        height: '21px',
        width: '2px',
        margin: '0px 24px',
      }}
    />
  );
};

export default InfoDivider;
