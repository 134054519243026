import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const MainPageContentContainer = styled.div<{ isLoading: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  ${(props) =>
    props.isLoading
      ? css`
      justify-content: center;
      align-items: center;
  `
      : ''}
`;
