import styled from '@emotion/styled';

export interface ContainerProps {
  complete?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .linear-progress-item {
    margin-top: 5px;
  }

  .MuiLinearProgress-root {
    background-color: ${({ theme }) => theme.palette?.brand.gray[300]}; ;
  }

  .MuiLinearProgress-bar {
    background-color: ${({ theme, complete }) =>
      complete ? '#00CC21' : theme.palette?.brand.primary.base};
  }
`;
