const JPEGIcon = ({ ...props }) => (
  <svg
    width="28"
    height="32"
    viewBox="0 0 28 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 0C4.9 0 4 0.9 4 2V30C4 31.1 4.9 32 6 32H26C27.1 32 28 31.1 28 30V8L20 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M22 8H28L20 0V6C20 7.1 20.9 8 22 8Z" fill="#B0B7BD" />
    <path d="M28 14L22 8H28V14Z" fill="#CAD1D8" />
    <path
      d="M24.1334 25.9334C24.1334 26.4834 23.6834 26.9334 23.1334 26.9334H1.13336C0.583362 26.9334 0.133362 26.4834 0.133362 25.9334V15.9333C0.133362 15.3833 0.583362 14.9333 1.13336 14.9333H23.1334C23.6834 14.9333 24.1334 15.3833 24.1334 15.9333V25.9334Z"
      fill="#17BEB6"
    />
    <path
      d="M23 27H4V28H23C23.55 28 24 27.55 24 27V26C24 26.55 23.55 27 23 27Z"
      fill="#CAD1D8"
    />
    <path
      d="M6.43115 23.1753C6.21648 23.1846 6.05315 23.1519 5.94115 23.0773C5.83382 23.0026 5.78015 22.8883 5.78015 22.7343C5.78015 22.5943 5.81515 22.4916 5.88515 22.4263C5.95515 22.3563 6.06482 22.3166 6.21415 22.3073L6.43815 22.2933C6.77415 22.2793 6.94215 22.0809 6.94215 21.6983V18.7023C6.94215 18.5389 6.99348 18.4083 7.09615 18.3103C7.19882 18.2123 7.33182 18.1633 7.49515 18.1633C7.65848 18.1633 7.78915 18.2123 7.88715 18.3103C7.98982 18.4083 8.04115 18.5389 8.04115 18.7023V21.6843C8.04115 22.6083 7.57915 23.1006 6.65515 23.1613L6.43115 23.1753Z"
      fill="white"
    />
    <path
      d="M9.56682 23.1753C9.39882 23.1753 9.26582 23.1263 9.16782 23.0283C9.06982 22.9303 9.02082 22.7973 9.02082 22.6293V18.7093C9.02082 18.5459 9.06516 18.4199 9.15382 18.3313C9.24249 18.2426 9.36849 18.1983 9.53182 18.1983H11.2888C11.8255 18.1983 12.2432 18.3336 12.5418 18.6043C12.8405 18.8749 12.9898 19.2529 12.9898 19.7383C12.9898 20.2236 12.8405 20.6016 12.5418 20.8723C12.2432 21.1429 11.8255 21.2783 11.2888 21.2783H10.1128V22.6293C10.1128 22.7973 10.0638 22.9303 9.96582 23.0283C9.86782 23.1263 9.73482 23.1753 9.56682 23.1753ZM11.1488 20.4523C11.6948 20.4523 11.9678 20.2166 11.9678 19.7453C11.9678 19.2693 11.6948 19.0313 11.1488 19.0313H10.1128V20.4523H11.1488Z"
      fill="white"
    />
    <path
      d="M17.5116 20.4103C17.6423 20.4103 17.7449 20.4476 17.8196 20.5223C17.8989 20.5923 17.9386 20.6903 17.9386 20.8163V22.1813C17.9386 22.3679 17.9223 22.5126 17.8896 22.6153C17.8616 22.7179 17.8103 22.7996 17.7356 22.8603C17.6609 22.9209 17.5489 22.9723 17.3996 23.0143C17.2176 23.0703 17.0076 23.1146 16.7696 23.1473C16.5363 23.1799 16.3146 23.1963 16.1046 23.1963C15.5819 23.1963 15.1293 23.0959 14.7466 22.8953C14.3639 22.6899 14.0699 22.3983 13.8646 22.0203C13.6593 21.6376 13.5566 21.1849 13.5566 20.6623C13.5566 20.1489 13.6569 19.7033 13.8576 19.3253C14.0629 18.9426 14.3546 18.6486 14.7326 18.4433C15.1106 18.2379 15.5516 18.1353 16.0556 18.1353C16.7043 18.1353 17.2409 18.3033 17.6656 18.6393C17.7356 18.6953 17.7846 18.7513 17.8126 18.8073C17.8406 18.8633 17.8546 18.9333 17.8546 19.0173C17.8546 19.1386 17.8196 19.2436 17.7496 19.3323C17.6843 19.4163 17.6049 19.4583 17.5116 19.4583C17.4509 19.4583 17.3949 19.4489 17.3436 19.4303C17.2969 19.4116 17.2386 19.3813 17.1686 19.3393C16.9726 19.2179 16.7929 19.1316 16.6296 19.0803C16.4663 19.0289 16.2866 19.0033 16.0906 19.0033C15.1386 19.0033 14.6626 19.5563 14.6626 20.6623C14.6626 21.2316 14.7863 21.6563 15.0336 21.9363C15.2809 22.2163 15.6543 22.3563 16.1536 22.3563C16.4243 22.3563 16.6926 22.3213 16.9586 22.2513V21.1663H16.3146C16.1793 21.1663 16.0743 21.1336 15.9996 21.0683C15.9249 21.0029 15.8876 20.9096 15.8876 20.7883C15.8876 20.6669 15.9249 20.5736 15.9996 20.5083C16.0743 20.4429 16.1793 20.4103 16.3146 20.4103H17.5116Z"
      fill="white"
    />
  </svg>
);

export default JPEGIcon;