import styled from '@emotion/styled';

export const ApplicationCustomVariablesTabPopOver = styled.div`
  padding: 7px;
`;

export const ApplicationCustomVariablesTableWrapper = styled.div`

  th:nth-of-type(5) {
    border-left: 1px solid #E6E5EA;
    max-width: 30px;

  }
  
  td:nth-of-type(5) {
    border-left: 1px solid #E6E5EA;
    max-width: 30px;
  }
`;
