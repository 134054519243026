import { TProducts } from '../../../products/context';
import { IPersonAction, IPersonState } from './person.interfaces';
import { personActionType } from './personActionType';

export const personInitialState: IPersonState = {
  func: {},
  product: {} as TProducts,
  registerData: {
    birth_date: '',
    email_address: '',
    full_name: '',
    nationality: '',
    occupation: '',
    pep: {
      label: '',
      value: '',
    },
    phone: {
      area_code: '',
      country_code: '',
      number: '',
    },
    taxpayer_id: '',
    address: {
      city: {
        label: '',
        value: '',
      },
      state_code: {
        label: '',
        value: '',
      },
      country_code: {
        label: 'Brasil',
        value: 'BRA',
      },
      district: '',
      extra_info: '',
      postal_code: '',
      street_name: '',
      street_number: '',
    },
  },
};

export const personReducer = (
  state: IPersonState = personInitialState,
  action: IPersonAction,
) => {
  switch (action.type) {
    case personActionType.SET_REGISTER_PERSON_DATA:
      return {
        ...state,
        registerData: {
          ...state.registerData,
          ...action.payload,
        },
      };
    case personActionType.SET_PRODUCT_DATA:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
        },
      };
    case personActionType.SET_FUNCTION_TYPE:
      return {
        ...state,
        func: {
          ...action.payload,
        },
      };
    case personActionType.RESET_STATE:
      return personInitialState;

    default:
      return state;
  }
};
