import styled from '@emotion/styled';

export const LegalPersonHandlerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-wrap: no-wrap;
`;

export const LegalPersonHandlerStepperContainer = styled.div`
  max-width: 25%;
`;

export const LegalPersonHandlerStepContainer = styled.div`
  width: 75%;
  padding-left: 30px;
`;
