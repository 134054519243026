import styled from '@emotion/styled';

export const FilterButtonContainer = styled.div`
  min-height: 50px;
  min-width: 103px;
  border-radius: 38px;
  border: 2px solid;
  border-color: ${({ theme }) => theme.palette?.brand.gray[300]} ;
  box-shadow: inset 0px 1px 0px -6px rgba(0, 0, 0, 0.04), inset 0px 4px 12px -4px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette?.brand.gray[50]};
  cursor: pointer;
`;
