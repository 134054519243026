import { forwardRef } from 'react';
import { Popover as PopoverMaterial, PopoverProps } from '@mui/material';
import { PopoverContainer } from './styles';

export interface IPopoverProps extends PopoverProps {
  children: JSX.Element;
}

const Popover = forwardRef<HTMLDivElement, IPopoverProps>(
  ({ children, ...props }, ref) => {
    return (
      <PopoverContainer>
        <PopoverMaterial {...props} ref={ref}>
          {children}
        </PopoverMaterial>
      </PopoverContainer>
    );
  },
);

Popover.displayName = 'Popover';

export default Popover;
