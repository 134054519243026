const InstallmentsUpIcon = ({ color }: { color: string }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99992 18.834C5.39992 18.834 1.66658 15.1007 1.66658 10.5007C1.66658 5.90065 5.39992 2.16732 9.99992 2.16732C14.5999 2.16732 18.3333 5.90065 18.3333 10.5007C18.3333 15.1007 14.5999 18.834 9.99992 18.834ZM9.16658 10.5007H6.66658L9.99992 13.834L13.3333 10.5007L10.8333 10.5007L10.8333 7.16732H9.16658V10.5007Z"
      fill={color}
    />
  </svg>
);

export default InstallmentsUpIcon;
