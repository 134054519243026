import { useCallback, useContext } from 'react';
import { ButtonPrimary, StepContent } from 'app/components';
import { UsersAccessHandlerStepperContext } from '../UsersAccessHandler';
import { UserPermissionsPicker } from 'modules/management/components';
import { UserValidPermissions } from 'modules/management/interfaces/usersAccess';

const PermissionsStep = () => {
  const { onForward, onBack, userAccessData, onChangeUserAccessData } =
    useContext(UsersAccessHandlerStepperContext);

  const onSelectAllPermissions = useCallback(() => {
    if (userAccessData.permissions?.length === UserValidPermissions.length) {
      onChangeUserAccessData({
        ...userAccessData,
        permissions: [],
      });
    } else {
      onChangeUserAccessData({
        ...userAccessData,
        permissions: UserValidPermissions,
      });
    }
  }, [userAccessData]);

  return (
    <StepContent
      title="Permissões"
      description="Defina quais permissões o novo usuário vai ter."
      action={
        <>
          <ButtonPrimary
            onClick={onSelectAllPermissions}
            style={{
              marginLeft: 'auto',
              width: 'auto',
              marginRight: '5px',
              paddingTop: '15px',
              display: 'block',
              border: 'none',
              fontSize: '18px',
            }}
            typeVariant="outline"
          >
            {userAccessData.permissions?.length === UserValidPermissions.length
              ? 'Desselecionar todas'
              : 'Selecionar todas'}
          </ButtonPrimary>
        </>
      }
    >
      <UserPermissionsPicker
        userAccess={userAccessData}
        updateUserAccess={onChangeUserAccessData}
        onBack={onBack}
        onConfirm={onForward}
        confirmButtonText="Avançar"
      />
    </StepContent>
  );
};

export default PermissionsStep;
