import { useBreadcrumbsContext } from 'app/context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { CessionsContentPage } from './components/CessionsContentPage';
import CessionIcon from 'app/components/Icons/CessionIcon';

const CessionsPage = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const { userInfo } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo?.permissions?.includes(EAccountPermissions.CNAB)) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setItems(
      [{ to: '/cession', label: 'Cessão' }],
      <CessionIcon color="rgb(66, 66, 66)" />,
    );

    return () => resetBreadcrumbs();
  }, []);

  return <CessionsContentPage />;
};

export default CessionsPage;
