import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { usePermify } from 'modules/auth/context';
import React from 'react';
import { NoAccess } from 'app/components';

export interface IHasProfileAccessProps {
  children?: React.ReactNode;
  permission: EAccountPermissions;
}

const HasProfileAccess = ({ children, permission }: IHasProfileAccessProps) => {
  const { isProfileAuthorized } = usePermify();

  if (!isProfileAuthorized(permission)) {
    return <NoAccess />;
  }

  return <>{children}</>;
};

export default HasProfileAccess;
