import { BoxSection, ButtonPrimary, Heading3 } from 'app/components';
import { UserPermissionsPicker } from 'modules/management/components';
import {
  UserAccess,
  UserValidPermissions,
} from 'modules/management/interfaces/usersAccess';
import { useState, useCallback } from 'react';
import { UserAccessPermissionsContainer } from './styles';
import { useAccessService } from 'modules/management/services/useAccessService';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { useAuthContext } from 'modules/auth/context';

interface IUsersAccessPermissionsTabProps {
  userAccess: UserAccess;
  updateUserAccess: (newUserAccess: UserAccess) => Promise<void>;
}

const UsersAccessPermissionsTab = ({
  userAccess,
}: IUsersAccessPermissionsTabProps) => {
  const { userInfo } = useAuthContext();
  const [newUserAccess, updateNewUserAccess] = useState(userAccess);
  const { updateUserAccess, isUpdateUserLoading } = useAccessService();
  const { showSnackbar } = useSnackbar();

  const onSavePermissions = useCallback(() => {
    updateUserAccess(
      newUserAccess.id,
      newUserAccess.permissions,
      newUserAccess.permission_properties ?? null,
    ).then(() => {
      showSnackbar('Dados salvos com suceso.', 'success');
    });
  }, [newUserAccess]);

  const onSelectAllPermissions = useCallback(() => {
    if (newUserAccess.permissions?.length === UserValidPermissions.length) {
      updateNewUserAccess({
        ...newUserAccess,
        permissions: [],
      });
    } else {
      updateNewUserAccess({
        ...newUserAccess,
        permissions: UserValidPermissions,
      });
    }
  }, [newUserAccess]);

  return (
    <BoxSection>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '70%',
        }}
      >
        <Heading3>Permissões</Heading3>
        <ButtonPrimary
          onClick={onSelectAllPermissions}
          style={{
            marginLeft: 'auto',
            width: 'auto',
            marginRight: '5px',
            paddingTop: '15px',
            display: 'block',
            border: 'none',
            fontSize: '18px',
          }}
          typeVariant="outline"
        >
          {newUserAccess.permissions?.length === UserValidPermissions.length
            ? 'Desselecionar todas'
            : 'Selecionar todas'}
        </ButtonPrimary>
      </div>
      <UserAccessPermissionsContainer>
        <UserPermissionsPicker
          isLoading={isUpdateUserLoading}
          userAccess={newUserAccess}
          isDisabled={userAccess.email === userInfo?.email}
          updateUserAccess={updateNewUserAccess}
          onConfirm={onSavePermissions}
          confirmButtonText="Salvar"
        />
      </UserAccessPermissionsContainer>
    </BoxSection>
  );
};

export default UsersAccessPermissionsTab;
