import { useState } from 'react';
import { DisbursementHttpService } from './api/disbursementHttpService';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { AxiosError, isAxiosError } from 'axios';
import {
  BankStatementResponseData,
  FundingAccount,
  GetMovementsProps,
  PaginatedResponse,
  RequestConciliationReportProps,
  TMoviments,
  WithdrawRequest,
  WithdrawStatusResponse,
} from '../interfaces/disbursement';
import { TFilterValues } from '../pages/BalancePage/components/BalancePageContent/components/Filters/types';
import { useAuthContext } from 'modules/auth/context';

export const useDisbursementService = () => {
  const [getBalanceLoading, setGetBalanceLoading] = useState(false);
  const [getAccountLoading, setGetAccountLoading] = useState(false);
  const [getMovementsLoading, setGetMovementsLoading] = useState(false);
  const [getBankStatementLoading, setBankStatementLoading] = useState(false);
  const [widthdrawLoading, setWidthdrawLoading] = useState(false);
  const [getWithdrawStatusLoading, setGetWithdrawStatusLoading] =
    useState(false);
  const [
    requestConciliationReportLoading,
    setRequestConciliationReportLoading,
  ] = useState(false);
  const [getBalanceError, setGetBalanceError] = useState(false);
  const { showSnackbar } = useSnackbar();
  const { userInfo } = useAuthContext();

  const getBalance = async (id?: string) => {
    setGetBalanceLoading(true);
    setGetBalanceError(false);
    try {
      const customerService = new DisbursementHttpService();
      customerService.setHeader({
        handled: true,
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const res = await customerService.getBalance(id || userInfo!.fundings[0]);
      return res;
    } catch (error) {
      if (
        isAxiosError(error) &&
        error.response?.data?.message === 'account not found'
      ) {
        setGetBalanceError(true);
        return;
      }

      showSnackbar('Ocorreu um erro ao buscar saldo');
    } finally {
      setGetBalanceLoading(false);
    }
  };

  const getAccount = async (
    id?: string,
  ): Promise<FundingAccount | undefined> => {
    setGetAccountLoading(true);
    try {
      const customerService = new DisbursementHttpService();
      customerService.setHeader({
        handled: true,
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const res = await customerService.getAccount(id || userInfo!.fundings[0]);
      return res;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar saldo');
    } finally {
      setGetAccountLoading(false);
    }
  };

  const withdraw = async (
    fundingId: string,
    params: WithdrawRequest,
  ): Promise<WithdrawRequest | { message: string } | unknown> => {
    setWidthdrawLoading(true);
    try {
      const customerService = new DisbursementHttpService();
      const res = await customerService.withdraw(fundingId, params);
      return res;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data) {
        throw error.response.data;
      }
      throw error;
    } finally {
      setWidthdrawLoading(false);
    }
  };

  const getWithdrawStatus = async (
    fundingId: string,
    identifier: string,
  ): Promise<WithdrawStatusResponse | undefined> => {
    setGetWithdrawStatusLoading(true);
    try {
      const customerService = new DisbursementHttpService();
      customerService.setHeader({
        handled: true,
      });
      const res = await customerService.getWithdrawStatus(
        fundingId,
        identifier,
      );
      return res;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data) {
        throw error.response.data;
      }
      throw error;
    } finally {
      setGetWithdrawStatusLoading(false);
    }
  };

  const generateMovementsFiltersString = (filters: TFilterValues) => {
    return Object.keys(filters).reduce((filtersToParse, filterKey) => {
      if (Array.isArray(filters[filterKey]))
        return `${filtersToParse}&${filterKey}=${(
          filters[filterKey] as string[]
        ).join(',')}`;
      return `${filtersToParse}&${filterKey}=${filters[filterKey] as string}`;
    }, '');
  };

  const getMovements = async ({
    id,
    dateFrom,
    dateTo,
    page,
    linesPerPage,
    filters,
  }: GetMovementsProps): Promise<TMoviments | undefined> => {
    setGetMovementsLoading(true);
    try {
      const disbursementService = new DisbursementHttpService();
      disbursementService.setHeader({
        handled: true,
      });

      const filtersString = generateMovementsFiltersString(filters);

      const customerService = new DisbursementHttpService();
      const res = await customerService.getMovements({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: id || userInfo!.fundings[0],
        dateFrom,
        dateTo,
        page,
        linesPerPage,
        filters: filtersString,
      });
      return res.data as TMoviments;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar o relatório de conciliação');
    } finally {
      setGetMovementsLoading(false);
    }
  };

  const requestConciliationReport = async ({
    fundingId,
    dateFrom,
    dateTo,
    columns,
    filters,
    email,
    format = 'csv',
    type = 'conciliation',
  }: RequestConciliationReportProps) => {
    try {
      setRequestConciliationReportLoading(true);
      const disbursementService = new DisbursementHttpService();
      const res = await disbursementService.requestConciliationReport({
        fundingId,
        dateFrom,
        dateTo,
        columns,
        filters,
        email,
        format,
        type,
      });

      return res.data;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao solicitar o relatório de conciliação');
      return undefined;
    } finally {
      new Promise((resolve) => {
        setTimeout(() => {
          setRequestConciliationReportLoading(false);
          resolve(true);
        }, 2000);
      });
    }
  };

  const getBankStatement = async ({
    id,
    dateFrom,
    dateTo,
    page,
    linesPerPage,
    filters,
  }: GetMovementsProps): Promise<
    PaginatedResponse<BankStatementResponseData[]> | undefined
  > => {
    try {
      const disbursementService = new DisbursementHttpService();
      disbursementService.setHeader({
        handled: true,
      });

      const filtersString = generateMovementsFiltersString(filters);

      setBankStatementLoading(true);
      const res = await disbursementService.getBankStatement({
        id: id || userInfo!.fundings[0],
        dateFrom,
        dateTo,
        page,
        linesPerPage,
        filters: filtersString,
      });

      return res.data as PaginatedResponse<BankStatementResponseData[]>;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar extrato');
    } finally {
      setBankStatementLoading(false);
    }
  };

  return {
    requestConciliationReport,
    getMovements,
    getBalance,
    getAccount,
    withdraw,
    getWithdrawStatus,
    getBankStatement,
    widthdrawLoading,
    getWithdrawStatusLoading,
    requestConciliationReportLoading,
    getAccountLoading,
    getMovementsLoading,
    getBalanceLoading,
    getBalanceError,
    getBankStatementLoading,
  };
};
