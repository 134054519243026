import React from 'react';

export interface IChevronDownIcon {
  height?: string;
  width?: string;
  color?: string;
}

const ChevronDownIcon = ({ color, ...props }: IChevronDownIcon) => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.00001 7.56266L15.6 0.962662L17.4853 2.84799L9.00001 11.3333L0.514679 2.84799L2.40001 0.962662L9.00001 7.56266Z"
      fill={color}
    />
  </svg>
);

export default ChevronDownIcon;
