import styled from '@emotion/styled';

export const ChargesSimulatorContainer = styled.div`
  position: relative;
  width: 80vw;
  height: 100vh;
  background-color: white;
  border: 2px solid #E6E5EA;
  border-radius: 20px;
  padding: 24px;
  max-width: 1400px;
  max-height: 1000px;
  @media screen and (max-width: 1920px) {
    width: 90vw;
    height: 105vh;
  }
  @media screen and (max-width: 1800px) {
    width: 100vw;
    height: 120vh;
  }
  @media screen and (max-width: 1706px) {
    width: 100vw;
    height: 120vh;
  }
  @media screen and (max-width: 1537px) {
    width: 110vw;
    min-height: 130vh;
  }
`;

export const ChargesSimulatorHeader = styled.div`
`;

export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-right: 24px;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      margin-top: 28px;
      margin-right: 28px;
  }
`;

export const ChargesSimulatorModal = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1400;
`;
