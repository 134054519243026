const CpfCnpjIcon = ({ color }: { color: string }) => (
  <svg
    width={28}
    height={24}
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 9C16 8.44771 16.4477 8 17 8H23.0004C23.5526 8 24.0004 8.44771 24.0004 9C24.0004 9.55229 23.5526 10 23.0004 10H17C16.4477 10 16 9.55229 16 9ZM17 13C16.4477 13 16 13.4477 16 14C16 14.5523 16.4477 15 17 15H23.0004C23.5526 15 24.0004 14.5523 24.0004 14C24.0004 13.4477 23.5526 13 23.0004 13H17ZM11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9ZM5 13.5C5 12.6716 5.67157 12 6.5 12H11.5C12.3284 12 13 12.6716 13 13.5C13 13.5 13 17 9 17C5 17 5 13.5 5 13.5ZM0 3.25C0 1.45508 1.45507 0 3.25 0L24.75 0C26.5449 0 28 1.45508 28 3.25L28 20.75C28 22.5449 26.5449 24 24.75 24L3.25 24C1.45507 24 0 22.5449 0 20.75L0 3.25ZM3.25 2C2.55964 2 2 2.55964 2 3.25L2 20.75C2 21.4404 2.55964 22 3.25 22L24.75 22C25.4404 22 26 21.4404 26 20.75L26 3.25C26 2.55964 25.4404 2 24.75 2L3.25 2Z"
      fill={color}
    />
  </svg>
);

export default CpfCnpjIcon;
