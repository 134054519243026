import styled from '@emotion/styled';

export const ApprovePermissionConfigContainer = styled.div`
  width: 100%;
  min-width: 600px;
  max-width: 600px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

export const ModalSection = styled.section`
  margin-top: 20px;
  strong {
    color: ${({ theme }) => theme.palette?.brand.primary.base};
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
    text-underline-offset: 3px;
  }

  .disabled {
    color: ${({ theme }) => theme.palette?.brand.gray[600]};
    cursor: default;
  }

`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 48px;
`;

export const SectionContent = styled.div`
  margin-top: 16px;

  ul {
    margin-left: 20px;
  }
`;

export const StepContentInput = styled.div<{
  marginTop?: string;
  isFull?: boolean;
}>`
  width: ${(props) => (props.isFull ? '100%' : '50%')};
  margin-top: ${(props) => props.marginTop || '0px'};
  padding: 15px;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;
