import styled from '@emotion/styled';

export const NaturalPersonViewPageSection = styled.div`
  margin-bottom: 50px;
`;

export const NaturalPersonViewBoxContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  display: flex;
  margin-top: 16px;
  min-height: 80px;
  align-items: center;
  padding: 20px 28px;
  border-radius: 10px;
`;

export const NaturalPersonViewBoxTableContainer = styled.div`
  margin-top: 16px;
`;
