import styled from '@emotion/styled';

export const MainTableWrapper = styled.div`
  .MuiPaper-root {
    border-radius: 18px;
    box-shadow: 0px -1px 0px -6px rgba(0, 0, 0, 0.04), 0px 4px 12px -4px rgba(0, 0, 0, 0.04);
    border: 1px solid #eee;
  }

  .MuiTableHead-root {
    th {
      padding: 32px 24px;
    }
  }

  .MuiTableCell-root {
    padding: 24px;
  }
`;

export const CellContent = styled.div`
  display: flex;
`;

export const IdArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;
