import { TRegisterBusinessDataRequest } from 'modules/customer/context';
import React, { useEffect } from 'react';
import { Divider } from '@mui/material';
import { TProducts } from 'modules/products/context';
import { TQualificationStatusOption } from 'modules/products/pages/interfaces';
import {
  TApplicationResponse,
  TQualificationRequestApplication,
} from 'modules/products/services/hooks/useApplicationsService';
import LegalPersonViewApplicationEmployersTab from './LegalPersonViewApplicationEmployersTab';
import LegalPersonViewApplicationBusinessTab from './LegalPersonViewApplicationBusinessTab';
import { ButtonApplicationViewContainer } from './styles';
import { ButtonPrimary, TextButton } from 'app/components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface ILegalPersonViewApplicationTabProps {
  business: TRegisterBusinessDataRequest;
}

export enum EApplicationViewType {
  BORROWER = 'borrower',
  EMPLOYEES = 'employees',
}

export type TApplicationData = {
  productData?: TProducts;
  statusApproval: TQualificationStatusOption[];
  qualificationData?: TQualificationRequestApplication;
} & TApplicationResponse;

const LegalPersonViewApplicationTab = ({
  business,
}: ILegalPersonViewApplicationTabProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const locationRouter = useLocation();

  useEffect(() => {
    if (!params.type) {
      navigate(`${locationRouter.pathname}/${EApplicationViewType.BORROWER}`);
    }
  }, []);
  return (
    <>
      <ButtonApplicationViewContainer>
        <div className="buttons">
          <ButtonPrimary
            typeVariant={
              params.type === EApplicationViewType.BORROWER ? 'fill' : 'ghost'
            }
            onClick={() =>
              navigate(
                `${locationRouter.pathname.replace(
                  EApplicationViewType.EMPLOYEES,
                  EApplicationViewType.BORROWER,
                )}`,
              )
            }
            style={{
              padding: '16px',
            }}
          >
            <TextButton>Empresa</TextButton>
          </ButtonPrimary>
          <ButtonPrimary
            typeVariant={
              params.type === EApplicationViewType.EMPLOYEES ? 'fill' : 'ghost'
            }
            onClick={() =>
              navigate(
                `${locationRouter.pathname.replace(
                  EApplicationViewType.BORROWER,
                  EApplicationViewType.EMPLOYEES,
                )}`,
              )
            }
            style={{
              padding: '16px',
            }}
          >
            <TextButton>Empregados</TextButton>
          </ButtonPrimary>
        </div>
        <Divider />
      </ButtonApplicationViewContainer>
      {params.type === EApplicationViewType.BORROWER && (
        <LegalPersonViewApplicationBusinessTab business={business} />
      )}
      {params.type === EApplicationViewType.EMPLOYEES && (
        <LegalPersonViewApplicationEmployersTab business={business} />
      )}
    </>
  );
};

export default LegalPersonViewApplicationTab;
