const CalendarIcon = ({ color, ...props }: { color: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={color}
      d="M2 20c-.55 0-1.021-.196-1.413-.588A1.922 1.922 0 0 1 0 18V4c0-.55.196-1.021.588-1.413A1.922 1.922 0 0 1 2 2h1V0h2v2h8V0h2v2h1c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v14c0 .55-.196 1.021-.588 1.413A1.922 1.922 0 0 1 16 20H2Zm0-2h14V8H2v10ZM2 6h14V4H2v2Zm7 6a.968.968 0 0 1-.713-.288A.964.964 0 0 1 8 11c0-.283.096-.521.288-.713A.964.964 0 0 1 9 10c.283 0 .521.096.713.288.192.192.288.43.287.712a.968.968 0 0 1-.288.713A.964.964 0 0 1 9 12Zm-4 0a.968.968 0 0 1-.713-.288A.964.964 0 0 1 4 11c0-.283.096-.521.288-.713A.964.964 0 0 1 5 10c.283 0 .521.096.713.288.192.192.288.43.287.712a.968.968 0 0 1-.288.713A.964.964 0 0 1 5 12Zm8 0a.968.968 0 0 1-.713-.288A.964.964 0 0 1 12 11c0-.283.096-.521.288-.713A.964.964 0 0 1 13 10c.283 0 .521.096.713.288.192.192.288.43.287.712a.968.968 0 0 1-.288.713A.964.964 0 0 1 13 12Zm-4 4a.968.968 0 0 1-.713-.288A.964.964 0 0 1 8 15c0-.283.096-.521.288-.713A.964.964 0 0 1 9 14c.283 0 .521.096.713.288.192.192.288.43.287.712a.968.968 0 0 1-.288.713A.964.964 0 0 1 9 16Zm-4 0a.968.968 0 0 1-.713-.288A.964.964 0 0 1 4 15c0-.283.096-.521.288-.713A.964.964 0 0 1 5 14c.283 0 .521.096.713.288.192.192.288.43.287.712a.968.968 0 0 1-.288.713A.964.964 0 0 1 5 16Zm8 0a.968.968 0 0 1-.713-.288A.964.964 0 0 1 12 15c0-.283.096-.521.288-.713A.964.964 0 0 1 13 14c.283 0 .521.096.713.288.192.192.288.43.287.712a.968.968 0 0 1-.288.713A.964.964 0 0 1 13 16Z"
    />
  </svg>
);
export default CalendarIcon;
