import React, { ButtonHTMLAttributes } from 'react';
import { ButtonPrimaryContainer } from './styles';

export type ButtonPrimaryTypeVariant = 'outline' | 'fill' | 'ghost';
export type ButtonPrimaryIconPosition = 'left' | 'right';

export interface IButtonPrimaryProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  roundType?: 'circular' | 'normal';
  typeVariant?: ButtonPrimaryTypeVariant;
  btnIcon?: React.ReactNode;
  iconPosition?: ButtonPrimaryIconPosition;
}

const ButtonPrimary = ({
  children,
  roundType,
  typeVariant = 'fill',
  iconPosition = 'right',
  btnIcon,
  ...props
}: IButtonPrimaryProps) => {
  return (
    <ButtonPrimaryContainer
      {...props}
      typeVariant={typeVariant}
      style={{
        ...props.style,
        borderRadius:
          props.style?.borderRadius || roundType === 'circular'
            ? '80px'
            : '10px',
      }}
    >
      {btnIcon && iconPosition === 'left' && btnIcon}
      {children}
      {btnIcon && iconPosition === 'right' && btnIcon}
    </ButtonPrimaryContainer>
  );
};

export default ButtonPrimary;
