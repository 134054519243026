import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { useState, useContext, useMemo } from 'react';
import {
  EViewBoxColumnType,
  EditWrapper,
  Heading3,
  ViewBox,
} from '../../../../../../app/components';
import { TRegisterPersonDataRequest } from '../../../../context';
import LegalPersonAboutSectionEdit from './NaturalPersonEditAboutSection';
import { NaturalPersonViewPageSection } from './styles';
import { NaturalPersonViewPageContext } from '../../NaturalPersonViewPage';
import { useTheme } from '@mui/material';
import { TViewBoxColumn } from 'app/components/ViewBox/ViewBox';
import {
  MaritialStatusObject,
  SexObject,
} from 'modules/customer/context/PersonProvider/person.constants';

type INaturalPersonViewAboutSectionProps = {
  personData: TRegisterPersonDataRequest;
};

const NaturalPersonViewAboutSection = ({
  personData,
}: INaturalPersonViewAboutSectionProps) => {
  const theme = useTheme();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { employer } = useContext(NaturalPersonViewPageContext);

  const viewBoxColumns = useMemo(() => {
    const columns: TViewBoxColumn[] = [
      {
        label: 'Nome',
        path: 'full_name',
      },
      {
        label: 'E-mail',
        path: 'email_address',
      },
      {
        label: 'Data de nascimento',
        path: 'birth_date',
        type: EViewBoxColumnType.DATE,
      },
      {
        label: 'CPF',
        path: 'taxpayer_id',
        type: EViewBoxColumnType.CPF,
      },
      {
        label: 'Celular',
        path: 'phone',
        type: EViewBoxColumnType.PHONE,
      },
      {
        label: 'Sexo',
        path: 'sex',
        enum: SexObject,
        type: EViewBoxColumnType.ENUM,
      },
      {
        label: 'Estado civil',
        path: 'marital_status',
        enum: MaritialStatusObject,
        type: EViewBoxColumnType.ENUM,
      },
      {
        label: 'Nome da mãe',
        path: 'mothers_name',
      },
      {
        label: 'Ocupação',
        path: 'occupation',
      },
      {
        label: 'Naturalidade',
        path: 'birthplace',
      },
      {
        label: 'Nacionalidade',
        path: 'nationality',
      },
      {
        label: 'Pessoa politicamente exposta',
        path: 'pep',
        type: EViewBoxColumnType.BOOLEAN,
      },
      {
        label: 'Renda mensal',
        path: 'monthly_income_range',
        type: EViewBoxColumnType.CURRENCY,
      },
      {
        label: 'Cadastrado em',
        path: 'created_at',
        type: EViewBoxColumnType.DATE,
      },
    ];

    if (typeof personData.employer?.id === 'string') {
      columns.push({
        label: 'Empregador',
        path: 'employer.legal_name',
        type: EViewBoxColumnType.EXTERNAL,
        url: `/records/legal/${personData?.employer?.id || ''}/details`,
      } as TViewBoxColumn);
    }

    return columns;
  }, [personData]);

  return (
    <>
      <NaturalPersonViewPageSection>
        <Heading3
          id="about"
          style={{ color: theme.palette.brand.secondary.base as string }}
        >
          Sobre
        </Heading3>
        <EditWrapper
          handleClick={() => setIsEditOpen(true)}
          permission={EAccountPermissions.UPDATE_PERSON_BUSINESS}
        >
          <ViewBox
            values={
              {
                ...personData,
                employer: employer ?? {},
              } as unknown as {
                [key: string]: string | number;
              }
            }
            textAlign="right"
            columns={viewBoxColumns}
          />
        </EditWrapper>
      </NaturalPersonViewPageSection>
      <LegalPersonAboutSectionEdit
        isOpen={isEditOpen}
        handleClose={() => setIsEditOpen(false)}
        personData={personData}
      />
    </>
  );
};

export default NaturalPersonViewAboutSection;
