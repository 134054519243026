const CopyIcon = ({ color, ...props }: { color: string }) => (
  <svg
    width={19}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5 4V1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H1.507A1 1 0 0 1 .5 19L.503 5c0-.552.45-1 1.007-1H4.5ZM2.503 6 2.5 18h10V6H2.503ZM6.5 4h8v10h2V2h-10v2Z"
      fill={color}
    />
  </svg>
)

export default CopyIcon
