
const CalculatorIco = ({color, ...props}: { color: string }) => (
  <svg
    width={22}
    height={26}
    fill="none"
    {...props}
  >
    <path
      d="M4.5 6.5a2.6 2.6 0 0 1 2.6-2.6h7.8a2.6 2.6 0 0 1 2.6 2.6v1.3a2.6 2.6 0 0 1-2.6 2.6H7.1a2.6 2.6 0 0 1-2.6-2.6V6.5Zm2.6-.65a.65.65 0 0 0-.65.65v1.3c0 .359.29.65.65.65h7.8a.65.65 0 0 0 .65-.65V6.5a.65.65 0 0 0-.65-.65H7.1Zm-.975 10.4a1.625 1.625 0 1 0 0-3.25 1.625 1.625 0 0 0 0 3.25Zm1.625 3.575a1.625 1.625 0 1 1-3.25 0 1.625 1.625 0 0 1 3.25 0Zm8.125-3.575a1.625 1.625 0 1 0 0-3.25 1.625 1.625 0 0 0 0 3.25Zm1.625 3.575a1.625 1.625 0 1 1-3.25 0 1.625 1.625 0 0 1 3.25 0ZM11 16.25A1.625 1.625 0 1 0 11 13a1.625 1.625 0 0 0 0 3.25Zm1.625 3.575a1.625 1.625 0 1 1-3.25 0 1.625 1.625 0 0 1 3.25 0ZM4.825 0A4.225 4.225 0 0 0 .6 4.225v17.55A4.225 4.225 0 0 0 4.825 26h12.35a4.225 4.225 0 0 0 4.225-4.225V4.225A4.225 4.225 0 0 0 17.175 0H4.825ZM2.55 4.225A2.275 2.275 0 0 1 4.825 1.95h12.35a2.275 2.275 0 0 1 2.275 2.275v17.55a2.275 2.275 0 0 1-2.275 2.275H4.825a2.275 2.275 0 0 1-2.275-2.275V4.225Z"
      fill={color}
    />
  </svg>
)

export default CalculatorIco
