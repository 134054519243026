import { useEffect } from 'react';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import {
  useBreadcrumbsContext,
  useHeaderContext,
} from '../../../../app/context';
import { LegalPersonListPageContent } from './components/LegalPersonListPageContent';
import { LegalPersonListPageContainer } from './styles';

const LegalPersonListPage = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const { setTitle, resetTitle } = useHeaderContext();

  useEffect(() => {
    setItems(
      [
        { to: '/records/legal', label: 'Cadastros' },
        { to: '/records/legal', label: 'Empresas' },
      ],
      <GroupAddOutlinedIcon />,
    );
    return resetBreadcrumbs;
  }, []);

  useEffect(() => {
    setTitle('Empresas');
    return resetTitle;
  }, []);

  return (
    <LegalPersonListPageContainer>
      <LegalPersonListPageContent />
    </LegalPersonListPageContainer>
  );
};

export default LegalPersonListPage;
