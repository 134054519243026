import { BaseHttpService } from 'app/services';
import {
  TCessionApplicationsSummary,
  TCessionConfigResponse,
  TCessionIssuerInfo,
  TCessionResponse,
} from 'modules/cession/interfaces/cession';
import { IList } from 'modules/products/services/hooks/intefaces';

export class CessionHttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  async getCessionRequestsList(
    fundingId: string,
  ): Promise<IList<TCessionResponse>> {
    const res = await this.get(
      `${this.BASE_URL}/originator/cession/get-cession?i_funding=${fundingId}`,
    );
    return res.data;
  }

  async getCessionConfiguration(fundings: string[]): Promise<boolean> {
    const requests = fundings.map(
      (funding) =>
        `${this.BASE_URL}/originator/cession/cession-configurations?i_funding=${funding}`,
    );
    const res = await Promise.all(
      requests.map(async (request) => this.get(request)),
    );
    return (
      res.filter((r) => {
        if (r.data) {
          const config = r.data as TCessionConfigResponse;
          return config.active;
        }
        return false;
      }).length > 0
    );
  }

  async getCessionApplicationSummary(
    ids: string[],
  ): Promise<TCessionApplicationsSummary> {
    const res = await this.post(
      `${this.BASE_URL}/originator/cession/summary-value`,
      ids,
    );
    return res.data;
  }

  async getCessionIssuerInfo(issuerId: string): Promise<TCessionIssuerInfo> {
    const res = await this.get(
      `${this.BASE_URL}/originator/cession/issuer-info/${issuerId}`,
    );
    return res.data;
  }

  async getCession(
    id: string,
    fundingId: string,
  ): Promise<IList<TCessionResponse>> {
    const res = await this.get(
      `${this.BASE_URL}/originator/cession/get-cession?i_funding=${fundingId}&i_cession=${id}`,
    );
    return res.data;
  }

  async getCessionFile(fundingId: string, cessionId: string): Promise<string> {
    const res = await this.get(
      `${this.BASE_URL}/originator/cession/signed-cession?i_funding=${fundingId}&i_cession=${cessionId}`,
    );
    return res.data;
  }
}
