import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .close-btn {
      cursor: pointer;
    }
  }

  .body {
    max-width: 700px;
    max-height: 500px;

    .loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      margin-top: 30px;
      
      p {
        margin-top: 30px;
      }
    }

    .input {
      margin-top: 20px;
    }

    .btn {
      margin-top: 40px;
    }

    .warning {
      display: flex;
      margin-top: 30px;
      gap: 12px;
    }
    
    .dates {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

  }
`;
