/* eslint-disable */

export function extractReceiptDataFromTemplate(str: string, pattern: string) {
  const delimiters = ['{{', '}}'];

  str = obfuscateNewLine(str);
  pattern = obfuscateNewLine(pattern);

  const specialCharsRegex = /[\\\^\$\*\+\.\?\(\)\[\]|\:]/g;
  const allTokensMatcher = getAllTokensMatcher(delimiters);
  const getTokenRegex = new RegExp(allTokensMatcher, 'g');
  const tokens = pattern.match(getTokenRegex);
  const patternRegex = new RegExp(
    pattern
      .replace(specialCharsRegex, '\\$&')
      .replace(getTokenRegex, '([\\s|\\S]*)'),
  );

  let matches = str.match(patternRegex) || [];

  if (!matches) {
    return null;
  }

  if (!tokens) {
    throw new Error('No tokens found in pattern');
  }

  matches = matches.slice(1) as any;

  const output: { [key: string]: string } = {};

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i]
      .replace(new RegExp(delimiters[0] + '|' + delimiters[1], 'g'), '')
      .trim();
    output[token] = unobfuscateNewLine((matches[i] || '').trim());
  }

  return output;
}

function obfuscateNewLine(input: string) {
  return input.split('\n').join('þ');
}

function unobfuscateNewLine(input: string) {
  return input.split('þ').join('\n');
}

function getAllTokensMatcher(delims: string[]) {
  return delims[0] + '([^' + delims.join('') + ']+)' + delims[1];
}

export const receiptTemplateForExtractData =
  '\n    COMPROVANTE TRANSFERENCIA INTERNA\n             {{date}}             \n                CONTROLE                 \n    {{identifier}}     \n---------------------------------------- \n                PAGADOR                  \n    \nCPF/CNPJ: {{debitAccountTaxpayerId}}\n{{debitAccountHolderName}}\nBANCO: {{debitAccountBank}} AG: {{debitAccountBranch}} CC: {{debitAccountAccountNumber}}\n---------------------------------------- \n                RECEBEDOR                 \n    \nCPF/CNPJ:{{creditAccountTaxpayerId}}\n{{creditAccountHolderName}}\nBANCO: {{creditAccountBank}} AG: {{creditAccountBranch}} CC: {{creditAccountAccountNumber}}\n\n---------------------------------------- \n    DATA DO PAGAMENTO {{paymentDate}}    \n    \n        VALOR R${{value}}            \n----------------------------------------\n\n========================================';
