import styled from '@emotion/styled';

export type TTypeVariant = 'fill' | 'outline' | 'ghost';

export interface IButtonPrimaryContainer {
  typeVariant: TTypeVariant;
}

export const ButtonPrimaryContainer = styled.button<IButtonPrimaryContainer>`
  outline: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'default !important' : 'pointer')};
  background-color: ${({ theme, typeVariant }) => {
    switch (typeVariant) {
      case 'fill':
        return theme.palette?.brand.primary.base;
      case 'outline':
        return '#fff';
      case 'ghost':
        return 'transparent';
      default:
        return theme.palette?.brand.primary.base;
    }
  }};
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: ${({ theme, typeVariant }) => {
    switch (typeVariant) {
      case 'fill':
        return theme.palette?.brand.neutral.base;
      case 'outline':
        return theme.palette?.brand.primary.base;
      default:
        return theme.palette?.brand.primary.base;
    }
  }};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  max-height: 45px;
  border: ${({ typeVariant }) => {
    switch (typeVariant) {
      case 'fill':
        return 0;
      case 'outline':
        return `2px solid  #E6E5EA`;
      default:
        return 0;
    }
  }}
`;
