import styled from '@emotion/styled';

export const StepContentInput = styled.div<{
  marginTop?: string;
  isFull?: boolean;
}>`
  width: ${(props) => (props.isFull ? '100%' : '50%')};
  margin-top: ${(props) => props.marginTop || '0px'};
  padding: 15px;
`;

export const StepContentInputArea = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;
