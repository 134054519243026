import React, { createContext, useContext, useReducer } from 'react';
import { IPersonContext } from './person.interfaces';
import { personInitialState, personReducer } from './personReducer';

export const PersonContext = createContext<IPersonContext | undefined>(
  undefined,
);

export interface IPersonProviderProps {
  children?: React.ReactNode;
}

export const PersonProvider = ({ children }: IPersonProviderProps) => {
  const [state, dispatch] = useReducer(personReducer, personInitialState);

  const value = { state, dispatch };

  return (
    <PersonContext.Provider value={value}>{children}</PersonContext.Provider>
  );
};

export const usePersonContext = () => {
  const context = useContext(PersonContext);

  if (context === undefined) {
    throw new Error('usePersonContext must be used within a PersonProvider');
  }
  return context;
};
