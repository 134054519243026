import { useCallback, useContext, useState } from 'react';
import { ButtonPrimary, ConfirmModal, StepContent } from 'app/components';
import {
  InfoStepExistingBusiness,
  StepContentButtonsArea,
  StepContentInput,
  StepContentInputArea,
} from './styles';
import AttentionCircularIcon from 'app/components/Icons/AttentionCircularIcon';
import { routingPath } from 'app/routes';
import { CustomInput } from 'celcoin-design-system';
import { useFormik } from 'formik';
import { infoStepSchema } from 'modules/customer/helpers/usersAccessValidation';
import { formatCPF, formatPhone, isValidCPF } from 'app/helpers';
import { UserAccess } from 'modules/management/interfaces/usersAccess';
import { UsersAccessHandlerStepperContext } from '../UsersAccessHandler';
import { useAccessService } from 'modules/management/services/useAccessService';

export const getFormatedPhoneNumber = (user?: UserAccess | null) => {
  if (user) {
    const phoneNumber = user?.phone || '';
    if (!phoneNumber) return null;
    return formatPhone(phoneNumber);
  }
  return null;
};

const InfoStep = () => {
  const { onForward, userAccessData, onChangeUserAccessData } = useContext(
    UsersAccessHandlerStepperContext,
  );
  const { existsUserAccessCheck, getUserAccessList } = useAccessService();
  const [
    isExistingUserAccessModalVisible,
    toggleExistingUserAccessModalVisible,
  ] = useState(false);
  const [
    isExistingUserAccessEmailModalVisible,
    toggleExistingUserAccessEmailModalVisible,
  ] = useState(false);
  const [existsUserAccess, updateExistsUserAccess] =
    useState<UserAccess | null>(null);
  const [existsUserAccessEmail, updateExistsUserAccessEmail] =
    useState<UserAccess | null>(null);
  const [isCheckingUserAccess, updateIsCheckingUserAccess] = useState(false);
  const [isCheckingUserAccessEmail, updateIsCheckingUserAccessEmail] =
    useState(false);

  const onChangeTaxpayerId = useCallback(
    async (taxpayerId: string): Promise<boolean> => {
      try {
        if (!isValidCPF(taxpayerId)) return false;
        updateIsCheckingUserAccess(true);
        const exists = await existsUserAccessCheck({
          taxpayerId,
        });
        if (exists) {
          const userList = await getUserAccessList();
          const userAccess = userList.filter(
            (u) => u.taxpayer_id === taxpayerId,
          );
          updateExistsUserAccess(userAccess[0]);
          toggleExistingUserAccessModalVisible(true);
          return true;
        }
        updateExistsUserAccess(null);
        return false;
      } catch {
        return false;
      } finally {
        updateIsCheckingUserAccess(false);
      }
    },
    [],
  );

  const onChangeEmail = useCallback(
    async (taxpayerId: string, email: string): Promise<boolean> => {
      try {
        updateIsCheckingUserAccessEmail(true);
        const exists = await existsUserAccessCheck({
          taxpayerId,
          email,
        });
        if (exists) {
          const userList = await getUserAccessList();
          const userAccess = userList.filter((u) => u.email === email);
          updateExistsUserAccessEmail(userAccess[0]);
          toggleExistingUserAccessEmailModalVisible(true);
          return true;
        }
        updateExistsUserAccessEmail(null);
        return false;
      } catch {
        return false;
      } finally {
        updateIsCheckingUserAccessEmail(false);
      }
    },
    [],
  );

  const getFormatedPhoneFromExistingUserAccess = useCallback(
    () => getFormatedPhoneNumber(existsUserAccess),
    [existsUserAccess],
  );

  const formik = useFormik({
    initialValues: {
      full_name: userAccessData?.full_name,
      taxpayer_id: userAccessData?.taxpayer_id || '',
      phone: userAccessData?.phone || '',
      email: userAccessData?.email || '',
    },
    validationSchema: infoStepSchema,
    onSubmit: (values) => {
      onChangeUserAccessData({
        ...values,
        taxpayer_id: values.taxpayer_id?.replace(/\D+/g, ''),
        phone: values.phone?.replace(/\D+/g, ''),
      });
      onForward();
    },
  });
  return (
    <StepContent
      title="Identificação"
      description="Informe os dados do usuário que deseja criar."
    >
      <StepContentInputArea>
        <StepContentInput>
          <CustomInput
            name="taxpayer_id"
            id="taxpayer_id"
            isLoading={isCheckingUserAccess}
            value={formatCPF(formik.values.taxpayer_id)}
            handleInputChange={(e) => {
              formik.handleChange(e);
              const taxpayerId = e?.currentTarget?.value;
              if (taxpayerId.length >= 14) {
                onChangeTaxpayerId(taxpayerId.replace(/\D+/g, ''));
              }
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              const taxpayerId = e?.currentTarget?.value;
              if (taxpayerId.length >= 14) {
                onChangeTaxpayerId(taxpayerId.replace(/\D+/g, ''));
              }
            }}
            placeholder="000.000.000-00"
            labelValue="CPF"
            validationError={
              formik.touched.taxpayer_id ? formik.errors.taxpayer_id : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="full_name"
            value={formik.values.full_name}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Digite o nome do novo usuário"
            labelValue="Nome"
            validationError={
              formik.touched.full_name ? formik.errors.full_name : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="phone"
            value={formatPhone(formik.values.phone)}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="(00) 00000-0000"
            labelValue="Telefone"
            validationError={formik.touched.phone ? formik.errors.phone : ''}
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            id="email"
            name="email"
            value={formik.values.email}
            isLoading={isCheckingUserAccessEmail}
            handleInputChange={formik.handleChange}
            onBlur={(e) => {
              formik.handleBlur(e);
              const { taxpayer_id: taxpayerId } = formik.values;
              const email = e?.currentTarget?.value;
              onChangeEmail(taxpayerId.replace(/\D+/g, ''), email);
            }}
            placeholder="exemplo@email.com"
            labelValue="E-mail"
            validationError={formik.touched.email ? formik.errors.email : ''}
          />
        </StepContentInput>
      </StepContentInputArea>
      <ConfirmModal
        isColumnButtons={true}
        isOpen={isExistingUserAccessModalVisible}
        icon={<AttentionCircularIcon />}
        title="O CPF informado já está cadastrado"
        btnConfirmText="Limpar e informar outro CPF"
        handleConfirm={() => {
          formik.setFieldValue('taxpayer_id', '');
          formik.setFieldTouched('taxpayer_id', false);
          updateExistsUserAccess(null);
          toggleExistingUserAccessModalVisible(false);
          setTimeout(() => {
            document.querySelector<HTMLInputElement>('#taxpayer_id')?.focus();
          });
        }}
        handleClose={() => toggleExistingUserAccessModalVisible(false)}
        handleCancel={() => toggleExistingUserAccessModalVisible(false)}
      >
        <InfoStepExistingBusiness>
          <span style={{ marginBottom: '8px' }}>
            <strong>{formatCPF(existsUserAccess?.taxpayer_id || '')}</strong>
          </span>
          <span>{existsUserAccess?.full_name}</span>
          {getFormatedPhoneFromExistingUserAccess() && (
            <span>{getFormatedPhoneFromExistingUserAccess()}</span>
          )}
          <span>{existsUserAccess?.email}</span>
          <span>...</span>
          <a
            href={`#${routingPath.management.access}/${existsUserAccess?.id}/details`}
            target="_blank"
            className="link"
            rel="noreferrer"
          >
            Abrir usuário completo em uma nova aba
          </a>
        </InfoStepExistingBusiness>
      </ConfirmModal>
      <ConfirmModal
        isColumnButtons={true}
        isOpen={isExistingUserAccessEmailModalVisible}
        icon={<AttentionCircularIcon />}
        title="O e-mail informado já está cadastrado"
        btnConfirmText="Limpar e informar outro e-mail"
        handleConfirm={() => {
          formik.setFieldValue('email', '');
          formik.setFieldTouched('email', false);
          updateExistsUserAccessEmail(null);
          toggleExistingUserAccessEmailModalVisible(false);
          setTimeout(() => {
            document.querySelector<HTMLInputElement>('#email')?.focus();
          });
        }}
        handleClose={() => toggleExistingUserAccessEmailModalVisible(false)}
        handleCancel={() => toggleExistingUserAccessEmailModalVisible(false)}
      >
        <InfoStepExistingBusiness>
          <span style={{ marginBottom: '8px' }}>
            <strong>{existsUserAccessEmail?.email || ''}</strong>
          </span>
          <span>{existsUserAccessEmail?.full_name}</span>
          {getFormatedPhoneFromExistingUserAccess() && (
            <span>{getFormatedPhoneFromExistingUserAccess()}</span>
          )}
          <span>...</span>
          <a
            href={`#${routingPath.management.access}/${existsUserAccessEmail?.id}/details`}
            target="_blank"
            className="link"
            rel="noreferrer"
          >
            Abrir usuário completo em uma nova aba
          </a>
        </InfoStepExistingBusiness>
      </ConfirmModal>
      <StepContentButtonsArea>
        <ButtonPrimary
          onClick={() => {
            if (existsUserAccess !== null) {
              toggleExistingUserAccessModalVisible(true);
            } else if (existsUserAccessEmail !== null) {
              toggleExistingUserAccessEmailModalVisible(true);
            } else {
              formik.submitForm();
            }
          }}
        >
          Avançar
        </ButtonPrimary>
      </StepContentButtonsArea>
    </StepContent>
  );
};

export default InfoStep;
