const ChargesSimulatorRemoveIcon = ({ color }: { color: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 11H5V13H19V11Z" fill={color} />
  </svg>
);

export default ChargesSimulatorRemoveIcon;
