import styled from '@emotion/styled';

export const NaturalPersonListPageContainer = styled.div`
  width: 100%;
  height: 100%;
  border-top: 1px solid #DBD6FE;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      padding: 20px;
  };
`;

export const HeaderArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      flex-direction: column;
  }
`;

export const FilterArea = styled.div`
  display: flex;
  margin-top: 44px;
  width: 100%;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      flex-direction: column;

  }
`;

export const TableArea = styled.div`
  margin-top: 48px;
  position: relative;
`;

export const ButtonArea = styled.div`
  min-width: 200px;
  display: flex;
  margin-left: 20px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      margin-top: 20px;
  }
`;

export const MenuDropdownContent = styled.div`
  position: relative;
  p {
    cursor: pointer;
  }
`;
