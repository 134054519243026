import React from 'react';
import { AuthContainer } from '../../components';
import { TwoFactorAuthForm } from './components';

const TwoFactorAuth = () => {
  return (
    <AuthContainer>
      <TwoFactorAuthForm />
    </AuthContainer>
  );
};

export default TwoFactorAuth;
