import styled from '@emotion/styled';

export const ApplicationScheduleTabContainer = styled.div`
  width: 100%;
  padding: 0px 48px 80px;
`;

export const TableArea = styled.div`
  margin-top: 40px;
`;
