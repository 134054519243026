import { Divider, DrawerProps, useTheme } from '@mui/material';
import { BaseSidesheet } from 'celcoin-design-system';
import { CloseIcon, Heading3 } from '../../../../app/components';
import { useWindowSize } from '../../../../app/hooks';
import {
  ProductSideSheetContent,
  CloseIconWrapper,
  SideSheetHeader,
} from './styles';

export interface IProductSideSheet extends DrawerProps {
  handleClose: () => void;
  title: string;
  subtitleItem?: JSX.Element | null;
  customHeader?: JSX.Element | null;
  isBigger?: boolean;
}

const ProductSideSheet = ({
  title,
  handleClose,
  open,
  children,
  subtitleItem,
  customHeader,
  isBigger,
}: IProductSideSheet) => {
  const theme = useTheme();
  const { width } = useWindowSize();
  return (
    <BaseSidesheet hideBackdrop open={open} handleClose={handleClose}>
      {customHeader ? (
        customHeader
      ) : (
        <>
          <SideSheetHeader>
            <div>
              <Heading3
                style={
                  !isBigger
                    ? {
                        maxWidth: '500px',
                      }
                    : {}
                }
              >
                {title}
              </Heading3>
              {subtitleItem && subtitleItem}
            </div>
            <CloseIconWrapper onClick={handleClose}>
              <CloseIcon color={theme.palette.brand.secondary.base as string} />
            </CloseIconWrapper>
          </SideSheetHeader>
          <Divider />
        </>
      )}
      <ProductSideSheetContent
        isBigger={isBigger}
        style={{
          width:
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            width! > theme.breakpoints.values.tablet
              ? theme.spacing(75)
              : theme.spacing(50),
        }}
      >
        {children}
      </ProductSideSheetContent>
    </BaseSidesheet>
  );
};

export default ProductSideSheet;
