import { useEffect, useState, useCallback } from 'react';
import { TextM } from 'celcoin-design-system';
import { CustomTabs } from 'app/components/CustomTabs';
import { Box, useTheme } from '@mui/material';
import { TRegisterBusinessDataRequest } from '../../../../context';
import { ButtonPrimary, Heading3 } from '../../../../../../app/components';
import {
  LegalPersonViewPageContentContainer,
  LegalPersonViewPageContentSection,
  LegalPersonViewPageContentHeader,
  LegalPersonViewPageContentTabsArea,
  LegalPersonViewPageContentHeaderButtons,
} from './styles';
import {
  AdditionalFields,
  CopyIcon,
  HandCoinIcon,
  HyperlinkIcon,
  LegalRelationIcon,
  SearchIcon,
} from 'app/components/Icons';
import { LegalPersonViewDetailsTab } from '../LegalPersonViewDetailsTab';
import { LegalPersonViewCustomVariablesTab } from '../LegalPersonViewCustomVariablesTab';
import { LegalPersonRepresentatives } from '../LegalPersonRepresentatives';
import { LegalPersonViewApplicationTab } from '../LegalPersonViewApplicationTab';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { routingPath } from 'app/routes';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { LegalPersonViewQualificationsTab } from '../LegalPersonViewQualificationsTab';
import { AccountBalanceOutlined, Person, Rule } from '@mui/icons-material';
import { LegalPersonViewKycTab } from '../LegalPersonViewKycTab';
import { LegalPersonViewScrTab } from '../LegalPersonViewScrTab';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';

type ILegalPersonViewPageContentProps = {
  business: TRegisterBusinessDataRequest;
};

const LegalPersonViewPageContent = ({
  business,
}: ILegalPersonViewPageContentProps) => {
  const [currentTabValue, setCurrentTabValue] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [removeBusinessConfirmVisible, toggleRemoveBusinessConfirmVisible] =
    useState(false);
  const { showSnackbarCopyLink, showSnackbarCopy } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const locationRouter = useLocation();
  const theme = useTheme();
  const { isProfileAuthorized } = usePermify();

  const tabItems = [
    {
      title: 'Cadastro',
      icon: <Person style={{ marginRight: '-5px' }} />,
      route: '/details',
    },
    {
      title: 'Campos adicionais',
      icon: (
        <AdditionalFields color={theme.palette.brand.primary.base as string} />
      ),
      route: '/custom-variables',
    },
    {
      title: 'Representantes legais',
      icon: (
        <LegalRelationIcon color={theme.palette.brand.primary.base as string} />
      ),
      route: '/signers',
    },
    {
      title: 'Solicitações',
      icon: <HandCoinIcon color={theme.palette.brand.primary.base as string} />,
      route: '/applications',
    },
    {
      title: 'SCR',
      icon: (
        <AccountBalanceOutlined
          style={{ color: theme.palette.brand.primary.base as string }}
        />
      ),
      isEnabled: isProfileAuthorized(EAccountPermissions.READ_SCR),
      route: '/scr',
    },
    {
      title: 'KYC',
      icon: <SearchIcon color={theme.palette.brand.primary.base as string} />,
      route: '/kyc',
      isEnabled: isProfileAuthorized(EAccountPermissions.READ_KYC),
    },
    {
      title: 'Linhas de Crédito',
      icon: <Rule style={{ marginRight: '-5px' }} />,
      route: '/qualifications',
    },
  ];

  const handleCopyId = useCallback(() => {
    navigator.clipboard.writeText(business.id as string);
    showSnackbarCopy();
  }, [business]);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(location.href);
    showSnackbarCopyLink();
  }, [business, location]);

  useEffect(() => {
    if (!params.tabRoute) {
      navigate(`${locationRouter.pathname}/details`);
    }
  }, []);

  const legalPersonViewPageContentValue = (route: string) => {
    const tabItemsContent: { [key: string]: JSX.Element } = {
      ['details']: <LegalPersonViewDetailsTab business={business} />,
      ['custom-variables']: (
        <LegalPersonViewCustomVariablesTab business={business} />
      ),
      ['signers']: <LegalPersonRepresentatives business={business} />,
      ['applications']: <LegalPersonViewApplicationTab business={business} />,
      ['scr']: <LegalPersonViewScrTab business={business} />,
      ['kyc']: <LegalPersonViewKycTab business={business} />,
      ['qualifications']: (
        <LegalPersonViewQualificationsTab business={business} />
      ),
    };
    return tabItemsContent[route];
  };

  return (
    <LegalPersonViewPageContentContainer>
      <LegalPersonViewPageContentSection>
        <LegalPersonViewPageContentHeader>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Heading3 fontWeight="Sb">{business.legal_name}</Heading3>
            <LegalPersonViewPageContentHeaderButtons>
              <ButtonPrimary
                style={{ backgroundColor: 'transparent' }}
                typeVariant="outline"
                btnIcon={
                  <CopyIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                }
                iconPosition="left"
                onClick={handleCopyId}
              >
                <TextM
                  weight="Semibold"
                  style={{
                    marginLeft: '11px',
                    color: theme.palette.brand.primary.base,
                  }}
                >
                  Copiar ID
                </TextM>
              </ButtonPrimary>
              <ButtonPrimary
                style={{ backgroundColor: 'transparent' }}
                typeVariant="outline"
                btnIcon={
                  <HyperlinkIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                }
                iconPosition="left"
                onClick={handleCopyLink}
              >
                <TextM
                  weight="Semibold"
                  style={{
                    marginLeft: '11px',
                    color: theme.palette.brand.primary.base,
                  }}
                >
                  Copiar Link
                </TextM>
              </ButtonPrimary>
            </LegalPersonViewPageContentHeaderButtons>
          </div>
          <LegalPersonViewPageContentTabsArea>
            <CustomTabs
              value={currentTabValue}
              route={routingPath.records.legalView}
              items={tabItems.filter(
                (tab) => typeof tab.isEnabled === 'undefined' || tab.isEnabled,
              )}
              handleChange={(value) => setCurrentTabValue(value)}
              showBorder={false}
              variant="scrollable"
            />
          </LegalPersonViewPageContentTabsArea>
        </LegalPersonViewPageContentHeader>
        <Box>
          {legalPersonViewPageContentValue(params.tabRoute || 'details')}
        </Box>
      </LegalPersonViewPageContentSection>
    </LegalPersonViewPageContentContainer>
  );
};

export default LegalPersonViewPageContent;
