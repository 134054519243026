import styled from '@emotion/styled';

export const UsersAccessHandlerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-wrap: no-wrap;
`;

export const UsersAccessHandlerStepperContainer = styled.div`
  max-width: 25%;

  .back-button {
    margin-bottom: 40px;
    padding: 0px;
  }
`;

export const UsersAccessHandlerStepContainer = styled.div`
  width: 75%;
  margin-left: auto;
`;
