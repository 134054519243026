const DocumentIcon = ({ color, ...props }: { color: string }) => (
  <svg
    width="37"
    height="40"
    viewBox="0 0 37 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36.5 12V37.986C36.5018 38.2486 36.4519 38.5091 36.3531 38.7524C36.2543 38.9958 36.1085 39.2173 35.9241 39.4043C35.7397 39.5913 35.5203 39.7402 35.2783 39.8424C35.0364 39.9446 34.7766 39.9982 34.514 40H2.486C1.95963 40 1.45478 39.791 1.08239 39.419C0.710003 39.047 0.50053 38.5424 0.5 38.016V1.984C0.5 0.91 1.398 0 2.504 0H24.494L36.5 12ZM32.5 14H22.5V4H4.5V36H32.5V14ZM10.5 10H16.5V14H10.5V10ZM10.5 18H26.5V22H10.5V18ZM10.5 26H26.5V30H10.5V26Z"
      fill={color}
    />
  </svg>
);

export default DocumentIcon;
