import { useEffect, useState } from 'react';
import {
  CloseButton,
  ConfirmButton,
  DocumentTypeSelectBox,
  BorrowerTypeModalContainer,
  BorrowerTypeModalContent,
  BorrowerTypeModalFooter,
  BorrowerTypeModalHeader,
} from './styles';
import { BaseModal, CustomSelect, Heading4 } from 'celcoin-design-system';
import { CloseIcon } from 'app/components';
import { useTheme } from '@mui/material';
import { TBorrowerType } from '../../types';
import { EFunctions } from 'modules/customer/context/CustomerProvider/customer.interfaces';
import { Rule } from '@mui/icons-material';

export type IBorrowerTypeModalProps = {
  isOpen: boolean;
  handleConfirm: (_borrowerType: TBorrowerType) => void;
  handleClose: () => void;
};

const BorrowerTypeModal = ({
  isOpen,
  handleConfirm,
  handleClose,
}: IBorrowerTypeModalProps) => {
  const theme = useTheme();
  const [borrowerType, updateBorrowerType] = useState<TBorrowerType>();

  useEffect(() => {
    updateBorrowerType(undefined);
  }, [isOpen]);

  return (
    <BaseModal open={isOpen} onClose={handleClose}>
      <BorrowerTypeModalContainer>
        <BorrowerTypeModalHeader>
          <CloseButton onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </CloseButton>
        </BorrowerTypeModalHeader>
        <BorrowerTypeModalContent>
          <Rule
            style={{
              width: 52,
              height: 52,
            }}
          />
          <Heading4
            style={{
              color: theme.palette.brand.secondary.base,
              paddingBottom: '16px',
              paddingTop: '20px',
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
            fontWeight="B"
          >
            {'Selecione a função para a linha de crédito'}
          </Heading4>
          <DocumentTypeSelectBox>
            <CustomSelect
              name="documentType"
              placeholder="Selecionar o tipo"
              value={borrowerType}
              handleInputChange={(e) =>
                updateBorrowerType(e.currentTarget.value)
              }
              options={[
                { value: EFunctions.BORROWER, label: 'Tomador' },
                { value: EFunctions.EMPLOYER, label: 'Empregador' },
              ]}
              validationError={undefined}
            />
          </DocumentTypeSelectBox>
        </BorrowerTypeModalContent>
        <BorrowerTypeModalFooter isColumnButtons={true}>
          <ConfirmButton
            onClick={() => borrowerType && handleConfirm(borrowerType)}
            disabled={!borrowerType}
            color="primary"
          >
            Confirmar
          </ConfirmButton>
        </BorrowerTypeModalFooter>
      </BorrowerTypeModalContainer>
    </BaseModal>
  );
};

export default BorrowerTypeModal;
