const DetailsIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.255 20.5C8.833 19.896 8.505 19.222 8.29 18.5H2V2.5H6H12H16V10.5H18V1.492C18 0.944 17.553 0.5 17.001 0.5H13H6H0.999C0.447 0.5 0 0.944 0 1.492V19.508C0.00209465 19.7706 0.107418 20.0219 0.293218 20.2075C0.479017 20.3931 0.730378 20.4982 0.993 20.5H9.255ZM4 4.5H13V6.5H4V4.5ZM4 8.5H13V10.5H4V8.5ZM7 12.5H4V14.5H7V12.5ZM19.95 20.036L18.446 18.532C18.9405 17.6934 19.1122 16.7031 18.9288 15.747C18.7454 14.7908 18.2196 13.9344 17.4499 13.3381C16.6802 12.7419 15.7195 12.4469 14.7479 12.5083C13.7763 12.5698 12.8604 12.9835 12.1719 13.6719C11.4835 14.3604 11.0698 15.2763 11.0083 16.2479C10.9469 17.2195 11.2419 18.1802 11.8381 18.9499C12.4344 19.7196 13.2908 20.2454 14.247 20.4288C15.2031 20.6122 16.1934 20.4405 17.032 19.946L18.536 21.45L19.95 20.036ZM16.4142 17.9142C16.0392 18.2893 15.5304 18.5 15 18.5C14.4696 18.5 13.9609 18.2893 13.5858 17.9142C13.2107 17.5392 13 17.0304 13 16.5C13 15.9696 13.2107 15.4609 13.5858 15.0858C13.9609 14.7107 14.4696 14.5 15 14.5C15.5304 14.5 16.0392 14.7107 16.4142 15.0858C16.7893 15.4609 17 15.9696 17 16.5C17 17.0304 16.7893 17.5392 16.4142 17.9142Z"
        fill={color}
      />
    </svg>
  );
};

export default DetailsIcon;
