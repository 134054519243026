import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 40px;
  position: relative;
`;

export const EmptyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 72px 0px;
`;

export const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 71px;

  .title {
    display: flex;

    h3 {
      margin-right: 16px;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-radius: 10px;
`;

export const ButtonApplicationViewContainer = styled.div`
  width: 100%;
  padding: 32px 48px 0px;
  border-left: 1px solid #DBD6FE;
  border-right: 1px solid #DBD6FE;

  .buttons {
    margin-bottom: 32px;
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    width: fit-content;
    padding: 5px;
    button {
      width: fit-content;
    }
  }
`;

export const TableArea = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 48px;
`;

export const ArrowArrowContainer = styled.div`
  cursor: pointer;
`;

export const TableWrapper = styled.div`

  th:nth-of-type(8) {
    border-left: 1px solid #E6E5EA;
    max-width: 50px;
  }

  td:nth-of-type(8) {
    border-left: 1px solid #E6E5EA;
    max-width: 50px;
  }
`;

export const TableEmployersWrapper = styled.div`

  th:nth-of-type(9) {
    border-left: 1px solid #E6E5EA;
    max-width: 60px;
  }

  td:nth-of-type(9) {
    border-left: 1px solid #E6E5EA;
    max-width: 60px;
  }
`;
