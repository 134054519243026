const ChargesSimulatorClockIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_382_403984)">
      <path
        d="M10.0001 18.3327C5.39758 18.3327 1.66675 14.6018 1.66675 9.99935C1.66675 5.39685 5.39758 1.66602 10.0001 1.66602C14.6026 1.66602 18.3334 5.39685 18.3334 9.99935C18.3334 14.6018 14.6026 18.3327 10.0001 18.3327ZM10.0001 16.666C11.7682 16.666 13.4639 15.9636 14.7141 14.7134C15.9644 13.4632 16.6667 11.7675 16.6667 9.99935C16.6667 8.23124 15.9644 6.53555 14.7141 5.2853C13.4639 4.03506 11.7682 3.33268 10.0001 3.33268C8.23197 3.33268 6.53628 4.03506 5.28604 5.2853C4.03579 6.53555 3.33341 8.23124 3.33341 9.99935C3.33341 11.7675 4.03579 13.4632 5.28604 14.7134C6.53628 15.9636 8.23197 16.666 10.0001 16.666ZM10.8334 9.99935H14.1667V11.666H9.16675V5.83268H10.8334V9.99935Z"
        fill="#9390A3"
      />
    </g>
    <defs>
      <clipPath id="clip0_382_403984">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ChargesSimulatorClockIcon;
