import styled from '@emotion/styled';

export const SideSheetHeader = styled.div`
  margin: 20px 0px 0px;
  padding: 0px 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);
  svg {
    cursor: pointer;
  }
`;

export const CloseIconWrapper = styled.div`
  display: flex;
`;

export const UserViewBox = styled.div`
  margin-bottom: 32px;
`;

export const UserSideSheetContent = styled.div`
  width: 600px;
  padding: 48px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TabsArea = styled.div`
  margin-bottom: -2px;
`;

export const ButtonArea = styled.div`
  display: flex;
  flex: 1;
  justify-self: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;
`;

export const UserViewBoxContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  display: flex;
  margin-top: 16px;
  min-height: 80px;
  align-items: center;
  padding: 20px 28px;
  border-radius: 10px;
  width: 100%;
  cursor: ${({ onClick }) =>
    typeof onClick === 'function' ? 'pointer' : 'default'};

  &:hover {
    span {
      text-decoration: ${({ onClick }) =>
        typeof onClick === 'function' ? 'underline' : 'none'};
    }
  }
`;

export const UserViewBoxContent = styled.div`
  width: 100%;
`;

export const UserViewBoxIcon = styled.div`
  padding-right: 19px;
  svg {
    font-size: 30px;
  }
`;

export const LogoutButton = styled.button`
    background: none;
    border: 2px solid;
    border-radius: 10px;
    border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
    color: ${({ theme }) => theme.palette?.brand?.primary.base};
	  padding: 0;
    cursor: pointer;
	  outline: none;
    min-width: 211px;
    margin-top: auto;
    min-height: 52px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 20px;
    transition: background-color .1s;

    &:hover {
        background-color: ${({ theme }) => theme.palette?.brand?.gray[100]};
    }

    svg {
      margin-left: -15px;
      font-size: 15px;
    }

    span {
      padding-left: 10px;
    }

`;
