import styled from '@emotion/styled';

export const ArrowArrowContainer = styled.div`
  cursor: pointer;
`;

export const Content = styled.div`
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 24px;
`;

export const TableWrapper = styled.div`
  margin-top: 20px;

  th:nth-of-type(7) {
    border-left: 1px solid #E6E5EA;
    max-width: 50px;
  }

  td:nth-of-type(7) {
    border-left: 1px solid #E6E5EA;
    max-width: 50px;
  }
`;
