import styled from '@emotion/styled';

export const ChargesSimulatorSection = styled.div`
  padding: 24px 0px;
`;

export const ChargesSimulatorSectionContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  padding: 16px 0px 0px;
`;

export const ChargesSimulatorSectionContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ChargesSimulatorSectionContent = styled.div`
  background-color: #f7f7f7;
  padding: 24px;
  width: 100%;
  border-radius: 10px;
`;

export const ChargesSimulatorSwitcher = styled.div<{
  columns: number;
  gap?: number;
}>`
  display: flex;
  padding: 24px 0px;
  flex-wrap: wrap;
  gap: ${({ gap }) => gap ?? 40}px;

  .column {
    width: calc(${({ columns }) => 100 / columns}% - ${({ gap }) =>
  (gap ?? 40) / 2}px);
  }
`;
