import { useAppContext } from 'app/context/AppContextProvider';
import React, {
  createContext,
  useEffect,
  useContext,
  useCallback,
  useState,
} from 'react';
import { AuthHttpService } from '../../services/authHttpService';
import auth, { AuthService, User, UserInfo } from '../../services/authService';
import { IAuthContext } from './auth.interfaces';
import Cookies from 'js-cookie';
import { TOKEN_COOKIE } from 'modules/auth/constants/cookies';

const AUTH_POOL_ID = process.env.REACT_APP_USER_POOL_ID as string;
const AUTH_CLIENT_ID = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID as string;

export const AuthContext = createContext<IAuthContext>({
  authLoading: true,
  isAuthLoading: false,
  user: undefined,
  userInfo: undefined,
  isLogged: false,
  temporaryPassword: null,
  login: () => {
    throw new Error('Método não implementado');
  },
  logout: () => {
    throw new Error('Método não implementado');
  },
  requestFirstAccess: () => {
    throw new Error('Método não implementado');
  },
  validateResetToken: () => {
    throw new Error('Método não implementado');
  },
  completeAccess: () => {
    throw new Error('Método não implementado');
  },
  requestResetPassword: () => {
    throw new Error('Método não implementado');
  },
});

export interface IAuthProviderProps {
  children?: React.ReactNode;
}

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState<User | undefined>();
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>();
  const [authLoading, setAuthLoading] = useState(true);
  const [isAuthLoading, toggleIsAuthLoading] = useState(false);
  const { app_id } = useAppContext();
  const [temporaryPassword, setTemporaryPassword] = useState<string | null>(
    null,
  );

  useEffect(() => {
    AuthService.init(AUTH_POOL_ID, AUTH_CLIENT_ID);
    auth
      .currentAuthenticatedUser()
      .then((u) => {
        if (typeof u !== 'undefined') {
          setUser(u);
          const accessToken = u.getSignInUserSession()?.getAccessToken();
          if (accessToken)
            Cookies.set(TOKEN_COOKIE, accessToken.getJwtToken(), {
              sameSite: 'strict',
              secure: true,
            });
          const authHttpService = new AuthHttpService();
          authHttpService
            .getUser()
            .then((ui) => {
              setUserInfo({
                ...ui.data,
                fundings: Array.isArray(ui.data.funding_id)
                  ? ui.data.funding_id
                  : [ui.data.funding_id],
              });
              setAuthLoading(false);
            })
            .catch(() => setAuthLoading(false));
        } else {
          setAuthLoading(false);
        }
      })
      .catch(() => setAuthLoading(false));
  }, []);

  const onRequestResetPassword = useCallback(
    (email: string): Promise<boolean> => {
      const authHttpService = new AuthHttpService();
      toggleIsAuthLoading(true);
      return authHttpService
        .requestResetPassword(email, app_id)
        .then(() => true)
        .catch(() => false)
        .finally(() => toggleIsAuthLoading(false));
    },
    [app_id],
  );

  const onRequestValidateResetToken = useCallback(
    (token: string): Promise<boolean> => {
      const authHttpService = new AuthHttpService();
      return authHttpService
        .getResetTokenInfo(token)
        .then((activationTokenResponse) => {
          setTemporaryPassword(activationTokenResponse.data.temporaryPassword);
          return true;
        })
        .catch(() => false);
    },
    [],
  );

  const onCompleteAccess = useCallback(
    (username: string, password: string): Promise<boolean> => {
      if (typeof temporaryPassword === 'string') {
        toggleIsAuthLoading(true);
        return auth
          .completeNewPassword(
            `${app_id}:${username}`,
            temporaryPassword,
            password,
          )
          .then(() => true)
          .catch(() => false)
          .finally(() => toggleIsAuthLoading(false));
      }
      return Promise.resolve(false);
    },
    [temporaryPassword, app_id],
  );

  const onRequestFirstAccess = useCallback(
    (token: string): Promise<boolean> => {
      const authHttpService = new AuthHttpService();
      return authHttpService
        .getActivationTokenInfo(token)
        .then((activationTokenResponse) => {
          setTemporaryPassword(activationTokenResponse.data.temporaryPassword);
          return true;
        })
        .catch(() => false);
    },
    [],
  );

  const onLoginRequest = useCallback(
    (username: string, password: string): Promise<boolean> => {
      toggleIsAuthLoading(true);
      return auth
        .signIn(`${app_id}:${username}`, password)
        .then((u) => {
          setUser(u);
          const accessToken = u.getSignInUserSession()?.getAccessToken();
          if (accessToken)
            Cookies.set(TOKEN_COOKIE, accessToken.getJwtToken(), {
              sameSite: 'strict',
              secure: true,
            });
          const authHttpService = new AuthHttpService();
          authHttpService
            .getUser()
            .then((ui) => {
              setUserInfo({
                ...ui.data,
                fundings: Array.isArray(ui.data.funding_id)
                  ? ui.data.funding_id
                  : [ui.data.funding_id],
              });
              toggleIsAuthLoading(false);
            })
            .catch(() => toggleIsAuthLoading(false));
          return true;
        })
        .catch(() => {
          setUser(undefined);
          toggleIsAuthLoading(false);
          return false;
        });
    },
    [app_id],
  );

  const onLogoutRequest = useCallback((): Promise<boolean> => {
    if (typeof user !== 'undefined') {
      return auth
        .signOut()
        .then(() => {
          setUser(undefined);
          Cookies.remove(TOKEN_COOKIE);
          return true;
        })
        .catch(() => {
          return false;
        });
    }
    return Promise.resolve(false);
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        authLoading,
        isAuthLoading,
        user,
        userInfo,
        temporaryPassword,
        isLogged: !!user,
        login: onLoginRequest,
        logout: onLogoutRequest,
        validateResetToken: onRequestValidateResetToken,
        requestResetPassword: onRequestResetPassword,
        requestFirstAccess: onRequestFirstAccess,
        completeAccess: onCompleteAccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
};
