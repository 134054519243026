import { Divider, useTheme } from '@mui/material';
import { SearchFilterInput } from './SearchFilterInput';
import { SearchFilterSelect } from './SearchFilterSelect';
import { ButtonArea, SearchFilterContainer } from './styles';
import { useState } from 'react';
import { MagnifyingGassIcon } from '../Icons';

const CustomDivider = () => {
  return (
    <div style={{ margin: '0px 12px' }}>
      <Divider sx={{ height: '100%', width: '3px' }} orientation="vertical" />
    </div>
  );
};

export type TFilterItems = {
  name: string;
  parameterName: string;
};

export interface ISearchFilterProps {
  filterItems: TFilterItems[];
  inputValue: string;
  selectedFilter?: string | undefined;
  handleInputChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearInput: () => void;
  handleSelectFilterItem: (filterSelectedValue: TFilterItems) => void;
  handleSearch: () => void;
}

const SearchFilter = ({
  inputValue,
  filterItems,
  selectedFilter,
  handleInputChange,
  handleClearInput,
  handleSelectFilterItem,
  handleSearch,
}: ISearchFilterProps) => {
  const theme = useTheme();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const handleClose = () => {
    setIsSelectOpen(false);
  };

  const handleSelectedFilter = (selectElementValue: TFilterItems) => {
    handleSelectFilterItem(selectElementValue);
  };

  const handleOpen = () => {
    setIsSelectOpen(true);
  };

  return (
    <SearchFilterContainer>
      <SearchFilterSelect
        handleClose={handleClose}
        handleOpen={handleOpen}
        isOpen={isSelectOpen}
        selectedElement={selectedFilter}
        filterItems={filterItems}
        handleSelectFilter={(selectElementValue) =>
          handleSelectedFilter(selectElementValue)
        }
      />
      <CustomDivider />
      <SearchFilterInput
        value={inputValue}
        onPressEnter={handleSearch}
        handleInputChange={(evt) => handleInputChange(evt)}
        handleClearInput={handleClearInput}
        searchItemName={selectedFilter || filterItems?.[0].name}
      />
      <CustomDivider />
      <ButtonArea onClick={handleSearch}>
        <MagnifyingGassIcon
          color={theme.palette.brand.primary.base as string}
        />
      </ButtonArea>
    </SearchFilterContainer>
  );
};

export default SearchFilter;
