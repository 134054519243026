import styled from '@emotion/styled';

export const PopperArrow = styled.span`
  position: absolute;
  font-size: 7px;
  left: 0;
  top: calc(50% - 15px) !important;
  margin-left: -16px;
  height: 30px;
  width: 30px;
  border: solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  border-width: 0 1px 1px 0;
  display: inline-block;
  background-color: white;
  padding: 3px;

  transform: rotate(135deg) !important;
  -webkit-transform: rotate(135deg) !important;
`;

export const PopperContainer = styled.div`
  margin-left: 36px;
  position: relative;
  box-shadow: 0px 12px 30px 5px rgba(0,0,0,0.1);
  border-radius: 10px;
`;

export const PopperContent = styled.div`
  padding: 24px;
`;
