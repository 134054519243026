import styled from '@emotion/styled';

export const PersonRelationTableWrapper = styled.div`

  .MuiPaper-root {
      border-radius: 18px;
      min-height: 584px;
  }
  margin-top: 32px;
`;

export const CellHeadContent = styled.div`
  display: flex;
`;

export const SpinnerArea = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
