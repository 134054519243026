import styled from '@emotion/styled';

export const SummaryContainer = styled.div`
  position: fixed;
  right: -290px;
  top: 15%;
  background-color: ${({ theme }) => theme.palette?.common?.white};
  width: 100%;
  max-width: 290px;
  box-shadow: 0px -1px 0px -6px rgba(0, 0, 0, 0.04), 0px 4px 12px -4px rgba(0, 0, 0, 0.04);
  border: 1px solid #DBD6FE;
  transition: right 0.7s ease-in-out;

  &.open {
    right: 0px;
  }
`;

export const SummaryButton = styled.button`
    position: absolute;
    left: -40px;
    border: none;
    top: 0px;
    border: 1px solid #DBD6FE;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -1px 0px -6px rgba(0, 0, 0, 0.04), 0px 4px 12px -4px rgba(0, 0, 0, 0.04);
    cursor: pointer;
    span {
      transform: rotate(270deg);
      display: block;
      font-size: 16px;
      letter-spacing: 2px;
    }
    color: ${({ theme }) => theme.palette?.brand?.primary.base};
    height: 100%;
    font-weight: bold;
    background-color: ${({ theme }) => theme.palette?.brand.background.base};
`;

export const SummaryList = styled.ul`
  padding-left: 5px;
  margin: 24px 0px 24px 15px;
  position: relative;
`;

export const SummaryItem = styled.li`
  font-size: 16px;
  margin-bottom: 8px;
  list-style-type: none;
  white-space: pre;
  color: ${({ theme }) => theme.palette?.brand?.gray[700]};
  transition: color 0.2s ease-in-out;
  a {
    color: ${({ theme }) => theme.palette?.brand?.gray[700]};
    text-decoration: none;
    cursor: pointer;
  }
  &:hover {
    color: ${({ theme }) => theme.palette?.brand?.primary.base};
    a {
      color: ${({ theme }) => theme.palette?.brand?.primary.base};
    }
  }
`;
