import { usePermify } from 'modules/auth/context';
import { HTMLAttributes } from 'react';
import { EditIcon } from '../Icons';
import { EditWrapperContainer, EditWrapperButton } from './styles';
import { useTheme } from '@mui/material';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';

interface IEditWrapper extends HTMLAttributes<HTMLDivElement> {
  handleClick?: () => void;
  children: React.ReactNode;
  permission?: EAccountPermissions;
}

const EditWrapper = ({
  handleClick,
  permission,
  children,
  ...props
}: IEditWrapper) => {
  const { isProfileAuthorized } = usePermify();
  const theme = useTheme();

  return (
    <EditWrapperContainer {...props} style={{ ...props.style }}>
      {children}
      {(!permission || isProfileAuthorized(permission)) && (
        <EditWrapperButton onClick={handleClick}>
          <EditIcon color={theme.palette.brand.primary.base as string} />
        </EditWrapperButton>
      )}
    </EditWrapperContainer>
  );
};

export default EditWrapper;
