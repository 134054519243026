import { useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { TextS } from '../typography/body';
import { BreadcrumbsItem, BreadcrumbsSeparator, ItemWrapper } from './styles';

export interface IBreadcrumbProps {
  to: string;
  label: string;
}

const BreadcrumbsList = ({
  separator,
  children,
  mainIco,
}: {
  children: React.ReactNode;
  separator: string;
  mainIco: JSX.Element | undefined;
}) => {
  const componentChildren = React.Children.toArray(children);

  const componentChildrenParsed = componentChildren.map((child, idx) => (
    <BreadcrumbsItem elIndex={idx} key={idx}>
      {child}
    </BreadcrumbsItem>
  ));

  const lastIndex = componentChildrenParsed.length - 1;

  const res = componentChildrenParsed.reduce<JSX.Element[]>(
    (acc, curr, index) => {
      const notLast = index < lastIndex;

      if (notLast) {
        acc?.push(
          curr,
          <BreadcrumbsSeparator key={`breadcrumb${index}`}>
            {separator}
          </BreadcrumbsSeparator>,
        );
      } else {
        acc?.push(curr);
      }
      return acc;
    },
    [],
  );

  return (
    <ItemWrapper>
      {mainIco}
      {res}
    </ItemWrapper>
  );
};

const Breadcrumb = ({
  items,
  mainIco,
  separator,
}: {
  items: IBreadcrumbProps[];
  separator: string;
  mainIco: JSX.Element | undefined;
}) => {
  const theme = useTheme();
  return (
    <>
      <BreadcrumbsList mainIco={mainIco} separator={separator || '/'}>
        {items.map(({ label, to }, index) => (
          <Link key={index} to={to}>
            <TextS
              style={{ color: theme.palette.brand.gray[800] }}
              weight={index === 0 ? 'Bold' : 'Regular'}
            >
              {label}
            </TextS>
          </Link>
        ))}
      </BreadcrumbsList>
    </>
  );
};

export default Breadcrumb;
