import {
  EDocumentType,
  EMaritalPropertySystem,
  EMaritalStatus,
  ESex,
} from './person.interfaces';

export const SexObject = {
  [ESex.MALE]: 'Masculino',
  [ESex.FEMALE]: 'Feminino',
  [ESex.OTHER]: 'Não informado',
};

export const MaritialStatusObject = {
  [EMaritalStatus.MARRIED]: 'Casado(a)',
  [EMaritalStatus.WIDOWED]: 'Viúvo(a)',
  [EMaritalStatus.SEPARATED]: 'Separado(a)',
  [EMaritalStatus.DIVORCED]: 'Divorciado(a)',
  [EMaritalStatus.SINGLE]: 'Solteiro(a)',
  [EMaritalStatus.OTHER]: 'Outro',
};

export const MaritialPropertyObject = {
  [EMaritalPropertySystem.FINAL_COMMUNION]: 'Participação final nos aquestros',
  [EMaritalPropertySystem.FULL_COMMUNION]: 'Comunhão universal de bens',
  [EMaritalPropertySystem.NO_COMMUNION]: 'Separação total de bens',
  [EMaritalPropertySystem.PARTIAL_COMMUNION]: 'Comunhão parcial de bens',
};

export const DocumentTypeObject = {
  [EDocumentType.RG]: 'RG',
  [EDocumentType.CNH]: 'CNH',
  [EDocumentType.PASSPORT]: 'PASSAPORTE',
  [EDocumentType.RNE]: 'RNE',
  [EDocumentType.CLASS_ID]: 'CARTEIRA FUNCIONAL',
  [EDocumentType.MILITARY_ID]: 'IDENTIDADE MILITAR',
};
