import { useTheme } from '@mui/material';
import { EditIcon } from '../Icons';
import { EditButtonContainer } from './styles';

export interface IEditButtonButton {
  handleClick: () => void;
}

const EditButton = ({ handleClick }: IEditButtonButton) => {
  const theme = useTheme();
  return (
    <EditButtonContainer onClick={handleClick}>
      <EditIcon color={theme.palette.brand.primary.base as string} />
    </EditButtonContainer>
  );
};

export default EditButton;
