import { useEffect, createContext, useState } from 'react';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { useBreadcrumbsContext } from '../../../../app/context';
import { NaturalPersonViewPageContainer } from './styles';
import { useParams } from 'react-router-dom';
import { useCustomerService, usePersonService } from '../../services/api';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from '../../context';
import { NaturalPersonViewPageContent } from './components';
interface INaturalPersonViewPageContext {
  isLoading: boolean;
  employer: TRegisterBusinessDataRequest | null;
  updatePerson: (data: TRegisterPersonDataRequest) => Promise<boolean>;
}

export const NaturalPersonViewPageContext =
  createContext<INaturalPersonViewPageContext>({
    isLoading: false,
    employer: null,
    updatePerson: () => {
      throw new Error('Método não implementado');
    },
  });

const NaturalPersonViewPage = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const [person, setPerson] = useState<TRegisterPersonDataRequest | null>(null);
  const [employer, setEmployer] = useState<TRegisterBusinessDataRequest | null>(
    null,
  );
  const {
    getPerson,
    editPersonLoading,
    getPersonLoading,
    updatePerson: updatePersonService,
  } = usePersonService();
  const { getBusiness, creatBusinessLoading } = useCustomerService();
  const params = useParams();
  const isLoading =
    editPersonLoading || getPersonLoading || creatBusinessLoading;

  useEffect(() => {
    setItems(
      [
        { to: '/records/natural', label: 'Cadastros' },
        { to: '/records/natural', label: 'Pessoas' },
      ],
      <GroupAddOutlinedIcon />,
    );
    return resetBreadcrumbs;
  }, []);

  useEffect(() => {
    if (typeof params.id === 'string') {
      getPerson(params.id).then((personData) => {
        setPerson(personData);
        if (typeof personData?.employer?.id === 'string') {
          getBusiness(personData.employer.id).then((businessData) => {
            setEmployer(businessData);
          });
        }
      });
    }
  }, []);

  const updatePerson = async (data: TRegisterPersonDataRequest) => {
    try {
      const wasUpdated = await updatePersonService(params.id as string, data);
      if (wasUpdated) {
        setPerson(data);
        if (data?.employer?.id) {
          getBusiness(data.employer.id).then((businessData) => {
            setEmployer(businessData);
          });
        }
        return true;
      }
      return Promise.reject();
    } catch {
      return Promise.reject();
    }
  };

  return (
    <NaturalPersonViewPageContext.Provider
      value={{ isLoading, employer, updatePerson }}
    >
      <NaturalPersonViewPageContainer>
        {person && <NaturalPersonViewPageContent personData={person} />}
      </NaturalPersonViewPageContainer>
    </NaturalPersonViewPageContext.Provider>
  );
};

export default NaturalPersonViewPage;
