import React, {
  createContext,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';

export interface IBreadcrumbsItem {
  to: string;
  label: string;
}

export interface IBreadcrumbsContext {
  breadcrumbs: IBreadcrumbsItem[];
  breadcrumbsIco: JSX.Element | undefined;
  setItems: (
    items: SetStateAction<
      {
        to: string;
        label: string;
      }[]
    >,
    mainIco?: JSX.Element | undefined,
  ) => void;
  resetBreadcrumbs: () => void;
}

const BreadcrumbsContext = createContext<IBreadcrumbsContext | undefined>(
  undefined,
);

const BreadcrumbsProvider = ({ children }: { children?: React.ReactNode }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([{ to: '', label: '' }]);
  const [breadcrumbsIco, setBreadcrumbsIco] = useState<JSX.Element | undefined>(
    undefined,
  );

  const setItems = useCallback(
    (
      items: SetStateAction<{ to: string; label: string }[]>,
      mainIco: JSX.Element | undefined,
    ) => {
      setBreadcrumbs(items);
      setBreadcrumbsIco(mainIco);
    },
    [],
  );

  const resetBreadcrumbs = useCallback(() => {
    setBreadcrumbs([{ to: '', label: '' }]);
    setBreadcrumbsIco(undefined);
  }, []);

  return (
    <BreadcrumbsContext.Provider
      value={{ breadcrumbs, breadcrumbsIco, setItems, resetBreadcrumbs }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbsContext = () => {
  const context = useContext(BreadcrumbsContext);

  if (context === undefined) {
    throw new Error(
      'useBreadcrumbsContext must be used within a BreadcrumbsProvider',
    );
  }
  return context;
};

export default BreadcrumbsProvider;
