import { ButtonDefault, ButtonPrimary } from 'app/components';
import { BalanceArea, BalanceStatementHeader, Content } from './styles';
import { Heading4, TextL, TextM } from 'celcoin-design-system';
import { useDisbursementService } from 'modules/disbursement/services/useDisbursementService';
import {
  FundingAccount,
  TBalance,
} from 'modules/disbursement/interfaces/disbursement';
import { formatCurrency } from 'app/utils/normalizer';
import { EmptyApplications } from 'app/components/Icons/svgs';
import { useTheme } from '@mui/material';
import { AddIcon, RefreshIcon } from 'app/components/Icons';
import { useState } from 'react';
import BalanceStatementDepositSideSheet from './BalanceStatementDepositSideSheet';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { ArrowOutward } from '@mui/icons-material';
import { Withdraw } from './components/Withdraw';

interface IBalanceStatementContentProps {
  balanceData?: TBalance;
  fundingId: string;
  balanceDataLoading: boolean;
  fundingAccount?: FundingAccount;
  fundingAccountLoading: boolean;
  fetchBalance: () => void;
  fetchMovements: () => void;
}

const BalanceStatementContent = ({
  balanceData,
  fetchBalance,
  fetchMovements,
  fundingId,
  fundingAccount,
  fundingAccountLoading,
  balanceDataLoading,
}: IBalanceStatementContentProps) => {
  const { getBalanceError } = useDisbursementService();
  const { isProfileAuthorized } = usePermify();
  const theme = useTheme();
  const [isDepositSideSheetOpen, toggleIsDepositSideSheetOpen] =
    useState(false);
  const [isWithdrawOpen, toggleIsWithdrawOpen] = useState(false);

  return (
    <Content>
      <BalanceStatementHeader>
        <TextL>
          <span style={{ fontSize: '24px' }}>Saldo</span>
        </TextL>
        {!fundingAccountLoading && (
          <ButtonPrimary
            style={{
              marginLeft: 'auto',
              backgroundColor: 'transparent',
              width: '160px',
            }}
            typeVariant="outline"
            iconPosition="left"
            btnIcon={
              <AddIcon color={theme.palette.brand.primary.base as string} />
            }
            onClick={() => toggleIsDepositSideSheetOpen(true)}
          >
            <TextM
              weight="Semibold"
              style={{
                marginLeft: '11px',
                color: theme.palette.brand.primary.base,
              }}
            >
              Depositar
            </TextM>
          </ButtonPrimary>
        )}
        {isProfileAuthorized(EAccountPermissions.WITHDRAW) && (
          <ButtonPrimary
            style={{
              backgroundColor: 'transparent',
              marginLeft: '15px',
              width: '130px',
            }}
            typeVariant="outline"
            iconPosition="left"
            btnIcon={
              <ArrowOutward
                style={{ color: theme.palette.brand.primary.base as string }}
              />
            }
            onClick={() => toggleIsWithdrawOpen(true)}
          >
            <TextM
              weight="Semibold"
              style={{
                marginLeft: '11px',
                color: theme.palette.brand.primary.base,
              }}
            >
              Sacar
            </TextM>
          </ButtonPrimary>
        )}
      </BalanceStatementHeader>
      <BalanceArea isLoading={balanceDataLoading || fundingAccountLoading}>
        <ButtonDefault onClick={fetchBalance}>
          <RefreshIcon color={theme.palette.brand.primary.base as string} />
        </ButtonDefault>
        <TextL style={{ fontSize: '20px' }}>
          {balanceDataLoading
            ? 'Carregando saldo...'
            : fundingAccountLoading
            ? 'Carregando conta...'
            : getBalanceError
            ? 'Indisponível'
            : balanceData
            ? `R$ ${formatCurrency(balanceData?.balance.amount, false)}`
            : '-'}
        </TextL>
      </BalanceArea>
      {getBalanceError && (
        <div className="get-balance-error">
          <img src={EmptyApplications} />
          <Heading4
            style={{
              marginTop: '30px',
              color: theme.palette.brand.secondary.base,
            }}
            fontWeight="B"
          >
            Este Portal não está com o Desembolso Gerenciado configurado
          </Heading4>
        </div>
      )}
      <BalanceStatementDepositSideSheet
        isOpen={isDepositSideSheetOpen}
        balanceData={balanceData}
        fundingAccount={fundingAccount}
        handleClose={() => toggleIsDepositSideSheetOpen(false)}
      />
      <Withdraw
        isOpen={isWithdrawOpen}
        fundingId={fundingId}
        fundingAccount={fundingAccount}
        fetchBalance={() => {
          fetchBalance();
          fetchMovements();
        }}
        handleClose={() => toggleIsWithdrawOpen(false)}
      />
    </Content>
  );
};

export default BalanceStatementContent;
