const BadgeClockIcon = ({color, ...props}: {color?: string}) => (
  <svg
    width={16}
    height={17}
    fill="none"
    {...props}
  >
    <path
      d="M8 .5a8 8 0 1 1 0 16 8 8 0 0 1 0-16Zm0 1a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-.5 2a.5.5 0 0 1 .492.41L8 4v4.5h2.5a.5.5 0 0 1 .09.992l-.09.008h-3a.5.5 0 0 1-.492-.41L7 9V4a.5.5 0 0 1 .5-.5Z"
      fill={color || "#8D4908"}
    />
  </svg>
)

export default BadgeClockIcon
