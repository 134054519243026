import styled from '@emotion/styled';

export const InstallmentsCartWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  height: 0px;
  width: 85.5%;
  left: 14.5%;
  padding: 0px 50px;
  transition: height 0.2s ease-in-out;

  &.active {
    height: 90px;
  } 
`;

export const InstallmentsCartContent = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette!.brand.primary.base};
  position: relative;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 0px 30px;
  box-shadow: 0px 2px 19px -6px rgba(0,0,0,0.75);
  &:after {
    content: " ";
    width: 5px;
    background-color: white;
    height: 50px;
    position: absolute;
    top: 20px;
    left: 15px;
    border-radius: 15px;
  }
`;

export const InstallmentsDescription = styled.div`
  display: block;
  margin-right: auto;
  margin-left: 10px;
`;
