import { useCallback, useEffect, useState } from 'react';
import { useBreadcrumbsContext } from '../../../../app/context';
import { UsersAccessDetailsContent } from './components/UsersAccessDetailsContent';
import { UsersAccessDetailsContainer } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { UserAccess } from 'modules/management/interfaces/usersAccess';
import { HomeOutlined } from '@mui/icons-material';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { useAccessService } from 'modules/management/services/useAccessService';
import { routingPath } from 'app/routes';
import { useAuthContext } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';

const UsersAccessDetails = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const { showSnackbar } = useSnackbar();
  const { getUserAccess } = useAccessService();
  const { userInfo } = useAuthContext();
  const navigate = useNavigate();
  const [userAccessData, updateUserAccessData] = useState<UserAccess | null>(
    null,
  );
  const params = useParams();

  useEffect(() => {
    if (!userInfo?.permissions?.includes(EAccountPermissions.USER_ADMIN)) {
      navigate('/');
      return;
    }

    const userAccessId = params.id;
    if (userAccessId) {
      getUserAccess(userAccessId).then((response) => {
        if (typeof response === 'object') {
          updateUserAccessData(response);
          return;
        }
        showSnackbar('Usuário não encontrado.', 'error');
        navigate(routingPath.management.access);
      });
    }
  }, [params]);

  useEffect(() => {
    setItems(
      [
        { to: '/access-management', label: 'Gestão de Acessos' },
        {
          to: `/access-management/${userAccessData?.id}`,
          label: userAccessData?.full_name ?? '',
        },
      ],
      <HomeOutlined />,
    );

    return () => resetBreadcrumbs();
  }, [userAccessData]);

  const updateUserAccess = useCallback(
    async (newUserAccessData: UserAccess) => {
      updateUserAccessData(newUserAccessData);
    },
    [],
  );

  if (!userInfo?.permissions?.includes(EAccountPermissions.USER_ADMIN))
    return null;

  return (
    <UsersAccessDetailsContainer>
      {userAccessData && (
        <UsersAccessDetailsContent
          userAccess={userAccessData}
          updateUserAccess={updateUserAccess}
        />
      )}
    </UsersAccessDetailsContainer>
  );
};

export default UsersAccessDetails;
