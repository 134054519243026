import { useTheme } from '@mui/material';
import { HTMLAttributes, ReactNode } from 'react';
import { TextS } from '../typography';
import { BadgeContainer, BadgeContent } from './styles';

export type TBadgeVariant =
  | 'active'
  | 'neutral'
  | 'success'
  | 'attention'
  | 'error'
  | 'info'
  | 'dashed'
  | 'disabled';
interface IBadge extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  typeVariant?: TBadgeVariant;
  icon?: JSX.Element;
  isSpaceBetween?: boolean;
  iconPosition?: 'left' | 'right';
}

const Badge = ({
  children,
  typeVariant = 'neutral',
  icon,
  isSpaceBetween,
  iconPosition,
  ...props
}: IBadge) => {
  const theme = useTheme();

  const getBadgeColor = (typeVar: TBadgeVariant) => {
    switch (typeVar) {
      case 'neutral':
        return '#4B4662';
      case 'success':
        return theme.palette.brand.success[200];
      case 'attention':
        return theme.palette.brand.attention[700];
      case 'error':
        return '#9F0B0B';
      case 'info':
        return '#5A4B9D';
      default:
        return '#4B4662';
    }
  };

  return (
    <BadgeContainer typeVariant={typeVariant} {...props}>
      <BadgeContent isSpaceBetween={isSpaceBetween}>
        {icon && iconPosition === 'left' ? icon : null}

        <TextS
          style={{
            color: getBadgeColor(typeVariant),
            margin: isSpaceBetween ? '0px auto' : 'unset',
            maxWidth: isSpaceBetween ? '60%' : 'unset',
          }}
          weight="Bold"
        >
          {children}
        </TextS>

        {icon && (!iconPosition || iconPosition === 'right') ? icon : null}
      </BadgeContent>
    </BadgeContainer>
  );
};

export default Badge;
