import { IDrawerAction, IDrawerState } from './drawer.interfaces';
import { drawerActionType } from './drawerActionTypes';
import AccountBalanceOutlined from '@mui/icons-material/AccountBalanceOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { routingPath } from '../../routes/config';
import { EPermission } from 'modules/auth/context/permify/permify.interfaces';

export const drawerInitialState: IDrawerState = {
  isOpen: false,
  currentItem: null,
  isCollapsed: false,
  items: [
    // {
    //   id: '1',
    //   title: 'Início',
    //   icon: <InsertChartOutlinedIcon />,
    //   expandable: false,
    //   category: 'home',
    //   toRoute: routingPath.home.base,
    // },
    {
      id: '2',
      title: 'Produtos',
      icon: <AccountBalanceOutlined />,
      expandable: false,
      category: 'products',
      permission: EPermission.PRODUCTS_LIST,
      toRoute: routingPath.products.main,
      // subMenu: [
      //   {
      //     id: '1',
      //     title: 'Visão geral',
      //     to: routingPath.products.main,
      //     basePath: routingPath.products.main,
      //   },
      // ],
    },
    {
      id: '3',
      title: 'Cadastros',
      icon: <GroupAddOutlinedIcon />,
      expandable: true,
      toRoute: routingPath.records.main,
      permission: EPermission.MENU_RECORDS,
      category: 'records',
      subMenu: [
        {
          id: '1',
          title: 'Pessoa Física',
          permission: EPermission.NATURAL_PERSON_LIST,
          to: routingPath.records.natural,
        },
        {
          id: '2',
          title: 'Pessoa Jurídica',
          permission: EPermission.LEGAL_PERSON_LIST,
          to: routingPath.records.legal,
        },
      ],
    },
  ],
};

export const drawerReducer = (
  state: IDrawerState = drawerInitialState,
  action: IDrawerAction,
) => {
  switch (action.type) {
    case drawerActionType.TOGGLE_DRAWER:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case drawerActionType.TOGGLE_COLLAPSE:
      return {
        ...state,
        isCollapsed: !state.isCollapsed,
      };
    case drawerActionType.REMOVE_COLLAPSE:
      return {
        ...state,
        isCollapsed: false,
      };
    case drawerActionType.CLOSE_DRAWER:
      return {
        ...state,
        isOpen: false,
      };
    case drawerActionType.SET_SELECTED_ITEM:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};
