import { useTheme } from '@mui/material';
import { Spinner } from 'app/components';
import { useAuthContext } from 'modules/auth/context';
import { useThemeContext } from '../../theme';

export interface ILoadingGuardProps {
  children: React.ReactNode;
}

export const LoadingGuard = ({ children }: ILoadingGuardProps) => {
  const { loading } = useThemeContext();
  const { authLoading } = useAuthContext();
  const theme = useTheme();

  if (loading || authLoading) {
    return (
      <div
        style={{
          height: '100%',
          minHeight: '100vh',
          width: '100%',
          minWidth: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '30px',
          flexDirection: 'column',
        }}
      >
        <h1 style={{ color: theme.palette.brand.secondary.base }}>
          Carregando suas configurações...
        </h1>
        <Spinner />
      </div>
    );
  }

  return <>{children}</>;
};

export default LoadingGuard;
