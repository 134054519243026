import { useEffect, createContext, useState } from 'react';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { useBreadcrumbsContext } from '../../../../app/context';
import { LegalPersonViewPageContent } from './components';
import { LegalPersonViewPageContainer } from './styles';
import { useParams } from 'react-router-dom';
import { useCustomerService } from '../../services/api';
import { TRegisterBusinessDataRequest } from '../../context';
interface ILegalPersonViewPageContext {
  isLoading: boolean;
  updateBusiness: (data: TRegisterBusinessDataRequest) => Promise<boolean>;
}

export const LegalPersonViewPageContext =
  createContext<ILegalPersonViewPageContext>({
    isLoading: false,
    updateBusiness: () => {
      throw new Error('Método não implementado');
    },
  });

const LegalPersonViewPage = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const [business, setBusiness] = useState<TRegisterBusinessDataRequest | null>(
    null,
  );
  const {
    getBusiness,
    creatBusinessLoading,
    updateBusiness: updateBusinessService,
  } = useCustomerService();
  const params = useParams();

  useEffect(() => {
    setItems(
      [
        { to: '/records/legal', label: 'Cadastros' },
        { to: '/records/legal', label: 'Empresas' },
      ],
      <GroupAddOutlinedIcon />,
    );
    return resetBreadcrumbs;
  }, []);

  useEffect(() => {
    if (typeof params.id === 'string') {
      getBusiness(params.id).then((b) => {
        setBusiness(b);
      });
    }
  }, []);

  const updateBusiness = async (data: TRegisterBusinessDataRequest) => {
    try {
      const wasUpdated = await updateBusinessService(params.id as string, data);
      if (wasUpdated) {
        setBusiness(data);
        return true;
      }
      return Promise.reject();
    } catch {
      return Promise.reject();
    }
  };

  return (
    <LegalPersonViewPageContext.Provider
      value={{ isLoading: creatBusinessLoading, updateBusiness }}
    >
      <LegalPersonViewPageContainer>
        {business && <LegalPersonViewPageContent business={business} />}
      </LegalPersonViewPageContainer>
    </LegalPersonViewPageContext.Provider>
  );
};

export default LegalPersonViewPage;
