import { Theme } from '@emotion/react';
import styled from '@emotion/styled';

const getColorByStrength = (
  strength: null | 'weak' | 'medium' | 'high' | 'strong',
  defaultColor: string,
  theme: Theme,
): string => {
  if (strength === 'weak') return theme.palette?.brand.error.base || 'red';
  if (strength === 'medium')
    return theme.palette?.brand.attention[900] || 'yellow';
  if (strength === 'high') return theme.palette?.brand.success[500] || 'green';
  if (strength === 'strong')
    return theme.palette?.brand.success[200] || 'green';
  return defaultColor;
};

export const PasswordRequirements = styled.div`
  margin: 26px 0px;
`;

export const PasswordRequirement = styled.div`
  display: flex;
  margin-top: 14px;

  span {
    margin-left: 10px;
    font-size: 16px;
    max-width: 200px;
  }
`;

export const PasswordStrengthContainer = styled.div`
  margin: 0px 24px;
`;

export const PasswordStrengthLevelText = styled.div<{
  strength: null | 'weak' | 'medium' | 'high' | 'strong';
}>`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  span {
    display: block;
  }
  span:nth-of-type(2) {
    font-weight: bold;
    color: ${({ theme, strength }) =>
      getColorByStrength(strength, 'black', theme)};
  }
`;

export const PasswordStrengthDescription = styled.div`
  margin-top: 24px;
  color: ${({ theme }) => theme.palette?.brand.gray[500]};
  max-width: 240px;
  text-align: justify;
  font-size: 16px;
  line-height: 24px;
`;

export const PasswordStrengthLevels = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

export const PasswordStrengthLevel = styled.span<{
  strength: null | 'weak' | 'medium' | 'high' | 'strong';
}>`
  height: 5px;
  width: 24%;
  display: block;
  background-color: ${({ theme, strength }) =>
    getColorByStrength(
      strength,
      theme.palette?.brand.gray[300] as string,
      theme,
    )};
`;
