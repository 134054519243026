import { routingPath } from 'app/routes';
import { useAuthContext } from 'modules/auth/context';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContainerWrapper } from './styles';

export interface IAuthContainerProps {
  children: React.ReactNode;
}

const AuthContainer = ({ children }: IAuthContainerProps) => {
  const { isLogged } = useAuthContext();

  if (isLogged) {
    return <Navigate to={`/${routingPath.products.base}`} />;
  }

  return <AuthContainerWrapper>{children}</AuthContainerWrapper>;
};

export default AuthContainer;
