import React, { HTMLAttributes } from 'react';
import { BlockWrapper } from './styles';

export type IBlockProps = HTMLAttributes<HTMLDivElement>;

const Block = ({ children, ...props }: IBlockProps) => {
  return <BlockWrapper {...props}>{children}</BlockWrapper>;
};

export default Block;
