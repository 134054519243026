import { createContext, useEffect, useMemo, useState } from 'react';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { useBreadcrumbsContext } from '../../../../app/context';
import {
  NaturalPersonHandlerContainer,
  NaturalPersonHandlerStepContainer,
  NaturalPersonHandlerStepperContainer,
} from './styles';
import { Stepper } from 'celcoin-design-system';
import {
  AboutStep,
  DocumentsStep,
  ProductStep,
  BankStep,
  EmployerStep,
  Conditions,
  FundingStep,
} from './steps';
import AddressStep from './steps/AddressStep';
import VariablesStep from './steps/VariablesStep';
import { IPersonCustomVariablesValues } from 'app/interfaces/customVariablesInterfaces';
import { personActionType, usePersonContext } from 'modules/customer/context';
import {
  EPersonFunctions,
  TRegisterPersonDataRequest,
} from 'modules/customer/context/PersonProvider/person.interfaces';
import { useLocation } from 'react-router-dom';
import { usePerson } from 'modules/customer/hooks';
import { useAuthContext, usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import SpouseStep from './steps/SpouseStep';

type INaturalPersonHandlerStepperContext = {
  activeStep: number;
  hasCustomVariables?: boolean;
  updateHasCustomVariables: (hasCustomVariables: boolean) => void;
  customVariables: IPersonCustomVariablesValues[] | null;
  updateCustomVariables: (variables: IPersonCustomVariablesValues[]) => void;
  onForward: () => void;
  onBack: () => void;
  onBackTwoSteps: () => void;
  createdPerson: TRegisterPersonDataRequest | null;
  handleCreatedPerson: (person: TRegisterPersonDataRequest) => void;
  funding: string | null;
  hasMultiplesFundings: boolean;
  updateFunding: (funding: string) => void;
  isMarried?: boolean;
  updateIsMarried: (isMarried: boolean) => void;
  stepsInfoState: StepItem[];
};

export type StepItem = {
  step: number;
  title: string;
  subtitle?: string;
  handleClick?: () => void;
  clickable?: boolean;
  element?: JSX.Element;
};

export const NaturalPersonHandlerStepperContext =
  createContext<INaturalPersonHandlerStepperContext>({
    activeStep: 1,
    hasCustomVariables: false,
    updateHasCustomVariables: () => {
      throw new Error('Método não implementado');
    },
    customVariables: null,
    updateCustomVariables: () => {
      throw new Error('Método não implementado');
    },
    onForward: () => {
      throw new Error('Método não implementado');
    },
    onBack: () => {
      throw new Error('Método não implementado');
    },
    onBackTwoSteps: () => {
      throw new Error('Método não implementado');
    },
    createdPerson: null,
    handleCreatedPerson: () => {
      throw new Error('Método não implementado');
    },
    funding: null,
    hasMultiplesFundings: false,
    updateFunding: () => {
      throw new Error('Método não implementado');
    },
    isMarried: false,
    updateIsMarried: () => {
      throw new Error('Método não implementado');
    },
    stepsInfoState: [],
  });

const NaturalPersonHandler = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const [activeStep, updateActiveStep] = useState<number>(0);
  const [hasCustomVariables, updateHasCustomVariables] = useState(false);
  const [stepsInfo, updateStepsInfo] = useState<StepItem[]>([]);
  const [createdPerson, setCreatedPerson] =
    useState<TRegisterPersonDataRequest | null>(null);
  const [customVariables, updateCustomVariables] = useState<
    IPersonCustomVariablesValues[] | null
  >(null);
  const [selectedFunding, updateSelectedFunding] = useState<string | null>(
    null,
  );
  const [marriedPerson, updateMarriedPerson] = useState(false);
  const { userInfo } = useAuthContext();
  const { state } = useLocation();
  const { setProductData, setFunctionType } = usePerson();
  const { isProfileAuthorized } = usePermify();
  const {
    dispatch,
    state: { product, func },
  } = usePersonContext();

  const hasMultiplesFundings = useMemo(
    () => userInfo && userInfo.fundings.length > 1,
    [userInfo],
  );

  useEffect(() => {
    setItems(
      [
        { to: '/records/natural', label: 'Cadastros' },
        { to: '/records/natural', label: 'Pessoas' },
      ],
      <GroupAddOutlinedIcon />,
    );

    return () => {
      resetBreadcrumbs();
      dispatch({
        type: personActionType.RESET_STATE,
      });
    };
  }, []);

  useEffect(() => {
    if (state?.applicationRequestFlow) {
      setTimeout(() => {
        setFunctionType({ value: EPersonFunctions.BORROWER, label: 'Tomador' });
        setProductData(state.product);
      });
    }
    return () => {
      history.replaceState({}, document.title);
    };
  }, [state]);

  const onForward = () => {
    if (activeStep < stepsInfo.length) {
      updateActiveStep(activeStep + 1);
    }
  };

  const onBack = () => {
    if (activeStep > 0) {
      updateActiveStep(activeStep - 1);
    }
  };

  const onBackTwoSteps = () => {
    if (activeStep > 1) {
      updateActiveStep(activeStep - 2);
    }
  };

  const handleCreatedPerson = (person: TRegisterPersonDataRequest) => {
    setCreatedPerson(person);
  };

  const steps = [
    {
      step: 0,
      title: 'Funding',
      clickable: false,
      element: <FundingStep />,
    },
    {
      step: 1,
      title: 'Produto e Função',
      clickable: false,
      element: <ProductStep />,
    },
    {
      step: 2,
      title: 'Sobre',
      clickable: false,
      element: <AboutStep />,
    },
    {
      step: 3,
      title: 'Cônjuge',
      clickable: false,
      element: <SpouseStep />,
    },
    {
      step: 4,
      title: 'Endereço',
      clickable: false,
      element: <AddressStep />,
    },
    {
      step: 5,
      title: 'Dados Bancários',
      clickable: false,
      element: <BankStep />,
    },
    {
      step: 6,
      title: 'Campos adicionais',
      clickable: false,
      element: <VariablesStep />,
    },
    {
      step: 7,
      title: 'Condições',
      clickable: false,
      element: <Conditions />,
    },
    {
      step: 8,
      title: 'Empregador',
      clickable: false,
      element: <EmployerStep />,
    },
    {
      step: 9,
      title: 'Documentos',
      clickable: false,
      element: <DocumentsStep />,
    },
  ];

  useEffect(() => {
    if (!hasMultiplesFundings && userInfo) {
      updateSelectedFunding(userInfo.fundings[0]);
    }
  }, [hasMultiplesFundings]);

  useEffect(() => {
    let updatedSteps: StepItem[] = steps;
    if (!marriedPerson) {
      updatedSteps = updatedSteps.filter((item) => item.title !== 'Cônjuge');
    }

    if (!hasMultiplesFundings) {
      updatedSteps = updatedSteps.filter((item) => item.title !== 'Funding');
    }

    if (
      !(
        product?.qualification_required &&
        func.value === EPersonFunctions.BORROWER &&
        isProfileAuthorized(
          EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION_CONDITIONS,
        )
      )
    ) {
      updatedSteps = updatedSteps.filter((item) => item.title !== 'Condições');
    }

    updateStepsInfo(updatedSteps);
  }, [marriedPerson, hasMultiplesFundings, product, func]);

  const renderSteps = () => {
    const currentStep = stepsInfo[activeStep];
    return currentStep?.element || null;
  };

  return (
    <NaturalPersonHandlerStepperContext.Provider
      value={{
        activeStep,
        createdPerson,
        hasCustomVariables,
        updateHasCustomVariables,
        customVariables,
        updateCustomVariables,
        onForward,
        onBack,
        onBackTwoSteps,
        handleCreatedPerson,
        funding: selectedFunding,
        hasMultiplesFundings: !!hasMultiplesFundings,
        updateFunding: updateSelectedFunding,
        isMarried: marriedPerson,
        updateIsMarried: updateMarriedPerson,
        stepsInfoState: stepsInfo,
      }}
    >
      <NaturalPersonHandlerContainer>
        <NaturalPersonHandlerStepperContainer>
          <Stepper activeStep={activeStep} stepsItems={stepsInfo} />
        </NaturalPersonHandlerStepperContainer>
        <NaturalPersonHandlerStepContainer>
          {renderSteps()}
        </NaturalPersonHandlerStepContainer>
      </NaturalPersonHandlerContainer>
    </NaturalPersonHandlerStepperContext.Provider>
  );
};

export default NaturalPersonHandler;
