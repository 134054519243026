import AddIcon from '@mui/icons-material/Add';
import { LegalRepresentativesEmptyContainer } from './styles';
import { LegalRepresentativesEmptyIcon } from '../../../../../../../../app/components/Icons/svgs';
import { ButtonDefault, Heading4, TextM } from 'app/components';
import { useTheme } from '@mui/material';

export interface ILegalRepresentativesEmptyProps {
  handleAddPerson: () => void;
}

const LegalRepresentativesEmpty = ({
  handleAddPerson,
}: ILegalRepresentativesEmptyProps) => {
  const theme = useTheme();

  return (
    <>
      <LegalRepresentativesEmptyContainer>
        <img src={LegalRepresentativesEmptyIcon} alt="icon" />
        <Heading4
          style={{ color: theme.palette.brand.secondary.base }}
          fontWeight="Sb"
        >
          Nenhum representante adicionado
        </Heading4>
        <ButtonDefault style={{ maxWidth: '300px' }} onClick={handleAddPerson}>
          <AddIcon />
          <TextM
            weight="Semibold"
            style={{ color: theme.palette.brand.primary.base }}
          >
            Adicionar representante
          </TextM>
        </ButtonDefault>
      </LegalRepresentativesEmptyContainer>
    </>
  );
};

export default LegalRepresentativesEmpty;
