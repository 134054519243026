import { TextM } from 'app/components';
import { FundingAccount } from 'modules/disbursement/interfaces/disbursement';
import { IFunding } from 'modules/products/services/hooks/intefaces';
import {
  BankStatementHolderInfoItem,
  BankStatementHolderInfoWrapper,
} from './styles';
import { normalizeCnpj } from 'app/utils/normalizer';

export interface BankStatementHolderInfoProps {
  fundingAccount?: FundingAccount;
  funding?: IFunding | undefined;
}

const BankStatementHolderInfo = ({
  funding,
  fundingAccount,
}: BankStatementHolderInfoProps) => {
  return (
    <BankStatementHolderInfoWrapper>
      <BankStatementHolderInfoItem>
        <TextM weight="Semibold">Titular:</TextM>
        <TextM>{funding?.legal_name ?? '-'}</TextM>
      </BankStatementHolderInfoItem>
      <BankStatementHolderInfoItem>
        <TextM weight="Semibold">CNPJ:</TextM>
        <TextM>
          {funding?.taxpayer_id ? normalizeCnpj(funding?.taxpayer_id) : '-'}
        </TextM>
      </BankStatementHolderInfoItem>
      <BankStatementHolderInfoItem>
        <TextM weight="Semibold">Instituição:</TextM>
        <TextM>{'Celcoin Instituição de Pagamento S.A'}</TextM>
      </BankStatementHolderInfoItem>
      <BankStatementHolderInfoItem>
        <TextM weight="Semibold">Agência:</TextM>
        <TextM>{fundingAccount?.bank_branch ?? '-'}</TextM>
      </BankStatementHolderInfoItem>
      <BankStatementHolderInfoItem>
        <TextM weight="Semibold">Conta:</TextM>
        <TextM>{fundingAccount?.bank_account ?? '-'}</TextM>
      </BankStatementHolderInfoItem>
    </BankStatementHolderInfoWrapper>
  );
};

export default BankStatementHolderInfo;
