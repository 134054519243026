import React, { ReactNode } from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

import { ButtonDropdownContainer, IconArea } from './styles';
import { useTheme } from '@mui/material';
import { MenuDropdown } from '../MenuDropdown';
import { TextM } from '../typography';
import { MenuOutside } from '../MenuDropdown/styles';

export interface IButtonDropdown {
  title?: string;
  handleClick: () => void;
  handleClose: () => void;
  isOpen: boolean;
  children: React.ReactNode;
  mode?: 'TEXT' | 'ICON';
  hideArrow?: boolean;
  icon?: ReactNode;
  style?: React.CSSProperties;
}

const ButtonDropdown = ({
  isOpen,
  handleClick,
  handleClose,
  title,
  mode = 'TEXT',
  hideArrow,
  children,
  style,
  icon,
}: IButtonDropdown) => {
  const theme = useTheme();

  const clickBtn = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    handleClick();
  };

  return (
    <>
      <ButtonDropdownContainer onClick={clickBtn} mode={mode}>
        {icon || null}
        {typeof title === 'string' && (
          <TextM
            weight="Semibold"
            style={{ color: theme.palette?.brand.primary.base }}
          >
            {title}
          </TextM>
        )}
        {!hideArrow && (
          <IconArea isOpen={isOpen}>
            <KeyboardArrowDownOutlinedIcon
              sx={{ color: theme.palette?.brand.primary.base }}
            />
          </IconArea>
        )}
        <MenuDropdown style={style} hideArrow={hideArrow} isOpen={isOpen}>
          {children}
        </MenuDropdown>
      </ButtonDropdownContainer>
      {isOpen && <MenuOutside onClick={() => handleClose()} />}
    </>
  );
};

export default ButtonDropdown;
