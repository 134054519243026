import styled from '@emotion/styled';

export const ListBoxWrapper = styled.div`
    margin: 32px 0px;
    `;

export const ListBoxContainer = styled.div`
    padding: 24px;
    background-color: #FDFDFD;
    border: 1px solid #E6E5EA;
    border-radius: 10px;
    max-height: 50vh;
    overflow: auto;
    `;

export const ListBoxContent = styled.div`
    position: relative;
    min-height: 80px;
`;

export const ListBoxSearchContainer = styled.div`
    margin-bottom: 16px;
`;

export const ListBoxItem = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${({ theme }) => theme.palette?.brand.secondary.base};
    border: 1px solid #E6E5EA;
    background-color: #FDFDFD;
    border-radius: 10px;
    padding: 12px 24px;
    margin-bottom: 16px;
    gap: 21px;
    cursor: pointer;
    &.selected {
        background-color: ${({ theme }) =>
          theme.palette?.brand.background.base};
        border: 2px solid ${({ theme }) => theme.palette?.brand.primary.base};
        h4 {
            color: ${({ theme }) => theme.palette?.brand.primary.base};
        }
    }
`;

export const ListBoxItemTitle = styled.h4`
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: ${({ theme }) => theme.palette?.brand.secondary.base};
`;
