import styled from '@emotion/styled';

export const NaturalPersonViewKycTabPopOver = styled.div`
  padding: 20px 20px;
`;

export const TableWrapper = styled.div`
  margin-top: 40px;
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 24px;
`;
