import styled from '@emotion/styled';

export const HeadTextArea = styled.div`
  margin-top: 32px;
`;

export const InputArea = styled.div`
  width: 100%;
`;

export const InputItem = styled.div`
  margin-top: 24px;
`;

export const ButtonArea = styled.div`
  margin-top: 32px;
  width: 100%;
  height: 48px;
`;

export const SubtitleArea = styled.div`
  margin-top: 24px;
`;
