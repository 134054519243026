import { ButtonPrimary, Heading3, Spinner } from 'app/components';
import { EmptyContainer } from './styles';
import { ReactNode } from 'react';
import { useTheme } from '@mui/material';
import { AddIcon } from 'app/components/Icons';
import { TextM } from 'celcoin-design-system';

interface IEmptyAreaProps {
  children: ReactNode;
  isLoading: boolean;
  message: string;
  createButtonText?: string;
  onCreateClick?: () => void;
}

const EmptyArea = ({
  children,
  isLoading,
  message,
  createButtonText,
  onCreateClick,
}: IEmptyAreaProps) => {
  const theme = useTheme();
  return (
    <EmptyContainer>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {children}
          <Heading3
            style={{
              margin: '30px 0px',
              fontWeight: 'lighter',
              fontSize: '32px',
              color: theme.palette.brand.secondary.base,
            }}
          >
            {message}
          </Heading3>
          {typeof onCreateClick === 'function' && (
            <ButtonPrimary
              style={{ maxWidth: '202px' }}
              typeVariant="fill"
              iconPosition="left"
              btnIcon={<AddIcon color={theme.palette.common.white as string} />}
              onClick={onCreateClick}
            >
              <TextM
                weight="Semibold"
                style={{
                  marginLeft: '11px',
                  color: theme.palette.common.white,
                }}
              >
                {createButtonText}
              </TextM>
            </ButtonPrimary>
          )}
        </>
      )}
    </EmptyContainer>
  );
};

export default EmptyArea;
