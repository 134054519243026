import styled from '@emotion/styled';

export const NaturalPersonHandlerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-wrap: no-wrap;
`;

export const NaturalPersonHandlerStepperContainer = styled.div`
  max-width: 25%;
  margin-right: auto !important;
`;

export const NaturalPersonHandlerStepContainer = styled.div`
  width: 75%;
  padding-left: 30px;
`;
