import styled from '@emotion/styled';

export interface IBreadcrumbsItemProps {
  elIndex: number;
}

export const BreadcrumbsContainer = styled.div`
  display: flex;
`;

export const BreadcrumbsItem = styled.li<IBreadcrumbsItemProps>`
 a {
    display: flex;
    margin: 0px 4px;
    text-decoration: none;
    font-family: 'Inter';
    color: ${({ elIndex, theme }) =>
      elIndex === 0
        ? theme.palette?.brand.gray[600]
        : theme.palette?.brand.gray[500]};
  }
`;

export const BreadcrumbsSeparator = styled.li`
  font-size: 12px;
  font-family: 'Inter';
  font-weight: bold;
`;

export const ItemWrapper = styled.ol`
  list-style: none;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette?.brand.gray[500]};
  padding: 2px;

  svg {
    color: ${({ theme }) => theme.palette?.brand.gray[800]};
  }

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
    font-size: clamp(12px, 1.5vw, 16px);

    svg {
      font-size: 12px;
    }
  }
`;
