import {
  BoxSection,
  ButtonDanger,
  ButtonPrimary,
  ConfirmModal,
  Heading3,
} from 'app/components';
import { UserAccess } from 'modules/management/interfaces/usersAccess';
import { useCallback, useMemo, useState } from 'react';
import { Spacing, UsersAccessSettingsContainer } from './styles';
import { EmailOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { ActivateIcon, DeactivateIcon, KeyIcon } from 'app/components/Icons';
import { useSnackbar } from 'app/hooks/useSnackbar';
import AttentionCircularIcon, {
  AttentionCircularRedIcon,
} from 'app/components/Icons/AttentionCircularIcon';
import { useAccessService } from 'modules/management/services/useAccessService';
import { useAuthContext } from 'modules/auth/context';

enum ConfirmModalType {
  RESEND_INVITE = 'RESEND_INVITE',
  RESET_PASSWORD = 'RESET_PASSWORD',
  DEACTIVATE_USER = 'DEACTIVATE_USER',
  ACTIVATE_USER = 'ACTIVATE_USER',
}

interface IUsersAccessSettingsTabProps {
  userAccess: UserAccess;
  updateUserAccess: (newUserAccess: UserAccess) => Promise<void>;
}

const UsersAccessSettingsTab = ({
  userAccess,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateUserAccess,
}: IUsersAccessSettingsTabProps) => {
  const theme = useTheme();
  const { userInfo } = useAuthContext();
  const {
    deactivateUserAccess,
    isDeactivateUserLoading,
    activateUserAccess,
    isActivateUserLoading,
    resendUserAccess,
    isResendUserLoading,
    resetUserAccess,
    isResetUserLoading,
  } = useAccessService();
  const { showSnackbar } = useSnackbar();
  const [isConfirmModalOpen, toggleConfirmModalOpen] =
    useState<ConfirmModalType | null>(null);

  const confirmModalConfig = useMemo(
    () => ({
      [ConfirmModalType.RESEND_INVITE]: {
        title: 'Confirme para enviar um novo convite de acesso via e-mail.',
        totast: 'Convite enviado.',
        buttonText: 'Continuar',
        description: `Um novo convite para acesso ao Portal X será enviado para o e-mail ${userAccess.email} do usuário.`,
        isDanger: false,
      },
      [ConfirmModalType.RESET_PASSWORD]: {
        title: 'A senha atual vai parar de funcionar',
        totast: 'Senha redefinida.',
        buttonText: 'Redefinir senha',
        description: `Caso o usuário esteja ativo, perderá o acesso com a senha atual. Usuários que não definirem uma senha para ativar sua conta em até 24h serão desativados automaticamente.`,
        isDanger: true,
      },
      [ConfirmModalType.DEACTIVATE_USER]: {
        title: 'Tem certeza que deseja desativar esta conta?',
        totast: 'Usuário desativado.',
        buttonText: 'Desativar usuário',
        description: `O usuário perderá o acesso imediatamente.`,
        isDanger: true,
      },
      [ConfirmModalType.ACTIVATE_USER]: {
        title: 'Tem certeza que deseja ativar esta conta?',
        totast: 'Usuário ativado.',
        buttonText: 'Ativar usuário',
        description: `O usuário poderá fazer login novamente com o e-mail e senha anterior. Se preferir, você pode redefinir a senha em seguida.`,
        isDanger: false,
      },
    }),
    [userAccess],
  );

  const onResendInvite = useCallback(() => {
    toggleConfirmModalOpen(ConfirmModalType.RESEND_INVITE);
  }, []);

  const onDeactivateUser = useCallback(() => {
    toggleConfirmModalOpen(ConfirmModalType.DEACTIVATE_USER);
  }, []);

  const onActivateUser = useCallback(() => {
    toggleConfirmModalOpen(ConfirmModalType.ACTIVATE_USER);
  }, []);

  const onResetPassword = useCallback(() => {
    toggleConfirmModalOpen(ConfirmModalType.RESET_PASSWORD);
  }, []);

  const onConfirmAction = useCallback(async () => {
    if (isConfirmModalOpen) {
      switch (isConfirmModalOpen) {
        case ConfirmModalType.DEACTIVATE_USER:
          await deactivateUserAccess({ email: userAccess.email });
          updateUserAccess({
            ...userAccess,
            status: 'INACTIVE',
          });
          break;

        case ConfirmModalType.ACTIVATE_USER:
          await activateUserAccess({ email: userAccess.email });
          updateUserAccess({
            ...userAccess,
            status: 'ACTIVE',
          });
          break;

        case ConfirmModalType.RESEND_INVITE:
          await resendUserAccess(userAccess.id);
          break;

        case ConfirmModalType.RESET_PASSWORD:
          await resetUserAccess(userAccess.id);
          break;
      }
      showSnackbar(confirmModalConfig[isConfirmModalOpen].totast);
    }
    toggleConfirmModalOpen(null);
  }, [isConfirmModalOpen]);

  return (
    <BoxSection>
      <Heading3>Configurações Gerais</Heading3>
      <UsersAccessSettingsContainer>
        <ButtonPrimary
          typeVariant="outline"
          btnIcon={
            <KeyboardArrowRightOutlined
              style={{
                color: theme.palette.brand.primary.base as string,
                marginLeft: 'auto',
              }}
            />
          }
          style={{
            maxWidth: '300px',
            marginBottom: '10px',
          }}
          disabled={userAccess.email === userInfo?.email}
          onClick={onResendInvite}
        >
          <EmailOutlined
            style={{
              color: theme.palette.brand.primary.base as string,
              marginRight: '10px',
            }}
          />
          Reenviar convite
        </ButtonPrimary>
      </UsersAccessSettingsContainer>
      <Spacing />
      <Heading3>Configurações Sensiveis</Heading3>
      <UsersAccessSettingsContainer>
        <ButtonDanger
          typeVariant="outline"
          disabled={userAccess.email === userInfo?.email}
          btnIcon={
            <KeyboardArrowRightOutlined
              style={{
                color: theme.palette.brand.error[900] as string,
                marginLeft: 'auto',
              }}
            />
          }
          style={{
            maxWidth: '300px',
            marginBottom: '10px',
          }}
          onClick={onResetPassword}
        >
          <KeyIcon color={theme.palette.brand.error[900] as string} />
          <span style={{ paddingLeft: '10px' }}>Redefinir senha</span>
        </ButtonDanger>

        {['ACTIVE', 'PENDING'].includes(userAccess.status) && (
          <ButtonDanger
            typeVariant="outline"
            disabled={userAccess.email === userInfo?.email}
            btnIcon={
              <KeyboardArrowRightOutlined
                style={{
                  color: theme.palette.brand.error[900] as string,
                  marginLeft: 'auto',
                }}
              />
            }
            style={{
              maxWidth: '300px',
              marginBottom: '10px',
            }}
            onClick={onDeactivateUser}
          >
            <DeactivateIcon color={theme.palette.brand.error[900] as string} />
            <span style={{ paddingLeft: '10px' }}>Desativar usuário</span>
          </ButtonDanger>
        )}
        {userAccess.status === 'INACTIVE' && (
          <ButtonPrimary
            typeVariant="outline"
            disabled={userAccess.email === userInfo?.email}
            btnIcon={
              <KeyboardArrowRightOutlined
                style={{
                  color: theme.palette.brand.primary.base as string,
                  marginLeft: 'auto',
                }}
              />
            }
            style={{
              maxWidth: '300px',
              marginBottom: '10px',
            }}
            onClick={onActivateUser}
          >
            <ActivateIcon color={theme.palette.brand.primary.base as string} />
            <span style={{ paddingLeft: '10px' }}>Ativar usuário</span>
          </ButtonPrimary>
        )}
      </UsersAccessSettingsContainer>
      <ConfirmModal
        isColumnButtons={true}
        isOpen={isConfirmModalOpen !== null}
        icon={
          isConfirmModalOpen &&
          confirmModalConfig[isConfirmModalOpen].isDanger ? (
            <AttentionCircularRedIcon />
          ) : (
            <AttentionCircularIcon />
          )
        }
        isLoading={
          isActivateUserLoading ||
          isDeactivateUserLoading ||
          isResendUserLoading ||
          isResetUserLoading
        }
        title={
          isConfirmModalOpen ? confirmModalConfig[isConfirmModalOpen].title : ''
        }
        btnConfirmText={
          isConfirmModalOpen
            ? confirmModalConfig[isConfirmModalOpen].buttonText
            : ''
        }
        color={
          isConfirmModalOpen && confirmModalConfig[isConfirmModalOpen].isDanger
            ? 'danger'
            : 'primary'
        }
        handleConfirm={onConfirmAction}
        handleClose={() => toggleConfirmModalOpen(null)}
      >
        {isConfirmModalOpen
          ? confirmModalConfig[isConfirmModalOpen].description
          : ''}
      </ConfirmModal>
    </BoxSection>
  );
};

export default UsersAccessSettingsTab;
