import { LoginForm } from './components';
import { AuthContainer } from '../../components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuthContext } from 'modules/auth/context';
import { routingPath } from 'app/routes';
import { useSnackbar } from 'app/hooks/useSnackbar';

export default function Login() {
  const [searchParams] = useSearchParams();
  const { requestFirstAccess, validateResetToken } = useAuthContext();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const token = searchParams.get('token');
    const resetToken = searchParams.get('reset-token');

    if (typeof token === 'string') {
      requestFirstAccess(token).then((canRedirect) => {
        if (canRedirect) {
          navigate(`/${routingPath.auth.newPassoword}`, {
            replace: true,
          });
        } else {
          showSnackbar('Token inválido ou expirado', 'error');
        }
      });
    } else if (typeof resetToken === 'string') {
      validateResetToken(resetToken).then((canRedirect) => {
        if (canRedirect) {
          navigate(`/${routingPath.auth.newPassoword}`, {
            replace: true,
          });
        } else {
          showSnackbar('Token inválido ou expirado', 'error');
        }
      });
    }
  }, []);

  return (
    <AuthContainer>
      <LoginForm />
    </AuthContainer>
  );
}
