import styled from '@emotion/styled';

export const NaturalPersonViewPageSection = styled.div`
  margin-bottom: 50px;
`;

export const NaturalPersonViewBoxContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  display: flex;
  margin-top: 32px;
  min-height: 80px;
  align-items: center;
  padding: 20px 28px;
  border-radius: 10px;
  width: 100%;
`;

export const NaturalPersonViewAddressContent = styled.div`
  
`;

export const NaturalPersonViewAddressIcon = styled.div`
  padding-right: 28px;
`;
