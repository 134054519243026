import styled from '@emotion/styled';

export interface ITextLDefaultWrapperProps {
  weight?: 'regular' | 'bold' | 'semibold' | 'medium';
}

export const TextLDefaultWrapper = styled.p<ITextLDefaultWrapperProps>`
  font-family: 'Inter';
  font-size: 18px;
  color: ${({ theme }) => theme.palette?.brand.secondary.base};
  font-weight: ${({ weight }) => (weight ? weight : 'regular')};
  line-height: 27px;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
    font-size: clamp(12px, 1.5vw, 16px);
  }
`;
