import { BoxSection } from 'app/components';
import { UsersAccessPageContentWrapper, HeadingArea } from './styles';
import { Heading4 } from 'celcoin-design-system';
import { UsersAccessListContent } from '../UsersAccessListContent';

const UsersAccessPageContent = () => {
  return (
    <UsersAccessPageContentWrapper>
      <BoxSection boxVariant="rounded">
        <HeadingArea>
          <Heading4 fontWeight="Sb">Gestão de Acessos</Heading4>
        </HeadingArea>
        <UsersAccessListContent />
      </BoxSection>
    </UsersAccessPageContentWrapper>
  );
};

export default UsersAccessPageContent;
