import styled from '@emotion/styled';

export const NaturalPersonViewPageSection = styled.div`
  margin-bottom: 50px;
`;

export const NaturalPersonViewBoxContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  display: flex;
  margin-top: 16px;
  min-height: 80px;
  align-items: center;
  padding: 20px 28px;
  border-radius: 10px;
  width: 100%;
`;

export const NaturalPersonViewBankContent = styled.div`
  
`;

export const NaturalPersonViewBankBox = styled.div`
  margin-top: 32px;
`;

export const NaturalPersonViewBankIndex = styled.div`
  font-family: 'Urbanist';
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  padding-right: 28px;
  max-width: 50px;
`;

export const NaturalPersonViewBankIcon = styled.div`
  padding-right: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50px;
`;

export const PixTypeTogglesArea = styled.div`
margin-bottom: 30px;
`;

export const PixTypeTogglesButton = styled.button<{ selected?: boolean }>`
  border-radius: 5px;
  min-width: 50px;
  font-weight: bold !important;
  min-height: 31px;
  font-size: 14px !important;
  line-height: 21px !important;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-right: 10px;
  padding: 0px 10px;
  background-color: ${({ theme, selected }) =>
    !selected
      ? `${theme.palette?.brand?.primary?.base}26`
      : theme.palette?.brand?.primary?.base} !important;
  color: ${({ theme, selected }) =>
    !selected
      ? `${theme.palette?.brand?.primary?.base}A5}`
      : theme.palette?.common?.white} !important;
`;
