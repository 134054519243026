/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

export const editConditionValidation = yup.object({
  creditLimit: yup.string(),
  maxPaymentAmount: yup.string(),
  interestRate: yup.string(),
  minRequestedAmount: yup.string(),
  minGracePeriod: yup.string(),
  maxGracePeriod: yup.string(),
  dueDay: yup.string(),
  minPaymentNumber: yup.string(),
  maxPaymentNumber: yup.string(),
  tac: yup.string(),
});
