import React, { createContext, useContext, useCallback } from 'react';
import { useAuthContext } from '../auth';
import {
  EAccountPermissions,
  EPermission,
  IPermifyContext,
  ProfilePermissions,
} from './permify.interfaces';

export const PermifyContext = createContext<IPermifyContext>({
  isAuthorized: () => {
    throw new Error('Método não implementado');
  },
  isProfileAuthorized: () => {
    throw new Error('Método não implementado');
  },
});

export interface IPermifyProviderProps {
  children?: React.ReactNode;
}

export const PermifyProvider = ({ children }: IPermifyProviderProps) => {
  const { userInfo } = useAuthContext();
  const isAuthorized = useCallback(
    (permission: EPermission): boolean => {
      if (!userInfo) return true;
      const hasPermission = userInfo.roles.reduce((prev, profile) => {
        if (!prev) return ProfilePermissions[profile].includes(permission);
        return prev;
      }, false);
      return hasPermission;
    },
    [userInfo],
  );

  const isProfileAuthorized = useCallback(
    (permission: EAccountPermissions): boolean => {
      if (!userInfo) return false;
      const hasPermission = userInfo.permissions.includes(permission);
      return hasPermission;
    },
    [userInfo],
  );

  return (
    <PermifyContext.Provider
      value={{
        isAuthorized,
        isProfileAuthorized,
      }}
    >
      {children}
    </PermifyContext.Provider>
  );
};

export const usePermify = () => {
  const context = useContext(PermifyContext);

  if (context === undefined) {
    throw new Error('usePermify must be used within a PermifyProvider');
  }
  return context;
};
