import React from 'react';
import { LockOutlined } from '@mui/icons-material';
import { Heading4 } from '../typography';
import { NoAccessContainer } from './style';
import { useTheme } from '@mui/material';

const NoAccess = () => {
  const theme = useTheme();
  return (
    <NoAccessContainer>
      <LockOutlined
        sx={{
          fontSize: '50px',
          marginBottom: '20px',
          color: theme.palette.brand.secondary.base,
        }}
      />
      <Heading4>Acesso Restrito</Heading4>
    </NoAccessContainer>
  );
};

export default NoAccess;
