import { DashboardLayout } from '../../../app/components';
import { routingPath } from '../../../app/routes';
import { CessionsPage } from '../pages';
import { CessionsDetailsPage } from '../pages/Cessions/components';
import { Cnab444Page } from '../pages/Cnab444';

const CessionRoutes = () => {
  return {
    element: <DashboardLayout />,
    children: [
      {
        path: routingPath.cession.cnab444,
        element: <Cnab444Page />,
      },
      {
        path: routingPath.cession.cessions,
        element: <CessionsPage />,
      },
      {
        path: routingPath.cession.cessionsDetails,
        element: <CessionsDetailsPage />,
      },
    ],
  };
};

export default CessionRoutes;
