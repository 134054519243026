const InstallmentsDueIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10.5" r="8.75" fill="#7664FA" />
    <rect
      x="13.8655"
      y="9.73047"
      width="1.54676"
      height="8.96962"
      rx="0.77338"
      transform="rotate(90 13.8655 9.73047)"
      fill="white"
    />
    <rect
      width="1.52107"
      height="5.32374"
      rx="0.760534"
      transform="matrix(0.694959 -0.71905 0.694959 0.71905 9.81836 7.2207)"
      fill="white"
    />
    <path
      d="M14.0471 11.5938L12.99 10.5L14.0471 9.4063L14.4092 9.78098C14.7931 10.1781 14.7931 10.822 14.4092 11.2191L14.0471 11.5938Z"
      fill="white"
    />
    <rect
      width="1.52107"
      height="5.32374"
      rx="0.760534"
      transform="matrix(-0.694959 -0.71905 0.694959 -0.71905 10.8757 14.875)"
      fill="white"
    />
  </svg>
);

export default InstallmentsDueIcon;
