import { useBreadcrumbsContext } from 'app/context';
import { useEffect } from 'react';
import { UsersAccessPageContent } from './components';
import { HomeOutlined } from '@mui/icons-material';
import { useAuthContext } from 'modules/auth/context';
import { useNavigate } from 'react-router-dom';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';

const UsersAccessPage = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const { userInfo } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo?.permissions?.includes(EAccountPermissions.USER_ADMIN)) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setItems(
      [{ to: '/access-management', label: 'Gestão de Acessos' }],
      <HomeOutlined />,
    );

    return () => resetBreadcrumbs();
  }, []);

  if (!userInfo?.permissions?.includes(EAccountPermissions.USER_ADMIN))
    return null;

  return <UsersAccessPageContent />;
};

export default UsersAccessPage;
