import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  padding: 0px 48px 80px;

  .status-description-area {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      padding: 0px 10px;
  };
`;

export const TitleArea = styled.div`
  display: flex;
  margin-top: 71px;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 202px;
  height: 40px;
  border-radius: 10px;
`;

export const TableArea = styled.div`
  width: 100%;
  margin-top: 60px;
  margin-bottom: 48px;
`;

export const ArrowArrowContainer = styled.div`
  cursor: pointer;
`;

export const StatusBox = styled.div`
  border: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  display: flex;
  margin-top: 32px;
  border-radius: 10px;
  padding: 15.5px 24px;
  width: fit-content;
  align-items: center;
  span:first-of-type {
    margin-right: 32px;
  }
`;

export const Spacing = styled.div`
  margin-top: 48px;
`;

export const StepContentInput = styled.div<{
  marginTop?: string;
  isFull?: boolean;
}>`
  width: ${(props) => (props.isFull ? '100%' : '50%')};
  margin-top: ${(props) => props.marginTop || '0px'};
  padding: 15px;
`;

export const StepContentInputArea = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;
