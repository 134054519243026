import { useEffect } from 'react';
import AccountBalanceOutlined from '@mui/icons-material/AccountBalanceOutlined';
import { useBreadcrumbsContext } from '../../../../app/context';
import { MainPageContent } from './components/MainPageContent';
import { MainPageContainer } from './styles';

const ProductsMain = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();

  useEffect(() => {
    setItems(
      [{ to: '/products/main', label: 'Produtos' }],
      <AccountBalanceOutlined />,
    );

    return () => resetBreadcrumbs();
  }, []);

  return (
    <MainPageContainer>
      <MainPageContent />
    </MainPageContainer>
  );
};

export default ProductsMain;
