import { Container } from './styles';

export interface FormLegendCardProps {
  children: React.ReactNode;
}

const FormLegendCard = ({ children }: FormLegendCardProps) => {
  return <Container>{children}</Container>;
};

export default FormLegendCard;
