import styled from '@emotion/styled';

export const HeadTextArea = styled.div`
  margin-top: 32px;
`;

export const InputArea = styled.div`
  width: 100%;
`;

export const InputItem = styled.div`
  margin-top: 24px;
`;

export const ButtonArea = styled.div`
  margin-top: 32px;
  width: 100%;
  height: 48px;
  display: flex;

  .spinner {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const SubtitleArea = styled.div`
  margin-top: 24px;
`;

export const ArrowArea = styled.div`
  width: 16px;
  height: 16px;
`;

export const BackLoginButtonContent = styled.div`
  display: flex ;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
`;
