import { HasAccess } from 'modules/auth/components/HasAccess';
import {
  EAccountPermissions,
  EPermission,
} from 'modules/auth/context/permify/permify.interfaces';
import { DashboardLayout } from '../../../app/components';
import { routingPath } from '../../../app/routes';
import {
  LegalPersonHandler,
  LegalPersonListPage,
  NaturalPersonListPage,
  NaturalPersonViewPage,
} from '../pages';
import LegalPersonViewPage from '../pages/LegalPersonViewPage/LegalPersonViewPage';
import { NaturalPersonHandler } from '../pages/NaturalPersonHandler';
import { HasProfileAccess } from 'modules/auth/components/HasProfileAccess';
import { LegalPersonViewScrDetails } from '../pages/LegalPersonViewPage/components/LegalPersonViewScrTab';
import { NaturalPersonViewScrDetails } from '../pages/NaturalPersonViewPage/components/NaturalPersonViewScrTab';

const CustomerRoutes = () => {
  return {
    element: <DashboardLayout />,
    children: [
      {
        path: routingPath.records.natural,
        element: (
          <HasAccess permission={EPermission.NATURAL_PERSON_LIST}>
            <NaturalPersonListPage />
          </HasAccess>
        ),
      },
      {
        path: routingPath.records.naturalNew,
        element: (
          <HasProfileAccess
            permission={EAccountPermissions.CREATE_PERSON_BUSINESS}
          >
            <NaturalPersonHandler />
          </HasProfileAccess>
        ),
      },
      {
        path: routingPath.records.legal,
        element: (
          <HasAccess permission={EPermission.LEGAL_PERSON_LIST}>
            <LegalPersonListPage />
          </HasAccess>
        ),
      },
      {
        path: routingPath.records.legalNew,
        element: (
          <HasProfileAccess
            permission={EAccountPermissions.CREATE_PERSON_BUSINESS}
          >
            <LegalPersonHandler />
          </HasProfileAccess>
        ),
      },
      {
        path: routingPath.records.legalView,
        element: (
          <HasAccess permission={EPermission.LEGAL_PERSON_VIEW}>
            <LegalPersonViewPage />
          </HasAccess>
        ),
      },
      {
        path: routingPath.records.legalScr,
        element: (
          <HasAccess permission={EPermission.LEGAL_PERSON_VIEW}>
            <LegalPersonViewScrDetails />
          </HasAccess>
        ),
      },
      {
        path: routingPath.records.naturalView,
        element: (
          <HasAccess permission={EPermission.NATURAL_PERSON_VIEW}>
            <NaturalPersonViewPage />
          </HasAccess>
        ),
      },
      {
        path: routingPath.records.naturalScr,
        element: (
          <HasAccess permission={EPermission.NATURAL_PERSON_VIEW}>
            <NaturalPersonViewScrDetails />
          </HasAccess>
        ),
      },
    ],
  };
};

export default CustomerRoutes;
