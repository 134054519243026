import styled from '@emotion/styled';
import { ButtonPrimary } from 'app/components';

export const UserPermissionsPickerWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  border-radius: 10px;
  padding: 0px 24px;
  margin-bottom: 24px;
`;

export const UserPermissionsPickerItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  cursor: pointer;
  padding: 24px 0px;

  &:last-of-type {
    border-bottom: unset;
  }

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .disabled-switch {
    .MuiSwitch-thumb {
      color: #CDCCD1 !important;
    }
    .MuiSwitch-track {
      background-color: #EDEDED !important;
    }
  }
`;

export const UserPermissionsPickerButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    width: auto;
  }
`;

export const UserPermissionsLoading = styled.div`
  padding-right: 30px;
`;

export const UserPermissionsConfigs = styled(ButtonPrimary)`
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;
