export enum DOCUMENT_TYPE {
  BALANCE_SHEETS = 'BALANCE_SHEETS',
  INCOME_STATEMENT = 'INCOME_STATEMENT',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  BYLAWS = 'BYLAWS',
  INCORPORATION_CERTIFICATE = 'INCORPORATION_CERTIFICATE',
  FEDERAL_REGISTER_CERTIFICATE = 'FEDERAL_REGISTER_CERTIFICATE',
  INVOICE = 'INVOICE',
  CERTIFICATE = 'CERTIFICATE',
  LETTER_OF_ATTORNEY = 'LETTER_OF_ATTORNEY',
  CONTRACTS = 'CONTRACTS',
  SCR_AUTHORIZATION = 'SCR_AUTHORIZATION',
  OTHER = 'OTHER',
  NATIONAL_ID = 'NATIONAL_ID',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  PROOF_OF_INCOME = 'PROOF_OF_INCOME',
  CLASS_ID = 'CLASS_ID',
  MILITARY_ID = 'MILITARY_ID',
}
export const BusinessDocumentTypeLabel = {
  [DOCUMENT_TYPE.BALANCE_SHEETS]: 'Balanço Patrimonial',
  [DOCUMENT_TYPE.INCOME_STATEMENT]: 'Comprovante de Renda',
  [DOCUMENT_TYPE.PROOF_OF_ADDRESS]: 'Comprovante de Endereço',
  [DOCUMENT_TYPE.BYLAWS]: 'Estatuto Social',
  [DOCUMENT_TYPE.INCORPORATION_CERTIFICATE]: 'Registro de Incorporação',
  [DOCUMENT_TYPE.FEDERAL_REGISTER_CERTIFICATE]: 'Cartão CNPJ',
  [DOCUMENT_TYPE.INVOICE]: 'Comprovante',
  [DOCUMENT_TYPE.CERTIFICATE]: 'Certidão',
  [DOCUMENT_TYPE.SCR_AUTHORIZATION]: 'Autorização de Consulta SCR',
  [DOCUMENT_TYPE.LETTER_OF_ATTORNEY]: 'Procuração',
  [DOCUMENT_TYPE.CONTRACTS]: 'Contrato',
  [DOCUMENT_TYPE.OTHER]: 'Outro',
};

export const PersonDocumentTypeCreation = {
  [DOCUMENT_TYPE.NATIONAL_ID]: 'Documento de Identificação',
  [DOCUMENT_TYPE.PROOF_OF_ADDRESS]: 'Comprovante de Endereço',
  [DOCUMENT_TYPE.PROOF_OF_INCOME]: 'Comprovante de Renda',
  [DOCUMENT_TYPE.SCR_AUTHORIZATION]: 'Autorização de Consulta SCR',
  [DOCUMENT_TYPE.OTHER]: 'Outro',
};

export const PersonDocumentTypeLabel = {
  [DOCUMENT_TYPE.NATIONAL_ID]: 'Documento de Identificação',
  [DOCUMENT_TYPE.DRIVERS_LICENSE]: 'CNH',
  [DOCUMENT_TYPE.PROOF_OF_ADDRESS]: 'Comprovante de Endereço',
  [DOCUMENT_TYPE.PROOF_OF_INCOME]: 'Comprovante de Renda',
  [DOCUMENT_TYPE.SCR_AUTHORIZATION]: 'Autorização de Consulta SCR',
  [DOCUMENT_TYPE.CLASS_ID]: 'Carteira Funcional',
  [DOCUMENT_TYPE.MILITARY_ID]: 'Identidade Militar',
  [DOCUMENT_TYPE.OTHER]: 'Outro',
};
