import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';

Font.register({
  family: 'monospace',
  src: '/SpaceMonoRegular.ttf',
});

const styles = StyleSheet.create({
  page: {
    justifyContent: 'center',
    fontFamily: 'monospace',
    textTransform: 'uppercase',
  },
  section: {
    textAlign: 'center',
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

interface IApplicationReceiptPDFProps {
  receipt: string;
}

const ApplicationReceiptPDF = ({ receipt }: IApplicationReceiptPDFProps) => (
  <Document>
    {receipt.split('\f').map((part, i) => (
      <Page key={i} size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>{part}</Text>
        </View>
      </Page>
    ))}
  </Document>
);

export default ApplicationReceiptPDF;
