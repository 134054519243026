import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  Heading3,
} from '../../../../../app/components';
import { ArrowBack } from '@mui/icons-material';
import {
  BRAZILIAN_STATES,
  formatCNPJ,
  formatCep,
  formatPhone,
} from '../../../../../app/helpers';
import { useAddress } from 'app/hooks';
import { ApplicationRequestContext } from '../ApplicationRequest';
import {
  CustodianInput,
  StepAddressContentInputArea,
  CustodianInputGroup,
  CustodianButtonsArea,
} from './styles';
import { CalendarInput, CustomInput } from 'celcoin-design-system';
import { CustomSelect } from 'app/components/CustomSelect';
import { custodianSchema } from './validation';

const CustodianStep = () => {
  const { custodian, updateCustodian, onBack, onForward } = useContext(
    ApplicationRequestContext,
  );
  const [citiesList, setCitiesList] = useState<{ value: string }[]>([]);
  const { fetchCities, fetchAddressByPostalCode, isFetchingPostalCode } =
    useAddress();
  const [stateCode, setStateCode] = useState('');
  const [cityManually, setCityManually] = useState(true);

  const formik = useFormik({
    initialValues: {
      taxpayer_id: custodian?.taxpayer_id || '',
      legal_name: custodian?.legal_name || '',
      phone: custodian?.phone?.number
        ? formatPhone(custodian.phone.number)
        : '',
      email_address: custodian?.email_address || '',
      foundation_date: custodian?.foundation_date || '',
      country_code: custodian?.address.country_code || '',
      postal_code: custodian?.address.postal_code || '',
      district: custodian?.address?.district || '',
      street_name: custodian?.address?.street_name || '',
      street_number: custodian?.address?.street_number || '',
      extra_info: custodian?.address?.extra_info || '',
      state_code: custodian?.address?.state_code,
      city: custodian?.address?.city,
      cityManually: custodian?.address?.city?.value ?? '',
    },
    validationSchema: custodianSchema,
    onSubmit: async (values) => {
      updateCustodian({
        email_address: values.email_address,
        foundation_date: values.foundation_date,
        legal_name: values.legal_name,
        phone: {
          country_code: '',
          area_code: '',
          number: values.phone,
        },
        taxpayer_id: values.taxpayer_id,
        address: {
          district: values.district,
          street_name: values.street_name,
          street_number: values.street_number,
          postal_code: values.postal_code,
          extra_info: values.extra_info,
          state_code: values.state_code!,
          city: values.city!,
          ...(citiesList?.length <= 0 && {
            city: { value: values.cityManually, label: values.cityManually },
          }),
          country_code: {
            label: 'Brasil',
            value: 'BRA',
          },
        },
      });
      onForward();
    },
  });

  const parseCities = (cities: { name: string; id: string }[]) => {
    return cities.map((item) => {
      return {
        value: item.name,
        label: item.name,
      };
    });
  };

  const handleCities = async (stateCodeFound?: string) => {
    setCityManually(false);
    try {
      const result = await fetchCities(stateCodeFound ?? stateCode);
      if (!result || result.data.status !== '200') {
        setCityManually(true);
        return [];
      }

      const parsedResult = parseCities(result.data?.body?.city);
      setCitiesList(parsedResult || []);
      return parsedResult;
    } catch (error) {
      setCityManually(true);
    }
  };

  const handleZipode = async (value: string) => {
    if (value.length < 9) return;
    const res = await fetchAddressByPostalCode(value);
    if (res && res.data.status === '200') {
      const values = res.data.body.address;
      const stateCodeFound = BRAZILIAN_STATES.find(
        (state) =>
          state.value.toLocaleLowerCase() === values.state.toLocaleLowerCase(),
      )?.value;
      formik.setFieldValue('district', values.neighborhood);
      formik.setFieldValue('street_name', values.street);
      formik.setFieldValue(
        'state_code',
        BRAZILIAN_STATES.find(
          (state) =>
            state.value.toLocaleLowerCase() ===
            values.state.toLocaleLowerCase(),
        ),
      );

      const resCities = await handleCities(stateCodeFound || '');
      if (resCities && resCities.length > 0) {
        const foundCity = resCities.find(
          (item: { value: string; label: string }) =>
            item.value.toLowerCase() === values.city.toLowerCase(),
        );
        formik.setFieldValue('city', foundCity);
        return;
      }
    }
    setCityManually(true);
  };

  const handleNumber = (numberValue: string) => {
    formik.setFieldValue(
      'street_number',
      numberValue.replace(/(-(?!\d))|[^0-9|-]/g, '') || '',
    );
  };

  useEffect(() => {
    if (stateCode) {
      handleCities();
    }
  }, [stateCode]);

  return (
    <BoxSection boxVariant="rounded">
      <Heading3>
        Informe os dados do <strong>Custodiante</strong> desta solicitação
      </Heading3>
      <StepAddressContentInputArea>
        <CustodianInputGroup>
          <CustodianInput>
            <CustomInput
              name="taxpayer_id"
              id="taxpayer_id"
              value={formatCNPJ(formik.values.taxpayer_id)}
              handleInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="00.000.000/0000-00"
              labelValue="CNPJ*"
              validationError={
                formik.touched.taxpayer_id ? formik.errors.taxpayer_id : ''
              }
            />
          </CustodianInput>
          <CustodianInput>
            <CustomInput
              name="legal_name"
              value={formik.values.legal_name}
              handleInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Ex.: Empresa S.A."
              labelValue="Razão social*"
              validationError={
                formik.touched.legal_name ? formik.errors.legal_name : ''
              }
            />
          </CustodianInput>
        </CustodianInputGroup>
        <CustodianInputGroup>
          <CustodianInput isDatePicker={true}>
            <CalendarInput
              name="foundation_date"
              labelValue="Data de Constituição*"
              placeholder="dd/mm/aaaa"
              value={formik.values.foundation_date}
              onChange={(e) =>
                formik.handleChange({
                  currentTarget: {
                    value: e,
                    name: 'foundation_date',
                  },
                })
              }
              validationError={
                formik.touched.foundation_date
                  ? formik.errors.foundation_date
                  : ''
              }
            />
          </CustodianInput>
          <CustodianInput>
            <CustomInput
              name="email_address"
              value={formik.values.email_address}
              handleInputChange={formik.handleChange}
              placeholder="exemplo@email.com"
              labelValue="E-mail"
              validationError={
                formik.touched.email_address ? formik.errors.email_address : ''
              }
            />
          </CustodianInput>
        </CustodianInputGroup>
        <CustodianInputGroup>
          <CustodianInput>
            <CustomInput
              name="phone"
              value={formatPhone(formik.values.phone)}
              handleInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="(00) 00000-0000"
              labelValue="Telefone"
              validationError={formik.touched.phone ? formik.errors.phone : ''}
            />
          </CustodianInput>
          <CustodianInput>
            <CustomInput
              name="postal_code"
              value={formatCep(formik.values.postal_code)}
              isLoading={isFetchingPostalCode}
              placeholder="Ex.: 00000-000"
              handleInputChange={formik.handleChange}
              onBlur={(evt) => {
                formik.handleBlur(evt);
                if (formik.values.postal_code)
                  handleZipode(formik.values.postal_code);
              }}
              labelValue="CEP"
              validationError={
                formik.touched.postal_code ? formik.errors.postal_code : ''
              }
            />
          </CustodianInput>
        </CustodianInputGroup>
        <CustodianInputGroup>
          <CustodianInput>
            <CustomInput
              name="street_name"
              value={formik.values.street_name}
              handleInputChange={formik.handleChange}
              placeholder="Ex.: Av Presidente Vargas"
              labelValue="Rua/Avenida"
              validationError={
                formik.touched.street_name ? formik.errors.street_name : ''
              }
            />
          </CustodianInput>
          <CustodianInput>
            <CustomInput
              name="street_number"
              value={formik.values.street_number}
              handleInputChange={(e) => handleNumber(e.target.value)}
              placeholder="Ex.: 300"
              labelValue="Número"
              validationError={
                formik.touched.street_number ? formik.errors.street_number : ''
              }
            />
          </CustodianInput>
        </CustodianInputGroup>
        <CustodianInputGroup>
          <CustodianInput isFull={true}>
            <CustomInput
              name="extra_info"
              value={formik.values.extra_info}
              handleInputChange={formik.handleChange}
              placeholder="Ex.: Próximo a escola..."
              labelValue="Complemento (opcional)"
              validationError={
                formik.touched.extra_info ? formik.errors.extra_info : ''
              }
            />
          </CustodianInput>
        </CustodianInputGroup>
        <CustodianInputGroup>
          <CustodianInput>
            <CustomInput
              name="district"
              value={formik.values.district}
              handleInputChange={formik.handleChange}
              placeholder="Ex.: Planalto do Sol"
              labelValue="Bairro"
              validationError={
                formik.touched.district ? formik.errors.district : ''
              }
            />
          </CustodianInput>
          <CustodianInput isHalf={true}>
            <CustomSelect
              labelValue="Estado"
              name="state_code"
              placeholder="Selecione o estado"
              value={
                formik.values.state_code?.value === ''
                  ? undefined
                  : formik.values.state_code
              }
              handleInputChange={(v) => {
                formik.handleChange(v);
                setStateCode(v.currentTarget.value.value);
              }}
              options={BRAZILIAN_STATES}
              validationError={
                formik.touched.state_code ? formik.errors.state_code : ''
              }
            />
          </CustodianInput>
          <CustodianInput isHalf={true}>
            {cityManually ? (
              <CustomInput
                name="cityManually"
                value={formik.values.cityManually}
                handleInputChange={(evt) => {
                  formik.setFieldValue('cityManually', evt.target.value);
                  formik.setFieldValue('city', {
                    label: evt.target.value,
                    value: evt.target.value,
                  });
                }}
                placeholder="Cidade"
                labelValue="Cidade"
                validationError={formik.touched.city ? formik.errors.city : ''}
              />
            ) : (
              <CustomSelect
                labelValue="Cidade"
                name="city"
                placeholder="Selecione a cidade"
                value={
                  formik.values.city?.value === ''
                    ? undefined
                    : formik.values.city
                }
                handleInputChange={formik.handleChange}
                options={citiesList}
                validationError={formik.touched.city ? formik.errors.city : ''}
              />
            )}
          </CustodianInput>
        </CustodianInputGroup>
      </StepAddressContentInputArea>

      <CustodianButtonsArea>
        <ButtonDefault margin={true} onClick={() => onBack()}>
          <ArrowBack />
        </ButtonDefault>

        <ButtonPrimary onClick={formik.submitForm}>Avançar</ButtonPrimary>
      </CustodianButtonsArea>
    </BoxSection>
  );
};

export default CustodianStep;
