import React from 'react';
import { AuthLayout } from '../components/AuthLayout';
import { routingPath } from '../../../app/routes/config';
import {
  EnableTwoFactorAuth,
  Login,
  NewPassword,
  PasswordRecovery,
  PasswordRecoveryConfirmation,
  TwoFactorAuth,
} from '../pages';

const AuthRoutes = () => {
  return {
    element: <AuthLayout />,
    children: [
      {
        path: routingPath.auth.login,
        element: <Login />,
      },
      {
        path: routingPath.auth.newPassoword,
        element: <NewPassword />,
      },
      {
        path: routingPath.auth.passwordRecovery,
        element: <PasswordRecovery />,
      },
      {
        path: routingPath.auth.passwordRecoveryConfirmation,
        element: <PasswordRecoveryConfirmation />,
      },
      {
        path: routingPath.auth.twoFactor,
        element: <TwoFactorAuth />,
      },
      {
        path: routingPath.auth.enableTwoFactor,
        element: <EnableTwoFactorAuth />,
      },
    ],
  };
};

export default AuthRoutes;
