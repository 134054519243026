import { Divider, DrawerProps, useTheme } from '@mui/material';
import { BaseSidesheet, Heading3 } from 'celcoin-design-system';
import { SideSheetContent, CloseIconWrapper, SideSheetHeader } from './styles';
import { CloseIcon } from '../Icons';
import { useWindowSize } from 'app/hooks';

export interface ISideSheet extends DrawerProps {
  handleClose: () => void;
  title: string;
  style?: React.CSSProperties;
  subtitleItem?: JSX.Element | null;
  customHeader?: JSX.Element | null;
  isBigger?: boolean;
}

const SideSheet = ({
  title,
  handleClose,
  style,
  open,
  children,
  subtitleItem,
  customHeader,
  isBigger,
}: ISideSheet) => {
  const theme = useTheme();
  const { width } = useWindowSize();
  return (
    <BaseSidesheet
      style={style}
      hideBackdrop
      open={open}
      handleClose={handleClose}
    >
      {customHeader ? (
        customHeader
      ) : (
        <>
          <SideSheetHeader>
            <div>
              <Heading3
                style={
                  !isBigger
                    ? {
                        maxWidth: '500px',
                      }
                    : {}
                }
              >
                {title}
              </Heading3>
              {subtitleItem && subtitleItem}
            </div>
            <CloseIconWrapper onClick={handleClose}>
              <CloseIcon color={theme.palette.brand.secondary.base as string} />
            </CloseIconWrapper>
          </SideSheetHeader>
          <Divider />
        </>
      )}
      <SideSheetContent
        isBigger={isBigger}
        style={{
          width:
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            width! > theme.breakpoints.values.tablet
              ? theme.spacing(75)
              : theme.spacing(50),
        }}
      >
        {children}
      </SideSheetContent>
    </BaseSidesheet>
  );
};

export default SideSheet;
