import styled from '@emotion/styled';

export const LegalPersonListPageContentContainer = styled.div`
  width: 100%;
  height: 100%;
  border-top: 1px solid #DBD6FE;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      padding: 20px;
  };
`;

export const HeaderArea = styled.div`
  width: 100%;
  position: relative;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      flex-direction: column;
  }
`;

export const FilterArea = styled.div`
  display: flex;
  margin-top: 44px;
  width: 100%;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      flex-direction: column;

  }
`;

export const TableArea = styled.div`
  margin-top: 48px;
  position: relative;
`;

export const ButtonArea = styled.div`
  height: 56px;
  position: absolute;
  right: 0px;
  top: 0px;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      margin-top: 20px;
  }
`;
