import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  useRoutes,
} from 'react-router-dom';
import {
  HttpClient as HttpClientIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
} from '@sentry/integrations';
import './app/styles/globalStyles.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: process.env.NODE_ENV !== 'development',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new HttpClientIntegration({
      failedRequestStatusCodes: [[500, 505], 400, 507],
      failedRequestTargets: [
        process.env.REACT_APP_DASHBOARD_API_HOST as string,
      ],
    }),
    new ExtraErrorDataIntegration({
      depth: 3,
    }),
  ],
  tracesSampleRate: 0.6,
});

export const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
