import styled from '@emotion/styled';

export const PendenciesModalContainer = styled.div`
    width: 100%;
`;

export const PendenciesModalContent = styled.div`
`;

export const PendenciesModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
`;

export const PendenciesModalFooter = styled.div<{
  isColumnButtons?: boolean;
  isLoading?: boolean;
}>`
    margin-top: 32px;
    display: flex;
    justify-content: ${({ isLoading }) =>
      isLoading ? 'center' : 'space-between'};
    width: 100%;
    flex-direction: ${({ isColumnButtons }) =>
      isColumnButtons ? 'column-reverse' : 'row'};
`;

export const PendenciesModalList = styled.div`
    margin-bottom: 24px;
`;

export const CloseButton = styled.button`
    margin-left: auto;
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
    cursor: pointer;
	outline: inherit;
`;

export const CancelButton = styled.button`
    background: none;
    border: none;
    border-radius: 10px;
    border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
    color: ${({ theme }) => theme.palette?.brand?.primary.base};
	padding: 0;
    cursor: pointer;
	outline: none;
    min-width: 211px;
    min-height: 52px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 20px;
    transition: background-color .1s;

    &:hover {
        background-color: ${({ theme }) => theme.palette?.brand?.gray[100]};
    }
`;
