import {
  EVariableRelation,
  EVariableType,
  TVariable,
  VariablesHttpService,
} from 'app/services/variablesHttpService';
import { useState } from 'react';
import { useSnackbar } from './useSnackbar';
import { ProductsHttpService } from 'modules/products/services/api';

const useCustomVariables = () => {
  const [isCustomVariablesLoading, toggleCustomVariablesLoading] =
    useState(false);
  const { showSnackbar } = useSnackbar();

  const getCustomVariablesForPerson = async (): Promise<TVariable[] | null> => {
    try {
      toggleCustomVariablesLoading(true);

      const productsHttpService = new ProductsHttpService();
      const customVariables = await productsHttpService.getAllCustomVariables();
      const personVariables = customVariables.reduce(
        (variables, customVariable) => {
          return ([] as TVariable[]).concat(
            variables,
            customVariable.borrower.person
              .filter(
                (variable) =>
                  variables.findIndex((v) => v.id === variable.id) < 0,
              )
              .map<TVariable>((variable) => ({
                description: variable.description,
                display_name: variable.display_name,
                id: variable.id,
                name: variable.name?.replace('custom_variables.', '') ?? '',
                relation: EVariableRelation.PERSON,
                type: variable.type as EVariableType,
              })),
          );
        },
        [] as TVariable[],
      );

      return personVariables;
    } catch (error) {
      console.log(error);
    } finally {
      toggleCustomVariablesLoading(false);
    }
    return null;
  };

  const getCustomVariablesForBusiness = async (): Promise<
    TVariable[] | null
  > => {
    try {
      toggleCustomVariablesLoading(true);

      const productsHttpService = new ProductsHttpService();
      const customVariables = await productsHttpService.getAllCustomVariables();
      const businessVariables = customVariables.reduce(
        (variables, customVariable) => {
          return ([] as TVariable[]).concat(
            variables,
            customVariable.borrower.business
              .filter(
                (variable) =>
                  variables.findIndex((v) => v.id === variable.id) < 0,
              )
              .map<TVariable>((variable) => ({
                description: variable.description,
                display_name: variable.display_name,
                id: variable.id,
                name: variable.name?.replace('custom_variables.', '') ?? '',
                relation: EVariableRelation.BUSINESS,
                type: variable.type as EVariableType,
              })),
            customVariable.employer
              .filter(
                (variable) =>
                  variables.findIndex((v) => v.id === variable.id) < 0,
              )
              .map<TVariable>((variable) => ({
                description: variable.description,
                display_name: variable.display_name,
                id: variable.id,
                name: variable.name?.replace('custom_variables.', '') ?? '',
                relation: EVariableRelation.BUSINESS,
                type: variable.type as EVariableType,
              })),
          );
        },
        [] as TVariable[],
      );

      return businessVariables;
    } catch (error) {
      console.log(error);
    } finally {
      toggleCustomVariablesLoading(false);
    }
    return null;
  };

  const getCustomVariablesForApplication = async (): Promise<
    TVariable[] | null
  > => {
    try {
      toggleCustomVariablesLoading(true);

      const productsHttpService = new ProductsHttpService();
      const customVariables = await productsHttpService.getAllCustomVariables();
      const applicationVariables = customVariables.reduce(
        (variables, customVariable) => {
          return ([] as TVariable[]).concat(
            variables,
            customVariable.application
              .filter(
                (variable) =>
                  variables.findIndex((v) => v.id === variable.id) < 0,
              )
              .map<TVariable>((variable) => ({
                description: variable.description,
                display_name: variable.display_name,
                id: variable.id,
                name: variable.name?.replace('custom_variables.', '') ?? '',
                relation: EVariableRelation.APPLICATION,
                type: variable.type as EVariableType,
              })),
          );
        },
        [] as TVariable[],
      );

      return applicationVariables;
    } catch (error) {
      console.log(error);
    } finally {
      toggleCustomVariablesLoading(false);
    }
    return null;
  };

  const getPersonVariables = async (): Promise<TVariable[]> => {
    try {
      toggleCustomVariablesLoading(true);

      const variablesHttpService = new VariablesHttpService();
      const variables = await variablesHttpService.getPersonVariables();
      return variables || ([] as TVariable[]);
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar as variáveis.');
      toggleCustomVariablesLoading(false);
    } finally {
      toggleCustomVariablesLoading(false);
    }
    return [] as TVariable[];
  };

  const getBusinessVariables = async (): Promise<TVariable[]> => {
    try {
      toggleCustomVariablesLoading(true);

      const variablesHttpService = new VariablesHttpService();
      const variables = await variablesHttpService.getBusinessVariables();
      return variables || ([] as TVariable[]);
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar as variáveis.');
      toggleCustomVariablesLoading(false);
    } finally {
      toggleCustomVariablesLoading(false);
    }
    return [] as TVariable[];
  };

  const getApplicationVariables = async (): Promise<TVariable[]> => {
    try {
      toggleCustomVariablesLoading(true);

      const variablesHttpService = new VariablesHttpService();
      const variables = await variablesHttpService.getApplicationVariables();
      return variables || ([] as TVariable[]);
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar as variáveis.');
      toggleCustomVariablesLoading(false);
    } finally {
      toggleCustomVariablesLoading(false);
    }
    return [] as TVariable[];
  };

  return {
    isCustomVariablesLoading,
    getCustomVariablesForPerson,
    getCustomVariablesForBusiness,
    getCustomVariablesForApplication,
    getPersonVariables,
    getBusinessVariables,
    getApplicationVariables,
  };
};

export default useCustomVariables;
