import { useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { StepContentContainer, StepContentContent } from './styles';
import { Heading3, TextM } from 'celcoin-design-system';

export interface IStepContentProps {
  title: string;
  description?: string;
  action?: ReactNode;
  children: ReactNode;
}

const StepContent = ({
  title,
  description,
  children,
  action,
}: IStepContentProps) => {
  const theme = useTheme();
  return (
    <StepContentContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Heading3
            fontWeight="Md"
            style={{ color: theme.palette.brand.secondary.base }}
          >
            {title}
          </Heading3>

          {description && (
            <TextM
              weight="Regular"
              style={{ color: theme.palette.brand.secondary.base }}
            >
              {description}
            </TextM>
          )}
        </div>
        {action ?? null}
      </div>
      <StepContentContent>{children}</StepContentContent>
    </StepContentContainer>
  );
};

export default StepContent;
