/* eslint-disable @typescript-eslint/no-explicit-any */
import TagManager from 'react-gtm-module';

export class GoogleTagManager {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static dataLayer: any = {};

  static initialize() {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID || '',
    });
  }

  static push(data: object) {
    this.dataLayer = { ...this.dataLayer, ...data };
    TagManager.dataLayer({ dataLayer: this.dataLayer });
  }

  static clearDataLayer(itemsToClear: any) {
    TagManager.dataLayer({
      dataLayer: {
        event: null,
        action: null,
        ...itemsToClear,
      },
    });
  }

  public static sendGtmData(dataLayerObject: object) {
    TagManager.dataLayer({ dataLayer: dataLayerObject });
  }
}
