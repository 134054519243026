import { BaseHttpService } from 'app/services';
import { ContractFilters } from 'modules/charges/interfaces/contracts';
import {
  InstallmentApiFiltersKey,
  InstallmentFilters,
} from 'modules/charges/interfaces/installments';

export class DebtCollectionsHttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}/charges`;

  getContracts = async (fundingId: string, parameters: ContractFilters) => {
    this.setHeader({
      funding: fundingId,
    });

    const queryParameters = Object.keys(parameters)
      .filter(
        (param) =>
          typeof parameters[param as keyof ContractFilters] !== 'undefined',
      )
      .map((param) => {
        return `${param}=${parameters[param as keyof ContractFilters]}`;
      });

    const res = await this.get(
      `${this.BASE_URL}/contracts?${queryParameters.join('&')}`,
    );
    return res.data;
  };

  getInstallments = async (
    fundingId: string,
    parameters: InstallmentFilters,
  ) => {
    this.setHeader({
      funding: fundingId,
    });
    const queryParameters = Object.keys(parameters)
      .filter(
        (param) =>
          typeof parameters[param as keyof InstallmentFilters] !== 'undefined',
      )
      .map((param) => {
        return `${
          InstallmentApiFiltersKey[
            param as keyof typeof InstallmentApiFiltersKey
          ]
        }=${parameters[param as keyof InstallmentFilters]}`;
      });
    const res = await this.get(
      `${this.BASE_URL}/installments?${queryParameters.join('&')}`,
    );

    return res;
  };
}
