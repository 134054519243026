import styled from '@emotion/styled';
import { BaseModal } from '../../../../../../app/components';

export const ConfirmationModalContainer = styled(BaseModal)`

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .MuiBackdrop-root  {
    background-color: rgb(20, 12, 57, 0.75);
    opacity: 0.9;
  }
`;

export const ConfirmationModalContent = styled.div`
  width: 100%;
  max-width: 504px;
  height: 100%;
  max-height: 394px;
  background-color: ${({ theme }) => theme.palette?.brand.neutral.base};
  border-radius: 10px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0px;
  outline: 0px;

  .bodyText {
    font-size: clamp(12px, 1.5vw, 16px);
  }
`;

export const ImageArea = styled.div`
  width: 69px;
  height: 78px;
  align-self: center;
`;

export const BtnArea = styled.div`
  max-height: 48px;
  width: 100%;
  margin-top: 28px;
`;
