const PersonBusinessIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 21C0 18.8783 0.842855 16.8434 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13C10.1217 13 12.1566 13.8429 13.6569 15.3431C15.1571 16.8434 16 18.8783 16 21H0ZM9 15.083V19H13.659C13.3015 17.9914 12.6812 17.0966 11.8621 16.408C11.0431 15.7193 10.055 15.2619 9 15.083V15.083ZM7 19V15.083C5.945 15.2619 4.95691 15.7193 4.13785 16.408C3.31879 17.0966 2.69847 17.9914 2.341 19H7ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10Z"
        fill={color}
      />
    </svg>
  );
};

export default PersonBusinessIcon;
