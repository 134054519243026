export const KycStatus = {
  APPROVED: 'Aprovado',
  REJECTED: 'Reprovado',
  PROCESSING: 'Processando',
};

export type TKyc<T> = {
  result: T | null;
  created_at: string;
  status: keyof typeof KycStatus;
};

export type TPersonKyc = {
  pf_name: string;
  pf_gender: string;
  pf_birth_date: string;
  pf_father_name: string;
  pf_mother_name: string;
  pf_tax_id_number: string;
  pf_tax_id_status: string;
  pf_birth_country: string;
  pf_tax_id_country: string;
  pf_has_obit_indication: boolean;
  pf_obit_indication_year: string;
  pf_obit_indication_origin: string;
  pf_is_currently_pep: boolean;
  pf_is_currently_sanctioned: boolean;
  pf_ofac_message: string;
};

export type TBusinessKyc = {
  pj_qsa: Array<{
    cpf: string;
    name: string;
    function: string;
    functionCode: string;
    isLegalRepresentative: boolean;
  }>;
  pj_address: {
    city: string;
    state: string;
    number: string;
    street: string;
    zipCode: string;
    complement: string;
    neighborhood: string;
  };
  pj_main_activity_description: string;
  pj_status: string;
  pj_joint_stock: string;
  pj_company_size: string;
  pj_fantasy_name: string;
  pj_opening_date: string;
  pj_phone_number: string;
  pj_main_activity: string;
  pj_official_name: string;
  pj_legal_nature_code: string;
  pj_main_activity_code: string;
  pj_registration_number: string;
  pj_legal_nature_description: string;
  pj_ofac_sanctions_result: string;
};
