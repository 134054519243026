import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  align-items: center ;
  justify-content: center ;
  width: 100%;

  @media screen and (max-width: 769px){
    flex-direction: column;
  }
`;

export const QrCodeArea = styled.div`
  width: 100%;
  padding: 10px  30px;
  display: flex ;
  justify-content: center;
  align-items: center;
`;

export const InputArea = styled.div`
  margin-top: 28px;
`;

export const ListAuthItemArea = styled.div`
  margin-top: 28px;

`;
