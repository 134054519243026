import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FundingsSelector = styled.div`
  width: fit-content;
`;

export const HeadingArea = styled.div`
  background-color: ${({ theme }) => theme.palette?.brand.background.base};
  min-height: 100px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px 28px;
`;

export const BalancePageContentWrapper = styled.div`
  & > section {
    padding: 0px;
    border: 1px solid #DBD6FE;
    padding-bottom: 40px;
  }
`;

export const TableHeadCellContainer = styled.div`
  padding: 14px;
  min-width: 200px;
`;

export const TableBodyCellContainer = styled.div`
  padding: 13px;
  height: 100%;
  margin-left: 0.5em;
  min-width: 200px;
`;

export const BalanceStatementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.div`
  padding: 48px;

  .get-balance-error {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
`;

export const ButtonFilterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filter-qty-container {
    display: flex;
    gap: 6px;
    width: fit-content;
    .filter-qty {
      color: ${({ theme }) => theme.palette?.brand.primary.base}
    }
  }
`;

export const TableArea = styled.div`
  width: 100%;
  max-width: 97vw;
  margin-top: 60px;
  margin-bottom: 48px;
`;

export const MovementStatementIdCell = styled.div`
  display: flex;
  max-width: 100px;
`;

export const MovementStatementMovementCell = styled.div`
  display: flex;
  align-items: center;
`;

export const MovementStatementMovementIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  margin-right: 8px;
`;

export interface TBalanceArea {
  isLoading?: boolean;
}

export const BalanceArea = styled.div<TBalanceArea>`
  margin-top: 30px;
  display: flex;
  gap: 10px;
  align-items: center;

  button {
    width: 48px;
    height: 48px;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

  ${({ isLoading }) =>
    isLoading &&
    css`

    button {
      svg {
        animation: loadingRotate 2s linear infinite;
      }
    }

    @keyframes loadingRotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
  `}
`;

export const MovementsStatementButtonsArea = styled.div`
  display: flex;
  align-items: center;
`;

export const ResultCopyArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;
