import React from "react"

const AttentionStatusCircle = ({color, ...props}: { color: string }) => (
  <svg
    width={22}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.533.886a1.658 1.658 0 0 1 2.934 0l8.803 15.955c.653 1.182-.162 2.659-1.467 2.659H2.197C.893 19.5.077 18.023.73 16.84L9.533.887Z"
      fill={color}
    />
    <path
      d="M9.754 7.062A1 1 0 0 1 10.752 6h.496a1 1 0 0 1 .998 1.062l-.316 5.065a.931.931 0 0 1-1.86 0l-.316-5.065Z"
      fill="#FDFDFD"
    />
    <circle cx={11} cy={15.188} r={1.313} fill="#FDFDFD" />
  </svg>
)

export default AttentionStatusCircle;
