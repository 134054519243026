import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { useState } from 'react';
import { NaturalPersonEditAddressSection } from '.';
import { EditWrapper, Heading3 } from '../../../../../../app/components';
import { AddressIcon } from '../../../../../../app/components/Icons';
import { formatCep } from '../../../../../../app/helpers';
import { TRegisterPersonDataRequest } from '../../../../context';
import {
  NaturalPersonViewAddressContent,
  NaturalPersonViewAddressIcon,
  NaturalPersonViewBoxContainer,
  NaturalPersonViewPageSection,
} from './styles';
import { useTheme } from '@mui/material';

type INaturalPersonViewAddressSectionProps = {
  personData: TRegisterPersonDataRequest;
};

const NaturalPersonViewAddressSection = ({
  personData,
}: INaturalPersonViewAddressSectionProps) => {
  const { address } = personData;
  const theme = useTheme();
  const [isEditOpen, setIsEditOpen] = useState(false);

  return (
    <>
      <NaturalPersonViewPageSection>
        <Heading3
          id="address"
          style={{ color: theme.palette.brand.secondary.base as string }}
        >
          Endereço
        </Heading3>
        <EditWrapper
          handleClick={() => setIsEditOpen(true)}
          permission={EAccountPermissions.UPDATE_PERSON_BUSINESS}
        >
          <NaturalPersonViewBoxContainer>
            <NaturalPersonViewAddressIcon>
              <AddressIcon
                color={theme.palette.brand.secondary.base as string}
              />
            </NaturalPersonViewAddressIcon>
            <NaturalPersonViewAddressContent>
              {address ? (
                <>
                  <span
                    style={{
                      display: 'block',
                    }}
                  >{`${address.street_name}, ${address.street_number}, ${
                    address?.extra_info ? address.extra_info : ''
                  } `}</span>
                  <span
                    style={{
                      display: 'block',
                    }}
                  >{`${address.district} - ${address.city} - ${address.state_code}`}</span>
                  <span
                    style={{
                      display: 'block',
                    }}
                  >{`${formatCep(address.postal_code)}`}</span>
                </>
              ) : (
                '-'
              )}
            </NaturalPersonViewAddressContent>
          </NaturalPersonViewBoxContainer>
        </EditWrapper>
      </NaturalPersonViewPageSection>
      <NaturalPersonEditAddressSection
        isOpen={isEditOpen}
        handleClose={() => setIsEditOpen(false)}
        personData={personData}
      />
    </>
  );
};

export default NaturalPersonViewAddressSection;
