import styled from '@emotion/styled';

export const ConditionsModalContent = styled.div`
  width: 100%;
  height: 100%;
  max-width: 70vw;
  max-height:85vh;
  overflow: auto;
  padding: 0 20px;
`;

export const IconArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

export const InputArea = styled.div`
  width: 100%;
  margin-top: 40px;
  gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
    grid-template-columns: 1fr;
  }
`;

export const LoadingArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const BtnArea = styled.div`
  margin-top: 40px;

  .spinner {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
