const SearchIcon = ({ color = "#757575" }: { color?: string }) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.084.33C4.7.33.334 4.695.334 10.08s4.365 9.75 9.75 9.75a9.707 9.707 0 0 0 5.888-1.977l3.422 3.422a1.333 1.333 0 1 0 1.886-1.885l-3.423-3.423a9.707 9.707 0 0 0 1.978-5.887c0-5.385-4.366-9.75-9.75-9.75Zm-7.083 9.75a7.084 7.084 0 1 1 14.167 0 7.084 7.084 0 0 1-14.167 0Z"
      fill={color}
    />
  </svg>
)

export default SearchIcon;
