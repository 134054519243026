import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import celcoinHeroImg from './celcoinReceiptHeroImg.png';
import { TScrResponse } from 'modules/customer/interfaces/scr';
import { useMemo } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  capitalize,
  formatCurrency,
  normalizeCpfCnpj,
} from 'app/utils/normalizer';
import { TRegisterPersonDataRequest } from 'modules/customer/context';

Font.register({
  family: 'Ubuntu',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 0,
    display: 'flex',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#806DFB',
    padding: '0 40px 0 30px',
    height: '64px',
  },
  headerImg: {
    width: 'auto',
    height: '64px',
  },
  headerTitle: {
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'right',
    color: 'white',
  },
  information: {
    height: '137px',
    backgroundColor: '#F7F7F7',
    padding: '16px 40px',
  },
  informationTitle: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'semibold',
    color: '#302D3F',
  },
  flexBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#302D3F',
    opacity: 0.7,
  },
  textBold: {
    fontSize: '10px',
    fontWeight: 'bold',
    color: 'black',
  },
  divider: {
    width: '100%',
    height: 1,
    margin: '12px 0px',
    backgroundColor: '#EDEDED',
  },
  table: {
    padding: '0px 40px 24px',
  },
  lineBlue: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#806DFB',
    height: '27px',
    borderBottom: '1px solid white',
  },
  lineBlueLight: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#E1E0FF',
    height: '27px',
    borderBottom: '1px solid white',
  },
  lineGray: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#eaeaea',
    height: '20px',
    borderBottom: '1px solid #eaeaea',
  },
  lineCellGray: {
    fontSize: '9px',
    padding: '5px 8px 0',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid white',
  },
  lineDefault: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    minHeight: '27px',
    borderLeft: '1px solid #eaeaea',
    borderTop: '0.5px solid #eaeaea',
    borderBottom: '0.5px solid #eaeaea',
  },
  lineCellDefaultBigger: {
    fontSize: '9px',
    padding: '15px 8px 0',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    opacity: 0.89,
    borderRight: '1px solid #eaeaea',
  },
  lineCellDefault: {
    fontSize: '9px',
    padding: '8px 8px 0',
    height: '27px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    opacity: 0.89,
    borderRight: '1px solid #eaeaea',
  },
  lineCell: {
    fontSize: '9px',
    padding: '8px 8px 0',
    height: '27px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid white',
  },
});

type TScrDebits = {
  descricao: string;
  totalValor: number;
  modalidades: Array<{
    descricao: string;
    variacaoCambial: boolean;
    valor: string;
  }>;
};

type TScrParsed = {
  totalLimits: number;
  totalLimitsCambial: number;
  limits: Array<TScrDebits>;
  totalExpires: number;
  totalExpiresCambial: number;
  expires: Array<TScrDebits>;
  totalExpired: number;
  totalExpiredCambial: number;
  expired: Array<TScrDebits>;
  losses: Array<TScrDebits>;
  totalLosses: number;
  totalLossesCambial: number;
  torelease: Array<TScrDebits>;
  totalToRelease: number;
  totalToReleaseCambial: number;
};

const Divider = () => {
  return (
    <View>
      <View style={{ ...styles.divider, marginTop: 10, marginBottom: 10 }} />
    </View>
  );
};

const LineBlue = (cells: Array<string>) => {
  return (
    <View style={styles.lineBlue} wrap={false}>
      {cells.map((cell, index) => (
        <Text
          key={cell}
          break={false}
          style={[
            styles.lineCell,
            { color: 'white', width: index === 0 ? '50%' : '25%' },
          ]}
          stroke={'10'}
        >
          {cell}
        </Text>
      ))}
    </View>
  );
};

const LineBlueLight = (cells: Array<string>) => {
  return (
    <View style={styles.lineBlueLight} wrap={false}>
      {cells.map((cell, index) => (
        <Text
          key={cell}
          break={false}
          style={[
            styles.lineCell,
            { color: 'black', width: index === 0 ? '50%' : '25%' },
          ]}
          stroke={'10'}
        >
          {cell}
        </Text>
      ))}
    </View>
  );
};

const LineGray = (cells: Array<string>) => {
  return (
    <View style={styles.lineGray} wrap={false}>
      {cells.map((cell, index) => (
        <Text
          key={cell}
          break={false}
          style={[
            styles.lineCellGray,
            { color: 'black', width: index === 0 ? '50%' : '25%' },
          ]}
        >
          {cell}
        </Text>
      ))}
    </View>
  );
};

const LineDefault = (cells: Array<string>, isBigger?: boolean) => {
  return (
    <View style={styles.lineDefault} wrap={false}>
      {cells.map((cell, index) => (
        <Text
          key={cell}
          break={false}
          style={[
            isBigger ? styles.lineCellDefaultBigger : styles.lineCellDefault,
            {
              color: 'black',
              width: index === 0 ? '50%' : '25%',
              paddingTop: index === 0 ? '8px' : isBigger ? '13px' : '8px',
            },
          ]}
          stroke={'10'}
        >
          {cell}
        </Text>
      ))}
    </View>
  );
};

const DebitsTable = (debits: TScrDebits) => {
  const totalValor = debits.modalidades.reduce((count, modalite) => {
    if (!modalite.variacaoCambial) {
      return count + parseFloat(modalite.valor);
    }
    return count;
  }, 0);

  const totalValorCambial = debits.modalidades.reduce((count, modalite) => {
    if (modalite.variacaoCambial) {
      return count + parseFloat(modalite.valor);
    }
    return count;
  }, 0);

  return (
    <>
      {LineGray([
        debits.descricao,
        totalValorCambial > 0
          ? formatCurrency(totalValorCambial) ?? 'R$ -'
          : 'R$ -',
        totalValor > 0 ? formatCurrency(totalValor) ?? 'R$ -' : 'R$ -',
      ])}
      {debits.modalidades.map((modalidate) =>
        LineDefault(
          [
            modalidate.descricao,
            modalidate.variacaoCambial
              ? formatCurrency(parseFloat(modalidate.valor)) ?? 'R$ -'
              : 'R$ -',
            !modalidate.variacaoCambial
              ? formatCurrency(parseFloat(modalidate.valor)) ?? 'R$ -'
              : 'R$ -',
          ],
          modalidate.descricao.length > 60,
        ),
      )}
    </>
  );
};

const NaturalPersonViewScrPDF = ({
  data,
  borrower,
}: {
  data?: TScrResponse;
  borrower: TRegisterPersonDataRequest;
}) => {
  const scrParsed: TScrParsed = useMemo(() => {
    const initialValues = {
      limits: [],
      totalLimits: 0,
      totalLimitsCambial: 0,
      expires: [],
      totalExpires: 0,
      totalExpiresCambial: 0,
      expired: [],
      totalExpired: 0,
      totalExpiredCambial: 0,
      losses: [],
      totalLosses: 0,
      totalLossesCambial: 0,
      torelease: [],
      totalToRelease: 0,
      totalToReleaseCambial: 0,
    };

    if (!data || !data.dados?.analise || Array.isArray(data.dados.analise)) {
      return initialValues;
    }

    const result = data.dados.analise.detalhamento.reduce((parsed, detalhe) => {
      const modalidate = detalhe.modalidade.subtitulo;
      const variacaoCambial = detalhe.variacaoCambial;
      detalhe.vencimentos
        .filter(
          (v) => v.categoria === 'avencer' && !v.descricao.startsWith('Limite'),
        )
        .forEach((vencimento) => {
          const hasVencimento = parsed.expires.findIndex(
            (v) => v.descricao === vencimento.descricao,
          );
          const valor = parseFloat(vencimento.valor);
          if (variacaoCambial !== 'Não') {
            parsed.totalExpiresCambial += valor;
          } else {
            parsed.totalExpires += valor;
          }
          if (hasVencimento >= 0) {
            parsed.expires[hasVencimento].modalidades.push({
              descricao: modalidate,
              variacaoCambial: variacaoCambial !== 'Não',
              valor: vencimento.valor,
            });
          } else {
            parsed.expires.push({
              descricao: vencimento.descricao,
              totalValor: 0,
              modalidades: [
                {
                  descricao: modalidate,
                  variacaoCambial: variacaoCambial !== 'Não',
                  valor: vencimento.valor,
                },
              ],
            });
          }
        });

      detalhe.vencimentos
        .filter(
          (v) => v.categoria === 'avencer' && v.descricao.startsWith('Limite'),
        )
        .forEach((vencimento) => {
          const hasVencimento = parsed.limits.findIndex(
            (v) => v.descricao === vencimento.descricao,
          );
          const valor = parseFloat(vencimento.valor);
          if (variacaoCambial !== 'Não') {
            parsed.totalLimitsCambial += valor;
          } else {
            parsed.totalLimits += valor;
          }
          if (hasVencimento >= 0) {
            parsed.limits[hasVencimento].modalidades.push({
              descricao: modalidate,
              variacaoCambial: variacaoCambial !== 'Não',
              valor: vencimento.valor,
            });
          } else {
            parsed.limits.push({
              descricao: vencimento.descricao,
              totalValor: 0,
              modalidades: [
                {
                  descricao: modalidate,
                  variacaoCambial: variacaoCambial !== 'Não',
                  valor: vencimento.valor,
                },
              ],
            });
          }
        });

      detalhe.vencimentos
        .filter((v) => v.categoria === 'vencido')
        .forEach((vencimento) => {
          const hasVencimento = parsed.expired.findIndex(
            (v) => v.descricao === vencimento.descricao,
          );
          const valor = parseFloat(vencimento.valor);
          if (variacaoCambial !== 'Não') {
            parsed.totalExpiredCambial += valor;
          } else {
            parsed.totalExpired += valor;
          }
          if (hasVencimento >= 0) {
            parsed.expired[hasVencimento].modalidades.push({
              descricao: modalidate,
              variacaoCambial: variacaoCambial !== 'Não',
              valor: vencimento.valor,
            });
          } else {
            parsed.expired.push({
              descricao: vencimento.descricao,
              totalValor: 0,
              modalidades: [
                {
                  descricao: modalidate,
                  variacaoCambial: variacaoCambial !== 'Não',
                  valor: vencimento.valor,
                },
              ],
            });
          }
        });

      detalhe.vencimentos
        .filter((v) => v.categoria === 'prejuizo')
        .forEach((vencimento) => {
          const hasVencimento = parsed.losses.findIndex(
            (v) => v.descricao === vencimento.descricao,
          );
          const valor = parseFloat(vencimento.valor);
          if (variacaoCambial !== 'Não') {
            parsed.totalLossesCambial += valor;
          } else {
            parsed.totalLosses += valor;
          }
          if (hasVencimento >= 0) {
            parsed.losses[hasVencimento].modalidades.push({
              descricao: modalidate,
              variacaoCambial: variacaoCambial !== 'Não',
              valor: vencimento.valor,
            });
          } else {
            parsed.losses.push({
              descricao: vencimento.descricao,
              totalValor: 0,
              modalidades: [
                {
                  descricao: modalidate,
                  variacaoCambial: variacaoCambial !== 'Não',
                  valor: vencimento.valor,
                },
              ],
            });
          }
        });

      detalhe.vencimentos
        .filter((v) => v.categoria === 'torelease')
        .forEach((vencimento) => {
          const hasVencimento = parsed.torelease.findIndex(
            (v) => v.descricao === vencimento.descricao,
          );
          const valor = parseFloat(vencimento.valor);
          if (variacaoCambial !== 'Não') {
            parsed.totalToReleaseCambial += valor;
          } else {
            parsed.totalToRelease += valor;
          }
          if (hasVencimento >= 0) {
            parsed.torelease[hasVencimento].modalidades.push({
              descricao: modalidate,
              variacaoCambial: variacaoCambial !== 'Não',
              valor: vencimento.valor,
            });
          } else {
            parsed.torelease.push({
              descricao: vencimento.descricao,
              totalValor: 0,
              modalidades: [
                {
                  descricao: modalidate,
                  variacaoCambial: variacaoCambial !== 'Não',
                  valor: vencimento.valor,
                },
              ],
            });
          }
        });
      return parsed;
    }, initialValues as TScrParsed);

    return result;
  }, [data]);

  const totalCreditWalletCambial =
    scrParsed.totalExpiredCambial +
    scrParsed.totalExpiresCambial +
    scrParsed.totalLossesCambial;
  const totalCreditWallet =
    scrParsed.totalExpired + scrParsed.totalExpires + scrParsed.totalLosses;

  const totalResponsability =
    totalCreditWallet +
    (data?.dados?.coobrigacaoAssumida
      ? parseFloat(data.dados.coobrigacaoAssumida)
      : 0);

  const totalRiskCambial =
    scrParsed.totalToReleaseCambial + scrParsed.totalLimitsCambial;

  const totalRisk =
    totalResponsability +
    scrParsed.totalToRelease +
    scrParsed.totalLimits +
    (data?.dados?.riscoIndiretoVendor
      ? parseFloat(data.dados.riscoIndiretoVendor)
      : 0);

  const baseDateString = data?.dados?.dataBaseConsultada?.split('-');
  const baseDate = baseDateString
    ? new Date(parseInt(baseDateString[0]), parseInt(baseDateString[1]) - 1, 1)
    : new Date();

  const cambialPercent =
    (totalRiskCambial / (totalRisk + totalRiskCambial)) * 100;
  const totalPercent = (totalRisk / (totalRisk + totalRiskCambial)) * 100;

  return (
    <Document>
      <Page size="A4" wrap={true} style={styles.page}>
        <View style={styles.header}>
          <Image source={celcoinHeroImg} style={styles.headerImg} />
          <Text style={styles.headerTitle}>{`SCR - ${capitalize(
            format(baseDate, 'MMMM', {
              locale: ptBR,
            }),
          )} ${format(baseDate, 'yyyy', {
            locale: ptBR,
          })}`}</Text>
        </View>
        <View style={styles.information}>
          <View style={styles.flexBetween}>
            <Text
              style={styles.informationTitle}
            >{`SCR ${borrower.full_name}`}</Text>
            <Text style={styles.text}>
              {`Situação de processamento: Ifs:${data?.dados.percentualDocumentosProcessados?.replaceAll(
                '.',
                ',',
              )}% Valor: ${data?.dados.percentualVolumeProcessado}%`}
            </Text>
          </View>
          <Divider />
          <View style={styles.flexBetween}>
            <View style={{ width: '33%' }}>
              <Text style={styles.textBold}>CNPJ Solicitante</Text>
              <Text style={[styles.text, { marginTop: '4px' }]}>
                {data?.dados?.cnpjDaIFSolicitante
                  ? `${normalizeCpfCnpj(
                      data?.dados?.cnpjDaIFSolicitante,
                    )}/****-**`
                  : '-'}
              </Text>
            </View>
            <View style={{ width: '33%' }}>
              <Text style={styles.textBold}>Data início relacionamento</Text>
              <Text style={[styles.text, { marginTop: '4px' }]}>
                {data?.dados?.dataInicioRelacionamento
                  ? format(
                      new Date(data?.dados?.dataInicioRelacionamento),
                      'dd/MM/yyyy',
                    )
                  : '-'}
              </Text>
            </View>
            <View style={{ width: '33%' }}>
              <Text style={styles.textBold}>Data base consultada</Text>
              <Text style={[styles.text, { marginTop: '4px' }]}>
                {data?.dados?.dataBaseConsultada}
              </Text>
            </View>
          </View>
          <View style={[styles.flexBetween, { marginTop: '12px' }]}>
            <View style={{ width: '33%' }}>
              <Text style={styles.textBold}>Quantidade de instituições</Text>
              <Text style={[styles.text, { marginTop: '4px' }]}>
                {data?.dados?.quantidadeDeInstituicoes}
              </Text>
            </View>
            <View style={{ width: '33%' }}>
              <Text style={styles.textBold}>Moeda estrangeira</Text>
              <Text style={[styles.text, { marginTop: '4px' }]}>
                {cambialPercent.toFixed(2).replace('.00', '')}%
              </Text>
            </View>
            <View style={{ width: '33%' }}>
              <Text style={styles.textBold}>Total</Text>
              <Text style={[styles.text, { marginTop: '4px' }]}>
                {totalPercent.toFixed(2).replace('.00', '')}%
              </Text>
            </View>
          </View>
        </View>
        <Text render={() => ' '} fixed />
        <View style={styles.table}>
          <View
            style={[
              styles.lineBlue,
              {
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
              },
            ]}
          >
            <Text
              style={[styles.lineCell, { color: 'white', width: '50%' }]}
              stroke={'10'}
            >
              Descrição
            </Text>
            <Text
              style={[styles.lineCell, { color: 'white', width: '25%' }]}
              stroke={'10'}
            >
              Moeda estrangeira
            </Text>
            <Text
              style={[styles.lineCell, { color: 'white', width: '25%' }]}
              stroke={'10'}
            >
              Valor total
            </Text>
          </View>
          {LineBlue([
            'Risco Total (F+G+H+I)',
            totalRiskCambial > 0
              ? formatCurrency(totalRiskCambial) ?? 'R$ -'
              : 'R$ -',
            totalRisk > 0 ? formatCurrency(totalRisk) ?? 'R$ -' : 'R$ -',
          ])}
          {LineBlueLight([
            'Carteira de Crédito (A+B+C)',
            totalCreditWalletCambial > 0
              ? formatCurrency(totalCreditWalletCambial) ?? 'R$ -'
              : 'R$ -',
            totalCreditWallet > 0
              ? formatCurrency(totalCreditWallet) ?? 'R$ -'
              : 'R$ -',
          ])}
          {LineBlueLight([
            'A Vencer (A)',
            scrParsed.totalExpiresCambial > 0
              ? formatCurrency(scrParsed.totalExpiresCambial) ?? 'R$ -'
              : 'R$ -',
            scrParsed.totalExpires > 0
              ? formatCurrency(scrParsed.totalExpires) ?? 'R$ -'
              : 'R$ -',
          ])}
          {scrParsed.expires.map((scr) => DebitsTable(scr))}
          {LineBlueLight([
            'Vencido (B)',
            scrParsed.totalExpiredCambial > 0
              ? formatCurrency(scrParsed.totalExpiredCambial) ?? 'R$ -'
              : 'R$ -',
            scrParsed.totalExpired > 0
              ? formatCurrency(scrParsed.totalExpired) ?? 'R$ -'
              : 'R$ -',
          ])}
          {scrParsed.expired.map((scr) => DebitsTable(scr))}
          {LineBlueLight([
            'Prejuízo (C)',
            scrParsed.totalLossesCambial > 0
              ? formatCurrency(scrParsed.totalLossesCambial) ?? 'R$ -'
              : 'R$ -',
            scrParsed.totalLosses > 0
              ? formatCurrency(scrParsed.totalLosses) ?? 'R$ -'
              : 'R$ -',
          ])}
          {scrParsed.losses.map((scr) => DebitsTable(scr))}
          {LineBlueLight(['Repasses financeiros (D)', 'R$ -', 'R$ -'])}
          {LineBlueLight([
            'Coobrigações (E)',
            'R$ -',
            data?.dados?.coobrigacaoAssumida &&
            parseFloat(data.dados.coobrigacaoAssumida) > 0
              ? formatCurrency(parseFloat(data.dados.coobrigacaoAssumida)) ??
                'R$ -'
              : 'R$ -',
          ])}
          {DebitsTable({
            descricao: 'Garantias prestadas',
            totalValor: 0,
            modalidades: [
              {
                descricao: 'Coobrigações beneficiários de outras garantias',
                variacaoCambial: false,
                valor: data?.dados?.coobrigacaoAssumida ?? '0',
              },
            ],
          })}
          {LineBlue([
            'Responsabilidade Total (F)=A+B+C+D+E',
            totalCreditWalletCambial > 0
              ? formatCurrency(totalCreditWalletCambial) ?? 'R$ -'
              : 'R$ -',
            totalResponsability > 0
              ? formatCurrency(totalResponsability) ?? 'R$ -'
              : 'R$ -',
          ])}
          {LineBlueLight([
            'Créditos a Liberar (G)',
            scrParsed.totalToReleaseCambial > 0
              ? formatCurrency(scrParsed.totalToReleaseCambial) ?? 'R$ -'
              : 'R$ -',
            scrParsed.totalToRelease > 0
              ? formatCurrency(scrParsed.totalToRelease) ?? 'R$ -'
              : 'R$ -',
          ])}
          {scrParsed.torelease.map((scr) => DebitsTable(scr))}
          {LineBlueLight([
            'Limite de Crédito (H)',
            scrParsed.totalLimitsCambial > 0
              ? formatCurrency(scrParsed.totalLimitsCambial) ?? 'R$ -'
              : 'R$ -',
            scrParsed.totalLimits > 0
              ? formatCurrency(scrParsed.totalLimits) ?? 'R$ -'
              : 'R$ -',
          ])}
          {scrParsed.limits.map((scr) => DebitsTable(scr))}
          {LineBlueLight([
            'Risco indireto (I)',
            'R$ -',
            data?.dados?.riscoIndiretoVendor &&
            parseFloat(data.dados.riscoIndiretoVendor) > 0
              ? formatCurrency(parseFloat(data.dados.riscoIndiretoVendor)) ??
                'R$ -'
              : 'R$ -',
          ])}
          {LineBlueLight([
            'Coobrigação recebida',
            'R$ -',
            data?.dados?.coobrigacaoRecebida &&
            parseFloat(data.dados.coobrigacaoRecebida) > 0
              ? formatCurrency(parseFloat(data.dados.coobrigacaoRecebida)) ??
                'R$ -'
              : 'R$ -',
          ])}
        </View>
        <Text
          style={{
            fontSize: '9px',
            color: 'black',
            opacity: 1,
            padding: '15px 40px 16px',
            textAlign: 'center',
          }}
          render={({ pageNumber, totalPages }) =>
            `Página ${pageNumber} de ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default NaturalPersonViewScrPDF;
