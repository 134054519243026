import styled from '@emotion/styled';

export const SideSheetHeader = styled.div`
  padding: 32px 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

export const CloseIconWrapper = styled.div`
  display: flex;
`;

export const SideSheetContent = styled.div<{ isBigger?: boolean }>`
  min-width: ${({ isBigger }) => (isBigger ? '900px' : '600px')};
  padding: 32px 48px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ButtonArea = styled.div`
  display: flex;
  flex: 1;
  justify-self: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;
`;
