const InstallmentsEyeIcon = ({ color }: { color: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_270_475425)">
      <path
        d="M11.9999 3.5C17.3919 3.5 21.8779 7.38 22.8189 12.5C21.8789 17.62 17.3919 21.5 11.9999 21.5C6.60791 21.5 2.12191 17.62 1.18091 12.5C2.12091 7.38 6.60791 3.5 11.9999 3.5ZM11.9999 19.5C14.0394 19.4996 16.0183 18.8068 17.6128 17.5352C19.2072 16.2635 20.3228 14.4883 20.7769 12.5C20.3211 10.5133 19.2048 8.74 17.6105 7.47003C16.0162 6.20005 14.0382 5.50853 11.9999 5.50853C9.96161 5.50853 7.9836 6.20005 6.38928 7.47003C4.79497 8.74 3.67868 10.5133 3.22291 12.5C3.677 14.4883 4.79258 16.2635 6.38705 17.5352C7.98152 18.8068 9.96044 19.4996 11.9999 19.5ZM11.9999 17C10.8064 17 9.66184 16.5259 8.81793 15.682C7.97401 14.8381 7.49991 13.6935 7.49991 12.5C7.49991 11.3065 7.97401 10.1619 8.81793 9.31802C9.66184 8.47411 10.8064 8 11.9999 8C13.1934 8 14.338 8.47411 15.1819 9.31802C16.0258 10.1619 16.4999 11.3065 16.4999 12.5C16.4999 13.6935 16.0258 14.8381 15.1819 15.682C14.338 16.5259 13.1934 17 11.9999 17ZM11.9999 15C12.6629 15 13.2988 14.7366 13.7677 14.2678C14.2365 13.7989 14.4999 13.163 14.4999 12.5C14.4999 11.837 14.2365 11.2011 13.7677 10.7322C13.2988 10.2634 12.6629 10 11.9999 10C11.3369 10 10.701 10.2634 10.2321 10.7322C9.7633 11.2011 9.49991 11.837 9.49991 12.5C9.49991 13.163 9.7633 13.7989 10.2321 14.2678C10.701 14.7366 11.3369 15 11.9999 15Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_270_475425">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default InstallmentsEyeIcon;
