import { TConditionRequiredProperty } from 'modules/customer/interfaces/qualifications';
import {
  TProductDocumentConfiguration,
  TProductVariableConfiguration,
} from '../context/ProductsProvider/products.interfaces';
import { TBorrowerQualification } from '../context/QualificationsProvider/qualifications.interfaces';
import { TBorrowerQualificationRequestType } from '../services/hooks/intefaces';
import { TQualificationRequestApplication } from '../services/hooks/useApplicationsService';

export type TApplicationResponseParsed = TQualificationRequestApplication & {
  borrower: {
    taxpayer_id: string;
    name: string;
  };
};

export type TBorrowerQualificationResponseParsed = TBorrowerQualification & {
  missingDocuments: TProductDocumentConfiguration[];
  missingVariables: TProductVariableConfiguration[];
  missingConditions: TConditionRequiredProperty[];
  missingBankOrPix: boolean;
  identifier?: string;
  borrower: {
    taxpayer_id?: string;
    name?: string;
  };
};

export type TQualificationStatusOption = {
  identifier: string;
  description: string;
};

export type TConditionItems = {
  creditLimit: string | undefined;
  maxPaymentAmount: string | undefined;
  interestRate: string | undefined;
  minRequestedAmount: string | undefined;
  dueDay: string | undefined;
  maxGracePeriod: string | undefined;
  minGracePeriod: string | undefined;
  maxPaymentNumber: string | undefined;
  minPaymentNumber: string | undefined;
  tac: string | undefined;
  qualification?: TBorrowerQualificationRequestType;
};

export enum SignatureProvider {
  CLICKSIGN = 'CLICKSIGN',
  ZAPSIGN = 'ZAPSIGN',
  CELCOIN = 'CELCOIN',
}
