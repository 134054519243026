import {
  Badge,
  BoxSection,
  ButtonPrimary,
  EViewBoxColumnType,
  Heading3,
  ViewBox,
} from 'app/components';
import { useTheme } from '@mui/material';
import { Contract } from 'modules/charges/interfaces/contracts';
import { useCallback, useEffect, useState } from 'react';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { StatusBox, TitleArea } from './styles';
import { CONTRACT_STATUS } from 'modules/charges/components/ContractsTable/ContractsTable';
import { TBadgeVariant } from 'app/components/Badge/Badge';
import { HyperlinkIcon, InstallmentsDueIcon } from 'app/components/Icons';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface IDetailsTabProps {
  contract: Contract;
}

const DetailsTab = ({ contract }: IDetailsTabProps) => {
  const theme = useTheme();
  const { showSnackbar, showSnackbarCopyLink } = useSnackbar();
  const { getPersonList } = usePersonService();
  const { getBusinessList } = useCustomerService();
  const navigate = useNavigate();
  const [borrower, updateBorrower] = useState<
    TRegisterBusinessDataRequest | TRegisterPersonDataRequest
  >();

  useEffect(() => {
    const { debtor } = contract;
    const taxpayerId = debtor[0].taxpayer_id;
    if (taxpayerId.length === 11) {
      getPersonList(0, 'taxpayerId', taxpayerId, 1).then((person) => {
        if (
          person &&
          Array.isArray(person.content) &&
          person.content.length > 0
        ) {
          updateBorrower(person.content[0]);
          return;
        }
        showSnackbar(
          'Ocorreu um problema ao buscar os dados do tomador',
          'error',
        );
      });
    } else {
      getBusinessList(0, 'taxpayerId', taxpayerId, 1).then((business) => {
        if (
          business &&
          Array.isArray(business.content) &&
          business.content.length > 0
        ) {
          updateBorrower(business.content[0]);
          return;
        }
        showSnackbar(
          'Ocorreu um problema ao buscar os dados do tomador',
          'error',
        );
      });
    }
  }, [contract]);

  const handleCopyLink = useCallback(() => {
    const host = `${location.protocol}//${location.host}`;
    if (borrower!.taxpayer_id.length === 11) {
      navigator.clipboard.writeText(
        `${host}/#/records/natural/${borrower?.id}/details`,
      );
    } else {
      navigator.clipboard.writeText(
        `${host}/#/records/legal/${borrower?.id}/details`,
      );
    }
    showSnackbarCopyLink();
  }, [borrower]);

  const handleBorrowerPage = useCallback(() => {
    if (borrower!.taxpayer_id.length === 11) {
      navigate(`/records/natural/${borrower?.id}/details`);
    } else {
      navigate(`/records/legal/${borrower?.id}/details`);
    }
  }, [borrower]);

  return (
    <BoxSection>
      <Heading3>Detalhes</Heading3>
      <StatusBox>
        <span>
          <strong>Status</strong>
        </span>
        <span>
          <Badge
            iconPosition="left"
            isSpaceBetween={true}
            style={{
              borderColor: 'transparent',
              position: 'relative',
            }}
            typeVariant={
              (CONTRACT_STATUS[contract.status as keyof typeof CONTRACT_STATUS]
                ?.color as TBadgeVariant) || 'info'
            }
            icon={
              CONTRACT_STATUS[contract.status as keyof typeof CONTRACT_STATUS]
                ?.icon || <InstallmentsDueIcon />
            }
          >
            {CONTRACT_STATUS[contract.status as keyof typeof CONTRACT_STATUS]
              ?.text || contract.status}
          </Badge>
        </span>
      </StatusBox>
      <ViewBox
        values={
          {
            ...contract,
          } as unknown as {
            [key: string]: string | number;
          }
        }
        textAlign="right"
        columns={[
          {
            path: 'requested_amount',
            label: 'Valor solicitado',
            type: EViewBoxColumnType.CURRENCY,
          },
          {
            path: 'financed_amount',
            label: 'Valor financiado',
            type: EViewBoxColumnType.CURRENCY,
          },
          {
            path: 'total_due_amount',
            label: 'Total devedor',
            type: EViewBoxColumnType.CURRENCY,
          },
          {
            path: 'taxes',
            label: 'Impostos',
            type: EViewBoxColumnType.CURRENCY,
          },
          {
            path: 'fees',
            label: 'Tarifas',
            type: EViewBoxColumnType.CURRENCY,
          },
          {
            path: 'total_due_installments',
            label: 'Parcelas à vencer',
          },
        ]}
      />

      {borrower && (
        <>
          <TitleArea>
            <Heading3>Tomador</Heading3>
            <ButtonPrimary
              style={{
                backgroundColor: 'transparent',
                marginLeft: '32px',
                width: 'fit-content',
              }}
              typeVariant="outline"
              btnIcon={
                <HyperlinkIcon
                  color={theme.palette.brand.primary.base as string}
                />
              }
              iconPosition="left"
              onClick={handleCopyLink}
            ></ButtonPrimary>
            <ButtonPrimary
              style={{
                backgroundColor: 'transparent',
                marginLeft: '10px',
                width: 'fit-content',
              }}
              typeVariant="outline"
              btnIcon={<ArrowForward />}
              iconPosition="right"
              onClick={handleBorrowerPage}
            >
              <span style={{ paddingRight: '5px' }}>Ver página</span>
            </ButtonPrimary>
          </TitleArea>
          {borrower.taxpayer_id.length === 11 && (
            <ViewBox
              values={
                {
                  ...contract.debtor[0],
                } as unknown as {
                  [key: string]: string | number;
                }
              }
              textAlign="right"
              columns={[
                {
                  path: 'full_name',
                  label: 'Nome',
                },
                {
                  path: 'email_address',
                  label: 'E-mail',
                },
                {
                  path: 'birth_date',
                  label: 'Data de nascimento',
                  type: EViewBoxColumnType.DATE,
                },
                {
                  path: 'taxpayer_id',
                  label: 'CPF',
                  type: EViewBoxColumnType.CPF,
                },
                {
                  path: 'phone',
                  label: 'Celular',
                  type: EViewBoxColumnType.PHONE,
                },
                {
                  path: 'created_at',
                  label: 'Cadastrado em',
                  type: EViewBoxColumnType.DATE,
                },
              ]}
            />
          )}
          {borrower.taxpayer_id.length > 11 && (
            <ViewBox
              values={
                {
                  ...contract.debtor[0],
                } as unknown as {
                  [key: string]: string | number;
                }
              }
              textAlign="right"
              columns={[
                {
                  path: 'legal_name',
                  label: 'Razão Social',
                },
                {
                  path: 'email_address',
                  label: 'E-mail',
                },
                {
                  path: 'foundation_date',
                  label: 'Data de constituição',
                  type: EViewBoxColumnType.DATE,
                },
                {
                  path: 'taxpayer_id',
                  label: 'CNPJ',
                  type: EViewBoxColumnType.CPF,
                },
                {
                  path: 'phone',
                  label: 'Celular',
                  type: EViewBoxColumnType.PHONE,
                },
                {
                  path: 'created_at',
                  label: 'Cadastrado em',
                  type: EViewBoxColumnType.DATE,
                },
              ]}
            />
          )}
        </>
      )}
    </BoxSection>
  );
};

export default DetailsTab;
