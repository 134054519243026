/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

export const editConditionValidation = yup.object({
  creditLimit: yup.string(),
  maxPaymentAmount: yup.string(),
  minRequestedAmount: yup.string(),
  minGracePeriod: yup.string(),
  maxGracePeriod: yup.string(),
  minPaymentNumber: yup.string(),
  maxPaymentNumber: yup.string().when('interestRate', {
    is: (val: any) => val,
    then: () => yup.string().required('Campo obrigatório'),
  }),
  interestRate: yup.string(),
});

export const editConditionValidationRequired = yup.object({
  creditLimit: yup
    .string()
    .required('Campo obrigatório')
    .test({
      name: 'creditLimit field',
      test: (value: unknown, { createError }) => {
        return Number((value as string)?.replace(',', '.')) === 0
          ? createError({
              message: 'Insira um valor',
              path: 'creditLimit',
            })
          : true;
      },
    }),
  maxPaymentAmount: yup
    .string()
    .required('Campo obrigatório')
    .test({
      name: 'maxPaymentAmount field',
      test: (value: unknown, { createError }) => {
        return Number((value as string)?.replace(',', '.')) === 0
          ? createError({
              message: 'Insira um valor',
              path: 'maxPaymentAmount',
            })
          : true;
      },
    }),
  minRequestedAmount: yup.string(),
  minGracePeriod: yup.string(),
  maxGracePeriod: yup.string(),
  minPaymentNumber: yup.string().required('Campo obrigatório'),
  maxPaymentNumber: yup.string().required('Campo obrigatório'),
  interestRate: yup.string().required('Campo obrigatório'),
});
