import { BoxSection } from '../../../../../../app/components';
import { TRegisterBusinessDataRequest } from '../../../../context';
import { LegalPersonViewAboutSection } from '../LegalPersonViewAboutSection';
import { LegalPersonViewAddressSection } from '../LegalPersonViewAddressSection';
import { LegalPersonViewBankSection } from '../LegalPersonViewBankSection';
import { LegalPersonViewBillingAddressSection } from '../LegalPersonViewBillingAddressSection';
import { LegalPersonViewDocumentsSection } from '../LegalPersonViewDocumentsSection';

type ILegalPersonViewDetailsTabProps = {
  business: TRegisterBusinessDataRequest;
};

const LegalPersonViewDetailsTab = ({
  business,
}: ILegalPersonViewDetailsTabProps) => {
  return (
    <BoxSection>
      <LegalPersonViewAboutSection business={business} />
      <LegalPersonViewAddressSection business={business} />
      <LegalPersonViewBillingAddressSection business={business} />
      <LegalPersonViewBankSection business={business} />
      <LegalPersonViewDocumentsSection business={business} />
    </BoxSection>
  );
};

export default LegalPersonViewDetailsTab;
