import React, { ButtonHTMLAttributes } from 'react';
import { ButtonDefaultContainer } from './styles';

export interface IButtonDefaultProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  margin?: boolean;
  roundType?: 'circular' | 'normal';
}

const ButtonDefault = ({
  children,
  roundType,
  margin,
  ...props
}: IButtonDefaultProps) => {
  return (
    <ButtonDefaultContainer
      {...props}
      style={{
        ...props.style,
        margin: margin ? '0px 15px' : '0px',
        borderRadius:
          props.style?.borderRadius || roundType === 'circular'
            ? '80px'
            : '10px',
      }}
    >
      {children}
    </ButtonDefaultContainer>
  );
};

export default ButtonDefault;
