import { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  ButtonPrimary,
  CloseIcon,
  CustomCircularIndicator,
  TextL,
  TextM,
  UploadCSV,
} from 'app/components';
import { saveAs } from 'file-saver';
import { AttentionStatusCircle } from 'app/components/Icons';
import { useProductsContext } from 'modules/products';
import { useCustomerContext } from 'modules/customer/context';
import {
  ButtonArea,
  LoadingContainer,
  ModalHeader,
  ModalSection,
  SectionContent,
  UploadCsvModalContainer,
} from './styles';
import { useProductsService } from 'modules/products/services/hooks';
import { useTheme } from '@mui/material';
import { useUploadRegisterCSV } from 'modules/customer/hooks';
import { IFile } from 'modules/customer/hooks/useUploadRegisterCSV';
import { TProducts } from 'modules/products/context';
import { useSnackbar } from 'app/hooks/useSnackbar';
import * as yup from 'yup';
import { BaseModal, TCustomValue } from 'celcoin-design-system';
import { CustomSelect } from 'app/components/CustomSelect';

const csvModalValidation = yup.object({
  product: yup
    .object({
      value: yup.string().required('Selecione o Produto'),
      label: yup.string(),
    })
    .nullable()
    .required('Selecione o Produto'),
});

export interface IUploadCsvModal {
  isOpen: boolean;
  handleClose: () => void;
  handleUpload: (
    product: TProducts,
    func: string,
    fileData: IFile | undefined,
  ) => void;
}

const UploadCsvModal = ({
  isOpen,
  handleClose,
  handleUpload,
}: IUploadCsvModal) => {
  const [uploadedCsvData, setUploadedCsvData] = useState<IFile>();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const { getProductVariablesCsv } = useProductsService();
  const { handleUploadFiles } = useUploadRegisterCSV();
  const {
    state: { product, func },
  } = useCustomerContext();

  // const { setProductData, setFunctionType } = useCustomer();

  const formik = useFormik({
    initialValues: {
      product: product.name
        ? { label: product.name, value: product.id, product }
        : undefined,
      function: func.label ? func : undefined,
    },
    validationSchema: csvModalValidation,
    validateOnMount: true,
    onSubmit: (values) => {
      handleUpload(
        values.product?.product || ({} as TProducts),
        values.function?.value || '',
        uploadedCsvData,
      );
    },
  });

  const {
    state: { content },
  } = useProductsContext();

  const { getProducts, getProductsLoading } = useProductsService();

  const handleDrop = useCallback((files: File[]) => {
    const resFile = handleUploadFiles(files);
    setUploadedCsvData(resFile[0]);
  }, []);

  const handleRemoveCSV = useCallback(() => {
    setUploadedCsvData(undefined);
  }, []);

  const clearFile = useCallback(() => {
    setUploadedCsvData(undefined);
  }, []);

  const fetchProducts = async () => {
    try {
      await getProducts();
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar as informações para o upload');
      handleClose();
    }
  };

  const downloadCsvFile = useCallback(() => {
    const selectedProduct = formik.values['product'];
    if (
      typeof selectedProduct === 'object' &&
      typeof selectedProduct?.value === 'string'
    ) {
      getProductVariablesCsv(selectedProduct?.value as string).then((csv) => {
        const date = new Date().toLocaleDateString().replaceAll('/', '_');
        saveAs(
          new Blob([csv?.data]),
          `remessa_tomadores_${selectedProduct?.label
            ?.replaceAll(' ', '_')
            .toLowerCase()}_${date}.csv`,
        );
      });
    } else {
      showSnackbar('Selecione um produto antes de baixar o modelo CSV', 'info');
    }
  }, [formik]);

  useEffect(() => {
    if (isOpen) {
      fetchProducts();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      clearFile();
    }
  }, [isOpen]);

  return (
    <BaseModal open={isOpen} onClose={handleClose}>
      <UploadCsvModalContainer>
        <ModalHeader>
          <TextL>Importação de pessoas</TextL>
          <div onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </ModalHeader>
        {getProductsLoading ? (
          <LoadingContainer>
            <CustomCircularIndicator
              sx={{ color: theme.palette.brand.primary.base }}
            />
          </LoadingContainer>
        ) : (
          <>
            <ModalSection>
              <TextL>1. Escolha o Produto.</TextL>
              <SectionContent>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <CustomSelect
                    labelValue="Produto"
                    name="product"
                    placeholder="Selecione o produto"
                    value={formik.values.product}
                    handleInputChange={(v) => {
                      formik.handleChange(v);
                    }}
                    onBlur={formik.handleBlur}
                    options={content.map((prd) => {
                      return {
                        label: prd.name,
                        value: prd.id,
                        product: prd,
                      };
                    })}
                    validationError={
                      formik.touched.product && formik.errors.product
                        ? (formik.errors.product as TCustomValue).value ||
                          formik.errors.product
                        : ''
                    }
                  />
                  {/* <CustomSelect
                    labelValue="Função"
                    name="function"
                    placeholder="Selecione a função"
                    value={
                      formik.values.function as {
                        label: string;
                        value: EFunctions;
                      }
                    }
                    handleInputChange={(v) => {
                      formik.handleChange(v);
                    }}
                    options={[
                      { value: EFunctions.BORROWER, label: 'Tomador' },
                      { value: EFunctions.EMPLOYER, label: 'Empregador' },
                    ]}
                    validationError={
                      formik.touched.function ? formik.errors.function : ''
                    }
                  /> */}
                </div>
              </SectionContent>
            </ModalSection>
            <ModalSection>
              <TextL>
                2. Baixe o{' '}
                <strong
                  onClick={() => {
                    if (!formik.errors.product) {
                      downloadCsvFile();
                      return;
                    }
                    formik.setFieldTouched('product');
                    document
                      .querySelector<HTMLInputElement>('#product')
                      ?.focus();
                  }}
                  className={formik.errors.product ? 'disabled' : ''}
                >
                  modelo de arquivo CSV
                </strong>{' '}
                e preencha com os itens a serem importados.{' '}
                <AttentionStatusCircle
                  color={theme.palette.brand.primary.base as string}
                />
              </TextL>
            </ModalSection>
            <ModalSection>
              <TextL>3. Envie o arquivo CSV no campo abaixo.</TextL>
              <SectionContent>
                <UploadCSV
                  fileData={uploadedCsvData}
                  loading={false}
                  handleDrop={handleDrop}
                  handleRemoveCSV={handleRemoveCSV}
                  title="Enviar CSV"
                />
                <div style={{ marginTop: '16px' }}>
                  <TextM>Dicas:</TextM>
                  <ul>
                    <li>
                      <TextM>
                        Evite enviar arquivos com mais de 1000 linhas de uma
                        vez.
                      </TextM>
                    </li>
                    <li>
                      <TextM>
                        Ao fazer testes, tente importar apenas 1 registro. Não é
                        possível atualizar cadastros em lote, caso ocorra um
                        erro de preenchimento.
                      </TextM>
                    </li>
                    <li>
                      <TextM>
                        Se uma pessoa já estiver cadastrada, o registro
                        existente não será alterado.
                      </TextM>
                    </li>
                    <li>
                      <TextM>
                        No relatório de resultados de importação, pessoas já
                        cadastradas resultam em erros.
                      </TextM>
                    </li>
                  </ul>
                </div>
              </SectionContent>
            </ModalSection>
            <ButtonArea>
              <ButtonPrimary typeVariant="outline" onClick={handleClose}>
                Cancelar
              </ButtonPrimary>
              <ButtonPrimary onClick={formik.submitForm}>Avançar</ButtonPrimary>
            </ButtonArea>
          </>
        )}
      </UploadCsvModalContainer>
    </BaseModal>
  );
};

export default UploadCsvModal;
