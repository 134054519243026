import { IProductsAction, IProductsState } from './products.interfaces';
import { productsActionType } from './productsActionType';

export const productsInitialState: IProductsState = {
  content: [],
};

export const productsReducer = (
  state: IProductsState = productsInitialState,
  action: IProductsAction,
) => {
  switch (action.type) {
    case productsActionType.SET_PRODUCTS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
