export interface InstallmentsList {
  content: InstallmentItem[];
  total_items: number;
  total_pages: number;
  has_next: boolean;
}

export interface InstallmentItem {
  id: number;
  debtor: InstallmentDebtor[];
  period: number;
  status: string;
  due_date: string;
  group_id: string;
  days_late: number;
  due_amount: number;
  contract_id: number;
  creditor_id: number;
  external_id?: string;
  running_day: number;
  taxes_amount: number;
  balance_amount: number;
  repayment_date?: string;
  updated_amount: number;
  correction_date: string;
  expected_amount: number;
  interest_amount: number;
  principal_amount: number;
  correction_amount: number;
  default_fine_amount: number;
  paid_amount: number;
  default_interest_amount: number;
}

export interface InstallmentDebtor {
  id: number;
  name: string;
  taxpayer_id: string;
}

export type InstallmentFilters = {
  order?: 'asc' | 'desc';
  page?: number;
  take?: number;
  creditorId?: string;
  externalId?: string;
  contractId?: number;
  groupId?: string;
  debtorId?: string;
  consignorId?: string;
  taxpayerId?: string;
  status?: string;
  minDueDate?: string;
  maxDueDate?: string;
  minRepaymentDate?: string;
  maxRepaymentDate?: string;
};

export const InstallmentApiFiltersKey = {
  order: 'order',
  page: 'page',
  take: 'take',
  creditorId: 'creditor_id',
  externalId: 'external_id',
  contractId: 'contract_id',
  groupId: 'group_id',
  debtorId: 'debtor_id',
  consignorId: 'consignor_id',
  taxpayerId: 'taxpayer_id',
  status: 'status',
  minDueDate: 'min_due_date',
  maxDueDate: 'max_due_date',
  minRepaymentDate: 'min_repayment_date',
  maxRepaymentDate: 'max_repayment_date',
};
