import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ButtonDropdownContainer = styled.div<{ mode: 'TEXT' | 'ICON' }>`
  width: ${({ mode }) => (mode === 'TEXT' ? '100%' : 'auto')};
  padding: 8px 12px;
  display: ${({ mode }) => (mode === 'TEXT' ? 'flex' : 'inline-flex')};
  border: ${({ mode }) => (mode === 'TEXT' ? '1px solid #E6E5EA' : 'none')};
  border-radius: 10px;
  cursor: pointer;
`;

export interface IIconArea {
  isOpen: boolean;
}

export const IconArea = styled.div<IIconArea>`
  width: 13px;
  height: 8px;
  margin-left: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  ${({ isOpen }) =>
    isOpen &&
    css`
    svg {
      transform: rotateZ(180deg) ;
    }
  `}

`;
