import { DashboardLayout } from 'app/components';
import { routingPath } from 'app/routes';
import { UsersAccessHandler, UsersAccessPage } from '../pages';
import { UsersAccessDetails } from '../pages/UsersAccessDetails';

const ManagementRoutes = () => {
  return {
    element: <DashboardLayout />,
    children: [
      {
        path: routingPath.management.access,
        element: <UsersAccessPage />,
      },
      {
        path: routingPath.management.accessNew,
        element: <UsersAccessHandler />,
      },
      {
        path: routingPath.management.accessView,
        element: <UsersAccessDetails />,
      },
    ],
  };
};

export default ManagementRoutes;
