const InstallmentsDueTodayIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10.5" r="8.75" fill="#F97800" />
    <rect
      x="9.27051"
      y="4.66602"
      width="1.45833"
      height="5.83333"
      rx="0.729167"
      fill="#FDFDFD"
    />
    <rect
      x="15.1045"
      y="9.77148"
      width="1.45833"
      height="5.10417"
      rx="0.729166"
      transform="rotate(90 15.1045 9.77148)"
      fill="#FDFDFD"
    />
    <rect
      x="9.27051"
      y="9.77148"
      width="1.45833"
      height="1.45833"
      fill="#FDFDFD"
    />
  </svg>
);

export default InstallmentsDueTodayIcon;
