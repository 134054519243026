import styled from '@emotion/styled';

export const TableWrapper = styled.div`
  th:last-child {
    border-left: 1px solid #E6E5EA;
    max-width: 70px;
  }

  td:last-child {
    border-left: 1px solid #E6E5EA;
    max-width: 70px;
  }
`;
