import React from 'react';
import { MenuContent, MenuWrapper } from './styles';

export interface IMenuDropdown {
  isOpen: boolean;
  hideArrow?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const MenuDropdown = ({
  isOpen,
  children,
  hideArrow,
  style,
  ...props
}: IMenuDropdown) => {
  return (
    <MenuWrapper className="mwrapper" {...props}>
      {isOpen && (
        <>
          <MenuContent style={style} hideArrow={hideArrow}>
            {children}
          </MenuContent>
        </>
      )}
    </MenuWrapper>
  );
};

export default MenuDropdown;
