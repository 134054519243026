import { HashRouter } from 'react-router-dom';
import { AppProvider } from './app/context';
import { AppRoutes } from './app/routes';
import { ToastContainer } from 'app/components/Toast';
import { ThemeProvider as WLThemeProvider } from './app/theme';
import { LoadingGuard } from 'app/components';
import { ModalApiError } from 'app/components/ModalApiError';
import { useEffect } from 'react';
import { GoogleTagManager } from 'app/services/gtm';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import * as Sentry from '@sentry/react';
import queryString from 'query-string';

function App() {
  useEffect(() => {
    GoogleTagManager.initialize();
  }, []);

  return (
    <WLThemeProvider>
      <HashRouter>
        <AppProvider>
          <LoadingGuard>
            <QueryParamProvider
              adapter={ReactRouter6Adapter}
              options={{
                searchStringToObject: queryString.parse,
                objectToSearchString: queryString.stringify,
                skipUpdateWhenNoChange: true,
                removeDefaultsFromUrl: true,
              }}
            >
              <AppRoutes />
            </QueryParamProvider>
            <ToastContainer position="top-center" />
            <ModalApiError />
          </LoadingGuard>
        </AppProvider>
      </HashRouter>
    </WLThemeProvider>
  );
}

export default Sentry.withProfiler(App);
