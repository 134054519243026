import React from 'react';
import { FallbackRender } from '@sentry/react';
import { Theme } from '@mui/material';
import { Container, ModalErrorContent } from './styles';
import { BaseModal } from '../BaseModal';
import { Heading4, TextL } from '../typography';
import { CloseIcon, ErrorCircularIcon } from '../Icons';
import { ButtonPrimary } from '../ButtonPrimary';
import { UserInfo } from 'modules/auth/services/authService';

type ErrorFallbackProps = {
  error: Error;
  componentStack: string;
  eventId: string;
  resetError(): void;
};
export const ErrorFallback = ({
  app_id,
  dns,
  userInfo,
  theme,
  showSnackbarCopy,
}: {
  app_id: string;
  dns: string;
  userInfo: UserInfo | undefined;
  theme: Theme;
  showSnackbarCopy: () => void;
}): FallbackRender => {
  const C = ({ resetError, componentStack }: ErrorFallbackProps) => {
    const handleCopy = () => {
      navigator.clipboard.writeText(
        `app_id: ${app_id || '-'}\nfundings: ${
          userInfo?.fundings?.join(',') || '-'
        }\noriginator_id: ${userInfo?.originator_id || '-'}\nissuer_id: ${
          userInfo?.issuer_id || '-'
        }\ndns: ${dns || '-'}\nerror: ${componentStack}`,
      );
      showSnackbarCopy();
    };

    return (
      <Container>
        <BaseModal open={true} hideBackdrop={true}>
          <ModalErrorContent>
            <div className="header-area">
              <TextL>Erro de sistema</TextL>
              <div className="close-btn" onClick={resetError}>
                <CloseIcon
                  color={theme.palette.brand.secondary.base as string}
                />
              </div>
            </div>
            <div className="body-content">
              <ErrorCircularIcon />
              <Heading4 fontWeight="B" style={{ marginTop: '12px' }}>
                Ocorreu um problema ao carregar a página
              </Heading4>
              <TextL style={{ marginTop: '8px' }}>
                Por gentileza, recarregue a página e tente novamente. Se o erro
                persistir, contate o atendimento Celcoin. Para agilizar seu
                atendimento, você pode copiar os registros de depuração.
              </TextL>
              <ButtonPrimary
                onClick={handleCopy}
                style={{ maxWidth: '300px', marginTop: '24px' }}
              >
                Copiar os registros de depuração
              </ButtonPrimary>
            </div>
          </ModalErrorContent>
        </BaseModal>
      </Container>
    );
  };
  C.displayName = 'ErrorFallback';
  return C;
};
