import { BaseHttpService } from 'app/services';

export class BeneficiaryHttpService extends BaseHttpService {
  baseUrl = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  getBeneficiaryAccounts = async (page = 0, linesPerPage = 10) => {
    const res = await this.get(
      `${this.baseUrl}/originator/beneficiary-accounts?page=${page}&size=${
        linesPerPage || 10
      }`,
    );

    return res;
  };
}
