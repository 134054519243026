import { useState } from 'react';
import { routingPath } from '../../../../../../app/routes';
import {
  FormUnderline,
  Heading3,
  Spinner,
} from '../../../../../../app/components';
import { ButtonPrimary } from '../../../../../../app/components';
import { AuthFormContainer } from '../../../../components';
import {
  ButtonArea,
  HeadTextArea,
  InputArea,
  InputItem,
  ValidationError,
} from './styles';
import { useFormik } from 'formik';
import { loginSchema } from '../../../../helpers';
import { useAuthContext } from 'modules/auth/context';
import { useNavigate } from 'react-router-dom';
import { CustomInput } from 'celcoin-design-system';

export const LoginForm = () => {
  const [newPassword, setNewPassword] = useState('');
  const { login, isAuthLoading } = useAuthContext();
  const [error, setError] = useState<string | false>(false);
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [isNewRegister] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      login(values.email, values.password)
        .then((isLogged) => {
          if (isLogged) {
            setTimeout(() => navigate(`/${routingPath.products.base}`));
            return;
          }
          setError('Não foi possível autenticar, tente novamente.');
        })
        .catch(() => {
          setError('Usuário ou senha inválida, tente novamente.');
        });
    },
  });

  return (
    <AuthFormContainer>
      <HeadTextArea>
        <Heading3 fontWeight="B">Acesse sua conta</Heading3>
      </HeadTextArea>
      <InputArea>
        <InputItem>
          <CustomInput
            name="email"
            value={formik.values.email}
            handleInputChange={formik.handleChange}
            placeholder="Digite seu email"
            labelValue="EMAIL"
            validationError={formik.touched.email ? formik.errors.email : ''}
          />
        </InputItem>
        <InputItem>
          <CustomInput
            name="password"
            value={formik.values.password}
            type="password"
            placeholder="Digite sua senha"
            handleInputChange={formik.handleChange}
            labelValue="SENHA"
            validationError={formik.touched.email ? formik.errors.password : ''}
          />
        </InputItem>
        <FormUnderline to={routingPath.auth.passwordRecovery}>
          Esqueceu sua senha?
        </FormUnderline>
      </InputArea>
      {isNewRegister && (
        <InputArea>
          <InputItem>
            <CustomInput
              name="newPassword"
              value={newPassword}
              handleInputChange={(e) => setNewPassword(e.target.value)}
              labelValue="NOVA SENHA"
              placeholder="Digite a nova senha"
            />
          </InputItem>
          <InputItem>
            <CustomInput
              name="newPasswordConfirmation"
              value={newPasswordConfirmation}
              type="password"
              handleInputChange={(e) =>
                setNewPasswordConfirmation(e.target.value)
              }
              labelValue="CONFIRMAR NOVA SENHA"
              placeholder="Digite a nova senha"
            />
          </InputItem>
        </InputArea>
      )}
      {typeof error === 'string' && <ValidationError>{error}</ValidationError>}
      <ButtonArea>
        {isAuthLoading ? (
          <div className="spinner">
            <Spinner />
          </div>
        ) : (
          <ButtonPrimary onClick={formik.submitForm}>ENTRAR</ButtonPrimary>
        )}
      </ButtonArea>
    </AuthFormContainer>
  );
};

export default LoginForm;
