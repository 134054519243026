export const getNumbersFromValue = (value = '') =>
  value.replace(/\D+/g, '') || '';

const addDigitToNumber = (number: string) => {
  const digitsStartPos = number.length - 1;
  const accNumber = number.substring(0, digitsStartPos);
  const digitNumber = number.substring(digitsStartPos);
  return accNumber + '-' + digitNumber;
};

export const formatNumberWithDigit = (value: string) => {
  const digits = getNumbersFromValue(value);
  return addDigitToNumber(digits);
};

export const formatBankAccount = (value: string) => {
  const res = formatNumberWithDigit(value).replace(',', '-');
  if (res === '-') {
    return '';
  }
  return res;
};
