import {
  ChevronIcon,
  ConfirmModal,
  DeleteButton,
  DocIcon,
  DownloadButton,
  Heading3,
  PdfIcon,
  TextM,
  TTableHeadCell,
  Upload,
} from '../../../../../../app/components';
import { useTheme } from '@mui/material';
import { TRegisterPersonDataRequest } from '../../../../context';
import {
  NaturalPersonViewBoxContainer,
  NaturalPersonViewBoxTableContainer,
  NaturalPersonViewPageSection,
} from './styles';
import { useCallback, useEffect, useState } from 'react';
import {
  TableBodyFile,
  TableFilesWrapper,
  TableHeadFile,
  UploadArea,
} from '../../../NaturalPersonHandler/steps/styles';
import { useUploadPersonFiles } from '../../../../hooks';
import { formatDateFromBackend } from '../../../../../../app/helpers/dateHelpers';
import { IFile } from '../../../../hooks/useUploadBusinessFiles';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { Table } from 'celcoin-design-system';
import { PersonDocumentTypeLabel } from 'app/helpers/documentTypes';

type INaturalPersonViewDocumentsSectionProps = {
  personData: TRegisterPersonDataRequest;
};

const iconByFileType: { [type: string]: React.ReactNode } = {
  pdf: <PdfIcon />,
  doc: <DocIcon />,
};

const tableBodyFileStyle = {
  display: 'flex',
};

const tableHeadRaw = {
  id: '1',
  content: ['Arquivos', 'Tipo', 'Criado em', 'Baixar', 'Excluir'],
};

type PersonDocumentTypeLabelType = typeof PersonDocumentTypeLabel;

const NaturalPersonViewDocumentsSection = ({
  personData,
}: INaturalPersonViewDocumentsSectionProps) => {
  const theme = useTheme();
  const [tableHeadContent, setTableHeadContent] = useState<
    TTableHeadCell[] | null
  >(null);
  const [isConfirmModalOpen, toggleConfirmModalOpen] = useState<IFile | false>(
    false,
  );
  const { isProfileAuthorized } = usePermify();
  const {
    handleUploadFiles,
    handleDeletePersonFile,
    uploadedFiles,
    uploadedFilesLoading,
    fetchPersonFile,
    downloadPersonFile,
    deleteRequestLoading,
  } = useUploadPersonFiles();

  const onDeleteDocument = useCallback(() => {
    if (isConfirmModalOpen !== false && personData.id) {
      handleDeletePersonFile(
        isConfirmModalOpen.id as string,
        personData.id,
      ).finally(() => {
        toggleConfirmModalOpen(false);
      });
    }
  }, [isConfirmModalOpen, personData]);

  useEffect(() => {
    const parsedHeadContent = tableHeadRaw.content.map((item, index) => {
      if (index === 0) {
        return {
          id: String(index),
          content: (
            <TableHeadFile>
              <TextM weight="Bold">Arquivo</TextM>
              <ChevronIcon />
            </TableHeadFile>
          ),
        };
      } else {
        return {
          id: String(index),
          content: <TextM weight="Bold">{item}</TextM>,
        };
      }
    });

    fetchPersonFile(personData.id as string);
    setTableHeadContent(parsedHeadContent);
  }, []);

  const handleDrop = (acceptedFiles: File[]) => {
    if (personData.id) handleUploadFiles(acceptedFiles, personData.id);
  };

  return (
    <NaturalPersonViewPageSection>
      <Heading3
        id="documents"
        style={{ color: theme.palette.brand.secondary.base as string }}
      >
        Arquivos
      </Heading3>
      {uploadedFiles && uploadedFiles.length > 0 ? (
        <NaturalPersonViewBoxTableContainer>
          <TableFilesWrapper>
            <Table
              tableHeadCell={
                isProfileAuthorized(EAccountPermissions.UPDATE_PERSON_BUSINESS)
                  ? tableHeadContent
                  : tableHeadContent?.slice(0, -1)
              }
              tableBodyContent={uploadedFiles.map((item, index) => {
                const cells = [
                  {
                    id: '1',
                    content: (
                      <TableBodyFile style={tableBodyFileStyle}>
                        <div>
                          {
                            iconByFileType[
                              item?.file_extension?.toLocaleLowerCase() || 'pdf'
                            ]
                          }
                        </div>
                        <TextM weight="Semibold">
                          {item?.file_name ?? 'Arquivo'}
                        </TextM>
                      </TableBodyFile>
                    ),
                  },
                  {
                    id: '2',
                    content: (
                      <TextM weight="Regular">
                        {
                          PersonDocumentTypeLabel[
                            (item?.type ||
                              'OTHER') as keyof PersonDocumentTypeLabelType
                          ]
                        }
                      </TextM>
                    ),
                  },
                  {
                    id: '3',
                    content: (
                      <TextM weight="Regular">
                        {formatDateFromBackend(
                          item.created_at as string,
                        ).toLocaleDateString('pt-BR')}
                      </TextM>
                    ),
                  },
                  {
                    id: '4',
                    content: (
                      <DownloadButton
                        handleClick={() =>
                          downloadPersonFile(
                            personData.id as string,
                            item.id as string,
                            item,
                          )
                        }
                      />
                    ),
                  },
                  {
                    id: '5',
                    content: (
                      <DeleteButton
                        handleClick={() => toggleConfirmModalOpen(item)}
                      />
                    ),
                  },
                ];
                return {
                  id: String(index),
                  cells: isProfileAuthorized(
                    EAccountPermissions.UPDATE_PERSON_BUSINESS,
                  )
                    ? cells
                    : cells?.slice(0, -1),
                };
              })}
            />
          </TableFilesWrapper>
        </NaturalPersonViewBoxTableContainer>
      ) : (
        <NaturalPersonViewBoxContainer>-</NaturalPersonViewBoxContainer>
      )}
      {isProfileAuthorized(EAccountPermissions.UPDATE_PERSON_BUSINESS) && (
        <UploadArea>
          <Upload handleDrop={handleDrop} loading={uploadedFilesLoading} />
        </UploadArea>
      )}
      <ConfirmModal
        isOpen={isConfirmModalOpen !== false}
        title="Exclusão de arquivo"
        color="danger"
        btnConfirmText="Excluir"
        isLoading={deleteRequestLoading}
        handleConfirm={onDeleteDocument}
        handleClose={() => toggleConfirmModalOpen(false)}
        handleCancel={() => toggleConfirmModalOpen(false)}
      >
        Essa ação é irreversivel, você tem certeza que deseja excluir o arquivo?
      </ConfirmModal>
    </NaturalPersonViewPageSection>
  );
};

export default NaturalPersonViewDocumentsSection;
