import { useTheme } from '@mui/material';
import { ButtonPrimary, Heading3, TextM } from 'app/components';
import { ArrowIcon, HyperlinkIcon } from 'app/components/Icons';
import {
  formatCNPJ,
  formatCPF,
  formatPhone,
  roleTypesTranslationParse,
} from 'app/helpers';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { formatCurrency } from 'app/utils/normalizer';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import { TProducts } from 'modules/products/context';
import { TBorrowerQualificationRequestType } from 'modules/products/services/hooks/intefaces';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { TApplicationsParsed } from '../../../ApplicationContent/ApplicationContent';
import {
  ApplicationDetailsHeader,
  ViewBoxContainer,
  ViewBoxContent,
  ViewBoxItem,
} from '../../styles';

const buttonStyle = {
  padding: '11px 15px',
  width: 'fit-content',
};

export interface IBorrowerContent {
  borrowerPerson: TRegisterPersonDataRequest | undefined;
  borrowerBusiness: TRegisterBusinessDataRequest | undefined;
  product: TProducts | undefined;
  applicationData: Partial<TApplicationsParsed> | undefined;
  borrowerQualificationData: TBorrowerQualificationRequestType | undefined;
}

const BorrowerContent = ({
  borrowerPerson,
  borrowerBusiness,
  product,
  borrowerQualificationData,
}: IBorrowerContent) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { showSnackbarCopyLink } = useSnackbar();

  const isPerson = useCallback(() => {
    return product?.borrower_type === 'PERSON';
  }, []);

  const handleNavigate = () => {
    isPerson()
      ? navigate(`/records/natural/${borrowerPerson?.id}/details`)
      : navigate(`/records/legal/${borrowerBusiness?.id}/details`);
  };

  const handleClipboard = useCallback(() => {
    isPerson()
      ? navigator.clipboard.writeText(
          `${document.location.origin}/#/records/natural/${borrowerPerson?.id}/details`,
        )
      : navigator.clipboard.writeText(
          `${document.location.origin}/#/records/legal/${borrowerBusiness?.id}/details`,
        );
    showSnackbarCopyLink();
  }, []);

  return (
    <>
      <ApplicationDetailsHeader>
        <Heading3>Tomador</Heading3>
        <div style={{ display: 'flex', gap: '6px' }}>
          <ButtonPrimary
            typeVariant="outline"
            style={buttonStyle}
            onClick={handleClipboard}
          >
            <HyperlinkIcon color={theme.palette.brand.primary.base as string} />
          </ButtonPrimary>
          <ButtonPrimary
            typeVariant="outline"
            style={buttonStyle}
            onClick={handleNavigate}
          >
            <TextM
              weight="Semibold"
              style={{
                color: theme.palette.brand.primary.base,
                marginRight: '8px',
              }}
            >
              Ver página
            </TextM>
            <ArrowIcon color={theme.palette.brand.primary.base as string} />
          </ButtonPrimary>
        </div>
      </ApplicationDetailsHeader>
      <ViewBoxContainer>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Nome completo</TextM>
            </span>
            <span>
              <TextM>
                {isPerson()
                  ? borrowerPerson?.full_name
                  : borrowerBusiness?.legal_name}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">{isPerson() ? 'CPF' : 'CNPJ'}</TextM>
            </span>
            <span>
              <TextM>
                {isPerson()
                  ? formatCPF(borrowerPerson?.taxpayer_id || '')
                  : formatCNPJ(borrowerBusiness?.taxpayer_id || '')}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Email</TextM>
            </span>
            <span>
              <TextM>
                {isPerson()
                  ? borrowerPerson?.email_address
                  : borrowerBusiness?.email_address}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Celular</TextM>
            </span>
            <span>
              <TextM>
                {isPerson()
                  ? formatPhone(
                      (borrowerPerson?.phone?.area_code || '') +
                        (borrowerPerson?.phone?.number || ''),
                    )
                  : formatPhone(
                      (borrowerBusiness?.phone?.area_code || '') +
                        (borrowerBusiness?.phone?.number || ''),
                    )}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Limite disponível</TextM>
            </span>
            <span>
              <TextM>
                {borrowerQualificationData?.condition?.line_of_credit
                  ? formatCurrency(
                      (borrowerQualificationData?.condition?.line_of_credit
                        ?.credit_limit || 0) -
                        (borrowerQualificationData?.condition?.line_of_credit
                          ?.consumed_credit_limit || 0),
                    ) || '-'
                  : '-'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Limite da parcela disponível</TextM>
            </span>
            <span>
              <TextM>
                {borrowerQualificationData?.condition?.line_of_credit
                  ? formatCurrency(
                      (borrowerQualificationData?.condition?.line_of_credit
                        ?.max_payment_amount || 0) -
                        (borrowerQualificationData?.condition?.line_of_credit
                          ?.consumed_payment_amount || 0),
                    ) || '-'
                  : '-'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Função</TextM>
            </span>
            <span>
              <TextM>
                {
                  roleTypesTranslationParse[
                    borrowerQualificationData?.role
                      ? borrowerQualificationData?.role
                      : 'BORROWER'
                  ]
                }
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
      </ViewBoxContainer>
    </>
  );
};

export default BorrowerContent;
