import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import { useTheme } from '@mui/material';
import { UploadIcon } from '../Icons';
import { TextM } from '../typography';
import { Col, UploadContainer, UploadContent } from './styles';
import { Spinner } from 'celcoin-design-system';

export interface IUpload {
  loading?: boolean;
  title?: string;
  handleDrop: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
  handleDropRejected?: (
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
}

const Upload = ({ handleDrop, handleDropRejected, loading }: IUpload) => {
  const theme = useTheme();

  const onDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => {
    handleDrop(acceptedFiles, fileRejections, event);
  };

  const onDropRejected = (
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => {
    handleDropRejected?.(fileRejections, event);
  };

  const { getRootProps, getInputProps, isDragAccept, isDragActive } =
    useDropzone({
      onDrop,
      onDropRejected,
      maxFiles: 1,
      multiple: false,
      accept: {
        'image/png': [],
        'image/jpeg': [],
        'application/pdf': [],
        'application/msword': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          [],
      },
    });

  return (
    <UploadContainer>
      <UploadContent
        {...getRootProps({
          onClick: (event) => {
            if (loading) {
              event.stopPropagation();
            }
          },
        })}
      >
        <input disabled={loading} {...getInputProps()} />
        {loading ? (
          <Col style={{ justifyContent: 'center' }}>
            <TextM style={{ marginLeft: '30px' }}>
              Arraste um documento aqui ou{' '}
              <span
                style={{
                  color: theme.palette.brand.primary.base as string,
                  fontWeight: 'bold',
                }}
              >
                navegue
              </span>{' '}
              para enviar
            </TextM>
            <Spinner />
          </Col>
        ) : (
          <>
            <Col>
              <UploadIcon color={theme.palette.brand.primary.base as string} />
              <TextM
                weight="Bold"
                style={{
                  marginLeft: '30px',
                  color: theme.palette.brand.primary.base as string,
                }}
              >
                {isDragActive && !isDragAccept ? (
                  'Tipo de arquivo não suportado'
                ) : isDragActive ? (
                  'Solte aqui'
                ) : (
                  <TextM>
                    Arraste um documento aqui ou{' '}
                    <span
                      style={{
                        color: theme.palette.brand.primary.base as string,
                        fontWeight: 'bold',
                      }}
                    >
                      navegue
                    </span>{' '}
                    para enviar
                  </TextM>
                )}
              </TextM>
            </Col>
          </>
        )}
      </UploadContent>
    </UploadContainer>
  );
};

export default Upload;
