import { useState, useEffect } from 'react';
import { UploadCsvModal } from './components/UploadCsvModal';
import { UploadCsvLoadingModal } from '../UploadCsvHandler/components/UploadCsvLoadingModal';
import { TProducts } from '../../../../../products/context';
import { IFile } from '../../../../hooks/useUploadBusinessFiles';
import { usePersonService } from '../../../../services';
import { UploadCsvMessageModal } from './components';
import { CSVIcon, ErrorCircularIcon } from 'app/components/Icons';
import AttentionCircularIcon from 'app/components/Icons/AttentionCircularIcon';
import { AxiosError } from 'axios';
import { ButtonPrimary, CustomCircularIndicator } from 'app/components';
import { ModalFooter } from './components/UploadCsvMessageModal/styles';
import { saveAs } from 'file-saver';
import { DownloadOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material';

enum EUploadCsvHandlerError {
  CONNECTION,
  UNKNOWN,
  FORMAT,
}

export interface IUploadCsvHandler {
  isOpen: boolean;
  handleClose: (isOpen: boolean) => void;
}

const UploadCsvHandler = ({ isOpen, handleClose }: IUploadCsvHandler) => {
  const {
    personBatchImport,
    getPersonBatchImportStatus,
    getPersonBatchImportResult,
    personBatchImportResultLoading,
  } = usePersonService();
  const theme = useTheme();
  const [modalCSVLoadingOpen, setModalCSVLoadingOpen] = useState(false);
  const [modalCSVResponseOpen, setModalCSVResponseOpen] = useState(false);
  const [batchImportId, setBatchImportId] = useState<string | false>(false);
  const [batchImportIntervalId, setBatchImportIntervalId] =
    useState<NodeJS.Timer | null>(null);
  const [modalErrorOpen, setModalErrorOpen] = useState<
    EUploadCsvHandlerError | false
  >(false);

  const getResultFile = (): void => {
    if (batchImportId) {
      getPersonBatchImportResult(batchImportId).then((result) => {
        saveAs(new Blob([result]), `result_${batchImportId}.csv`);
        setTimeout(() => handleClose(false));
      });
    }
  };

  const handleUpload = (
    product: TProducts,
    func: string,
    fileData: IFile | undefined,
  ) => {
    const formData = new FormData();
    if (fileData?.file) {
      handleClose(false);
      formData.append('file', fileData?.file);
      setModalCSVLoadingOpen(true);
      personBatchImport(formData)
        .then((res) => {
          if (res) {
            setBatchImportId(res?.id);
            return;
          }
          setModalCSVLoadingOpen(false);
          setModalErrorOpen(EUploadCsvHandlerError.UNKNOWN);
        })
        .catch((e) => {
          if ((e as AxiosError).code === 'ERR_NETWORK') {
            setModalErrorOpen(EUploadCsvHandlerError.CONNECTION);
          } else {
            setModalErrorOpen(EUploadCsvHandlerError.UNKNOWN);
          }
          setModalCSVLoadingOpen(false);
        });
    }
  };

  useEffect(() => {
    if (!modalCSVLoadingOpen && batchImportIntervalId) {
      clearTimeout(batchImportIntervalId);
      setBatchImportIntervalId(null);
    }
  }, [modalCSVLoadingOpen]);

  useEffect(() => {
    if (batchImportId) {
      const fetchBatchImportStatus = async () => {
        try {
          const status = await getPersonBatchImportStatus(batchImportId);
          if (!status?.has_result_file) {
            const timeout = setTimeout(() => fetchBatchImportStatus(), 2000);
            setBatchImportIntervalId(timeout);
            return;
          }
          setBatchImportIntervalId(null);
          setModalCSVLoadingOpen(false);
          setModalCSVResponseOpen(true);
        } catch (e: unknown) {
          if ((e as AxiosError).code === 'ERR_NETWORK') {
            setModalErrorOpen(EUploadCsvHandlerError.CONNECTION);
          } else {
            setModalErrorOpen(EUploadCsvHandlerError.FORMAT);
          }
          setBatchImportIntervalId(null);
          console.log(e);
        }
      };

      fetchBatchImportStatus();
    } else {
      if (batchImportIntervalId) {
        clearTimeout(batchImportIntervalId);
        setBatchImportIntervalId(null);
      }
    }
    return () => {
      if (batchImportIntervalId) {
        clearTimeout(batchImportIntervalId);
        setBatchImportIntervalId(null);
      }
    };
  }, [batchImportId]);

  return (
    <>
      <UploadCsvModal
        handleClose={() => handleClose(false)}
        isOpen={isOpen}
        handleUpload={(product, func, fileData) =>
          handleUpload(product, func, fileData)
        }
      />
      <UploadCsvLoadingModal
        isOpen={modalCSVLoadingOpen}
        handleClose={() => setModalCSVLoadingOpen(false)}
      />
      <UploadCsvMessageModal
        handleClose={() => setModalCSVResponseOpen(false)}
        isOpen={modalCSVResponseOpen}
        title="Seu arquivo foi processado. Analise os resultados de importação para conferir se todas as pessoas foram cadastradas com sucesso."
        icon={<CSVIcon />}
      >
        <div style={{ marginTop: '15px' }}>
          Erros são indicados na coluna status com o valor ERROR. O motivo do
          erro está na coluna reason. Realize as correções necessárias e reenvie
          o arquivo.
        </div>
        <ModalFooter
          isColumnButtons={true}
          isLoading={personBatchImportResultLoading}
        >
          {!personBatchImportResultLoading ? (
            <ButtonPrimary
              onClick={getResultFile}
              iconPosition="right"
              btnIcon={
                <DownloadOutlined
                  style={{
                    position: 'absolute',
                    right: 20,
                  }}
                />
              }
              style={{ marginBottom: '10px', position: 'relative' }}
            >
              Baixar resultados de importação
            </ButtonPrimary>
          ) : (
            <CustomCircularIndicator
              sx={{
                color: theme.palette.brand.primary.base,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          )}
        </ModalFooter>
      </UploadCsvMessageModal>
      <UploadCsvMessageModal
        handleClose={() => setModalErrorOpen(false)}
        isOpen={modalErrorOpen === EUploadCsvHandlerError.CONNECTION}
        title="Não foi possível conectar à internet."
        icon={<ErrorCircularIcon />}
      >
        Verifique sua conexão de rede e tente novamente.
      </UploadCsvMessageModal>
      <UploadCsvMessageModal
        handleClose={() => setModalErrorOpen(false)}
        isOpen={modalErrorOpen === EUploadCsvHandlerError.UNKNOWN}
        title="Ocorreu um problema no nosso sistema."
        icon={<ErrorCircularIcon />}
      >
        Por gentileza, tente novamente ou contate o suporte.
      </UploadCsvMessageModal>
      <UploadCsvMessageModal
        handleClose={() => setModalErrorOpen(false)}
        isOpen={modalErrorOpen === EUploadCsvHandlerError.FORMAT}
        title="Parece que o arquivo CSV não está no formato correto."
        icon={<AttentionCircularIcon />}
      >
        Baixe o <u>modelo de arquivo CSV</u>, preencha com os itens a serem
        importados e tente novamente.
      </UploadCsvMessageModal>
    </>
  );
};

export default UploadCsvHandler;
