import { useCallback, useState } from 'react';
import {
  SummaryButton,
  SummaryContainer,
  SummaryItem,
  SummaryList,
} from './styles';

type INaturalPersonViewDetailsSummaryProps = {
  anchors: Array<{
    label: string;
    anchor: string;
  }>;
};

const NaturalPersonViewDetailsSummary = ({
  anchors,
}: INaturalPersonViewDetailsSummaryProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onAnchorClick = useCallback((anchor: string) => {
    const element = document.querySelector(anchor);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
      setIsOpen(false);
    }
  }, []);

  return (
    <SummaryContainer className={isOpen ? 'open' : ''}>
      <SummaryButton onClick={() => setIsOpen(!isOpen)}>
        <span>Navegação</span>
      </SummaryButton>
      <SummaryList>
        {anchors.map((anchor, index) => {
          return (
            <SummaryItem key={index}>
              <a onClick={() => onAnchorClick(anchor.anchor)}>{anchor.label}</a>
            </SummaryItem>
          );
        })}
      </SummaryList>
    </SummaryContainer>
  );
};

export default NaturalPersonViewDetailsSummary;
