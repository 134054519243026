export const formatDateFromBackend = (dateFromBackend: string): Date => {
  if (dateFromBackend.includes('T')) {
    const date = new Date(dateFromBackend);
    if (!isNaN(date.getTime())) return date;
  }
  if (typeof dateFromBackend === 'string' && dateFromBackend.includes('-')) {
    const [year, month, day] = dateFromBackend.split('-');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }
  return new Date();
};

export const formatDateForBackendString = (
  dateForBackend?: string | Date,
): string | null => {
  if (!dateForBackend) return null;
  if (typeof dateForBackend === 'string' && dateForBackend.includes('/')) {
    const [day, month, year] = dateForBackend.split('/');
    return `${year}-${month}-${day}`;
  }

  if (dateForBackend instanceof Date) {
    const year = dateForBackend.getFullYear();
    const month = dateForBackend.getMonth() + 1;
    const day = dateForBackend.getDate();
    return `${year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')}`;
  }

  if (typeof dateForBackend === 'string' && dateForBackend.includes('T')) {
    const date = new Date(dateForBackend);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')}`;
  }
  return null;
};

export const formatDateForBackend = (dateForBackend: string): Date => {
  if (typeof dateForBackend === 'string' && dateForBackend.includes('/')) {
    const [day, month, year] = dateForBackend.split('/');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }
  return new Date();
};

export const formatStringHourFromBackend = (
  dateFromBackend: string,
): string => {
  const date = new Date(dateFromBackend);
  return `${date?.getHours().toString().padStart(2, '0')}:${date
    ?.getMinutes()
    .toString()
    .padStart(2, '0')}`;
};

export const subtractDays = (date: Date, days: number): Date => {
  const dateCopy = new Date(date);
  dateCopy.setDate(dateCopy.getDate() - days);
  return dateCopy;
};
