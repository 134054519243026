import {
  EditWrapper,
  Heading3,
  Heading4,
} from '../../../../../../app/components';
import { CpfCnpjIcon } from '../../../../../../app/components/Icons';
import {
  ACCOUNT_TYPE_OBJECT,
  BANK_LIST_OBJECT,
  formatCNPJ,
  formatCPF,
} from '../../../../../../app/helpers';
import { TRegisterPersonDataRequest } from '../../../../context';
import { useTheme } from '@mui/material';
import {
  NaturalPersonViewBankContent,
  NaturalPersonViewBankIcon,
  NaturalPersonViewBankBox,
  NaturalPersonViewBankIndex,
  NaturalPersonViewBoxContainer,
  NaturalPersonViewPageSection,
} from './styles';
import { PixType } from '../../../../context/CustomerProvider/customer.interfaces';
import { useState } from 'react';
import LegalPersonEditBankSection from './NaturalPersonEditBankSection';
import NaturalPersonEditPixSection from './NaturalPersonEditPixSection';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';

type INaturalPersonViewBankSectionProps = {
  personData: TRegisterPersonDataRequest;
};

const PixTypeDescription = {
  [PixType.ALEATORY_KEY]: 'Chave aleatória',
  [PixType.EMAIL]: 'Email',
  [PixType.PHONE_NUMBER]: 'Celular',
  [PixType.TAXPAYER_ID]: 'CPF/CNPJ',
};

const NaturalPersonViewBankSection = ({
  personData,
}: INaturalPersonViewBankSectionProps) => {
  const { external_bank_account, pix } = personData;
  const theme = useTheme();
  const [isBankEditOpen, setIsBankEditOpen] = useState(false);
  const [isPixEditOpen, setIsPixEditOpen] = useState(false);
  return (
    <>
      <NaturalPersonViewPageSection>
        <Heading3
          id="bank"
          style={{ color: theme.palette.brand.secondary.base as string }}
        >
          Dados bancários
        </Heading3>

        <NaturalPersonViewBankBox>
          <Heading4
            fontWeight="B"
            style={{ color: theme.palette.brand.primary.base }}
          >
            Conta
          </Heading4>
          <EditWrapper
            handleClick={() => setIsBankEditOpen(true)}
            permission={EAccountPermissions.UPDATE_PERSON_BUSINESS}
          >
            <NaturalPersonViewBoxContainer>
              {external_bank_account ? (
                <>
                  <NaturalPersonViewBankIndex>01</NaturalPersonViewBankIndex>
                  <NaturalPersonViewBankContent>
                    <span
                      style={{
                        fontWeight: 'bold',
                        display: 'block',
                      }}
                    >{`${
                      BANK_LIST_OBJECT[external_bank_account.ispb_code]
                    }`}</span>
                    <span
                      style={{
                        display: 'block',
                      }}
                    >{`Agência ${external_bank_account.bank_branch} - Conta ${external_bank_account.bank_account}-${external_bank_account.bank_account_digit}`}</span>
                    <span
                      style={{
                        display: 'block',
                      }}
                    >{`Tipo ${
                      ACCOUNT_TYPE_OBJECT[
                        external_bank_account.bank_account_type
                      ]
                    }`}</span>
                  </NaturalPersonViewBankContent>
                </>
              ) : (
                <span style={{ color: theme.palette?.brand?.gray[600] }}>
                  -
                </span>
              )}
            </NaturalPersonViewBoxContainer>
          </EditWrapper>
        </NaturalPersonViewBankBox>
        <NaturalPersonViewBankBox>
          <Heading4
            fontWeight="B"
            style={{ color: theme.palette.brand.primary.base }}
          >
            Pix
          </Heading4>
          <EditWrapper
            handleClick={() => setIsPixEditOpen(true)}
            permission={EAccountPermissions.UPDATE_PERSON_BUSINESS}
          >
            <NaturalPersonViewBoxContainer>
              {pix ? (
                <>
                  <NaturalPersonViewBankIcon>
                    <CpfCnpjIcon
                      color={theme.palette.brand.secondary.base as string}
                    />
                  </NaturalPersonViewBankIcon>
                  <NaturalPersonViewBankContent>
                    <span
                      style={{
                        fontWeight: 'bold',
                        display: 'block',
                      }}
                    >{`${PixTypeDescription[pix.key_type]}`}</span>
                    <span
                      style={{
                        display: 'block',
                      }}
                    >
                      {pix.key_type === PixType.TAXPAYER_ID
                        ? pix.key.length > 11
                          ? formatCNPJ(pix.key)
                          : formatCPF(pix.key)
                        : pix.key}
                    </span>
                  </NaturalPersonViewBankContent>
                </>
              ) : (
                <span style={{ color: theme.palette?.brand?.gray[600] }}>
                  -
                </span>
              )}
            </NaturalPersonViewBoxContainer>
          </EditWrapper>
        </NaturalPersonViewBankBox>
      </NaturalPersonViewPageSection>
      <LegalPersonEditBankSection
        isOpen={isBankEditOpen}
        handleClose={() => setIsBankEditOpen(false)}
        personData={personData}
      />
      <NaturalPersonEditPixSection
        isOpen={isPixEditOpen}
        handleClose={() => setIsPixEditOpen(false)}
        personData={personData}
      />
    </>
  );
};

export default NaturalPersonViewBankSection;
