import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const CustomTableWrapper = styled.div<{ tableHeadSticky?: boolean }>`
  .MuiPaper-root {
    border-radius: 18px;
    box-shadow: 0px -1px 0px -6px rgba(0, 0, 0, 0.04), 0px 4px 12px -4px rgba(0, 0, 0, 0.04);
    border: 1px solid #eee;
  }

  .MuiTableHead-root {
    th {
      padding: 13px 16px;
    }
  }

  .MuiTableCell-root {
    padding: 14px 16px;

  }

  ${({ tableHeadSticky }) =>
    tableHeadSticky &&
    css`
    table {
      border-collapse: separate;
      border-spacing: 0;
    }
  
    thead {
      th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background-color: white;
      }
    }
  `}

  tfoot {
    td {
      position: sticky;
      bottom: -1px;
      background-color: white;
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: ${({ theme }) => theme.palette?.brand.gray[200]};
    }
  }
`;

export const CellHeadContent = styled.div`
  display: flex;
`;

export const CellBodyContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
