import {
  BaseModal,
  CustomInput,
  Heading3,
  Spinner,
  TextL,
  TextM,
} from 'celcoin-design-system';
import { Container } from './styles';
import { ButtonPrimary, CloseIcon } from 'app/components';
import { useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { WarningRounded } from '@mui/icons-material';
import * as Yup from 'yup';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { useAuthContext } from 'modules/auth/context';
import { CustomSelect } from 'app/components/CustomSelect';
import { BankStatementFormat } from 'modules/disbursement/interfaces/disbursement';

export interface IDownloadFileModal {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (email: string, format: BankStatementFormat) => void;
  loading: boolean;
  dateFrom: string;
  dateTo: string;
}

const formattedStatementFormats: {
  label: string;
  value: BankStatementFormat;
}[] = [
  {
    label: 'Excel',
    value: 'xlsx',
  },
  {
    label: 'PDF',
    value: 'pdf',
  },
];

const DownloadReportModal = ({
  isOpen,
  handleClose,
  handleSubmit,
  loading,
  dateFrom,
  dateTo,
}: IDownloadFileModal) => {
  const { user } = useAuthContext();
  const theme = useTheme();
  const [email, setEmail] = useState(user?.getUsername().split(':')[1]);
  const [selectedFormat, setSelectedFormat] = useState(
    formattedStatementFormats[0],
  );

  const formik = useFormik({
    validationSchema: Yup.object({
      email: Yup.string().email('Email inválido').required('Campo obrigatório'),
      format: Yup.object().required('Campo obrigatório'),
    }),
    initialValues: {
      email: email,
      format: selectedFormat,
    },
    onSubmit: (values) => {
      setEmail(values.email);
      setSelectedFormat(values.format);
      handleSubmit(values.email || '', values.format.value);
    },
  });

  const onCloseModal = () => {
    handleClose();
  };

  useEffect(() => {
    if (!isOpen) {
      setEmail('');
      setSelectedFormat(formattedStatementFormats[0]);
      formik.resetForm();
    }
  }, [isOpen]);

  return (
    <BaseModal open={isOpen} onClose={onCloseModal}>
      <Container>
        <div className="header">
          <Heading3>Exportar Extrato</Heading3>
          <div className="close-btn" onClick={onCloseModal}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </div>
        <div className="body">
          {loading && (
            <div className="loading">
              <div>
                <Spinner />
              </div>
              <TextL>
                Enviando solicitação de relatório para o email informado...
              </TextL>
            </div>
          )}
          {!loading && (
            <>
              <TextL>
                O extrato será processado e enviado para o email informado.
                Indique um email válido para receber as instruções para download
                do arquivo.
              </TextL>

              <div className="input">
                <CustomInput
                  labelValue="Email"
                  value={formik.values.email || ''}
                  handleInputChange={formik.handleChange}
                  name="email"
                  validationError={
                    formik.touched.email ? formik.errors.email : ''
                  }
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <CustomSelect
                  labelValue="Formato"
                  name="format"
                  placeholder="Selecione o formato"
                  value={selectedFormat}
                  isClearable={false}
                  handleInputChange={(v) => {
                    formik.handleChange(v);
                    setSelectedFormat(v.currentTarget.value.value);
                  }}
                  options={formattedStatementFormats}
                  validationError={
                    formik.touched.format?.value
                      ? formik.errors.format?.value
                      : ''
                  }
                />
              </div>

              <div className="warning">
                <WarningRounded
                  style={{
                    color: theme.palette.brand.primary.base,
                    alignSelf: 'center',
                  }}
                />
                <TextL weight="medium">
                  Verifique os filtros aplicados, eles serão considerados para a
                  criação do relatório
                </TextL>
              </div>

              <div className="dates">
                <TextM weight="Medium">
                  Data Inicial:{' '}
                  {formatDateFromBackend(dateFrom).toLocaleDateString('pt-br')}
                </TextM>
                <TextM weight="Medium">
                  Data Final:{' '}
                  {formatDateFromBackend(dateTo).toLocaleDateString('pt-br')}
                </TextM>
              </div>

              <div className="btn">
                <ButtonPrimary onClick={formik.submitForm}>
                  <TextM weight="Semibold" style={{ color: '#fff' }}>
                    Emitir Extrato
                  </TextM>
                </ButtonPrimary>
              </div>
            </>
          )}
        </div>
      </Container>
    </BaseModal>
  );
};

export default DownloadReportModal;
