import styled from '@emotion/styled';

export const ModalApiErrorContent = styled.div`
  padding: 0px;
  width: 100%;
  max-width: 600px;

  .header-area {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .close-btn {
      cursor: pointer;
    }
  }

  .body-content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .copy-text {
      cursor: pointer;
    }
  }
`;
