import styled from '@emotion/styled';

export const NaturalPersonViewPageSection = styled.div`
  margin-bottom: 50px;
`;

export const NaturalPersonViewBoxContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  border-radius: 10px;
  width: 100%;
`;

export const NaturalPersonViewBoxContent = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  &:first-of-type {
    border-top: unset;
  }
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      flex-direction: column;
  };
`;

export const NaturalPersonViewBoxItem = styled.div`
  display: flex;
  max-width: calc(100% / 3);
  width: 100%;
  justify-content: space-between;
  border-right: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  padding: 12px 24px;
  span {
    display: block;
    &:first-of-type {
      min-width: max-content;
    }
    &:nth-of-type(2) {
      max-width: 70%;
      text-align: right;
    }
  }

  &:first-of-type {
    border-right: 1px solid ${({ theme }) =>
      theme.palette?.brand?.gray[300]} !important;
  }
  &:last-of-type {
    border-right: unset;
  }
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
    max-width: unset !important;
};
`;
