const BuildingIcon = ({color = "#9490A3", ...props}) => (
  <svg
    width={30}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27 21.333h2.667V24H.333v-2.667H3v-20A1.333 1.333 0 0 1 4.333 0h13.334A1.333 1.333 0 0 1 19 1.333v20h5.334V10.667h-2.667V8h4A1.333 1.333 0 0 1 27 9.333v12ZM5.667 2.667v18.666h10.667V2.667H5.667Zm2.667 8h5.333v2.666H8.334v-2.666Zm0-5.334h5.333V8H8.334V5.333Z"
      fill={color}
    />
  </svg>
)

export default BuildingIcon
