import { useTheme } from '@mui/material';
import { usePermify } from 'modules/auth/context';
import { EPermission } from 'modules/auth/context/permify/permify.interfaces';
import { useEffect, useState, useCallback } from 'react';
import { BoxSection, ButtonPrimary, Heading3, TextM } from 'app/components';
import {
  BuildingIcon,
  CopyIcon,
  HandCoinIcon,
  HyperlinkIcon,
} from 'app/components/Icons';
import { TProducts } from '../../../../context';
import { ApplicationContent } from '../ApplicationContent';
import { PersonQualificationContent } from '../PersonQualificationContent';
import { QualificationContent } from '../QualificationContent';
import {
  BoxHeading,
  ProductDetailsContentHeaderButtons,
  ProductsDetailsContentPageContentHeader,
  TabsArea,
} from './styles';
import { routingPath } from 'app/routes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { Rule } from '@mui/icons-material';
import { CustomTabs } from 'app/components/CustomTabs';

interface IProductsDetailsContent {
  productData: TProducts | null;
}

const productTabsValueContent = (
  tabIndex: number,
  productData: TProducts | null,
  tabName: string,
) => {
  if (productData?.borrower_type === 'PERSON') {
    const tabItems: { [key: string]: JSX.Element } = {
      ['applications']: <ApplicationContent />,
      ['employers']: <QualificationContent />,
      ['borrowers']: <PersonQualificationContent />,
    };

    return tabItems[tabName];
  }
  if (productData?.borrower_type === 'BUSINESS') {
    const tabItems: { [key: string]: JSX.Element } = {
      ['applications']: <ApplicationContent />,
      ['borrowers']: <QualificationContent />,
    };

    return tabItems[tabName];
  }

  return null;
};

const ProductsDetailsContent = ({ productData }: IProductsDetailsContent) => {
  const [currentTabValue, setCurrentTabValue] = useState(0);
  const { showSnackbarCopyLink, showSnackbarCopy } = useSnackbar();
  const { isAuthorized } = usePermify();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const theme = useTheme();

  const tabItemsPerson = [
    {
      title: 'Solicitações',
      route: '/applications',
      isEnabled: true,
      icon: <HandCoinIcon color={theme.palette.brand.primary.base as string} />,
      permission: EPermission.PRODUCTS_APPLICATIONS,
    },
    {
      title: 'Empregadores',
      route: '/employers',
      icon: <BuildingIcon />,
      isEnabled: true,
      permission: EPermission.PRODUCTS_QUALIFICATIONS,
    },
    {
      title: 'Linhas de Crédito',
      route: '/borrowers',
      isEnabled: true,
      permission: EPermission.PRODUCTS_PERSON_QUALIFICATIONS,
      icon: <Rule style={{ marginRight: '-5px' }} />,
    },
  ];

  const tabItemsBusiness = [
    {
      title: 'Solicitações',
      route: '/applications',
      isEnabled: true,
      icon: <HandCoinIcon color={theme.palette.brand.primary.base as string} />,
      permission: EPermission.PRODUCTS_APPLICATIONS,
    },
    {
      title: 'Linhas de Crédito',
      route: '/borrowers',
      isEnabled: true,
      permission: EPermission.PRODUCTS_PERSON_QUALIFICATIONS,
      icon: <Rule style={{ marginRight: '-5px' }} />,
    },
  ];

  const handleCopyId = useCallback(() => {
    navigator.clipboard.writeText(productData?.id as string);
    showSnackbarCopy();
  }, [productData]);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(window.location.href);
    showSnackbarCopyLink();
  }, [productData, location]);

  useEffect(() => {
    if (!params.tabRoute) {
      navigate(`${location.pathname}/applications?page=0`);
    }
  }, []);

  return (
    <>
      <ProductsDetailsContentPageContentHeader>
        <BoxHeading>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Heading3 fontWeight="Sb">{productData?.name}</Heading3>
              <TextM
                style={{ color: theme.palette.brand.secondary.base as string }}
              >
                {productData?.description}
              </TextM>
            </div>
            <ProductDetailsContentHeaderButtons>
              <ButtonPrimary
                style={{ backgroundColor: 'transparent' }}
                typeVariant="outline"
                btnIcon={
                  <CopyIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                }
                iconPosition="left"
                onClick={handleCopyId}
              >
                <TextM
                  weight="Semibold"
                  style={{
                    marginLeft: '11px',
                    color: theme.palette.brand.primary.base,
                  }}
                >
                  Copiar ID
                </TextM>
              </ButtonPrimary>
              <ButtonPrimary
                style={{ backgroundColor: 'transparent' }}
                typeVariant="outline"
                btnIcon={
                  <HyperlinkIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                }
                iconPosition="left"
                onClick={handleCopyLink}
              >
                <TextM
                  weight="Semibold"
                  style={{
                    marginLeft: '11px',
                    color: theme.palette.brand.primary.base,
                  }}
                >
                  Copiar Link
                </TextM>
              </ButtonPrimary>
            </ProductDetailsContentHeaderButtons>
          </div>
          <TabsArea>
            <CustomTabs
              value={currentTabValue}
              route={routingPath.products.detail}
              variant="scrollable"
              items={(productData?.borrower_type === 'PERSON'
                ? tabItemsPerson
                : tabItemsBusiness
              )
                .filter((tab) => isAuthorized(tab.permission) && tab.isEnabled)
                .map((tab) => ({
                  title: tab.title,
                  icon: tab.icon,
                  route: tab.route,
                }))}
              handleChange={(value) => setCurrentTabValue(value)}
              showBorder={false}
            />
          </TabsArea>
        </BoxHeading>
      </ProductsDetailsContentPageContentHeader>
      <BoxSection>
        {productTabsValueContent(
          currentTabValue,
          productData,
          params.tabRoute || 'applications',
        )}
      </BoxSection>
    </>
  );
};

export default ProductsDetailsContent;
