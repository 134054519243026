import { useState } from 'react';
import { DebtCollectionsHttpService } from '../services/api/chargeHttpService';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { ContractFilters } from '../interfaces/contracts';

const useContract = () => {
  const [getContractsLoading, setGetContractsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const getContracts = async (
    fundingId: string,
    parameters: ContractFilters,
  ) => {
    setGetContractsLoading(true);

    try {
      const debtCollectionsHttpService = new DebtCollectionsHttpService();

      const res = await debtCollectionsHttpService.getContracts(
        fundingId,
        parameters,
      );

      return res;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao obter os contratos');
    } finally {
      setGetContractsLoading(false);
    }
  };

  return { getContracts, getContractsLoading };
};

export default useContract;
