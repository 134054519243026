import { useTheme } from '@mui/material';
import { CustomSpinnerContent } from './styles';

export interface ISpinner {
  color?: string;
  size?: number;
}

const Spinner = ({ size = 48, color }: ISpinner) => {
  const theme = useTheme();
  return (
    <CustomSpinnerContent
      size={size}
      color={color ?? (theme.palette.brand.primary.base as string)}
    >
      <div className="loader">Loading...</div>
    </CustomSpinnerContent>
  );
};

export default Spinner;
