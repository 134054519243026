const InstallmentsCartIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2837 25.8945C16.2893 25.8945 17.1046 26.7193 17.1046 27.7366C20.0814 27.7366 22.8198 28.7689 24.9892 30.499L28.9406 30.4997C31.3671 30.4997 33.5473 31.5669 35.0478 33.2625L40.7766 33.2629C44.4044 33.2629 47.5365 35.4095 49 38.5155C44.6939 44.2642 37.7209 47.9998 29.851 47.9998C24.7694 47.9998 20.4729 46.8886 16.9937 44.9457C16.7417 45.6522 16.071 46.1577 15.2837 46.1577H9.82092C8.81526 46.1577 8 45.333 8 44.3156V27.7366C8 26.7193 8.81526 25.8945 9.82092 25.8945H15.2837ZM17.1064 31.4208L17.1046 40.6719L17.1872 40.7317C20.4536 43.0535 24.7126 44.3156 29.851 44.3156C35.3211 44.3156 40.4098 42.1868 44.1181 38.5509L44.3601 38.3048L44.1431 38.1198C43.2982 37.4511 42.2617 37.0363 41.1498 36.96L40.7766 36.9471L36.9317 36.9473C37.0646 37.5397 37.1347 38.1563 37.1347 38.7892V40.6313H20.7464V36.9471L33.1105 36.9453L33.0479 36.8009C32.355 35.339 30.9219 34.3043 29.2399 34.1937L28.9406 34.184L23.6063 34.1838C21.9543 32.4791 19.6525 31.4214 17.1064 31.4208ZM13.4628 29.5787H11.6418V42.4734H13.4628V29.5787Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.9554 19C41.9723 19 44.4182 21.4345 44.4182 24.4375C44.4182 27.4406 41.9723 29.875 38.9554 29.875C35.9383 29.875 33.4927 27.4406 33.4927 24.4375C33.4927 21.4345 35.9383 19 38.9554 19ZM37.1345 24.4375C37.1345 23.4365 37.9497 22.625 38.9554 22.625C39.9611 22.625 40.7763 23.4365 40.7763 24.4375C40.7763 25.4385 39.9611 26.25 38.9554 26.25C37.9497 26.25 37.1345 25.4385 37.1345 24.4375Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.4628 13C29.4796 13 31.9255 15.4345 31.9255 18.4375C31.9255 21.4406 29.4796 23.875 26.4628 23.875C23.4457 23.875 21 21.4406 21 18.4375C21 15.4345 23.4457 13 26.4628 13ZM24.6418 18.4375C24.6418 17.4365 25.4571 16.625 26.4628 16.625C27.4684 16.625 28.2837 17.4365 28.2837 18.4375C28.2837 19.4385 27.4684 20.25 26.4628 20.25C25.4571 20.25 24.6418 19.4385 24.6418 18.4375Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.5 6C42.5375 6 45 8.46244 45 11.5C45 14.5376 42.5375 17 39.5 17C36.4624 17 34 14.5376 34 11.5C34 8.46244 36.4624 6 39.5 6ZM37.6667 11.5C37.6667 10.4875 38.4875 9.66667 39.5 9.66667C40.5126 9.66667 41.3333 10.4875 41.3333 11.5C41.3333 12.5125 40.5126 13.3333 39.5 13.3333C38.4875 13.3333 37.6667 12.5125 37.6667 11.5Z"
        fill={color}
      />
    </svg>
  );
};

export default InstallmentsCartIcon;
