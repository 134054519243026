import * as React from "react"

const PdfIcon = () => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C6.9 0 6 .9 6 2v28c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V8l-8-8H8Z"
      fill="#EEE"
    />
    <path d="M24 8h6l-8-8v6c0 1.1.9 2 2 2Z" fill="#BDBDBD" />
    <path d="m30 14-6-6h6v6Z" fill="#E0E0E0" />
    <path
      d="M26 26c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1V16c0-.55.45-1 1-1h22c.55 0 1 .45 1 1v10Z"
      fill="#F15642"
    />
    <path
      d="M6.36 18.948c0-.264.207-.552.542-.552H8.75c1.04 0 1.976.696 1.976 2.03 0 1.264-.936 1.968-1.976 1.968H7.414v1.056c0 .352-.224.55-.512.55a.539.539 0 0 1-.543-.55v-4.502Zm1.054.455v1.992H8.75c.536 0 .96-.473.96-.97 0-.558-.424-1.022-.96-1.022H7.414ZM12.292 24c-.264 0-.552-.144-.552-.495v-4.542c0-.287.288-.496.552-.496h1.83c3.655 0 3.575 5.533.073 5.533h-1.903Zm.504-4.557v3.582h1.327c2.159 0 2.255-3.582 0-3.582h-1.327ZM18.992 19.508v1.27h2.04c.287 0 .575.289.575.568 0 .264-.288.48-.576.48h-2.039v1.679c0 .28-.199.495-.479.495-.352 0-.567-.215-.567-.495v-4.542c0-.287.216-.496.567-.496h2.807c.352 0 .56.209.56.496a.552.552 0 0 1-.56.544h-2.328Z"
      fill="#fff"
    />
    <path
      d="M25 27H6v1h19c.55 0 1-.45 1-1v-1c0 .55-.45 1-1 1Z"
      fill="#E0E0E0"
    />
  </svg>
)

export default PdfIcon
