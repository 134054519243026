import React from 'react';
import { AuthContainer } from '../../components';
import { EnableTwoFactorAuthContent } from './components';

const EnableTwoFactorAuth = () => {
  return (
    <AuthContainer>
      <EnableTwoFactorAuthContent />
    </AuthContainer>
  );
};

export default EnableTwoFactorAuth;
