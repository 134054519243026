import styled from '@emotion/styled';

export const Container = styled.div``;

export const BackBtnArea = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  svg {
    transform: rotate(90deg) ;
  }
`;

export const InputArea = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BtnArea = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  .spinner {
    height: 60px;
    width: 100%;
    align-self: flex-end;
    display: flex;
    justify-content: center;
  }
`;

export const LoadingArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;
