import styled from '@emotion/styled';

export const SearchFilterContainer = styled.div`
  width: 100%;
  padding: 12px;
  background-color: #FDFDFD;
  width: 100%;
  height: 100%;
  max-height: 56px;
  border-radius: 10px;
  display: flex;
  border: 1px solid #E6E5EA;
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 17px;
  cursor: pointer;
`;
