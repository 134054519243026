import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const TabsArea = styled.div`
  margin-top: 32px;
  margin-left: -10px;
`;

export const ApplicationDetailsSection = styled.section`
  box-shadow: 0px -1px 0px -6px rgba(0, 0, 0, 0.04), 0px 4px 12px -4px rgba(0, 0, 0, 0.04);
`;

export const ApplicationDetailsHeader = styled.div`
  background-color: ${({ theme }) => theme.palette?.brand.background.base};
  padding: 40px 48px 0px;
  border-top: 1px solid #DBD6FE;
  border-left: 1px solid #DBD6FE;
  border-right: 1px solid #DBD6FE;
  border-radius: 16px 16px 0px 0px;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      padding: 20px;
  };
`;

export const ApplicationDetailsHeaderButtons = styled.section`
  display: flex;
  justify-content: flex-end;
  button {
    width: auto;
    margin-right: 16px;
  }
  &:last-of-type {
    margin-top: 16px;
  }
`;

export const ApplicationDetailsHeaderButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
