const HyperlinkIcon = ({ color }: { color: string }) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.681 11.8477L14.2396 10.4062L15.681 8.96478C16.0597 8.58612 16.36 8.13658 16.565 7.64184C16.7699 7.1471 16.8754 6.61684 16.8754 6.08133C16.8754 5.54583 16.7699 5.01557 16.565 4.52082C16.36 4.02608 16.0597 3.57655 15.681 3.19789C15.3024 2.81923 14.8528 2.51886 14.3581 2.31393C13.8633 2.109 13.3331 2.00352 12.7976 2.00352C12.2621 2.00352 11.7318 2.109 11.2371 2.31393C10.7423 2.51886 10.2928 2.81923 9.91413 3.19789L8.47266 4.63935L7.03119 3.19789L8.47266 1.75642C9.62289 0.624773 11.1737 -0.00651952 12.7873 5.07768e-05C14.4008 0.00662108 15.9464 0.650522 17.0874 1.7915C18.2284 2.93247 18.8723 4.47808 18.8789 6.09165C18.8854 7.70522 18.2541 9.25602 17.1225 10.4062L15.681 11.8477V11.8477ZM12.7971 14.7317L11.3556 16.1731C10.7893 16.7487 10.1147 17.2065 9.37064 17.52C8.62657 17.8335 7.82779 17.9967 7.02037 17.9999C6.21294 18.0032 5.41286 17.8466 4.66627 17.5392C3.91967 17.2317 3.24134 16.7794 2.6704 16.2085C2.09947 15.6376 1.64722 14.9592 1.33975 14.2126C1.03228 13.466 0.875669 12.666 0.878957 11.8585C0.882245 11.0511 1.04536 10.2523 1.3589 9.50827C1.67244 8.7642 2.1302 8.08958 2.70577 7.52331L4.14724 6.08184L5.5887 7.52331L4.14724 8.96478C3.76858 9.34344 3.46821 9.79297 3.26328 10.2877C3.05835 10.7825 2.95287 11.3127 2.95287 11.8482C2.95287 12.3837 3.05835 12.914 3.26328 13.4087C3.46821 13.9035 3.76858 14.353 4.14724 14.7317C4.5259 15.1103 4.97543 15.4107 5.47017 15.6156C5.96492 15.8206 6.49518 15.926 7.03068 15.926C7.56619 15.926 8.09645 15.8206 8.59119 15.6156C9.08593 15.4107 9.53547 15.1103 9.91413 14.7317L11.3556 13.2902L12.7971 14.7317ZM12.7971 4.63935L14.2396 6.08184L7.03119 13.2892L5.5887 11.8477L12.7971 4.64037V4.63935Z"
      fill={color}
    />
  </svg>
);

export default HyperlinkIcon;
