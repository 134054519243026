import { useState } from 'react';
import { Divider, useTheme } from '@mui/material';
import { ButtonDefault, TextL, TextS } from 'app/components';
import { formatCPF, formatDecimalField, formatPhone } from 'app/helpers';
import { TRegisterPersonDataRequest } from 'modules/customer/context';
import { ButtonArea, InfoArea, ListBoxItem, SelectedAddInfo } from './styles';
import { InfoDivider } from '../InfoDivider';
import { RelationPerson } from 'modules/customer/interfaces/businessRelation';
import {
  CustomSwitchIOS,
  CustomRadioInput,
  CustomInput,
} from 'celcoin-design-system';
import { useFormik } from 'formik';
import { businessRelationSchema } from 'modules/customer/helpers/businessValidation';
import { formatToCurrencyFloat } from 'app/utils/normalizer';

export interface IListItemProps {
  person: TRegisterPersonDataRequest;
  isSelected: boolean;
  personAdded: boolean;
  handleSelect: (selectedPerson: TRegisterPersonDataRequest) => void;
  cancelSelection: () => void;
  handleAdd: (addedPerson: RelationPerson) => void;
}

const ListItem = ({
  person,
  isSelected,
  personAdded,
  handleSelect,
  cancelSelection,
  handleAdd,
}: IListItemProps) => {
  const theme = useTheme();
  const [signForCompany, setSignForCompany] = useState(false);
  const [workRelation, setWorkRelation] = useState('OTHER');

  const setRelationType = (value: string) => {
    setWorkRelation(value);
  };

  const handleShare = (value: string) => {
    return formatDecimalField(value);
  };

  const formik = useFormik({
    initialValues: {
      share: '0',
    },
    validationSchema: businessRelationSchema,
    onSubmit: (values) => {
      handleAdd({
        ...person,
        signer: signForCompany,
        workRelation,
        share: values.share
          ? Number(formatToCurrencyFloat(values.share))
          : undefined,
      });
    },
  });

  return (
    <ListBoxItem
      className={isSelected ? 'selected' : ''}
      key={person.taxpayer_id}
    >
      <div onClick={() => handleSelect(person)}>
        <TextL
          style={{
            color: theme.palette.brand.secondary.base,
          }}
          weight="semibold"
        >
          {person.full_name}
        </TextL>
        <InfoArea>
          <TextS>{formatCPF(person.taxpayer_id || '')}</TextS>
          <InfoDivider />
          <TextS>
            {formatPhone(person.phone.area_code + person.phone.number || '')}
          </TextS>
          <InfoDivider />
          <TextS>{person.email_address}</TextS>
        </InfoArea>
      </div>
      {isSelected && (
        <SelectedAddInfo>
          {!personAdded && (
            <>
              <div className="switch-area">
                <TextL weight="medium">Assina pela empresa</TextL>
                <div className="switch-item">
                  {!signForCompany && (
                    <TextL style={{ color: '#9490A3' }}>Não</TextL>
                  )}
                  <CustomSwitchIOS
                    onChange={(evt) => setSignForCompany(evt.target.checked)}
                    checked={signForCompany}
                  />
                </div>
              </div>
              <Divider />
              <div className="relationship-area">
                <div style={{ width: '20%' }}>
                  <TextL weight="medium">Vínculo trabalhista</TextL>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '80%',
                    gap: '20px',
                  }}
                >
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'EMPLOYEE'}
                      handleClick={() => setRelationType('EMPLOYEE')}
                    />
                    <TextL>Empregado</TextL>
                  </div>
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'PARTNER'}
                      handleClick={() => setRelationType('PARTNER')}
                    />
                    <TextL>Sócio</TextL>
                  </div>
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'MANAGER'}
                      handleClick={() => setRelationType('MANAGER')}
                    />
                    <TextL>Administrador</TextL>
                  </div>
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'PROXY'}
                      handleClick={() => setRelationType('PROXY')}
                    />
                    <TextL>Procurador</TextL>
                  </div>
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'OTHER'}
                      handleClick={() => setRelationType('OTHER')}
                    />
                    <TextL>Não especificado</TextL>
                  </div>
                </div>
              </div>
              <Divider />

              <div style={{ marginTop: '16px', display: 'flex', gap: '12px' }}>
                <TextL weight="medium">Particiapação em quotas (%)</TextL>
                <CustomInput
                  placeholder="Ex: 50"
                  value={formik.values.share}
                  handleInputChange={(e) => {
                    formik.setFieldValue('share', handleShare(e.target.value));
                  }}
                  validationError={
                    formik.touched.share ? formik.errors.share : ''
                  }
                />
              </div>
            </>
          )}
          <ButtonArea>
            <div className="cancel-btn" onClick={cancelSelection}>
              <TextL
                weight="semibold"
                style={{
                  color: theme.palette.brand.primary.base,
                  alignSelf: 'center',
                }}
              >
                Cancelar
              </TextL>
            </div>
            <ButtonDefault
              onClick={() => formik.handleSubmit()}
              style={{
                width: '522px',
                cursor: personAdded ? 'not-allowed' : 'pointer',
              }}
              disabled={personAdded}
            >
              {personAdded ? 'Adicionado' : 'Adicionar'}
            </ButtonDefault>
          </ButtonArea>
        </SelectedAddInfo>
      )}
    </ListBoxItem>
  );
};

export default ListItem;
