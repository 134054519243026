import { TProducts } from '../../products/context';
import {
  customerActionType,
  TRegisterBusinessData,
  useCustomerContext,
} from '../context';
import { EFunctions } from '../context/CustomerProvider/customer.interfaces';

const useCustomer = () => {
  const { dispatch } = useCustomerContext();
  const setBusinessData = (data: Partial<TRegisterBusinessData>) => {
    dispatch({
      type: customerActionType.SET_REGISTER_BUSINESS_DATA,
      payload: data,
    });
  };
  const setProductData = (data: Partial<TProducts>) => {
    dispatch({
      type: customerActionType.SET_PRODUCT_DATA,
      payload: data,
    });
  };
  const setFunctionType = (data: { label: string; value: EFunctions }) => {
    dispatch({
      type: customerActionType.SET_FUNCTION_TYPE,
      payload: data,
    });
  };
  return { setBusinessData, setProductData, setFunctionType };
};

export default useCustomer;
