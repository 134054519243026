import styled from '@emotion/styled';

export const FundingsSelector = styled.div`
  width: fit-content;
`;

export const HeadingArea = styled.div`
  background-color: ${({ theme }) => theme.palette?.brand.background.base};
  min-height: 100px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px 28px;
`;

export const CessionsContentPageWrapper = styled.div`
  & > section {
    padding: 0px;
    border: 1px solid #DBD6FE;
    padding-bottom: 40px;
  }
`;
