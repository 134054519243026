import styled from '@emotion/styled';

export type TTypeVariant = 'fill' | 'outline' | 'ghost';

export interface IButtonDangerContainer {
  typeVariant: TTypeVariant;
}

export const ButtonDangerContainer = styled.button<IButtonDangerContainer>`
  outline: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'default !important' : 'pointer')};
  background-color: ${({ theme, typeVariant }) => {
    switch (typeVariant) {
      case 'fill':
        return theme.palette?.brand.error[900];
      case 'outline':
        return '#fff';
      case 'ghost':
        return 'transparent';
      default:
        return theme.palette?.brand.error[900];
    }
  }};
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: ${({ theme, typeVariant }) => {
    switch (typeVariant) {
      case 'fill':
        return theme.palette?.brand.neutral.base;
      case 'outline':
        return theme.palette?.brand.error[900];
      default:
        return theme.palette?.brand.error[900];
    }
  }};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  max-height: 45px;
  border: ${({ typeVariant }) => {
    switch (typeVariant) {
      case 'fill':
        return 0;
      case 'outline':
        return `2px solid  #FFD3D3`;
      default:
        return 0;
    }
  }}
`;
