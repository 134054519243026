import styled from '@emotion/styled';

export const PixTypeTogglesArea = styled.div`
margin-bottom: 17px;
`;

export const PixTypeTogglesButton = styled.button<{ selected?: boolean }>`
  border-radius: 5px;
  min-width: 50px;
  font-weight: bold !important;
  min-height: 31px;
  font-size: 14px !important;
  line-height: 21px !important;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-right: 10px;
  padding: 0px 10px;
  background-color: ${({ theme, selected }) =>
    !selected
      ? `${theme.palette?.brand?.primary?.base}26`
      : theme.palette?.brand?.primary?.base} !important;
  color: ${({ theme, selected }) =>
    !selected
      ? `${theme.palette?.brand?.primary?.base}A5}`
      : theme.palette?.common?.white} !important;
`;
