import { Divider, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  CustomCircularIndicator,
  Heading3,
  TextL,
  TextM,
  TextS,
} from 'app/components';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonData,
  TRegisterPersonDataRequest,
  usePersonContext,
} from 'modules/customer/context';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import { useRef, useState, useContext } from 'react';
import {
  EmployerStepContent,
  InfoArea,
  ListBoxItem,
  ListBoxSection,
  StepContentButtonsArea,
} from './styles';
import { useNavigate } from 'react-router-dom';
import { ListBox } from '../components';
import { NaturalPersonHandlerStepperContext } from '../NaturalPersonHandler';
import { formatCNPJ, formatPhone } from 'app/helpers';
import { usePerson } from 'modules/customer/hooks';
import { EPersonFunctions } from 'modules/customer/context/PersonProvider/person.interfaces';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { usePermify } from 'modules/auth/context';

export type ItemPersonType = TRegisterPersonDataRequest;
export type ItemBusinessType = TRegisterBusinessDataRequest;
export type TBorrowerType = 'PERSON' | 'BUSINESS';

const BorrowerInfoDivider = () => {
  return (
    <Divider
      orientation="vertical"
      sx={{
        backgroundColor: '#E6E5EA',
        height: '21px',
        width: '2px',
        margin: '0px 24px',
      }}
    />
  );
};

const EmployerStep = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { getBusinessList, creatBusinessLoading } = useCustomerService();
  const { setPersonData } = usePerson();
  const { creatPerson, creatPersonLoading } = usePersonService();
  const {
    state: { registerData, product: productData, func },
  } = usePersonContext();

  const {
    onBack,
    onBackTwoSteps,
    onForward,
    handleCreatedPerson,
    hasCustomVariables,
    customVariables,
    funding,
  } = useContext(NaturalPersonHandlerStepperContext);
  const [isLoading, setIsLoading] = useState(false);
  const currentPage = useRef<number>(0);
  const searchParameter = useRef<string>('');
  const searchValue = useRef<string>('');
  const { isProfileAuthorized } = usePermify();
  const [employerList, setEmployerList] = useState<
    ({ id: string } & TRegisterBusinessDataRequest)[] | null | undefined
  >([]);
  const [selectedEmployer, setSelectedEmployer] = useState<
    TRegisterBusinessDataRequest | null | undefined
  >();

  const onSearch = (field: string, value: string) => {
    searchParameter.current = field;
    searchValue.current = value;
    currentPage.current = 0;
    setEmployerList([]);

    if (field === '') {
      handleGetBusinessList(0);
      return;
    }
    handleGetBusinessList(0, field, value);
  };

  const handleRegister = () => {
    navigate('/records/legal/new');
  };

  const handleGetBusinessList = async (
    page: number,
    searchParameterName?: string,
    searchParameterValue?: string,
  ) => {
    setIsLoading(true);
    const businessListRes = await getBusinessList(
      page,
      searchParameterName,
      searchParameterValue,
    );
    setEmployerList((state) =>
      state?.concat(
        businessListRes?.content.filter((item) => {
          const index = state?.findIndex((el) => el.id === item.id);
          return index < 0;
        }) || [],
      ),
    );
    currentPage.current = page;
    setIsLoading(false);
  };

  const handleCreatePerson = async (parsedPersonData: TRegisterPersonData) => {
    const res = await creatPerson(parsedPersonData);
    if (res) {
      handleCreatedPerson(res?.data);
      return true;
    }
    return false;
  };

  const submitPersonData = async () => {
    setPersonData({
      employer: {
        id: selectedEmployer?.id,
      },
    });

    const parsedPersonData: TRegisterPersonData = {
      ...registerData,
      productId: productData.id,
      hasQualificationRequest:
        (productData.qualification_required &&
          func.value === EPersonFunctions.BORROWER &&
          isProfileAuthorized(
            EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION_CONDITIONS,
          )) ||
        false,
      employer: {
        id: selectedEmployer?.id,
      },
      fundingId: funding as string,
      role: func.value,
      customVariables: customVariables ?? undefined,
    };
    const success = await handleCreatePerson(parsedPersonData);
    if (success) onForward();
  };

  const onSkip = async () => {
    const parsedPersonData: TRegisterPersonData = {
      ...registerData,
      productId: productData.id,
      hasQualificationRequest:
        (productData?.qualification_required &&
          func.value === EPersonFunctions.BORROWER &&
          isProfileAuthorized(
            EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION_CONDITIONS,
          )) ||
        false,
      fundingId: funding as string,
      role: func.value,
      customVariables: customVariables ?? undefined,
    };
    const success = await handleCreatePerson(parsedPersonData);
    if (success) {
      onForward();
    } else {
      onBack();
    }
  };

  return (
    <BoxSection boxVariant="rounded">
      <EmployerStepContent>
        <Heading3>Empregador</Heading3>
        <ListBox
          filterItems={[
            { name: 'Nome', parameterName: 'legal_name' },
            { name: 'CNPJ', parameterName: 'taxpayer_id' },
          ]}
          items={employerList || []}
          onSearch={onSearch}
          isLoading={isLoading || creatBusinessLoading}
          handleRequest={() => {
            handleGetBusinessList(
              currentPage.current + 1,
              searchParameter.current ? searchParameter.current : undefined,
              searchValue.current ? searchValue.current : undefined,
            );
          }}
        >
          {(i) => {
            const item = i as ItemBusinessType;
            return (
              <ListBoxItem
                className={item.id === selectedEmployer?.id ? 'selected' : ''}
                onClick={() => setSelectedEmployer(item)}
                key={item.id || ''}
              >
                <TextL
                  style={{
                    color: theme.palette.brand.secondary.base,
                  }}
                  weight="semibold"
                >
                  {item.legal_name}
                </TextL>
                <InfoArea>
                  <TextS>{formatCNPJ(item.taxpayer_id)}</TextS>
                  <BorrowerInfoDivider />
                  <TextS>
                    {formatPhone(
                      item.phone.area_code + item.phone.number || '',
                    )}
                  </TextS>
                  <BorrowerInfoDivider />
                  <TextS>{item.email_address}</TextS>
                </InfoArea>
              </ListBoxItem>
            );
          }}
        </ListBox>
        <ListBoxSection>
          <TextM weight="Semibold">Não encontrou o empregador?</TextM>
          <ButtonPrimary
            style={{ maxWidth: '30%' }}
            typeVariant="outline"
            onClick={handleRegister}
          >
            <AddIcon />
            <TextM
              weight="Semibold"
              style={{ color: theme.palette.brand.primary.base }}
            >
              Cadastrar empresa
            </TextM>
          </ButtonPrimary>
        </ListBoxSection>
        <StepContentButtonsArea>
          {creatPersonLoading ? (
            <CustomCircularIndicator
              style={{ color: theme.palette.brand.primary.base }}
            />
          ) : (
            <>
              <ButtonDefault
                margin={true}
                onClick={() =>
                  hasCustomVariables ? onBack() : onBackTwoSteps()
                }
              >
                <ArrowBack />
              </ButtonDefault>

              <ButtonPrimary
                onClick={selectedEmployer ? submitPersonData : onSkip}
              >
                {selectedEmployer ? 'Avançar' : 'Pular'}
              </ButtonPrimary>
            </>
          )}
        </StepContentButtonsArea>
      </EmployerStepContent>
    </BoxSection>
  );
};

export default EmployerStep;
