import { useTheme } from '@mui/material';
import { DownloadIcon } from '../Icons';
import { DownloadButtonContainer } from './styles';

export interface IDownloadButton {
  handleClick: () => void;
}

const DownloadButton = ({ handleClick }: IDownloadButton) => {
  const theme = useTheme();
  return (
    <DownloadButtonContainer onClick={handleClick}>
      <DownloadIcon color={theme.palette.brand.primary.base as string} />
    </DownloadButtonContainer>
  );
};

export default DownloadButton;
