import { isValidCNPJ } from 'app/helpers';
import * as yup from 'yup';

export const custodianSchema = yup.object({
  taxpayer_id: yup
    .string()
    .required('Campo obrigatório')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}$/,
      'Formato de CNPJ incorreto',
    )
    .test('test-invalid-cnpj', 'CNPJ inválido', (cnpj) =>
      isValidCNPJ(cnpj || ''),
    ),
  legal_name: yup.string().required('Campo obrigatório'),
  foundation_date: yup.string().required('Campo obrigatório'),
  phone: yup
    .string()
    .test(
      'test-phone-number',
      'Formato de telefone incorreto',
      function (value) {
        const { path, createError } = this;

        if (
          value &&
          value.length < 15 &&
          value?.replace(/\D+/g, '')?.match(/(\d{2})(\d)/)?.[2] === '9'
        ) {
          return createError({
            path,
            message: 'Formato de telefone incorreto',
          });
        }

        if (value && value.length < 14) {
          return createError({
            path,
            message: 'Formato de telefone incorreto',
          });
        }

        return true;
      },
    ),
  // eslint-disable-next-line no-useless-escape
  email_address: yup.string().email('Formato de e-mail inválido'),
});
