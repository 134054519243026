import styled from '@emotion/styled';

export const StepContentInput = styled.div<{
  marginTop?: string;
  isFull?: boolean;
}>`
  width: ${(props) => (props.isFull ? '100%' : '50%')};
  margin-top: ${(props) => props.marginTop || '0px'};
  padding: 15px;
`;

export const StepContentInputArea = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;
export const StepAddressContentInputArea = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
`;

export const StepContentInputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StepContentButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    width: auto;
  }
`;

export const TableFilesWrapper = styled.div`
  width: 100%;

  thead {
    th {
      padding: 20px !important;
    }
  }

  tbody {

    tr {
      td {
        padding: 24px !important;
        width: 50%;
        &:first-of-type {
          width: 40% !important;
        }
      }

    }

    tr:nth-of-type(odd) {
      background-color: #fff !important;
    }
  }
  
`;

export const InfoStepExistingBusiness = styled.div`
  text-align: left;
  border: 1px solid #E6E5EA;
  padding: 16px;
  border-radius: 10px;
  span {
    display: block;
  }

  .link {
    display: block;

    font-size: 16px;
    text-decoration: none;
    color: ${({ theme }) => theme.palette?.brand?.primary.base};
    font-weight: bold;
    margin-top: 8px;
  }
`;

export const TableHeadFile = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 6px;
    cursor: pointer;
  }
`;

export const TableBodyFile = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 18px;
  }
`;

export const UploadArea = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export const InfoArea = styled.div`
  display: flex;
  margin-top: 6px;
`;

export const VariablesArea = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  border: 1px solid #E6E5EA;
  border-radius: 10px;
  padding: 24px;
  overflow: auto;
  height: 500px;
`;

export const InputItem = styled.div`
  width: 50%;
  margin-top: 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BusinessRelationsList = styled.div`
  background: #FFFFFF;
  border-radius: 20px;
  padding: 24px;
  border: 1px solid #E6E5EA;
  box-shadow: 0px 2px 23px -8px rgba(31, 30, 35, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  height: 584px;
`;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  margin-top: 32px;
  justify-content: flex-end;
`;

export const StepContentUserAccessHandlerWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  border-radius: 10px;
  padding: 0px 24px;
  margin-bottom: 24px;
`;

export const StepContentReviewItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;
`;
export const StepContentReviewPermissions = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 24px;
`;

export const StepContentReviewPermission = styled.div`
  background-color: #EDEDED;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  position: relative;
  margin-bottom: 8px;
`;

export const StepContentReviewItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  cursor: pointer;
  padding: 12px 0px;
  display: flex;
  width: 48%;
  justify-content: space-between;

  &:nth-last-of-type(-n+2) {
    border-bottom: unset;
  }
`;

export const StepContentPermissionItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  cursor: pointer;
  padding: 24px 0px;

  &:last-of-type {
    border-bottom: unset;
  }

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  .header {
    display: flex;
    justify-content: space-between;
  }
  .disabled-switch {
    .MuiSwitch-thumb {
      color: #CDCCD1 !important;
    }
    .MuiSwitch-track {
      background-color: #EDEDED !important;
    }
  }
`;
