import { BaseModal, ButtonPrimary, CloseIcon, TextL } from 'app/components';
import { Content, ModalHeader } from './styles';
import { useTheme } from '@mui/material';

export interface RetryPaymentConfirmationModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const RetryPaymentConfirmationModal = ({
  handleClose,
  handleConfirm,
  isOpen,
}: RetryPaymentConfirmationModalProps) => {
  const theme = useTheme();
  return (
    <BaseModal open={isOpen} onClose={handleClose}>
      <>
        <ModalHeader style={{ width: '100%' }}>
          <TextL>Confirmar retentativa de pagamento</TextL>
          <div onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </ModalHeader>

        <Content>
          <TextL
            style={{
              color: theme.palette.brand.secondary.base,
              textAlign: 'center',
              marginTop: '15px',
            }}
          >
            Tem certeza que deseja retentar a realização do pagamento?
          </TextL>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '15px',
              gap: '30px',
            }}
          >
            <ButtonPrimary typeVariant="outline" onClick={handleClose}>
              Cancelar
            </ButtonPrimary>
            <ButtonPrimary onClick={handleConfirm}>Confirmar</ButtonPrimary>
          </div>
        </Content>
      </>
    </BaseModal>
  );
};

export default RetryPaymentConfirmationModal;
