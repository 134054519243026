import styled from '@emotion/styled';

export const MenuWrapper = styled.div`
  position: relative;
  margin-top: 13px;
`;

export const MenuOutside = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw;
  background: transparent;
  z-index: 999;
`;

export const MenuContent = styled.div<{ hideArrow?: boolean }>`
  padding: 16px;
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  top: 20px;
  right: 0px;
  z-index: 9999;
  min-width: 180px;
  border-radius: 20px;
  filter: drop-shadow(0px 18px 25px rgba(0, 21, 33, 0.14));
  border: 1px solid #E6E5EA;
  background-color: ${({ theme }) => theme.palette?.brand.neutral.base};

  
  &::after {
    display: ${({ hideArrow }) => (hideArrow ? 'none' : 'block')};
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    top: -8px;
    left: 40px;
    transform: rotateZ(45deg) ;
    z-index: -1;
    background-color: ${({ theme }) => theme.palette?.brand.neutral.base};
    border-top: 1px solid #E6E5EA;
    border-left: 1px solid #E6E5EA;
    border-radius: 3px 0px 0px 0px;

  }
`;
