import { useMemo, useState } from 'react';
import { AddressHttpService } from '../services';
import { useAppContext } from 'app/context/AppContextProvider';

const useAddress = () => {
  const { dns } = useAppContext();
  const [isFetchingPostalCode, toggleFetchingPostalCode] = useState(false);
  const [isFetchingCities, toggleFetchingCities] = useState(false);

  const isLocalOrSandboxEnv = useMemo(
    () => ['localhost:3000', 'd3vrw2742r7c9x.cloudfront.net'].includes(dns),
    [dns],
  );
  const fetchCities = async (stateCode: string) => {
    try {
      toggleFetchingCities(true);

      const addressHttpService = new AddressHttpService(isLocalOrSandboxEnv);
      addressHttpService.setHeader({
        handled: true,
      });
      const res = await addressHttpService.getCities(stateCode);

      return res;
    } catch (e) {
      console.log(e);
    } finally {
      toggleFetchingCities(false);
    }
  };

  const fetchAddressByPostalCode = async (postalCode: string) => {
    try {
      toggleFetchingPostalCode(true);

      const addressHttpService = new AddressHttpService(isLocalOrSandboxEnv);
      addressHttpService.setHeader({
        handled: true,
      });
      const res = await addressHttpService.getAddressByPostalCode(postalCode);

      return res;
    } catch (e) {
      console.log(e);
    } finally {
      toggleFetchingPostalCode(false);
    }
  };

  return {
    isFetchingCities,
    fetchCities,
    isFetchingPostalCode,
    fetchAddressByPostalCode,
  };
};

export default useAddress;
