import React, { cloneElement, ReactElement } from 'react';

interface ProviderComposerProperties {
  contexts: Array<React.ReactElement | React.ReactNode | unknown>;
  children?: React.ReactElement | React.ReactNode;
}

const ProviderComposer: React.FC<ProviderComposerProperties> = ({
  contexts = [],
  children,
}): React.ReactElement => {
  return (
    <>
      {contexts.reduceRight(
        (child, parent) =>
          cloneElement(parent as ReactElement, {
            children: child,
          }),
        children,
      )}
    </>
  );
};

export default ProviderComposer;
