import styled from '@emotion/styled';

export const ApplicationRequestContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
`;

export const ApplicationRequestStepperContainer = styled.div`
  width: 30%;
`;

export const ApplicationRequestStepContainer = styled.div`
  width: 100%;
  margin-left: 30px;
`;

export const StepContentButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    width: auto;
  }
`;
