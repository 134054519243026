import { ESignatureCollectMethod } from '../services/hooks/useApplicationsService';

export const SignatureCollectObject = {
  [ESignatureCollectMethod.NONE]: 'Manual',
  [ESignatureCollectMethod.SMS]: 'SMS',
  [ESignatureCollectMethod.LINK]: 'Link',
  [ESignatureCollectMethod.EMAIL]: 'E-mail',
  [ESignatureCollectMethod.PHYSICAL]: 'Físico',
  [ESignatureCollectMethod.WHATSAPP]: 'WhatsApp',
};
