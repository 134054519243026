import React, { RefObject, useEffect, useMemo, useState } from 'react';
import { CalendarInput, Heading4, Popover } from 'celcoin-design-system';
import {
  CloseBtn,
  CessionsFilterButtons,
  CessionsFilterBody,
  CessionsFilterContainer,
  CessionsFilterHeader,
  CessionsFilterInput,
  CessionsFilterInputInvalid,
  CessionsFilterInputs,
} from './styles';
import { ButtonPrimary } from 'app/components';
import { Close } from '@mui/icons-material';
import {
  formatDateForBackendString,
  formatDateFromBackend,
} from 'app/helpers/dateHelpers';
import { Divider, useTheme } from '@mui/material';
import { TFilterType } from '../../types';

const buttonStyles = {
  width: 'fit-content',
  padding: '5px 10px',
  fontSize: '14px',
  marginRight: '5px',
};

export type TStatusItem = {
  title: string;
  value: string;
  selected: boolean;
};

export interface ICessionsFilterProps {
  open: boolean;
  children?: React.ReactElement;
  dateFrom: string;
  dateTo: string;
  filterType: TFilterType;
  refElement: RefObject<HTMLDivElement>;
  handleClose?: () => void;
  handleFilter: (
    dateFrom: string,
    dateTo: string,
    filterType: TFilterType,
  ) => void;
}
const CessionsFilter = ({
  open,
  refElement,
  filterType,
  dateFrom,
  dateTo,
  handleClose,
  handleFilter,
}: ICessionsFilterProps) => {
  const theme = useTheme();
  const [dateFromInner, updateDateFromInner] = useState<string | null>(null);
  const [dateToInner, updateDateToInner] = useState<string | null>(null);
  const [filterTypeInner, updateFilterInner] =
    useState<TFilterType>(filterType);

  useEffect(() => {
    if (open) {
      updateFilterInner(filterType);
      updateDateFromInner(formatDateFromBackend(dateFrom).toISOString());
      updateDateToInner(formatDateFromBackend(dateTo).toISOString());
    }
  }, [open]);

  const isInvalid = useMemo(() => {
    if (filterTypeInner !== TFilterType.CUSTOM) return false;
    if (!dateToInner || !dateFromInner) return 'Preencha todas as datas';

    if (
      new Date(dateToInner) > new Date() ||
      new Date(dateFromInner) > new Date() ||
      new Date(dateFromInner) > new Date(dateToInner)
    )
      return 'Datas selecionadas inválidas';

    return false;
  }, [dateFromInner, dateToInner, filterTypeInner]);

  return (
    <Popover
      open={open}
      anchorEl={refElement.current}
      onClose={handleClose}
      sx={{
        '& .MuiPopover-paper	': {
          marginTop: '60px',
          borderRadius: '20px',
        },
      }}
    >
      <CessionsFilterContainer>
        <CessionsFilterHeader>
          <Heading4>Data</Heading4>
          <CloseBtn onClick={handleClose}>
            <Close sx={{ color: theme.palette.brand.secondary.base }} />
          </CloseBtn>
        </CessionsFilterHeader>
        <CessionsFilterBody>
          <CessionsFilterButtons>
            <ButtonPrimary
              typeVariant={
                filterTypeInner === TFilterType.TODAY ? 'fill' : 'outline'
              }
              onClick={() => updateFilterInner(TFilterType.TODAY)}
              style={buttonStyles}
            >
              Hoje
            </ButtonPrimary>
            <ButtonPrimary
              typeVariant={
                filterTypeInner === TFilterType.YESTERDAY ? 'fill' : 'outline'
              }
              onClick={() => updateFilterInner(TFilterType.YESTERDAY)}
              style={buttonStyles}
            >
              Ontem
            </ButtonPrimary>
            <ButtonPrimary
              typeVariant={
                filterTypeInner === TFilterType.LAST_7_DAYS ? 'fill' : 'outline'
              }
              onClick={() => updateFilterInner(TFilterType.LAST_7_DAYS)}
              style={buttonStyles}
            >
              Últimos 7 dias
            </ButtonPrimary>
            <ButtonPrimary
              typeVariant={
                filterTypeInner === TFilterType.ALL ? 'fill' : 'outline'
              }
              onClick={() => updateFilterInner(TFilterType.ALL)}
              style={buttonStyles}
            >
              Desde o início
            </ButtonPrimary>
            <ButtonPrimary
              typeVariant={
                filterTypeInner === TFilterType.CUSTOM ? 'fill' : 'outline'
              }
              onClick={() => updateFilterInner(TFilterType.CUSTOM)}
              style={buttonStyles}
            >
              Escolher período
            </ButtonPrimary>
          </CessionsFilterButtons>
          {filterTypeInner === TFilterType.CUSTOM && (
            <>
              <Divider style={{ marginTop: '20px', marginBottom: '10px' }} />
              <CessionsFilterInputs>
                <CessionsFilterInput>
                  <CalendarInput
                    name="dateFrom"
                    labelValue=""
                    placeholder="dd/mm/aaaa"
                    value={dateFromInner}
                    onChange={(e) => {
                      try {
                        updateDateFromInner(e?.toISOString() ?? null);
                      } catch {
                        updateDateFromInner(null);
                      }
                    }}
                    validationError={''}
                  />
                </CessionsFilterInput>
                <CessionsFilterInput>
                  <CalendarInput
                    name="dateTo"
                    labelValue=""
                    placeholder="dd/mm/aaaa"
                    value={dateToInner}
                    onChange={(e) => {
                      try {
                        updateDateToInner(e?.toISOString() ?? null);
                      } catch {
                        updateDateToInner(null);
                      }
                    }}
                    validationError={''}
                  />
                </CessionsFilterInput>
              </CessionsFilterInputs>
              {isInvalid !== false && (
                <CessionsFilterInputInvalid>
                  {isInvalid}
                </CessionsFilterInputInvalid>
              )}
            </>
          )}
        </CessionsFilterBody>
        <ButtonPrimary
          style={{ marginTop: '25px' }}
          disabled={
            (filterType === filterTypeInner &&
              filterTypeInner !== TFilterType.CUSTOM) ||
            isInvalid !== false
          }
          onClick={() =>
            handleFilter(
              formatDateForBackendString(
                new Date(dateFromInner as string),
              ) as string,
              formatDateForBackendString(
                new Date(dateToInner as string),
              ) as string,
              filterTypeInner,
            )
          }
        >
          Aplicar filtro
        </ButtonPrimary>
      </CessionsFilterContainer>
    </Popover>
  );
};

export default CessionsFilter;
