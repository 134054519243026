import { useState, useMemo, useCallback, useEffect } from 'react';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { useTheme, Checkbox } from '@mui/material';
import {
  Cnab444ApplicationsButtonArea,
  Cnab444ApplicationsFilterArea,
  Cnab444ApplicationsFiltersCount,
  Cnab444ApplicationsHeader,
  Cnab444ApplicationsTableArea,
  Cnab444ApplicationsWrapper,
} from './styles';
import { ButtonPrimary } from 'app/components';
import {
  EmptyAccessImage,
  InstallmentsFiltersIcon,
} from 'app/components/Icons';
import { Heading3, TextL, TextM, TextS, TextXs } from 'celcoin-design-system';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { EmptyArea } from 'modules/management/components';
import { TBodyContentCell, TTableHeadCell, Table } from 'app/components/Table';
import { useSnackbar } from 'app/hooks/useSnackbar';
import ApplicationsFilters, {
  ApplicationStatusDescription,
  TFilters,
} from 'modules/applications/components/ApplicationsFilters/ApplicationsFilters';
import {
  TApplicationResponse,
  TQualificationRequestApplication,
} from 'modules/products/services/hooks/useApplicationsService';
import {
  useApplicationsService,
  useProductsService,
} from 'modules/products/services/hooks';
import {
  formatDateForBackendString,
  formatDateFromBackend,
  formatStringHourFromBackend,
} from 'app/helpers/dateHelpers';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import { TProducts } from 'modules/products/context';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import { formatCNPJ, formatCPF } from 'app/helpers';
import { formatCurrency } from 'app/utils/normalizer';
import { Cnab444Generate } from '../Cnab444Generate';
import { TextSnippetOutlined } from '@mui/icons-material';
import { endOfDay, startOfDay } from 'date-fns';
import { flushSync } from 'react-dom';

export const initalFilters = {
  status: ['ISSUED'],
};

export type TApplications = Partial<TApplicationResponse> & {
  borrower?: {
    id: string;
    name: string;
    taxpayer_id: string;
  };
  product?: TProducts;
  qualificationData?: TQualificationRequestApplication;
};
interface ICnab444ApplicationsTableProps {
  fundingId: string;
}
const Cnab444ApplicationsTable = ({
  fundingId,
}: ICnab444ApplicationsTableProps) => {
  const theme = useTheme();
  const [applicationsList, setApplicationsList] = useState<TApplications[]>([]);
  const [isFiltersOpened, toggleFiltersOpened] = useState<boolean>(false);
  const [checkedItems, updateCheckedItems] = useState<string[]>([]);
  const [isCnab444GenerateModalOpen, toggleCnab444GenerateModal] =
    useState<boolean>(false);
  const [filters, updateFilters] = useState<TFilters>(
    initalFilters as TFilters,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const { getPerson } = usePersonService();
  const { getBusiness } = useCustomerService();
  const { getProduct } = useProductsService();
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const [applicationIds, setApplicationIds] = useState<string[]>([]);
  const { getApplications, getQualificationRequestApplication } =
    useApplicationsService();
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );

  const onApplyFilters = useCallback((f: TFilters) => {
    updateFilters(f);
    toggleFiltersOpened(false);
  }, []);

  const handleGetApplications = async (page: number, itemsPerPage?: number) => {
    setIsLoading(true);
    setApplicationsList([]);
    try {
      const applicationFilters = {
        created_date_from: formatDateForBackendString(
          filters?.minCreatedAtDate,
        ),
        created_date_to: formatDateForBackendString(filters?.maxCreatedAtDate),
        disbursement_date_from: formatDateForBackendString(
          filters?.minDisbursementDate,
        ),
        disbursement_date_to: formatDateForBackendString(
          filters?.maxDisbursementDate,
        ),
        disbursement_processed_date_from: formatDateForBackendString(
          filters.minDisbursementProcessedAtDate,
        ),
        disbursement_processed_date_to: formatDateForBackendString(
          filters.maxDisbursementProcessedAtDate,
        ),
        ids: filters.applicationId,
        borrower_id: filters?.borrower,
        funding_id: fundingId,
      };

      const applicationData = await getApplications(
        page,
        itemsPerPage,
        applicationFilters,
        !filters.status || filters.status.length === 0
          ? undefined
          : filters.status,
      );
      setTotalItemsQty(applicationData?.total_elements || 0);
      if (
        applicationData?.content !== undefined &&
        Array.isArray(applicationData?.content)
      ) {
        const applicationDataWithProduct = await Promise.all<TApplications>(
          applicationData?.content.map(
            async (application: TApplicationResponse) => {
              const productId = application.product.id;
              const qualificationDataArr =
                await getQualificationRequestApplication(
                  productId,
                  0,
                  application.borrower.id,
                  application.id,
                );
              try {
                const productData = await getProduct(productId, true);
                const product = productData?.data as TProducts;
                const isBusiness = product?.borrower_type === 'BUSINESS';

                const borrowerData = !isBusiness
                  ? await getPerson(application.borrower.id, true)
                  : await getBusiness(application.borrower.id, true);

                return {
                  ...application,
                  product,
                  borrower: {
                    id: borrowerData?.id || '',
                    name: isBusiness
                      ? (borrowerData as TRegisterBusinessDataRequest)
                          .legal_name
                      : (borrowerData as TRegisterPersonDataRequest)
                          ?.full_name || '',
                    taxpayer_id: borrowerData?.taxpayer_id || '',
                  },
                  qualificationData: qualificationDataArr
                    ? qualificationDataArr?.content?.[0]
                    : undefined,
                };
              } catch {
                return {
                  ...application,
                  borrower: undefined,
                  product: undefined,
                  qualificationData: qualificationDataArr
                    ? qualificationDataArr?.content?.[0]
                    : undefined,
                };
              }
            },
          ),
        );

        flushSync(() =>
          setApplicationsList((state) =>
            state?.concat(
              applicationDataWithProduct.filter((content) => {
                const inx = state?.findIndex(
                  (stateContent) => stateContent?.id === content?.id,
                );
                return inx < 0;
              }),
            ),
          ),
        );
        setIsLoading(false);

        if (
          ((typeof filters?.borrower === 'string' &&
            filters.borrower.length > 0) ||
            (typeof filters?.applicationId === 'string' &&
              filters.applicationId.length > 0)) &&
          applicationData?.total_elements &&
          applicationData?.total_elements > 0
        ) {
          const applicationIdsResponse = (
            await getApplications(
              0,
              applicationData?.total_elements || 0,
              applicationFilters,
              !filters.status || filters.status.length === 0
                ? undefined
                : filters.status,
            )
          )?.content?.map((application) => application.id);
          setApplicationIds(applicationIdsResponse || []);
        }
      }
    } catch (error) {
      showSnackbar('Ocorreu algum problema ao buscar as solicitações', 'error');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetApplications(currentPage, linesPerPage);
  }, [linesPerPage, currentPage, fundingId]);

  useEffect(() => {
    if (currentPage === 0) {
      handleGetApplications(0, linesPerPage);
    } else {
      setCurrentPage(0);
    }
  }, [filters]);

  const tableHeadContent: TTableHeadCell[] = useMemo(() => {
    return [
      {
        id: '1',
        content: (
          <TableHeadCellContainer style={{ padding: '0px 10px 0px 7px' }}>
            <div />
          </TableHeadCellContainer>
        ),
      },
      {
        id: '2',
        content: (
          <TableHeadCellContainer>
            <TextL weight="bold">Nome</TextL>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '3',
        content: (
          <TableHeadCellContainer>
            <TextL weight="bold">Status</TextL>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '4',
        content: (
          <TableHeadCellContainer>
            <TextL weight="bold">Data de Digitação</TextL>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '5',
        content: (
          <TableHeadCellContainer>
            <TextL weight="bold">Data de Desembolso</TextL>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '6',
        content: (
          <TableHeadCellContainer>
            <TextL weight="bold">Data de Pagamento Pix</TextL>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '7',
        content: (
          <TableHeadCellContainer>
            <TextL weight="bold">Valor Solicitado</TextL>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '8',
        content: (
          <TableHeadCellContainer>
            <TextL weight="bold">Valor de Aquisição</TextL>
          </TableHeadCellContainer>
        ),
      },
      {
        id: '9',
        content: (
          <TableHeadCellContainer>
            <TextL weight="bold">Total Devido</TextL>
          </TableHeadCellContainer>
        ),
      },
    ] as TTableHeadCell[];
  }, [checkedItems, applicationsList]);

  const filterCount = useMemo(() => {
    let counter = 0;
    if (filters.minCreatedAtDate || filters.maxCreatedAtDate) counter++;
    if (filters.minDisbursementDate || filters.maxDisbursementDate) counter++;
    if (
      filters.minDisbursementProcessedAtDate ||
      filters.maxDisbursementProcessedAtDate
    )
      counter++;
    if (filters.productId) counter++;
    if (filters.fundingId) counter++;

    return counter;
  }, [filters]);

  return (
    <>
      <Cnab444ApplicationsWrapper>
        <Cnab444ApplicationsFilterArea>
          <Cnab444ApplicationsHeader>
            <Heading3>Lista de Solicitações</Heading3>
            <TextM style={{ marginTop: '5px' }}>
              Desmarque solicitações para excluí-las do arquivo
            </TextM>
          </Cnab444ApplicationsHeader>
          <Cnab444ApplicationsButtonArea>
            {applicationsList.length > 0 && (
              <ButtonPrimary
                style={{
                  maxWidth: '190px',
                  marginLeft: 'auto',
                }}
                typeVariant="outline"
                iconPosition="left"
                btnIcon={
                  <TextSnippetOutlined
                    style={{
                      color: theme.palette.brand.primary.base as string,
                    }}
                  />
                }
                onClick={() => toggleCnab444GenerateModal(() => true)}
              >
                <TextM
                  weight="Bold"
                  style={{
                    marginLeft: '11px',
                    color: theme.palette.brand.primary.base as string,
                  }}
                >
                  Gerar Arquivo
                </TextM>
              </ButtonPrimary>
            )}
            <ButtonPrimary
              style={{
                maxWidth: filterCount > 0 ? '150px' : '120px',
                marginLeft: '11px',
              }}
              typeVariant="outline"
              iconPosition="left"
              btnIcon={
                <InstallmentsFiltersIcon
                  color={theme.palette.brand.primary.base as string}
                />
              }
              onClick={() => toggleFiltersOpened(() => true)}
            >
              <TextM
                weight="Bold"
                style={{
                  marginLeft: '11px',
                  color: theme.palette.brand.primary.base as string,
                }}
              >
                Filtros
              </TextM>
              {filterCount > 0 && (
                <Cnab444ApplicationsFiltersCount>
                  {filterCount}
                </Cnab444ApplicationsFiltersCount>
              )}
            </ButtonPrimary>
          </Cnab444ApplicationsButtonArea>
        </Cnab444ApplicationsFilterArea>
        {applicationsList.length === 0 || isLoading ? (
          <EmptyArea
            isLoading={!!isLoading}
            message="Nenhuma parcela encontrada"
          >
            <EmptyAccessImage />
          </EmptyArea>
        ) : (
          <Cnab444ApplicationsTableArea>
            <Table
              showPagination
              handlePageChange={(selectedPage) => {
                setCurrentPage(selectedPage - 1);
              }}
              handleLinesPerPage={(selectedLinesPerPage) => {
                setCurrentPage(0);
                setLinesPerPage(
                  selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                );
                handleGetApplications(0, selectedLinesPerPage);
              }}
              totalElementsQty={totalItemsQty}
              currentPage={currentPage + 1}
              linesPerPage={linesPerPage}
              tableHeadCell={tableHeadContent}
              tableBodyContent={applicationsList.map((application) => {
                const { product } = application;
                const isChecked = !checkedItems.includes(application.id!);
                const acquisitionAmount =
                  (application.loan_details?.financed_amount ?? 0) +
                  (application.base_premium_amount ?? 0) +
                  (application.additional_premium_amount ?? 0);

                return {
                  id: '1',
                  handleLineClick: () => {
                    if (!isChecked) {
                      updateCheckedItems((state) =>
                        state.filter((i) => i !== application.id),
                      );
                    } else {
                      updateCheckedItems((state) =>
                        state.concat(application.id!),
                      );
                    }
                  },
                  disabled: !isChecked,
                  cells: [
                    {
                      id: '1',
                      content: (
                        <TableBodyCellContainer style={{ padding: '0px' }}>
                          <Checkbox checked={isChecked} />
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '2',
                      content: (
                        <TableBodyCellContainer>
                          <TextS weight="Bold">
                            {application?.borrower?.name || '-'}
                          </TextS>
                          {application?.borrower?.taxpayer_id && (
                            <TextXs>
                              {product?.borrower_type === 'PERSON'
                                ? formatCPF(application?.borrower?.taxpayer_id)
                                : formatCNPJ(
                                    application?.borrower?.taxpayer_id,
                                  )}
                            </TextXs>
                          )}
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '3',
                      content: (
                        <TableBodyCellContainer>
                          <TextS>
                            {application?.status
                              ? ApplicationStatusDescription[
                                  application.status as keyof typeof ApplicationStatusDescription
                                ]
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '4',
                      content: (
                        <TableBodyCellContainer>
                          <TextS>
                            {application?.created_at
                              ? `${formatDateFromBackend(
                                  application?.created_at,
                                ).toLocaleDateString(
                                  'pt-BR',
                                )} às ${formatStringHourFromBackend(
                                  application?.created_at,
                                )}`
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '5',
                      content: (
                        <TableBodyCellContainer>
                          <TextS>
                            {application?.disbursement_date
                              ? `${formatDateFromBackend(
                                  application?.disbursement_date,
                                ).toLocaleDateString('pt-BR')}`
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '6',
                      content: (
                        <TableBodyCellContainer>
                          <TextS>
                            {application?.disbursement?.processed_at
                              ? `${formatDateFromBackend(
                                  application.disbursement.processed_at,
                                ).toLocaleDateString(
                                  'pt-BR',
                                )} às ${formatStringHourFromBackend(
                                  application.disbursement.processed_at,
                                )}`
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '7',
                      content: (
                        <TableBodyCellContainer>
                          <TextS>
                            {application?.requested_amount
                              ? formatCurrency(application?.requested_amount)
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '8',
                      content: (
                        <TableBodyCellContainer>
                          <TextS>{formatCurrency(acquisitionAmount)}</TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '9',
                      content: (
                        <TableBodyCellContainer>
                          <TextS>
                            {application?.loan_details?.total_amount_owed
                              ? formatCurrency(
                                  application?.loan_details?.total_amount_owed,
                                )
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                  ] as TBodyContentCell[],
                };
              })}
            />
          </Cnab444ApplicationsTableArea>
        )}
        <ApplicationsFilters
          filters={filters}
          handleApply={onApplyFilters}
          handleClose={() => toggleFiltersOpened(false)}
          initialFilters={initalFilters as TFilters}
          isLoading={isLoading}
          isOpen={isFiltersOpened}
          onlyOneStatus={true}
          hideFunding={true}
          hideProduct={true}
          hideDisbursementProcessedAtDate={true}
        />
        <Cnab444Generate
          isOpen={isCnab444GenerateModalOpen}
          handleClose={() => toggleCnab444GenerateModal(false)}
          fundingId={fundingId}
          filters={
            isCnab444GenerateModalOpen
              ? {
                  created_from: filters?.minCreatedAtDate
                    ? startOfDay(
                        new Date(filters.minCreatedAtDate),
                      ).toISOString()
                    : undefined,
                  created_to: filters?.maxCreatedAtDate
                    ? endOfDay(new Date(filters.maxCreatedAtDate)).toISOString()
                    : undefined,
                  disbursement_date_from:
                    formatDateForBackendString(filters?.minDisbursementDate) ??
                    undefined,
                  disbursement_date_to:
                    formatDateForBackendString(filters?.maxDisbursementDate) ??
                    undefined,
                  excluded_application_ids: checkedItems,
                  application_ids:
                    (typeof filters?.borrower === 'string' &&
                      filters.borrower.length > 0) ||
                    (typeof filters?.applicationId === 'string' &&
                      filters.applicationId.length > 0)
                      ? applicationIds
                      : undefined,
                  status:
                    filters?.status && filters?.status.length > 0
                      ? filters.status[0]
                      : undefined,
                }
              : undefined
          }
        />
      </Cnab444ApplicationsWrapper>
    </>
  );
};

export default Cnab444ApplicationsTable;
