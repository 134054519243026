import { HTMLAttributes } from 'react';
import { useTheme } from '@mui/material';
import { TextButtonContainer } from './styles';

interface IHeading3Props extends HTMLAttributes<HTMLParagraphElement> {
  fontWeight?: 'XB' | 'B' | 'Sb' | 'Md' | 'Rg';
  size?: 'M' | 'G';
}

const TextButton = ({
  fontWeight = 'B',
  size = 'M',
  children,
  ...props
}: IHeading3Props) => {
  const theme = useTheme();
  return (
    <TextButtonContainer
      {...props}
      style={{
        ...props.style,
        fontWeight: theme.typography?.fontWeight?.[fontWeight] || 400,
        fontSize: theme.typography?.customfontSize?.[size] || 'M',
      }}
    >
      {children}
    </TextButtonContainer>
  );
};

export default TextButton;
