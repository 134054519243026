import styled from '@emotion/styled';

export const WithdrawConfirmItem = styled.div`
  margin-top: 10px;
  width: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
`;
