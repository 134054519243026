
const LegalRelationIcon = ({color, ...props}: { color: string }) => (
  <svg
    width={28}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.333.667v1.704L22 4.595l4.843-1.613.844 2.528-4.043 1.348 4.128 11.349A7.982 7.982 0 0 1 22 20.667a7.98 7.98 0 0 1-5.772-2.46l4.125-11.35-5.02-1.674v18.15h5.334V26H7.333v-2.666h5.334V5.183L7.643 6.858l4.128 11.349A7.977 7.977 0 0 1 6 20.667a7.98 7.98 0 0 1-5.772-2.46l4.125-11.35-4.04-1.346.844-2.53L6 4.593l6.667-2.223V.667h2.666ZM22 10.137l-2.61 7.182A5.3 5.3 0 0 0 22 18c.933 0 1.827-.24 2.61-.68L22 10.136v.002Zm-16 0L3.39 17.32A5.301 5.301 0 0 0 6 18c.933 0 1.827-.24 2.61-.68L6 10.136v.002Z"
      fill={color}
    />
  </svg>
)

export default LegalRelationIcon
