import { BaseHttpService } from 'app/services';
import {
  TGenerateCnab444Request,
  TGenerateCnab444Response,
} from 'modules/cession/interfaces/cnab444';
import { IList } from 'modules/products/services/hooks/intefaces';

export class CnabHttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  async generateCnab444File(
    params: TGenerateCnab444Request,
  ): Promise<TGenerateCnab444Response> {
    const res = await this.post(
      `${this.BASE_URL}/funding/applications/cnab444/requests`,
      params,
    );
    return res.data;
  }

  async getCnab444RequestsList(
    page: number,
    size: number,
  ): Promise<IList<TGenerateCnab444Response>> {
    const res = await this.get(
      `${this.BASE_URL}/funding/applications/cnab444/requests?page=${page}&size=${size}`,
    );
    return res.data;
  }

  async getCnab444(id: string): Promise<TGenerateCnab444Response> {
    const res = await this.get(
      `${this.BASE_URL}/funding/applications/cnab444/requests/${id}`,
    );
    return res.data;
  }

  async getCnab444File(id: string): Promise<string> {
    const res = await this.get(
      `${this.BASE_URL}/funding/applications/cnab444/requests/${id}/file`,
    );
    return res.data;
  }
}
