import styled from '@emotion/styled';

export const ViewBoxContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  border-radius: 10px;
  margin-bottom: 48px;
  width: 100%;
`;

export const ViewBoxContent = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  &:first-of-type {
    border-top: unset;
  }
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      flex-direction: column;
  };
`;

export const ViewBoxItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-left: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  padding: 12px 24px;
  span {
    display: block;
    &:first-of-type {
      min-width: max-content;
    }
    &:nth-of-type(2) {
      max-width: 70%;
      text-align: right;
    }
  }

  &:first-of-type {
    border-left: unset;
  }
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
    max-width: unset !important;
};
`;

export const ApplicationDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SpinnerLoadingArea = styled.div`
  display: flex;
  justify-content: center;
`;
