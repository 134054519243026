import React, { createContext, useContext, useState, useCallback } from 'react';
import { IInstallmentsCartContext } from '../interfaces/cart';
import { InstallmentItem } from '../interfaces/installments';

export const InstallmentsCartContext = createContext<IInstallmentsCartContext>({
  installments: [],
  onAddInstallment: () => {
    throw new Error('Método não implementado');
  },
  onAddInstallments: () => {
    throw new Error('Método não implementado');
  },
  onRemoveInstallment: () => {
    throw new Error('Método não implementado');
  },
  onRemoveInstallments: () => {
    throw new Error('Método não implementado');
  },
  onCleanCart: () => {
    throw new Error('Método não implementado');
  },
});

export interface IInstallmentsCartProviderProps {
  children?: React.ReactNode;
}

export const InstallmentsCartProvider = ({
  children,
}: IInstallmentsCartProviderProps) => {
  const [installments, updateInstallments] = useState<Array<InstallmentItem>>(
    [],
  );

  const onAddInstallment = useCallback(
    (installment: InstallmentItem) => {
      updateInstallments(installments.concat(installment));
    },
    [installments],
  );
  const onAddInstallments = useCallback(
    (installmentList: Array<InstallmentItem>) => {
      updateInstallments(installments.concat(installmentList));
    },
    [installments],
  );
  const onRemoveInstallment = useCallback(
    (installment: InstallmentItem) => {
      updateInstallments(installments.filter((i) => i.id !== installment.id));
    },
    [installments],
  );
  const onRemoveInstallments = useCallback(
    (installmentList: Array<InstallmentItem>) => {
      const installmentListIds = installmentList.map(
        (installment) => installment.id,
      );
      updateInstallments(
        installments.filter((i) => !installmentListIds.includes(i.id)),
      );
    },
    [installments],
  );
  const onCleanCart = useCallback(() => {
    updateInstallments([]);
  }, []);

  return (
    <InstallmentsCartContext.Provider
      value={{
        installments,
        onAddInstallment,
        onAddInstallments,
        onRemoveInstallment,
        onRemoveInstallments,
        onCleanCart,
      }}
    >
      {children}
    </InstallmentsCartContext.Provider>
  );
};

export const useInstallmentsCart = () => {
  const context = useContext(InstallmentsCartContext);

  if (context === undefined) {
    throw new Error(
      'useInstallmentsCart must be used within a InstallmentsCartProvider',
    );
  }
  return context;
};
