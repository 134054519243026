import styled from '@emotion/styled';

export interface ITextSDefaultWrapperProps {
  weight?: 'Regular' | 'Bold' | 'Semibold' | 'Medium';
}

export const TextSDefaultWrapper = styled.p<ITextSDefaultWrapperProps>`
  font-family: 'Inter';
  font-size: 14px;
  color: ${({ theme }) => theme.palette?.brand.secondary.base};
`;
