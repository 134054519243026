import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  padding: 0px 48px 80px;

  
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      padding: 0px 10px;
  };
`;

export const TableContainer = styled.div`
  margin-top: 40px;
`;

export const DocumentArea = styled.div`
  margin-top: 52px;
  display: flex;
  gap: 16px;
  text-align: center;
`;

export const SpinnerArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;
