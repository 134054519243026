import { useBreadcrumbsContext } from 'app/context';
import { useEffect } from 'react';
import { Cnab444PageContent } from './components';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { FolderCopyOutlined } from '@mui/icons-material';

const Cnab444Page = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const { userInfo } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo?.permissions?.includes(EAccountPermissions.CNAB)) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setItems(
      [
        { to: '/cession', label: 'Cessão' },
        { to: '/cession/cnab444', label: 'CNAB 444' },
      ],
      <FolderCopyOutlined />,
    );

    return () => resetBreadcrumbs();
  }, []);

  if (!userInfo?.permissions?.includes(EAccountPermissions.CNAB)) return null;

  return <Cnab444PageContent />;
};

export default Cnab444Page;
