import { useTheme } from '@mui/material';
import { ButtonPrimary, Heading3, TextM } from 'app/components';
import { ArrowIcon, HyperlinkIcon } from 'app/components/Icons';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { TProducts } from 'modules/products/context';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ApplicationDetailsHeader,
  ViewBoxContainer,
  ViewBoxContent,
  ViewBoxItem,
} from '../../styles';

export interface IConditionContent {
  product?: TProducts;
}

const buttonStyle = {
  padding: '11px 15px',
  width: 'fit-content',
  gap: '6px',
};

const ConditionContent = ({ product }: IConditionContent) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { showSnackbarCopyLink } = useSnackbar();

  const handleClipboard = useCallback(() => {
    navigator.clipboard.writeText(
      `${document.location.origin}/#/products/detail/${product?.id}/applications`,
    );
    showSnackbarCopyLink();
  }, []);

  const handleNavigate = () => {
    navigate(`/products/detail/${product?.id}/applications`);
  };

  return (
    <>
      <ApplicationDetailsHeader>
        <Heading3>Produto</Heading3>
        <div style={{ display: 'flex', gap: '6px' }}>
          <ButtonPrimary
            typeVariant="outline"
            style={buttonStyle}
            onClick={handleClipboard}
          >
            <HyperlinkIcon color={theme.palette.brand.primary.base as string} />
          </ButtonPrimary>
          <ButtonPrimary
            typeVariant="outline"
            style={buttonStyle}
            onClick={handleNavigate}
          >
            <TextM
              weight="Semibold"
              style={{
                color: theme.palette.brand.primary.base,
                marginRight: '8px',
              }}
            >
              Ver página
            </TextM>
            <ArrowIcon color={theme.palette.brand.primary.base as string} />
          </ButtonPrimary>
        </div>
      </ApplicationDetailsHeader>
      <ViewBoxContainer>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Nome</TextM>
            </span>
            <span>
              <TextM>{product?.name}</TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Descrição</TextM>
            </span>
            <span>
              <TextM>{product?.description}</TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
      </ViewBoxContainer>
    </>
  );
};

export default ConditionContent;
