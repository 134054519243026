const ContractIcon = ({ color, ...props }: { color: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={color}
        d="M25.334 29.333H6.667a4 4 0 0 1-4-4V4A1.333 1.333 0 0 1 4 2.667h18.667A1.333 1.333 0 0 1 24 4v16h5.334v5.333a4 4 0 0 1-4 4ZM24 22.667v2.666a1.334 1.334 0 0 0 2.667 0v-2.666H24Zm-2.666 4V5.332h-16v20a1.333 1.333 0 0 0 1.333 1.334h14.667ZM8 9.332h10.667V12H8V9.333Zm0 5.333h10.667v2.667H8v-2.666ZM8 20h6.667v2.666H8V20Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default ContractIcon;
