import { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { ButtonPrimary, CloseIcon, TextL } from 'app/components';
import {
  ButtonArea,
  ModalHeader,
  ModalSection,
  SectionContent,
  Cnab444GenerateModalContainer,
} from './styles';
import { useTheme } from '@mui/material';
import * as yup from 'yup';
import { BaseModal, CalendarInput, CustomInput } from 'celcoin-design-system';
import { TGenerateCnab444RequestParameters } from 'modules/cession/interfaces/cnab444';
import { formatDateForBackendString } from 'app/helpers/dateHelpers';
import { useAuthContext } from 'modules/auth/context';
import { isJSON } from 'app/helpers';

const CNAB_444_GENERATE_MODAL_INITIAL_VALUES =
  'CNAB_444_GENERATE_MODAL_INITIAL_VALUES';

const cnab444GenerateModalValidation = yup.object({
  cessionDate: yup.string().required('Campo obrigatório'),
});

export interface ICnab444GenerateModal {
  isOpen: boolean;
  handleClose: () => void;
  handleGenerate: (params: TGenerateCnab444RequestParameters) => void;
}

const Cnab444GenerateModal = ({
  isOpen,
  handleClose,
  handleGenerate,
}: ICnab444GenerateModal) => {
  const theme = useTheme();
  const { userInfo } = useAuthContext();

  const initialValues = useMemo<{
    cessionDate: string;
    depositoryAgency?: string;
    collectionAgencyBank?: string;
    cessionTermNumber?: string;
  }>(() => {
    const storageInitialValues = localStorage.getItem(
      CNAB_444_GENERATE_MODAL_INITIAL_VALUES,
    );
    if (storageInitialValues && isJSON(storageInitialValues)) {
      return JSON.parse(storageInitialValues);
    }
    return {
      cessionDate: '',
      depositoryAgency: undefined,
      collectionAgencyBank: undefined,
      cessionTermNumber: undefined,
    };
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: cnab444GenerateModalValidation,
    validateOnMount: true,
    onSubmit: (values) => {
      localStorage.setItem(
        CNAB_444_GENERATE_MODAL_INITIAL_VALUES,
        JSON.stringify(values),
      );
      handleGenerate({
        cession_date: formatDateForBackendString(
          new Date(values.cessionDate),
        ) as string,
        cession_term_number: values.cessionTermNumber,
        collection_agency_bank: values.collectionAgencyBank,
        depository_agency: values.depositoryAgency,
        originator: {
          id: userInfo!.originator_id as string,
        },
      });
    },
  });

  const onChangeCessionDate = useCallback(
    (date: Date | null) => {
      try {
        formik.setFieldValue('cessionDate', date?.toISOString());
      } catch {
        formik.setFieldValue('cessionDate', '');
      }
    },
    [formik],
  );

  const onChangeInputMax = useCallback(
    (max: number) =>
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        formik.setFieldValue(
          event.currentTarget.name,
          event.currentTarget.value.slice(0, max),
        );
      },
    [formik],
  );

  return (
    <BaseModal open={isOpen} onClose={handleClose}>
      <Cnab444GenerateModalContainer>
        <ModalHeader>
          <TextL>Geração de CNAB 444</TextL>
          <div onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </ModalHeader>
        <ModalSection>
          <SectionContent>
            <div style={{ display: 'flex', gap: '16px' }}>
              <CalendarInput
                name="cessionDate"
                labelValue="Data de Cessão"
                placeholder="dd/mm/aaaa"
                value={formik.values.cessionDate ?? null}
                onChange={onChangeCessionDate}
                validationError={
                  formik.touched.cessionDate && formik.errors.cessionDate
                    ? formik.errors.cessionDate
                    : ''
                }
              />
              <CustomInput
                name="depositoryAgency"
                value={formik.values.depositoryAgency ?? ''}
                handleInputChange={onChangeInputMax(5)}
                onBlur={formik.handleBlur}
                labelValue="Agência de Cobrança"
                validationError={''}
              />
            </div>
            <div style={{ display: 'flex', gap: '16px', marginTop: '12px' }}>
              <CustomInput
                name="collectionAgencyBank"
                value={formik.values.collectionAgencyBank ?? ''}
                handleInputChange={onChangeInputMax(3)}
                onBlur={formik.handleBlur}
                labelValue="Banco de Cobrança"
                validationError={''}
              />
              <CustomInput
                name="cessionTermNumber"
                value={formik.values.cessionTermNumber ?? ''}
                handleInputChange={onChangeInputMax(18)}
                onBlur={formik.handleBlur}
                labelValue="Número do Termo de Cessão"
                validationError={''}
              />
            </div>
          </SectionContent>
        </ModalSection>
        <ButtonArea>
          <ButtonPrimary typeVariant="outline" onClick={handleClose}>
            Cancelar
          </ButtonPrimary>
          <ButtonPrimary onClick={formik.submitForm}>Gerar</ButtonPrimary>
        </ButtonArea>
      </Cnab444GenerateModalContainer>
    </BaseModal>
  );
};

export default Cnab444GenerateModal;
