import { useCallback } from 'react';
import { Divider, useTheme } from '@mui/material';
import {
  ButtonPrimary,
  CloseIcon,
  Heading3,
  PencilIcon,
  TextM,
} from 'app/components';
import { ArrowIcon, HyperlinkIcon } from 'app/components/Icons';
import { usePermify } from 'modules/auth/context';
import { useNavigate } from 'react-router-dom';
import {
  CloseIconWrapper,
  DetailContainer,
  HeaderArea,
  ViewBoxContainer,
  ViewBoxContent,
  ViewBoxItem,
} from './styles';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { TBusinessRelationsData } from '../../LegalPersonRepresentatives';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { formatCPF, formatPhone } from 'app/helpers';
import { translatedWorkRelation } from 'modules/customer/helpers';
import { BaseSidesheet } from 'celcoin-design-system';
import { useSnackbar } from 'app/hooks/useSnackbar';

export interface IDetailModalProps {
  isOpen: boolean;
  handleClose: () => void;
  editUser: (personData: TBusinessRelationsData | undefined) => void;
  personData: TBusinessRelationsData | undefined;
}

const buttonStyle = {
  padding: '11px 15px',
  width: 'fit-content',
  gap: '6px',
};

const DetailModal = ({
  handleClose,
  isOpen,
  personData,
  editUser,
}: IDetailModalProps) => {
  const theme = useTheme();
  const { isProfileAuthorized } = usePermify();
  const navigate = useNavigate();
  const { showSnackbarCopyLink } = useSnackbar();

  const handleNavigate = () => {
    navigate(`/records/natural/${personData?.person?.id}/details`);
  };

  const handleClipboard = useCallback(() => {
    navigator.clipboard.writeText(
      `${document.location.origin}/#/records/natural/${personData?.person?.id}/details`,
    );
    showSnackbarCopyLink();
  }, [personData]);

  return (
    <BaseSidesheet handleClose={handleClose} open={isOpen}>
      <HeaderArea>
        <Heading3 fontWeight="Sb">
          {personData?.person?.full_name || '-'}
        </Heading3>
        <CloseIconWrapper onClick={handleClose}>
          <CloseIcon color={theme.palette.brand.secondary.base as string} />
        </CloseIconWrapper>
      </HeaderArea>
      <Divider />
      <DetailContainer>
        <div style={{ display: 'flex', gap: '40px', marginTop: '43px' }}>
          <Heading3>Representante Legal</Heading3>
          {isProfileAuthorized(EAccountPermissions.UPDATE_PERSON_BUSINESS) && (
            <ButtonPrimary
              typeVariant="outline"
              style={buttonStyle}
              onClick={() => editUser(personData)}
            >
              <PencilIcon color={theme.palette.brand.primary.base as string} />
              <TextM
                weight="Semibold"
                style={{
                  color: theme.palette.brand.primary.base,
                  marginRight: '8px',
                }}
              >
                Editar representante
              </TextM>
            </ButtonPrimary>
          )}
        </div>
        <ViewBoxContainer>
          <ViewBoxContent>
            <ViewBoxItem>
              <span>
                <TextM weight="Semibold">Assina pela empresa</TextM>
              </span>
              <span>
                <TextM>{personData?.signer ? 'Sim' : 'Não' || '-'}</TextM>
              </span>
            </ViewBoxItem>
          </ViewBoxContent>
          <ViewBoxContent>
            <ViewBoxItem>
              <span>
                <TextM weight="Semibold">Vínculo trabalhista</TextM>
              </span>
              <span>
                <TextM>
                  {translatedWorkRelation[personData?.type || 'OTHER'] || '-'}
                </TextM>
              </span>
            </ViewBoxItem>
          </ViewBoxContent>
          <ViewBoxContent>
            <ViewBoxItem>
              <span>
                <TextM weight="Semibold">Particiapação em quotas (%)</TextM>
              </span>
              <span>
                <TextM>
                  {typeof personData?.person.share === 'number'
                    ? `${personData?.person.share * 100}%`
                    : '-'}
                </TextM>
              </span>
            </ViewBoxItem>
          </ViewBoxContent>
        </ViewBoxContainer>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Heading3>Cadastro</Heading3>
          <div style={{ display: 'flex', gap: '6px' }}>
            <ButtonPrimary
              typeVariant="outline"
              style={buttonStyle}
              onClick={handleClipboard}
            >
              <HyperlinkIcon
                color={theme.palette.brand.primary.base as string}
              />
            </ButtonPrimary>

            <ButtonPrimary
              typeVariant="outline"
              style={buttonStyle}
              onClick={handleNavigate}
            >
              <TextM
                weight="Semibold"
                style={{
                  color: theme.palette.brand.primary.base,
                  marginRight: '8px',
                }}
              >
                Ver página
              </TextM>
              <ArrowIcon color={theme.palette.brand.primary.base as string} />
            </ButtonPrimary>
          </div>
        </div>
        <ViewBoxContainer>
          <ViewBoxContent>
            <ViewBoxItem>
              <span>
                <TextM weight="Semibold">Nome completo</TextM>
              </span>
              <span>
                <TextM>{personData?.person?.full_name || '-'}</TextM>
              </span>
            </ViewBoxItem>
          </ViewBoxContent>
          <ViewBoxContent>
            <ViewBoxItem>
              <span>
                <TextM weight="Semibold">CPF</TextM>
              </span>
              <span>
                <TextM>
                  {formatCPF(personData?.person?.taxpayer_id || '') || '-'}
                </TextM>
              </span>
            </ViewBoxItem>
          </ViewBoxContent>
          <ViewBoxContent>
            <ViewBoxItem>
              <span>
                <TextM weight="Semibold">Nascimento</TextM>
              </span>
              <span>
                <TextM>
                  {formatDateFromBackend(
                    personData?.person?.birth_date || '-',
                  ).toLocaleDateString('pt-br') || '-'}
                </TextM>
              </span>
            </ViewBoxItem>
          </ViewBoxContent>
          <ViewBoxContent>
            <ViewBoxItem>
              <span>
                <TextM weight="Semibold">E-mail</TextM>
              </span>
              <span>
                <TextM>{personData?.person?.email}</TextM>
              </span>
            </ViewBoxItem>
          </ViewBoxContent>
          <ViewBoxContent>
            <ViewBoxItem>
              <span>
                <TextM weight="Semibold">Celular</TextM>
              </span>
              <span>
                <TextM>
                  {formatPhone(
                    `${personData?.person.phone?.area_code}${personData?.person.phone?.number}`,
                  ) || '-'}
                </TextM>
              </span>
            </ViewBoxItem>
          </ViewBoxContent>
          <ViewBoxContent>
            <ViewBoxItem>
              <span>
                <TextM weight="Semibold">Cadastrado em</TextM>
              </span>
              <span>
                <TextM>
                  {formatDateFromBackend(
                    personData?.person?.created_at || '-',
                  ).toLocaleDateString('pt-br') || '-'}
                </TextM>
              </span>
            </ViewBoxItem>
          </ViewBoxContent>
        </ViewBoxContainer>
      </DetailContainer>
    </BaseSidesheet>
  );
};

export default DetailModal;
