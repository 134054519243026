import React from 'react';
import { LinkProps } from 'react-router-dom';
import { FormUnderlineContainer } from './styles';

export type IFormUnderlineProps = LinkProps;

const FormUnderline = ({ children, to, ...props }: IFormUnderlineProps) => {
  return (
    <FormUnderlineContainer to={`/${to}`} {...props}>
      {children}
    </FormUnderlineContainer>
  );
};

export default FormUnderline;
