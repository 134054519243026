import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { ButtonDefault, ButtonPrimary } from '../../../../../app/components';
import { StepContent } from '../../../components';
import {
  BillingAddressCheckboxContainer,
  StepAddressContentInputArea,
  StepContentButtonsArea,
  StepContentInput,
  StepContentInputGroup,
} from './styles';
import { ArrowBack } from '@mui/icons-material';
import { LegalPersonHandlerStepperContext } from '../LegalPersonHandler';
import { useCustomerContext } from '../../../context';
import { useCustomer } from '../../../hooks';
import { BRAZILIAN_STATES, formatCep } from '../../../../../app/helpers';
import { addressStepSchema } from '../../../helpers/businessValidation';
import { CustomSelect, CustomInput, TextM } from 'celcoin-design-system';
import { useAddress } from 'app/hooks';
import Checkbox from '@mui/material/Checkbox';

const AddressStep = () => {
  const {
    onBack,
    onForward,
    utilizeCurrentAddressAsBilling,
    updateUtilizeCurrentAddressAsBilling,
  } = useContext(LegalPersonHandlerStepperContext);
  const [citiesList, setCitiesList] = useState<{ value: string }[]>([]);
  const { fetchCities, fetchAddressByPostalCode, isFetchingPostalCode } =
    useAddress();
  const [stateCode, setStateCode] = useState('');
  const [cityManually, setCityManually] = useState(true);

  const {
    state: { registerData },
  } = useCustomerContext();
  const { setBusinessData } = useCustomer();
  const formik = useFormik({
    initialValues: {
      country_code: registerData.address.country_code || '',
      postal_code: registerData.address.postal_code || '',
      noNumber: false,
      district: registerData.address.district || '',
      street_name: registerData.address.street_name || '',
      street_number: registerData.address.street_number || '',
      extra_info: registerData.address.extra_info || '',
      state_code: registerData.address.state_code || '',
      city: registerData.address.city || '',
      cityManually: registerData.address.city.value,
      references: '',
    },
    validationSchema: addressStepSchema,
    onSubmit: async (values) => {
      setBusinessData({
        address: {
          ...values,
          ...(citiesList?.length <= 0 && {
            city: { value: values.cityManually, label: values.cityManually },
          }),
          country_code: {
            label: 'Brasil',
            value: 'BRA',
          },
        },
        ...(utilizeCurrentAddressAsBilling && {
          billing_address: undefined,
        }),
      });
      onForward();
    },
  });

  const parseCities = (cities: { name: string; id: string }[]) => {
    return cities.map((item) => {
      return {
        value: item.name,
        label: item.name,
      };
    });
  };

  const handleCities = async (stateCodeFound?: string) => {
    setCityManually(false);
    try {
      const result = await fetchCities(stateCodeFound ?? stateCode);
      if (!result || result.data.status !== '200') {
        setCityManually(true);
        return [];
      }

      const parsedResult = parseCities(result.data?.body?.city);
      setCitiesList(parsedResult || []);
      return parsedResult;
    } catch (error) {
      setCityManually(true);
    }
  };

  const handleZipode = async (value: string) => {
    if (value.length < 9) return;
    const res = await fetchAddressByPostalCode(value);
    if (res && res.data.status === '200') {
      const values = res.data.body.address;
      const stateCodeFound = BRAZILIAN_STATES.find(
        (state) =>
          state.value.toLocaleLowerCase() === values.state.toLocaleLowerCase(),
      )?.value;
      formik.setFieldValue('district', values.neighborhood);
      formik.setFieldValue('street_name', values.street);
      formik.setFieldValue(
        'state_code',
        BRAZILIAN_STATES.find(
          (state) =>
            state.value.toLocaleLowerCase() ===
            values.state.toLocaleLowerCase(),
        ),
      );

      const resCities = await handleCities(stateCodeFound || '');
      if (resCities && resCities.length > 0) {
        const foundCity = resCities.find(
          (item: { value: string; label: string }) =>
            item.value.toLowerCase() === values.city.toLowerCase(),
        );
        formik.setFieldValue('city', foundCity);
        return;
      }
    }
    setCityManually(true);
  };

  const handleNumber = (numberValue: string) => {
    formik.setFieldValue(
      'street_number',
      numberValue.replace(/(-(?!\d))|[^0-9|-]/g, '') || '',
    );
  };

  useEffect(() => {
    if (stateCode) {
      handleCities();
    }
  }, [stateCode]);

  return (
    <>
      <StepContent
        title="Endereço"
        description="Cadastre seu endereço comercial principal"
      >
        <StepAddressContentInputArea>
          <StepContentInput>
            <CustomInput
              name="postal_code"
              value={formatCep(formik.values.postal_code)}
              isLoading={isFetchingPostalCode}
              placeholder="Ex.: 00000-000"
              handleInputChange={formik.handleChange}
              onBlur={(evt) => {
                formik.handleBlur(evt);
                if (formik.values.postal_code)
                  handleZipode(formik.values.postal_code);
              }}
              labelValue="CEP"
              validationError={
                formik.touched.postal_code ? formik.errors.postal_code : ''
              }
            />
          </StepContentInput>

          <StepContentInputGroup>
            <StepContentInput>
              <CustomInput
                name="street_name"
                value={formik.values.street_name}
                handleInputChange={formik.handleChange}
                placeholder="Ex.: Av Presidente Vargas"
                labelValue="Rua/Avenida"
                validationError={
                  formik.touched.street_name ? formik.errors.street_name : ''
                }
              />
            </StepContentInput>
            <StepContentInput>
              <CustomInput
                name="street_number"
                value={formik.values.street_number}
                handleInputChange={(e) => handleNumber(e.target.value)}
                placeholder="Ex.: 300"
                labelValue="Número"
                validationError={
                  formik.touched.street_number
                    ? formik.errors.street_number
                    : ''
                }
              />
            </StepContentInput>
          </StepContentInputGroup>
          <StepContentInput>
            <CustomInput
              name="extra_info"
              value={formik.values.extra_info}
              handleInputChange={formik.handleChange}
              placeholder="Ex.: Próximo a escola..."
              labelValue="Complemento (opcional)"
              validationError={
                formik.touched.extra_info ? formik.errors.extra_info : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="district"
              value={formik.values.district}
              handleInputChange={formik.handleChange}
              placeholder="Ex.: Planalto do Sol"
              labelValue="Bairro"
              validationError={
                formik.touched.district ? formik.errors.district : ''
              }
            />
          </StepContentInput>
          <StepContentInputGroup>
            <StepContentInput marginTop="15px">
              <CustomSelect
                labelValue="Estado"
                name="state_code"
                placeholder="Selecione o estado"
                value={
                  formik.values.state_code?.value === ''
                    ? undefined
                    : formik.values.state_code
                }
                handleInputChange={(v) => {
                  formik.handleChange(v);
                  setStateCode(v.currentTarget.value.value);
                }}
                options={BRAZILIAN_STATES}
                validationError={
                  formik.touched.state_code?.value
                    ? formik.errors.state_code?.value
                    : ''
                }
              />
            </StepContentInput>
            <StepContentInput marginTop="15px">
              {cityManually ? (
                <CustomInput
                  name="cityManually"
                  value={formik.values.cityManually}
                  handleInputChange={(evt) => {
                    formik.setFieldValue('cityManually', evt.target.value);
                    formik.setFieldValue('city', {
                      label: evt.target.value,
                      value: evt.target.value,
                    });
                  }}
                  placeholder="Cidade"
                  labelValue="Cidade"
                  validationError={
                    formik.touched.city?.value ? formik.errors.city?.value : ''
                  }
                />
              ) : (
                <CustomSelect
                  labelValue="Cidade"
                  name="city"
                  placeholder="Selecione a cidade"
                  value={
                    formik.values.city?.value === ''
                      ? undefined
                      : formik.values.city
                  }
                  handleInputChange={formik.handleChange}
                  options={citiesList}
                  validationError={
                    formik.touched.city?.value ? formik.errors.city?.value : ''
                  }
                />
              )}
            </StepContentInput>
          </StepContentInputGroup>
          <StepContentInput isFull={true}>
            <CustomInput
              name="references"
              value={formik.values.references}
              handleInputChange={formik.handleChange}
              placeholder="Digite aqui outras informações"
              labelValue="Referências / Informações adicionais (opcional)"
              validationError={
                formik.touched.references ? formik.errors.references : ''
              }
            />
          </StepContentInput>
        </StepAddressContentInputArea>

        <BillingAddressCheckboxContainer>
          <Checkbox
            checked={utilizeCurrentAddressAsBilling}
            onChange={(value) =>
              updateUtilizeCurrentAddressAsBilling(value.target.checked)
            }
            name="billingAddress"
            color="primary"
          />
          <TextM weight="Regular">
            Usar o endereço principal como endereço de cobrança
          </TextM>
        </BillingAddressCheckboxContainer>

        <StepContentButtonsArea>
          <ButtonDefault margin={true} onClick={() => onBack()}>
            <ArrowBack />
          </ButtonDefault>

          <ButtonPrimary onClick={formik.submitForm}>Avançar</ButtonPrimary>
        </StepContentButtonsArea>
      </StepContent>
    </>
  );
};

export default AddressStep;
