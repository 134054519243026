import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Item } from './types';
import { ListItem, ListItemIcon, useTheme } from '@mui/material';
import { Close, DragIndicator } from '@mui/icons-material';
import { DraggableListItemContainer } from './styles';
import { ButtonPrimary } from '../ButtonPrimary';

interface IDraggableListItemProps {
  item: Item;
  onRemoveItem: () => void;
}

export function DraggableListItem({
  item,
  onRemoveItem,
}: IDraggableListItemProps) {
  const theme = useTheme();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: theme.palette?.brand?.gray[100],
    borderTop: '3px solid white',
    borderBottom: '3px solid white',
    cursor: 'grab',
    paddingLeft: '5px',
    '&:hover': {
      backgroundColor: theme.palette?.brand?.gray[200],
    },
  };

  return (
    <DraggableListItemContainer>
      <ListItem ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <ListItemIcon
          sx={{
            padding: '0px',
          }}
        >
          <DragIndicator />
        </ListItemIcon>
        {item.name}
      </ListItem>
      <ButtonPrimary
        style={{
          width: 'auto',
          fontSize: '8px',
          color: theme.palette?.brand?.gray[600],
          padding: '0px 0px 0px 5px',
        }}
        typeVariant="ghost"
        onClick={onRemoveItem}
      >
        <Close />
      </ButtonPrimary>
    </DraggableListItemContainer>
  );
}
