import styled from '@emotion/styled';

export const HeaderArea = styled.div`
  width: 100%;
  display: flex;
  gap: 193px;
  padding: 32px 42px;
  align-items: center;
  justify-content: space-between;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
`;

export const ContentArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 32px 42px;
  flex-direction: column;
`;

export const Section = styled.div`
  background: #FFFFFF;
  border-radius: 20px;
  padding: 24px;
  border: 1px solid #E6E5EA;
  flex-direction: column;
  margin-top: 30px;
`;

export const SelectedAddInfo = styled.div`
    .switch-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0px;
        padding-top: 0px;

      .switch-item {
        display: flex;
        gap: 8px;
      }
    }

    .relationship-area {
        padding: 20px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;


        .relationship-area-item {
            display: flex;
            gap: 8px;

            input {
                margin-right: 12px;
            }
        }
    }

    .btn-remove {
      padding: 40px 0px;
      padding-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
`;

export const ButtonArea = styled.div`
    display: flex;
    height: 100%;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 24px;
    gap: 16px;

    .cancel-btn {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
