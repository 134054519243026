import {
  IBusinessCustomVariables,
  IBusinessCustomVariablesValues,
  IPersonCustomVariables,
  IPersonCustomVariablesValues,
} from 'app/interfaces/customVariablesInterfaces';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import { EFunctions } from 'modules/customer/context/CustomerProvider/customer.interfaces';

export type TQualificationBorrower = TRegisterPersonDataRequest &
  TRegisterBusinessDataRequest;

export type TCustomVariable = IPersonCustomVariables & IBusinessCustomVariables;
export type TCustomVariableValue = IPersonCustomVariablesValues &
  IBusinessCustomVariablesValues;

export enum EQualificationError {
  ON_CREATING_QUALIFICATION,
  ON_CREATING_CONDITIONS,
  ON_QUALIFYING,
}

export type TBorrowerType = { label: string; value: EFunctions };
