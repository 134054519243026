import React, { HTMLAttributes } from 'react';
import { RegularTextWrapper } from './styles';

export interface IRegularTextProps
  extends HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode;
}

const RegularText = ({ children, ...props }: IRegularTextProps) => {
  return <RegularTextWrapper {...props}>{children}</RegularTextWrapper>;
};

export default RegularText;
