import styled from '@emotion/styled';

export const FilterMovementsDropdownContainer = styled.div`
  padding: 20px;
`;

export const FilterMovementsDropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 200px;
`;

export const FilterMovementsDropdownBody = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FilterMovementsButtons = styled.div`
  display: flex;
`;

export const FilterMovementsInputs = styled.div`
  display: flex;
`;

export const FilterMovementsInput = styled.div`
  max-width: 190px;
  &:first-child {
    margin-right: 10px;
  }
`;

export const FilterMovementsInputInvalid = styled.div`
  margin-top: 6px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette?.brand.error[900]};
`;

export const StatusElement = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px;
  align-items: center;
`;

export const CloseBtn = styled.div`
  cursor: pointer;
`;
