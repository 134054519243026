import { differenceInDays } from 'date-fns';
import { TacValueType } from 'modules/products/pages/ApplicationRequest/ApplicationRequest';
import * as yup from 'yup';

export const creditSimulatorValidation = (
  isMultiplesDisbursementDates?: boolean,
) =>
  yup.object({
    amount: yup
      .string()
      .required('Campo obrigatório')
      .test({
        name: 'amount field',
        test: (value: unknown, { createError }) => {
          return value === '0,00'
            ? createError({
                message: 'Insira um valor válido para simular',
                path: 'amount',
              })
            : true;
        },
      }),
    numPayments: yup.string().required('Campo obrigatório'),
    tacAmount: yup.string().required('Campo obrigatório'),
    tacRate: yup.string().test({
      name: 'tacRate field',
      test: (value: unknown, { createError, parent }) => {
        if (parent.tacType.value !== TacValueType.PERCENTAGE) return true;
        return value === '0'
          ? createError({
              message: 'Insira um valor maior que 0',
            })
          : !value
          ? createError({
              message: 'Campo obrigatório',
            })
          : true;
      },
    }),
    interestRate: yup
      .string()
      .required('Campo obrigatório')
      .test({
        name: 'interestRate field',
        test: (value: unknown, { createError }) => {
          return value === '0'
            ? createError({
                message: 'Insira um valor maior que 0',
                path: 'interestRate',
              })
            : value === '0,'
            ? createError({
                message: 'Insira um valor maior que 0',
                path: 'interestRate',
              })
            : value === '0,0'
            ? createError({
                message: 'Insira um valor maior que 0',
                path: 'interestRate',
              })
            : Number((value as string)?.replace(',', '.')) === 0
            ? createError({
                message: 'Insira um valor maior que 0',
                path: 'interestRate',
              })
            : true;
        },
      }),
    financeFee: yup.string().required('Campo obrigatório'),
    iofType: yup.object({
      value: yup.string(),
      label: yup.string(),
    }),
    scheduleType: yup.object({
      value: yup.string(),
      label: yup.string(),
    }),
    firstPaymentDate: yup
      .string()
      .nullable()
      .required('Campo obrigatório')
      .test({
        name: 'firstPaymentDate field',
        test: (value: unknown, { createError, parent }) => {
          return new Date(value as string) < new Date(parent.disbursementDate)
            ? createError({
                message: 'Valor deve ser posterior à data de liberação',
                path: 'firstPaymentDate',
              })
            : !parent.disbursementDate
            ? createError({
                message: 'Preencha a data de liberação do recurso',
                path: 'firstPaymentDate',
              })
            : value === parent.disbursementDate
            ? createError({
                message:
                  'Valor deve ser superior à data de liberação do recurso',
                path: 'firstPaymentDate',
              })
            : true;
        },
      }),
    disbursementDate: yup
      .string()
      .nullable()
      .required('Campo obrigatório')
      .test({
        name: 'disbursementDate field',
        test: (value: unknown, { createError }) => {
          if (
            !isMultiplesDisbursementDates &&
            new Date(value as string) <
              new Date(new Date().setHours(0, 0, 0, 0))
          ) {
            return createError({
              message: 'Valor deve ser superior à data atual',
              path: 'disbursementDate',
            });
          }

          if (
            isMultiplesDisbursementDates &&
            new Date(new Date(value as string).setHours(0, 0, 0, 0)) <=
              new Date(new Date().setHours(0, 0, 0, 0))
          ) {
            return createError({
              message: 'Valor deve ser superior à data atual',
              path: 'disbursementDate',
            });
          }

          if (
            isMultiplesDisbursementDates &&
            differenceInDays(
              new Date(value as string),
              new Date(new Date().setHours(0, 0, 0, 0)),
            ) > 15
          ) {
            return createError({
              message:
                'A data de liberação do recurso não pode ser superior a 15 dias',
              path: 'disbursementDate',
            });
          }

          return true;
        },
      }),
  });
