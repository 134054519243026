import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 40px;
  position: relative;
`;

export const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 71px;

  .title {
    display: flex;

    h3 {
      margin-right: 16px;
    }
  }
`;

export const ButtonContainer = styled.div`
  border-radius: 10px;
`;

export const TableArea = styled.div`
  width: 100%;
  margin-bottom: 48px;
`;

export const ArrowArrowContainer = styled.div`
  cursor: pointer;
`;

export const TableWrapper = styled.div`

  th:nth-of-type(7) {
    border-left: 1px solid #E6E5EA;
    max-width: 50px;
  }

  td:nth-of-type(7) {
    border-left: 1px solid #E6E5EA;
    max-width: 50px;
  }
`;
