import { TProducts } from '../../products/context';
import {
  personActionType,
  TRegisterPersonData,
  usePersonContext,
} from '../context';
import { EPersonFunctions } from '../context/PersonProvider/person.interfaces';

const usePerson = () => {
  const { dispatch } = usePersonContext();
  const setPersonData = (data: Partial<TRegisterPersonData>) => {
    dispatch({
      type: personActionType.SET_REGISTER_PERSON_DATA,
      payload: data,
    });
  };
  const setProductData = (data: Partial<TProducts>) => {
    dispatch({
      type: personActionType.SET_PRODUCT_DATA,
      payload: data,
    });
  };
  const setFunctionType = (data: {
    label: string;
    value: EPersonFunctions;
  }) => {
    dispatch({
      type: personActionType.SET_FUNCTION_TYPE,
      payload: data,
    });
  };
  return { setPersonData, setProductData, setFunctionType };
};

export default usePerson;
