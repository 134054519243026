export enum EFilterItemType {
  DATE = 'DATE',
  DATE_RANGE = 'DATE_RANGE',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  TEXT = 'TEXT',
}

export type TFilterValues = {
  [key: string]: null | string | string[] | TFilterDateRange | undefined;
};

export type TFilterItem = {
  name: string;
  label: string;
  placeholder: string;
  isFull?: boolean;
  type: EFilterItemType;
  options?: Array<{ label: string; value: string }>;
};

export type TFilterDateRange = {
  dateFrom: string;
  dateTo: string;
};

export type TFilterResult = {
  [key: string]:
    | {
        filter: TFilterItem;
        value?: null | string | string[] | TFilterDateRange;
        dateFrom?: string;
        dateTo?: string;
      }
    | undefined;
};
