import React from 'react';
import { ButtonArea } from '../styles';
import { SearchFilterInputContainer } from './styles';
import { SearchCloseIcon } from 'app/components/Icons';
import { useTheme } from '@mui/material';

export interface ISearchFilterInputProps {
  value: string;
  searchItemName: string;
  placeholder?: string;
  onPressEnter?: () => void;
  handleInputChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearInput: () => void;
}

const SearchFilterInput = ({
  value,
  searchItemName,
  placeholder,
  onPressEnter,
  handleClearInput,
  handleInputChange,
}: ISearchFilterInputProps) => {
  const theme = useTheme();
  return (
    <SearchFilterInputContainer>
      <input
        onChange={handleInputChange}
        value={value}
        onKeyDown={(e) =>
          e.key === 'Enter' &&
          typeof onPressEnter === 'function' &&
          onPressEnter()
        }
        placeholder={placeholder ?? `Pesquisar por ${searchItemName}`}
      />
      {value && (
        <ButtonArea onClick={handleClearInput}>
          <SearchCloseIcon color={theme.palette.brand.primary.base as string} />
        </ButtonArea>
      )}
    </SearchFilterInputContainer>
  );
};

export default SearchFilterInput;
