import { useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { CloseIcon, Heading4, TextL } from 'app/components';
import {
  ModalBody,
  ModalHeader,
  WithdrawMessageModalContainer,
} from './styles';
import { BaseModal, Heading3 } from 'celcoin-design-system';

export interface IWithdrawMessageModal {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  icon: ReactNode;
  children: ReactNode;
}

const WithdrawMessageModal = ({
  isOpen,
  handleClose,
  title,
  icon,
  children,
}: IWithdrawMessageModal) => {
  const theme = useTheme();
  return (
    <BaseModal open={isOpen} onClose={handleClose}>
      <WithdrawMessageModalContainer>
        <ModalHeader>
          <Heading3>Saque</Heading3>
          <div onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </ModalHeader>
        <ModalBody>
          {icon}
          <Heading4
            style={{
              color: theme.palette.brand.secondary.base,
              marginTop: '24px',
              textAlign: 'center',
            }}
            fontWeight="B"
          >
            {title}
          </Heading4>
          <TextL
            style={{
              color: theme.palette.brand.secondary.base,
              textAlign: 'center',
            }}
          >
            {children}
          </TextL>
        </ModalBody>
      </WithdrawMessageModalContainer>
    </BaseModal>
  );
};

export default WithdrawMessageModal;
