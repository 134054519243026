const CloseIcon = ({
  color,
  width,
  height,
}: {
  color: string;
  width?: number;
  height?: number;
}) => (
  <svg
    width={width || 22}
    height={height || 23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2.7 2.2.5 11 9.3 19.8.5 22 2.7l-8.8 8.8 8.8 8.8-2.2 2.2-8.8-8.8-8.8 8.8L0 20.3l8.8-8.8L0 2.7Z"
      fill={color}
    />
  </svg>
);

export default CloseIcon;
