import styled from '@emotion/styled';

export const Heading4Container = styled.h3`
  font-size: 24px;
  color: ${({ theme }) => theme.palette?.brand?.gray[800]};
  font-weight: 700;
  font-family: 'Urbanist';

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
    font-size: clamp(18px, 1.5vw, 24px);
  }
`;
