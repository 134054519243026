import { css } from '@emotion/react';
import styled from '@emotion/styled';

export interface IAccordionContainerProps {
  isOpen?: boolean;
  cleanStyle?: boolean;
  fullHeight?: boolean;
}

export const AccordionContainer = styled.div<IAccordionContainerProps>`
  width: 100%;
  ${({ cleanStyle }) =>
    !cleanStyle &&
    css`
      border: 1px solid #E6E5EA;
      box-shadow: 0px 2px 23px -8px rgba(31, 30, 35, 0.15);
      border-radius: 10px;
      background-color: #FDFDFD;
  `}
  ${({ fullHeight, isOpen }) =>
    fullHeight &&
    isOpen &&
    css`
      height: 100%;
      display: flex;
      flex: 1;
    `}
`;

export interface IAccordionContentProps {
  isOpen?: boolean;
  cleanStyle?: boolean;
}

export const AccordionContent = styled.div<IAccordionContentProps>`
  padding: ${({ cleanStyle }) => (!cleanStyle ? '16px 26px' : '0px')};
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  
  .title-area {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .accordion-icon {
      svg {
        ${({ isOpen }) =>
          isOpen &&
          css`
          transform: rotate(180deg);
        `}
      }
    }

  }

`;
