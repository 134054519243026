import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import { ButtonDefault, ButtonPrimary } from '../../../../../app/components';
import { StepContent } from '../../../components';
import {
  StepContentButtonsArea,
  StepContentInput,
  StepContentInputArea,
} from './styles';
import { LegalPersonHandlerStepperContext } from '../LegalPersonHandler';
import { useProductsService } from '../../../../products/services/hooks';
import { useProductsContext } from '../../../../products';
import { useCustomer } from '../../../hooks';
import { customerActionType, useCustomerContext } from '../../../context';
import { EFunctions } from '../../../context/CustomerProvider/customer.interfaces';
import { CustomSelect } from 'app/components/CustomSelect';
import { ArrowBack } from '@mui/icons-material';

const ProductStep = () => {
  const { hasMultiplesFundings, onForward, onBack } = useContext(
    LegalPersonHandlerStepperContext,
  );
  const { getProducts } = useProductsService();
  const { dispatch } = useCustomerContext();
  const {
    state: { content },
  } = useProductsContext();
  const {
    state: { product, func },
  } = useCustomerContext();
  const { setProductData, setFunctionType } = useCustomer();

  const formik = useFormik({
    initialValues: {
      product: product.name
        ? { label: product.name, value: product.id, product }
        : undefined,
      function: func.label ? func : undefined,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setProductData({ ...values.product?.product });
      setFunctionType(values.function as { label: string; value: EFunctions });
      onForward();
    },
  });

  useEffect(() => {
    dispatch({
      type: customerActionType.RESET_STATE,
    });
  }, []);

  useEffect(() => {
    getProducts();
  }, []);
  return (
    <StepContent
      title="Produto e Função"
      description="Selecione o produto, função e tipo a qual este cadastro será relacionado."
    >
      <StepContentInputArea>
        <StepContentInput>
          <CustomSelect
            labelValue="Produto"
            name="product"
            placeholder="Selecione o produto"
            value={formik.values.product}
            handleInputChange={(v) => {
              formik.handleChange(v);
              formik.setFieldValue('function', undefined);
            }}
            options={content.map((prd) => {
              return {
                label: prd.name,
                value: prd.id,
                product: prd,
              };
            })}
            validationError={
              formik.touched.product ? formik.errors.product : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Função"
            name="function"
            disabled={!formik.values.product}
            placeholder="Selecione a função"
            value={
              formik.values.function as { label: string; value: EFunctions }
            }
            handleInputChange={(v) => {
              formik.handleChange(v);
            }}
            options={
              formik.values.product?.product?.borrower_type === 'BUSINESS'
                ? [{ value: EFunctions.BORROWER, label: 'Tomador' }]
                : [{ value: EFunctions.EMPLOYER, label: 'Empregador' }]
            }
            validationError={
              formik.touched.function ? formik.errors.function : ''
            }
          />
        </StepContentInput>
      </StepContentInputArea>
      <StepContentButtonsArea>
        {hasMultiplesFundings && (
          <ButtonDefault margin={true} onClick={() => onBack()}>
            <ArrowBack />
          </ButtonDefault>
        )}
        <ButtonPrimary onClick={() => formik.submitForm()}>
          Avançar
        </ButtonPrimary>
      </StepContentButtonsArea>
    </StepContent>
  );
};

export default ProductStep;
