import { useCallback, useState } from 'react';
import {
  UserPermissionsConfigs,
  UserPermissionsLoading,
  UserPermissionsPickerButtonsArea,
  UserPermissionsPickerItem,
  UserPermissionsPickerWrapper,
} from './styles';
import {
  UserAccess,
  UserPermissionsDescription,
  UserValidPermissions,
} from 'modules/management/interfaces/usersAccess';
import {
  CustomSwitchIOS,
  Heading4,
  Spinner,
  TextM,
} from 'celcoin-design-system';
import { ButtonDefault, ButtonPrimary } from 'app/components';
import { ArrowBack } from '@mui/icons-material';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { SettingsIcon } from 'app/components/Icons';
import { useTheme } from '@mui/material';
import { ApprovePermissionConfig } from '../ApprovePermissionConfig';

interface IUserPermissionsPickerProps {
  userAccess: UserAccess;
  confirmButtonText: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  updateUserAccess: (newUserAccess: UserAccess) => void;
  onBack?: () => void;
  onConfirm?: () => void;
}

const UserPermissionsPicker = ({
  userAccess,
  confirmButtonText,
  isLoading,
  isDisabled,
  updateUserAccess,
  onBack,
  onConfirm,
}: IUserPermissionsPickerProps) => {
  const theme = useTheme();
  const [isApprovePermissionConfigOpen, setIsApprovePermissionConfigOpen] =
    useState(false);
  const permissionsFiltered = userAccess?.permissions?.filter((permission) =>
    UserValidPermissions.includes(permission),
  );

  const onChangeSwitch = useCallback(
    (permission: EAccountPermissions) => {
      const { permissions } = userAccess;
      if (permissions?.includes(permission)) {
        updateUserAccess({
          ...userAccess,
          permissions: permissions.filter(
            (userPermission) => userPermission !== permission,
          ),
        });
        return;
      }
      if (permission === EAccountPermissions.APPROVE_APPLICATION) {
        setIsApprovePermissionConfigOpen(true);
        return;
      }
      if (permission === EAccountPermissions.CANCEL_APPLICATION) {
        updateUserAccess({
          ...userAccess,
          permissions: ([] as EAccountPermissions[]).concat(
            permissions ?? [],
            permission,
            EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION,
          ),
        });
        return;
      }
      updateUserAccess({
        ...userAccess,
        permissions: ([] as EAccountPermissions[]).concat(
          permissions ?? [],
          permission,
        ),
      });
      return;
    },
    [userAccess, updateUserAccess],
  );

  return (
    <>
      <UserPermissionsPickerWrapper>
        {Object.keys(UserPermissionsDescription).map((userPermission) => {
          const permission =
            UserPermissionsDescription[
              userPermission as keyof typeof UserPermissionsDescription
            ];
          const disabled =
            permission.isDisabled ||
            isDisabled ||
            (userPermission ===
              EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION &&
              (userAccess?.permissions?.includes(
                EAccountPermissions.APPROVE_APPLICATION,
              ) ||
                userAccess?.permissions?.includes(
                  EAccountPermissions.CANCEL_APPLICATION,
                )));
          return (
            <UserPermissionsPickerItem
              key={userPermission}
              onClick={() =>
                !disabled &&
                onChangeSwitch(userPermission as EAccountPermissions)
              }
            >
              <div className="header">
                <Heading4
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {permission.title}
                  {userPermission ===
                    EAccountPermissions.APPROVE_APPLICATION && (
                    <>
                      <UserPermissionsConfigs
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setIsApprovePermissionConfigOpen(true);
                        }}
                        style={{
                          width: 'auto',
                          border: 'none',
                          fontSize: '14px',
                          display: 'block',
                          padding: '5px 0px 0px 0px',
                          marginLeft: '15px',
                        }}
                        typeVariant="outline"
                      >
                        <SettingsIcon
                          color={theme.palette.brand.primary.base as string}
                        />
                      </UserPermissionsConfigs>
                    </>
                  )}
                </Heading4>
                <CustomSwitchIOS
                  checked={
                    disabled ||
                    !!userAccess?.permissions?.includes(
                      userPermission as EAccountPermissions,
                    )
                  }
                  disabled={disabled}
                  size="medium"
                  className={disabled ? 'disabled-switch' : undefined}
                  name={userPermission}
                  onChange={() =>
                    !disabled &&
                    onChangeSwitch(userPermission as EAccountPermissions)
                  }
                />
              </div>
              {permission.description.length > 0 && (
                <TextM style={{ paddingRight: '100px', marginTop: '4px' }}>
                  {permission.description}
                </TextM>
              )}
            </UserPermissionsPickerItem>
          );
        })}
      </UserPermissionsPickerWrapper>
      <ApprovePermissionConfig
        isOpen={isApprovePermissionConfigOpen}
        userAccess={userAccess}
        handleSave={(newPermissionProperties) => {
          setIsApprovePermissionConfigOpen(false);
          const { permissions } = userAccess;
          updateUserAccess({
            ...userAccess,
            permissions: !permissions?.includes(
              EAccountPermissions.APPROVE_APPLICATION,
            )
              ? ([] as EAccountPermissions[]).concat(
                  permissions ?? [],
                  EAccountPermissions.APPROVE_APPLICATION,
                  EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION,
                )
              : userAccess.permissions,
            permission_properties: newPermissionProperties,
          });
        }}
      />
      <UserPermissionsPickerButtonsArea>
        <TextM weight="Medium" style={{ marginRight: 'auto' }}>
          {`Permissões aplicadas: ${permissionsFiltered?.length ?? 0}`}
        </TextM>
        {typeof onBack === 'function' && (
          <ButtonDefault margin={true} onClick={onBack}>
            <ArrowBack />
          </ButtonDefault>
        )}
        {isLoading ? (
          <UserPermissionsLoading>
            <Spinner size={24} />
          </UserPermissionsLoading>
        ) : (
          <ButtonPrimary disabled={isDisabled} onClick={onConfirm}>
            {confirmButtonText}
          </ButtonPrimary>
        )}
      </UserPermissionsPickerButtonsArea>
    </>
  );
};

export default UserPermissionsPicker;
