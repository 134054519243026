import styled from '@emotion/styled';

export const BorrowerStepContent = styled.div``;

export const ListBoxItem = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${({ theme }) => theme.palette?.brand.secondary.base};
    border: 1px solid #E6E5EA;
    background-color: #FDFDFD;
    border-radius: 10px;
    padding: 12px 24px;
    margin-bottom: 16px;
    gap: 21px;
    cursor: pointer;
    &.selected {
        background-color: ${({ theme }) =>
          theme.palette?.brand.background.base};
        border: 2px solid ${({ theme }) => theme.palette?.brand.primary.base};
        h4 {
            color: ${({ theme }) => theme.palette?.brand.primary.base};
        }
    }

    &::-webkit-scrollbar {
     width: 10px;
     margin-right: 30px;
    }
`;

export const InfoArea = styled.div`
  display: flex;
  margin-top: 6px;
`;

export const ListBoxSection = styled.div`
    padding: 24px;
    background-color: #FDFDFD;
    border: 1px solid #E6E5EA;
    border-radius: 10px;
    max-height: 50vh;
    overflow: auto;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 23px;
`;

export const QualificationModalContent = styled.div`
  text-align: left;
  border: 1px solid #E6E5EA;
  padding: 16px;
  border-radius: 10px;
  span {
    display: block;
  }

  .link {
    display: block;

    font-size: 16px;
    text-decoration: none;
    color: ${({ theme }) => theme.palette?.brand?.primary.base};
    font-weight: bold;
    margin-top: 8px;
  }
`;
