import { NoItemsRegisteredContent } from './components';
import { INoItemsRegisteredProps } from './noItemsRegistered.interfaces';
import { NoItemsRegisteredContainer } from './style';

const NoItemsRegistered = ({
  title,
  icon,
  buttonText,
  handleClick,
}: INoItemsRegisteredProps) => {
  return (
    <NoItemsRegisteredContainer>
      <NoItemsRegisteredContent
        title={title}
        icon={icon}
        buttonText={buttonText}
        handleClick={handleClick}
      />
    </NoItemsRegisteredContainer>
  );
};

export default NoItemsRegistered;
