const InstallmentsSeePageIcon = ({ color }: { color: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.255 22.5C11.833 21.896 11.505 21.222 11.29 20.5H5V4.5H9H15H19V12.5H21V3.492C21 2.944 20.553 2.5 20.001 2.5H16H9H3.999C3.447 2.5 3 2.944 3 3.492V21.508C3.00209 21.7706 3.10742 22.0219 3.29322 22.2075C3.47902 22.3931 3.73038 22.4982 3.993 22.5H12.255ZM7 6.5H16V8.5H7V6.5ZM7 10.5H16V12.5H7V10.5ZM10 14.5H7V16.5H10V14.5ZM22.95 22.036L21.446 20.532C21.9405 19.6934 22.1122 18.7031 21.9288 17.747C21.7454 16.7908 21.2196 15.9344 20.4499 15.3381C19.6802 14.7419 18.7195 14.4469 17.7479 14.5083C16.7763 14.5698 15.8604 14.9835 15.1719 15.6719C14.4835 16.3604 14.0698 17.2763 14.0083 18.2479C13.9469 19.2195 14.2419 20.1802 14.8381 20.9499C15.4344 21.7196 16.2908 22.2454 17.247 22.4288C18.2031 22.6122 19.1934 22.4405 20.032 21.946L21.536 23.45L22.95 22.036ZM19.4142 19.9142C19.0392 20.2893 18.5304 20.5 18 20.5C17.4696 20.5 16.9609 20.2893 16.5858 19.9142C16.2107 19.5392 16 19.0304 16 18.5C16 17.9696 16.2107 17.4609 16.5858 17.0858C16.9609 16.7107 17.4696 16.5 18 16.5C18.5304 16.5 19.0392 16.7107 19.4142 17.0858C19.7893 17.4609 20 17.9696 20 18.5C20 19.0304 19.7893 19.5392 19.4142 19.9142Z"
      fill={color}
    />
  </svg>
);

export default InstallmentsSeePageIcon;
