import styled from '@emotion/styled';

export const ButtonDefaultContainer = styled.button`
  outline: 0;
  border: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette?.common?.white};
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: ${({ theme }) => theme.palette?.brand.primary.base};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: 2px solid;
  border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
  max-height: 45px;

  svg {
    padding: 0px;
    margin: 0px;
  }
`;
