import styled from '@emotion/styled';

export const ChargesSimulatorSection = styled.div`
`;

export const ChargesSimulatorSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
  padding: 16px 0px;
  border: 1px solid #EDEDED;
  border-radius: 10px;
  margin-top: 16px;
`;

export const ChargesSimulatorDebtorContent = styled.div`
  border-left: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  height: 24px;
  display: flex;
  align-items: center;
  span {
    display: block;
    padding-right: 24px;
  }
  span:first-of-type {
    color: #6F6B82;
    padding-left: 24px;
  }

  .middle {
    padding-left: 12px !important;
  }
`;
