import React from 'react';

const ConfirmationShield = ({ color, ...props}: { color: string }) => (
  <svg
    width={'100%'}
    height={'100%'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M35.2 73.677a13.528 13.528 0 0 1-3.369-.438l-.216-.06-.202-.101c-7.271-3.662-13.405-8.5-18.233-14.377A54.495 54.495 0 0 1 3.974 42.28 63.461 63.461 0 0 1 .416 20.015c.003-.159.006-.282.006-.366 0-3.683 2.035-6.914 5.184-8.232 2.41-1.01 24.295-10.038 25.876-10.69 2.978-1.5 6.155-.249 6.664-.03 1.14.468 21.371 8.78 25.743 10.872 4.506 2.155 5.708 6.027 5.708 7.975 0 8.819-1.52 17.06-4.52 24.497A56.765 56.765 0 0 1 54.93 60.47c-8.284 9.365-16.57 12.685-16.65 12.714-.99.342-2.032.51-3.08.494Zm-1.95-4.849c.72.162 2.373.405 3.451.01 1.37-.502 8.305-4.115 14.786-11.44 8.955-10.123 13.498-22.848 13.505-37.822-.016-.303-.23-2.467-3.083-3.831-4.287-2.05-25.315-10.688-25.527-10.775l-.058-.025c-.441-.185-1.844-.576-2.81-.067l-.194.09c-.234.097-23.465 9.681-25.943 10.718-1.733.726-2.35 2.523-2.35 3.963 0 .105-.003.258-.007.456-.198 10.245 2.162 35.457 28.23 48.723Z"
        fill={color}
      />
      <path
        d="M32.447 2.829S9.005 12.5 6.493 13.552c-2.512 1.05-3.768 3.574-3.768 6.097 0 2.523-1.883 35.444 29.722 51.361 0 0 2.868.798 5.044 0 2.177-.797 29.805-14.253 29.805-51.467 0 0 0-3.784-4.396-5.886-4.395-2.103-25.646-10.828-25.646-10.828s-2.61-1.156-4.807 0Z"
        fill="#00B5AA"
      />
      <path
        opacity={0.1}
        d="M34.958 10.398V61.99S11.307 50.555 11.516 20.49l23.442-10.092Z"
        fill="#000"
      />
      <path
        d="m35.267 47.479-7.535-9.731 4.382-3.423 3.567 4.607 12.052-12.78 4.03 3.836-16.496 17.49Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(.403)"
          d="M0 0h69.194v73.678H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ConfirmationShield;
