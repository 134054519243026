import styled from '@emotion/styled';

export const ListBoxItem = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${({ theme }) => theme.palette?.brand.secondary.base};
    border: 1px solid #E6E5EA;
    background-color: #FDFDFD;
    border-radius: 10px;
    padding: 12px 24px;
    margin-bottom: 16px;
    gap: 21px;
    cursor: pointer;
    &.selected {
        background-color: ${({ theme }) =>
          theme.palette?.brand.background.base};
        border: 2px solid ${({ theme }) => theme.palette?.brand.primary.base};
        h4 {
            color: ${({ theme }) => theme.palette?.brand.primary.base};
        }
    }

    &::-webkit-scrollbar {
     width: 10px;
     margin-right: 30px;
    }
`;

export const InfoArea = styled.div`
  display: flex;
  margin-top: 6px;
`;

export const SelectedAddInfo = styled.div`
    margin-top: 10px;
    .switch-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0px;

        .switch-item {
            display: flex;
            gap: 8px;
        }
    }

    .relationship-area {
        padding: 20px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .relationship-area-item {
            display: flex;
            gap: 8px;

            input {
                margin-right: 12px;
            }
        }
    }
`;

export const ButtonArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    .cancel-btn {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
