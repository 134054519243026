import { useEffect } from 'react';
import { useTheme } from '@mui/material';
import { ToastCloseIcon, CopyIcon, HyperlinkIcon } from '../Icons';
import {
  ToastInfoIcon,
  ToastAlertIcon,
  ToastErrorIcon,
  ToastSuccessIcon,
} from '../Icons/svgs';
import { TextS } from '../typography';
import { ToastMessageContent, ToastMessageWrapper } from './styles';
import { TMessageType } from './toastEventManager';

type Message = {
  id: number;
  text: string;
  type: TMessageType;
  duration?: number;
};

export interface IToastMessageProps {
  message: Message;
  position?: string;
  onRemoveMessage: (id: number) => void;
}

export function ToastMessage({ message, onRemoveMessage }: IToastMessageProps) {
  const { id, text, type, duration } = message;
  const theme = useTheme();

  function handleRemoveToast() {
    onRemoveMessage(id);
  }

  useEffect(() => {
    const removeMessageTimout = setTimeout(() => {
      onRemoveMessage(id);
    }, duration);

    return () => clearTimeout(removeMessageTimout);
  }, [id, onRemoveMessage, duration]);

  const renderIcon = (msgType: TMessageType) => {
    switch (msgType) {
      case 'alert':
        return ToastAlertIcon;
      case 'error':
        return ToastErrorIcon;
      case 'info':
        return ToastInfoIcon;
      case 'success':
        return ToastSuccessIcon;
      default:
        return ToastInfoIcon;
    }
  };

  return (
    <ToastMessageWrapper messageType={type || 'info'}>
      <ToastMessageContent>
        <div>
          {type === 'link' && (
            <HyperlinkIcon color={theme.palette.brand.primary.base as string} />
          )}
          {type === 'copy' && (
            <CopyIcon color={theme.palette.brand.primary.base as string} />
          )}
          {!['link', 'copy'].includes(type) && <img src={renderIcon(type)} />}
          <TextS weight="Semibold">{text}</TextS>
        </div>
        <div className="close-btn" onClick={handleRemoveToast}>
          <ToastCloseIcon color={theme.palette.brand.primary.base as string} />
        </div>
      </ToastMessageContent>
    </ToastMessageWrapper>
  );
}
