import styled from '@emotion/styled';

export const AuthContainerWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center ;
  align-items: center;
  padding: 0px 20px;
`;
