import React, { HTMLAttributes } from 'react';
import { TextXLBoldWrapper } from './styles';

export interface ITextXLBoldProps extends HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode;
}

const TextXLBold = ({ children, ...props }: ITextXLBoldProps) => {
  return <TextXLBoldWrapper {...props}>{children}</TextXLBoldWrapper>;
};

export default TextXLBold;
