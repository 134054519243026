import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import { useTheme } from '@mui/material';
import { IFile } from '../../../modules/customer/hooks/useUploadRegisterCSV';
import { CustomCircularIndicator } from '../CustomCircularIndicator';
import { DeleteButton } from '../DeleteButton';
import { CSVIcon, UploadIcon } from '../Icons';
import { TextM } from '../typography';
import {
  Col,
  FileTitle,
  UploadCSVContainer,
  UploadCSVContent,
  UploadedCSVContainer,
  UploadedCSVContent,
} from './styles';

export interface IUploadCSV {
  loading?: boolean;
  title?: string;
  fileData?: IFile;
  handleDrop: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
  handleDropRejected?: (
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
  handleRemoveCSV: () => void;
}

const UploadCSV = ({
  handleDrop,
  handleDropRejected,
  handleRemoveCSV,
  loading,
  title = 'Enviar CSV',
  fileData,
}: IUploadCSV) => {
  const theme = useTheme();

  const onDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => {
    handleDrop(acceptedFiles, fileRejections, event);
  };

  const onDropRejected = (
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => {
    handleDropRejected?.(fileRejections, event);
  };

  const { getRootProps, getInputProps, isDragAccept, isDragActive } =
    useDropzone({
      onDrop,
      onDropRejected,
      multiple: false,
      accept: {
        'text/csv': ['.csv'],
      },
    });

  return (
    <>
      {fileData ? (
        <UploadedCSVContainer>
          <UploadedCSVContent>
            <FileTitle>
              <CSVIcon />
              <TextM style={{ alignSelf: 'center' }} weight="Bold">
                {fileData.name}
              </TextM>
            </FileTitle>
            <DeleteButton handleClick={handleRemoveCSV} />
          </UploadedCSVContent>
        </UploadedCSVContainer>
      ) : (
        <UploadCSVContainer>
          <UploadCSVContent
            {...getRootProps({
              onClick: (event) => {
                if (loading) {
                  event.stopPropagation();
                }
              },
            })}
          >
            <input disabled={loading} {...getInputProps()} />
            {loading ? (
              <Col style={{ justifyContent: 'center' }}>
                <TextM
                  weight="Bold"
                  style={{
                    marginLeft: '30px',
                    color: theme.palette.brand.primary.base as string,
                  }}
                >
                  Enviando os arquivos
                </TextM>
                <CustomCircularIndicator
                  style={{
                    marginLeft: '20px',
                    color: theme.palette.brand.primary.base as string,
                  }}
                />
              </Col>
            ) : (
              <>
                <Col>
                  <UploadIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                  <TextM
                    weight="Bold"
                    style={{
                      marginLeft: '30px',
                      color: theme.palette.brand.primary.base as string,
                    }}
                  >
                    {isDragActive && !isDragAccept
                      ? 'Tipo de arquivo não suportado'
                      : isDragActive
                      ? 'Solte aqui'
                      : title}
                  </TextM>
                </Col>
                <Col>
                  <TextM
                    style={{
                      color: theme.palette.brand.primary.base as string,
                    }}
                  >
                    (arraste e solte aqui arquivos do seu computador)
                  </TextM>
                </Col>
              </>
            )}
          </UploadCSVContent>
        </UploadCSVContainer>
      )}
    </>
  );
};

export default UploadCSV;
