import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { ChevronIcon } from '../Icons';
import { TextM } from '../typography';
import { TextS } from '../typography/body';
import { TableAvatar } from './components';
import { CellContent, IdArea, MainTableWrapper } from './styles';

export enum ETableHeadItemType {
  ID,
  DATE,
}

export type TTableDataItems = {
  [key: string]: string;
};

export type TTableHeadItem = {
  label: string;
  key: string;
  type?: ETableHeadItemType;
};

interface IMainTable {
  tableHeadItems?: TTableHeadItem[];
  tableDataItems?: TTableDataItems[];
  handleOrder?: () => void;
  handleClick?: (id: string) => void;
  registerKey?: string;
  nameKey?: string;
  idKey?: string;
}

const MainTable = ({
  tableHeadItems,
  tableDataItems,
  handleOrder,
  handleClick,
  registerKey,
  nameKey,
}: IMainTable) => {
  const theme = useTheme();
  return (
    <MainTableWrapper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeadItems?.map((item, index) => (
                <TableCell key={index}>
                  {index === 0 ? (
                    <CellContent>
                      <TextM weight="Bold">{item.label}</TextM>
                      {!!handleOrder && (
                        <ChevronIcon
                          style={{
                            marginLeft: '6px',
                            alignSelf: 'center',
                            cursor: 'pointer',
                          }}
                        />
                      )}
                    </CellContent>
                  ) : (
                    <CellContent>
                      <TextM weight="Bold">{item.label}</TextM>
                    </CellContent>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDataItems?.map((item, inx) => (
              <TableRow
                key={`${item.id}-${inx}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                }}
                onClick={() => handleClick && handleClick(item.id)}
              >
                {tableHeadItems?.map((headItem) => {
                  if (headItem.type === ETableHeadItemType.ID) {
                    const name = item[nameKey || 'name'];
                    const register = item[registerKey || 'register'];
                    return (
                      <TableCell key={`${inx}-${headItem.key}`}>
                        <CellContent>
                          <TableAvatar letter={name?.charAt(0)} />
                          <IdArea>
                            <TextM
                              weight="Semibold"
                              style={{ color: theme.palette.grey[800] }}
                            >
                              {name}
                            </TextM>
                            <TextS style={{ color: theme.palette.grey[800] }}>
                              {register}
                            </TextS>
                          </IdArea>
                        </CellContent>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell key={`${inx}-${headItem.key}`}>
                      <CellContent>
                        <TextM style={{ color: theme.palette.grey[800] }}>
                          {item[headItem.key]}
                        </TextM>
                      </CellContent>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainTableWrapper>
  );
};

export default MainTable;
