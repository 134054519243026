import React, { createContext, useContext, useReducer } from 'react';
import { IProductsContext } from './products.interfaces';
import { productsInitialState, productsReducer } from './productsReducer';

export const ProductsContext = createContext<IProductsContext | undefined>(
  undefined,
);

export interface IProductsProviderProps {
  children?: React.ReactNode;
}

export const ProductsProvider = ({ children }: IProductsProviderProps) => {
  const [state, dispatch] = useReducer(productsReducer, productsInitialState);

  const value = { state, dispatch };

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => {
  const context = useContext(ProductsContext);

  if (context === undefined) {
    throw new Error(
      'useProductsContext must be used within a ProductsProvider',
    );
  }
  return context;
};
