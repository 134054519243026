import styled from '@emotion/styled';

export interface ITextMDefaultWrapperProps {
  weight?: 'Regular' | 'Bold' | 'Semibold' | 'Medium';
}

export const TextMDefaultWrapper = styled.p<ITextMDefaultWrapperProps>`
  font-family: 'Inter';
  font-size: 16px;
  color: ${({ theme }) => theme.palette?.brand.secondary.base};
  font-weight: ${({ weight }) => (weight ? weight : 'Regular')};

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
  }

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
  }
`;
