import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  BackToLoginBtnContainer,
  BackToLoginBtnContent,
  BackToLoginText,
} from './styles';

export interface IBackToLoginBtnContainer {
  children: React.ReactNode;
  onBtnClick: () => void;
}

const BackToLoginBtn = ({ onBtnClick, children }: IBackToLoginBtnContainer) => {
  return (
    <BackToLoginBtnContainer>
      <BackToLoginBtnContent onClick={onBtnClick}>
        <LogoutIcon sx={{ width: '24px', height: '24px' }} />
        <BackToLoginText>{children}</BackToLoginText>
      </BackToLoginBtnContent>
    </BackToLoginBtnContainer>
  );
};

export default BackToLoginBtn;
