import styled from '@emotion/styled';

export const BankStatementHolderInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const BankStatementHolderInfoItem = styled.div`
    display: flex;
    gap: 12px;
    
    & > p {
        min-width: 100px;
    }
`;
