import { useTheme } from '@mui/material';
import React from 'react';
import { TextSDefaultWrapper } from './styles';

export interface ITextSDefaultProps
  extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode;
  weight?: 'Regular' | 'Bold' | 'Semibold' | 'Medium';
}

const fontWeightTypes = {
  Regular: 'Rg',
  Bold: 'B',
  Semibold: 'Sb',
  Medium: 'Md',
};

const TextS = ({
  children,
  weight = 'Regular',
  ...props
}: ITextSDefaultProps) => {
  const theme = useTheme();
  return (
    <TextSDefaultWrapper
      weight={weight}
      {...props}
      style={{
        ...props.style,
        fontWeight: theme.typography?.fontWeight?.[fontWeightTypes[weight]],
      }}
    >
      {children}
    </TextSDefaultWrapper>
  );
};

export default TextS;
