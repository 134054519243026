import React, { RefObject, useEffect, useState } from 'react';
import { TextL } from 'app/components';
import { useTheme, Theme } from '@mui/material';
import {
  PasswordRequirement,
  PasswordRequirements,
  PasswordStrengthContainer,
  PasswordStrengthDescription,
  PasswordStrengthLevel,
  PasswordStrengthLevels,
  PasswordStrengthLevelText,
} from './styles';
import { CheckIcon } from 'app/components/Icons';
import { Divider } from '@mui/material';
import { Popper } from 'celcoin-design-system';

interface PasswordStrengthProps {
  password: string;
  onChangePasswordStrength: (strength: number) => void;
  anchorRef: RefObject<HTMLDivElement>;
}

const PasswordStrengthDivider = ({ theme }: { theme: Theme }) => (
  <Divider
    sx={{
      backgroundColor: theme.palette.brand.primary.base,
      marginTop: '12px',
      opacity: 0.16,
    }}
  />
);

const PasswordStrength = ({
  password,
  anchorRef,
  onChangePasswordStrength,
}: PasswordStrengthProps) => {
  const theme = useTheme();
  const [strength, updateStrength] = useState<number>(-1);
  const [hasMinLength, toggleHasMinLength] = useState<boolean>(false);
  const [hasLowerUppercase, toggleHasLowerUppercase] = useState<boolean>(false);
  const [hasSymbols, toggleHasSymbols] = useState<boolean>(false);
  const [hasNumber, toggleHasNumber] = useState<boolean>(false);
  const [strengthText, updateStrengthText] = useState<
    null | 'weak' | 'medium' | 'high' | 'strong'
  >('weak');

  useEffect(() => {
    if (strength >= 3) {
      updateStrengthText('strong');
    } else if (strength >= 2) {
      updateStrengthText('high');
    } else if (strength >= 1) {
      updateStrengthText('medium');
    } else if (strength >= 0) {
      updateStrengthText('weak');
    } else {
      updateStrengthText(null);
    }
    onChangePasswordStrength(strength);
  }, [strength]);

  useEffect(() => {
    if (password.length >= 8) {
      toggleHasMinLength(true);
      if (!hasMinLength) updateStrength((state) => state + 1);
    } else if (hasMinLength) {
      toggleHasMinLength(false);
      updateStrength((state) => state - 1);
    }

    if (/\d/.test(password)) {
      toggleHasNumber(true);
      if (!hasNumber) updateStrength((state) => state + 1);
    } else if (hasNumber) {
      toggleHasNumber(false);
      updateStrength((state) => state - 1);
    }

    if (/[A-Z]/.test(password) && /[a-z]/.test(password)) {
      toggleHasLowerUppercase(true);
      if (!hasLowerUppercase) updateStrength((state) => state + 1);
    } else if (hasLowerUppercase) {
      toggleHasLowerUppercase(false);
      updateStrength((state) => state - 1);
    }

    if (/[!@#$%^&*_-]/.test(password)) {
      toggleHasSymbols(true);
      if (!hasSymbols) updateStrength((state) => state + 1);
    } else if (hasSymbols) {
      toggleHasSymbols(false);
      updateStrength((state) => state - 1);
    }
  }, [password]);

  return (
    <Popper open={true} anchorRef={anchorRef}>
      <PasswordStrengthContainer>
        <TextL weight="bold">Sua senha deve ter:</TextL>
        <PasswordRequirements>
          <PasswordRequirement>
            <CheckIcon isChecked={hasMinLength} />
            <span>8 ou mais caracteres</span>
          </PasswordRequirement>
          <PasswordRequirement>
            <CheckIcon isChecked={hasLowerUppercase} />
            <span>Letras maiúsculas e minúsculas</span>
          </PasswordRequirement>
          <PasswordRequirement>
            <CheckIcon isChecked={hasSymbols} />
            <span>No mínimo 1 caractere especial (/@$#*...)</span>
          </PasswordRequirement>
          <PasswordRequirement>
            <CheckIcon isChecked={hasNumber} />
            <span>Pelo menos um número</span>
          </PasswordRequirement>
        </PasswordRequirements>
      </PasswordStrengthContainer>
      <PasswordStrengthDivider theme={theme} />
      <PasswordStrengthContainer>
        <PasswordStrengthLevels>
          <PasswordStrengthLevel
            strength={strength >= 0 ? strengthText : null}
          />
          <PasswordStrengthLevel
            strength={strength >= 1 ? strengthText : null}
          />
          <PasswordStrengthLevel
            strength={strength >= 2 ? strengthText : null}
          />
          <PasswordStrengthLevel
            strength={strength >= 3 ? strengthText : null}
          />
        </PasswordStrengthLevels>
        <PasswordStrengthLevelText
          strength={strength >= 0 ? strengthText : null}
        >
          <span>Nível de segurança:</span>
          {!strengthText && <>-</>}
          {strengthText === 'weak' && <span>Fraco</span>}
          {strengthText === 'medium' && <span>Médio</span>}
          {strengthText === 'high' && <span>Alto</span>}
          {strengthText === 'strong' && <span>Forte</span>}
        </PasswordStrengthLevelText>
        <PasswordStrengthDescription>
          Evite senhas usadas em outros sites ou que sejam fáceis de adivinhar.
        </PasswordStrengthDescription>
      </PasswordStrengthContainer>
    </Popper>
  );
};

export default PasswordStrength;
