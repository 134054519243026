import React from 'react';
import { AuthContainer } from '../../components';
import { PasswordRecoveryForm } from './PasswordRecoveryForm';

const PasswordRecovery = () => {
  return (
    <AuthContainer>
      <PasswordRecoveryForm />
    </AuthContainer>
  );
};

export default PasswordRecovery;
