import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  Spinner,
  TextL,
  TextM,
  TextS,
  TextXs,
} from 'app/components';
import { TRegisterBusinessDataRequest } from 'modules/customer/context';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import { IBusinessPersonRelation } from 'modules/customer/services/api/interfaces';
import { formatCPF, formatPhone } from 'app/helpers';
import { ButtonArea, TableWrapper } from './styles';
import {
  DetailModal,
  LegalRepresentativesEmpty,
  PersonEditSidesheet,
} from './components';
import { PersonListSidesheet } from 'modules/customer/components';
import { RelationPerson } from 'modules/customer/interfaces/businessRelation';
import { translatedWorkRelation } from 'modules/customer/helpers';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { Heading3, Table } from 'celcoin-design-system';
import { TableHeadCellContainer } from 'app/styles/components/Table';
import { TableBodyCellContainer } from 'app/styles/components/Table';
import { DetailsIcon } from 'app/components/Icons';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';

type ILegalPersonRepresentativesProps = {
  business: TRegisterBusinessDataRequest;
};

export interface TBusinessRelationsData extends IBusinessPersonRelation {
  person: {
    full_name: string;
    id: string;
    taxpayer_id: string;
    share?: number;
    phone:
      | { country_code: string; area_code: string; number: string }
      | undefined;
    email: string | undefined;
    birth_date: string | undefined;
    created_at: string | undefined;
  };
}

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Pessoa</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Telefone</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">E-mail</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Particiapação em quotas (%)</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Assina pela empresa</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '6',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Vínculo trabalhista</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '',
    content: (
      <TableHeadCellContainer>
        <div />
      </TableHeadCellContainer>
    ),
  },
];

const LegalPersonRepresentatives = ({
  business,
}: ILegalPersonRepresentativesProps) => {
  const [businessRelationsDataList, setBusinessRelationsDataList] = useState<
    TBusinessRelationsData[] | undefined
  >(undefined);
  const [businessRelationsData, setBusinessRelationsData] = useState<
    TBusinessRelationsData | undefined
  >(undefined);
  const [businessRelationsDataEdit, setBusinessRelationsDataEdit] = useState<
    TBusinessRelationsData | undefined
  >(undefined);
  const [isPersonListSidesheetOpen, setIsPersonListSidesheetOpen] =
    useState(false);
  const { showSnackbar } = useSnackbar();
  const {
    getBusinessRelations: getBusinessRelationsRequest,
    businessRelationsLoading,
    deleteBusinessRelations,
    updateBusinessRelations,
    createBusinessRelations,
  } = useCustomerService();
  const { getPerson } = usePersonService();
  const { isProfileAuthorized } = usePermify();
  const theme = useTheme();

  const handleCloseEditSidesheet = () => {
    setBusinessRelationsDataEdit(undefined);
  };

  const getBusinessRelations = async (businessId: string) => {
    const res = await getBusinessRelationsRequest(businessId);
    const businessRelationParsedPromise = res?.map(async (person) => {
      const personDataRes = await getPerson(person.person.id);
      if (personDataRes) {
        return {
          ...person,
          person: {
            ...person.person,
            phone: {
              country_code: personDataRes?.phone.country_code,
              area_code: personDataRes?.phone.area_code,
              number: personDataRes?.phone.number,
            },
            email: personDataRes?.email_address,
            birth_date: personDataRes?.birth_date,
            share: person.share,
            created_at: personDataRes?.created_at,
          },
        };
      }
      return {
        ...person,
        person: {
          ...person.person,
          phone: undefined,
          email: '',
          birth_date: '',
          created_at: '',
        },
      };
    });

    const businessRelationParsed = await Promise.all(
      businessRelationParsedPromise || [],
    );

    setBusinessRelationsDataList(businessRelationParsed);
  };

  const handleUpdatePerson = async (
    updatedPersonData: TBusinessRelationsData | undefined,
  ) => {
    const res = await updateBusinessRelations(
      business?.id || '',
      updatedPersonData?.id || '',
      updatedPersonData,
    );

    handleCloseEditSidesheet();
    setBusinessRelationsData(undefined);
    await getBusinessRelations(business?.id || '');
    if (!res) {
      return;
    }

    showSnackbar('Representante legal atualizado');
  };

  const handleRemovePerson = async (
    personDataToRemove: TBusinessRelationsData | undefined,
  ) => {
    const res = await deleteBusinessRelations(
      business.id || '',
      personDataToRemove?.id || '',
    );
    handleCloseEditSidesheet();
    setBusinessRelationsData(undefined);
    await getBusinessRelations(business?.id || '');

    if (res?.status !== 200) {
      return;
    }

    showSnackbar('Representante legal removido da lista');
  };

  const handleAdd = async (updatedPersonData: RelationPerson) => {
    const res = await createBusinessRelations(business?.id || '', [
      updatedPersonData,
    ]);
    setBusinessRelationsData(undefined);
    handleCloseEditSidesheet();
    setIsPersonListSidesheetOpen(false);
    await getBusinessRelations(business?.id || '');

    if (!res) {
      return;
    }

    showSnackbar('Representante legal adicionado na lista');
  };

  useEffect(() => {
    if (business) {
      getBusinessRelations(business.id || '');
    }
  }, []);

  return (
    <>
      <BoxSection>
        {businessRelationsLoading ? (
          <Spinner />
        ) : businessRelationsDataList &&
          businessRelationsDataList.length <= 0 ? (
          <LegalRepresentativesEmpty
            handleAddPerson={() => setIsPersonListSidesheetOpen(true)}
          />
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Heading3
                style={{
                  width: '450px',
                  color: theme.palette.brand.secondary.base,
                }}
              >
                Representantes legais
              </Heading3>
              <ButtonArea>
                {isProfileAuthorized(
                  EAccountPermissions.UPDATE_PERSON_BUSINESS,
                ) && (
                  <ButtonDefault
                    style={{ maxWidth: '250px' }}
                    onClick={() => setIsPersonListSidesheetOpen(true)}
                  >
                    <AddIcon />
                    <TextM
                      weight="Semibold"
                      style={{ color: theme.palette.brand.primary.base }}
                    >
                      Adicionar representante
                    </TextM>
                  </ButtonDefault>
                )}
              </ButtonArea>
            </div>
            <TableWrapper>
              <Table
                tableHeadCell={tableHeader}
                tableBodyContent={businessRelationsDataList?.map((person) => {
                  return {
                    id: person.id,
                    cells: [
                      {
                        id: '1',
                        content: (
                          <TableBodyCellContainer>
                            <div>
                              <TextS weight="Bold">
                                {person?.person?.full_name}
                              </TextS>
                              <TextXs>
                                {formatCPF(person?.person?.taxpayer_id)}
                              </TextXs>
                            </div>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '2',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {formatPhone(
                                `${person?.person?.phone?.area_code}${person?.person?.phone?.number}`,
                              )}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '3',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>{person.person.email}</TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '4',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {typeof person.person.share === 'number'
                                ? `${person.person.share * 100}%`
                                : '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '5',
                        content: (
                          <TableBodyCellContainer>
                            {' '}
                            <TextS>{person.signer ? 'Sim' : 'Não'}</TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '6',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {translatedWorkRelation[person.type] ||
                                'Não especificado'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '7',
                        content: (
                          <TableBodyCellContainer
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              margin: '0px',
                            }}
                          >
                            <ButtonPrimary
                              typeVariant="outline"
                              style={{ width: 'fit-content' }}
                              onClick={() => setBusinessRelationsData(person)}
                            >
                              <DetailsIcon
                                color={
                                  theme.palette.brand.primary.base as string
                                }
                              />
                            </ButtonPrimary>
                          </TableBodyCellContainer>
                        ),
                      },
                    ],
                  };
                })}
              />
            </TableWrapper>
          </>
        )}
      </BoxSection>
      <DetailModal
        isOpen={!!businessRelationsData}
        handleClose={() => setBusinessRelationsData(undefined)}
        personData={businessRelationsData}
        editUser={(personData) => setBusinessRelationsDataEdit(personData)}
      />
      <PersonEditSidesheet
        isOpen={!!businessRelationsDataEdit}
        handleClose={handleCloseEditSidesheet}
        personData={businessRelationsDataEdit}
        removePerson={handleRemovePerson}
        handleSave={handleUpdatePerson}
      />
      <PersonListSidesheet
        isOpen={isPersonListSidesheetOpen}
        handleClose={() => setIsPersonListSidesheetOpen(false)}
        handleAddFromEdit={handleAdd}
      />
    </>
  );
};

export default LegalPersonRepresentatives;
