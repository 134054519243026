import styled from '@emotion/styled';

export const ConfirmationModalContent = styled.div`
  width: 400px;
  height: 320px;
  background-color: #fff;
`;

export const BodyArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 30px;

  img {
    width: 42px;
    height: 42px;
  }
`;

export const HeaderArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
