import { CircularProgress, CircularProgressProps } from '@mui/material';
import { CustomCircularIndicatorContainer } from './styles';

export type ICustomCircularIndicator = CircularProgressProps;

const CustomCircularIndicator = ({
  size,
  ...props
}: ICustomCircularIndicator) => {
  return (
    <CustomCircularIndicatorContainer>
      <CircularProgress
        value={100}
        variant="determinate"
        sx={{
          color: '#eee',
          position: 'absolute',
        }}
        thickness={4}
        size={size}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        thickness={4}
        value={30}
        sx={{ animationDuration: '550ms', ...props.sx }}
        size={size}
        {...props}
      />
    </CustomCircularIndicatorContainer>
  );
};

export default CustomCircularIndicator;
