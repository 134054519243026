import { ButtonPrimary } from '../ButtonPrimary';
import { Heading3, RegularText } from '../typography';
import { RequestErrorItemContainer, RequestErrorItemContent } from './styles';

export interface IRequestErrorItemProps {
  errorTitle?: string;
  errorContent?: string;
  errorHandler: () => void;
}

const RequestErrorItem = ({
  errorTitle,
  errorContent,
  errorHandler,
}: IRequestErrorItemProps) => {
  return (
    <RequestErrorItemContainer>
      <RequestErrorItemContent>
        <Heading3>{errorTitle || 'Ops!'}</Heading3>
        <RegularText style={{ marginTop: '20px' }}>
          {errorContent || 'Ocorreu um erro.'}
        </RegularText>
        <ButtonPrimary style={{ marginTop: '20px' }} onClick={errorHandler}>
          Tentar novamente
        </ButtonPrimary>
      </RequestErrorItemContent>
    </RequestErrorItemContainer>
  );
};

export default RequestErrorItem;
