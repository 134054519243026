
const BroomIcon = ({color, ...props}: { color: string }) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.452.923a.75.75 0 0 1 0 1.06l-6.928 6.929a5.751 5.751 0 0 1-.496 7.567l-.832.832-2.787 4.18a.75.75 0 0 1-1.154.115L.769 13.12a.75.75 0 0 1 .115-1.154l4.18-2.787.832-.832a5.751 5.751 0 0 1 7.567-.496L20.392.923a.75.75 0 0 1 1.06 0ZM6.603 9.762l6.01 6.01.354-.354a4.25 4.25 0 0 0-6.01-6.01l-.354.354Zm-1.156.965-2.97 1.98 7.192 7.191 1.98-2.97-6.202-6.201Z"
      fill={color}
    />
  </svg>
)

export default BroomIcon
