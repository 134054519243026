import { useTheme } from '@mui/material';
import {
  ButtonPrimary,
  CustomTableSelect,
  Heading3,
  TextM,
} from 'app/components';
import { TCustomSelectOption } from 'app/components/CustomTableSelect/CustomTableSelect';
import { ExternalIcon, PencilIcon } from 'app/components/Icons';
import { roleTypesTranslationParse } from 'app/helpers';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { formatCurrency, normalizeAddDigits } from 'app/utils/normalizer';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { TProducts } from 'modules/products/context';
import { useNavigate } from 'react-router-dom';
import {
  ApplicationDetailsHeader,
  ViewBoxContainer,
  ViewBoxContent,
  ViewBoxItem,
} from '../../styles';
import {
  TQualification,
  TQualificationWithStatusAndProduct,
} from 'modules/customer/interfaces/qualifications';

export interface IBorrowerContent {
  product: TProducts | undefined;
  borrowerQualificationData: TQualification | undefined;
  selectedQualification: TQualificationWithStatusAndProduct | undefined;
  statusOptions: TCustomSelectOption[];
  isStatusOptionsLoading: string | boolean;
  handleEditCondition: (
    qualification: TQualificationWithStatusAndProduct | undefined,
  ) => void;
  handleUpdateQualificationStatus: (
    qualification: TQualificationWithStatusAndProduct,
    identifier: string,
  ) => void;
}

const buttonStyle = {
  padding: '11px 15px',
  width: 'fit-content',
  gap: '6px',
};

const PersonContent = ({
  borrowerQualificationData,
  selectedQualification,
  statusOptions,
  isStatusOptionsLoading,
  handleEditCondition,
  handleUpdateQualificationStatus,
}: IBorrowerContent) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isProfileAuthorized } = usePermify();
  const handleNavigateToEmployer = () => {
    navigate(
      `/records/legal/${borrowerQualificationData?.employer?.id || ''}/details`,
    );
  };

  return (
    <>
      <ApplicationDetailsHeader>
        <Heading3>Linha de crédito</Heading3>
      </ApplicationDetailsHeader>
      <ViewBoxContainer>
        <ViewBoxContent>
          <ViewBoxItem>
            <div>
              <span>
                <TextM weight="Semibold">Situação</TextM>
              </span>
              <TextM>
                <>
                  Última atualização:{' '}
                  {formatDateFromBackend(
                    borrowerQualificationData?.updated_at || '',
                  ).toLocaleDateString('pt-br')}
                </>
              </TextM>
            </div>
            <span>
              <CustomTableSelect
                selectItems={statusOptions}
                disabled={
                  !isProfileAuthorized(EAccountPermissions.UPDATE_QUALIFICATION)
                }
                isLoading={!!isStatusOptionsLoading}
                handleChange={(identifier) =>
                  handleUpdateQualificationStatus(
                    selectedQualification as TQualificationWithStatusAndProduct,
                    identifier,
                  )
                }
                value={selectedQualification?.status || ''}
              />
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Função</TextM>
            </span>
            <span>
              <TextM>
                {
                  roleTypesTranslationParse[
                    borrowerQualificationData?.role
                      ? borrowerQualificationData?.role
                      : 'BORROWER'
                  ]
                }
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Funding</TextM>
            </span>
            <span>
              <TextM>
                {borrowerQualificationData?.funding?.legal_name || 'N/A'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        {borrowerQualificationData?.employer?.legal_name && (
          <ViewBoxContent>
            <ViewBoxItem>
              <span>
                <TextM weight="Semibold">Empregador</TextM>
              </span>
              <span
                style={{ display: 'flex', gap: '12px', alignItems: 'center' }}
              >
                <TextM>
                  {borrowerQualificationData?.employer?.legal_name || 'N/A'}
                </TextM>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={handleNavigateToEmployer}
                >
                  <ExternalIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                </div>
              </span>
            </ViewBoxItem>
          </ViewBoxContent>
        )}
      </ViewBoxContainer>
      <ApplicationDetailsHeader>
        <Heading3>Condições</Heading3>
        {isProfileAuthorized(
          EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION_CONDITIONS,
        ) && (
          <div style={{ display: 'flex', gap: '6px' }}>
            <ButtonPrimary
              typeVariant="outline"
              style={buttonStyle}
              onClick={() => handleEditCondition(selectedQualification)}
            >
              <PencilIcon color={theme.palette.brand.primary.base as string} />
              <TextM
                weight="Semibold"
                style={{
                  color: theme.palette.brand.primary.base,
                  marginRight: '8px',
                }}
              >
                Editar condições
              </TextM>
            </ButtonPrimary>
          </div>
        )}
      </ApplicationDetailsHeader>
      <ViewBoxContainer>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Limite total</TextM>
            </span>
            <span>
              <TextM>
                {borrowerQualificationData?.condition?.line_of_credit
                  ?.credit_limit
                  ? formatCurrency(
                      borrowerQualificationData?.condition?.line_of_credit
                        ?.credit_limit || 0,
                    )
                  : '-'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Limite da parcela</TextM>
            </span>
            <span>
              <TextM>
                {borrowerQualificationData?.condition?.line_of_credit
                  ?.max_payment_amount
                  ? formatCurrency(
                      borrowerQualificationData?.condition?.line_of_credit
                        ?.max_payment_amount || 0,
                    )
                  : '-'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Mínimo solicitação</TextM>
            </span>
            <span>
              <TextM>
                {borrowerQualificationData?.condition?.min_requested_amount
                  ? formatCurrency(
                      borrowerQualificationData?.condition
                        ?.min_requested_amount || 0,
                    )
                  : '-'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        {/* <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Tac</TextM>
            </span>
            <span>
              <TextM>
                {borrowerQualificationData?.condition?.tac
                  ? formatCurrency(
                      borrowerQualificationData?.condition?.tac || 0,
                    )
                  : '-'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent> */}
        {/* <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Limite disponível</TextM>
            </span>
            <span>
              <TextM>
                {borrowerQualificationData?.condition?.line_of_credit
                  ?.credit_limit
                  ? formatCurrency(
                      (borrowerQualificationData?.condition?.line_of_credit
                        ?.credit_limit || 0) -
                        (borrowerQualificationData?.condition?.line_of_credit
                          ?.consumed_credit_limit || 0),
                    ) || '-'
                  : '-'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Limite da parcela disponível</TextM>
            </span>
            <span>
              <TextM>
                {borrowerQualificationData?.condition?.line_of_credit
                  ?.max_payment_amount
                  ? formatCurrency(
                      (borrowerQualificationData?.condition?.line_of_credit
                        ?.max_payment_amount || 0) -
                        (borrowerQualificationData?.condition?.line_of_credit
                          ?.consumed_payment_amount || 0),
                    ) || '-'
                  : '-'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent> */}
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Carência máxima</TextM>
            </span>
            <span>
              <TextM>
                {borrowerQualificationData?.condition?.max_grace_period
                  ? borrowerQualificationData?.condition?.max_grace_period
                  : '-'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Carência mínima</TextM>
            </span>
            <span>
              <TextM>
                {borrowerQualificationData?.condition?.min_grace_period
                  ? borrowerQualificationData?.condition?.min_grace_period
                  : '-'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Prazo mínimo</TextM>
            </span>
            <span>
              <TextM>
                {borrowerQualificationData?.condition?.min_payment_number
                  ? borrowerQualificationData?.condition?.min_payment_number
                  : '-'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Prazo máximo</TextM>
            </span>
            <span>
              <TextM>
                {borrowerQualificationData?.condition?.max_payment_number
                  ? borrowerQualificationData?.condition?.max_payment_number
                  : '-'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          {/*<ViewBoxItem>*/}
          {/*  <span>*/}
          {/*    <TextM weight="Semibold">Dia de vencimento</TextM>*/}
          {/*  </span>*/}
          {/*  <span>*/}
          {/*    <TextM>*/}
          {/*      {borrowerQualificationData?.condition?.due_day*/}
          {/*        ? borrowerQualificationData?.condition?.due_day*/}
          {/*        : '-'}*/}
          {/*    </TextM>*/}
          {/*  </span>*/}
          {/*</ViewBoxItem>*/}
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Taxa de juros (% a.m.)</TextM>
            </span>
            <span>
              <TextM>
                {borrowerQualificationData?.condition
                  ?.schedule_based_conditions?.[0].interest_rate
                  ? normalizeAddDigits(
                      borrowerQualificationData?.condition
                        ?.schedule_based_conditions?.[0]?.interest_rate * 100,
                      2,
                    )
                  : '-'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
      </ViewBoxContainer>
    </>
  );
};

export default PersonContent;
