const ChargesSimulatorPersonIcon = ({ color }: { color: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 22.5C4 20.3783 4.84285 18.3434 6.34315 16.8431C7.84344 15.3429 9.87827 14.5 12 14.5C14.1217 14.5 16.1566 15.3429 17.6569 16.8431C19.1571 18.3434 20 20.3783 20 22.5H18C18 20.9087 17.3679 19.3826 16.2426 18.2574C15.1174 17.1321 13.5913 16.5 12 16.5C10.4087 16.5 8.88258 17.1321 7.75736 18.2574C6.63214 19.3826 6 20.9087 6 22.5H4ZM12 13.5C8.685 13.5 6 10.815 6 7.5C6 4.185 8.685 1.5 12 1.5C15.315 1.5 18 4.185 18 7.5C18 10.815 15.315 13.5 12 13.5ZM12 11.5C14.21 11.5 16 9.71 16 7.5C16 5.29 14.21 3.5 12 3.5C9.79 3.5 8 5.29 8 7.5C8 9.71 9.79 11.5 12 11.5Z"
      fill={color}
    />
  </svg>
);

export default ChargesSimulatorPersonIcon;
