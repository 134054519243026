import styled from '@emotion/styled';

export const HeaderArea = styled.div`
  width: 100%;
  display: flex;
  gap: 512px;
  padding: 32px 42px;
  align-items: center;
  justify-content: space-between;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
`;

export const ContentArea = styled.div`
  width: 100%;
  display: flex;
  padding: 32px 42px;
  flex-direction: column;
`;

export const BusinessRelationsList = styled.div`
  background: #FFFFFF;
  border-radius: 20px;
  border: 1px solid #E6E5EA;
  box-shadow: 0px 2px 23px -8px rgba(31, 30, 35, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  height: 584px;
  width: 100%;
  overflow: auto;

  .container-list {
    height: 100%;
    width: 100%;
  }
  
  .content-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    padding: 32px 24px;
    position: relative;
  }
`;
