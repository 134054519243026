import {
  CustomInput,
  CalendarInput,
  CustomSelect,
} from 'celcoin-design-system';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { formatCPF } from '../../../../../../app/helpers';
import { formatDateFromBackend } from '../../../../../../app/helpers/dateHelpers';
import { BusinessEditSideSheet } from '../../../../components/BusinessEditSideSheet';
import { TRegisterPersonDataRequest } from '../../../../context';
import { spouseStepSchema } from '../../../../helpers/personValidation';
import { NaturalPersonViewPageContext } from '../../NaturalPersonViewPage';
import { StepContentInput, StepContentInputArea } from '../styles';
import { EMaritalPropertySystem } from 'modules/customer/context/PersonProvider/person.interfaces';
import { MaritialPropertyObject } from 'modules/customer/context/PersonProvider/person.constants';

export interface INaturalPersonEditSpouseSection {
  personData: TRegisterPersonDataRequest;
  handleClose: () => void;
  isOpen: boolean;
}

const NaturalPersonEditSpouseSection = ({
  personData,
  handleClose,
  isOpen,
}: INaturalPersonEditSpouseSection) => {
  const { updatePerson, isLoading } = useContext(NaturalPersonViewPageContext);
  const formik = useFormik({
    initialValues: {
      spouse_full_name: personData.spouse_full_name || '',
      spouse_taxpayer_id: personData.spouse_taxpayer_id || '',
      spouse_date_of_birth: personData.spouse_date_of_birth
        ? formatDateFromBackend(personData.spouse_date_of_birth).toISOString()
        : '',
      marital_property_system: personData.marital_property_system
        ? {
            label: MaritialPropertyObject[personData.marital_property_system],
            value: personData.marital_property_system,
          }
        : {
            label: '',
            value: undefined,
          },
    },
    validationSchema: spouseStepSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const newPersonData: TRegisterPersonDataRequest = {
        ...personData,
        spouse_full_name: values.spouse_full_name,
        spouse_taxpayer_id: values.spouse_taxpayer_id.replace(/\D+/g, ''),
        spouse_date_of_birth: values.spouse_date_of_birth
          ? new Date(values.spouse_date_of_birth).toISOString()
          : '',
        marital_property_system: values.marital_property_system?.value,
      };
      updatePerson(newPersonData).then(() => {
        onClose();
      });
    },
  });

  const onClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <BusinessEditSideSheet
      open={isOpen}
      handleClose={onClose}
      handleSave={formik.submitForm}
      isLoading={isLoading}
      title="Cônjuge"
    >
      <StepContentInputArea>
        <StepContentInput isFull={true}>
          <CustomInput
            name="spouse_full_name"
            value={formik.values.spouse_full_name}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Ex.: Maria da Silva"
            labelValue="Nome completo cônjuge"
            validationError={
              formik.touched.spouse_full_name
                ? formik.errors.spouse_full_name
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="spouse_taxpayer_id"
            value={formatCPF(formik.values.spouse_taxpayer_id)}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="000.000.000-00"
            labelValue="CPF cônjuge"
            validationError={
              formik.touched.spouse_taxpayer_id
                ? formik.errors.spouse_taxpayer_id
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CalendarInput
            name="spouse_date_of_birth"
            labelValue="Data de nascimento cônjuge"
            placeholder="dd/mm/aaaa"
            value={formik.values.spouse_date_of_birth}
            onChange={(e) =>
              formik.handleChange({
                currentTarget: {
                  value: e,
                  name: 'spouse_date_of_birth',
                },
              })
            }
            validationError={
              formik.touched.spouse_date_of_birth
                ? formik.errors.spouse_date_of_birth
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput isFull={true}>
          <CustomSelect
            labelValue="Regime de bens"
            name="marital_property_system"
            placeholder="Selecione"
            value={formik.values.marital_property_system}
            handleInputChange={(v) => {
              formik.handleChange(v);
            }}
            options={[
              {
                value: EMaritalPropertySystem.FINAL_COMMUNION,
                label: 'Participação final nos aquestros',
              },
              {
                value: EMaritalPropertySystem.FULL_COMMUNION,
                label: 'Comunhão universal de bens',
              },
              {
                value: EMaritalPropertySystem.NO_COMMUNION,
                label: 'Separação total de bens',
              },
              {
                value: EMaritalPropertySystem.PARTIAL_COMMUNION,
                label: 'Comunhão parcial de bens',
              },
            ]}
            validationError={
              formik.touched.marital_property_system?.value
                ? formik.errors.marital_property_system?.value
                : ''
            }
          />
        </StepContentInput>
      </StepContentInputArea>
    </BusinessEditSideSheet>
  );
};

export default NaturalPersonEditSpouseSection;
