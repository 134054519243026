import {
  TMessageType,
  toast,
  toastCopy,
  toastCopyLink,
} from 'app/components/Toast/toastEventManager';

export function useSnackbar() {
  const showSnackbar = (
    message = '',
    type: TMessageType = 'info',
    duration = 5000,
  ) => {
    toast({ type, text: message, duration });
  };

  const showSnackbarCopy = () => {
    toastCopy();
  };

  const showSnackbarCopyLink = () => {
    toastCopyLink();
  };

  return { showSnackbar, showSnackbarCopy, showSnackbarCopyLink };
}
