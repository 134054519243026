import { DrawerProps, useTheme } from '@mui/material';
import { CloseIcon, TextL } from '../';
import { useState } from 'react';
import { useWindowSize } from 'app/hooks';
import {
  UserSideSheetContent,
  CloseIconWrapper,
  SideSheetHeader,
  TabsArea,
  UserViewBoxContainer,
  UserViewBoxIcon,
  UserViewBoxContent,
  UserViewBox,
  LogoutButton,
} from './styles';
import {
  AccountCircleOutlined,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  HelpOutlineOutlined,
  HttpsOutlined,
} from '@mui/icons-material';
import { useAuthContext } from 'modules/auth/context';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { BaseSidesheet } from 'celcoin-design-system';
import { CustomTabs } from '../CustomTabs';

export interface IUserSideSheet extends DrawerProps {
  handleClose: () => void;
}

const tabItems = [{ title: 'Meu Perfil', route: '/profile' }];
const helpCenterUrl =
  'https://suporte.celcoin.com.br/hc/pt-br/sections/12011728077211-Plataforma-de-Cr%C3%A9dito';

const UserSideSheet = ({ handleClose, open }: IUserSideSheet) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [currentTabValue, setCurrentTabValue] = useState(0);
  const { logout, user } = useAuthContext();
  const { showSnackbar } = useSnackbar();

  const handleResetPassword = async () => {
    const res = await logout();
    if (res) {
      navigate('/auth/password-recovery');
      return;
    } else {
      showSnackbar('Ocorreu um erro. Por favor tente novamente.');
    }
  };

  return (
    <BaseSidesheet hideBackdrop open={open} handleClose={handleClose}>
      <SideSheetHeader>
        <TabsArea>
          <CustomTabs
            route="/"
            value={currentTabValue}
            items={tabItems}
            handleChange={(value) => setCurrentTabValue(value)}
          />
        </TabsArea>
        <CloseIconWrapper onClick={handleClose}>
          <CloseIcon color={theme.palette.brand.secondary.base as string} />
        </CloseIconWrapper>
      </SideSheetHeader>
      <UserSideSheetContent
        style={{
          width:
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            width! > theme.breakpoints.values.tablet
              ? theme.spacing(75)
              : theme.spacing(50),
        }}
      >
        <UserViewBox>
          <TextL weight="bold">Dados Cadastrais</TextL>
          <UserViewBoxContainer>
            <UserViewBoxIcon>
              <AccountCircleOutlined />
            </UserViewBoxIcon>
            <UserViewBoxContent>
              <span
                style={{
                  fontWeight: 'bold',
                  display: 'block',
                  fontSize: '16px',
                }}
              >
                {user?.attributes.name}
              </span>
              <span
                style={{
                  display: 'block',
                  fontSize: '16px',
                }}
              >
                {user?.getUsername().split(':')[1]}
              </span>
            </UserViewBoxContent>
          </UserViewBoxContainer>
        </UserViewBox>
        <UserViewBox>
          <TextL weight="bold">Segurança</TextL>
          <UserViewBoxContainer onClick={handleResetPassword}>
            <UserViewBoxIcon>
              <HttpsOutlined />
            </UserViewBoxIcon>
            <UserViewBoxContent>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    display: 'block',
                    fontSize: '16px',
                    marginRight: 'auto',
                  }}
                >
                  Alterar senha
                </span>
                <ArrowForwardIosOutlined />
              </div>
            </UserViewBoxContent>
          </UserViewBoxContainer>
        </UserViewBox>
        <UserViewBox>
          <TextL weight="bold">Geral</TextL>
          <UserViewBoxContainer
            onClick={() => {
              window.open(helpCenterUrl, '_blank');
            }}
          >
            <UserViewBoxIcon>
              <HelpOutlineOutlined />
            </UserViewBoxIcon>
            <UserViewBoxContent>
              <span
                style={{
                  fontWeight: 'bold',
                  display: 'block',
                  fontSize: '16px',
                  marginRight: 'auto',
                }}
              >
                Central de Ajuda
              </span>
            </UserViewBoxContent>
          </UserViewBoxContainer>
        </UserViewBox>
        <LogoutButton onClick={() => logout()}>
          <ArrowBackIosOutlined />
          <span>Logout</span>
        </LogoutButton>
      </UserSideSheetContent>
    </BaseSidesheet>
  );
};

export default UserSideSheet;
