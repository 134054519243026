import type { FC } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Item } from './types';
import { DraggableListItem } from './DraggableListItem';
import { List } from '@mui/material';

export interface IDraggableListProps {
  items: Array<Item>;
  onRemoveItem: (_item: Item) => void;
  updateItems: (_items: Array<Item>) => void;
}

export const DraggableList: FC<IDraggableListProps> = ({
  items,
  onRemoveItem,
  updateItems,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((item) => item.id === active?.id);
      const newIndex = items.findIndex((item) => item.id === over?.id);
      updateItems(arrayMove(items, oldIndex, newIndex));
    }
  }
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <List>
          {items.map((item) => (
            <DraggableListItem
              key={item.id}
              item={item}
              onRemoveItem={() => onRemoveItem(item)}
            />
          ))}
        </List>
      </SortableContext>
    </DndContext>
  );
};

export default DraggableList;
