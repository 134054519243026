import { DashboardLayout } from '../../../app/components';
import { routingPath } from '../../../app/routes';
import { BalancePage, BankStatementPage } from '../pages';

const CustomerRoutes = () => {
  return {
    element: <DashboardLayout />,
    children: [
      {
        path: routingPath.disbursement.balance,
        element: <BalancePage />,
      },
      {
        path: routingPath.disbursement.statement,
        element: <BankStatementPage />,
      },
    ],
  };
};

export default CustomerRoutes;
