const KeyIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75804 8.828L16.607 0.979004L18.021 2.393L16.607 3.808L19.081 6.282L17.667 7.697L15.192 5.222L13.778 6.636L15.899 8.757L14.485 10.172L12.364 8.05L10.172 10.242C10.8525 11.276 11.1254 12.5256 10.938 13.7491C10.7505 14.9727 10.1161 16.0832 9.15731 16.8661C8.19853 17.649 6.98352 18.0486 5.74721 17.9876C4.51091 17.9267 3.34112 17.4094 2.46404 16.536C1.58507 15.6603 1.06307 14.4891 0.999493 13.25C0.935919 12.0109 1.33531 10.7925 2.12005 9.83142C2.90479 8.87039 4.01881 8.23544 5.2456 8.05C6.47239 7.86455 7.72431 8.14185 8.75804 8.828ZM8.12104 15.121C8.41235 14.8455 8.64547 14.5143 8.80662 14.1471C8.96776 13.7799 9.05366 13.3842 9.05924 12.9832C9.06481 12.5823 8.98995 12.1843 8.83908 11.8128C8.68821 11.4412 8.46439 11.1037 8.18085 10.8202C7.89731 10.5367 7.5598 10.3128 7.18828 10.162C6.81676 10.0111 6.41876 9.93623 6.01781 9.9418C5.61686 9.94738 5.2211 10.0333 4.85392 10.1944C4.48674 10.3556 4.15559 10.5887 3.88004 10.88C3.33356 11.4458 3.03118 12.2036 3.03802 12.9902C3.04485 13.7768 3.36036 14.5292 3.91658 15.0855C4.47281 15.6417 5.22524 15.9572 6.01183 15.964C6.79842 15.9709 7.55623 15.6685 8.12204 15.122L8.12104 15.121Z"
        fill={color}
      />
    </svg>
  );
};

export default KeyIcon;
