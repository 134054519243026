import { useState, useEffect } from 'react';
import { InfinityScroll, SearchFilter } from 'app/components';
import { TFilterItems } from 'app/components/SearchFilter/SearchFilter';
import { useDebounce } from 'app/hooks';
import {
  ListBoxContainer,
  ListBoxContent,
  ListBoxSearchContainer,
  ListBoxWrapper,
} from './styles';

type IListBoxProps = {
  children: (item: unknown) => JSX.Element;
  onSearch?: (field: string, value: string) => void;
  items: Array<unknown>;
  isLoading?: boolean;
  handleRequest?: () => void;
  filterItems?: TFilterItems[];
  noGutters?: boolean;
  fullHeight?: boolean;
  hasMoreItems?: boolean;
};

const ListBox = ({
  children,
  onSearch,
  items,
  isLoading = false,
  handleRequest,
  filterItems,
  noGutters,
  fullHeight,
  hasMoreItems,
}: IListBoxProps) => {
  const [searchText, updateSearchText] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<TFilterItems>();
  const debouncedSearchTerm = useDebounce(searchText, 500);

  const handleSelectFilterItem = (selectedFilterValue: TFilterItems) => {
    setSelectedFilter(selectedFilterValue);
  };

  const handleSearch = () => {
    if (typeof onSearch === 'function') {
      onSearch(
        selectedFilter?.parameterName || filterItems?.[0].parameterName || '',
        searchText,
      );
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm || debouncedSearchTerm === '') {
      onSearch?.(
        selectedFilter?.parameterName || filterItems?.[0].parameterName || '',
        debouncedSearchTerm,
      );
    }
  }, [debouncedSearchTerm]);

  return (
    <ListBoxWrapper noGutters={noGutters}>
      {filterItems && (
        <ListBoxSearchContainer>
          <SearchFilter
            inputValue={searchText}
            handleClearInput={() => updateSearchText('')}
            handleInputChange={(evt) => updateSearchText(evt.target.value)}
            filterItems={filterItems}
            handleSelectFilterItem={handleSelectFilterItem}
            selectedFilter={selectedFilter?.name}
            handleSearch={handleSearch}
          />
        </ListBoxSearchContainer>
      )}
      <ListBoxContainer fullHeight={fullHeight}>
        <ListBoxContent>
          {items.map((item) => children(item))}
          {hasMoreItems !== false && (
            <InfinityScroll
              isLoading={isLoading}
              onRequest={() => handleRequest?.()}
            />
          )}
        </ListBoxContent>
      </ListBoxContainer>
    </ListBoxWrapper>
  );
};

export default ListBox;
