import styled from '@emotion/styled';

export const StatusBox = styled.div`
  border: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  display: flex;
  margin-top: 32px;
  border-radius: 10px;
  width: 300px;
  padding: 15px 24px;
  align-items: center;
  span:first-of-type {
    margin-right: 32px;
  }

  span:nth-of-type(2) {
    width: 100%;
  }
`;

export const TitleArea = styled.div`
  display: flex;
  margin-top: 71px;
  align-items: center;
`;
