import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { useState } from 'react';
import { LegalPersonEditAddressSection } from '.';
import { EditWrapper, Heading3 } from '../../../../../../app/components';
import { AddressIcon } from '../../../../../../app/components/Icons';
import { formatCep } from '../../../../../../app/helpers';
import { TRegisterBusinessDataRequest } from '../../../../context';
import {
  LegalPersonViewAddressContent,
  LegalPersonViewAddressIcon,
  LegalPersonViewBoxContainer,
  LegalPersonViewPageSection,
} from './styles';
import { useTheme } from '@mui/material';

type ILegalPersonViewAddressSectionProps = {
  business: TRegisterBusinessDataRequest;
};

const LegalPersonViewAddressSection = ({
  business,
}: ILegalPersonViewAddressSectionProps) => {
  const { address } = business;
  const [isEditOpen, setIsEditOpen] = useState(false);
  const theme = useTheme();

  return (
    <>
      <LegalPersonViewPageSection>
        <Heading3
          style={{ color: theme.palette.brand.secondary.base as string }}
        >
          Endereço
        </Heading3>
        <EditWrapper
          handleClick={() => setIsEditOpen(true)}
          permission={EAccountPermissions.UPDATE_PERSON_BUSINESS}
        >
          <LegalPersonViewBoxContainer>
            <LegalPersonViewAddressIcon>
              <AddressIcon
                color={theme.palette.brand.secondary.base as string}
              />
            </LegalPersonViewAddressIcon>
            <LegalPersonViewAddressContent>
              {address ? (
                <>
                  <span
                    style={{
                      display: 'block',
                    }}
                  >{`${address?.street_name}, ${address?.street_number}, ${
                    address?.extra_info ? address.extra_info : ''
                  } `}</span>
                  <span
                    style={{
                      display: 'block',
                    }}
                  >{`${address.district} - ${address.city} - ${address.state_code}`}</span>
                  <span
                    style={{
                      display: 'block',
                    }}
                  >{`${formatCep(address.postal_code)}`}</span>
                </>
              ) : (
                '-'
              )}
            </LegalPersonViewAddressContent>
          </LegalPersonViewBoxContainer>
        </EditWrapper>
      </LegalPersonViewPageSection>
      <LegalPersonEditAddressSection
        isOpen={isEditOpen}
        handleClose={() => setIsEditOpen(false)}
        business={business}
      />
    </>
  );
};

export default LegalPersonViewAddressSection;
