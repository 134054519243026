export const initalFilters = {
  status: [
    'PENDING_SIGNATURE',
    'PENDING_QUALIFICATION',
    'PENDING_PAYMENT',
    'KYC_PROCESSING',
    'ISSUED',
    'CANCELED',
  ],
};
