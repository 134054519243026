const DownloadIcon = ({ color }: { color: string }) => (
  <svg
    width={18}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 17h18v2H0v-2Zm10-5.828L16.071 5.1l1.414 1.414L9 15 .515 6.515 1.929 5.1 8 11.17V0h2v11.172Z"
      fill={color}
    />
  </svg>
)

export default DownloadIcon;
