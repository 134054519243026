import { CognitoUser } from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';
import {
  AccountPermissionProperties,
  EAccountPermissions,
  EProfile,
} from '../context/permify/permify.interfaces';

export type User = CognitoUser & {
  attributes: Record<string, string>;
};

export type UserInfo = {
  app_id: string;
  full_name: string;
  email: string;
  roles: EProfile[];
  permissions: EAccountPermissions[];
  permission_properties: AccountPermissionProperties | null;
  issuer_id: string;
  originator_id: string;
  funding_id: string;
  employer_id: string;
  fundings: string[];
};

export class AuthService {
  static init(userPoolId: string, userPoolWebClientId: string) {
    Amplify.configure({
      Auth: {
        region: 'sa-east-1',
        identityPoolRegion: 'sa-east-1',
        userPoolId: userPoolId,
        userPoolWebClientId: userPoolWebClientId,
      },
    });
  }

  async currentAuthenticatedUser(): Promise<User | undefined> {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (e) {
      return undefined;
    }
  }

  signIn(username: string, password: string): Promise<User> {
    return Auth.signIn(username, password);
  }

  signOut() {
    return Auth.signOut();
  }

  async completeNewPassword(
    username: string,
    password: string,
    newPassword: string,
  ) {
    const user = await this.signIn(username, password);
    return Auth.completeNewPassword(user, newPassword);
  }

  async updateUserAttributes(attributes: Record<string, unknown>) {
    return await Auth.updateUserAttributes(
      await Auth.currentAuthenticatedUser(),
      attributes,
    );
  }
}

export default new AuthService();
