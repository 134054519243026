import styled from '@emotion/styled';

export const InstallmentsContentWrapper = styled.div`
`;

export const InstallmentsContentFilterArea = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      flex-direction: column;

  }

  .search-filter {
    max-width: 550px;
    width: 100%;
  }
`;

export const InstallmentsContentButtonArea = styled.div`
  min-width: 200px;
  display: flex;
  margin-left: 20px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      margin-top: 20px;
  }
`;

export const InstallmentsContentTableArea = styled.div`
  margin-top: 48px;
  position: relative;

  .MuiTableContainer-root {
    overflow-x: visible !important;
  }

  th:nth-of-type(1) {
    max-width: 30px;
  }

  td:nth-of-type(1) {
    max-width: 30px;
  }

  th:nth-of-type(2) {
    min-width: 200px;
  }

  td:nth-of-type(2) {
    min-width: 200px;
  }

  th:nth-of-type(9) {
    min-width: 160px;
  }

  td:nth-of-type(9) {
    min-width: 160px;
  }
`;

export const InstallmentsContentFiltersCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${({ theme }) => theme.palette?.brand.primary.base};
  font-size: 12px;
  line-height: 0px;
  border: 1px solid ${({ theme }) => theme.palette?.brand.primary.base};
  font-weight: 600;
  width: 26px;
  height: 26px;
  border-radius: 100px;
  margin-left: 10px;
  cursor: pointer;
  z-index: 999999;
`;
