import { useTheme, Divider } from '@mui/material';
import { useState, useMemo, useCallback } from 'react';
import { Badge, EditSideSheet } from 'app/components';
import { Accordion } from 'app/components/Accordion';
import {
  CalendarInput,
  CustomSwitchIOS,
  TextL,
  TextM,
} from 'celcoin-design-system';
import {
  InstallmentsFilterRepaymentIcon,
  InstallmentsFilterStatusIcon,
  InstallmentsFiltersDueIcon,
} from 'app/components/Icons';
import {
  InstallmentsFilterAccordionTitle,
  InstallmentsFilterInput,
  InstallmentsFilterInputInvalid,
  InstallmentsFilterInputs,
  InstallmentsFilterStatusElement,
} from './styles';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { initalFilters } from 'modules/charges/utils/filters';

const statusItems = [
  {
    label: 'Pago',
    key: 'paid',
  },
  {
    label: 'Vencida',
    key: 'over_due',
  },
  {
    label: 'Vence hoje',
    key: 'due_today',
  },
  {
    label: 'A vencer',
    key: 'due',
  },
  {
    label: 'Parcialmente paga',
    key: 'partially_paid',
  },
];

export type TFilterStatus =
  | 'due'
  | 'paid'
  | 'partially_paid'
  | 'due_today'
  | 'over_due';

export type TFilters = {
  status?: TFilterStatus[];
  minDueDate?: string;
  maxDueDate?: string;
  minRepaymentDate?: string;
  maxRepaymentDate?: string;
};

export interface IInstallmentsFilters {
  handleClose: () => void;
  handleApply: (filters: TFilters) => void;
  isLoading: boolean;
  isOpen: boolean;
  filters: TFilters;
}

const InstallmentsFilters = ({
  handleClose,
  handleApply,
  isOpen,
  isLoading,
  filters,
}: IInstallmentsFilters) => {
  const theme = useTheme();

  const {
    status: initialStatus,
    minDueDate: initialMinDueDate,
    maxDueDate: initialMaxDueDate,
    maxRepaymentDate: initialMaxRepaymentDate,
    minRepaymentDate: initialMinRepaymentDate,
  } = filters;

  const [isStatusAccordionOpen, toggleStatusAccordionOpen] = useState<boolean>(
    initialStatus?.length !== 5,
  );
  const [isDueDateAccordionOpen, toggleDueDateAccordionOpen] =
    useState<boolean>(!!initialMinDueDate || !!initialMaxDueDate);
  const [isRepaymentDateAccordionOpen, toggleRepaymentDateAccordionOpen] =
    useState<boolean>(!!initialMaxRepaymentDate || !!initialMinRepaymentDate);

  const [filtersIn, updateFiltersIn] = useState<TFilters>(filters);

  const onClose = () => {
    handleClose();
  };

  const onSave = () => {
    handleApply(filtersIn);
  };

  const onClear = () => {
    updateFiltersIn(initalFilters as TFilters);
  };

  const onSelectStatus = useCallback(
    (statusKey: TFilterStatus, isSelected: boolean) => {
      updateFiltersIn({
        ...filtersIn,
        status: isSelected
          ? filtersIn.status?.filter((status) => status !== statusKey)
          : filtersIn.status?.concat(statusKey),
      });
    },
    [filtersIn],
  );

  const onChangeDate = useCallback(
    (field: keyof TFilters) => (date: Date | null) => {
      try {
        updateFiltersIn({
          ...filtersIn,
          [field]: date?.toISOString(),
        });
      } catch {
        updateFiltersIn({
          ...filtersIn,
          [field]: undefined,
        });
      }
    },
    [filtersIn],
  );

  const isDueDateInvalid = useMemo(() => {
    if (!filtersIn.minDueDate || !filtersIn.maxDueDate) return false;

    if (new Date(filtersIn.minDueDate) > new Date(filtersIn.maxDueDate))
      return 'Datas selecionadas inválidas';

    return false;
  }, [filtersIn]);

  const isRepaymentDateInvalid = useMemo(() => {
    if (!filtersIn.minRepaymentDate || !filtersIn.maxRepaymentDate)
      return false;

    if (
      new Date(filtersIn.minRepaymentDate) > new Date() ||
      new Date(filtersIn.maxRepaymentDate) > new Date() ||
      new Date(filtersIn.minRepaymentDate) >
        new Date(filtersIn.maxRepaymentDate)
    )
      return 'Datas selecionadas inválidas';

    return false;
  }, [filtersIn]);

  const dueDateBadge = useMemo(() => {
    const { minDueDate, maxDueDate } = filtersIn;
    if (!minDueDate && maxDueDate) {
      return `Até: ${formatDateFromBackend(maxDueDate).toLocaleDateString(
        'pt-br',
      )}`;
    }
    if (minDueDate && !maxDueDate) {
      return `Desde: ${formatDateFromBackend(minDueDate).toLocaleDateString(
        'pt-br',
      )}`;
    }

    if (minDueDate && maxDueDate) {
      return `${formatDateFromBackend(minDueDate).toLocaleDateString(
        'pt-br',
      )} - ${formatDateFromBackend(maxDueDate).toLocaleDateString('pt-br')}`;
    }

    return 'Todos';
  }, [filtersIn]);

  const repaymentDateBadge = useMemo(() => {
    const { minRepaymentDate, maxRepaymentDate } = filtersIn;
    if (!minRepaymentDate && maxRepaymentDate) {
      return `Até: ${formatDateFromBackend(maxRepaymentDate).toLocaleDateString(
        'pt-br',
      )}`;
    }
    if (minRepaymentDate && !maxRepaymentDate) {
      return `Desde: ${formatDateFromBackend(
        minRepaymentDate,
      ).toLocaleDateString('pt-br')}`;
    }

    if (minRepaymentDate && maxRepaymentDate) {
      return `${formatDateFromBackend(minRepaymentDate).toLocaleDateString(
        'pt-br',
      )} - ${formatDateFromBackend(maxRepaymentDate).toLocaleDateString(
        'pt-br',
      )}`;
    }

    return 'Todos';
  }, [filtersIn]);

  return (
    <EditSideSheet
      open={isOpen}
      handleSave={onSave}
      isLoading={isLoading}
      isDisabled={!!isDueDateInvalid || !!isRepaymentDateInvalid}
      handleClose={onClose}
      handleClear={onClear}
      title="Filtros"
    >
      <Accordion
        isOpen={isStatusAccordionOpen}
        title={
          <InstallmentsFilterAccordionTitle>
            <InstallmentsFilterStatusIcon
              color={theme.palette.brand.primary.base as string}
            />
            <TextL
              style={{ paddingLeft: '10px', paddingTop: '2px' }}
              weight="medium"
            >
              Status
            </TextL>
            <Badge
              style={{
                width: 'auto',
                marginRight: '10px',
                opacity: 0.7,
                marginLeft: 'auto',
              }}
              typeVariant="info"
            >
              {filtersIn.status?.length === 5
                ? 'Todos'
                : `Selecionados: ${filtersIn.status?.length}`}
            </Badge>
          </InstallmentsFilterAccordionTitle>
        }
        onClick={() => toggleStatusAccordionOpen(!isStatusAccordionOpen)}
      >
        {statusItems.map((status, index) => {
          const isSelected =
            !filtersIn.status ||
            filtersIn.status.includes(status.key as TFilterStatus);
          return (
            <div key={status.label}>
              {index > 0 && <Divider />}
              <InstallmentsFilterStatusElement>
                <TextM>{status.label}</TextM>
                <CustomSwitchIOS
                  checked={isSelected}
                  onClick={() =>
                    onSelectStatus(status.key as TFilterStatus, isSelected)
                  }
                />
              </InstallmentsFilterStatusElement>
            </div>
          );
        })}
      </Accordion>

      <Accordion
        isOpen={isDueDateAccordionOpen}
        style={{ marginTop: '15px' }}
        title={
          <InstallmentsFilterAccordionTitle>
            <InstallmentsFiltersDueIcon
              color={theme.palette.brand.primary.base as string}
            />
            <TextL
              style={{ paddingLeft: '10px', paddingTop: '2px' }}
              weight="medium"
            >
              Vencimento
            </TextL>
            <Badge
              style={{
                width: 'auto',
                marginRight: '10px',
                opacity: 0.7,
                marginLeft: 'auto',
              }}
              typeVariant="info"
            >
              {dueDateBadge}
            </Badge>
          </InstallmentsFilterAccordionTitle>
        }
        onClick={() => toggleDueDateAccordionOpen(!isDueDateAccordionOpen)}
      >
        <>
          <InstallmentsFilterInputs>
            <InstallmentsFilterInput>
              <CalendarInput
                name="minDueDate"
                labelValue=""
                placeholder="dd/mm/aaaa"
                value={filtersIn.minDueDate ?? null}
                onChange={onChangeDate('minDueDate')}
                validationError={''}
              />
            </InstallmentsFilterInput>
            <InstallmentsFilterInput>
              <CalendarInput
                name="maxDueDate"
                labelValue=""
                placeholder="dd/mm/aaaa"
                value={filtersIn.maxDueDate ?? null}
                onChange={onChangeDate('maxDueDate')}
                validationError={''}
              />
            </InstallmentsFilterInput>
          </InstallmentsFilterInputs>
          {isDueDateInvalid !== false && (
            <InstallmentsFilterInputInvalid>
              {isDueDateInvalid}
            </InstallmentsFilterInputInvalid>
          )}
        </>
      </Accordion>

      <Accordion
        isOpen={isRepaymentDateAccordionOpen}
        style={{ marginTop: '15px' }}
        title={
          <InstallmentsFilterAccordionTitle>
            <InstallmentsFilterRepaymentIcon
              color={theme.palette.brand.primary.base as string}
            />
            <TextL
              style={{ paddingLeft: '10px', paddingTop: '2px' }}
              weight="medium"
            >
              Data de pagamento
            </TextL>
            <Badge
              style={{
                width: 'auto',
                marginRight: '10px',
                opacity: 0.7,
                marginLeft: 'auto',
              }}
              typeVariant="info"
            >
              {repaymentDateBadge}
            </Badge>
          </InstallmentsFilterAccordionTitle>
        }
        onClick={() =>
          toggleRepaymentDateAccordionOpen(!isRepaymentDateAccordionOpen)
        }
      >
        <>
          <InstallmentsFilterInputs>
            <InstallmentsFilterInput>
              <CalendarInput
                name="minRepaymentDate"
                labelValue=""
                placeholder="dd/mm/aaaa"
                value={filtersIn.minRepaymentDate ?? null}
                onChange={onChangeDate('minRepaymentDate')}
                validationError={''}
              />
            </InstallmentsFilterInput>
            <InstallmentsFilterInput>
              <CalendarInput
                name="maxRepaymentDate"
                labelValue=""
                placeholder="dd/mm/aaaa"
                value={filtersIn.maxRepaymentDate ?? null}
                onChange={onChangeDate('maxRepaymentDate')}
                validationError={''}
              />
            </InstallmentsFilterInput>
          </InstallmentsFilterInputs>
          {isRepaymentDateInvalid !== false && (
            <InstallmentsFilterInputInvalid>
              {isRepaymentDateInvalid}
            </InstallmentsFilterInputInvalid>
          )}
        </>
      </Accordion>
    </EditSideSheet>
  );
};

export default InstallmentsFilters;
