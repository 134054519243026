import { usePermify } from 'modules/auth/context';
import { EPermission } from 'modules/auth/context/permify/permify.interfaces';
import { SimulationButton } from './SimulationButton';
import { FButtonsContainer } from './styles';
import { useInstallmentsCart } from 'modules/charges/contexts/InstallmentsCartContext';

export interface IFButtons {
  handleClickSimulator: () => void;
}

const FButtons = ({ handleClickSimulator }: IFButtons) => {
  const { isAuthorized } = usePermify();
  const { installments } = useInstallmentsCart();

  return (
    <FButtonsContainer
      style={{
        bottom: installments.length > 0 ? '100px' : '50px',
      }}
    >
      {isAuthorized(EPermission.SIMULATION_OPEN) && (
        <SimulationButton handleClick={handleClickSimulator} />
      )}
    </FButtonsContainer>
  );
};

export default FButtons;
