import { BaseHttpService } from '../../../../app/services/baseHttpService';

export class BusinessFileHttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  uploadFilesToBusiness = async (data: FormData, businessId: string) => {
    const res = await this.post(
      `${this.BASE_URL}/originator/business/${businessId}/documents`,
      data,
    );
    return res;
  };

  getFilesByBusiness = async (businessId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/business/${businessId}/documents`,
    );
    return res;
  };

  deleteFilesByBusiness = async (businessId: string, fileId: string) => {
    const res = await this.delete(
      `${this.BASE_URL}/originator/business/${businessId}/documents/${fileId}`,
    );
    return res;
  };

  downloadBusinessFile = async (businessId: string, fileId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/business/${businessId}/documents/${fileId}/file`,
    );
    return res;
  };
}
