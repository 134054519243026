const EditIcon = ({ color }: { color: string }) => (
  <svg
    width={18}
    height={19}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.728 7.18608L11.314 5.77208L2 15.0861L2 16.5001H3.414L12.728 7.18608ZM14.142 5.77208L15.556 4.35808L14.142 2.94408L12.728 4.35808L14.142 5.77208ZM4.242 18.5001H0L0 14.2571L13.435 0.822083C13.6225 0.634612 13.8768 0.529297 14.142 0.529297C14.4072 0.529297 14.6615 0.634612 14.849 0.822083L17.678 3.65108C17.8655 3.83861 17.9708 4.09292 17.9708 4.35808C17.9708 4.62325 17.8655 4.87756 17.678 5.06508L4.243 18.5001H4.242Z"
      fill={color}
    />
  </svg>
);

export default EditIcon;
