import { useFormik } from 'formik';
import { useContext, useState, useCallback } from 'react';
import { useTheme } from '@mui/material';
import { ConfirmModal, Heading4 } from '../../../../../../app/components';
import { BusinessEditSideSheet } from '../../../../components/BusinessEditSideSheet';
import { TRegisterBusinessDataRequest } from '../../../../context/CustomerProvider/customer.interfaces';
import { bankStepSchema } from '../../../../helpers/businessValidation';
import { LegalPersonViewPageContext } from '../../LegalPersonViewPage';
import { StepContentInput, StepContentInputArea } from '../styles';
import {
  ACCOUNT_TYPE_ARRAY,
  ACCOUNT_TYPE_OBJECT,
  BANK_LIST_ARRAY,
  BANK_LIST_OBJECT,
} from '../../../../../../app/helpers';
import { formatBankAccount } from 'app/utils/numbers';
import { CustomSelect, CustomInput } from 'celcoin-design-system';
import { AttentionCircularRedIcon } from 'app/components/Icons/AttentionCircularIcon';

export interface ILegalPersonEditBankSection {
  business: TRegisterBusinessDataRequest;
  handleClose: () => void;
  isOpen: boolean;
}

const LegalPersonEditBankSection = ({
  business,
  handleClose,
  isOpen,
}: ILegalPersonEditBankSection) => {
  const theme = useTheme();
  const [isConfirmModalOpen, toggleConfirmModal] = useState(false);
  const { updateBusiness, isLoading } = useContext(LegalPersonViewPageContext);
  const { external_bank_account } = business;

  const onConfirmAction = useCallback(() => {
    toggleConfirmModal(false);
    updateBusiness({
      ...business,
      external_bank_account: undefined,
    }).then(() => {
      onClose();
    });
  }, [business]);

  const formik = useFormik({
    initialValues: {
      ispb_code: external_bank_account?.ispb_code
        ? {
            value: external_bank_account?.ispb_code,
            label: BANK_LIST_OBJECT[external_bank_account?.ispb_code as string],
          }
        : undefined,
      bank_account: external_bank_account?.bank_account
        ? formatBankAccount(
            `${external_bank_account?.bank_account?.padStart(7, '0')}${
              external_bank_account?.bank_account_digit
            }`,
          )
        : '',
      bank_branch: external_bank_account?.bank_branch ?? '',
      bank_account_type: external_bank_account?.bank_account_type?.length
        ? {
            value: external_bank_account?.bank_account_type,
            label:
              ACCOUNT_TYPE_OBJECT[
                external_bank_account?.bank_account_type as string
              ],
          }
        : undefined,
    },
    validationSchema: bankStepSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const bank_account = values.bank_account.trim().replace(/[^\w\s]/gi, '');
      const businessData: TRegisterBusinessDataRequest = {
        ...business,
        external_bank_account:
          typeof values.ispb_code?.value === 'string' &&
          values.ispb_code?.value.length > 0
            ? {
                ...(business.external_bank_account || {}),
                bank_code: undefined,
                ispb_code: values.ispb_code?.value as string,
                bank_account: bank_account.slice(0, bank_account.length - 1),
                bank_account_digit: bank_account.slice(
                  bank_account.length - 1,
                  bank_account.length,
                ),
                bank_branch: values.bank_branch.trim(),
                bank_account_type: values.bank_account_type?.value as string,
              }
            : undefined,
      };
      updateBusiness(businessData).then(() => {
        onClose();
      });
    },
  });

  const onClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <BusinessEditSideSheet
      open={isOpen}
      handleClose={onClose}
      handleSave={formik.submitForm}
      isRemoveDisabled={
        typeof external_bank_account?.ispb_code !== 'string' ||
        external_bank_account?.ispb_code.length === 0
      }
      handleRemove={() => toggleConfirmModal(true)}
      isLoading={isLoading}
      title="Dados bancários"
    >
      <Heading4
        fontWeight="B"
        style={{
          color: theme.palette.brand.primary.base,
          marginBottom: '32px',
        }}
      >
        Conta
      </Heading4>
      <StepContentInputArea>
        <StepContentInput isFull={true}>
          <CustomSelect
            labelValue="Banco"
            name="ispb_code"
            placeholder="Selecione o banco"
            value={formik.values.ispb_code}
            handleInputChange={formik.handleChange}
            options={BANK_LIST_ARRAY}
            validationError={
              formik.touched?.ispb_code ? formik.errors?.ispb_code : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="bank_branch"
            value={formik.values.bank_branch}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="0000"
            labelValue="Agência"
            validationError={
              formik.touched.bank_branch ? formik.errors.bank_branch : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="bank_account"
            value={formatBankAccount(formik.values.bank_account)}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="000000-0"
            labelValue="Conta"
            validationError={
              formik.touched.bank_account ? formik.errors.bank_account : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Tipo de conta"
            name="bank_account_type"
            placeholder="Selecione o tipo"
            value={formik.values.bank_account_type}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={ACCOUNT_TYPE_ARRAY}
            validationError={
              formik.touched?.bank_account_type
                ? formik.errors?.bank_account_type
                : ''
            }
          />
        </StepContentInput>
      </StepContentInputArea>
      <ConfirmModal
        isColumnButtons={true}
        isOpen={isConfirmModalOpen}
        icon={<AttentionCircularRedIcon />}
        title="Os dados bancários serão excluídos. Você tem certeza?"
        btnConfirmText="Confirmar"
        color="danger"
        handleConfirm={onConfirmAction}
        handleClose={() => toggleConfirmModal(false)}
      >
        {`Esta ação é irreversível. Solicitações em aberto para esta empresa serão pagas com os dados bancários presentes no cadastro no momento em que a assinatura das partes for finalizada.`}
      </ConfirmModal>
    </BusinessEditSideSheet>
  );
};

export default LegalPersonEditBankSection;
