import styled from '@emotion/styled';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    height: 100%;
    justify-content: space-between;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`;

export const ModalContent = styled.div`
    gap: 30px;
    margin-top: 14px;

    .beneficiary-account-bank {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 30px;
`;

export const ViewBoxContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  border-radius: 10px;
  margin-bottom: 48px;
  width: 100%;
`;

export const ViewBoxContent = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  &:first-of-type {
    border-top: unset;
  }
`;

export const ViewBoxItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-left: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  padding: 12px 24px;
  span {
    display: block;
    &:first-of-type {
      min-width: max-content;
    }
    &:nth-of-type(2) {
      max-width: 70%;
      text-align: right;
    }
  }

  &:first-of-type {
    border-left: unset;
  }
`;

export const Content = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
`;
