const AttentionCircularIcon = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21" cy="21" r="21" fill="#F97800" />
    <path
      d="M18.4414 11.5624C18.4054 10.9867 18.8626 10.5 19.4395 10.5H22.5605C23.1374 10.5 23.5946 10.9867 23.5586 11.5624L22.8086 23.5624C22.7757 24.0894 22.3386 24.5 21.8105 24.5H20.1895C19.6614 24.5 19.2243 24.0894 19.1914 23.5624L18.4414 11.5624Z"
      fill="#FDFDFD"
    />
    <circle cx="21" cy="28.875" r="2.625" fill="#FDFDFD" />
  </svg>
);

export const AttentionCircularRedIcon = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21" cy="21" r="21" fill="#F50404" />
    <path
      d="M18.4414 11.5624C18.4054 10.9867 18.8626 10.5 19.4395 10.5H22.5605C23.1374 10.5 23.5946 10.9867 23.5586 11.5624L22.8086 23.5624C22.7757 24.0894 22.3386 24.5 21.8105 24.5H20.1895C19.6614 24.5 19.2243 24.0894 19.1914 23.5624L18.4414 11.5624Z"
      fill="#FDFDFD"
    />
    <circle cx="21" cy="28.875" r="2.625" fill="#FDFDFD" />
  </svg>
);


export default AttentionCircularIcon;
