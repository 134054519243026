import { TFile } from '../context';

export const fileTypes: { [key in TFile]: string } = {
  BALANCE_SHEETS: 'Balanço',
  INCOME_STATEMENT: 'Comprovante de Renda',
  PROOF_OF_ADDRESS: 'Comprovante de Endereço',
  BYLAWS: 'Estatuto Social',
  INCORPORATION_CERTIFICATE: 'Cartão CNPJ',
  FEDERAL_REGISTER_CERTIFICATE: 'Documento de Identidade',
  INVOICE: 'Comprovante',
  CERTIFICATE: 'Certidão',
  LETTER_OF_ATTORNEY: 'Procuração',
  CONTRACT: 'Contrato',
  OTHER: 'Outros',
};

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const MimeTypeToExtension: { [key: string]: string } = {
  'image/jpeg': 'jpeg',
  'image/png': 'png',
  'application/pdf': 'pdf',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
};
