const UploadIcon = ({ color }: { color: string }) => (
  <svg
    width={20}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.95h18c.552 0 1-.437 1-.975a.984.984 0 0 0-.864-.966L19 0H1C.448 0 0 .436 0 .975c0 .493.376.901.864.966L1 1.95ZM9.846 25.99l.156.009c.683 0 1.247-.502 1.324-1.148l.01-.152V8.337l4.392 4.28a1.36 1.36 0 0 0 1.76.107l.126-.108c.48-.468.517-1.205.11-1.715l-.11-.123-6.665-6.494a1.36 1.36 0 0 0-1.76-.108l-.125.108-6.672 6.494a1.276 1.276 0 0 0-.002 1.838 1.36 1.36 0 0 0 1.76.11l.126-.109 4.392-4.274V24.7c0 .667.515 1.216 1.178 1.291Z"
      fill={color}
    />
  </svg>
)

export default UploadIcon
