import styled from '@emotion/styled';

export const NoItemsRegisteredContainer = styled.div`
  width: 100%;
  height: 100%;  
  display: flex;
  flex: 1;
  align-items: center;
  align-self: center;
`;
