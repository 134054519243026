import { useEffect } from 'react';
import { useBreadcrumbsContext } from '../../../../app/context';
import { ContractDetailsContainer } from './styles';
import { useParams } from 'react-router-dom';
import { HandCoinIcon } from 'app/components/Icons';
import { ContractsDetails } from 'modules/charges/components/ContractsDetails';
import { routingPath } from 'app/routes';

const ContractDetails = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const params = useParams();

  useEffect(() => {
    setItems(
      [
        {
          to: `/products/detail/${params.productId}/contracts?page=0`,
          label: 'Contratos',
        },
        {
          to: `charges/contract/${params.id}/details`,
          label: 'Detalhes',
        },
      ],
      <HandCoinIcon color="#424242" />,
    );

    return () => resetBreadcrumbs();
  }, []);

  return (
    <ContractDetailsContainer>
      <ContractsDetails
        contractId={params.id as string}
        route={routingPath.products.contractsDetails}
      />
    </ContractDetailsContainer>
  );
};

export default ContractDetails;
