const BoxIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.57102 0.237371C8.83738 0.0875429 9.16262 0.0875429 9.42898 0.237371L17.429 4.73737C17.7045 4.89235 17.875 5.18389 17.875 5.5V14.5C17.875 14.8161 17.7045 15.1076 17.429 15.2626L9.42898 19.7626C9.16262 19.9125 8.83738 19.9125 8.57102 19.7626L0.571021 15.2626C0.295504 15.1076 0.125 14.8161 0.125 14.5V5.5C0.125 5.18389 0.295504 4.89235 0.571021 4.73737L8.57102 0.237371ZM1.875 6.01174V13.9883L9 17.9961L16.125 13.9883V6.01174L9 2.00393L1.875 6.01174Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7627 5.07105C17.9996 5.49224 17.8502 6.02574 17.429 6.26266L9.42901 10.7627C9.00782 10.9996 8.47432 10.8502 8.2374 10.429C8.00049 10.0078 8.14987 9.47432 8.57105 9.2374L16.5711 4.7374C16.9922 4.50049 17.5257 4.64987 17.7627 5.07105Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 9.125C9.48325 9.125 9.875 9.51675 9.875 10V19C9.875 19.4832 9.48325 19.875 9 19.875C8.51675 19.875 8.125 19.4832 8.125 19V10C8.125 9.51675 8.51675 9.125 9 9.125Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.237405 5.07105C0.474323 4.64987 1.00782 4.50049 1.42901 4.7374L9.42901 9.2374C9.8502 9.47432 9.99958 10.0078 9.76266 10.429C9.52574 10.8502 8.99224 10.9996 8.57105 10.7627L0.571055 6.26266C0.149866 6.02574 0.000486188 5.49224 0.237405 5.07105Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7627 2.82105C13.9996 3.24224 13.8502 3.77574 13.429 4.01266L5.42901 8.51266C5.00782 8.74958 4.47432 8.6002 4.2374 8.17901C4.00049 7.75782 4.14987 7.22432 4.57105 6.9874L12.5711 2.4874C12.9922 2.25049 13.5257 2.39987 13.7627 2.82105Z"
        fill={color}
      />
    </svg>
  );
};

export default BoxIcon;
