import { ProductSideSheet } from 'modules/products/components/ProductSideSheet';
import { PersonContent, RegisterContent } from './components';
import { TProducts } from 'modules/products/context';
import {} from 'modules/products/services/hooks/useApplicationsService';
import { TCustomSelectOption } from 'app/components/CustomTableSelect/CustomTableSelect';
import {
  TQualification,
  TQualificationWithStatusAndProduct,
} from 'modules/customer/interfaces/qualifications';

export interface INaturalPersonViewQualificationsSideSheetProps {
  handleClose: () => void;
  handleEditCondition: (
    qualification: TQualificationWithStatusAndProduct | undefined,
  ) => void;
  isOpen: boolean;
  qualification?: TQualificationWithStatusAndProduct | undefined;
  productInfo?: TProducts | undefined;
  statusList: TCustomSelectOption[];
  isStatusOptionsLoading: string | boolean;
  borrowerQualificationData?: TQualification;
  handleUpdateQualificationStatus: (
    qualification: TQualificationWithStatusAndProduct,
    identifier: string,
  ) => void;
}

const NaturalPersonViewQualificationsSideSheet = ({
  handleClose,
  handleUpdateQualificationStatus,
  handleEditCondition,
  isOpen,
  qualification,
  productInfo,
  statusList,
  borrowerQualificationData,
  isStatusOptionsLoading,
}: INaturalPersonViewQualificationsSideSheetProps) => {
  const onClose = () => {
    handleClose();
  };

  return (
    <ProductSideSheet
      open={isOpen}
      handleClose={onClose}
      title={qualification?.borrower.name || ''}
    >
      <PersonContent
        borrowerQualificationData={borrowerQualificationData}
        selectedQualification={qualification}
        product={productInfo}
        statusOptions={statusList}
        handleUpdateQualificationStatus={handleUpdateQualificationStatus}
        isStatusOptionsLoading={isStatusOptionsLoading}
        handleEditCondition={handleEditCondition}
      />
      <RegisterContent product={productInfo} />
    </ProductSideSheet>
  );
};

export default NaturalPersonViewQualificationsSideSheet;
