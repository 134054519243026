import { useState } from 'react';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { FundingsHttpService } from '../api';
import { IFunding, IList } from './intefaces';

const useFundingsService = () => {
  const [getFundingsLoading, setGetFundingsLoading] = useState(false);
  const [getFundingLoading, setGetFundingLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const getFundings = async (
    fundingIds: string[],
  ): Promise<IList<IFunding> | undefined> => {
    setGetFundingsLoading(true);
    try {
      const fundingsService = new FundingsHttpService();
      const res = await fundingsService.getFundings(fundingIds);
      return res.data;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar os fundings');
    } finally {
      setGetFundingsLoading(false);
    }
  };

  const getFunding = async (
    fundingId: string,
  ): Promise<IFunding | undefined> => {
    setGetFundingLoading(true);
    try {
      const fundingsService = new FundingsHttpService();
      const res = await fundingsService.getFunding(fundingId);
      return res.data;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar os fundings');
    } finally {
      setGetFundingLoading(false);
    }
  };

  return {
    getFundingsLoading,
    getFundings,
    getFundingLoading,
    getFunding,
  };
};

export default useFundingsService;
