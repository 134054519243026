import { useSnackbar } from 'app/hooks/useSnackbar';
import { CustomInput } from 'celcoin-design-system';
import { useFormik } from 'formik';
import { useAuthContext } from 'modules/auth/context';
import { newPasswordSchema } from 'modules/auth/helpers/validationSchema';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ButtonPrimary,
  FormUnderline,
  Heading3,
  Spinner,
} from '../../../../../../app/components';
import { routingPath } from '../../../../../../app/routes';
import { AuthFormContainer, PasswordStrength } from '../../../../components';
import { ButtonArea, HeadTextArea, InputArea, InputItem } from './styles';

const NewPasswordForm = () => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const { showSnackbar } = useSnackbar();
  const { completeAccess } = useAuthContext();
  const [isLoading, toggleIsLoading] = useState(false);
  const navigate = useNavigate();
  const [passwordStrength, onChangePasswordStrength] = useState<number>();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: newPasswordSchema,
    onSubmit: (values) => {
      toggleIsLoading(true);
      completeAccess(values.email, values.password)
        .then((res) => {
          if (res) {
            showSnackbar('Senha cadastrada com sucesso!');
            setTimeout(() => navigate(`/${routingPath.auth.login}`), 100);
          } else {
            showSnackbar(
              'Ocorreu um problema ao cadastrar a senha, tente novamente',
              'error',
            );
          }
        })
        .finally(() => toggleIsLoading(false));
    },
  });

  return (
    <>
      <AuthFormContainer ref={anchorRef}>
        <HeadTextArea>
          <Heading3 fontWeight="B">Nova senha</Heading3>
        </HeadTextArea>
        <InputArea>
          <InputItem>
            <CustomInput
              name="email"
              value={formik.values.email}
              handleInputChange={formik.handleChange}
              placeholder="Digite seu email"
              onBlur={formik.handleBlur}
              labelValue="EMAIL"
              validationError={formik.touched.email ? formik.errors.email : ''}
            />
          </InputItem>
          <InputItem>
            <CustomInput
              name="password"
              value={formik.values.password}
              type="password"
              placeholder="Digite sua senha"
              onBlur={formik.handleBlur}
              handleInputChange={formik.handleChange}
              labelValue="SENHA"
              validationError={
                formik.touched.password ? formik.errors.password : ''
              }
            />
          </InputItem>
          <InputItem>
            <CustomInput
              name="confirmPassword"
              value={formik.values.confirmPassword}
              type="password"
              placeholder="Digite sua senha"
              handleInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              labelValue="REPETIR SENHA"
              validationError={
                formik.touched.confirmPassword
                  ? formik.errors.confirmPassword
                  : ''
              }
            />
          </InputItem>
          <FormUnderline to={routingPath.auth.login}>
            Voltar para o login
          </FormUnderline>
          <ButtonArea>
            <ButtonPrimary
              disabled={!passwordStrength || passwordStrength < 3 || isLoading}
              onClick={formik.submitForm}
            >
              {isLoading ? <Spinner color="#fff" size={20} /> : 'Confirmar'}
            </ButtonPrimary>
          </ButtonArea>
        </InputArea>
      </AuthFormContainer>
      <PasswordStrength
        onChangePasswordStrength={(s) => onChangePasswordStrength(s)}
        password={formik.values.password}
        anchorRef={anchorRef}
      />
    </>
  );
};

export default NewPasswordForm;
