import { useNavigate } from 'react-router-dom';

const useCustomNavigation = () => {
  const nav = useNavigate();
  const navigate = (to: string) => {
    nav(`/${to}`);
  };

  return { navigate };
};

export default useCustomNavigation;
