import { EIncorporationType, ETaxRegime } from './customer.interfaces';

export const TaxRegimeObject = {
  [ETaxRegime.SIMPLE]: 'Simples Nacional',
  [ETaxRegime.REAL_PROFIT]: 'Lucro real',
  [ETaxRegime.PRESUMED_PROFIT]: 'Lucro presumido',
};

export const IncorporationTypeObject = {
  [EIncorporationType.EIRELI]: 'EIRELI',
  [EIncorporationType.LTDA]: 'LTDA',
  [EIncorporationType.MEI]: 'MEI',
  [EIncorporationType.SA]: 'SA',
  [EIncorporationType.SS]: 'SS',
};
