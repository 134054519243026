import { useState, useMemo, useCallback, useEffect, ReactNode } from 'react';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { useTheme, Checkbox } from '@mui/material';
import {
  InstallmentsContentButtonArea,
  InstallmentsContentFilterArea,
  InstallmentsContentFiltersCount,
  InstallmentsContentTableArea,
  InstallmentsContentWrapper,
} from './styles';
import { ButtonPrimary } from 'app/components';
import {
  EmptyAccessImage,
  InstallmentsDueIcon,
  InstallmentsDueTodayIcon,
  InstallmentsFiltersIcon,
  InstallmentsOverdueIcon,
  InstallmentsPaidIcon,
} from 'app/components/Icons';
import { TextM } from 'celcoin-design-system';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { EmptyArea } from 'modules/management/components';
import { useParams } from 'react-router-dom';
import { useAuthContext } from 'modules/auth/context';
import { InstallmentItem } from 'modules/products/context/DebitCollections/intefaces';
import InstallmentsFilters, {
  TFilters,
} from '../InstallmentsFilters/InstallmentsFilters';
import useInstallments from 'modules/charges/hooks/useInstallments';
import { TBodyContentCell, TTableHeadCell, Table } from 'app/components/Table';
import { initalFilters } from 'modules/charges/utils/filters';
import InstallmentsCart from '../InstallmentsCart/InstallmentsCart';
import { useInstallmentsCart } from 'modules/charges/contexts/InstallmentsCartContext';
import { useSnackbar } from 'app/hooks/useSnackbar';

export const STATUS = {
  due: {
    color: 'info',
    text: 'A vencer',
    icon: <InstallmentsDueIcon />,
  },
  paid: {
    color: 'success',
    text: 'Paga',
    icon: <InstallmentsPaidIcon />,
  },
  partially_paid: {
    color: 'attention',
    text: 'Parcialmente paga',
    icon: <InstallmentsDueTodayIcon />,
  },
  due_today: {
    color: 'attention',
    text: 'Vence hoje',
    icon: <InstallmentsDueTodayIcon />,
  },
  over_due: {
    color: 'error',
    text: 'Vencida',
    icon: <InstallmentsOverdueIcon />,
  },
};

export interface IInstallmentsTable {
  productId?: string;
  taxpayerId?: string;
  contractId?: number;
  isLoading?: boolean;
  hideFilters?: boolean;
  headers: TTableHeadCell[];
  onRenderSearch?: () => ReactNode;
  onRenderItem: (
    item: InstallmentItem,
    isDisabled: boolean,
  ) => TBodyContentCell[];
}
const InstallmentsTable = ({
  productId,
  taxpayerId,
  contractId,
  headers,
  isLoading,
  hideFilters,
  onRenderSearch,
  onRenderItem,
}: IInstallmentsTable) => {
  const theme = useTheme();
  const params = useParams();
  const [installmentsList, setInstallmentsList] = useState<InstallmentItem[]>(
    [],
  );
  const [filters, updateFilters] = useState<TFilters>(
    initalFilters as TFilters,
  );
  const [isFiltersOpened, toggleFiltersOpened] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const {
    installments,
    onRemoveInstallment,
    onRemoveInstallments,
    onAddInstallment,
    onAddInstallments,
    onCleanCart,
  } = useInstallmentsCart();
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const { userInfo } = useAuthContext();
  const { getInstallments, getInstallmentsLoading } = useInstallments();
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );

  const checkedItems = useMemo(() => {
    return installments.map((installment) => installment.id);
  }, [installments]);

  const getInstallmentsContent = useCallback(() => {
    if (userInfo) {
      const minDueDate =
        typeof filters.minDueDate === 'string' &&
        filters.minDueDate.includes('T')
          ? filters.minDueDate.split('T')[0]
          : undefined;
      const maxDueDate =
        typeof filters.maxDueDate === 'string' &&
        filters.maxDueDate.includes('T')
          ? filters.maxDueDate.split('T')[0]
          : undefined;
      const minRepaymentDate =
        typeof filters.minRepaymentDate === 'string' &&
        filters.minRepaymentDate.includes('T')
          ? filters.minRepaymentDate.split('T')[0]
          : undefined;
      const maxRepaymentDate =
        typeof filters.maxRepaymentDate === 'string' &&
        filters.maxRepaymentDate.includes('T')
          ? filters.maxRepaymentDate.split('T')[0]
          : undefined;

      getInstallments(userInfo?.fundings[0], {
        order: 'desc',
        page: currentPage + 1,
        take: linesPerPage,
        groupId: productId,
        contractId: contractId,
        minRepaymentDate,
        maxRepaymentDate,
        minDueDate,
        maxDueDate,
        taxpayerId,
        status: `%7B${filters.status?.join(',')}%7D`,
      }).then((list) => {
        if (list && Array.isArray(list.content)) {
          setInstallmentsList(list.content);
          setTotalItemsQty(list.total_items);
        } else {
          setInstallmentsList([]);
          setTotalItemsQty(0);
        }
      });
    }
  }, [
    taxpayerId,
    contractId,
    linesPerPage,
    currentPage,
    params,
    filters,
    productId,
  ]);

  const onApplyFilters = useCallback((f: TFilters) => {
    updateFilters(f);
    toggleFiltersOpened(false);
  }, []);

  useEffect(() => {
    getInstallmentsContent();
  }, [currentPage, filters, linesPerPage]);

  useEffect(() => {
    setCurrentPage(() => 0);
    setLinesPerPage(() => 10);
    getInstallmentsContent();
  }, [taxpayerId, productId]);

  const notPaidInstallments = useMemo(
    () => installmentsList.filter(({ status }) => status !== 'paid'),
    [installmentsList],
  );

  const selectedDebtorTaxpayerId = useMemo(() => {
    if (installments.length > 0) {
      return installments[0].debtor[0].taxpayer_id;
    } else if (notPaidInstallments.length > 0) {
      return notPaidInstallments[0].debtor[0].taxpayer_id;
    }
    return null;
  }, [installments, notPaidInstallments]);

  const totalItemsAvailableToCheck = useMemo(
    () =>
      notPaidInstallments.filter(
        ({ debtor }) => debtor[0].taxpayer_id === selectedDebtorTaxpayerId,
      ),
    [selectedDebtorTaxpayerId, notPaidInstallments],
  );

  const totalItemsToCheck = useMemo(
    () =>
      notPaidInstallments.filter(
        ({ id, debtor }) =>
          debtor[0].taxpayer_id === selectedDebtorTaxpayerId &&
          !checkedItems.includes(id),
      ),
    [selectedDebtorTaxpayerId, checkedItems, notPaidInstallments],
  );

  const tableHeadContent: TTableHeadCell[] = (
    [
      {
        id: '1',
        content: (
          <TableHeadCellContainer style={{ padding: '0px 10px 0px 7px' }}>
            <Checkbox
              checked={
                totalItemsAvailableToCheck.length === checkedItems.length
              }
              color="primary"
              indeterminate={
                checkedItems.length > 0 &&
                totalItemsAvailableToCheck.length !== checkedItems.length
              }
              onClick={() => {
                if (checkedItems.length === totalItemsAvailableToCheck.length) {
                  onCleanCart();
                } else if (totalItemsToCheck.length > 0) {
                  onAddInstallments(totalItemsToCheck);
                } else {
                  onRemoveInstallments(
                    notPaidInstallments.filter((installment) =>
                      checkedItems.includes(installment.id),
                    ),
                  );
                }
              }}
            />
          </TableHeadCellContainer>
        ),
      },
    ] as TTableHeadCell[]
  ).concat(headers);

  const filterCount = useMemo(() => {
    let counter = 0;
    if (filters.minDueDate || filters.maxDueDate) counter++;
    if (filters.minRepaymentDate || filters.maxRepaymentDate) counter++;
    if (filters.status?.length !== 5) counter++;
    return counter;
  }, [filters]);

  return (
    <>
      <InstallmentsContentWrapper>
        <InstallmentsContentFilterArea>
          {typeof onRenderSearch === 'function' && onRenderSearch()}
          <InstallmentsContentButtonArea>
            {!hideFilters && (
              <ButtonPrimary
                style={{ maxWidth: '202px' }}
                typeVariant="outline"
                iconPosition="left"
                btnIcon={
                  <InstallmentsFiltersIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                }
                onClick={() => toggleFiltersOpened(() => true)}
              >
                <TextM
                  weight="Bold"
                  style={{
                    marginLeft: '11px',
                  }}
                >
                  Mais filtros
                </TextM>
                {filterCount > 0 && (
                  <InstallmentsContentFiltersCount>
                    {filterCount}
                  </InstallmentsContentFiltersCount>
                )}
              </ButtonPrimary>
            )}
          </InstallmentsContentButtonArea>
        </InstallmentsContentFilterArea>
        {installmentsList.length === 0 ||
        getInstallmentsLoading ||
        isLoading ? (
          <EmptyArea
            isLoading={getInstallmentsLoading || !!isLoading}
            message="Nenhuma parcela encontrada"
          >
            <EmptyAccessImage />
          </EmptyArea>
        ) : (
          <InstallmentsContentTableArea>
            <Table
              showPagination
              handlePageChange={(selectedPage) => {
                setCurrentPage(selectedPage - 1);
              }}
              handleLinesPerPage={(selectedLinesPerPage) => {
                setCurrentPage(0);
                setLinesPerPage(
                  selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                );
              }}
              totalElementsQty={totalItemsQty}
              currentPage={currentPage + 1}
              linesPerPage={linesPerPage}
              tableHeadCell={tableHeadContent}
              tableBodyContent={installmentsList.map((item) => {
                const isChecked = checkedItems.includes(item.id);
                const isDisabled =
                  installments.length > 0 &&
                  installments[0].debtor[0].taxpayer_id !==
                    item.debtor[0].taxpayer_id;
                const cells = onRenderItem(item, isDisabled);
                return {
                  id: '1',
                  handleLineClick: () => {
                    if (item.status === 'paid') {
                      showSnackbar('Parcela já paga', 'alert');
                    } else if (isChecked) {
                      onRemoveInstallment(item);
                    } else if (!isDisabled) {
                      onAddInstallment(item);
                    } else {
                      showSnackbar('Parcela de sacados diferentes', 'alert');
                    }
                  },
                  disabled: isDisabled,
                  cells: (
                    [
                      {
                        id: '1',
                        content: (
                          <TableBodyCellContainer style={{ padding: '0px' }}>
                            {item.status !== 'paid' && !isDisabled && (
                              <Checkbox checked={isChecked} />
                            )}
                          </TableBodyCellContainer>
                        ),
                      },
                    ] as TBodyContentCell[]
                  ).concat(cells),
                };
              })}
            />
          </InstallmentsContentTableArea>
        )}
        <InstallmentsFilters
          filters={filters}
          handleApply={onApplyFilters}
          handleClose={() => toggleFiltersOpened(false)}
          isLoading={getInstallmentsLoading}
          isOpen={isFiltersOpened}
        />
      </InstallmentsContentWrapper>
      <InstallmentsCart />
    </>
  );
};

export default InstallmentsTable;
