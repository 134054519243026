import styled from '@emotion/styled';

export const BackToLoginBtnContainer = styled.div`
  width: 100%;
  padding: 19px 8px;
  display: flex;
  justify-content: center ;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.palette?.brand.primary.base};
  }
`;

export const BackToLoginBtnContent = styled.div`
  display: flex ;
  justify-content: center ;
  align-items: center;
  cursor: pointer;
`;

export const BackToLoginText = styled.p`
  color: ${({ theme }) => theme.palette?.brand.primary.base};
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  margin-left: 8px;
  font-weight: bold;
`;
