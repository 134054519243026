import AddIcon from '@mui/icons-material/Add';
import { ButtonContainer, NoItemsRegisteredContentWrapper } from './styles';
import { INoItemsRegisteredProps } from '../../noItemsRegistered.interfaces';
import { Heading3 } from '../../../typography';
import { ButtonPrimary } from '../../../ButtonPrimary';

const NoItemsRegisteredContent = ({
  buttonText,
  handleClick,
  icon,
  title,
}: INoItemsRegisteredProps) => {
  return (
    <NoItemsRegisteredContentWrapper>
      {icon}
      <Heading3 style={{ marginTop: '40px' }}>{title}</Heading3>
      {typeof handleClick === 'function' && (
        <ButtonContainer>
          <ButtonPrimary onClick={handleClick}>
            <AddIcon sx={{ marginRight: '8px' }} />
            {buttonText}
          </ButtonPrimary>
        </ButtonContainer>
      )}
    </NoItemsRegisteredContentWrapper>
  );
};

export default NoItemsRegisteredContent;
