import useGtm from 'app/hooks/gtm/useGtm';
import React, { createContext, useEffect, useContext, useState } from 'react';
import { AppHttpService } from '../../services';
import { IAppContext } from './app.interfaces';

export const AppContext = createContext<IAppContext>({} as IAppContext);

export interface IAppContextProviderProps {
  children?: React.ReactNode;
}

export const AppContextProvider = ({ children }: IAppContextProviderProps) => {
  const [app, setApp] = useState<IAppContext>();
  const { sendGtmData } = useGtm();

  useEffect(() => {
    const appService = new AppHttpService();
    appService.getApp().then((appResponse) => {
      const { data: appData } = appResponse;
      const root = document.querySelector<HTMLHtmlElement>(':root');
      if (root)
        root.style.setProperty(
          '--primary',
          appData?.primary_color ?? '#806DFB',
        );
      setApp(appData);
      sendGtmData('sendDNS', {
        app_dns: appData?.dns,
      });
    });
  }, []);

  if (!app) {
    return null;
  }

  return <AppContext.Provider value={app}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }
  return context;
};
