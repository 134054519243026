import React, { useState, useEffect, useCallback } from 'react';
import {
  formatDateForBackendString,
  formatDateFromBackend,
} from 'app/helpers/dateHelpers';
import { BusinessEditSideSheet } from '../BusinessEditSideSheet';
import { EVariableType, TVariable } from 'app/services/variablesHttpService';
import { StepContentInput, StepContentInputArea } from './styles';
import {
  CustomSelect,
  CalendarInput,
  CustomInput,
} from 'celcoin-design-system';

export interface ICustomVariableEditSideSheet {
  isOpen: boolean;
  value: string;
  variable: TVariable | null;
  isLoading: boolean;
  handleSave: (value: string | undefined) => void;
  handleClose: () => void;
}

const CustomVariableEditSideSheet = ({
  isOpen,
  variable,
  isLoading,
  value: valueExternal,
  handleClose,
  handleSave,
}: ICustomVariableEditSideSheet) => {
  const [value, updateValue] = useState<string>('');

  useEffect(() => {
    if (variable?.type === EVariableType.DATE && valueExternal) {
      updateValue(() => formatDateFromBackend(valueExternal).toISOString());
      return;
    }
    updateValue(() => valueExternal);
  }, [variable, valueExternal]);

  const onSave = useCallback(() => {
    if (variable?.type === EVariableType.DATE) {
      handleSave(
        value
          ? formatDateForBackendString(
              new Date(value).toLocaleDateString('pt-BR'),
            ) ?? ''
          : undefined,
      );
      return;
    }
    handleSave(value);
  }, [variable, value]);

  const handleCalendarChange = (dateValue: Date | null) => {
    try {
      updateValue(dateValue?.toISOString() ?? '');
    } catch (err) {
      updateValue(dateValue ? (dateValue as unknown as string) : '');
    }
  };

  const onClose = useCallback(() => {
    handleClose();
  }, []);

  return (
    <BusinessEditSideSheet
      open={isOpen}
      handleClose={onClose}
      handleSave={onSave}
      isLoading={isLoading}
      title="Editar campo adicional"
    >
      <StepContentInputArea>
        {variable?.type === EVariableType.TEXT && (
          <StepContentInput isFull={true}>
            <CustomInput
              name={variable.name}
              value={value}
              placeholder="Digitar informação"
              handleInputChange={({ currentTarget }) =>
                updateValue(() => currentTarget.value)
              }
              labelValue={variable.display_name}
            />
          </StepContentInput>
        )}
        {variable?.type === EVariableType.BOOLEAN && (
          <StepContentInput>
            <CustomSelect
              labelValue={variable.display_name}
              name={variable.name}
              placeholder="Vazio"
              value={{
                label: value === 'true' ? 'Sim' : 'Não',
                value: value ?? '',
              }}
              handleInputChange={({ currentTarget }) =>
                updateValue(() => currentTarget?.value?.value)
              }
              options={[
                { value: 'true', label: 'Sim' },
                { value: 'false', label: 'Não' },
              ]}
            />
          </StepContentInput>
        )}
        {variable?.type === EVariableType.DATE && (
          <StepContentInput>
            <CalendarInput
              name={variable.name}
              labelValue={variable.display_name}
              placeholder="00/00/0000"
              value={value}
              onChange={handleCalendarChange}
            />
          </StepContentInput>
        )}
        {variable?.type === EVariableType.NUMBER && (
          <StepContentInput isFull={true}>
            <CustomInput
              name={variable.name}
              value={value}
              placeholder="Digitar números"
              handleInputChange={({ currentTarget }) =>
                updateValue(() => currentTarget?.value?.replace(/\D/g, ''))
              }
              labelValue={variable.display_name}
            />
          </StepContentInput>
        )}
      </StepContentInputArea>
    </BusinessEditSideSheet>
  );
};

export default CustomVariableEditSideSheet;
