import React from 'react';
import {
  ButtonPrimary,
  RegularText,
  TextXLBold,
} from '../../../../../../app/components';
import { ConfirmationShield } from '../../../../components';
import {
  BtnArea,
  ConfirmationModalContainer,
  ConfirmationModalContent,
  ImageArea,
} from './styles';
import { useTheme } from '@mui/material';

export interface IConfirmationModalProps {
  open: boolean;
  handleModal: () => void;
}

const ConfirmationModal = ({ open, handleModal }: IConfirmationModalProps) => {
  const theme = useTheme();
  return (
    <ConfirmationModalContainer open={open}>
      <ConfirmationModalContent>
        <ImageArea>
          <ConfirmationShield
            color={theme.palette.brand.secondary.base as string}
          />
        </ImageArea>
        <TextXLBold style={{ marginTop: '28px', textAlign: 'center' }}>
          Autenticação de 2 fatores ativado
        </TextXLBold>
        <RegularText
          className="bodyText"
          style={{ textAlign: 'center', marginTop: '21px', maxWidth: '395px' }}
        >
          A partir de agora, um novo código será solicitado periodicamente em
          seus acessos. Basta abrir o aplicativo e copiá-lo no campo de ativação
          do painel.
        </RegularText>
        <BtnArea>
          <ButtonPrimary onClick={handleModal}>Acessar painel</ButtonPrimary>
        </BtnArea>
      </ConfirmationModalContent>
    </ConfirmationModalContainer>
  );
};

export default ConfirmationModal;
