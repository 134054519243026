import styled from '@emotion/styled';

export const LoginFormContainer = styled.div`
  width: 100%;
  max-width: 592px;
  height: 100% ;
  max-height: 600px;
  box-shadow: 0px 8px 20px rgba(15, 33, 43, 0.15);
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 65px;

  @media screen and (max-width: 426px) {
    padding: 20px;
  }
`;

export const HeadTextArea = styled.div`
  margin-top: 32px;
`;

export const InputArea = styled.div`
  width: 100%;
`;

export const InputItem = styled.div`
  margin-top: 24px;
`;

export const ButtonArea = styled.div`
  margin-top: 32px;
  width: 100%;
  height: 48px;

  .spinner {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const ValidationError = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.palette?.brand.error[900]};
  text-align: center;
  margin: 32px 0px 0px;
  padding: 0px;
`;
