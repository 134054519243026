import styled from '@emotion/styled';

export const FiltersContent = styled.div`
  padding: 0px;
  width: 100%;
  max-width: 85vw;
  min-width: 800px;

  .header-area {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .close-btn {
      cursor: pointer;
    }
  }

  .body-content {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  .footer-content {
    display: flex;
    justify-content: flex-end;
  }
`;

export const FilterItemContent = styled.div<{
  marginTop?: string;
  isFull?: boolean;
}>`
  width: ${(props) => (props.isFull ? '100%' : 'calc(100% / 3)')};
  margin-top: ${(props) => props.marginTop || '0px'};
  padding: 15px;
`;

export const FilterItemDataRange = styled.div`
  display: flex;
`;

export const FilterItemDataRangeInput = styled.div`
  max-width: 190px;
  &:first-child {
    margin-right: 10px;
  }
`;
