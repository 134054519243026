import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { useState } from 'react';
import {
  EditWrapper,
  Heading3,
  ViewBox,
  EViewBoxColumnType,
} from '../../../../../../app/components';
import { TRegisterBusinessDataRequest } from '../../../../context';
import LegalPersonAboutSectionEdit from './LegalPersonEditAboutSection';
import { LegalPersonViewPageSection } from './styles';
import { useTheme } from '@mui/material';
import {
  IncorporationTypeObject,
  TaxRegimeObject,
} from 'modules/customer/context/CustomerProvider/customer.constants';

type ILegalPersonViewAboutSectionProps = {
  business: TRegisterBusinessDataRequest;
};

const LegalPersonViewAboutSection = ({
  business,
}: ILegalPersonViewAboutSectionProps) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const theme = useTheme();
  return (
    <>
      <LegalPersonViewPageSection>
        <Heading3
          style={{ color: theme.palette.brand.secondary.base as string }}
        >
          Sobre
        </Heading3>
        <EditWrapper
          handleClick={() => setIsEditOpen(true)}
          permission={EAccountPermissions.UPDATE_PERSON_BUSINESS}
        >
          <ViewBox
            values={
              {
                ...business,
              } as unknown as {
                [key: string]: string | number;
              }
            }
            textAlign="right"
            columns={[
              {
                label: 'Razão Social',
                path: 'legal_name',
              },
              {
                label: 'Nome Fantasia',
                path: 'name',
              },
              {
                label: 'E-mail',
                path: 'email_address',
              },
              {
                label: 'Data de constituição',
                path: 'foundation_date',
                type: EViewBoxColumnType.DATE,
              },
              {
                label: 'CNPJ',
                path: 'taxpayer_id',
                type: EViewBoxColumnType.CNPJ,
              },
              {
                label: 'Celular',
                path: 'phone',
                type: EViewBoxColumnType.PHONE,
              },
              {
                label: 'Regime Fiscal',
                path: 'tax_regime',
                type: EViewBoxColumnType.ENUM,
                enum: TaxRegimeObject,
              },
              {
                label: 'Formato Jurídico',
                path: 'incorporation_type',
                type: EViewBoxColumnType.ENUM,
                enum: IncorporationTypeObject,
              },
              {
                label: 'Faturamento mensal',
                path: 'monthly_income_revenue',
                type: EViewBoxColumnType.CURRENCY,
              },
              {
                label: 'Capital Social',
                path: 'share_capital',
                type: EViewBoxColumnType.CURRENCY,
              },
              {
                label: 'Classificação da indústria',
                path: 'industry_classification',
              },
              {
                label: 'Cadastrado em',
                path: 'created_at',
                type: EViewBoxColumnType.DATE,
              },
            ]}
          />
        </EditWrapper>
      </LegalPersonViewPageSection>
      <LegalPersonAboutSectionEdit
        isOpen={isEditOpen}
        handleClose={() => setIsEditOpen(false)}
        business={business}
      />
    </>
  );
};

export default LegalPersonViewAboutSection;
