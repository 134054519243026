import React, { CSSProperties } from 'react';
import { BoxSectionContainer } from './styles';

export type TBoxVariant = 'rounded' | 'default';

export interface IBoxSectionProps {
  level?: 'content' | 'wrapper';
  children: React.ReactNode;
  boxVariant?: TBoxVariant;
  style?: CSSProperties;
}

const BoxSection = ({
  children,
  level = 'wrapper',
  boxVariant = 'default',
  style,
}: IBoxSectionProps) => {
  return (
    <BoxSectionContainer boxVariant={boxVariant} level={level} style={style}>
      {children}
    </BoxSectionContainer>
  );
};

export default BoxSection;
