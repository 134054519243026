const AccessManagementIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 13.5H4V18.5H16V2.5H4V7.5H2V1.5C2 0.94772 2.44772 0.5 3 0.5H17C17.5523 0.5 18 0.94772 18 1.5V19.5C18 20.0523 17.5523 20.5 17 20.5H3C2.44772 20.5 2 20.0523 2 19.5V13.5ZM8 9.5V6.5L13 10.5L8 14.5V11.5H0V9.5H8Z"
        fill={color}
      />
    </svg>
  );
};

export default AccessManagementIcon;
