import { IHeaderAction, IHeaderState } from './header.interfaces';
import { headerActionType } from './headerActionTypes';

export const headerInitialState: IHeaderState = {
  title: '',
};

export const headerReducer = (
  state: IHeaderState = headerInitialState,
  action: IHeaderAction,
) => {
  switch (action.type) {
    case headerActionType.SET_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case headerActionType.RESET_TITLE:
      return {
        ...state,
        title: '',
      };
    default:
      return state;
  }
};
