import styled from '@emotion/styled';

export const CessionsFilterContainer = styled.div`
  padding: 20px;
`;

export const CessionsFilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 200px;
`;

export const CessionsFilterBody = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CessionsFilterButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  gap: 8px 0px;
`;

export const CessionsFilterInputs = styled.div`
  display: flex;
`;

export const CessionsFilterInput = styled.div`
  max-width: 190px;
  &:first-child {
    margin-right: 10px;
  }
`;

export const CessionsFilterInputInvalid = styled.div`
  margin-top: 6px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette?.brand.error[900]};
`;

export const StatusElement = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px;
  align-items: center;
`;

export const CloseBtn = styled.div`
  cursor: pointer;
`;
