import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  & > section {
    padding: 0px;
  }
`;

export const ProductsDetailsContentPageContentHeader = styled.div`
  background-color: ${({ theme }) => theme.palette?.brand.background.base};
  padding: 40px 48px 0px;
  border-top: 1px solid #DBD6FE;
  border-left: 1px solid #DBD6FE;
  border-right: 1px solid #DBD6FE;
  border-radius: 16px 16px 0px 0px;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      padding: 20px;
  };
`;

export const BoxHeading = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette?.brand.background.base};
`;

export const TabsArea = styled.div`
  margin-top: 58px;
`;

export const ProductDetailsContentHeaderButtons = styled.section`
  display: flex;
  button {
    width: auto;
    margin-right: 16px;
  }
`;
