import { isValidCPF } from 'app/helpers';
import * as yup from 'yup';

export const infoStepSchema = yup.object({
  full_name: yup.string().required('Campo obrigatório'),
  taxpayer_id: yup
    .string()
    .required('Campo obrigatório')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}/,
      'Formato de CPF incorreto',
    )
    .test('test-invalid-cpf', 'CPF inválido', (cpf) => isValidCPF(cpf || '')),
  phone: yup
    .string()
    .test(
      'test-phone-number',
      'Formato de telefone incorreto',
      function (value) {
        const { path, createError } = this;

        if (
          value &&
          value.length < 15 &&
          value?.replace(/\D+/g, '')?.match(/(\d{2})(\d)/)?.[2] === '9'
        ) {
          return createError({
            path,
            message: 'Formato de telefone incorreto',
          });
        }

        if (value && value.length < 14) {
          return createError({
            path,
            message: 'Formato de telefone incorreto',
          });
        }

        return true;
      },
    )
    .required('Campo obrigatório'),
  email: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('Campo obrigatório'),
});
