import styled from '@emotion/styled';

export const FButtonsContainer = styled.div`
  position: fixed;
  bottom: 50px;
  right: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
