import { css } from '@emotion/react';
import styled from '@emotion/styled';

export interface ISideMenuDrawerContainerProps {
  isCollapsed?: boolean;
  hideOnSmallScreen?: boolean;
}

export const SideMenuDrawerContainer = styled.div<ISideMenuDrawerContainerProps>`
  display: flex;
  min-height: 100vh;
  height: 100%;
  background-color: ${({ theme }) => theme.palette?.brand.gray[50]};
  overflow-x: hidden;
  width: ${({ isCollapsed }) => (isCollapsed ? '5%' : '17%')};
  max-width: 304px;
  transition: width .4s ease-in;

  ${({ hideOnSmallScreen }) =>
    hideOnSmallScreen &&
    css`
    width: 0%;
  `}


  .MuiDrawer-root {
    transition: width .4s ease-in;
  }

  .MuiDrawer-paper {
    overflow: hidden ;
    width:  ${({ isCollapsed }) => (isCollapsed ? '5%' : '15%')};
    max-width: 304px;
    transition: width .4s ease-in;
  }
`;

export interface IDrawerContentProps {
  widthContent: string;
}

export const DrawerContentArea = styled.div<IDrawerContentProps>`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.palette?.brand.primary.base};
  ${({ widthContent }) =>
    widthContent &&
    css`
    width: ${widthContent};
    transition: width .4s ease-in ;
  `}
`;
