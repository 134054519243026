import styled from '@emotion/styled';

export const VariablesArea = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  border: 1px solid #E6E5EA;
  border-radius: 10px;
  padding: 24px;
  overflow: auto;
  height: 500px;
`;

export const InputItem = styled.div`
  width: 50%;
  margin-top: 10px;
`;
