import React, { ButtonHTMLAttributes } from 'react';
import { ButtonDangerContainer } from './styles';

export type ButtonDangerTypeVariant = 'outline' | 'fill' | 'ghost';
export type ButtonDangerIconPosition = 'left' | 'right';

export interface IButtonDangerProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  roundType?: 'circular' | 'normal';
  typeVariant?: ButtonDangerTypeVariant;
  btnIcon?: React.ReactNode;
  iconPosition?: ButtonDangerIconPosition;
}

const ButtonDanger = ({
  children,
  roundType,
  typeVariant = 'fill',
  iconPosition = 'right',
  btnIcon,
  ...props
}: IButtonDangerProps) => {
  return (
    <ButtonDangerContainer
      {...props}
      typeVariant={typeVariant}
      style={{
        ...props.style,
        borderRadius:
          props.style?.borderRadius || roundType === 'circular'
            ? '80px'
            : '10px',
      }}
    >
      {btnIcon && iconPosition === 'left' && btnIcon}
      {children}
      {btnIcon && iconPosition === 'right' && btnIcon}
    </ButtonDangerContainer>
  );
};

export default ButtonDanger;
