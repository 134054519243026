import styled from '@emotion/styled';
import { TBadgeVariant } from './Badge';

export interface IBadgeContainerProps {
  typeVariant?: TBadgeVariant;
}

export const BadgeContainer = styled.div<IBadgeContainerProps>`
  width: 100%;
  position: relative;
  background-color: ${({ typeVariant, theme }) => {
    switch (typeVariant) {
      case 'neutral':
        return '#F7F7F7';
      case 'info':
        return '#DBD6FE';
      case 'error':
        return '#FCB9B9';
      case 'success':
        return theme.palette?.brand.success[300];
      case 'attention':
        return theme.palette?.brand.attention[200];
      default:
        return '#F7F7F7';
    }
  }};
  border-radius: 5px;
  border: 1px solid #E6E5EA;
  border-color: ${({ typeVariant }) => {
    switch (typeVariant) {
      case 'neutral':
        return '#E6E5EA';
      case 'info':
        return '#DBD6FE';
      case 'error':
        return '#FCB9B9';
      case 'success':
        return 'transparent';
      default:
        return '#E6E5EA';
    }
  }};
`;

export const BadgeContent = styled.div<{ isSpaceBetween?: boolean }>`
  position: relative;
  display: flex;
  justify-content: ${({ isSpaceBetween }) =>
    isSpaceBetween ? 'space-between' : 'center'};
  align-items: center;
  padding: 5px 8px;
  border-radius: 5px;
  cursor: pointer;
  
  svg {
    margin-left: 4px;
  }
`;
