import { useTheme } from '@mui/material';
import { Heading4 } from 'app/components';
import { EmptyApplications } from 'app/components/Icons/svgs';
import { EmptyContainer } from './styles';

export interface IEmptyContentProps {
  titleText: string;
}

const EmptyContent = ({ titleText }: IEmptyContentProps) => {
  const theme = useTheme();

  return (
    <EmptyContainer>
      <>
        <img src={EmptyApplications} />
        <Heading4
          style={{
            marginTop: '30px',
            color: theme.palette.brand.secondary.base,
          }}
          fontWeight="B"
        >
          {titleText}
        </Heading4>
      </>
    </EmptyContainer>
  );
};

export default EmptyContent;
