import { EventManager } from 'app/utils/EventManager';

export type TMessageType =
  | 'success'
  | 'info'
  | 'error'
  | 'alert'
  | 'copy'
  | 'link';

type Toast = {
  type: TMessageType;
  text: string;
  duration?: number;
};

export const toastEventManager = new EventManager();

export function toast({ type, text, duration = 5000 }: Toast) {
  toastEventManager.emit('addtoast', { type, text, duration });
}

export function toastCopyLink() {
  toastEventManager.emit('addtoastCopyLink', {});
}

export function toastCopy() {
  toastEventManager.emit('addtoastCopy', {});
}
