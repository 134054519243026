import { ButtonPrimary, StepContent } from 'app/components';
import { useFundingsService } from 'modules/products/services/hooks';
import { useEffect, useState, useContext } from 'react';
import { StepContentButtonsArea } from './styles';
import { IFunding } from 'modules/products/services/hooks/intefaces';
import { useAuthContext } from 'modules/auth/context';
import { LegalPersonHandlerStepperContext } from '../LegalPersonHandler';
import {
  ListBox,
  ListBoxItem,
  ListBoxItemTitle,
} from 'modules/products/pages/ApplicationRequest/components';
import { formatCNPJ } from 'app/helpers';

const FundingStep = () => {
  const { userInfo } = useAuthContext();
  const { getFundings } = useFundingsService();
  const [hasNext, updateHasNext] = useState(false);
  const [searchText, updateSearchText] = useState('');
  const [fundingList, setFundingList] = useState<IFunding[]>([]);
  const { funding, updateFunding, onForward } = useContext(
    LegalPersonHandlerStepperContext,
  );
  const [selectedFunding, setSelectedFunding] = useState<string | null>(
    funding,
  );

  useEffect(() => {
    if (userInfo) {
      getFundings(userInfo.fundings).then((fundings) => {
        if (fundings) {
          setFundingList(fundings.content);
          updateHasNext(!!fundings.has_next);
        }
      });
    }
  }, []);

  return (
    <StepContent
      title="Funding"
      description="Selecione o funding para a linha de crédito"
    >
      <ListBox
        filterItems={[{ name: 'Nome', parameterName: 'legal_name' }]}
        hasMoreItems={hasNext}
        items={fundingList?.filter(
          (fundingItem) =>
            !searchText ||
            fundingItem.legal_name
              .toLowerCase()
              .includes(searchText.toLowerCase()),
        )}
        onSearch={(field, value) => updateSearchText(value)}
      >
        {(i) => {
          const item = i as IFunding;
          return (
            <ListBoxItem
              className={selectedFunding === item.id ? 'selected' : ''}
              onClick={() => setSelectedFunding(item.id)}
              key={item.legal_name}
            >
              <ListBoxItemTitle>
                {item.alias ?? item.legal_name}
              </ListBoxItemTitle>
              <span>{formatCNPJ(item.taxpayer_id)}</span>
            </ListBoxItem>
          );
        }}
      </ListBox>
      <StepContentButtonsArea>
        <ButtonPrimary
          disabled={!selectedFunding}
          onClick={() => {
            if (selectedFunding) {
              updateFunding(selectedFunding);
              setTimeout(() => onForward());
            }
          }}
        >
          Avançar
        </ButtonPrimary>
      </StepContentButtonsArea>
    </StepContent>
  );
};

export default FundingStep;
