import { useTheme } from '@mui/material';
import { ButtonPrimary, CloseIcon, Heading4 } from 'app/components';
import {
  ModalBody,
  ModalHeader,
  WithdrawReceiptModalContainer,
} from './styles';
import { BaseModal, Heading3, TextM } from 'celcoin-design-system';
import { WithdrawStatusResponse } from 'modules/disbursement/interfaces/disbursement';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ApplicationReceiptPDF from 'modules/products/pages/ApplicationDetails/components/ApplicationDetailsTab/ApplicationReceiptPDF';
import { DownloadIcon } from 'app/components/Icons';

export interface IWithdrawReceiptModal {
  isOpen: boolean;
  identifier: string | false;
  handleClose: () => void;
  withdrawResponse: WithdrawStatusResponse | null;
}

const WithdrawReceiptModal = ({
  isOpen,
  identifier,
  handleClose,
  withdrawResponse,
}: IWithdrawReceiptModal) => {
  const theme = useTheme();
  return (
    <BaseModal open={isOpen} onClose={handleClose}>
      <WithdrawReceiptModalContainer>
        <ModalHeader>
          <Heading3>Saque</Heading3>
          <div onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </ModalHeader>
        <ModalBody>
          <Heading4
            style={{
              color: theme.palette.brand.secondary.base,
              textAlign: 'center',
            }}
            fontWeight="B"
          >
            Recibo da transação
          </Heading4>
          <code
            style={{
              fontFamily: 'monospace',
              textAlign: 'center',
              fontSize: '18px',
              backgroundColor: '#f5f5f5',
              padding: '10px',
              border: '1px solid lightgray',
              marginTop: '15px',
            }}
          >
            {withdrawResponse?.receipt?.split('\n').map((line, index) => (
              <>
                {line.trim().length > 0 ? (
                  <span key={index} style={{ display: 'block' }}>
                    {line}
                  </span>
                ) : (
                  <br key={index} />
                )}
              </>
            ))}
          </code>

          {withdrawResponse?.receipt && (
            <PDFDownloadLink
              style={{
                textDecoration: 'none',
                marginTop: '10px',
              }}
              fileName={identifier || 'receipt'}
              document={
                <ApplicationReceiptPDF receipt={withdrawResponse.receipt} />
              }
            >
              {() => {
                return (
                  <ButtonPrimary
                    typeVariant="outline"
                    style={{
                      gap: '6px',
                      width: 'fit-content',
                      border: 'none',
                      color: theme.palette.brand.primary.base,
                    }}
                  >
                    <DownloadIcon
                      color={theme.palette.brand.primary.base as string}
                    />
                    <TextM
                      weight="Semibold"
                      style={{ color: theme.palette.brand.primary.base }}
                    >
                      Baixar
                    </TextM>
                  </ButtonPrimary>
                );
              }}
            </PDFDownloadLink>
          )}
        </ModalBody>
      </WithdrawReceiptModalContainer>
    </BaseModal>
  );
};

export default WithdrawReceiptModal;
