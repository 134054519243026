import { BaseHttpService } from 'app/services';

export class AuthHttpService extends BaseHttpService {
  BASE_URL = process.env.REACT_APP_DASHBOARD_API_HOST;

  getActivationTokenInfo = async (token: string) => {
    const res = await this.get(
      `${this.BASE_URL}/accounts/active?token=${token}`,
    );
    return res;
  };

  requestResetPassword = async (email: string, appId: string) => {
    const res = await this.post(
      `${this.BASE_URL}/accounts/request-reset-password`,
      {
        email,
        app_id: appId,
      },
    );
    return res;
  };

  getResetTokenInfo = async (token: string) => {
    const res = await this.get(
      `${this.BASE_URL}/accounts/reset-password?token=${token}`,
    );
    return res;
  };

  getUser = async () => {
    const res = await this.get(`${this.BASE_URL}/accounts/@me`);
    return res;
  };
}
