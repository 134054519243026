import {
  ISimulatorAction,
  ISimulatorState,
} from 'modules/simulation/interfaces/context.interfaces';
import { simulatorActionTypes } from './simulatorActionTypes';

export const simulatorInitialState: ISimulatorState = {
  simulatorParametersData: null,
  applicationPreview: null,
};

export const simulatorReducer = (
  state: ISimulatorState = simulatorInitialState,
  action: ISimulatorAction,
) => {
  switch (action.type) {
    case simulatorActionTypes.SET_SIMULATOR_DATA:
      return {
        ...state,
        simulatorParametersData: action.payload,
      };
    case simulatorActionTypes.RESET_SIMULATOR_DATA:
      return {
        ...state,
        simulatorParametersData: null,
      };

    case simulatorActionTypes.SET_APPLICATION_PREVIEW:
      return {
        ...state,
        applicationPreview: action.payload,
      };

    default:
      return state;
  }
};
