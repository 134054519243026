import { CopyIcon } from 'app/components/Icons';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { useTheme } from '@mui/material';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import { TProducts } from 'modules/products/context';
import { useQualificationsService } from 'modules/products/services/hooks';
import { TBorrowerQualificationRequestType } from 'modules/products/services/hooks/intefaces';
import { useCallback, useEffect, useState } from 'react';
import {
  ButtonPrimary,
  Spinner,
  TextM,
} from '../../../../../../app/components';
import { ProductSideSheet } from '../../../../components/ProductSideSheet';
import { TApplicationsParsed } from '../ApplicationContent/ApplicationContent';
import { BorrowerContent, ConditionContent } from './components';

export interface IApplicationDetails {
  handleClose: () => void;
  isOpen: boolean;
  application: Partial<TApplicationsParsed> | undefined;
  productInfo: TProducts | undefined;
  handleUpdateQualificationStatus: (
    applicationId: string,
    identifier: string,
  ) => void;
}

const ApplicationDetailsSideSheet = ({
  handleClose,
  handleUpdateQualificationStatus,
  isOpen,
  application,
  productInfo,
}: IApplicationDetails) => {
  const theme = useTheme();
  const { getBorrowerQualification, updateQualificationStatusLoading } =
    useQualificationsService();
  const { getPerson } = usePersonService();
  const { getBusiness } = useCustomerService();
  const [borrowerPersonData, setBorrowerPersonData] =
    useState<TRegisterPersonDataRequest>();
  const [borrowerBusinessData, setBorrowerBusinessData] =
    useState<TRegisterBusinessDataRequest>();
  const [borrowerQualificationData, setBorrowerQualificationData] = useState<
    TBorrowerQualificationRequestType[] | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbarCopy } = useSnackbar();

  const onClose = () => {
    handleClose();
  };

  const fetchBorrowerDetails = useCallback(
    async (applicationData: Partial<TApplicationsParsed> | undefined) => {
      setIsLoading(true);
      try {
        if (productInfo) {
          await handleGetBorrower(
            productInfo,
            applicationData?.borrower?.id || '',
          );
        }
      } finally {
        setIsLoading(false);
      }
    },
    [productInfo],
  );

  const handleGetBorrower = useCallback(
    async (product: TProducts, borrowerId: string) => {
      try {
        if (product.borrower_type === 'PERSON') {
          const personData = await getPerson(borrowerId || '');
          if (!personData) {
            handleClose();
          }

          setBorrowerPersonData(
            personData || ({} as TRegisterPersonDataRequest),
          );
        }
        if (product.borrower_type === 'BUSINESS') {
          const personData = await getBusiness(borrowerId || '');
          if (!personData) {
            handleClose();
          }
          setBorrowerBusinessData(
            personData || ({} as TRegisterBusinessDataRequest),
          );
        }
      } catch (error) {
        handleClose();
      }
    },
    [productInfo],
  );

  const handleCopyId = useCallback(() => {
    navigator.clipboard.writeText(`${application?.id || ''}`);
    showSnackbarCopy();
  }, [application]);

  const handleQualification = async () => {
    const qualificationRes = await getBorrowerQualification(
      productInfo?.id || '',
      application?.borrower?.id || '',
    );

    setBorrowerQualificationData(qualificationRes?.content);
  };

  useEffect(() => {
    if (application) {
      fetchBorrowerDetails(application);
    }
  }, [application]);

  useEffect(() => {
    if (productInfo && application) {
      handleQualification();
    }
  }, [productInfo, application]);

  return (
    <ProductSideSheet
      open={isOpen}
      handleClose={onClose}
      title={'Solicitação'}
      subtitleItem={
        isLoading ? null : (
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <TextM>ID: {application?.id || '-'}</TextM>
            <ButtonPrimary
              typeVariant="outline"
              style={{ padding: '10px 15px', width: 'fit-content' }}
              onClick={handleCopyId}
            >
              <CopyIcon color={theme.palette.brand.primary.base as string} />
            </ButtonPrimary>
          </div>
        )
      }
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ConditionContent
            applicationData={application}
            handleUpdateQualificationStatus={handleUpdateQualificationStatus}
            isStatusOptionsLoading={
              updateQualificationStatusLoading ===
              application?.qualificationData?.id
            }
          />
          <BorrowerContent
            borrowerPerson={borrowerPersonData}
            borrowerBusiness={borrowerBusinessData}
            product={productInfo}
            applicationData={application}
            borrowerQualificationData={borrowerQualificationData?.[0]}
          />
        </>
      )}
    </ProductSideSheet>
  );
};

export default ApplicationDetailsSideSheet;
