import { HasAccess } from 'modules/auth/components/HasAccess';
import {
  EAccountPermissions,
  EPermission,
} from 'modules/auth/context/permify/permify.interfaces';
import { DashboardLayout } from '../../../app/components';
import { routingPath } from '../../../app/routes';
import { ApplicationRequest, ProductDetail, ProductsMain } from '../pages';
import { ApplicationDetails } from '../pages/ApplicationDetails';
import { QualificationRequest } from '../pages/QualificationRequest';
import { HasProfileAccess } from 'modules/auth/components/HasProfileAccess';
import { ContractDetails } from '../pages/ContractDetails';

const ProductRoutes = () => {
  return {
    element: <DashboardLayout />,
    children: [
      {
        path: routingPath.products.main,
        element: (
          <HasAccess permission={EPermission.PRODUCTS_LIST}>
            <ProductsMain />
          </HasAccess>
        ),
      },
      {
        path: routingPath.products.detail,
        element: <ProductDetail />,
      },
      {
        path: routingPath.products.applicationRequest,
        element: (
          <HasProfileAccess permission={EAccountPermissions.CREATE_APPLICATION}>
            <ApplicationRequest />
          </HasProfileAccess>
        ),
      },
      {
        path: routingPath.products.applicationDetails,
        element: (
          <HasAccess permission={EPermission.PRODUCTS_APPLICATIONS_VIEW}>
            <ApplicationDetails />
          </HasAccess>
        ),
      },
      {
        path: routingPath.qualification.new,
        element: (
          <HasProfileAccess
            permission={EAccountPermissions.CREATE_PERSON_BUSINESS}
          >
            <QualificationRequest />
          </HasProfileAccess>
        ),
      },
      {
        path: routingPath.products.contractsDetails,
        element: <ContractDetails />,
      },
    ],
  };
};

export default ProductRoutes;
