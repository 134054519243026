import styled from '@emotion/styled';

export const CustomTabIcon = styled.span<{ isSelected: boolean }>`
  padding-right: 5px;
  svg {
    path {
      fill: ${({ isSelected, theme }) =>
        isSelected
          ? theme.palette?.brand.primary.base
          : theme.palette?.brand.gray[400]};
      color: ${({ isSelected, theme }) =>
        isSelected
          ? theme.palette?.brand.primary.base
          : theme.palette?.brand.gray[400]};
    }
  }
`;

export const CustomTabsContent = styled.div`
  .MuiTab-root {
    color: ${({ theme }) => theme.palette?.brand.primary.base};
  }  

  .MuiTab-root.Mui-selected {
    color: ${({ theme }) => theme.palette?.brand.primary.base};
  }

  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.palette?.brand.primary.base};
  }

  .MuiButtonBase-root.Mui-selected {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.palette?.brand.primary.base};
  }

  .MuiButtonBase-root {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.palette?.brand.gray[400]};
  }

  .MuiTabScrollButton-root {
    color: ${({ theme }) => theme.palette?.brand.primary.base};
  }
`;
