import React, { forwardRef } from 'react';
import { AuthFormContainerWrapper } from './styles';

export interface IAuthFormContainerProps {
  children: React.ReactNode;
}

const AuthFormContainer = forwardRef<HTMLDivElement, IAuthFormContainerProps>(
  ({ children }, ref) => {
    return (
      <AuthFormContainerWrapper ref={ref}>{children}</AuthFormContainerWrapper>
    );
  },
);

AuthFormContainer.displayName = 'AuthFormContainer';

export default AuthFormContainer;
