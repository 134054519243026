import { BaseHttpService } from './baseHttpService';

export class AddressHttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}/celcoin/getaddress-ms`;
  BASE_URL_SANDBOX = `${process.env.REACT_APP_DASHBOARD_API_HOST}/celcoin/getaddress/v1`;

  private isSandbox = false;
  constructor(isSandbox?: boolean) {
    super();
    this.isSandbox = !!isSandbox;
  }

  getCities = async (stateCode: string) => {
    const res = await this.get(
      `${
        this.isSandbox ? this.BASE_URL_SANDBOX : this.BASE_URL
      }/city?uf=${stateCode}`,
    );
    return res;
  };
  getAddressByPostalCode = async (postalCode: string) => {
    const res = await this.get(
      `${
        this.isSandbox ? this.BASE_URL_SANDBOX : this.BASE_URL
      }/getaddressbycep?CEP=${postalCode}`,
    );
    return res;
  };
}
