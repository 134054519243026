import styled from '@emotion/styled';

export const UploadCSVContainer = styled.div`
  width: 100%;
  border: 1px dashed ${({ theme }) => theme.palette?.brand.primary.base};
  background-color: ${({ theme }) => theme.palette?.brand.background.base};
  border-radius: 16px;
  `;

export const UploadCSVContent = styled.div`
  width: 100%;
  padding: 27px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  input {
    display: none;
  }

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      flex-direction: column;
      gap: 10px;
  };
`;

export const Col = styled.div`
  display: flex;
  align-items: center;
`;

export const UploadedCSVContainer = styled.div`
  width: 100%;
  border: 1px solid #EEEEEE;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px -1px 0px -6px rgba(0, 0, 0, 0.04), 0px 4px 12px -4px rgba(0, 0, 0, 0.04);
`;

export const UploadedCSVContent = styled.div`
  width: 100%;
  padding: 27px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      flex-direction: column;
      gap: 10px;
  };
`;

export const FileTitle = styled.div`
  display: flex;
  gap: 16px;
`;
