import { EventManager } from 'app/utils/EventManager';

export interface IApiErrorModal {
  errorMessage?: string;
  app_id?: string;
  fundings?: string[];
  originator_id?: string;
  issuer_id?: string;
  dns?: string;
  endpoint?: string;
  method?: string;
  request_body?: string;
  response_status?: string;
  response_body?: string;
}

export const apiErrorEventManager = new EventManager();

export function setRequestBodyErrorModal({ request_body }: IApiErrorModal) {
  apiErrorEventManager.emit('setRequestBodyErrorModal', {
    request_body,
  });
}

export function openApiErrorModal({
  errorMessage,
  endpoint,
  method,
  response_body,
  response_status,
  request_body,
}: IApiErrorModal) {
  apiErrorEventManager.emit('openApiErrorModal', {
    errorMessage,
    endpoint,
    method,
    response_body,
    response_status,
    request_body,
  });
}
