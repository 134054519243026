import {
  IQualificationsAction,
  IQualificationsState,
} from './qualifications.interfaces';
import { qualificationsActionType } from './qualificationsActionType';

export const qualificationsInitialState: IQualificationsState = {
  content: [],
  hasMorePage: false,
  page: 0,
  totalElements: 0,
  statusOptions: [],
  qualificationBorrower: [],
  borrowerStatusOptions: [],
};

export const qualificationsReducer = (
  state: IQualificationsState = qualificationsInitialState,
  action: IQualificationsAction,
): IQualificationsState => {
  switch (action.type) {
    case qualificationsActionType.SET_QUALIFICATIONS:
      return {
        ...state,
        content: action.payload.content,
        hasMorePage: action.payload.hasMorePage,
        page: action.payload.page,
        totalElements: action.payload.totalElements,
      };
    case qualificationsActionType.SET_QUALIFICATIONS_BORROWER:
      return {
        ...state,
        qualificationBorrower: action.payload,
      };
    case qualificationsActionType.SELECT_QUALIFICATION:
      return {
        ...state,
        selected: action.payload,
      };
    case qualificationsActionType.SET_STATUS_OPTIONS:
      return {
        ...state,
        statusOptions: action.payload || [],
      };
    case qualificationsActionType.SET_STATUS_OPTIONS_PERSON:
      return {
        ...state,
        borrowerStatusOptions: action.payload || [],
      };
    case qualificationsActionType.UPDATE_QUALIFICATION:
      return {
        ...state,
        content: state.content.map((qualification) => {
          if (qualification.id === action.payload.id) {
            return {
              ...qualification,
              ...action.payload.qualification,
            };
          }
          return qualification;
        }),
      };
    case qualificationsActionType.UPDATE_PERSON_QUALIFICATION:
      return {
        ...state,
        qualificationBorrower: state.qualificationBorrower.map(
          (qualification) => {
            if (qualification.id === action.payload.id) {
              return {
                ...qualification,
                ...action.payload.qualification,
              };
            }
            return qualification;
          },
        ),
      };
    default:
      return state;
  }
};
