export const isValidEmail = (email: string): boolean => {
  // eslint-disable-next-line prettier/prettier, no-useless-escape
  const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return emailRegex.test(email);
};

export const isValidPhoneNumber = (phone: string): boolean => {
  return phone.replace(/[^\w\s]/gi, '').replace(/\s/g, '').length === 11;
};

export const isValidKey = (key: string): boolean => {
  const keyRegex = new RegExp(
    /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/,
  );
  return keyRegex.test(key);
};
