import styled from '@emotion/styled';

export const StepContentInput = styled.div<{
  marginTop?: string;
  isFull?: boolean;
  isHalf?: boolean;
}>`
  width: ${(props) => (props.isFull ? '100%' : !props.isHalf ? '50%' : '25%')};
  margin-top: ${(props) => props.marginTop || '0px'};
  padding: 15px;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      width:  ${(props) => (!props.isHalf ? '100%' : '50%')}; 
  };

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}){
      width: 100%;
  };
`;

export const StepContentInputArea = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;
export const StepAddressContentInputArea = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
`;

export const StepContentInputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StepContentButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    width: auto;
  }
`;

export const TableFilesWrapper = styled.div`
  width: 100%;

  thead {
    th {
      padding: 20px !important;
    }
  }

  tbody {

    tr {
      td {
        padding: 24px !important;
        width: 50%;
        &:first-of-type {
          width: 40% !important;
        }
      }

    }

    tr:nth-of-type(odd) {
      background-color: #fff !important;
    }
  }
  
`;

export const AboutStepExistingBusiness = styled.div`
  text-align: left;
  border: 1px solid #E6E5EA;
  padding: 16px;
  border-radius: 10px;
  span {
    display: block;
  }

  .link {
    display: block;

    font-size: 16px;
    text-decoration: none;
    color: ${({ theme }) => theme.palette?.brand?.primary.base};
    font-weight: bold;
    margin-top: 8px;
  }
`;

export const TableHeadFile = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 6px;
    cursor: pointer;
  }
`;

export const TableBodyFile = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 18px;
  }
`;

export const UploadArea = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export const InfoArea = styled.div`
  display: flex;
  margin-top: 6px;
`;

export const VariablesArea = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  border: 1px solid #E6E5EA;
  border-radius: 10px;
  padding: 24px;
  overflow: auto;
  height: 500px;
`;

export const InputItem = styled.div`
  width: 50%;
  margin-top: 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BusinessRelationsList = styled.div`
  background: #FFFFFF;
  border-radius: 20px;
  padding: 24px;
  border: 1px solid #E6E5EA;
  box-shadow: 0px 2px 23px -8px rgba(31, 30, 35, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  height: 584px;
`;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  margin-top: 32px;
  justify-content: flex-end;
`;

export const BillingAddressCheckboxContainer = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const BillingAddressContainer = styled.div`
  margin-top: 32px;
`;
