import React, { createContext, useContext, useReducer } from 'react';
import { IHeaderContext } from './header.interfaces';
import { headerActionType } from './headerActionTypes';
import { headerInitialState, headerReducer } from './headerReducer';

export const HeaderContext = createContext<IHeaderContext | undefined>(
  undefined,
);

export interface IHeaderProviderProps {
  children?: React.ReactNode;
}

export const HeaderProvider = ({ children }: IHeaderProviderProps) => {
  const [state, dispatch] = useReducer(headerReducer, headerInitialState);

  const value = { state, dispatch };

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export const useHeaderContext = () => {
  const context = useContext(HeaderContext);

  const resetTitle = () => {
    context?.dispatch({
      type: headerActionType.RESET_TITLE,
    });
  };

  const setTitle = (title: string) => {
    context?.dispatch({
      type: headerActionType.SET_TITLE,
      payload: title,
    });
  };

  if (context === undefined) {
    throw new Error('useHeaderContext must be used within a HeaderProvider');
  }
  return { resetTitle, setTitle, ...context };
};
