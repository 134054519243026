import React, { createContext, useContext, useReducer } from 'react';
import { ICustomerContext } from './customer.interfaces';
import { customerInitialState, customerReducer } from './customerReducer';

export const CustomerContext = createContext<ICustomerContext | undefined>(
  undefined,
);

export interface ICustomerProviderProps {
  children?: React.ReactNode;
}

export const CustomerProvider = ({ children }: ICustomerProviderProps) => {
  const [state, dispatch] = useReducer(customerReducer, customerInitialState);

  const value = { state, dispatch };

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomerContext = () => {
  const context = useContext(CustomerContext);

  if (context === undefined) {
    throw new Error(
      'useCustomerContext must be used within a CustomerProvider',
    );
  }
  return context;
};
