import { useEffect } from 'react';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import {
  useBreadcrumbsContext,
  useHeaderContext,
} from '../../../../app/context';
import { NaturalPersonListPageContainer } from './styles';
import { NaturalPersonListPageContent } from './components';

const NaturalPersonListPage = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const { setTitle, resetTitle } = useHeaderContext();

  useEffect(() => {
    setItems(
      [
        { to: '/records/natural', label: 'Cadastros' },
        { to: '/records/natural', label: 'Pessoas' },
      ],
      <GroupAddOutlinedIcon />,
    );
    return resetBreadcrumbs;
  }, []);

  useEffect(() => {
    setTitle('Pessoas');
    return resetTitle;
  }, []);

  return (
    <NaturalPersonListPageContainer>
      <NaturalPersonListPageContent />
    </NaturalPersonListPageContainer>
  );
};

export default NaturalPersonListPage;
