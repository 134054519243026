import { ToastDetails } from 'app/@types/dom';
import { useEffect, useState, useCallback } from 'react';
import { ToastContainerWrapper } from './styles';
import { TMessageType, toastEventManager } from './toastEventManager';
import { ToastMessage } from './ToastMessage';

interface IToastContainer {
  position: 'top-center';
}

interface IToastMessage {
  id: number;
  type: TMessageType;
  text: string;
  duration?: number;
}

export function ToastContainer({ position = 'top-center' }: IToastContainer) {
  const [messages, setMessages] = useState<IToastMessage[]>([]);

  useEffect(() => {
    function handleAddToast(event: ToastDetails) {
      const { type, text, duration } = event;

      setMessages((prevState) => [
        ...prevState,
        { id: Math.random(), type, text, duration },
      ]);
    }

    function handleAddToastCopyLink() {
      setMessages((prevState) => [
        ...prevState,
        {
          id: Math.random(),
          type: 'link',
          text: 'Link copiado para área de transferência.',
          duration: 5000,
        },
      ]);
    }

    function handleAddToastCopy() {
      setMessages((prevState) => [
        ...prevState,
        {
          id: Math.random(),
          type: 'copy',
          text: 'Copiado para área de transferência.',
          duration: 5000,
        },
      ]);
    }

    toastEventManager.on('addtoast', handleAddToast);
    toastEventManager.on('addtoastCopyLink', handleAddToastCopyLink);
    toastEventManager.on('addtoastCopy', handleAddToastCopy);

    return () => {
      toastEventManager.removeListener('addtoast', handleAddToast);
      toastEventManager.removeListener('addtoastCopy', handleAddToastCopy);
      toastEventManager.removeListener(
        'addtoastCopyLink',
        handleAddToastCopyLink,
      );
    };
  }, []);

  const handleRemoveMessage = useCallback((id: number) => {
    setMessages((prevState) =>
      prevState.filter((message) => message.id !== id),
    );
  }, []);

  return (
    <ToastContainerWrapper className="container top-center">
      {messages.map((message) => (
        <ToastMessage
          key={message.id}
          message={message}
          onRemoveMessage={handleRemoveMessage}
          position={position}
        />
      ))}
    </ToastContainerWrapper>
  );
}
