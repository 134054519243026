import { Box, useTheme } from '@mui/material';
import { CopyIcon, HyperlinkIcon } from 'app/components/Icons';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ButtonPrimary,
  Heading3,
  TextM,
} from '../../../../../../app/components';
import { UsersAccessDetailsTab } from '../UsersAccessDetailsTab';
import {
  UsersAccessDetailsHeader,
  UsersAccessDetailsSection,
  UsersAccessDetailsHeaderButtons,
  Container,
  TabsArea,
} from './styles';
import { routingPath } from 'app/routes';
import {
  UserAccess,
  UserValidPermissions,
} from 'modules/management/interfaces/usersAccess';
import { UsersAccessPermissionsTab } from '../UsersAccessPermissionsTab';
import { UsersAccessSettingsTab } from '../UsersAccessSettingsTab';
import { CustomTabs } from 'app/components/CustomTabs';

interface IUsersAccessDetailsContent {
  userAccess: UserAccess;
  updateUserAccess: (newUserAccess: UserAccess) => Promise<void>;
}

const UsersAccessDetailsContent = ({
  userAccess,
  updateUserAccess,
}: IUsersAccessDetailsContent) => {
  const theme = useTheme();
  const { showSnackbarCopy, showSnackbarCopyLink } = useSnackbar();
  const [currentTabValue, setCurrentTabValue] = useState(0);
  const navigate = useNavigate();
  const locationRouter = useLocation();
  const params = useParams();
  const permissions = userAccess.permissions.filter((permission) =>
    UserValidPermissions.includes(permission),
  );
  const tabItems = [
    {
      title: 'Sobre',
      route: '/details',
    },
    {
      title: `Permissões (${permissions.length})`,
      route: '/permissions',
    },
    {
      title: 'Configurações',
      route: '/settings',
    },
  ];

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(location.href);
    showSnackbarCopyLink();
  }, [userAccess, location]);

  const handleCopyId = useCallback(() => {
    navigator.clipboard.writeText(userAccess.id);
    showSnackbarCopy();
  }, [userAccess]);

  useEffect(() => {
    if (!params.tabRoute) {
      navigate(`${locationRouter.pathname}/details`);
    }
  }, []);

  const applicationDetailsTabsValueContent = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        return (
          <UsersAccessDetailsTab
            userAccess={userAccess}
            updateUserAccess={updateUserAccess}
          />
        );
      case 1:
        return (
          <UsersAccessPermissionsTab
            userAccess={userAccess}
            updateUserAccess={updateUserAccess}
          />
        );
      case 2:
        return (
          <UsersAccessSettingsTab
            userAccess={userAccess}
            updateUserAccess={updateUserAccess}
          />
        );
    }
    return null;
  };

  return (
    <Container>
      <UsersAccessDetailsSection>
        <UsersAccessDetailsHeader>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Heading3 fontWeight="Sb">{`${userAccess.full_name}`}</Heading3>
              <TextM>{`ID usuário: ${userAccess.id}`}</TextM>
            </div>
            <UsersAccessDetailsHeaderButtons>
              <ButtonPrimary
                style={{ backgroundColor: 'transparent' }}
                typeVariant="outline"
                btnIcon={
                  <CopyIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                }
                iconPosition="left"
                onClick={handleCopyId}
              >
                <TextM
                  weight="Semibold"
                  style={{
                    marginLeft: '11px',
                    color: theme.palette.brand.primary.base,
                  }}
                >
                  Copiar ID
                </TextM>
              </ButtonPrimary>
              <ButtonPrimary
                style={{ backgroundColor: 'transparent' }}
                typeVariant="outline"
                btnIcon={
                  <HyperlinkIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                }
                iconPosition="left"
                onClick={handleCopyLink}
              >
                <TextM
                  weight="Semibold"
                  style={{
                    marginLeft: '11px',
                    color: theme.palette.brand.primary.base,
                  }}
                >
                  Copiar Link
                </TextM>
              </ButtonPrimary>
            </UsersAccessDetailsHeaderButtons>
          </div>
          <TabsArea>
            <CustomTabs
              value={currentTabValue}
              route={routingPath.management.accessView}
              items={tabItems}
              variant="scrollable"
              handleChange={(value) => setCurrentTabValue(value)}
              showBorder={false}
            />
          </TabsArea>
        </UsersAccessDetailsHeader>
        <Box>{applicationDetailsTabsValueContent(currentTabValue)}</Box>
      </UsersAccessDetailsSection>
    </Container>
  );
};

export default UsersAccessDetailsContent;
