import styled from '@emotion/styled';

export const ProductCardStyle = {
  width: '100%',
  maxWidth: '464px',
  padding: '32px 24px',
  height: '199px',
  cursor: 'pointer',
  boxShadow:
    '0px 0px 4px -6px rgba(0, 0, 0, 0.08), 0px 16px 24px -4px rgba(0, 0, 0, 0.04)',
  borderRadius: '10px',
};

export const ProductCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & svg {
    color: ${({ theme }) => theme.palette?.others?.red} !important;
  }
  & h3 {
    color: ${({ theme }) => theme.palette?.brand?.primary.base} !important;
  }
`;

export const ProductCardContent = styled.div`
  margin-top: 26px;
`;
