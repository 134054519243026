import styled from '@emotion/styled';

export const UploadContainer = styled.div`
  width: 100%;
  border: 1px dashed ${({ theme }) => theme.palette?.brand.primary.base};
  background-color: ${({ theme }) => theme.palette?.brand.background.base};
  border-radius: 16px;
  `;

export const UploadContent = styled.div`
  width: 100%;
  padding: 27px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  input {
    display: none;
  }

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      flex-direction: column;
      gap: 10px;
  };
`;

export const Col = styled.div`
  display: flex;
  align-items: center;
`;
