import { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { ButtonPrimary } from 'app/components';
import {
  ButtonArea,
  ModalHeader,
  ModalSection,
  SectionContent,
  ApprovePermissionConfigContainer,
} from './styles';
import * as yup from 'yup';
import { BaseModal, Heading3, TextL } from 'celcoin-design-system';
import { formatCurrency, formatToCurrencyFloat } from 'app/utils/normalizer';
import { CustomCurrencyInput } from 'app/components/CustomCurrencyInput';
import { formatBRLCurrencyMaskInput } from 'app/utils/currency';
import { UserAccess } from 'modules/management/interfaces/usersAccess';
import {
  AccountPermissionProperties,
  EAccountPermissions,
} from 'modules/auth/context/permify/permify.interfaces';

const ApprovePermissionConfigValidation = yup.object({
  amount: yup.string(),
});

export interface IApprovePermissionConfig {
  isOpen: boolean;
  userAccess: UserAccess;
  handleSave: (params: AccountPermissionProperties) => void;
}

const ApprovePermissionConfig = ({
  isOpen,
  userAccess,
  handleSave,
}: IApprovePermissionConfig) => {
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      amount: formatCurrency(
        userAccess.permission_properties?.[
          EAccountPermissions.APPROVE_APPLICATION
        ]?.amount ?? 0,
        false,
      ),
    },
    validationSchema: ApprovePermissionConfigValidation,
    onSubmit: (values) => {
      handleSave({
        ...(userAccess.permission_properties ?? {}),
        [EAccountPermissions.APPROVE_APPLICATION]: {
          amount:
            typeof values.amount === 'string'
              ? Number(formatToCurrencyFloat(values.amount))
              : 0,
        },
      });
    },
  });

  useEffect(() => {
    if (isOpen) {
      formik.setValues({
        amount: formatCurrency(
          userAccess.permission_properties?.[
            EAccountPermissions.APPROVE_APPLICATION
          ]?.amount ?? 0,
          false,
        ),
      });
    }
  }, [isOpen]);

  const handleAmount = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      formik.setFieldValue(
        'amount',
        formatBRLCurrencyMaskInput(evt.target.value || ''),
      );
    },
    [],
  );

  const handleClose = useCallback(() => {
    handleSave({
      ...(userAccess.permission_properties ?? {}),
      [EAccountPermissions.APPROVE_APPLICATION]: {
        amount: 0,
      },
    });
  }, [handleSave]);

  return (
    <BaseModal
      open={isOpen}
      onClose={() =>
        handleSave({
          ...(userAccess.permission_properties ?? {}),
          [EAccountPermissions.APPROVE_APPLICATION]: {
            amount:
              userAccess.permission_properties?.[
                EAccountPermissions.APPROVE_APPLICATION
              ]?.amount ?? 0,
          },
        })
      }
    >
      <ApprovePermissionConfigContainer>
        <ModalHeader>
          <Heading3>Volume financeiro para aprovação</Heading3>
        </ModalHeader>
        <TextL style={{ marginTop: '15px' }}>
          Defina o volume financeiro máximo das solicitações que o usuário pode
          aprovar. Em caso de valor igual a R$ 0,00 o usuário poderá aprovar
          todas as solicitações.
        </TextL>
        <ModalSection>
          <SectionContent>
            <div style={{ display: 'flex', gap: '16px' }}>
              <CustomCurrencyInput
                labelValue="Valor"
                value={formik.values.amount ?? '0,00'}
                handleInputChange={handleAmount}
                onBlurCapture={formik.handleBlur}
                validationError={
                  formik.touched.amount ? formik.errors.amount : ''
                }
              />
            </div>
          </SectionContent>
        </ModalSection>
        <ButtonArea>
          <ButtonPrimary
            typeVariant="outline"
            style={{ border: 'none' }}
            onClick={handleClose}
          >
            Não aplicar limite
          </ButtonPrimary>
          <ButtonPrimary disabled={!formik.isValid} onClick={formik.submitForm}>
            Aplicar limite
          </ButtonPrimary>
        </ButtonArea>
      </ApprovePermissionConfigContainer>
    </BaseModal>
  );
};

export default ApprovePermissionConfig;
