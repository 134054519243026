import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { ButtonDefault, ButtonPrimary } from '../../../../../app/components';
import { StepContent } from '../../../components';
import {
  BillingAddressContainer,
  StepAddressContentInputArea,
  StepContentButtonsArea,
  StepContentInput,
  StepContentInputGroup,
} from './styles';
import { ArrowBack } from '@mui/icons-material';
import { LegalPersonHandlerStepperContext } from '../LegalPersonHandler';
import { useCustomerContext } from '../../../context';
import { useCustomer } from '../../../hooks';
import { BRAZILIAN_STATES, formatCep } from '../../../../../app/helpers';
import { billingAddressStepSchema } from '../../../helpers/businessValidation';
import { CustomSelect, CustomInput } from 'celcoin-design-system';
import { useAddress } from 'app/hooks';

const BillingAddressStep = () => {
  const { onBack, onForward } = useContext(LegalPersonHandlerStepperContext);
  const [billingAddressCitiesList, setBillingAddressCitiesList] = useState<
    { value: string }[]
  >([]);
  const { fetchCities, fetchAddressByPostalCode, isFetchingPostalCode } =
    useAddress();
  const [billingAddressStateCode, setBillingAddressStateCode] = useState('');
  const [billingAddressCityManually, setBillingAddressCityManually] =
    useState(true);
  const {
    state: { registerData },
  } = useCustomerContext();
  const { setBusinessData } = useCustomer();
  const formik = useFormik({
    initialValues: {
      billing_address_postal_code:
        registerData.billing_address?.postal_code || '',
      billing_address_street_name:
        registerData.billing_address?.street_name || '',
      billing_address_street_number:
        registerData.billing_address?.street_number || '',
      billing_address_extra_info:
        registerData.billing_address?.extra_info || '',
      billing_address_district: registerData.billing_address?.district || '',
      billing_address_state_code: registerData.billing_address?.state_code || {
        value: '',
        label: '',
      },
      billing_address_city: registerData.billing_address?.city || {
        value: '',
        label: '',
      },
      billing_address_cityManually: registerData.billing_address?.city.value,
      billing_address_references: '',
      billingAddressCityManually:
        registerData.billing_address?.city.value || '',
    },
    validationSchema: billingAddressStepSchema,
    onSubmit: async (values) => {
      setBusinessData({
        billing_address: {
          street_name: values.billing_address_street_name,
          street_number: values.billing_address_street_number,
          postal_code: values.billing_address_postal_code,
          district: values.billing_address_district,
          extra_info: values.billing_address_extra_info,
          state_code: values.billing_address_state_code,
          city: values.billing_address_city || {
            label: '',
            value: '',
          },
          ...((billingAddressCitiesList?.length <= 0 && {
            city: {
              value: values.billingAddressCityManually || '',
              label: values.billingAddressCityManually || '',
            },
          }) ||
            ''),
          country_code: {
            label: 'Brasil',
            value: 'BRA',
          },
        },
      });
      onForward();
    },
  });

  const parseCities = (cities: { name: string; id: string }[]) => {
    return cities.map((item) => {
      return {
        value: item.name,
        label: item.name,
      };
    });
  };

  const handleBillingAddressCities = async (stateCodeFound?: string) => {
    setBillingAddressCityManually(false);
    try {
      const result = await fetchCities(
        stateCodeFound ?? billingAddressStateCode,
      );
      if (!result || result.data.status !== '200') {
        setBillingAddressCityManually(true);
        return [];
      }

      const parsedResult = parseCities(result.data?.body?.city);
      setBillingAddressCitiesList(parsedResult || []);
      return parsedResult;
    } catch (error) {
      setBillingAddressCityManually(true);
    }
  };

  const handleBillingAddressZipode = async (value: string) => {
    if (value.length < 9) return;
    const res = await fetchAddressByPostalCode(value);
    if (res && res.data.status === '200') {
      const values = res.data.body.address;
      const stateCodeFound = BRAZILIAN_STATES.find(
        (state) =>
          state.value.toLocaleLowerCase() === values.state.toLocaleLowerCase(),
      )?.value;
      formik.setFieldValue('billing_address_district', values.neighborhood);
      formik.setFieldValue('billing_address_street_name', values.street);
      formik.setFieldValue(
        'billing_address_state_code',
        BRAZILIAN_STATES.find(
          (state) =>
            state.value.toLocaleLowerCase() ===
            values.state.toLocaleLowerCase(),
        ),
      );

      const resCities = await handleBillingAddressCities(stateCodeFound || '');
      if (resCities && resCities.length > 0) {
        const foundCity = resCities.find(
          (item: { value: string; label: string }) =>
            item.value.toLowerCase() === values.city.toLowerCase(),
        );
        formik.setFieldValue('billing_address_city', foundCity);
        return;
      }
    }
    setBillingAddressCityManually(true);
  };

  const handleBillingAddressNumber = (numberValue: string) => {
    formik.setFieldValue(
      'billing_address_street_number',
      numberValue.replace(/(-(?!\d))|[^0-9|-]/g, '') || '',
    );
  };

  return (
    <>
      <StepContent
        title="Endereço"
        description="Cadastre o endereço de cobrança"
      >
        <BillingAddressContainer>
          <StepAddressContentInputArea>
            <StepContentInput>
              <CustomInput
                name="billing_address_postal_code"
                value={formatCep(formik.values.billing_address_postal_code)}
                isLoading={isFetchingPostalCode}
                placeholder="Ex.: 00000-000"
                handleInputChange={formik.handleChange}
                onBlur={(evt) => {
                  formik.handleBlur(evt);
                  if (formik.values.billing_address_postal_code)
                    handleBillingAddressZipode(
                      formik.values.billing_address_postal_code,
                    );
                }}
                labelValue="CEP"
                validationError={
                  formik.touched.billing_address_postal_code
                    ? formik.errors.billing_address_postal_code
                    : ''
                }
              />
            </StepContentInput>

            <StepContentInputGroup>
              <StepContentInput>
                <CustomInput
                  name="billing_address_street_name"
                  value={formik.values.billing_address_street_name}
                  handleInputChange={formik.handleChange}
                  placeholder="Ex.: Av Presidente Vargas"
                  labelValue="Rua/Avenida"
                  validationError={
                    formik.touched.billing_address_street_name
                      ? formik.errors.billing_address_street_name
                      : ''
                  }
                />
              </StepContentInput>
              <StepContentInput>
                <CustomInput
                  name="billing_address_street_number"
                  value={formik.values.billing_address_street_number}
                  handleInputChange={(e) =>
                    handleBillingAddressNumber(e.target.value)
                  }
                  placeholder="Ex.: 300"
                  labelValue="Número"
                  validationError={
                    formik.touched.billing_address_street_number
                      ? formik.errors.billing_address_street_number
                      : ''
                  }
                />
              </StepContentInput>
            </StepContentInputGroup>
            <StepContentInput>
              <CustomInput
                name="billing_address_extra_info"
                value={formik.values.billing_address_extra_info}
                handleInputChange={formik.handleChange}
                placeholder="Ex.: Próximo a escola..."
                labelValue="Complemento (opcional)"
                validationError={
                  formik.touched.billing_address_extra_info
                    ? formik.errors.billing_address_extra_info
                    : ''
                }
              />
            </StepContentInput>
            <StepContentInput>
              <CustomInput
                name="billing_address_district"
                value={formik.values.billing_address_district}
                handleInputChange={formik.handleChange}
                placeholder="Ex.: Planalto do Sol"
                labelValue="Bairro"
                validationError={
                  formik.touched.billing_address_district
                    ? formik.errors.billing_address_district
                    : ''
                }
              />
            </StepContentInput>
            <StepContentInputGroup>
              <StepContentInput marginTop="15px">
                <CustomSelect
                  labelValue="Estado"
                  name="billing_address_state_code"
                  placeholder="Selecione o estado"
                  value={
                    formik.values.billing_address_state_code?.value === ''
                      ? undefined
                      : formik.values.billing_address_state_code
                  }
                  handleInputChange={(v) => {
                    formik.handleChange(v);
                    setBillingAddressStateCode(v.currentTarget.value.value);
                  }}
                  options={BRAZILIAN_STATES}
                  validationError={
                    formik.touched.billing_address_state_code?.value
                      ? formik.errors.billing_address_state_code?.value
                      : ''
                  }
                />
              </StepContentInput>
              <StepContentInput marginTop="15px">
                {billingAddressCityManually ? (
                  <CustomInput
                    name="billingAddressCityManually"
                    value={formik.values.billingAddressCityManually}
                    handleInputChange={(evt) => {
                      formik.setFieldValue(
                        'billingAddressCityManually',
                        evt.target.value,
                      );
                      formik.setFieldValue('billing_address_city', {
                        label: evt.target.value,
                        value: evt.target.value,
                      });
                    }}
                    placeholder="Cidade"
                    labelValue="Cidade"
                    validationError={
                      formik.touched.billing_address_city?.value
                        ? formik.errors.billing_address_city?.value
                        : ''
                    }
                  />
                ) : (
                  <CustomSelect
                    labelValue="Cidade"
                    name="billing_address_city"
                    placeholder="Selecione a cidade"
                    value={
                      formik.values.billing_address_city?.value === ''
                        ? undefined
                        : formik.values.billing_address_city
                    }
                    handleInputChange={formik.handleChange}
                    options={billingAddressCitiesList}
                    validationError={
                      formik.touched.billing_address_city?.value
                        ? formik.errors.billing_address_city?.value
                        : ''
                    }
                  />
                )}
              </StepContentInput>
            </StepContentInputGroup>
            <StepContentInput isFull={true}>
              <CustomInput
                name="billing_address_references"
                value={formik.values.billing_address_references}
                handleInputChange={formik.handleChange}
                placeholder="Digite aqui outras informações"
                labelValue="Referências / Informações adicionais (opcional)"
                validationError={
                  formik.touched.billing_address_references
                    ? formik.errors.billing_address_references
                    : ''
                }
              />
            </StepContentInput>
          </StepAddressContentInputArea>
        </BillingAddressContainer>

        <StepContentButtonsArea>
          <ButtonDefault margin={true} onClick={() => onBack()}>
            <ArrowBack />
          </ButtonDefault>

          <ButtonPrimary onClick={formik.submitForm}>Avançar</ButtonPrimary>
        </StepContentButtonsArea>
      </StepContent>
    </>
  );
};

export default BillingAddressStep;
