const MagnifyingGassIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0837 0.33042C4.69875 0.330421 0.333343 4.69583 0.333343 10.0808C0.333343 15.4658 4.69875 19.8312 10.0837 19.8312C12.2954 19.8312 14.3352 19.0948 15.9711 17.8538L19.3934 21.2761C19.9141 21.7968 20.7584 21.7968 21.2791 21.2761C21.7998 20.7554 21.7998 19.9112 21.2791 19.3905L17.8567 15.9681C19.0977 14.3322 19.8341 12.2925 19.8341 10.0808C19.8341 4.69583 15.4687 0.33042 10.0837 0.33042ZM3.00001 10.0808C3.00001 6.16859 6.17151 2.99709 10.0837 2.99709C13.9959 2.99709 17.1674 6.16859 17.1674 10.0808C17.1674 13.993 13.9959 17.1645 10.0837 17.1645C6.17151 17.1645 3.00001 13.993 3.00001 10.0808Z"
        fill={color}
      />
    </svg>
  );
};

export default MagnifyingGassIcon;
