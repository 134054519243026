const UncheckIcon = () => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8295 27.5C12.1597 27.4999 9.5498 26.7081 7.32988 25.2248C5.10982 23.7414 3.37949 21.633 2.35771 19.1662C1.33593 16.6994 1.06858 13.985 1.58948 11.3663C2.11038 8.74754 3.39613 6.34207 5.28414 4.45406C7.17215 2.56606 9.57762 1.28031 12.1964 0.759405C14.8151 0.238505 17.5295 0.505849 19.9963 1.52763C22.4631 2.54942 24.5715 4.27974 26.0549 6.49981C27.5382 8.71972 28.33 11.3296 28.3301 13.9995C28.3261 17.5788 26.9025 21.0104 24.3715 23.5414C21.8405 26.0724 18.4089 27.4961 14.8295 27.5Z"
      stroke="#9490A3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9974 9.62248C19.1828 9.43464 19.4312 9.3307 19.689 9.33305C19.9468 9.3354 20.1934 9.44385 20.3757 9.63504C20.558 9.82624 20.6615 10.0849 20.6637 10.3553C20.6659 10.6256 20.5668 10.8861 20.3877 11.0806L20.3842 11.0844L13.4437 18.3632C13.3524 18.4591 13.2437 18.5355 13.1244 18.5875C13.0049 18.6395 12.8769 18.6663 12.7475 18.6663C12.4872 18.6663 12.2375 18.558 12.0532 18.3652L9.27306 15.4496C9.09394 15.2551 8.99487 14.9946 8.99711 14.7242C8.99935 14.4538 9.10276 14.1952 9.28507 14.004C9.46738 13.8128 9.714 13.7043 9.97181 13.702C10.2296 13.6996 10.478 13.8036 10.6635 13.9914L10.6671 13.9951L12.7475 16.1769L18.9974 9.62248Z"
      fill="#9490A3"
    />
  </svg>
);

export default UncheckIcon;
