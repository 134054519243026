import { TProducts } from '../../../products/context';
import {
  ETaxRegime,
  ICustomerAction,
  ICustomerState,
} from './customer.interfaces';
import { customerActionType } from './customerActionType';

export const customerInitialState: ICustomerState = {
  func: {},
  product: {} as TProducts,
  registerData: {
    foundation_date: '',
    email_address: '',
    legal_name: '',
    phone: {
      area_code: '',
      country_code: '',
      number: '',
    },
    taxpayer_id: '',
    tax_regime: {
      value: ETaxRegime.SIMPLE,
      label: 'Simples Nacional',
    },
    address: {
      city: {
        label: '',
        value: '',
      },
      state_code: {
        label: '',
        value: '',
      },
      country_code: {
        label: 'Brasil',
        value: 'BRA',
      },
      district: '',
      extra_info: '',
      postal_code: '',
      street_name: '',
      street_number: '',
    },
  },
};

export const customerReducer = (
  state: ICustomerState = customerInitialState,
  action: ICustomerAction,
) => {
  switch (action.type) {
    case customerActionType.SET_REGISTER_BUSINESS_DATA:
      return {
        ...state,
        registerData: {
          ...state.registerData,
          ...action.payload,
        },
      };
    case customerActionType.SET_PRODUCT_DATA:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
        },
      };
    case customerActionType.SET_FUNCTION_TYPE:
      return {
        ...state,
        func: {
          ...action.payload,
        },
      };
    case customerActionType.RESET_STATE:
      return customerInitialState;

    default:
      return state;
  }
};
