import styled from '@emotion/styled';

export const WithdrawReceiptModalContainer = styled.div`
  width: 100%;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 504px;
  margin-top: 24px;
`;

export const ModalFooter = styled.div<{
  isColumnButtons?: boolean;
  isLoading?: boolean;
}>`
    margin-top: 32px;
    display: flex;
    justify-content: ${({ isLoading }) =>
      isLoading ? 'center' : 'space-between'};
    width: 100%;
    flex-direction: ${({ isColumnButtons }) =>
      isColumnButtons ? 'column-reverse' : 'row'};
`;
