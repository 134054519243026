import { useTheme } from '@mui/material';
import { formatToCurrencyFloat } from 'app/utils/normalizer';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { useCustomerService } from 'modules/customer/services';
import {
  TConditionItems,
  TQualificationStatusOption,
} from 'modules/products/pages/interfaces';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomTableSelect,
  ButtonPrimary,
  Spinner,
  BoxSection,
  ButtonDanger,
  ConfirmModal,
} from '../../../../../../app/components';
import {
  TextS,
  TextXs,
} from '../../../../../../app/components/typography/body';
import { TextL } from '../../../../../../app/components/typography/body/TextL';
import { roleTypesTranslationParse } from '../../../../../../app/helpers';
import {
  formatDateFromBackend,
  formatStringHourFromBackend,
} from '../../../../../../app/helpers/dateHelpers';
import { ButtonContainer, TableArea, TableWrapper } from './styles';
import { Heading3, Table, TextM } from 'celcoin-design-system';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { useQueryParam, NumberParam, withDefault } from 'use-query-params';
import { AddIcon, DetailsIcon } from 'app/components/Icons';
import { TProducts, useQualificationsContext } from 'modules/products/context';
import {
  useProductsService,
  useQualificationsService,
} from 'modules/products/services/hooks';
import { EQualificationStatus } from 'modules/products/context/QualificationsProvider/qualifications.interfaces';
import { EmptyContent } from 'modules/products/pages/ProductDetail/components/EmptyContent';
import { ConditionsModal } from 'modules/products/pages/ProductDetail/components/ConditionsModal';
import {
  ConditionsRequiredProperty,
  TQualification,
  TQualificationWithStatusAndProduct,
} from 'modules/customer/interfaces/qualifications';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import { PendenciesModal } from 'modules/customer/components/PendenciesModal';
import { useUploadBusinessFiles } from 'modules/customer/hooks';
import get from 'lodash.get';
import { TRegisterBusinessDataRequest } from 'modules/customer/context';
import { routingPath } from 'app/routes';
import { AttentionCircularRedIcon } from 'app/components/Icons/AttentionCircularIcon';
import { EditConditionSidesheet } from 'modules/products/pages/ProductDetail/components/EditConditionSidesheet';
import LegalPersonViewQualificationsSideSheet from '../LegalPersonViewQualificationsSideSheet/LegalPersonViewQualificationsSideSheet';
import { Condition } from 'modules/products/services/hooks/intefaces';
import { formatInterestRate } from 'modules/products/helpers/interestRate';

type THandleApproveCheck = {
  handleApproveParams: TConditionItems;
  fieldsToWarn: string[];
};

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        {' '}
        <TextL weight="bold">Produto</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Função</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Criado em</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer style={{ textAlign: 'center' }}>
        <TextL weight="bold">Situação de preenchimento</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer style={{ textAlign: 'center', width: '100%' }}>
        <TextL weight="bold">Status</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  { id: '6', content: <div /> },
];

interface ILegalPersonViewQualificationsTabProps {
  business: TRegisterBusinessDataRequest;
}
const LegalPersonViewQualificationsTab = ({
  business,
}: ILegalPersonViewQualificationsTabProps) => {
  const theme = useTheme();
  const params = useParams();
  const {
    state: { borrowerStatusOptions },
  } = useQualificationsContext();
  const navigate = useNavigate();
  const {
    getQualificationStatusOptions,
    updateQualificationStatus,
    updateLimitsCondition,
    getProductQualificationsLoading,
    updateQualificationStatusLoading,
    updateLimitsConditionLoading,
  } = useQualificationsService();
  const { getProduct, getCustomVariables } = useProductsService();
  const [qualifications, setQualifications] = useState<
    TQualificationWithStatusAndProduct[]
  >([]);
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [totalItemsQty, setTotalItemsQty] = useState(10);
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );
  const [pendenciesModalOpen, updatePendenciesModalOpen] = useState<
    TQualificationWithStatusAndProduct | undefined
  >(undefined);
  const [
    selectedQualificationForCondition,
    setSelectedQualificationForCondition,
  ] = useState<
    (TQualificationWithStatusAndProduct & { identifier: string }) | undefined
  >();
  const { getQualifications } = useCustomerService();
  const [finishRequest, setFinishRequest] = useState(false);
  const { isProfileAuthorized } = usePermify();
  const { uploadedFiles, fetchBusinessFile } = useUploadBusinessFiles();
  const [handleApproveCheck, updateHandleApproveCheck] =
    useState<THandleApproveCheck | null>(null);
  const [selectedQualification, setSelectedQualification] = useState<
    TQualificationWithStatusAndProduct | undefined
  >();
  const [
    selectedQualificationForConditionEdit,
    setSelectedQualificationForConditionEdit,
  ] = useState<TQualificationWithStatusAndProduct | undefined>();
  const pid = params?.id as string;

  const handleGetBorrowerQualification = async (
    personId: string,
    page = 0,
    itemsPerPage?: number,
  ) => {
    const businessUploadedFiles =
      uploadedFiles.length === 0
        ? await fetchBusinessFile(personId)
        : uploadedFiles;

    const borrowerContent = await getQualifications(
      personId,
      'BORROWER',
      page,
      itemsPerPage,
    );
    const employerContent = await getQualifications(
      personId,
      'EMPLOYER',
      page,
      itemsPerPage,
    );
    if (borrowerContent) {
      setTotalItemsQty(
        (borrowerContent?.total_elements || 0) +
          (employerContent?.total_elements || 0),
      );

      const qualificationsList = await Promise.all(
        ([] as Promise<TQualificationWithStatusAndProduct | null>[]).concat(
          borrowerContent.content.map(async (qualification) => {
            try {
              const statusList: TQualificationStatusOption[] =
                await getQualificationStatusOptions(
                  qualification.product.id,
                  'BUSINESS',
                );
              const productData = await getProduct(qualification.product.id);
              const customVariables = await getCustomVariables(
                qualification.product.id,
              );
              const missingDocuments =
                (
                  productData?.data as TProducts
                ).variables_configuration?.borrower.business_document.filter(
                  (document) => {
                    if (document.required) {
                      const inx = businessUploadedFiles.findIndex(
                        (businessUploadedFile) =>
                          document.type === businessUploadedFile.type,
                      );
                      return inx === -1;
                    }
                    return false;
                  },
                ) || [];

              const missingVariables =
                customVariables?.borrower.business.filter((variable) => {
                  if (variable.required) {
                    const variableValue = get(business, variable.name);
                    return typeof variableValue === 'undefined';
                  }
                  return false;
                }) || [];

              const missingConditions = (productData?.data as TProducts)
                .conditions_required
                ? ConditionsRequiredProperty.filter((condition) => {
                    const conditionValue = get(
                      qualification.condition,
                      condition.name,
                      null,
                    );
                    return conditionValue === null;
                  })
                : [];

              const missingBankOrPix =
                (!business.external_bank_account ||
                  !business.external_bank_account?.bank_account) &&
                (!business.pix || !business.pix?.key) &&
                (productData?.data as TProducts)?.beneficiary_type ===
                  'BORROWER';

              return {
                ...qualification,
                statusList,
                missingDocuments,
                missingConditions,
                missingVariables,
                missingBankOrPix,
                productData: productData?.data as TProducts,
              };
            } catch {
              return null;
            }
          }),
          employerContent
            ? employerContent.content.map(async (qualification) => {
                try {
                  const statusList: TQualificationStatusOption[] =
                    await getQualificationStatusOptions(
                      qualification.product.id,
                      'BUSINESS',
                    );
                  const productData = await getProduct(
                    qualification.product.id,
                  );
                  const customVariables = await getCustomVariables(
                    qualification.product.id,
                  );

                  const missingDocuments =
                    (
                      productData?.data as TProducts
                    ).variables_configuration?.employer_document.filter(
                      (document) => {
                        if (document.required) {
                          const inx = businessUploadedFiles.findIndex(
                            (businessUploadedFile) =>
                              document.type === businessUploadedFile.type,
                          );
                          return inx === -1;
                        }
                        return false;
                      },
                    ) || [];

                  const missingVariables =
                    customVariables?.employer.filter((variable) => {
                      if (variable.required) {
                        const variableValue = get(business, variable.name);
                        return typeof variableValue === 'undefined';
                      }
                      return false;
                    }) || [];

                  const missingBankOrPix =
                    (!business.external_bank_account ||
                      !business.external_bank_account?.bank_account) &&
                    (!business.pix || !business.pix?.key);

                  return {
                    ...qualification,
                    statusList,
                    missingDocuments,
                    missingConditions: [],
                    missingVariables,
                    missingBankOrPix,
                    productData: productData?.data as TProducts,
                  };
                } catch {
                  return null;
                }
              })
            : [],
        ),
      );
      setQualifications(
        qualificationsList.filter(
          (item) => item !== null,
        ) as TQualificationWithStatusAndProduct[],
      );
      setFinishRequest(true);
    }
  };

  useEffect(() => {
    setFinishRequest(false);
    handleGetBorrowerQualification(pid, currentPage, linesPerPage);
  }, [currentPage, linesPerPage]);

  const handleUpdateQualificationStatus = async (
    qualification: TQualificationWithStatusAndProduct,
    identifier: string,
  ) => {
    const productId = qualification?.product.id as string;
    if (identifier === 'APPROVED' || identifier === 'QUALIFIED') {
      if (
        qualification.missingDocuments.length > 0 ||
        qualification.missingVariables.length > 0 ||
        qualification.missingBankOrPix
      ) {
        updatePendenciesModalOpen(qualification);
        return;
      }
      if (
        qualification.productData.conditions_required &&
        qualification.missingConditions.length > 0
      ) {
        if (
          isProfileAuthorized(
            EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION_CONDITIONS,
          )
        ) {
          setSelectedQualificationForCondition({
            ...qualification,
            identifier,
          });
        }
        return;
      }
    }

    const result = await updateQualificationStatus(
      productId,
      qualification?.id || '',
      identifier,
      'BORROWER',
    );

    if (result) {
      setQualifications((state) =>
        state.map((element) => {
          if (element?.id === (qualification?.id || '')) {
            return {
              ...element,
              status: identifier,
            };
          } else {
            return element;
          }
        }),
      );
      if (selectedQualification) {
        setSelectedQualification({
          ...qualification,
          status: identifier,
        });
      }
    }
  };

  const handleEditCondition = (
    qualification: TQualificationWithStatusAndProduct | undefined,
  ) => {
    setSelectedQualificationForConditionEdit(qualification);
  };

  const checkHandleApprove = async (handleApproveParams: TConditionItems) => {
    const {
      creditLimit,
      interestRate,
      maxPaymentAmount,
      minRequestedAmount,
      maxGracePeriod,
      minGracePeriod,
      maxPaymentNumber,
      minPaymentNumber,
      qualification,
    } = handleApproveParams;
    const fieldsToWarn = [];
    if (
      qualification?.condition?.line_of_credit?.credit_limit &&
      (!creditLimit || creditLimit.length === 0)
    ) {
      fieldsToWarn.push('Limite total');
      handleApproveParams.creditLimit = undefined;
    }
    if (
      qualification?.condition?.schedule_based_conditions &&
      qualification?.condition?.schedule_based_conditions.length > 0 &&
      (!interestRate || interestRate.length === 0)
    ) {
      fieldsToWarn.push('Taxa de Juros');
      handleApproveParams.interestRate = undefined;
    }
    if (
      qualification?.condition?.line_of_credit?.max_payment_amount &&
      (!maxPaymentAmount || maxPaymentAmount.length === 0)
    ) {
      fieldsToWarn.push('Limite de parcela');
      handleApproveParams.maxPaymentAmount = undefined;
    }
    if (
      qualification?.condition?.min_requested_amount &&
      (!minRequestedAmount || minRequestedAmount.length === 0)
    ) {
      fieldsToWarn.push('Mínimo solicitação');
      handleApproveParams.minRequestedAmount = undefined;
    }
    if (
      qualification?.condition?.max_grace_period &&
      (!maxGracePeriod || maxGracePeriod.length === 0)
    ) {
      fieldsToWarn.push('Carência máxima');
      handleApproveParams.maxGracePeriod = undefined;
    }
    if (
      qualification?.condition?.min_grace_period &&
      (!minGracePeriod || minGracePeriod.length === 0)
    ) {
      fieldsToWarn.push('Carência mínima');
      handleApproveParams.minGracePeriod = undefined;
    }
    if (
      qualification?.condition?.max_payment_number &&
      (!maxPaymentNumber || maxPaymentNumber.length === 0)
    ) {
      fieldsToWarn.push('Prazo máximo');
      handleApproveParams.maxPaymentNumber = undefined;
    }
    if (
      qualification?.condition?.min_payment_number &&
      (!minPaymentNumber || minPaymentNumber.length === 0)
    ) {
      fieldsToWarn.push('Prazo mínimo');
      handleApproveParams.minPaymentNumber = undefined;
    }

    if (fieldsToWarn.length > 0) {
      updateHandleApproveCheck({
        handleApproveParams,
        fieldsToWarn,
      });
      return;
    }

    return handleApprove(handleApproveParams);
  };

  const handleApprove = async ({
    creditLimit,
    interestRate,
    maxPaymentAmount,
    qualification,
    minRequestedAmount,
    dueDay,
    maxGracePeriod,
    minGracePeriod,
    maxPaymentNumber,
    minPaymentNumber,
    tac,
  }: TConditionItems) => {
    const qualificationObject =
      selectedQualificationForCondition ||
      selectedQualificationForConditionEdit;
    const condition = {
      ...qualification?.condition,
      line_of_credit: {
        ...qualification?.condition?.line_of_credit,
        credit_limit: creditLimit
          ? Number(formatToCurrencyFloat(creditLimit || ''))
          : undefined,
        max_payment_amount:
          Number(formatToCurrencyFloat(maxPaymentAmount || '')) || undefined,
      },
      due_day: Number(dueDay) || undefined,
      min_requested_amount:
        Number(formatToCurrencyFloat(minRequestedAmount || '')) || undefined,
      max_grace_period: Number(maxGracePeriod) || undefined,
      min_grace_period: Number(minGracePeriod) || undefined,
      max_payment_number: Number(maxPaymentNumber) || undefined,
      min_payment_number: Number(minPaymentNumber) || undefined,
      tac: Number(formatToCurrencyFloat(tac || '')) || undefined,
      schedule_based_conditions: interestRate
        ? [
            {
              interest_rate: formatInterestRate(interestRate),
              upper_limit:
                (qualificationObject?.productData?.max_num_payments
                  ? qualificationObject?.productData?.max_num_payments
                  : Number(maxPaymentNumber)) || undefined,
            },
          ]
        : undefined,
    };
    const res = await updateLimitsCondition(
      qualificationObject?.productData?.id || '',
      qualification?.id || '',
      condition,
    );

    if (res) {
      if (selectedQualificationForCondition) {
        const result = await updateQualificationStatus(
          qualificationObject?.productData?.id || '',
          qualificationObject?.id || '',
          selectedQualificationForCondition?.identifier || '',
          'BORROWER',
        );

        if (result) {
          setQualifications((state) =>
            state.map((element) => {
              if (element?.id === (qualification?.id || '')) {
                return {
                  ...element,
                  status: selectedQualificationForCondition?.identifier || '',
                };
              } else {
                return element;
              }
            }),
          );
        }
      }

      if (selectedQualification) {
        setSelectedQualification({
          ...selectedQualification,
          condition: condition as Condition,
        });
      }
      setSelectedQualificationForCondition(undefined);
      setSelectedQualificationForConditionEdit(undefined);
    }
  };

  const getStatusList = useCallback(
    (statusList: TQualificationStatusOption[]) =>
      [
        {
          label: 'Aprovado',
          value: EQualificationStatus.APPROVED as string,
        },
      ].concat(
        statusList && statusList?.length > 0
          ? statusList?.map((status) => ({
              label: status.description,
              value: status.identifier,
            }))
          : [],
        {
          label: 'Reprovado',
          value: EQualificationStatus.DENIED as string,
        },
      ),
    [],
  );

  const isLoading = getProductQualificationsLoading;

  return (
    <BoxSection>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Heading3 style={{ color: theme.palette.brand.secondary.base }}>
          Linhas de Crédito
        </Heading3>
        <ButtonContainer>
          {isProfileAuthorized(EAccountPermissions.CREATE_PERSON_BUSINESS) && (
            <ButtonPrimary
              style={{ backgroundColor: 'transparent', width: '230px' }}
              typeVariant="outline"
              iconPosition="left"
              btnIcon={
                <AddIcon color={theme.palette.brand.primary.base as string} />
              }
              onClick={() =>
                navigate(`/${routingPath.qualification.new}`, {
                  state: {
                    business,
                  },
                })
              }
            >
              <TextM
                weight="Semibold"
                style={{
                  marginLeft: '11px',
                  color: theme.palette.brand.primary.base,
                }}
              >
                Criar Linha de Crédito
              </TextM>
            </ButtonPrimary>
          )}
        </ButtonContainer>
      </div>
      {isLoading || !finishRequest ? (
        <div
          style={{
            paddingTop: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
            width: '100%',
          }}
        >
          <Spinner />
        </div>
      ) : (!getProductQualificationsLoading && !borrowerStatusOptions) ||
        (qualifications?.length <= 0 && finishRequest) ? (
        <EmptyContent titleText="Nenhuma linha de crédito encontrada" />
      ) : (
        borrowerStatusOptions &&
        qualifications.length > 0 && (
          <TableArea style={{ marginTop: '40px' }}>
            <TableWrapper>
              <Table
                showPagination
                overflowVisible
                handlePageChange={(selectedPage) => {
                  setCurrentPage(selectedPage - 1);
                }}
                handleLinesPerPage={(selectedLinesPerPage) => {
                  setCurrentPage(0);
                  setLinesPerPage(
                    selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                  );
                }}
                totalElementsQty={totalItemsQty}
                currentPage={currentPage + 1}
                linesPerPage={linesPerPage}
                tableHeadCell={tableHeader}
                tableBodyContent={qualifications?.map((qualification) => {
                  const missingItems =
                    qualification.missingConditions.length > 0 ||
                    qualification.missingDocuments.length > 0 ||
                    qualification.missingVariables.length > 0 ||
                    qualification.missingBankOrPix;
                  return {
                    id: qualification?.id,
                    cells: [
                      {
                        id: '1',
                        content: (
                          <TableBodyCellContainer>
                            <TextS weight="Bold">
                              {qualification?.product?.name}
                            </TextS>
                            <TextXs>
                              {qualification?.product?.description}
                            </TextXs>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '2',
                        content: (
                          <TableBodyCellContainer>
                            <TextS weight="Bold">
                              {roleTypesTranslationParse[
                                qualification?.role || ''
                              ] || qualification?.role}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '3',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {`${formatDateFromBackend(
                                qualification?.created_at || '',
                              ).toLocaleDateString('pt-br')}
                                às ${formatStringHourFromBackend(
                                  qualification?.created_at || '',
                                )}`}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '4',
                        content: (
                          <TableBodyCellContainer>
                            {!missingItems ? (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <CheckCircleOutline
                                  style={{ marginRight: '5px' }}
                                  color="success"
                                />
                                <TextM
                                  style={{
                                    color: '#2e7d32',
                                  }}
                                >
                                  Sem pendências
                                </TextM>
                              </div>
                            ) : (
                              <ButtonDanger
                                typeVariant="ghost"
                                style={{
                                  fontWeight: 'normal',
                                  justifyContent: 'flex-start',
                                  padding: '0px',
                                }}
                                iconPosition="left"
                                onClick={() =>
                                  updatePendenciesModalOpen(qualification)
                                }
                                btnIcon={
                                  <ErrorOutline
                                    color="error"
                                    style={{ marginRight: '5px' }}
                                  />
                                }
                              >
                                Visualizar pendências
                              </ButtonDanger>
                            )}
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '5',
                        content: (
                          <TableBodyCellContainer>
                            <CustomTableSelect
                              selectItems={getStatusList(
                                qualification.statusList,
                              )}
                              disabled={
                                !isProfileAuthorized(
                                  EAccountPermissions.UPDATE_QUALIFICATION,
                                )
                              }
                              isLoading={
                                updateQualificationStatusLoading ===
                                qualification?.id
                              }
                              handleChange={(identifier) =>
                                handleUpdateQualificationStatus(
                                  qualification,
                                  identifier,
                                )
                              }
                              value={qualification?.status || ''}
                            />
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '6',
                        content: (
                          <TableBodyCellContainer
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginLeft: '0px',
                            }}
                          >
                            <ButtonPrimary
                              typeVariant="outline"
                              style={{ width: 'fit-content' }}
                              onClick={() =>
                                setSelectedQualification(qualification)
                              }
                            >
                              <DetailsIcon
                                color={
                                  theme.palette.brand.primary.base as string
                                }
                              />
                            </ButtonPrimary>
                          </TableBodyCellContainer>
                        ),
                      },
                    ],
                  };
                })}
              />
            </TableWrapper>
          </TableArea>
        )
      )}
      <ConditionsModal
        qualification={
          selectedQualificationForCondition
            ? {
                borrower: (selectedQualificationForCondition as TQualification)
                  .borrower,
                created_at: (
                  selectedQualificationForCondition as TQualification
                ).created_at,
                employer: (selectedQualificationForCondition as TQualification)
                  .employer,
                funding: (selectedQualificationForCondition as TQualification)
                  .funding,
                id: (selectedQualificationForCondition as TQualification).id,
                role: (selectedQualificationForCondition as TQualification)
                  .role,
                status: (selectedQualificationForCondition as TQualification)
                  .status,
                updated_at: (
                  selectedQualificationForCondition as TQualification
                ).updated_at,
                condition: selectedQualificationForCondition.condition,
                missingConditions:
                  selectedQualificationForCondition.missingConditions,
                missingVariables:
                  selectedQualificationForCondition.missingVariables,
                missingDocuments:
                  selectedQualificationForCondition.missingDocuments,
                missingBankOrPix:
                  selectedQualificationForCondition.missingBankOrPix,
              }
            : undefined
        }
        productInfo={selectedQualificationForCondition?.productData}
        isOpen={!!selectedQualificationForCondition}
        handleModalClose={() => setSelectedQualificationForCondition(undefined)}
        handleApprove={handleApprove}
        updateQualificationConditionLoading={updateLimitsConditionLoading}
      />
      <PendenciesModal
        isOpen={pendenciesModalOpen !== undefined}
        qualification={pendenciesModalOpen}
        handleClose={() => updatePendenciesModalOpen(undefined)}
      />
      <LegalPersonViewQualificationsSideSheet
        isOpen={!!selectedQualification}
        handleClose={() => setSelectedQualification(undefined)}
        qualification={selectedQualification}
        handleUpdateQualificationStatus={handleUpdateQualificationStatus}
        productInfo={selectedQualification?.productData}
        statusList={
          selectedQualification?.statusList
            ? getStatusList(selectedQualification?.statusList)
            : []
        }
        isStatusOptionsLoading={updateQualificationStatusLoading}
        borrowerQualificationData={selectedQualification}
        handleEditCondition={handleEditCondition}
      />
      <EditConditionSidesheet
        qualification={selectedQualificationForConditionEdit}
        productInfo={selectedQualification?.productData}
        isOpen={!!selectedQualificationForConditionEdit}
        handleClose={() => setSelectedQualificationForConditionEdit(undefined)}
        handleApprove={checkHandleApprove}
        updateQualificationConditionLoading={updateLimitsConditionLoading}
      />
      <ConfirmModal
        isOpen={handleApproveCheck !== null}
        icon={<AttentionCircularRedIcon />}
        title="Atenção!"
        color="danger"
        btnConfirmText="Continuar"
        isLoading={updateLimitsConditionLoading}
        handleConfirm={() => {
          handleApprove(
            handleApproveCheck?.handleApproveParams as TConditionItems,
          );
          updateHandleApproveCheck(null);
        }}
        handleClose={() => updateHandleApproveCheck(null)}
        handleCancel={() => updateHandleApproveCheck(null)}
      >
        {handleApproveCheck?.fieldsToWarn &&
          handleApproveCheck?.fieldsToWarn.length > 1 && (
            <>
              <TextM style={{ marginBottom: '15px' }}>
                Os campos abaixo não serão mais validados nas solicitações de{' '}
                <strong>
                  {selectedQualificationForCondition?.borrower?.name}
                </strong>
              </TextM>
              {handleApproveCheck?.fieldsToWarn.map((field) => {
                return (
                  <TextM
                    style={{ paddingLeft: '25px', textAlign: 'left' }}
                    key={field}
                  >
                    - {field}
                  </TextM>
                );
              })}
            </>
          )}
        {handleApproveCheck?.fieldsToWarn &&
          handleApproveCheck?.fieldsToWarn.length === 1 && (
            <TextM style={{ textAlign: 'left' }}>
              Os campo <strong>{handleApproveCheck?.fieldsToWarn[0]}</strong>{' '}
              não será mais validado nas solicitações de{' '}
              <strong>
                {selectedQualificationForCondition?.borrower?.name}
              </strong>
            </TextM>
          )}
      </ConfirmModal>
    </BoxSection>
  );
};

export default LegalPersonViewQualificationsTab;
