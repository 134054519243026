const DisbursementIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.96626 9.1896L6.83069 5.30804L8.43977 5.74698C9.74253 5.00066 11.2455 4.68251 12.7374 4.83682C14.2294 4.99113 15.6362 5.61024 16.7599 6.60786C17.8836 7.60554 18.6677 8.93169 19.0011 10.4002C19.2441 11.4701 19.2388 12.5759 18.9926 13.6341L17.0332 14.8105L17.1687 18.6906L15.5609 18.2519C14.2581 18.9983 12.7551 19.3164 11.2632 19.1621C9.77122 19.0078 8.36443 18.3887 7.24076 17.3911C6.11702 16.3934 5.33291 15.0672 4.99948 13.5987C4.75662 12.529 4.76186 11.4234 5.00786 10.3654L6.96626 9.1896ZM8.49974 15.9995V13.9995H13.9997C14.1323 13.9995 14.2595 13.9468 14.3533 13.8531C14.4471 13.7593 14.4997 13.6321 14.4997 13.4995C14.4997 13.3669 14.4471 13.2397 14.3533 13.146C14.2595 13.0522 14.1323 12.9995 13.9997 12.9995H9.99974C9.3367 12.9995 8.70081 12.7361 8.23197 12.2673C7.76313 11.7984 7.49974 11.1626 7.49974 10.4995C7.49974 9.83647 7.76313 9.20059 8.23197 8.73174C8.70081 8.2629 9.3367 7.99951 9.99974 7.99951H10.9997V5.99951H12.9997V7.99951H15.4997V9.99951H9.99974C9.86713 9.99951 9.73995 10.0522 9.64619 10.146C9.55242 10.2397 9.49974 10.3669 9.49974 10.4995C9.49974 10.6321 9.55242 10.7593 9.64619 10.8531C9.73995 10.9468 9.86713 10.9995 9.99974 10.9995H13.9997C14.6628 10.9995 15.2987 11.2629 15.7675 11.7317C16.2363 12.2006 16.4997 12.8365 16.4997 13.4995C16.4997 14.1626 16.2363 14.7984 15.7675 15.2673C15.2987 15.7361 14.6628 15.9995 13.9997 15.9995H12.9997V17.9995H10.9997V15.9995H8.49974Z"
        fill={color}
      />
      <path
        d="M20.4618 15.5679C21.2062 13.7952 21.3776 11.8333 20.9518 9.95793C20.526 8.08257 19.5244 6.38812 18.088 5.11279C16.6515 3.83746 14.8523 3.0454 12.9435 2.84797C11.0346 2.65054 9.11193 3.05767 7.44586 4.01211L6.30776 2.01485C8.04891 1.0175 10.0206 0.495191 12.026 0.500033C14.0315 0.504876 16.0006 1.0367 17.737 2.04244C22.8883 5.02281 24.8616 11.4044 22.4603 16.7258L24 17.6158L19.2216 20.1615L19.0323 14.7412L20.4618 15.5679ZM3.53937 8.43207C2.79495 10.2048 2.62359 12.1667 3.04938 14.0421C3.47518 15.9174 4.47671 17.6119 5.91318 18.8872C7.34965 20.1625 9.1488 20.9546 11.0577 21.152C12.9666 21.3495 14.8892 20.9423 16.5553 19.9879L17.6934 21.9852C15.9522 22.9825 13.9806 23.5048 11.9751 23.5C9.96964 23.4951 8.0005 22.9633 6.26416 21.9576C1.11286 18.9772 -0.860462 12.5956 1.5408 7.27419L0 6.38537L4.77843 3.83963L4.96773 9.25995L3.53822 8.43322L3.53937 8.43207Z"
        fill={color}
      />
    </svg>
  );
};

export default DisbursementIcon;
