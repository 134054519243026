/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  Heading3,
  Spinner,
} from 'app/components';
import { formatDecimalField } from 'app/helpers';
import { useProductsService } from 'modules/products/services/hooks';

import { InputItem, StepContentButtonsArea, VariablesArea } from './styles';
import { ArrowBack } from '@mui/icons-material';
import { formatDateForBackend } from 'app/helpers/dateHelpers';
import {
  EFunctionsVariablesCategory,
  IPersonCustomVariables,
} from 'app/interfaces/customVariablesInterfaces';
import { usePersonContext } from 'modules/customer/context';
import { usePerson } from 'modules/customer/hooks';
import { NaturalPersonHandlerStepperContext } from '../NaturalPersonHandler';
import { format } from 'date-fns';
import { ICustomVariablesRequest } from 'modules/products/services/hooks/intefaces';
import {
  CustomSelect,
  CustomInput,
  CalendarInput,
} from 'celcoin-design-system';

const VariablesStep = () => {
  const [customVariablesList, setCustomVariablesList] = useState<
    IPersonCustomVariables[] | undefined
  >([]);
  const [validationSchemaObj, setValidationSchemaObj] = useState({});
  const [variablesFormSchema, setVariablesFormSchema] = useState({});
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>();
  const { getCustomVariables } = useProductsService();
  const { setPersonData } = usePerson();
  const {
    state: { product: productData, func },
  } = usePersonContext();
  const { onBack, onForward, updateCustomVariables, updateHasCustomVariables } =
    useContext(NaturalPersonHandlerStepperContext);
  const requiredField = 'Campo obrigatório';

  const handleCreateValidationSchema = (
    variables: IPersonCustomVariables[] | undefined,
  ) => {
    const obj = {};
    if (variables && variables?.length > 0) {
      variables?.map((variable) => {
        Object.assign(obj, {
          [`${variable.id}`]:
            variable.type === 'BOOLEAN'
              ? variable.type === 'BOOLEAN' && variable.required
                ? yup
                    .object({
                      label: yup.string(),
                      value: yup.string(),
                    })
                    .nullable()
                    .test({
                      name: `${variable.name} field`,
                      test: (value: any, { createError }) => {
                        return !value ||
                          value?.value === undefined ||
                          value?.value === ''
                          ? createError({
                              message: 'Campo obrigatório',
                              path: `${variable.id}`,
                            })
                          : true;
                      },
                    })
                : yup
                    .object({
                      label: yup.string().nullable(),
                      value: yup.string().nullable(),
                    })
                    .nullable()
              : variable.type === 'NUMBER'
              ? variable.type === 'NUMBER' && variable.required
                ? yup.string().required(requiredField)
                : yup.string()
              : variable.type === 'TEXT'
              ? variable.required
                ? yup.string().required(requiredField).min(1)
                : yup.string()
              : variable.type === 'DATE'
              ? variable.type === 'DATE' && variable.required
                ? yup.string().nullable().required(requiredField)
                : yup.string().nullable()
              : yup.string(),
        });
        return true;
      });
    }

    setValidationSchemaObj(obj);
  };

  const getVariablesByFunction = (
    customVariableRes: ICustomVariablesRequest | undefined,
    type: EFunctionsVariablesCategory = 'person',
  ) => {
    switch (func.value) {
      case 'BORROWER':
        return customVariableRes?.borrower?.[type];
      case 'GUARANTOR':
        return customVariableRes?.guarantors?.[type];
      case 'CO_SIGNER':
        return customVariableRes?.co_signers?.[type];
      default:
        return;
    }
  };

  const handleGetVariables = async () => {
    setLoading(true);
    const customVariableRes = await getCustomVariables(productData?.id || '');

    const personCustomVariables = getVariablesByFunction(customVariableRes);

    const initialValuesObj: { [key: string]: string | boolean } = {};

    personCustomVariables?.map((item) => {
      Object.assign(initialValuesObj, {
        ...(item.type === 'BOOLEAN' || item.type === 'DATE'
          ? { [item.id]: null }
          : { [item.id]: '' }),
      });

      return true;
    });

    setInitialValues(initialValuesObj);

    handleCreateValidationSchema(personCustomVariables);
    setCustomVariablesList(personCustomVariables);
    setLoading(false);

    if (personCustomVariables?.length === 0) {
      updateHasCustomVariables(false);
      onForward();
    } else {
      updateHasCustomVariables(true);
    }
  };

  useEffect(() => {
    if (productData) {
      handleGetVariables();
    }
  }, [productData]);

  useEffect(() => {
    if (validationSchemaObj) {
      setVariablesFormSchema(yup.object(validationSchemaObj).required());
    }
  }, [validationSchemaObj]);

  const handleInputChange = useCallback(
    (
      evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      variable: IPersonCustomVariables,
    ) => {
      if (variable.type === 'NUMBER') {
        formik.setFieldValue(
          `${evt.target.name}`,
          formatDecimalField(evt.target.value),
        );
        return;
      }
      formik.setFieldValue(`${evt.target.name}`, evt.target.value);
    },
    [],
  );

  const handleInputCalendarChange = useCallback(
    (value: Date | null, variable: IPersonCustomVariables) => {
      formik.setFieldValue(`${variable.id}`, value);
    },
    [],
  );

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: variablesFormSchema,
    onSubmit(values) {
      const variablesValuesKeys = Object.keys(values);

      const parsedVariablesValues = variablesValuesKeys.map((key) => {
        const currentVar: IPersonCustomVariables | undefined =
          customVariablesList?.find((i) => i.id === key);
        return {
          name: currentVar?.name?.replace('custom_variables.', ''),
          value:
            currentVar?.type === 'NUMBER'
              ? formatDecimalField(values[key]).replace(',', '.')
              : currentVar?.type === 'DATE'
              ? values[key] &&
                format(
                  formatDateForBackend(
                    (values[key] as Date)?.toLocaleDateString('pt-br'),
                  ),
                  'yyyy-MM-dd',
                )
              : currentVar?.type === 'BOOLEAN'
              ? values[key]?.value
              : values[key],
          id: key,
        };
      });

      updateCustomVariables(parsedVariablesValues);
      setPersonData({
        customVariables: parsedVariablesValues,
      });
      onForward();
    },
  });

  return (
    <BoxSection boxVariant="rounded">
      <Heading3>Quer incluir algum campo adicional?</Heading3>
      <VariablesArea>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {customVariablesList?.map((variable) => {
              return (
                <InputItem key={variable.id}>
                  {variable.type === 'BOOLEAN' ? (
                    <CustomSelect
                      handleInputChange={formik.handleChange}
                      name={variable.id}
                      options={[
                        { value: false, label: 'Não' },
                        { value: true, label: 'Sim' },
                        { value: '', label: 'Vazio' },
                      ]}
                      labelValue={variable.display_name}
                      value={{
                        value: formik.values?.[`${variable.id}`]?.value,
                        label: formik.values?.[`${variable.id}`]?.label,
                      }}
                      placeholder="Definir valor"
                      validationError={
                        formik.touched?.[`${variable.id}`]
                          ? formik.errors?.[`${variable.id}`]?.toString()
                          : ''
                      }
                    />
                  ) : variable.type === 'DATE' ? (
                    <CalendarInput
                      name={variable.name}
                      labelValue={variable.display_name}
                      placeholder="00/00/0000"
                      value={formik.values?.[`${variable.id}`]}
                      onChange={(e) => handleInputCalendarChange(e, variable)}
                      validationError={
                        formik.touched?.[`${variable.id}`]
                          ? formik.errors?.[`${variable.id}`]?.toString()
                          : ''
                      }
                    />
                  ) : (
                    <CustomInput
                      handleInputChange={(e) => handleInputChange(e, variable)}
                      name={variable.id}
                      labelValue={variable.display_name}
                      value={formik.values?.[`${variable.id}`] || ''}
                      placeholder={
                        variable.type === 'NUMBER'
                          ? 'Digitar número'
                          : 'Digitar informação'
                      }
                      validationError={
                        formik.touched?.[`${variable.id}`]
                          ? formik.errors?.[`${variable.id}`]?.toString()
                          : ''
                      }
                    />
                  )}
                </InputItem>
              );
            })}
          </>
        )}
      </VariablesArea>
      <StepContentButtonsArea>
        <ButtonDefault margin={true} onClick={onBack}>
          <ArrowBack />
        </ButtonDefault>
        <ButtonPrimary onClick={formik.submitForm}>Avançar</ButtonPrimary>
      </StepContentButtonsArea>
    </BoxSection>
  );
};

export default VariablesStep;
