import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  padding: 0px 48px 80px;

  .status-description-area {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      padding: 0px 10px;
  };
`;

export const TitleArea = styled.div`
  display: flex;
  margin-top: 42px;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 202px;
  height: 40px;
  border-radius: 10px;
`;

export const TableArea = styled.div`
  width: 100%;
  margin-top: 60px;
  margin-bottom: 48px;
`;

export const ArrowArrowContainer = styled.div`
  cursor: pointer;
`;

export const StatusBox = styled.div`
  border: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  display: flex;
  border-radius: 10px;
  padding: 15.5px 24px;
  width: fit-content;
  align-items: center;
  span:first-of-type {
    margin-right: 32px;
  }
`;

export const StatusReason = styled.div`
  background-color: #FEF4F4;
  padding: 16px;
  border-left: 8px solid #FCB9B9;
  border-radius: 10px;
  margin-bottom: 32px;
  max-width: 50vw;

  

  .info {
    display: flex;
    svg {
      width: 32px;
      height: 32px;
    }
    div:first-of-type {
      margin-right: 16px;
    }
  }
`;
