import styled from '@emotion/styled';

export const ImgArea = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  
  img {
    width: 154px;
    height: 125px;
  }
`;
