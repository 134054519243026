import styled from '@emotion/styled';

export const DeleteButtonContainer = styled.div`
  width: 56px;
  height: 40px;
  border: 2px solid #E6E5EA;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
