import { GoogleTagManager } from '../../services/gtm';

const useGtm = () => {
  const sendGtmData = (evtName: string, dataLayerObject: object) => {
    GoogleTagManager.sendGtmData({
      event: evtName,
      dataLayer: dataLayerObject,
    });
  };

  return { sendGtmData };
};

export default useGtm;
