import React, { createContext, useContext, useReducer, useState } from 'react';
import { IThemeContext } from './theme.interfaces';
import { themeActionType } from './themeActionTypes';
import { themeInitialState, themeReducer } from './themeReducer';
import { useSnackbar } from '../../hooks/useSnackbar';

export const ThemeContext = createContext<IThemeContext | undefined>(undefined);

export interface IThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider = ({ children }: IThemeProviderProps) => {
  const [state, dispatch] = useReducer(themeReducer, themeInitialState);
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const value = { state, dispatch, loading };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetchTheme = async () => {
    setLoading(true);
    try {
      const res = await fetch('http://localhost:3031/theme');
      const themeRes = (await res?.json()) as {
        primary: string;
        secondary: string;
        background: string;
      };

      dispatch({
        type: themeActionType.SET_THEME,
        payload: themeRes,
      });
      setLoading(false);
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar suas configurações de cores.');
      setLoading(false);
    }
  };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return { ...context };
};
