import { BaseHttpService } from 'app/services';

export class FundingsHttpService extends BaseHttpService {
  baseUrl = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  getFundings = async (fundingIds: string[]) => {
    const res = await this.get(
      `${this.baseUrl}/issuer/fundings?size=100&${fundingIds
        .map((funding) => `id=${funding}`)
        .join('&')}`,
    );

    return res;
  };

  getFunding = async (fundingId: string) => {
    const res = await this.get(`${this.baseUrl}/issuer/fundings/${fundingId}`);

    return res;
  };
}
