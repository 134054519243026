import React, { HTMLProps } from 'react';
import { useTheme } from '@mui/material';

export default function Container({ ...props }: HTMLProps<HTMLDivElement>) {
  const theme = useTheme();
  return (
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
        overflowX: 'hidden',
        backgroundColor: theme.palette.background.default,
      }}
      {...props}
    >
      {props.children}
    </div>
  );
}
