import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { IDrawerContext } from './drawer.interfaces';
import { drawerActionType } from './drawerActionTypes';
import { drawerInitialState, drawerReducer } from './drawerReducer';

export const DrawerContext = createContext<IDrawerContext | undefined>(
  undefined,
);

export interface iDrawerProviderProps {
  children?: React.ReactNode;
}

export const DrawerProvider = ({ children }: iDrawerProviderProps) => {
  const [state, dispatch] = useReducer(drawerReducer, drawerInitialState);
  const theme = useTheme();
  const smWindowSize = useMediaQuery(theme.breakpoints.down?.('laptop'));

  useEffect(() => {
    if (smWindowSize) {
      dispatch({
        type: drawerActionType.REMOVE_COLLAPSE,
      });
    }
  }, [state.isCollapsed, smWindowSize]);

  const value = { state, dispatch };

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);

  if (context === undefined) {
    throw new Error('useDrawerContext must be used within a DrawerProvider');
  }
  return context;
};
