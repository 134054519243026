import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { useState, useCallback, useContext } from 'react';
import { EditWrapper, Heading3 } from '../../../../../../app/components';
import { formatCNPJ, formatPhone } from '../../../../../../app/helpers';
import { TRegisterPersonDataRequest } from '../../../../context';
import NaturalPersonEditEmployerSection from './NaturalPersonEditEmployerSection';
import {
  NaturalPersonViewBoxContainer,
  NaturalPersonViewEmployerContent,
  NaturalPersonViewEmployerIcon,
  NaturalPersonViewPageSection,
} from './styles';
import { BuildingIcon, ExternalIcon } from 'app/components/Icons';
import { useNavigate } from 'react-router-dom';
import { NaturalPersonViewPageContext } from '../../NaturalPersonViewPage';
import { useTheme } from '@mui/material';

type INaturalPersonViewEmployerSectionProps = {
  personData: TRegisterPersonDataRequest;
};

const NaturalPersonViewEmployerSection = ({
  personData,
}: INaturalPersonViewEmployerSectionProps) => {
  const theme = useTheme();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { employer } = useContext(NaturalPersonViewPageContext);
  const navigate = useNavigate();

  const handleNavigateToEmployer = useCallback(() => {
    navigate(`/records/legal/${personData?.employer?.id || ''}/details`);
  }, [personData]);

  return (
    <>
      <NaturalPersonViewPageSection>
        <Heading3
          id="employeer"
          style={{ color: theme.palette.brand.secondary.base as string }}
        >
          Empregador
        </Heading3>
        <EditWrapper
          handleClick={() => setIsEditOpen(true)}
          permission={EAccountPermissions.UPDATE_PERSON_BUSINESS}
        >
          <NaturalPersonViewBoxContainer>
            {personData?.employer?.id ? (
              <>
                <NaturalPersonViewEmployerIcon>
                  <BuildingIcon
                    color={theme.palette.brand.secondary.base as string}
                  />
                </NaturalPersonViewEmployerIcon>
                <NaturalPersonViewEmployerContent>
                  <span
                    style={{
                      display: 'block',
                    }}
                  >
                    {`${employer?.legal_name}`}
                    <span
                      style={{ cursor: 'pointer', paddingLeft: '10px' }}
                      onClick={handleNavigateToEmployer}
                    >
                      <ExternalIcon
                        color={theme.palette.brand.primary.base as string}
                      />
                    </span>
                  </span>
                  <span
                    style={{
                      display: 'block',
                    }}
                  >{`${formatCNPJ(employer?.taxpayer_id || '')}`}</span>
                  <span
                    style={{
                      display: 'block',
                    }}
                  >{`${
                    employer?.phone?.number &&
                    formatPhone(
                      employer?.phone?.area_code + employer?.phone?.number,
                    )
                  }`}</span>
                  <span
                    style={{
                      display: 'block',
                    }}
                  >{`${employer?.email_address}`}</span>
                </NaturalPersonViewEmployerContent>
              </>
            ) : (
              '-'
            )}
          </NaturalPersonViewBoxContainer>
        </EditWrapper>
      </NaturalPersonViewPageSection>
      <NaturalPersonEditEmployerSection
        isOpen={isEditOpen}
        handleClose={() => setIsEditOpen(false)}
        personData={personData}
      />
    </>
  );
};

export default NaturalPersonViewEmployerSection;
