import { EMaritalStatus } from 'modules/customer/context/PersonProvider/person.interfaces';
import { BoxSection } from '../../../../../../app/components';
import { TRegisterPersonDataRequest } from '../../../../context';
import { NaturalPersonViewAboutSection } from '../NaturalPersonViewAboutSection';
import { NaturalPersonViewAddressSection } from '../NaturalPersonViewAddressSection';
import { NaturalPersonViewBankSection } from '../NaturalPersonViewBankSection';
import { NaturalPersonViewDocumentSection } from '../NaturalPersonViewDocumentSection';
import { NaturalPersonViewDocumentsSection } from '../NaturalPersonViewDocumentsSection';
import { NaturalPersonViewEmployerSection } from '../NaturalPersonViewEmployerSection';
import { NaturalPersonViewSpouseSection } from '../NaturalPersonViewSpouseSection';
import NaturalPersonViewDetailsSummary from './NaturalPersonViewDetailsSummary';
import { useMemo } from 'react';

type INaturalPersonViewDetailsTabProps = {
  personData: TRegisterPersonDataRequest;
};

const NaturalPersonViewDetailsTab = ({
  personData,
}: INaturalPersonViewDetailsTabProps) => {
  const hasSpouseSection = personData.marital_status === EMaritalStatus.MARRIED;
  const anchors = useMemo(() => {
    if (hasSpouseSection)
      return [
        {
          label: 'Sobre',
          anchor: '#about',
        },
        {
          label: 'Cônjuge',
          anchor: '#spouse',
        },
        {
          label: 'Documento de identificação',
          anchor: '#identification',
        },
        {
          label: 'Endereço',
          anchor: '#address',
        },
        {
          label: 'Dados Bancários',
          anchor: '#bank',
        },
        {
          label: 'Empregador',
          anchor: '#employeer',
        },
        {
          label: 'Documentos',
          anchor: '#documents',
        },
      ];
    return [
      {
        label: 'Sobre',
        anchor: '#about',
      },
      {
        label: 'Documento de identificação',
        anchor: '#identification',
      },
      {
        label: 'Endereço',
        anchor: '#address',
      },
      {
        label: 'Dados Bancários',
        anchor: '#bank',
      },
      {
        label: 'Empregador',
        anchor: '#employeer',
      },
      {
        label: 'Documentos',
        anchor: '#documents',
      },
    ];
  }, [hasSpouseSection]);

  return (
    <BoxSection>
      <NaturalPersonViewAboutSection personData={personData} />
      {hasSpouseSection && (
        <NaturalPersonViewSpouseSection personData={personData} />
      )}
      <NaturalPersonViewDocumentSection personData={personData} />
      <NaturalPersonViewAddressSection personData={personData} />
      <NaturalPersonViewBankSection personData={personData} />
      <NaturalPersonViewEmployerSection personData={personData} />
      <NaturalPersonViewDocumentsSection personData={personData} />
      <NaturalPersonViewDetailsSummary anchors={anchors} />
    </BoxSection>
  );
};

export default NaturalPersonViewDetailsTab;
