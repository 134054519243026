import styled from '@emotion/styled';

export const CustodianInput = styled.div<{
  marginTop?: string;
  isFull?: boolean;
  isHalf?: boolean;
  isDatePicker?: boolean;
}>`
  width: ${(props) => (props.isFull ? '100%' : !props.isHalf ? '50%' : '25%')};
  margin-top: ${(props) => props.marginTop || '0px'};
  padding: 15px;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      width:  ${(props) => (!props.isHalf ? '100%' : '50%')}; 
  };

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}){
      width: 100%;
  };

  ${({ isDatePicker, theme }) =>
    !isDatePicker &&
    `
    .MuiFormLabel-root + div > div:first-of-type {
      background-color: ${theme.palette?.brand?.primary?.base};
    }
  `}
`;

export const StepAddressContentInputArea = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 32px;
  margin-top: 32px;
  border: 1px solid #E6E5EA;
  border-radius: 15px;
  padding: 12px 16px;
`;

export const CustodianInputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CustodianButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    width: auto;
  }
`;
