import { BaseHttpService } from './baseHttpService';

const amountKeys: { [key: string]: string } = {
  '0': 'requested_amount',
  '1': 'total_amount_owed',
  '2': 'payment_value',
  '3': 'financed_amount',
};

const endpointTypeRequest: { [key: string]: string } = {
  '0': 'preview',
  '1': 'preview-total-amount',
  '2': 'preview-payment-value',
  '3': 'preview-financed-amount',
};

export type TSimulatorParametersDataRequest = {
  amount: number;
  numPayments: number;
  tacAmount?: number;
  tacRate?: number;
  interestRate: number;
  financeFee: number;
  discountRate?: number;
  iofType: { value: string; label: string };
  scheduleType: { value: string; label: string };
  amortizationType: { value: string; label: string };
  interestCompoundingType: { value: string; label: string };
  financedIof: { value: string; label: string };
  firstPaymentDate: string;
  disbursementDate: string;
};

export class SimulatorHttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;
  getRequestedAmount = async (
    simulatorParametersData: TSimulatorParametersDataRequest,
    index: string,
  ) => {
    const res = await this.post(
      `${this.BASE_URL}/originator/applications/${endpointTypeRequest[index]}`,
      {
        [amountKeys[index]]: simulatorParametersData.amount,
        interest_rate: simulatorParametersData.interestRate,
        tac_amount: simulatorParametersData.tacAmount,
        tac_rate: simulatorParametersData.tacRate,
        finance_fee: simulatorParametersData.financeFee,
        num_payments: simulatorParametersData.numPayments,
        first_payment_date: simulatorParametersData.firstPaymentDate,
        disbursement_date: simulatorParametersData.disbursementDate,
        iof_type: simulatorParametersData.iofType.value,
        schedule_type: simulatorParametersData.scheduleType.value,
        discount_rate: simulatorParametersData.discountRate,
        amortization_type: simulatorParametersData.amortizationType.value,
        interest_compounding_type:
          simulatorParametersData.interestCompoundingType.value,
        financed_iof: simulatorParametersData.financedIof.value === 'true',
      },
    );
    return res;
  };

  getRequestedAmountByProduct = async (
    simulatorParametersData: TSimulatorParametersDataRequest,
    index: string,
    productId: string,
  ) => {
    const res = await this.post(
      `${this.BASE_URL}/originator/products/${productId}/preview`,
      {
        [amountKeys[index]]: simulatorParametersData.amount,
        interest_rate: simulatorParametersData.interestRate,
        tac_amount: simulatorParametersData.tacAmount,
        finance_fee: simulatorParametersData.financeFee,
        num_payments: simulatorParametersData.numPayments,
        discount_rate: simulatorParametersData.discountRate,
        first_payment_date: simulatorParametersData.firstPaymentDate,
        disbursement_date: simulatorParametersData.disbursementDate,
        iof_type: simulatorParametersData.iofType.value,
        schedule_type: simulatorParametersData.scheduleType.value,
        tac_rate: simulatorParametersData.tacRate,
      },
    );
    return res;
  };
}
