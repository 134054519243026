import { useTheme } from '@mui/material';
import React from 'react';
import { TextXsDefaultWrapper } from './styles';

export interface ITextXsDefaultProps
  extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode;
  weight?: 'regular' | 'bold' | 'semibold' | 'medium';
}

const fontWeightTypes = {
  regular: 'Rg',
  bold: 'B',
  semibold: 'Sb',
  medium: 'Md',
};

const TextXs = ({
  children,
  weight = 'regular',
  ...props
}: ITextXsDefaultProps) => {
  const theme = useTheme();
  return (
    <TextXsDefaultWrapper
      weight={weight}
      {...props}
      style={{
        ...props.style,
        fontWeight: theme.typography?.fontWeight?.[fontWeightTypes[weight]],
      }}
    >
      {children}
    </TextXsDefaultWrapper>
  );
};

export default TextXs;
