const InstallmentsDownIcon = ({ color }: { color: string }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0001 2.16602C14.6001 2.16602 18.3334 5.89935 18.3334 10.4993C18.3334 15.0993 14.6001 18.8327 10.0001 18.8327C5.40008 18.8327 1.66675 15.0993 1.66675 10.4993C1.66675 5.89935 5.40008 2.16602 10.0001 2.16602ZM10.8334 10.4993H13.3334L10.0001 7.16602L6.66675 10.4993H9.16675V13.8327H10.8334V10.4993Z"
      fill={color}
    />
  </svg>
);

export default InstallmentsDownIcon;
