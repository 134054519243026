import { useEffect, useRef } from 'react';
import { Spinner } from '../Spinner';
import { InfinityScrollContent, InfinityScrollLoader } from './styles';

export interface IInfinityScroll {
  onRequest: () => void;
  isLoading: boolean;
}

const InfinityScroll = ({ onRequest, isLoading }: IInfinityScroll) => {
  const loaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver((entities) => {
      const target = entities[0];

      if (target.isIntersecting && !isLoading) {
        onRequest();
      }
    }, options);

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, []);

  return (
    <>
      <InfinityScrollLoader ref={loaderRef} />
      {isLoading && (
        <InfinityScrollContent>
          <Spinner />
          <div>Carregando mais itens</div>
        </InfinityScrollContent>
      )}
    </>
  );
};

export default InfinityScroll;
