import { TProducts } from 'modules/products/context';
import {
  TProductDocumentConfiguration,
  TProductVariableConfiguration,
} from 'modules/products/context/ProductsProvider/products.interfaces';
import {
  EQualificationRole,
  EQualificationStatus,
  TQualificationBorrower,
  TQualificationEmployer,
  TQualificationFunding,
} from 'modules/products/context/QualificationsProvider/qualifications.interfaces';
import { TQualificationStatusOption } from 'modules/products/pages/interfaces';
import { Condition } from 'modules/products/services/hooks/intefaces';

export type TQualificationProduct = {
  id: string;
  name: string;
  description: string;
};

export type TQualification = {
  borrower: TQualificationBorrower;
  borrower_employer: TQualificationEmployer | null;
  condition: Condition | null;
  created_at: string;
  employer: TQualificationEmployer | null;
  funding: TQualificationFunding;
  id: string;
  product: TQualificationProduct;
  role: EQualificationRole;
  status: string | EQualificationStatus;
  updated_at?: string;
};

export type TLimit = {
  borrower: TQualificationBorrower;
  borrower_employer: TQualificationEmployer | null;
  condition: Condition | null;
  created_at: string;
  employer: TQualificationEmployer | null;
  funding: TQualificationFunding;
  id: string;
  product: TQualificationProduct;
  role: EQualificationRole;
  status: string | EQualificationStatus;
  updated_at?: string;
};

export type TQualificationWithStatusAndProduct = TQualification & {
  productData: TProducts;
  statusList: TQualificationStatusOption[];
  missingDocuments: TProductDocumentConfiguration[];
  missingVariables: TProductVariableConfiguration[];
  missingConditions: TConditionRequiredProperty[];
  missingBankOrPix: boolean;
};

export type TConditionRequiredProperty = {
  name: string;
  label: string;
};

export const ConditionsRequiredProperty: TConditionRequiredProperty[] = [
  { name: 'line_of_credit.credit_limit', label: 'Limite Total' },
  { name: 'line_of_credit.max_payment_amount', label: 'Limite de Parcela' },
  {
    name: 'schedule_based_conditions[0].interest_rate',
    label: 'Taxa de Juros',
  },
  { name: 'min_payment_number', label: 'Prazo mínimo' },
  { name: 'max_payment_number', label: 'Prazo máximo' },
];
