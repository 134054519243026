import { Divider, useTheme } from '@mui/material';
import {
  ButtonPrimary,
  CloseIcon,
  Heading3,
  Spinner,
  TextXLBold,
} from 'app/components';
import { TReactChangeInput } from 'app/components/CreditSimulator/CreditSimulatorModal/CreditSimulatorContent';
// import { CustomSelectModal } from 'app/components/CustomSelectModal';
import { ChevronDownIcon } from 'app/components/Icons';
import { formatDecimalField } from 'app/helpers';
import { formatBRLCurrencyMaskInput } from 'app/utils/currency';
import { formatCurrency, normalizeAddDigits } from 'app/utils/normalizer';
import { getNumbersFromValue } from 'app/utils/numbers';
import { CustomCurrencyInput, CustomInput } from 'celcoin-design-system';
import { useFormik } from 'formik';
// import { editConditionValidation } from 'modules/customer/helpers';
// import { editConditionValidationRequired } from 'modules/customer/helpers/conditionStepValidation';
import { ProductSideSheet } from 'modules/products/components/ProductSideSheet';
import {
  CloseIconWrapper,
  SideSheetHeader,
} from 'modules/products/components/ProductSideSheet/styles';
import { TProducts } from 'modules/products/context';
import {
  TBorrowerQualificationResponseParsed,
  TConditionItems,
} from 'modules/products/pages/interfaces';
import { useQualificationsService } from 'modules/products/services/hooks';
import { TBorrowerQualificationRequestType } from 'modules/products/services/hooks/intefaces';
import { useCallback, useState, useEffect } from 'react';
import { BackBtnArea, BtnArea, InputArea, LoadingArea } from './styles';
import { editConditionValidationRequired } from 'modules/customer/helpers/conditionStepValidation';
import { editConditionValidation } from 'modules/products/helpers';

export interface IEditConditionModalProps {
  isOpen: boolean;
  qualification?: TBorrowerQualificationResponseParsed | undefined;
  productInfo?: TProducts;
  handleClose: () => void;
  handleApprove: ({
    creditLimit,
    maxPaymentAmount,
    interestRate,
    qualification,
  }: TConditionItems) => void;
  updateQualificationConditionLoading: boolean;
}

const EditConditionSidesheet = ({
  isOpen,
  qualification,
  productInfo,
  handleClose,
  handleApprove,
  updateQualificationConditionLoading,
}: IEditConditionModalProps) => {
  const theme = useTheme();
  const { getBorrowerQualification } = useQualificationsService();
  const [borrowerQualificationData, setBorrowerQualificationData] = useState<
    TBorrowerQualificationRequestType[] | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);

  const fetchBorrowerQualificationDetails = useCallback(async () => {
    setIsLoading(true);

    try {
      const borrowerQualificationRes = await handleGetBorrowerQualification(
        productInfo?.id || '',
        qualification?.borrower.id || '',
        qualification?.id || '',
      );

      if (!borrowerQualificationRes) {
        handleClose?.();
      }
      setBorrowerQualificationData(borrowerQualificationRes?.content);
    } finally {
      setIsLoading(false);
    }
  }, [productInfo, qualification]);

  const handleGetBorrowerQualification = useCallback(
    async (productId: string, borrowerId: string, qualificationId: string) => {
      try {
        const borrowerQualificationRes = await getBorrowerQualification(
          productId,
          borrowerId,
          qualificationId,
        );
        return borrowerQualificationRes;
      } catch (error) {
        handleClose?.();
      }
    },
    [],
  );

  const formik = useFormik({
    initialValues: {
      creditLimit:
        borrowerQualificationData?.[0]?.condition?.line_of_credit
          ?.credit_limit !== null
          ? formatCurrency(
              borrowerQualificationData?.[0]?.condition?.line_of_credit
                ?.credit_limit || 0.0,
              false,
            ) || ''
          : '',
      maxPaymentAmount:
        borrowerQualificationData?.[0]?.condition?.line_of_credit
          ?.max_payment_amount !== null
          ? formatCurrency(
              borrowerQualificationData?.[0]?.condition?.line_of_credit
                ?.max_payment_amount || 0.0,
              false,
            ) || ''
          : '',
      minRequestedAmount:
        borrowerQualificationData?.[0]?.condition?.min_requested_amount !== null
          ? formatCurrency(
              borrowerQualificationData?.[0]?.condition?.min_requested_amount ||
                0,
              false,
            ) || ''
          : '',
      interestRate: borrowerQualificationData?.[0]?.condition
        ?.schedule_based_conditions?.[0]?.interest_rate
        ? normalizeAddDigits(
            borrowerQualificationData?.[0]?.condition
              ?.schedule_based_conditions?.[0]?.interest_rate * 100,
            2,
          )
        : '',
      dueDay: borrowerQualificationData?.[0]?.condition?.due_day || '',
      maxGracePeriod:
        borrowerQualificationData?.[0]?.condition?.max_grace_period || '',
      minGracePeriod:
        borrowerQualificationData?.[0]?.condition?.min_grace_period || '',
      maxPaymentNumber:
        borrowerQualificationData?.[0]?.condition?.max_payment_number || '',
      minPaymentNumber:
        borrowerQualificationData?.[0]?.condition?.min_payment_number || '',
      tac:
        borrowerQualificationData?.[0]?.condition?.tac !== null
          ? formatCurrency(
              borrowerQualificationData?.[0]?.condition?.tac || 0,
              false,
            ) || ''
          : '',
    },
    validationSchema: productInfo?.conditions_required
      ? editConditionValidationRequired
      : editConditionValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleApprove({
        ...values,
        qualification: borrowerQualificationData?.[0],
      });
    },
  });

  const handleAmountChange = useCallback((evt: TReactChangeInput) => {
    if (evt.target.value.length === 0) {
      formik.setFieldValue(evt.target.name, '');
      return;
    }
    formik.setFieldValue(
      evt.target.name,
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  const handleMaxPaymentAmountChange = useCallback((evt: TReactChangeInput) => {
    if (evt.target.value.length === 0) {
      formik.setFieldValue('maxPaymentAmount', '');
      return;
    }
    formik.setFieldValue(
      'maxPaymentAmount',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  const handleInterestRateChange = useCallback((evt: TReactChangeInput) => {
    if (evt.target.value.length === 0) {
      formik.setFieldValue('interestRate', '');
      return;
    }
    formik.setFieldValue('interestRate', formatDecimalField(evt.target.value));
  }, []);

  const handleIntChange = useCallback((evt: TReactChangeInput) => {
    if (evt.target.value.length === 0) {
      formik.setFieldValue(evt.target.name, '');
      return;
    }
    formik.setFieldValue(
      evt.target.name,
      getNumbersFromValue(evt.target.value || ''),
    );
  }, []);

  useEffect(() => {
    if (isOpen && productInfo) {
      fetchBorrowerQualificationDetails();
    }
  }, [isOpen, productInfo]);

  return (
    <ProductSideSheet
      handleClose={handleClose}
      open={isOpen}
      title=""
      customHeader={
        <>
          <SideSheetHeader>
            <BackBtnArea onClick={handleClose}>
              <ChevronDownIcon
                color={theme.palette.brand.secondary.base as string}
              />
              <TextXLBold>Voltar</TextXLBold>
            </BackBtnArea>
            <CloseIconWrapper onClick={handleClose}>
              <CloseIcon color={theme.palette.brand.secondary.base as string} />
            </CloseIconWrapper>
          </SideSheetHeader>
          <Divider />
        </>
      }
    >
      <Heading3 style={{ color: theme.palette.brand.secondary.base }}>
        Editar condições
      </Heading3>

      {isLoading ? (
        <LoadingArea>
          <Spinner />
        </LoadingArea>
      ) : (
        <>
          <InputArea>
            <CustomCurrencyInput
              value={String(formik.values.creditLimit)}
              labelValue="Limite total"
              handleInputChange={handleAmountChange}
              name="creditLimit"
              validationError={
                formik.touched.creditLimit ? formik.errors.creditLimit : ''
              }
            />
            <CustomCurrencyInput
              value={String(formik.values.maxPaymentAmount)}
              labelValue="Limite da parcela"
              handleInputChange={handleMaxPaymentAmountChange}
              name="maxPaymentAmount"
              validationError={
                formik.touched.maxPaymentAmount
                  ? formik.errors.maxPaymentAmount
                  : ''
              }
            />
            <CustomCurrencyInput
              name="minRequestedAmount"
              value={formik.values.minRequestedAmount}
              handleInputChange={handleAmountChange}
              labelValue="Mínimo solicitação"
              validationError={
                formik.touched.minRequestedAmount
                  ? formik.errors.minRequestedAmount
                  : ''
              }
            />
            {/*<CustomCurrencyInput*/}
            {/*  name="tac"*/}
            {/*  value={formik.values.tac}*/}
            {/*  handleInputChange={handleAmountChange}*/}
            {/*  labelValue="Tac"*/}
            {/*  validationError={formik.touched.tac ? formik.errors.tac : ''}*/}
            {/*/>*/}
            <CustomInput
              name="maxGracePeriod"
              value={String(formik.values.maxGracePeriod)}
              handleInputChange={handleIntChange}
              labelValue="Carência máxima"
              validationError={
                formik.touched.maxGracePeriod
                  ? formik.errors.maxGracePeriod
                  : ''
              }
            />
            <CustomInput
              name="minGracePeriod"
              value={String(formik.values.minGracePeriod)}
              handleInputChange={handleIntChange}
              labelValue="Carência mínima"
              validationError={
                formik.touched.minGracePeriod
                  ? formik.errors.minGracePeriod
                  : ''
              }
            />
            <CustomInput
              name="minPaymentNumber"
              value={String(formik.values.minPaymentNumber)}
              handleInputChange={handleIntChange}
              labelValue="Prazo mínimo"
              validationError={
                formik.touched.minPaymentNumber
                  ? formik.errors.minPaymentNumber
                  : ''
              }
            />
            <CustomInput
              name="maxPaymentNumber"
              value={String(formik.values.maxPaymentNumber)}
              handleInputChange={handleIntChange}
              labelValue="Prazo máximo"
              validationError={
                formik.touched.maxPaymentNumber
                  ? formik.errors.maxPaymentNumber
                  : ''
              }
            />
            {/*<CustomInput*/}
            {/*  name="dueDay"*/}
            {/*  value={String(formik.values.dueDay)}*/}
            {/*  handleInputChange={handleIntChange}*/}
            {/*  labelValue="Dia de vencimento"*/}
            {/*  validationError={*/}
            {/*    formik.touched.dueDay ? formik.errors.dueDay : ''*/}
            {/*  }*/}
            {/*/>*/}
            <CustomInput
              labelValue="Taxa de juros (% a.m.)"
              value={formik.values.interestRate || ''}
              handleInputChange={handleInterestRateChange}
              validationError={
                formik.touched.interestRate ? formik.errors.interestRate : ''
              }
            />
          </InputArea>

          <BtnArea style={{ marginTop: '30px' }}>
            {updateQualificationConditionLoading ? (
              <div className="spinner">
                <Spinner />
              </div>
            ) : (
              <ButtonPrimary
                style={{ alignSelf: 'flex-end' }}
                typeVariant="fill"
                onClick={formik.submitForm}
              >
                Salvar
              </ButtonPrimary>
            )}
          </BtnArea>
        </>
      )}
    </ProductSideSheet>
  );
};

export default EditConditionSidesheet;
