import { useFormik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { ConfirmModal, Heading4 } from '../../../../../../app/components';
import {
  formatCNPJ,
  formatCPF,
  formatPhone,
} from '../../../../../../app/helpers/masks';
import { BusinessEditSideSheet } from '../../../../components/BusinessEditSideSheet';
import {
  IPixKey,
  PixType,
} from '../../../../context/CustomerProvider/customer.interfaces';
import { pixStepSchema } from '../../../../helpers/businessValidation';
import { NaturalPersonViewPageContext } from '../../NaturalPersonViewPage';
import { StepContentInput, StepContentInputArea } from '../styles';
import { PixTypeTogglesArea, PixTypeTogglesButton } from './styles';
import { TRegisterPersonDataRequest } from '../../../../context';
import { CustomInput } from 'celcoin-design-system';
import { getErrorValidation } from 'modules/customer/components/PixSelector/PixSelector';
import { AttentionCircularRedIcon } from 'app/components/Icons/AttentionCircularIcon';

export interface INaturalPersonEditPixSection {
  personData: TRegisterPersonDataRequest;
  handleClose: () => void;
  isOpen: boolean;
}

enum PixTypeToggle {
  CPF,
  CNPJ,
  PHONE,
  ALEATORY_KEY,
  EMAIL,
}

const pixTypeToggles: Array<{ value: PixTypeToggle; label: string }> = [
  { value: PixTypeToggle.CPF, label: 'CPF' },
  { value: PixTypeToggle.CNPJ, label: 'CNPJ' },
  { value: PixTypeToggle.EMAIL, label: 'E-mail' },
  { value: PixTypeToggle.PHONE, label: 'Celular' },
  { value: PixTypeToggle.ALEATORY_KEY, label: 'Chave aleatória' },
];

const applyFormat = (
  pixKey: string,
  pixType: PixTypeToggle,
  isFirst?: boolean,
): string => {
  if (pixType === PixTypeToggle.CNPJ) return formatCNPJ(pixKey);
  if (pixType === PixTypeToggle.CPF) return formatCPF(pixKey);
  if (pixType === PixTypeToggle.PHONE) {
    if (isFirst) {
      const pixKeyCleaned = pixKey.replace(/[^\w\s]/gi, '').replace(/\s/g, '');
      return formatPhone(
        pixKeyCleaned.startsWith('55') ? pixKeyCleaned.slice(2) : pixKeyCleaned,
      );
    }
    return formatPhone(pixKey);
  }
  return pixKey;
};

const getPlaceholder = (pixType: PixTypeToggle): string => {
  if (pixType === PixTypeToggle.CPF) return '000.000.000-00';
  if (pixType === PixTypeToggle.CNPJ) return '00.000.000/0000-00';
  if (pixType === PixTypeToggle.EMAIL) return 'examplo@exemplo.com.br';
  if (pixType === PixTypeToggle.PHONE) return '(00) 00000-0000';

  return 'Chave aleatória';
};

const getPixTypeToggleFromPixType = (
  pix: IPixKey | undefined,
): PixTypeToggle => {
  if (!pix) return PixTypeToggle.CPF;
  if (pix.key_type === PixType.TAXPAYER_ID) {
    if (pix.key.length > 11) return PixTypeToggle.CNPJ;
    return PixTypeToggle.CPF;
  }

  if (pix.key_type === PixType.EMAIL) return PixTypeToggle.EMAIL;
  if (pix.key_type === PixType.PHONE_NUMBER) return PixTypeToggle.PHONE;

  return PixTypeToggle.ALEATORY_KEY;
};

const getPixTypeFromPixTypeToggle = (pix: PixTypeToggle): PixType => {
  if (pix === PixTypeToggle.CPF || pix === PixTypeToggle.CNPJ)
    return PixType.TAXPAYER_ID;

  if (pix === PixTypeToggle.EMAIL) return PixType.EMAIL;
  if (pix === PixTypeToggle.PHONE) return PixType.PHONE_NUMBER;

  return PixType.ALEATORY_KEY;
};

const NaturalPersonEditPixSection = ({
  personData,
  handleClose,
  isOpen,
}: INaturalPersonEditPixSection) => {
  const theme = useTheme();
  const { pix } = personData;
  const [isConfirmModalOpen, toggleConfirmModal] = useState(false);
  const [pixType, togglePixType] = useState<PixTypeToggle>(
    getPixTypeToggleFromPixType(pix),
  );
  const [pixKey, updatePixKey] = useState<string>(
    pix?.key ? applyFormat(pix?.key, pixType, true) : '',
  );
  const { updatePerson, isLoading } = useContext(NaturalPersonViewPageContext);

  useEffect(() => {
    togglePixType(getPixTypeToggleFromPixType(pix));
    updatePixKey(pix?.key ? applyFormat(pix?.key, pixType, true) : '');
  }, [isOpen]);

  const onConfirmAction = useCallback(() => {
    toggleConfirmModal(false);
    updatePerson({
      ...personData,
      pix: undefined,
    }).then(() => {
      updatePixKey('');
      onClose();
    });
  }, [personData]);

  const formik = useFormik({
    initialValues: {
      key: pixKey,
    },
    validationSchema: pixStepSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const keyType = getPixTypeFromPixTypeToggle(pixType);
      const key =
        keyType === PixType.PHONE_NUMBER
          ? `+55${values.key.replace(/[^\w\s]/gi, '').replace(/\s/g, '')}`
          : keyType === PixType.TAXPAYER_ID
          ? values.key.replace(/[^\w\s]/gi, '')
          : keyType === PixType.EMAIL
          ? values.key.toLowerCase()
          : values.key;

      const personDataValues: TRegisterPersonDataRequest = {
        ...personData,
        pix: {
          ...(personData.pix || {}),
          key,
          key_type: keyType,
        },
      };
      updatePerson(personDataValues).then(() => {
        updatePixKey(applyFormat(key, pixType, true));
        onClose();
      });
    },
  });

  const onChangePixTypeToggle = useCallback(
    (pixTypeToggle: PixTypeToggle) => {
      updatePixKey('');
      formik.setFieldValue('key', '');
      togglePixType(pixTypeToggle);
      setTimeout(() => {
        const pixKeyElement =
          document.querySelector<HTMLInputElement>('#pixKey');
        if (typeof pixKeyElement?.focus === 'function') pixKeyElement.focus();
      });
    },
    [pix],
  );

  const onClose = () => {
    formik.resetForm();
    handleClose();
  };

  const pixValidation =
    formik.values.key !== '' && getErrorValidation(pixType, formik.values.key);

  return (
    <BusinessEditSideSheet
      open={isOpen}
      handleClose={onClose}
      handleSave={formik.submitForm}
      isLoading={isLoading}
      isDisabled={!!pixValidation}
      isRemoveDisabled={typeof pix?.key !== 'string' || pix?.key.length === 0}
      handleRemove={() => toggleConfirmModal(true)}
      title="Dados bancários"
    >
      <Heading4
        fontWeight="B"
        style={{
          color: theme.palette.brand.primary.base,
          marginBottom: '32px',
        }}
      >
        Pix
      </Heading4>
      <PixTypeTogglesArea>
        {pixTypeToggles.map((pixTypeToggle) => {
          return (
            <PixTypeTogglesButton
              selected={pixType === pixTypeToggle.value}
              onClick={() => onChangePixTypeToggle(pixTypeToggle.value)}
              key={pixTypeToggle.value}
            >
              {pixTypeToggle.label}
            </PixTypeTogglesButton>
          );
        })}
      </PixTypeTogglesArea>
      <StepContentInputArea>
        <StepContentInput isHalf={true}>
          <CustomInput
            name="key"
            id="pixKey"
            value={applyFormat(formik.values.key, pixType)}
            handleInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={getPlaceholder(pixType)}
            labelValue="Insira abaixo a chave Pix"
            validationError={formik.touched.key ? pixValidation || '' : ''}
          />
        </StepContentInput>
      </StepContentInputArea>
      <ConfirmModal
        isColumnButtons={true}
        isOpen={isConfirmModalOpen}
        icon={<AttentionCircularRedIcon />}
        title="A chave Pix será excluída. Você tem certeza?"
        btnConfirmText="Confirmar"
        color="danger"
        handleConfirm={onConfirmAction}
        handleClose={() => toggleConfirmModal(false)}
      >
        {`Esta ação é irreversível. Solicitações em aberto para esta pessoa serão pagas com os dados bancários presentes no cadastro no momento em que a assinatura das partes for finalizada.`}
      </ConfirmModal>
    </BusinessEditSideSheet>
  );
};

export default NaturalPersonEditPixSection;
