const EyeIcon = ({ color, ...props }: { color?: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9997 3.5C17.3917 3.5 21.8777 7.38 22.8187 12.5C21.8787 17.62 17.3917 21.5 11.9997 21.5C6.60766 21.5 2.12166 17.62 1.18066 12.5C2.12066 7.38 6.60766 3.5 11.9997 3.5ZM11.9997 19.5C14.0391 19.4996 16.0181 18.8068 17.6125 17.5352C19.207 16.2635 20.3226 14.4883 20.7767 12.5C20.3209 10.5133 19.2046 8.74 17.6103 7.47003C16.016 6.20005 14.038 5.50853 11.9997 5.50853C9.96136 5.50853 7.98335 6.20005 6.38904 7.47003C4.79473 8.74 3.67843 10.5133 3.22266 12.5C3.67676 14.4883 4.79234 16.2635 6.38681 17.5352C7.98128 18.8068 9.9602 19.4996 11.9997 19.5ZM11.9997 17C10.8062 17 9.6616 16.5259 8.81768 15.682C7.97377 14.8381 7.49966 13.6935 7.49966 12.5C7.49966 11.3065 7.97377 10.1619 8.81768 9.31802C9.6616 8.47411 10.8062 8 11.9997 8C13.1931 8 14.3377 8.47411 15.1816 9.31802C16.0256 10.1619 16.4997 11.3065 16.4997 12.5C16.4997 13.6935 16.0256 14.8381 15.1816 15.682C14.3377 16.5259 13.1931 17 11.9997 17ZM11.9997 15C12.6627 15 13.2986 14.7366 13.7674 14.2678C14.2363 13.7989 14.4997 13.163 14.4997 12.5C14.4997 11.837 14.2363 11.2011 13.7674 10.7322C13.2986 10.2634 12.6627 10 11.9997 10C11.3366 10 10.7007 10.2634 10.2319 10.7322C9.76306 11.2011 9.49966 11.837 9.49966 12.5C9.49966 13.163 9.76306 13.7989 10.2319 14.2678C10.7007 14.7366 11.3366 15 11.9997 15Z"
      fill={color ?? "#7664FA"}
    />
  </svg>
);
export default EyeIcon;
