const InstallmentsOverdueIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10.5" r="8.75" fill="#F50404" />
    <path
      d="M12.499 13.417C12.4443 13.4171 12.39 13.4064 12.3394 13.3855C12.2888 13.3646 12.2428 13.3339 12.2041 13.2952L9.99947 11.0877L7.79404 13.2952C7.71592 13.3734 7.60997 13.4173 7.49951 13.4173C7.38904 13.4173 7.2831 13.3734 7.20498 13.2952C7.12688 13.217 7.08301 13.111 7.08301 13.0004C7.08301 12.8899 7.12688 12.7838 7.20498 12.7056L9.41041 10.4981L7.20498 8.29064C7.12909 8.212 7.0871 8.10667 7.08805 7.99734C7.089 7.88801 7.13282 7.78343 7.21005 7.70611C7.28729 7.6288 7.39178 7.58495 7.50101 7.584C7.61024 7.58305 7.71547 7.62508 7.79404 7.70104L9.99947 9.90852L12.2041 7.70104C12.2826 7.62508 12.3879 7.58305 12.4971 7.584C12.6063 7.58495 12.7108 7.6288 12.788 7.70611C12.8653 7.78343 12.9091 7.88801 12.91 7.99734C12.911 8.10667 12.869 8.212 12.7931 8.29064L10.5885 10.4981L12.794 12.7048C12.8524 12.7631 12.8922 12.8375 12.9083 12.9185C12.9244 12.9994 12.9162 13.0834 12.8846 13.1597C12.853 13.236 12.7995 13.3011 12.7308 13.347C12.6622 13.3928 12.5815 13.4171 12.499 13.417Z"
      fill="#FDFDFD"
      stroke="#FDFDFD"
      stroke-width="0.5"
    />
  </svg>
);

export default InstallmentsOverdueIcon;
