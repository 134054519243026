const ExternalIcon = ({
  color,
  width,
  height,
}: {
  color: string;
  width?: number;
  height?: number;
}) => (
  <svg
    width={width ?? 14}
    height={height ?? 14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1171 13.5C11.8708 13.5 11.6346 13.4022 11.4605 13.228C11.2863 13.0539 11.1885 12.8177 11.1885 12.5714V3.67014L1.6298 13.227C1.45361 13.3894 1.22146 13.4774 0.981895 13.4726C0.742328 13.4678 0.513888 13.3706 0.344335 13.2013C0.174782 13.032 0.0772431 12.8037 0.0721109 12.5641C0.0669788 12.3245 0.154651 12.0923 0.316797 11.9159L9.87551 2.35714H0.974226C0.727953 2.35714 0.491767 2.25931 0.317627 2.08517C0.143486 1.91103 0.0456543 1.67484 0.0456543 1.42857C0.0456543 1.1823 0.143486 0.946112 0.317627 0.771972C0.491767 0.597831 0.727953 0.5 0.974226 0.5H12.1171C12.3634 0.5 12.5995 0.597831 12.7737 0.771972C12.9478 0.946112 13.0457 1.1823 13.0457 1.42857V12.5714C13.0457 12.8177 12.9478 13.0539 12.7737 13.228C12.5995 13.4022 12.3634 13.5 12.1171 13.5Z"
      fill={color}
    />
  </svg>
);

export default ExternalIcon;
