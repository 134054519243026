import { BaseModal, Heading3, TextL } from 'celcoin-design-system';
import { WarningRounded } from '@mui/icons-material';
import { Container } from './styles';
import { CloseIcon } from 'app/components';
import { useTheme } from '@mui/material';

export interface OversizedRegistersModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleRequestReport: () => void;
}

const OversizedRegistersModal = ({
  isOpen,
  handleClose,
  handleRequestReport,
}: OversizedRegistersModalProps) => {
  const theme = useTheme();
  return (
    <BaseModal open={isOpen} onClose={handleClose}>
      <Container>
        <div className="header">
          <Heading3>Solicitar relatório</Heading3>
          <div className="close-btn" onClick={handleClose}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </div>
        <div className="body">
          <div className="icon">
            <WarningRounded
              style={{
                color: theme.palette.brand.primary.base,
                fontSize: '48px',
              }}
            />
          </div>
          <div className="text">
            <TextL>
              Este resultado contém mais de 100 registros, quer{' '}
              <strong onClick={handleRequestReport}>
                receber o relatório completo por email?
              </strong>
            </TextL>
          </div>
        </div>
      </Container>
    </BaseModal>
  );
};

export default OversizedRegistersModal;
