import { useState, useContext } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  CustomCircularIndicator,
  Heading3,
  TextL,
} from 'app/components';
import { BalanceIcon } from '../../../../../app/components/Icons/svgs';
import { BusinessRelationsList, ButtonArea, Header } from './styles';
import { PersonEditSidesheet, PersonRelationTable } from '../components';
import { LegalPersonHandlerStepperContext } from '../LegalPersonHandler';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { PersonListSidesheet } from 'modules/customer/components';
import { RelationPerson } from 'modules/customer/interfaces/businessRelation';
import {
  TRegisterBusinessData,
  useCustomerContext,
} from 'modules/customer/context';
import { useCustomerService } from 'modules/customer/services';
import { EFunctions } from 'modules/customer/context/CustomerProvider/customer.interfaces';

const BusinessRelations = () => {
  const [isBusinessSidesheetOpen, setIsBusinessSidesheetOpen] = useState(false);
  const [selectedPersonToEdit, setSelectedPersonToEdit] =
    useState<RelationPerson>();
  const {
    onBack,
    onBackTwoSteps,
    onForward,
    selectedRelationPersonList,
    hasCustomVariables,
    customVariables,
    removePersonFromList,
    updatePersonList,
    handleSelectedRelationPersonListOrder,
    handleCreatedBusiness,
    funding,
  } = useContext(LegalPersonHandlerStepperContext);
  const { creatBusiness, creatBusinessLoading } = useCustomerService();
  const {
    state: { registerData, product: productData, func },
  } = useCustomerContext();
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();

  const handleOpenBusinessSidesheet = () => {
    setIsBusinessSidesheetOpen(true);
  };

  const handleCloseBusinessSidesheet = () => {
    setIsBusinessSidesheetOpen(false);
  };

  const handleCloseEditSidesheet = () => {
    setSelectedPersonToEdit(undefined);
  };

  const removePerson = (personToRemove?: RelationPerson) => {
    removePersonFromList(personToRemove);
  };

  const handleTableItemsOrdering = () => {
    handleSelectedRelationPersonListOrder();
  };

  const checkHasSigner = () => {
    return !!selectedRelationPersonList?.find(
      (person) => person.signer === true,
    );
  };

  const handleCreateBusiness = async (
    parsedBusinessData: TRegisterBusinessData,
    selectedRelationList: RelationPerson[] | undefined,
  ) => {
    try {
      const res = await creatBusiness(parsedBusinessData, selectedRelationList);
      handleCreatedBusiness(res?.data);
      if (res) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      showSnackbar('Ocorreu um erro. Por favor tente novamente.');
      return false;
    }
  };

  const submitBusinessData = async () => {
    const parsedPersonData = {
      ...registerData,
      productId: productData.id,
      role: func.value,
      fundingId: funding as string,
      hasQualificationRequest: productData.qualification_required || false,
      customVariables: customVariables ?? undefined,
    };

    const success = await handleCreateBusiness(
      parsedPersonData,
      selectedRelationPersonList,
    );
    if (success) onForward();
  };

  const handleForward = () => {
    if (
      (!selectedRelationPersonList ||
        selectedRelationPersonList?.length <= 0) &&
      func.value !== EFunctions.EMPLOYER
    ) {
      showSnackbar('A empresa precisa ter ao menos 1 representante legal');
    }

    if (
      selectedRelationPersonList &&
      selectedRelationPersonList?.length > 0 &&
      func.value !== EFunctions.EMPLOYER
    ) {
      if (!checkHasSigner()) {
        showSnackbar('A empresa precisa ter ao menos 1 pessoa assinante');
        return;
      }
      submitBusinessData();
    }

    if (func.value === EFunctions.EMPLOYER) {
      submitBusinessData();
    }
  };

  return (
    <>
      <BoxSection boxVariant="rounded">
        <Header>
          <Heading3 fontWeight="Md">
            Quem são os representantes legais?
          </Heading3>
          <div>
            <ButtonDefault>
              <TextL
                weight="semibold"
                style={{ color: theme.palette.brand.primary.base }}
                onClick={handleOpenBusinessSidesheet}
              >
                Adicionar na lista
              </TextL>
            </ButtonDefault>
          </div>
        </Header>
        {selectedRelationPersonList &&
        selectedRelationPersonList?.length > 0 ? (
          <PersonRelationTable
            items={selectedRelationPersonList}
            selectedPersonToEdit={(person) => setSelectedPersonToEdit(person)}
            handleOrdering={handleTableItemsOrdering}
          />
        ) : (
          <BusinessRelationsList>
            <img src={BalanceIcon} alt="balanceicon" />
            <Heading3 style={{ color: '#C3C1CA', marginTop: '40px' }}>
              Nenhum adicionado
            </Heading3>
          </BusinessRelationsList>
        )}
        <ButtonArea>
          {creatBusinessLoading ? (
            <CustomCircularIndicator
              style={{ color: theme.palette.brand.primary.base }}
            />
          ) : (
            <>
              <ButtonDefault
                style={{ width: '55px' }}
                onClick={() =>
                  hasCustomVariables ? onBack() : onBackTwoSteps()
                }
              >
                <ArrowBack />
              </ButtonDefault>

              <ButtonPrimary style={{ width: '240px' }} onClick={handleForward}>
                {selectedRelationPersonList &&
                selectedRelationPersonList.length > 0
                  ? `Adicionar lista (${selectedRelationPersonList.length})`
                  : func.value !== EFunctions.EMPLOYER
                  ? 'Adicionar lista'
                  : 'Pular'}
              </ButtonPrimary>
            </>
          )}
        </ButtonArea>
      </BoxSection>
      <PersonListSidesheet
        isOpen={isBusinessSidesheetOpen}
        handleClose={handleCloseBusinessSidesheet}
      />
      <PersonEditSidesheet
        isOpen={!!selectedPersonToEdit}
        handleClose={handleCloseEditSidesheet}
        personData={selectedPersonToEdit}
        removePerson={(removedPerson) => removePerson(removedPerson)}
        handleSave={(newPersonData) => updatePersonList(newPersonData)}
      />
    </>
  );
};

export default BusinessRelations;
