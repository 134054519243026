import { useCallback, useEffect, useState } from 'react';
import {
  IPixKey,
  PixType,
} from '../../context/CustomerProvider/customer.interfaces';
import { PixTypeTogglesArea, PixTypeTogglesButton } from './styles';
import {
  formatCNPJ,
  formatCPF,
  formatPhone,
  isValidCNPJ,
  isValidCPF,
  isValidEmail,
} from '../../../../app/helpers';
import { isValidKey, isValidPhoneNumber } from 'app/helpers/pixValidators';

export enum PixTypeToggle {
  CPF,
  CNPJ,
  PHONE,
  ALEATORY_KEY,
  EMAIL,
}

const pixTypeToggles: Array<{ value: PixTypeToggle; label: string }> = [
  { value: PixTypeToggle.CPF, label: 'CPF' },
  { value: PixTypeToggle.CNPJ, label: 'CNPJ' },
  { value: PixTypeToggle.EMAIL, label: 'E-mail' },
  { value: PixTypeToggle.PHONE, label: 'Celular' },
  { value: PixTypeToggle.ALEATORY_KEY, label: 'Chave aleatória' },
];

export const applyFormat = (pixKey: string, pixType: PixTypeToggle): string => {
  if (pixType === PixTypeToggle.CNPJ) return formatCNPJ(pixKey);
  if (pixType === PixTypeToggle.CPF) return formatCPF(pixKey);
  if (pixType === PixTypeToggle.PHONE) return formatPhone(pixKey);
  return pixKey;
};

export const getPlaceholder = (pixType: PixTypeToggle): string => {
  if (pixType === PixTypeToggle.CPF) return '000.000.000-00';
  if (pixType === PixTypeToggle.CNPJ) return '00.000.000/0000-00';
  if (pixType === PixTypeToggle.EMAIL) return 'examplo@exemplo.com.br';
  if (pixType === PixTypeToggle.PHONE) return '(00) 00000-0000';

  return 'Chave aleatória';
};

export const getErrorValidation = (
  pixType: PixTypeToggle,
  value: string,
): string | false => {
  if (pixType === PixTypeToggle.CPF && !isValidCPF(value)) {
    return 'Chave de CPF inválida';
  }
  if (pixType === PixTypeToggle.CNPJ && !isValidCNPJ(value)) {
    return 'Chave de CNPJ inválida';
  }
  if (pixType === PixTypeToggle.EMAIL && !isValidEmail(value)) {
    return 'Chave de e-mail inválida';
  }
  if (pixType === PixTypeToggle.PHONE && !isValidPhoneNumber(value)) {
    return 'Chave de telefone inválida';
  }
  if (pixType === PixTypeToggle.ALEATORY_KEY && !isValidKey(value)) {
    return 'Chave aleatória inválida';
  }
  return false;
};

export const getPixTypeToggleFromPixType = (
  pix: IPixKey | undefined,
): PixTypeToggle => {
  if (!pix) return PixTypeToggle.CPF;
  if (pix.key_type === PixType.TAXPAYER_ID) {
    if (pix.key.length > 11) return PixTypeToggle.CNPJ;
    return PixTypeToggle.CPF;
  }

  if (pix.key_type === PixType.EMAIL) return PixTypeToggle.EMAIL;
  if (pix.key_type === PixType.PHONE_NUMBER) return PixTypeToggle.PHONE;

  return PixTypeToggle.ALEATORY_KEY;
};

export const getPixTypeFromPixTypeToggle = (pix: PixTypeToggle): PixType => {
  if (pix === PixTypeToggle.CPF || pix === PixTypeToggle.CNPJ)
    return PixType.TAXPAYER_ID;

  if (pix === PixTypeToggle.EMAIL) return PixType.EMAIL;
  if (pix === PixTypeToggle.PHONE) return PixType.PHONE_NUMBER;

  return PixType.ALEATORY_KEY;
};

interface IPixSelector {
  pix?: IPixKey;
  onChange: (pixKey: string, pixType: PixTypeToggle) => void;
}

const PixSelector = ({ pix, onChange }: IPixSelector) => {
  const [pixType, togglePixType] = useState<PixTypeToggle>(
    getPixTypeToggleFromPixType(pix),
  );
  const [pixKey, updatePixKey] = useState<string>(
    pix?.key ? applyFormat(pix?.key, pixType) : '',
  );

  const onChangePixTypeToggle = useCallback(
    (pixTypeToggle: PixTypeToggle) => {
      updatePixKey('');
      togglePixType(pixTypeToggle);
      setTimeout(() => {
        const pixKeyElement =
          document.querySelector<HTMLInputElement>('#pixKey');
        if (typeof pixKeyElement?.focus === 'function') pixKeyElement.focus();
      });
    },
    [pix],
  );

  useEffect(() => {
    onChange(pixKey, pixType);
  }, [pixType, pixKey]);

  return (
    <PixTypeTogglesArea>
      {pixTypeToggles.map((pixTypeToggle) => {
        return (
          <PixTypeTogglesButton
            selected={pixType === pixTypeToggle.value}
            onClick={() => onChangePixTypeToggle(pixTypeToggle.value)}
            key={pixTypeToggle.value}
          >
            {pixTypeToggle.label}
          </PixTypeTogglesButton>
        );
      })}
    </PixTypeTogglesArea>
  );
};

export default PixSelector;
