import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 12px;
  border-radius: 8px;
`;

export const FormLegendCardWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
