import styled from '@emotion/styled';
import { css } from '@emotion/react';

export interface ISideMenuDrawerItemContainerProps {
  isSelected: boolean;
}

export const SideMenuDrawerItemContainer = styled.div<ISideMenuDrawerItemContainerProps>`

  .title-area {
    p {
      color: #fff;
    }
  }

  svg {
    color: #fff;
  }
  .MuiListItemButton-root	 {
    border-radius: 10px;
  }


  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      &:hover{


        .title-area {
          p {
            color: ${theme.palette?.brand.primary.base};
          }
        }

        .MuiListItemButton-root	 {
          background-color: #fff;
        }

        svg {
          color: ${theme.palette?.brand.primary.base};
        } 
      }

      .title-area {
        p {
          color: ${theme.palette?.brand.primary.base};
        }
      }
        
      .MuiListItemButton-root	 {
        background-color: #fff;
      }

      svg {
        color: ${theme.palette?.brand.primary.base};
      } 
    `}


`;

export const SideMenuDrawerItemContainerCollapsed = styled.div<ISideMenuDrawerItemContainerProps>`

  .MuiListItemButton-root	 {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      &:hover{
        .MuiListItemButton-root	 {
          background-color: #fff;
        }
      }

      .MuiListItemButton-root	 {
        background-color: #fff;
      }

    `}


`;

export interface IListItemButtonContentProps {
  isOpen: boolean;
}

export const ListItemButtonContent = styled.div<IListItemButtonContentProps>`
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  width: 100%;
  height: 100%;


  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.desktop + 'px'}){
    padding: 9px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 8px;

  }

  .arrowico {
    ${({ isOpen }) =>
      isOpen &&
      css`
      transform: rotate(180deg);
    `}
  }
`;

export const ListItemButtonWrapper = styled.div`
  width: 100%;
`;
