import { ConfirmModal } from 'app/components';
import { AttentionCircularRedIcon } from 'app/components/Icons/AttentionCircularIcon';
import {
  useApplicationsService,
  useQualificationsService,
} from 'modules/products/services/hooks';
import {
  EApplicationStatus,
  TApplicationResponse,
  TQualificationRequestApplication,
} from 'modules/products/services/hooks/useApplicationsService';
import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useRef,
} from 'react';
import { IQualificationChangeContext } from './qualificationChange.interfaces';

export const QualificationChangeContext = createContext<
  IQualificationChangeContext | undefined
>(undefined);

export interface IQualificationChangeProviderProps {
  children?: React.ReactNode;
}

export const QualificationChangeProvider = ({
  children,
}: IQualificationChangeProviderProps) => {
  const {
    cancelApplication,
    getQualificationRequestApplication,
    cancelApplicationLoading,
  } = useApplicationsService();
  const [
    cancelApplicationConfirmVisible,
    toggleCancelApplicationConfirmVisible,
  ] = useState<Partial<TApplicationResponse> | false>(false);
  const {
    updateApplicationQualificationStatus,
    updateApplicationQualificationStatusLoading,
  } = useQualificationsService();
  const promiseRef = useRef<{
    resolve?: (
      payload:
        | {
            isQualification?: boolean;
            newStatus: string | TQualificationRequestApplication;
          }
        | false,
    ) => void;
  }>({
    resolve: undefined,
  });

  const onCancelApplication = useCallback(() => {
    if (cancelApplicationConfirmVisible && cancelApplicationConfirmVisible.id) {
      cancelApplication(cancelApplicationConfirmVisible.id).then(() => {
        toggleCancelApplicationConfirmVisible(false);
        if (promiseRef.current?.resolve)
          promiseRef.current.resolve({
            isQualification: false,
            newStatus: EApplicationStatus.CANCELED,
          });
      });
    }
  }, [cancelApplicationConfirmVisible]);

  const onCloseCancelConfirmationModal = useCallback(() => {
    toggleCancelApplicationConfirmVisible(false);
    if (promiseRef.current?.resolve) promiseRef.current.resolve(false);
  }, []);

  const handleUpdateQualificationStatus = useCallback(
    (
      application: Partial<TApplicationResponse>,
      identifier: string,
      qualification?: TQualificationRequestApplication,
    ) => {
      return new Promise<
        | {
            isQualification?: boolean;
            newStatus: string | TQualificationRequestApplication;
          }
        | false
      >((resolve) => {
        if (!application || !application.product || !application.id) {
          resolve(false);
          return;
        }
        if (
          application.status !== EApplicationStatus.PENDING_QUALIFICATION &&
          identifier === EApplicationStatus.CANCELED
        ) {
          toggleCancelApplicationConfirmVisible(application);
          promiseRef.current.resolve = resolve;
          return;
        }

        if (application.status === EApplicationStatus.PENDING_QUALIFICATION) {
          if (identifier === EApplicationStatus.CANCELED) {
            toggleCancelApplicationConfirmVisible(application);
          } else if (qualification) {
            updateApplicationQualificationStatus(
              application.product.id,
              qualification.id,
              identifier,
              'BORROWER',
            ).then(() => {
              getQualificationRequestApplication(
                application?.product?.id || '',
                0,
                application?.borrower?.id || '',
                application.id,
              ).then((qualificationRequests) => {
                resolve({
                  isQualification: true,
                  newStatus: qualificationRequests
                    ?.content[0] as TQualificationRequestApplication,
                });
              });
            });
          } else {
            resolve(false);
          }
        }
      });
    },
    [],
  );

  return (
    <QualificationChangeContext.Provider
      value={{
        handleUpdateQualificationStatus,
        handleUpdateQualificationStatusLoading:
          updateApplicationQualificationStatusLoading,
      }}
    >
      {children}
      <ConfirmModal
        isOpen={!!cancelApplicationConfirmVisible}
        icon={<AttentionCircularRedIcon />}
        title="Essa ação é irreversível, você tem certeza?"
        color="danger"
        btnConfirmText="Cancelar solicitação"
        isLoading={cancelApplicationLoading}
        handleConfirm={onCancelApplication}
        handleClose={onCloseCancelConfirmationModal}
        handleCancel={onCloseCancelConfirmationModal}
      >
        Esta solicitação não poderá ser mais utilizada para pagamentos ou
        recebimentos. Créditos já solicitados ou concedidos não serão alterados.
      </ConfirmModal>
    </QualificationChangeContext.Provider>
  );
};

export const useQualificationChangeContext = () => {
  const context = useContext(QualificationChangeContext);

  if (context === undefined) {
    throw new Error(
      'useQualificationChangeContext must be used within a QualificationChangeProvider',
    );
  }
  return context;
};
