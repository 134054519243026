import { formatToCurrencyFloat } from 'app/utils/normalizer';

export const formatInterestRate = (
  interestRate?: string,
): number | undefined => {
  if (!interestRate || interestRate.length === 0) return undefined;
  const precision = interestRate.length > 2 ? interestRate.length - 1 : 2;
  return parseFloat(
    (
      (formatToCurrencyFloat(interestRate) as unknown as number) / 100
    ).toPrecision(precision),
  );
};
