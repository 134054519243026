import {
  formatDateFromBackend,
  formatStringHourFromBackend,
} from 'app/helpers/dateHelpers';
import {
  capitalize,
  formatCurrency,
  normalizeAddDigits,
  normalizeCpfCnpj,
} from 'app/utils/normalizer';
import { ReactNode, useCallback, useMemo } from 'react';
import { ViewBoxContainer, ViewBoxContent, ViewBoxItem } from './styles';
import get from 'lodash.get';
import {
  ACCOUNT_TYPE_OBJECT,
  BANK_LIST_OBJECT,
  formatCNPJ,
  formatCPF,
  formatCep,
  formatPhone,
} from 'app/helpers';
import { Link } from 'react-router-dom';
import { CopyIcon, ExternalIcon } from 'app/components/Icons';
import { Tooltip, useTheme } from '@mui/material';
import { ButtonPrimary } from '../ButtonPrimary';
import { IBankAccount } from 'modules/customer/context/CustomerProvider/customer.interfaces';
import { InfoOutlined } from '@mui/icons-material';
import { TextM } from '../typography';
import { BRAZILIAN_STATES_OBJECT } from 'app/helpers/addressHelper';

export enum EViewBoxColumnType {
  DATE,
  DATETIME,
  CURRENCY,
  PERCENT,
  DEFAULT,
  CPF,
  CNPJ,
  PHONE,
  EXTERNAL,
  URL,
  COPY,
  EMPTY,
  PHONE_STRING,
  PHONE_PIX,
  ITALIC,
  CPFCNPJ,
  BANK,
  BANK_ACCOUNT,
  BANK_TYPE,
  ENUM,
  BOOLEAN,
  CEP,
  STATE,
  CAPITALIZED,
}

export type TViewBoxColumn = {
  path: string;
  label: string;
  type?: EViewBoxColumnType;
  prefix?: string;
  sufix?: string;
  x100?: boolean;
  maxWidth?: string;
  full?: boolean;
  digits?: number;
  onCopy?: (value: string) => void;
  hidden?: boolean;
  url?: string;
  enum?: { [key: string]: string };
  tooltip?: string;
  size?: number;
};

interface IViewBoxProps {
  columns: TViewBoxColumn[];
  values: { [key: string]: string | number };
  style?: React.CSSProperties;
  size?: number;
  textAlign?: 'left' | 'right';
}

type PhoneType = {
  country_code: string;
  area_code: string;
  number: string;
};

const ViewBox = ({
  columns: allColumns,
  values,
  style,
  size = 3,
  textAlign,
}: IViewBoxProps) => {
  const theme = useTheme();
  const columns = useMemo(
    () => allColumns.filter((c) => !c.hidden),
    [allColumns],
  );
  const matrix = useMemo<Array<Array<TViewBoxColumn>>>(() => {
    const arrayOfArrays = [];
    let i = 0;
    while (i < columns.length) {
      const newSize = columns[i].size || size;
      arrayOfArrays.push(columns.slice(i, i + newSize));
      i += newSize;
    }
    return arrayOfArrays;
  }, [columns]);

  const getFormatedValue = useCallback(
    (item: TViewBoxColumn): string | ReactNode | undefined => {
      const value = get(values, item?.path, 'undefined');

      if (
        value === null ||
        value === undefined ||
        value === 'null' ||
        value === 'undefined' ||
        value === ''
      )
        return '-';

      const applySufixPrefix = (v: string | undefined): string | undefined => {
        if (v) {
          return `${item.prefix ? `${item.prefix} ` : ''}${v}${
            item.sufix ? `${item.sufix} ` : ''
          }`;
        }
        return v;
      };

      if (item.type === EViewBoxColumnType.DATE && typeof value === 'string')
        return applySufixPrefix(
          formatDateFromBackend(value).toLocaleDateString('pt-BR'),
        );

      if (
        item.type === EViewBoxColumnType.DATETIME &&
        typeof value === 'string' &&
        value.length > 0
      )
        return applySufixPrefix(
          `${formatDateFromBackend(value).toLocaleDateString(
            'pt-BR',
          )} às ${formatStringHourFromBackend(value)}`,
        );

      if (
        item.type === EViewBoxColumnType.CURRENCY &&
        typeof value === 'number'
      )
        return applySufixPrefix(formatCurrency(value)?.toString());

      if (
        item.type === EViewBoxColumnType.CURRENCY &&
        typeof value === 'string' &&
        !isNaN(parseFloat(value))
      )
        return applySufixPrefix(formatCurrency(parseFloat(value))?.toString());

      if (
        item.type === EViewBoxColumnType.ENUM &&
        typeof value === 'string' &&
        typeof item.enum === 'object' &&
        item.enum[value]
      )
        return applySufixPrefix(item.enum[value]);

      if (
        item.type === EViewBoxColumnType.BOOLEAN &&
        typeof value === 'string'
      ) {
        if (value === 'true') return 'Sim';
        if (value === 'false') return 'Não';
      }

      if (
        item.type === EViewBoxColumnType.BOOLEAN &&
        typeof value === 'boolean'
      ) {
        if (value) return 'Sim';
        return 'Não';
      }

      if (item.type === EViewBoxColumnType.STATE && typeof value === 'string')
        return applySufixPrefix(BRAZILIAN_STATES_OBJECT[value]);

      if (item.type === EViewBoxColumnType.PERCENT && typeof value === 'number')
        return applySufixPrefix(
          `${normalizeAddDigits(value * (item.x100 ? 100 : 1), item.digits)
            .toString()
            .replace('.', ',')}%`,
        );

      if (
        item.type === EViewBoxColumnType.CAPITALIZED &&
        typeof value === 'string'
      )
        return applySufixPrefix(capitalize(value));

      if (item.type === EViewBoxColumnType.PHONE && typeof value === 'object') {
        if (!(value as PhoneType)?.area_code && !(value as PhoneType)?.number)
          return '-';
        return applySufixPrefix(
          formatPhone(
            `${(value as PhoneType)?.area_code}${(value as PhoneType)?.number}`,
          ),
        );
      }

      if (
        item.type === EViewBoxColumnType.PHONE_PIX &&
        typeof value === 'string'
      )
        return applySufixPrefix(formatPhone(value.slice(3)));

      if (
        item.type === EViewBoxColumnType.PHONE_STRING &&
        typeof value === 'string'
      )
        return applySufixPrefix(formatPhone(value));

      if (item.type === EViewBoxColumnType.CEP && typeof value === 'string')
        return applySufixPrefix(formatCep(value));

      if (item.type === EViewBoxColumnType.CPF && typeof value === 'string')
        return applySufixPrefix(formatCPF(value));

      if (item.type === EViewBoxColumnType.CNPJ && typeof value === 'string')
        return applySufixPrefix(formatCNPJ(value));

      if (item.type === EViewBoxColumnType.CPFCNPJ && typeof value === 'string')
        return applySufixPrefix(normalizeCpfCnpj(value));

      if (
        item.type === EViewBoxColumnType.BANK &&
        typeof value === 'string' &&
        BANK_LIST_OBJECT[value]
      ) {
        return `${BANK_LIST_OBJECT[value]} (${value})`;
      }

      if (
        item.type === EViewBoxColumnType.BANK_ACCOUNT &&
        typeof value === 'object' &&
        (value as IBankAccount).bank_account
      ) {
        if (!(value as IBankAccount).bank_account_digit)
          return (value as IBankAccount).bank_account;
        return `${(value as IBankAccount).bank_account}-${
          (value as IBankAccount).bank_account_digit
        }`;
      }

      if (
        item.type === EViewBoxColumnType.BANK_TYPE &&
        typeof value === 'string' &&
        ACCOUNT_TYPE_OBJECT[value]
      ) {
        return ACCOUNT_TYPE_OBJECT[value];
      }

      if (
        item.type === EViewBoxColumnType.EXTERNAL &&
        typeof value === 'string' &&
        typeof item.url === 'string'
      ) {
        return (
          <Link
            to={item.url}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'black',
              textDecoration: 'none',
            }}
          >
            {applySufixPrefix(value)}
            <span style={{ paddingLeft: '10px' }}>
              <ExternalIcon
                color={theme.palette.brand.primary.base as string}
              />
            </span>
          </Link>
        );
      }
      if (item.type === EViewBoxColumnType.URL && typeof value === 'string') {
        return (
          <Link
            to={value}
            target="_blank"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: theme.palette.brand.primary.base as string,
            }}
          >
            {applySufixPrefix(value)}
            <span style={{ paddingLeft: '10px' }}>
              <ExternalIcon
                color={theme.palette.brand.primary.base as string}
              />
            </span>
          </Link>
        );
      }
      if (
        item.type === EViewBoxColumnType.COPY &&
        typeof value === 'string' &&
        typeof item.onCopy === 'function'
      ) {
        return (
          <>
            {applySufixPrefix(value)}
            <ButtonPrimary
              typeVariant="ghost"
              style={{
                padding: '0px',
                marginLeft: '10px',
                width: 'fit-content',
              }}
              onClick={() => item.onCopy && item.onCopy(value)}
            >
              <CopyIcon color={theme.palette.brand.primary.base as string} />
            </ButtonPrimary>
          </>
        );
      }

      if (item.type === EViewBoxColumnType.ITALIC)
        return <em>{applySufixPrefix(String(value))}</em>;

      if (!item.type || item.type === EViewBoxColumnType.DEFAULT)
        return applySufixPrefix(String(value));

      return '-';
    },
    [values],
  );

  return (
    <ViewBoxContainer style={style}>
      {matrix.map((content, contentInx) => {
        return (
          <ViewBoxContent key={`content-${contentInx}`}>
            {content.map((item, inx) => {
              const isLast =
                contentInx === matrix.length - 1 &&
                inx === content.length - 1 &&
                inx < 2;
              return (
                <ViewBoxItem
                  size={item.size || size}
                  isLast={isLast}
                  key={item?.path}
                  item={item}
                  textAlign={textAlign}
                >
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    {typeof item.tooltip === 'string' && (
                      <Tooltip
                        placement="bottom-start"
                        title={
                          <TextM style={{ color: theme.palette.common.white }}>
                            {item.tooltip}
                          </TextM>
                        }
                      >
                        <InfoOutlined
                          style={{
                            marginRight: '0.6rem',
                            color: theme.palette.brand.gray[900],
                          }}
                        />
                      </Tooltip>
                    )}
                    <strong style={{ lineHeight: '1rem' }}>{item.label}</strong>
                  </span>

                  <div
                    className="viewbox-value"
                    style={{
                      maxWidth: item.maxWidth ?? '70%',
                    }}
                  >
                    {getFormatedValue(item)}
                  </div>
                </ViewBoxItem>
              );
            })}
          </ViewBoxContent>
        );
      })}
    </ViewBoxContainer>
  );
};

export default ViewBox;
