import { EPermission } from 'modules/auth/context/permify/permify.interfaces';
import { usePermify } from 'modules/auth/context';
import React from 'react';
import { NoAccess } from 'app/components';

export interface IHasAccessProps {
  children?: React.ReactNode;
  permission: EPermission;
}

const HasAccess = ({ children, permission }: IHasAccessProps) => {
  const { isAuthorized } = usePermify();

  if (!isAuthorized(permission)) {
    return <NoAccess />;
  }

  return <>{children}</>;
};

export default HasAccess;
