import { ButtonPrimary, Heading3, Spinner, TextL, TextM } from 'app/components';
import { useTheme } from '@mui/material';
import { TApplicationResponse } from 'modules/products/services/hooks/useApplicationsService';
import { ProductSideSheet } from 'modules/products/components/ProductSideSheet';
import {
  ButtonContainer,
  Content,
  ViewBoxContainer,
  ViewBoxContent,
  ViewBoxItem,
  Wrapper,
} from './styles';
import { Edit } from '@mui/icons-material';
import { TProducts } from 'modules/products/context';
import { BANK_LIST_OBJECT } from 'app/helpers';
import { normalizeCpfCnpj } from 'app/utils/normalizer';
import {
  applyFormat,
  getPixTypeToggleFromPixType,
} from 'modules/customer/components/PixSelector';
import { useEffect, useState } from 'react';
import { useApplicationsService } from 'modules/products/services/hooks';

export interface RetryConfirmationModalProps {
  isOpen: boolean;
  applicationData: TApplicationResponse | null;
  productData: TProducts | null | undefined;
  handleClose: () => void;
  handleEdit: () => void;
  retryPayment: () => void;
}

const RetryPaymentViewSidesheet = ({
  isOpen,
  applicationData,
  handleClose,
  handleEdit,
  retryPayment,
}: RetryConfirmationModalProps) => {
  const theme = useTheme();
  const { getApplication, applicationLoading } = useApplicationsService();

  const [application, updateApplication] =
    useState<TApplicationResponse | null>(null);

  const beneficiaryAccount =
    application?.payment_orders?.[0]?.beneficiary_account;

  const onEdit = () => {
    handleEdit();
  };

  const handleRetry = () => {
    retryPayment();
  };

  useEffect(() => {
    if (isOpen) {
      getApplication(applicationData?.id || '').then((a) =>
        updateApplication(a),
      );
    }
  }, [isOpen]);

  return (
    <ProductSideSheet
      open={isOpen}
      handleClose={handleClose}
      title="Retentativa de pagamento"
    >
      <Wrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Heading3>Conta beneficiária</Heading3>
          <ButtonPrimary
            onClick={onEdit}
            style={{
              width: '40px',
              padding: '8px',
              minWidth: 'auto',
              height: 'auto',
            }}
          >
            <Edit />
          </ButtonPrimary>
        </div>

        <Content>
          {applicationLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <div>
              <ViewBoxContainer>
                <ViewBoxContent>
                  <ViewBoxItem>
                    <span>
                      <TextL>Nome do banco</TextL>
                    </span>
                    <span>
                      <TextL>
                        {beneficiaryAccount?.external_bank_account?.ispb_code
                          ? BANK_LIST_OBJECT[
                              beneficiaryAccount?.external_bank_account
                                ?.ispb_code
                            ]
                          : '-'}
                      </TextL>
                    </span>
                  </ViewBoxItem>
                </ViewBoxContent>
                <ViewBoxContent>
                  <ViewBoxItem>
                    <TextL>Agência</TextL>
                    <TextL>
                      {beneficiaryAccount?.external_bank_account?.bank_branch
                        ? beneficiaryAccount?.external_bank_account?.bank_branch
                        : '-'}
                    </TextL>
                  </ViewBoxItem>
                </ViewBoxContent>
                <ViewBoxContent>
                  <ViewBoxItem>
                    <TextL>Conta</TextL>
                    <TextL>
                      {beneficiaryAccount?.external_bank_account?.bank_account
                        ? beneficiaryAccount?.external_bank_account
                            ?.bank_account +
                          '-' +
                          beneficiaryAccount?.external_bank_account
                            ?.bank_account_digit
                        : '-'}
                    </TextL>
                  </ViewBoxItem>
                </ViewBoxContent>
                <ViewBoxContent>
                  <ViewBoxItem>
                    <TextL>CPF/CNPJ</TextL>
                    <TextL>
                      {normalizeCpfCnpj(
                        beneficiaryAccount?.holder?.taxpayer_id || '',
                      )}
                    </TextL>
                  </ViewBoxItem>
                </ViewBoxContent>
                <ViewBoxContent>
                  <ViewBoxItem>
                    <TextL>Nome</TextL>
                    <TextL>{beneficiaryAccount?.holder.name || '-'}</TextL>
                  </ViewBoxItem>
                </ViewBoxContent>
                <ViewBoxContent>
                  <ViewBoxItem>
                    <TextL>Pix</TextL>
                    <TextL>
                      {beneficiaryAccount?.pix
                        ? applyFormat(
                            beneficiaryAccount?.pix.key,
                            getPixTypeToggleFromPixType(
                              beneficiaryAccount?.pix || undefined,
                            ),
                          )
                        : '-'}
                    </TextL>
                  </ViewBoxItem>
                </ViewBoxContent>
              </ViewBoxContainer>
            </div>
          )}
          <div>
            <ButtonContainer>
              <ButtonPrimary typeVariant="outline" onClick={handleClose}>
                <TextM
                  weight="Semibold"
                  style={{
                    color: theme.palette.brand.primary.base,
                    marginRight: '8px',
                  }}
                >
                  Cancelar
                </TextM>
              </ButtonPrimary>
              <ButtonPrimary onClick={handleRetry}>
                <TextM
                  weight="Semibold"
                  style={{
                    color: theme.palette.brand.neutral.base,
                    marginRight: '8px',
                  }}
                >
                  Retentar pagamento
                </TextM>
              </ButtonPrimary>
            </ButtonContainer>
          </div>
        </Content>
      </Wrapper>
    </ProductSideSheet>
  );
};

export default RetryPaymentViewSidesheet;
