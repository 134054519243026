import { QrCode } from '@mui/icons-material';
import React, { useState } from 'react';
import { routingPath } from '../../../../../../app/routes';
import {
  ButtonPrimary,
  Heading4,
  RegularText,
  RequestErrorItem,
} from '../../../../../../app/components';
import { useCustomNavigation } from '../../../../../../app/hooks';
import { AuthItem } from '../AuthItem';
import { Block } from '../Block';
import { Clipboard } from '../Clipboard';
import { ConfirmationModal } from '../ConfirmationModal';
import { Container, InputArea, ListAuthItemArea, QrCodeArea } from './styles';
import { useTheme } from '@mui/material';
import { CustomInput } from 'celcoin-design-system';

const EnableTwoFactorAuthContent = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { navigate } = useCustomNavigation();
  const theme = useTheme();

  return (
    <Container>
      <Block>
        <Heading4>Autenticação de 2 fatores</Heading4>
        <RegularText style={{ marginTop: '16px' }}>
          Para acessar o painel, é necessário fazer a autenticação de 2 fatores.
          Este é um recurso obrigatório, que trará uma camada adicional de
          segurança para você, cliente Celcoin.
        </RegularText>
        <RegularText style={{ marginTop: '24px' }}>
          Para realizar a autenticação utilize um dos apps disponíveis em sua
          loja Android ou IOs:
        </RegularText>

        <ListAuthItemArea>
          <AuthItem title="Authy" />
          <AuthItem title="Google Authenticator" />
          <AuthItem title="LastPass Authenticator" />
        </ListAuthItemArea>

        <RegularText style={{ marginTop: '24px' }}>
          Em seguida, abra o aplicativo escolhido, selecione a opção para
          “Escanear o código” e realize a leitura do QR code ao lado.
        </RegularText>
      </Block>
      <Block style={{ backgroundColor: theme.palette.brand.background.base }}>
        {error ? (
          <RequestErrorItem
            errorContent="Ocorreu um erro ao gerar o QR code."
            errorHandler={() => setError(false)}
          />
        ) : (
          <>
            <QrCodeArea>
              <QrCode sx={{ width: '174px', height: '174px' }} />
            </QrCodeArea>
            <Clipboard code="P6GZTGCEUMMX654A3216SASD45DSW5R4G542" />
            <RegularText style={{ marginTop: '16px' }}>
              Agora forneça o código de autenticação para registrar sua chave
              única 2FA.
            </RegularText>
            <InputArea>
              <CustomInput
                value={code}
                handleInputChange={(e) => setCode(e.target.value)}
                placeholder="Digite seu código"
              />
            </InputArea>
            <ButtonPrimary
              style={{ marginTop: '19px' }}
              onClick={() => setIsModalVisible(true)}
            >
              ATIVAR 2FA
            </ButtonPrimary>
          </>
        )}
      </Block>
      <ConfirmationModal
        open={isModalVisible}
        handleModal={() => {
          setIsModalVisible(false);
          navigate(routingPath.home.base);
        }}
      />
    </Container>
  );
};

export default EnableTwoFactorAuthContent;
