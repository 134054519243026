import { useState, useEffect } from 'react';
import { Divider, useTheme } from '@mui/material';
import {
  ButtonDefault,
  ButtonPrimary,
  CloseIcon,
  Heading3,
  TextL,
  TextM,
  TextS,
} from 'app/components';
import { formatCPF, formatDecimalField, formatPhone } from 'app/helpers';
import {
  ButtonArea,
  CloseIconWrapper,
  ContentArea,
  HeaderArea,
  Section,
  SelectedAddInfo,
} from './styles';
import { InfoDivider } from 'modules/customer/pages/LegalPersonHandler/components';
import { TBusinessRelationsData } from '../../LegalPersonRepresentatives';
import { ConfirmationModal } from '../ConfirmationModal';
import {
  BaseSidesheet,
  CustomInput,
  CustomRadioInput,
  CustomSwitchIOS,
} from 'celcoin-design-system';
import { useFormik } from 'formik';
import { businessRelationSchema } from 'modules/customer/helpers/businessValidation';
import {
  formatNumberToStringWithComma,
  formatToCurrencyFloat,
} from 'app/utils/normalizer';

export interface IPersonEditSidesheetProps {
  isOpen: boolean;
  handleClose: () => void;
  personData?: TBusinessRelationsData;
  removePerson?: (removedPerson?: TBusinessRelationsData) => void;
  handleSave?: (removedPerson?: TBusinessRelationsData) => void;
}

const PersonEditSidesheet = ({
  isOpen,
  personData,
  handleClose,
  removePerson,
  handleSave,
}: IPersonEditSidesheetProps) => {
  const [isConfirmationRemoveModalOpen, setIsConfirmationRemoveModalOpen] =
    useState(false);
  const [personSelectedToEdit, setPersonSelectedToEdit] =
    useState<TBusinessRelationsData>();
  const [signForCompany, setSignForCompany] = useState(
    personData?.signer || false,
  );
  const [workRelation, setWorkRelation] = useState(personData?.type || 'OTHER');
  const theme = useTheme();

  const handleCloseModal = () => {
    handleClose();
  };

  const handleOpenConfirmationRemoveModal = () => {
    setIsConfirmationRemoveModalOpen(true);
  };

  const handleRemovePerson = () => {
    removePerson?.(personData);
    setIsConfirmationRemoveModalOpen(false);
    handleCloseModal();
  };

  const closeConfirmationRemoveModal = () => {
    setIsConfirmationRemoveModalOpen(false);
  };

  const setRelationType = (value: string) => {
    setWorkRelation(value);
    setPersonSelectedToEdit((state) => {
      if (state) {
        return {
          ...state,
          type: value,
        };
      }
    });
  };

  const handleSignForCompany = (value: boolean) => {
    setSignForCompany(value);
    setPersonSelectedToEdit((state) => {
      if (state) {
        return {
          ...state,
          signer: value,
        };
      }
    });
  };

  const handleShare = (value: string) => {
    return formatDecimalField(value);
  };

  useEffect(() => {
    if (personData) {
      setPersonSelectedToEdit(personData);
      setSignForCompany(personData.signer);
      setWorkRelation(personData.type);
    }
  }, [personData]);

  const formik = useFormik({
    initialValues: {
      share: personData?.share
        ? formatNumberToStringWithComma(personData?.share * 100)
        : '',
    },
    validationSchema: businessRelationSchema,
    onSubmit: (values) => {
      handleSave?.({
        ...personSelectedToEdit,
        share: values.share
          ? Number(formatToCurrencyFloat(values.share))
          : null,
      } as TBusinessRelationsData);
      handleClose?.();
    },
  });

  useEffect(() => {
    if (personData && personData.share) {
      formik.setFieldValue(
        'share',
        formatNumberToStringWithComma(personData.share * 100),
      );
    }
  }, [personData]);

  return (
    <>
      <BaseSidesheet open={isOpen} handleClose={handleCloseModal}>
        <HeaderArea>
          <Heading3 fontWeight="Sb">Editar representante</Heading3>
          <CloseIconWrapper onClick={handleCloseModal}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </CloseIconWrapper>
        </HeaderArea>
        <Divider />
        <ContentArea>
          <Section>
            <TextM weight="Semibold">
              {personSelectedToEdit?.person?.full_name}
            </TextM>
            <div style={{ display: 'flex', marginTop: '8px' }}>
              <TextS>
                {formatCPF(personSelectedToEdit?.person?.taxpayer_id || '-')}
              </TextS>
              <InfoDivider />
              <TextS>
                {formatPhone(
                  `${
                    (personSelectedToEdit?.person?.phone?.area_code || '') +
                    (personSelectedToEdit?.person?.phone?.number || '')
                  }`,
                )}
              </TextS>
            </div>
            <TextS style={{ marginTop: '4px' }}>
              {personSelectedToEdit?.person?.email}
            </TextS>
          </Section>
          <Section>
            <SelectedAddInfo>
              <div className="switch-area">
                <TextL weight="medium">Assina pela empresa</TextL>
                <div className="switch-item">
                  {!signForCompany && (
                    <TextL style={{ color: '#9490A3' }}>Não</TextL>
                  )}
                  <CustomSwitchIOS
                    onChange={(evt) => handleSignForCompany(evt.target.checked)}
                    checked={signForCompany}
                  />
                </div>
              </div>
              <Divider />
              <div style={{ marginTop: '20px' }}>
                <TextL weight="medium">Vínculo trabalhista</TextL>
                <div
                  className="relationship-area"
                  style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}
                >
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'EMPLOYEE'}
                      handleClick={() => setRelationType('EMPLOYEE')}
                    />
                    <TextL>Empregado</TextL>
                  </div>
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'PARTNER'}
                      handleClick={() => setRelationType('PARTNER')}
                    />
                    <TextL>Sócio</TextL>
                  </div>
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'MANAGER'}
                      handleClick={() => setRelationType('MANAGER')}
                    />
                    <TextL>Administrador</TextL>
                  </div>
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'PROXY'}
                      handleClick={() => setRelationType('PROXY')}
                    />
                    <TextL>Procurador</TextL>
                  </div>
                  <div className="relationship-area-item">
                    <CustomRadioInput
                      isChecked={workRelation === 'OTHER'}
                      handleClick={() => setRelationType('OTHER')}
                    />
                    <TextL>Não especificado</TextL>
                  </div>
                </div>
              </div>
              <Divider />

              <div
                style={{
                  marginTop: '22px',
                  alignItems: 'center',
                  display: 'flex',
                  gap: '12px',
                }}
              >
                <TextL
                  style={{ display: 'block', width: '60%', marginTop: '10px' }}
                  weight="medium"
                >
                  Particiapação em quotas (%)
                </TextL>
                <CustomInput
                  placeholder="Ex: 50"
                  value={formik.values.share}
                  handleInputChange={(e) => {
                    formik.handleChange({
                      target: {
                        name: 'share',
                        value: handleShare(e.target.value),
                      },
                    });
                  }}
                  validationError={
                    formik.touched.share ? formik.errors.share : ''
                  }
                />
              </div>

              <div className="btn-remove">
                <TextL
                  weight="semibold"
                  style={{ color: theme.palette.brand.error[900] }}
                  onClick={handleOpenConfirmationRemoveModal}
                >
                  Remover representante
                </TextL>
              </div>
            </SelectedAddInfo>
          </Section>
          <ButtonArea>
            <ButtonDefault
              onClick={handleCloseModal}
              style={{ width: '160px' }}
            >
              Cancelar
            </ButtonDefault>
            <ButtonPrimary
              onClick={() => formik.submitForm()}
              style={{ width: '160px' }}
            >
              Salvar
            </ButtonPrimary>
          </ButtonArea>
        </ContentArea>
      </BaseSidesheet>
      <ConfirmationModal
        isOpen={isConfirmationRemoveModalOpen}
        handleClose={closeConfirmationRemoveModal}
        handleConfirm={handleRemovePerson}
      />
    </>
  );
};

export default PersonEditSidesheet;
