import React from 'react';
import { Box, ModalProps } from '@mui/material';
import { BaseModalContainer } from './styles';

export type IBaseModalProps = ModalProps;

const style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '48px',
  gap: '32px',
  background: '#FFFFFF',
  boxShadow: '0px 17px 26px rgba(15, 33, 43, 0.22)',
  borderRadius: '10px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '534px',
  transform: 'translate(-50%, -50%)',
};

const BaseModal = ({ children, ...props }: IBaseModalProps) => {
  return (
    <BaseModalContainer {...props}>
      <Box sx={style}>{children}</Box>
    </BaseModalContainer>
  );
};

export default BaseModal;
