import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { useState } from 'react';
import {
  EViewBoxColumnType,
  EditWrapper,
  Heading3,
  ViewBox,
} from '../../../../../../app/components';
import { TRegisterPersonDataRequest } from '../../../../context';
import NaturalPersonEditSpouseSection from './NaturalPersonEditSpouseSection';
import { NaturalPersonViewSpouseSectionContainer } from './styles';
import { useTheme } from '@mui/material';
import { MaritialPropertyObject } from 'modules/customer/context/PersonProvider/person.constants';

type INaturalPersonViewSpouseSectionProps = {
  personData: TRegisterPersonDataRequest;
};

const NaturalPersonViewSpouseSection = ({
  personData,
}: INaturalPersonViewSpouseSectionProps) => {
  const theme = useTheme();
  const [isEditOpen, setIsEditOpen] = useState(false);

  return (
    <>
      <NaturalPersonViewSpouseSectionContainer>
        <Heading3
          id="spouse"
          style={{ color: theme.palette.brand.secondary.base as string }}
        >
          Cônjuge
        </Heading3>
        <EditWrapper
          handleClick={() => setIsEditOpen(true)}
          permission={EAccountPermissions.UPDATE_PERSON_BUSINESS}
        >
          <ViewBox
            values={
              {
                ...personData,
              } as unknown as {
                [key: string]: string | number;
              }
            }
            textAlign="right"
            columns={[
              {
                label: 'Nome cônjuge',
                path: 'spouse_full_name',
              },
              {
                label: 'CPF cônjuge',
                path: 'spouse_taxpayer_id',
                type: EViewBoxColumnType.CPF,
              },
              {
                label: 'Data de nascimento cônjuge',
                path: 'spouse_date_of_birth',
                type: EViewBoxColumnType.DATE,
              },
              {
                label: 'Regime de bens',
                path: 'marital_property_system',
                type: EViewBoxColumnType.ENUM,
                enum: MaritialPropertyObject,
              },
            ]}
          />
        </EditWrapper>
      </NaturalPersonViewSpouseSectionContainer>
      <NaturalPersonEditSpouseSection
        isOpen={isEditOpen}
        handleClose={() => setIsEditOpen(false)}
        personData={personData}
      />
    </>
  );
};

export default NaturalPersonViewSpouseSection;
