import { ProductSideSheet } from 'modules/products/components/ProductSideSheet';
import { PersonContent, RegisterContent } from './components';
import { TProducts } from 'modules/products/context';
import {} from 'modules/products/services/hooks/useApplicationsService';
import { TCustomSelectOption } from 'app/components/CustomTableSelect/CustomTableSelect';
import {
  TQualification,
  TQualificationWithStatusAndProduct,
} from 'modules/customer/interfaces/qualifications';
import { EQualificationRole } from 'modules/products/context/QualificationsProvider/qualifications.interfaces';

export interface ILegalPersonViewQualificationsSideSheetProps {
  handleClose: () => void;
  handleEditCondition: (
    qualification: TQualificationWithStatusAndProduct | undefined,
  ) => void;
  isOpen: boolean;
  qualification?: TQualificationWithStatusAndProduct | undefined;
  productInfo?: TProducts | undefined;
  statusList: TCustomSelectOption[];
  isStatusOptionsLoading: string | boolean;
  borrowerQualificationData?: TQualification;
  handleUpdateQualificationStatus: (
    qualification: TQualificationWithStatusAndProduct,
    identifier: string,
  ) => void;
}

const LegalPersonViewQualificationsSideSheet = ({
  handleClose,
  handleUpdateQualificationStatus,
  handleEditCondition,
  isOpen,
  qualification,
  productInfo,
  statusList,
  borrowerQualificationData,
  isStatusOptionsLoading,
}: ILegalPersonViewQualificationsSideSheetProps) => {
  const onClose = () => {
    handleClose();
  };

  return (
    <ProductSideSheet
      open={isOpen}
      handleClose={onClose}
      title={
        (qualification?.role === EQualificationRole.EMPLOYER
          ? qualification?.employer?.legal_name
          : qualification?.borrower?.name) || ''
      }
    >
      <PersonContent
        borrowerQualificationData={borrowerQualificationData}
        selectedQualification={qualification}
        product={productInfo}
        statusOptions={statusList}
        handleUpdateQualificationStatus={handleUpdateQualificationStatus}
        isStatusOptionsLoading={isStatusOptionsLoading}
        handleEditCondition={handleEditCondition}
      />
      <RegisterContent product={productInfo} />
    </ProductSideSheet>
  );
};

export default LegalPersonViewQualificationsSideSheet;
