import React, { ReactNode } from 'react';
import ChevronDownIcon from './ChevronDownIcon';
import { AccordionContainer } from './styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { useTheme } from '@mui/material';

export interface IAccordionProps {
  isOpen: boolean;
  title: ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
  cleanStyle?: boolean;
}

const Accordion = ({
  title,
  isOpen,
  onClick,
  cleanStyle,
  style,
  children,
}: IAccordionProps) => {
  const theme = useTheme();
  return (
    <AccordionContainer isOpen={true} cleanStyle={cleanStyle} style={style}>
      <MuiAccordion
        sx={{
          border: 'none',
          borderRadius: '0px',
          boxShadow: 'none',
          '&.MuiPaper-root': {
            width: '97%',
            margin: '0 auto',
          },
        }}
        expanded={isOpen}
        onChange={onClick}
      >
        <MuiAccordionSummary
          expandIcon={
            <ChevronDownIcon
              color={theme.palette.brand.primary.base as string}
            />
          }
          sx={{
            minHeight: 'unset !important',
            borderRadius: '0px !important',
          }}
        >
          {title}
        </MuiAccordionSummary>
        <MuiAccordionDetails>{children}</MuiAccordionDetails>
      </MuiAccordion>
    </AccordionContainer>
  );
};

export default Accordion;
