import styled from '@emotion/styled';

interface ITableAvatarContainer {
  avatarUrl?: string;
}

export const TableAvatarContainer = styled.div<ITableAvatarContainer>`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  background-color: #00AEEF;
  background-image: url(${({ avatarUrl }) => avatarUrl && avatarUrl});
  background-size: cover;
  align-self: center;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items:center;

  p {
    color: ${({ theme }) => theme.palette?.brand.neutral.base};
    font-weight: 700;
  }
`;
