import styled from '@emotion/styled';

export const UsersAccessListContentWrapper = styled.div`
  padding: 48px 48px;
`;

export const UsersAccessListContentFilterArea = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      flex-direction: column;

  }
`;

export const UsersAccessListContentButtonArea = styled.div`
  min-width: 200px;
  display: flex;
  margin-left: 20px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      margin-top: 20px;
  }
`;

export const UsersAccessListContentPermissionDetails = styled.div`
  background-color: #424242;
  padding: 12px 16px 12px 32px;
  border-radius: 16px;
  color: white;
  position: absolute;
  top: 45px;
  left: 5px;
  z-index: 99991;

  &.review {
    width: 450px;
    left: -50% !important;

    &:before {
      left: 40%;
    }
  }

  &:before {
    content: " ";
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid  #424242;
    position: absolute;
    top: -10px;
    left: 10px;
  }
  &:after {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    content: " ";
    z-index: 9999;
    background-color: transparent;
  }

  ul {
    li {
      white-space: nowrap;
    }
  }
`;

export const UsersAccessListContentPermissionCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${({ theme }) => theme.palette?.brand.primary.base};
  font-size: 12px;
  line-height: 0px;
  border: 1px solid ${({ theme }) => theme.palette?.brand.primary.base};
  font-weight: 600;
  width: 26px;
  height: 26px;
  border-radius: 100px;
  cursor: pointer;
  z-index: 999999;
`;

export const UsersAccessListContentTableArea = styled.div`
  margin-top: 48px;
  position: relative;

  .MuiTableContainer-root {
    overflow-x: visible !important;
  }

  th:nth-of-type(3),
  th:nth-of-type(4) {
    min-width: 300px;
  }

  td:nth-of-type(3),
  td:nth-of-type(4) {
    min-width: 300px;
  }

  th:nth-of-type(6) {
    border-left: 1px solid #E6E5EA;
    max-width: 60px;
  }

  td:nth-of-type(6) {
    border-left: 1px solid #E6E5EA;
    max-width: 60px;
  }
`;
