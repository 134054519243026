import styled from '@emotion/styled';

export const ChargesSimulatorSwitch = styled.div`
  width: 100%;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #EDEDED;

  .wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;
