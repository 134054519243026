import styled from '@emotion/styled';

export const InstallmentsFilterAccordionTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const InstallmentsFilterStatusElement = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px;
  align-items: center;
`;

export const InstallmentsFilterInputs = styled.div`
  display: flex;
  width: 100%;
`;

export const InstallmentsFilterInput = styled.div`
  max-width: 49%;
  &:first-child {
    margin-right: 10px;
  }
`;

export const InstallmentsFilterInputInvalid = styled.div`
  margin-top: 6px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette?.brand.error[900]};
`;
