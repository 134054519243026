import styled from '@emotion/styled';

export const SimulationButtonContainer = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px -6px rgba(0, 0, 0, 0.08), 0px 24px 48px -4px rgba(0, 0, 0, 0.08);
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.08);
  cursor: pointer;
`;
