const ArrowIcon = ({ color }: { color: string }) => (
  <svg
    width={24}
    height={25}
    fill="none"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.345 12.5c0 .506-.41.917-.917.917H2.572a.917.917 0 1 1 0-1.833h18.856c.506 0 .917.41.917.916Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.295 3.367a.917.917 0 0 1 1.296 0l8.485 8.485a.917.917 0 0 1 0 1.296l-8.485 8.486a.917.917 0 1 1-1.296-1.297l7.837-7.837-7.837-7.837a.917.917 0 0 1 0-1.296Z"
      fill={color}
    />
  </svg>
)

export default ArrowIcon
