import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 40px;
  position: relative;

  .application-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;

  }
`;

export const ApplicationsContentFiltersCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${({ theme }) => theme.palette?.brand.primary.base};
  font-size: 12px;
  line-height: 0px;
  border: 1px solid ${({ theme }) => theme.palette?.brand.primary.base};
  font-weight: 600;
  width: 26px;
  height: 26px;
  border-radius: 100px;
  margin-left: 10px;
  cursor: pointer;
  z-index: 99;
`;

export const ButtonApplicationContainer = styled.div`
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
`;

export const BtnStatusFilterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filter-qty-container {
    display: flex;
    gap: 6px;

    .filter-qty {
      color: ${({ theme }) => theme.palette?.brand.primary.base}
    }
  }
`;

export const FilterArea = styled.div`
  display: flex;
  width: 100%;
  margin-right: 11px;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      flex-direction: column;

  }
`;

export const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 71px;

  .title {
    display: flex;

    h3 {
      margin-right: 16px;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 202px;
  height: 40px;
  border-radius: 10px;
`;

export const TableArea = styled.div`
  width: 100%;
  margin-top: 26px;
  margin-bottom: 48px;
`;

export const ArrowArrowContainer = styled.div`
  cursor: pointer;
`;
