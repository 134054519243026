import { useContext, useCallback } from 'react';
import { ButtonDefault, ButtonPrimary, StepContent } from 'app/components';
import { QualificationRequestStepperContext } from '../QualificationRequest';
import { ArrowBack } from '@mui/icons-material';
import {
  StepContentButtonsArea,
  StepContentInput,
  StepContentInputGroup,
} from './styles';
import { CustomCurrencyInput, CustomInput, TextM } from 'celcoin-design-system';
import { useFormik } from 'formik';
import {
  formatCurrency,
  formatToCurrencyFloat,
  normalizeAddDigits,
} from 'app/utils/normalizer';
import { formatDecimalField } from 'app/helpers';
import { editConditionValidationRequired } from 'modules/customer/helpers/conditionStepValidation';
import { editConditionValidation } from 'modules/products/helpers';
import { TReactChangeInput } from 'app/components/CreditSimulator/CreditSimulatorModal/CreditSimulatorContent';
import { formatBRLCurrencyMaskInput } from 'app/utils/currency';
import { getNumbersFromValue } from 'app/utils/numbers';
import { useTheme } from '@mui/material';
import { formatInterestRate } from 'modules/products/helpers/interestRate';

const ConditionsStep = () => {
  const {
    onBack,
    onChangeConditionData,
    onStartCreateQualififcation,
    conditionData,
    productData,
  } = useContext(QualificationRequestStepperContext);

  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      creditLimit: conditionData?.line_of_credit?.credit_limit
        ? formatCurrency(
            conditionData?.line_of_credit?.credit_limit || 0,
            false,
          ) || ''
        : '',
      maxPaymentAmount: conditionData?.line_of_credit?.max_payment_amount
        ? formatCurrency(
            conditionData?.line_of_credit?.max_payment_amount || 0,
            false,
          ) || ''
        : '',
      minRequestedAmount: conditionData?.min_requested_amount
        ? formatCurrency(conditionData?.min_requested_amount || 0, false) || ''
        : '',
      tac: conditionData?.tac
        ? formatCurrency(conditionData?.tac || 0, false) || ''
        : '',
      minGracePeriod: conditionData?.min_grace_period || '',
      maxGracePeriod: conditionData?.max_grace_period || '',
      dueDay: conditionData?.due_day || '',
      minPaymentNumber: conditionData?.min_payment_number || '',
      maxPaymentNumber: conditionData?.max_payment_number || '',
      interestRate: conditionData?.schedule_based_conditions?.[0]?.interest_rate
        ? normalizeAddDigits(
            conditionData?.schedule_based_conditions?.[0]?.interest_rate * 100,
            2,
          )
        : '',
    },
    validationSchema: productData?.conditions_required
      ? editConditionValidationRequired
      : editConditionValidation,
    enableReinitialize: false,
    onSubmit: async (values) => {
      onChangeConditionData({
        due_day: Number(values.dueDay) || undefined,
        line_of_credit: {
          credit_limit:
            Number(formatToCurrencyFloat(values.creditLimit || '')) ||
            undefined,
          max_payment_amount:
            Number(formatToCurrencyFloat(values.maxPaymentAmount || '')) ||
            undefined,
        },
        min_requested_amount:
          Number(formatToCurrencyFloat(values.minRequestedAmount || '')) ||
          undefined,
        tac: Number(formatToCurrencyFloat(values.tac || '')) || undefined,
        min_grace_period: Number(values.minGracePeriod) || undefined,
        max_grace_period: Number(values.maxGracePeriod) || undefined,
        min_payment_number: Number(values.minPaymentNumber) || undefined,
        max_payment_number: Number(values.maxPaymentNumber) || undefined,
        schedule_based_conditions: [
          {
            interest_rate: formatInterestRate(values.interestRate),
            upper_limit: Number(values.maxPaymentNumber) || undefined,
          },
        ],
      });

      onStartCreateQualififcation(false);
    },
  });

  const handleSubmit = () => {
    const hasAnyFieldFilled = Object.keys(formik.values).filter((field) => {
      return !!formik.values[field as keyof typeof formik.values];
    });

    if (!productData?.conditions_required && hasAnyFieldFilled.length === 0) {
      onStartCreateQualififcation(true);
      return;
    }
    formik.submitForm();
  };

  const handleAmountChange = useCallback((evt: TReactChangeInput) => {
    if (evt.target.value.length === 0) {
      formik.setFieldValue(evt.target.name, '');
      return;
    }
    formik.setFieldValue(
      evt.target.name,
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  const handleIntChange = useCallback((evt: TReactChangeInput) => {
    if (evt.target.value.length === 0) {
      formik.setFieldValue(evt.target.name, '');
      return;
    }
    formik.setFieldValue(
      evt.target.name,
      getNumbersFromValue(evt.target.value || ''),
    );
  }, []);

  const handleInterestRate = useCallback((evt: TReactChangeInput) => {
    if (evt.target.value.length === 0) {
      formik.setFieldValue('interestRate', '');
      return;
    }
    formik.setFieldValue('interestRate', formatDecimalField(evt.target.value));
  }, []);

  return (
    <StepContent
      title="Condições"
      description="Defina as condições para a linha de crédito"
    >
      {productData?.conditions_required && (
        <TextM
          style={{
            color: theme.palette.brand.error[900],
            marginBottom: '10px',
          }}
        >
          * Esse produto requer o cadastro de condições
        </TextM>
      )}
      <StepContentInputGroup>
        <StepContentInput>
          <CustomCurrencyInput
            name="creditLimit"
            value={formik.values.creditLimit}
            handleInputChange={handleAmountChange}
            labelValue="Limite Total"
            validationError={
              formik.touched.creditLimit ? formik.errors.creditLimit : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomCurrencyInput
            name="maxPaymentAmount"
            value={formik.values.maxPaymentAmount}
            handleInputChange={handleAmountChange}
            labelValue="Limite de parcela"
            validationError={
              formik.touched.maxPaymentAmount
                ? formik.errors.maxPaymentAmount
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomCurrencyInput
            name="minRequestedAmount"
            value={formik.values.minRequestedAmount}
            handleInputChange={handleAmountChange}
            labelValue="Mínimo solicitação"
            validationError={
              formik.touched.minRequestedAmount
                ? formik.errors.minRequestedAmount
                : ''
            }
          />
        </StepContentInput>
        {/* <StepContentInput>
          <CustomCurrencyInput
            name="tac"
            value={formik.values.tac}
            handleInputChange={handleAmountChange}
            labelValue="Tac"
            validationError={formik.touched.tac ? formik.errors.tac : ''}
          />
        </StepContentInput> */}
        <StepContentInput>
          <CustomInput
            name="maxGracePeriod"
            value={String(formik.values.maxGracePeriod)}
            handleInputChange={handleIntChange}
            labelValue="Carência máxima"
            validationError={
              formik.touched.maxGracePeriod ? formik.errors.maxGracePeriod : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="minGracePeriod"
            value={String(formik.values.minGracePeriod)}
            handleInputChange={handleIntChange}
            labelValue="Carência mínima"
            validationError={
              formik.touched.minGracePeriod ? formik.errors.minGracePeriod : ''
            }
          />
        </StepContentInput>
        {/*<StepContentInput>*/}
        {/*  <CustomInput*/}
        {/*    name="dueDay"*/}
        {/*    value={String(formik.values.dueDay)}*/}
        {/*    handleInputChange={handleIntChange}*/}
        {/*    labelValue="Dia de vencimento"*/}
        {/*    validationError={formik.touched.dueDay ? formik.errors.dueDay : ''}*/}
        {/*  />*/}
        {/*</StepContentInput>*/}
        <StepContentInput>
          <CustomInput
            name="minPaymentNumber"
            value={String(formik.values.minPaymentNumber)}
            handleInputChange={handleIntChange}
            labelValue="Prazo mínimo"
            validationError={
              formik.touched.minPaymentNumber
                ? formik.errors.minPaymentNumber
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="maxPaymentNumber"
            value={String(formik.values.maxPaymentNumber)}
            handleInputChange={handleIntChange}
            labelValue="Prazo máximo"
            validationError={
              formik.touched.maxPaymentNumber
                ? formik.errors.maxPaymentNumber
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="interestRate"
            value={formik.values.interestRate}
            handleInputChange={handleInterestRate}
            labelValue="Taxa de juros (% a.m.)"
            validationError={
              formik.touched.interestRate ? formik.errors.interestRate : ''
            }
          />
        </StepContentInput>
      </StepContentInputGroup>
      <StepContentButtonsArea>
        <ButtonDefault margin={true} onClick={() => onBack()}>
          <ArrowBack />
        </ButtonDefault>
        <ButtonPrimary onClick={handleSubmit}>Concluir</ButtonPrimary>
      </StepContentButtonsArea>
    </StepContent>
  );
};

export default ConditionsStep;
