import styled from '@emotion/styled';

export const StepContentInput = styled.div<{
  marginTop?: string;
  isFull?: boolean;
  isHalf?: boolean;
}>`
  width: ${(props) => (props.isFull ? '100%' : !props.isHalf ? '50%' : '25%')};
  margin-top: ${(props) => props.marginTop || '0px'};
  padding: 15px;

  
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      width:  ${(props) => (!props.isHalf ? '100%' : '50%')}; 
  };

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}){
      width: 100%;
  };
`;

export const StepContentInputArea = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

export const StepAddressContentInputArea = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
`;

export const StepContentInputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StepContentButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    width: auto;
  }
`;

export const TableFilesWrapper = styled.div`
  width: 100%;

  thead {
    th {
      padding: 20px !important;
    }
  }

  tbody {

    tr {
      td {
        padding: 24px !important;
        width: 50%;
        &:first-of-type {
          width: 40% !important;
        }
      }

    }

    tr:nth-of-type(odd) {
      background-color: #fff !important;
    }
  }
  
`;

export const AboutStepExistingPerson = styled.div`
  text-align: left;
  border: 1px solid #E6E5EA;
  padding: 16px;
  border-radius: 10px;
  span {
    display: block;
  }

  .link {
    display: block;

    font-size: 16px;
    text-decoration: none;
    color: ${({ theme }) => theme.palette?.brand?.primary.base};
    font-weight: bold;
    margin-top: 8px;
  }
`;

export const TableHeadFile = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 6px;
    cursor: pointer;
  }
`;

export const TableBodyFile = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 18px;
  }
`;

export const UploadArea = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export const EmployerStepContent = styled.div``;

export const ListBoxSection = styled.div`
    padding: 24px;
    background-color: #FDFDFD;
    border: 1px solid #E6E5EA;
    border-radius: 10px;
    max-height: 50vh;
    overflow: auto;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 23px;
`;

export const ListBoxItem = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${({ theme }) => theme.palette?.brand.secondary.base};
    border: 1px solid #E6E5EA;
    background-color: #FDFDFD;
    border-radius: 10px;
    padding: 12px 24px;
    margin-bottom: 16px;
    gap: 21px;
    cursor: pointer;
    &.selected {
        background-color: ${({ theme }) =>
          theme.palette?.brand.background.base};
        border: 2px solid ${({ theme }) => theme.palette?.brand.primary.base};
        h4 {
            color: ${({ theme }) => theme.palette?.brand.primary.base};
        }
    }

    &::-webkit-scrollbar {
     width: 10px;
     margin-right: 30px;
    }
`;

export const InfoArea = styled.div`
  display: flex;
  margin-top: 6px;
`;

export const VariablesArea = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  border: 1px solid #E6E5EA;
  border-radius: 10px;
  padding: 24px;
  overflow: auto;
  height: 500px;
`;

export const InputItem = styled.div`
  width: 50%;
  margin-top: 10px;
`;

export const ConditionsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
