import React from 'react';
import { useTheme } from '@mui/material';
import { RegularText } from '../../../../../../app/components';
import { ClipboardIco } from '../../../../assets';
import { ClipboardButton, ClipboardContainer } from './styles';

export interface IClipboardProps {
  code: string;
}

const Clipboard = ({ code }: IClipboardProps) => {
  const theme = useTheme();
  return (
    <ClipboardContainer>
      <RegularText>{code}</RegularText>
      <ClipboardButton>
        <ClipboardIco color={theme.palette.brand.primary.base as string} />
      </ClipboardButton>
    </ClipboardContainer>
  );
};

export default Clipboard;
