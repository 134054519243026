import { BaseHttpService } from '../../../../app/services/baseHttpService';

export class PersonFileHttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  uploadFilesToPerson = async (data: FormData, personId: string) => {
    const res = await this.post(
      `${this.BASE_URL}/originator/persons/${personId}/documents`,
      data,
    );
    return res;
  };

  getFilesByPerson = async (personId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/persons/${personId}/documents`,
    );
    return res;
  };

  deleteFilesByPerson = async (personId: string, fileId: string) => {
    const res = await this.delete(
      `${this.BASE_URL}/originator/persons/${personId}/documents/${fileId}`,
    );
    return res;
  };

  downloadPersonFile = async (personId: string, fileId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/persons/${personId}/documents/${fileId}/file`,
    );
    return res;
  };
}
