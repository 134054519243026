const InstallmentsIcon = ({ color }: { color: string }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.6667 4.00065H28.0001C28.3537 4.00065 28.6928 4.14113 28.9429 4.39118C29.1929 4.64122 29.3334 4.98036 29.3334 5.33398V26.6673C29.3334 27.0209 29.1929 27.3601 28.9429 27.6101C28.6928 27.8602 28.3537 28.0006 28.0001 28.0006H4.00008C3.64646 28.0006 3.30732 27.8602 3.05727 27.6101C2.80722 27.3601 2.66675 27.0209 2.66675 26.6673V5.33398C2.66675 4.98036 2.80722 4.64122 3.05727 4.39118C3.30732 4.14113 3.64646 4.00065 4.00008 4.00065H9.33342V1.33398H12.0001V4.00065H20.0001V1.33398H22.6667V4.00065ZM20.0001 6.66732H12.0001V9.33398H9.33342V6.66732H5.33341V12.0007H26.6667V6.66732H22.6667V9.33398H20.0001V6.66732ZM26.6667 14.6673H5.33341V25.334H26.6667V14.6673Z"
      fill={color}
    />
  </svg>
);

export default InstallmentsIcon;
