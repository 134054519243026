import { routingPath } from 'app/routes';
import { useAuthContext } from 'modules/auth/context';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContainer } from '../../components';
import { NewPasswordForm } from './components';

const NewPassword = () => {
  const { temporaryPassword } = useAuthContext();

  if (!temporaryPassword) {
    return <Navigate to={`/${routingPath.auth.login}`} />;
  }

  return (
    <AuthContainer>
      <NewPasswordForm />
    </AuthContainer>
  );
};

export default NewPassword;
