import styled from '@emotion/styled';

export const CessionListTableWrapper = styled.div`
  padding: 24px 48px;
`;

export const CessionListTableFilterArea = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      flex-direction: column;
  }

  .search-filter {
    max-width: 550px;
    width: 100%;
  }
`;

export const CessionListTableButtonArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: flex-start;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      margin-top: 20px;
  }
`;

export const CessionListTableArea = styled.div`
  margin-top: 32px;
  position: relative;

  .MuiTableContainer-root {
    overflow-x: visible !important;
  }

  th:nth-of-type(1) {
    max-width: 165px;
  }

  td:nth-of-type(1) {
    max-width: 165px;
    text-transform: uppercase;
  }

   th:last-of-type {
    max-width: 50px;
  }

  td:last-of-type {
    max-width: 50px;
  }
`;

export const CessionListTableFiltersCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${({ theme }) => theme.palette?.brand.primary.base};
  font-size: 12px;
  line-height: 0px;
  border: 1px solid ${({ theme }) => theme.palette?.brand.primary.base};
  font-weight: 600;
  width: 26px;
  height: 26px;
  border-radius: 100px;
  margin-left: 10px;
  cursor: pointer;
  z-index: 99;
`;

export const ButtonFilterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filter-qty-container {
    display: flex;
    gap: 8px;
    width: fit-content;
    .filter-qty {
      color: ${({ theme }) => theme.palette?.brand.primary.base}
      font-size: 16px !important;
    }
  }
`;

export const SearchInputContainer = styled.div`
  max-width: 550px;
  width: 100%;
  box-shadow: 0px 4px 10px 0px rgba(15, 33, 43, 0.05);
  border-radius: 10px;
  padding: 9px 15px;
  height: 40px;
  margin-right: 12px;
  position: relative;

  & > svg {
    position: absolute;
    left: 16px;
    top: 8px;
  }

  input {
    padding-left: 35px;
    font-size: 16px;
    font-weight: 500;

    &::placeholder {
      font-size: 16px;
    }
  }
`;
export const CessionListActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-end;
  padding: 0 12px;

`;

export const CessionListActionButton = styled.div<{ isDisabled: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;

  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
`;
