import styled from '@emotion/styled';

export type ICustomSelectContainerProps = ICustomSelectDropdownItemProps;
export interface ICustomSelectDropdownItemProps {
  value?: string;
}

export const CustomSelectContainer = styled.div<ICustomSelectContainerProps>`
  width: 100%;
  position: relative;
  background-color: ${({ value }) => {
    switch (value) {
      case 'QUALIFIED':
      case 'ISSUED':
      case 'APPROVED':
        return '#B8F1C1';
      case 'DENIED':
      case 'CANCELED':
        return '#FCB9B9';
      default:
        return '#F7F7F7';
    }
  }};;
  border-radius: 5px;
  border: 1px solid;
  border-color: ${({ value }) => {
    switch (value) {
      case 'QUALIFIED':
      case 'APPROVED':
      case 'ISSUED':
        return '#B8F1C1';
      case 'DENIED':
      case 'CANCELED':
        return '#FCB9B9';
      default:
        return '#E6E5EA';
    }
  }};
`;

export const CustomSelectContent = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  
  svg {
    margin-left: 4px;
  }
`;

export const SpinDiv = styled.div`
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0px 5px 2px;
  svg {
    margin: 0px !important;
    font-size: 15px;
  }

  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }
`;

export const MenuWrapper = styled.div`
  position: relative;
`;

export const MenuOutside = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw;
  background: transparent;
  z-index: 98;
`;

export const MenuContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  top: 20px;
  z-index: 99;
  right: 0px;
  position: absolute;
  border-radius: 20px;
  filter: drop-shadow(0px 18px 25px rgba(0, 21, 33, 0.14));
  border: 1px solid #E6E5EA;
  background-color: ${({ theme }) => theme.palette?.brand.neutral.base};

  
  &::after {
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    top: -8px;
    left: 40px;
    transform: rotateZ(45deg) ;
    z-index: -1;
    background-color: ${({ theme }) => theme.palette?.brand.neutral.base};
    border-top: 1px solid #E6E5EA;
    border-left: 1px solid #E6E5EA;
    border-radius: 3px 0px 0px 0px;

  }
`;

export const CustomSelectDropdownItem = styled.div<ICustomSelectDropdownItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 168px;
  padding: 5px 0px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid;
  text-align: center;
  background-color: ${({ value }) => {
    switch (value) {
      case 'QUALIFIED':
      case 'ISSUED':
      case 'APPROVED':
        return '#B8F1C1';
      case 'DENIED':
      case 'CANCELED':
        return '#FCB9B9';
      default:
        return '#F7F7F7';
    }
  }};
  border-color: ${({ value }) => {
    switch (value) {
      case 'QUALIFIED':
      case 'ISSUED':
      case 'APPROVED':
        return '#B8F1C1';
      case 'DENIED':
      case 'CANCELED':
        return '#FCB9B9';
      default:
        return '#E6E5EA';
    }
  }};
  &:hover {
    background-color:${({ value }) => {
      switch (value) {
        case 'QUALIFIED':
        case 'ISSUED':
        case 'APPROVED':
          return '#B8F1C1';
        case 'DENIED':
        case 'CANCELED':
          return '#FCB9B9';
        default:
          return '#E6E5EA';
      }
    }}
  }
`;
