import { useAppContext } from 'app/context/AppContextProvider';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { BaseModal, Heading4, TextL } from 'celcoin-design-system';
import { useEffect, useState } from 'react';
import { CloseIcon, ErrorCircularIcon } from '../Icons';
import { apiErrorEventManager, IApiErrorModal } from './apiErrorEventManager';
import { ModalApiErrorContent } from './styles';
import { ButtonPrimary } from '../ButtonPrimary';
import { useTheme } from '@mui/material';
import { useAuthContext } from 'modules/auth/context';

const ModalApiError = () => {
  const { userInfo } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<IApiErrorModal>();
  const { showSnackbarCopy } = useSnackbar();
  const theme = useTheme();
  const app = useAppContext();

  const handleOpenApiErrorModal = (event: IApiErrorModal) => {
    const {
      errorMessage,
      response_body,
      endpoint,
      method,
      response_status,
      request_body,
    } = event;
    setModalContent((state) => {
      return {
        ...state,
        errorMessage,
        response_body,
        request_body,
        endpoint,
        method,
        response_status,
        app_id: app.app_id,
        dns: app.dns,
        fundings: userInfo?.fundings,
        issuer_id: userInfo?.issuer_id,
        originator_id: userInfo?.originator_id,
      };
    });
    setIsModalOpen((state) => !state);
  };

  const closeApiErrorModal = () => {
    setIsModalOpen(false);
    setModalContent(undefined);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `app_id: ${modalContent?.app_id || '-'}\nfundings: ${
        modalContent?.fundings || '-'
      }\noriginator_id: ${modalContent?.originator_id || '-'}\nissuer_id: ${
        modalContent?.issuer_id || '-'
      }\ndns: ${modalContent?.dns || '-'}\nendpoint: ${
        modalContent?.endpoint || '-'
      }\nmethod: ${modalContent?.method || '-'}\nrequest_body: ${
        modalContent?.request_body || '-'
      }\nresponse_status: ${
        modalContent?.response_status || '-'
      }\nresponse_body: ${modalContent?.response_body || '-'}`,
    );
    showSnackbarCopy();
  };

  useEffect(() => {
    apiErrorEventManager.on('openApiErrorModal', handleOpenApiErrorModal);

    return () => {
      apiErrorEventManager.removeListener(
        'openApiErrorModal',
        handleOpenApiErrorModal,
      );
    };
  }, []);

  if (!isModalOpen) {
    return null;
  }

  return (
    <BaseModal open={isModalOpen}>
      <ModalApiErrorContent>
        <div className="header-area">
          <TextL>Erro de sistema</TextL>
          <div className="close-btn" onClick={closeApiErrorModal}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </div>
        <div className="body-content">
          <ErrorCircularIcon />
          <Heading4 fontWeight="B" style={{ marginTop: '12px' }}>
            Ocorreu um erro na comunicação com o nosso sistema.
          </Heading4>
          <TextL style={{ marginTop: '14px' }}>
            <strong>Erro:</strong>{' '}
            {modalContent?.errorMessage || 'Não definido'}
          </TextL>
          <TextL style={{ marginTop: '8px' }}>
            Por gentileza, recarregue a página e tente novamente. Se o erro
            persistir, contate o atendimento Celcoin. Para agilizar seu
            atendimento, você pode copiar os registros de depuração.
          </TextL>
          <ButtonPrimary
            onClick={handleCopy}
            style={{ maxWidth: '300px', marginTop: '24px' }}
          >
            Copiar os registros de depuração
          </ButtonPrimary>
        </div>
      </ModalApiErrorContent>
    </BaseModal>
  );
};

export default ModalApiError;
