import * as yup from 'yup';

const defaultValues = {
  required: 'Campo obrigatório',
};

export const loginSchema = yup.object({
  email: yup
    .string()
    .trim()
    // .email('Insira um email válido')
    .required(defaultValues.required),
  password: yup
    .string()
    .trim()
    .min(8, 'A senha deve conter no mínimo 8 caracteres')
    .required(defaultValues.required),
});

export const newPasswordSchema = yup.object({
  email: yup
    .string()
    .trim()
    // .email('Insira um email válido')
    .required(defaultValues.required),
  password: yup
    .string()
    .trim()
    .min(8, 'A senha deve conter no mínimo 8 caracteres')
    .required(defaultValues.required),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'As senhas devem coincidir')
    .required(defaultValues.required),
});

export const passwordRecoverySchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Insira um email válido')
    .required(defaultValues.required),
});
