import styled from '@emotion/styled';
import { TViewBoxColumn } from './ViewBox';

export const ViewBoxContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
`;

export const ViewBoxContent = styled.div`
  display: flex;
  width: 100%;
  background-color: #FAFAFA;
  border-top: 1px solid ${({ theme }) => theme.palette?.brand?.gray[300]};
  &:first-of-type {
    border-top: unset;
  }
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      flex-direction: column;
  };
`;

export const ViewBoxItem = styled.div<{
  item: TViewBoxColumn;
  isLast: boolean;
  size: number;
  textAlign?: 'left' | 'right';
}>`
  display: flex;
  max-width: ${({ item, size }) =>
    item.full ? '100%' : `calc(100% / ${size})`};
  width: 100%;
  justify-content: space-between;
  background-color: white;
  border-right: ${({ item, size, theme }) =>
    item.full || size === 1
      ? 'none'
      : `1px solid ${theme.palette?.brand?.gray[300]}`};
  padding: 12px 24px;
  span {
    display: block;
    &:first-of-type {
      min-width: max-content;
    }
  }

  .viewbox-value {
    display: flex;
    width: 100%;
    padding-left: 10px;
    overflow: hidden; 
    max-width: ${({ item }) => (item.full ? '80%' : '70%')};
    text-align: ${({ item }) => (item.full ? 'left' : 'right')};
    text-overflow: ellipsis;
    line-break: anywhere;
    align-items: center;
    justify-content: ${({ textAlign }) =>
      textAlign === 'right' ? 'flex-end' : 'flex-start'};
  }

  &:first-of-type {
    border-right: ${({ theme, size }) =>
      size === 1
        ? 'unset'
        : `1px solid ${theme.palette?.brand?.gray[300]} !important`};
  }
  &:last-of-type {
    border-right: ${({ isLast, size, theme }) =>
      !isLast || size === 1
        ? 'unset'
        : `1px solid ${theme.palette?.brand?.gray[300]}`};
  }
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
    max-width: unset !important;
    flex-direction: column;
    width: 100%;
    span {
    &:first-of-type {
      min-width: unset !important;
    }
    &:nth-of-type(2) {
      max-width: unset !important;
      text-align: left !important;
    }
  }
};
`;
