import { Heading3, RegularText } from '../../../../../../app/components';
import { AuthFormContainer, BackToLoginBtn } from '../../../../components';
import { routingPath } from '../../../../../../app/routes';
import passwordImg from '../../../../assets/images/password-confirmation.png';
import { ImgArea } from './styles';
import { useCustomNavigation } from '../../../../../../app/hooks';

const PasswordRecoveryConfirmationContent = () => {
  const { navigate } = useCustomNavigation();

  return (
    <AuthFormContainer>
      <Heading3
        fontWeight="B"
        style={{
          marginTop: '32px',
        }}
      >
        Tudo pronto
      </Heading3>
      <RegularText
        style={{
          marginTop: '24px',
          textAlign: 'center',
          maxWidth: 303,
        }}
      >
        Verifique sua caixa de entrada para redefinir sua senha
      </RegularText>
      <ImgArea>
        <img src={passwordImg} />
      </ImgArea>
      <BackToLoginBtn onBtnClick={() => navigate(routingPath.auth.login)}>
        Voltar para o login
      </BackToLoginBtn>
    </AuthFormContainer>
  );
};

export default PasswordRecoveryConfirmationContent;
