import React from 'react';
import { RegularText } from '../../../../../../app/components';
import { AuthItemContainer, Bullet } from './styles';
import { useTheme } from '@mui/material';

const AuthItem = ({ title }: { title: string }) => {
  const theme = useTheme();
  return (
    <AuthItemContainer>
      <Bullet />
      <RegularText
        style={{
          color: theme.palette.brand.primary.base as string,
          marginLeft: '10px',
        }}
      >
        {title}
      </RegularText>
    </AuthItemContainer>
  );
};

export default AuthItem;
