import styled from '@emotion/styled';

export const RequiredArea = styled.div`
  border-left: 8px solid #FDD9B8;
  background: #FFFAEE;
  position: relative;
  box-shadow: 0px 1px 0px rgba(253, 217, 184, 0.48);
  border-radius: 10px;
  width: 100%;
  padding: 13.5px 44px;
  margin-top: 32px;
`;

export const RequiredAreaIcon = styled.span`
  position: absolute;
  top: 13.5px;
  left: 13.5px;
`;

export const RequiredDocumentsList = styled.ul`
  font-size: 12px;
  margin-top: 4px;
  padding-left: 24px;
`;

export const RequiredDocumentsListItem = styled.li`
  line-height: 14px;
`;
