import React, { HTMLAttributes } from 'react';
import { InputErrorWrapper } from './styles';

export interface IInputErrorProps extends HTMLAttributes<HTMLTextAreaElement> {
  children: React.ReactNode;
}

const InputError = ({ children }: IInputErrorProps) => {
  return <InputErrorWrapper>{children}</InputErrorWrapper>;
};

export default InputError;
