const AddressIcon = ({ color }: { color: string }) => (
  <svg
    width={24}
    height={26}
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0692 1.56719C11.1853 0.628643 12.8147 0.628643 13.9308 1.56719L22.9308 9.13538C23.6087 9.70539 24 10.5458 24 11.4315L24 24.0001C24 25.1047 23.1046 26.0001 22 26.0001H16.5C15.3954 26.0001 14.5 25.1047 14.5 24.0001L14.5 17.0001L9.5 17.0001L9.5 24.0001C9.5 25.1047 8.60457 26.0001 7.5 26.0001H2C0.895431 26.0001 0 25.1047 0 24.0001L0 11.4315C0 10.5458 0.391346 9.70539 1.0692 9.13538L10.0692 1.56719ZM12.6436 3.09792C12.2716 2.78507 11.7284 2.78507 11.3564 3.09792L2.3564 10.6661C2.13045 10.8561 2 11.1362 2 11.4315L2 24.0001H7.5L7.5 17.0001C7.5 15.8956 8.39543 15.0001 9.5 15.0001H14.5C15.6046 15.0001 16.5 15.8956 16.5 17.0001V24.0001H22L22 11.4315C22 11.1362 21.8696 10.8561 21.6436 10.6661L12.6436 3.09792Z"
      fill={color}
    />
  </svg>
);

export default AddressIcon;
