import styled from '@emotion/styled';

export const EditWrapperContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

`;

export const EditWrapperButton = styled.button`
  margin-top: 32px;
  margin-left: 15px;
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
  cursor: pointer;
	outline: inherit;
`;
