import styled from '@emotion/styled';

export const ConfirmModalContainer = styled.div<{ width?: string }>`
    max-width: ${({ width }) => width ?? '430px'};
    width: ${({ width }) => width ?? 'auto'};
`;

export const ConfirmModalContent = styled.div`
    text-align: center;
`;

export const ConfirmModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ConfirmModalFooter = styled.div<{
  isColumnButtons?: boolean;
  isLoading: boolean;
}>`
    margin-top: 32px;
    display: flex;
    justify-content: ${({ isLoading }) =>
      isLoading ? 'center' : 'space-between'};
    width: 100%;
    flex-direction: ${({ isColumnButtons }) =>
      isColumnButtons ? 'column-reverse' : 'row'};
`;

export const CloseButton = styled.button`
    margin-left: auto;
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
    cursor: pointer;
	outline: inherit;
`;

export const CancelButton = styled.button<{
  color: 'primary' | 'danger' | 'attention';
}>`
    background: none;
    border: none;
    border-radius: 10px;
    border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
    color: ${({ theme, color }) =>
      color === 'primary'
        ? theme.palette?.brand?.primary.base
        : color === 'attention'
        ? 'rgb(249, 120, 0)'
        : theme.palette?.brand?.error[900]};
	padding: 0;
    cursor: pointer;
	outline: none;
    min-width: 211px;
    min-height: 52px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 20px;
    transition: background-color .1s;

    &:hover {
        background-color: ${({ theme }) => theme.palette?.brand?.gray[100]};
    }
`;

export const SkipButton = styled.button<{
  color: 'primary' | 'danger' | 'attention';
}>`
    border: 1px solid ${({ theme, color }) =>
      color === 'primary'
        ? theme.palette?.brand?.primary.base
        : color === 'attention'
        ? 'rgb(249, 120, 0)'
        : theme.palette?.brand?.error[900]};
    background: none;
    border-radius: 10px;
    color: ${({ theme, color }) =>
      color === 'primary'
        ? theme.palette?.brand?.primary.base
        : color === 'attention'
        ? 'rgb(249, 120, 0)'
        : theme.palette?.brand?.error[900]};
    padding: 0;
    cursor: pointer;
    outline: none;
    min-width: 211px;
    min-height: 52px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 20px;
    transition: opacity .1s;
    &:hover {
        opacity: 0.7;
    }
`;

export const ConfirmButton = styled.button<{
  color: 'primary' | 'danger' | 'attention';
}>`
    background: ${({ theme, color }) =>
      color === 'primary'
        ? theme.palette?.brand?.primary.base
        : color === 'attention'
        ? 'rgb(249, 120, 0)'
        : theme.palette?.brand?.error[900]};
    border: none;
    border-radius: 10px;
    color: white;
	padding: 0;
    cursor: pointer;
	outline: none;
    min-width: 211px;
    min-height: 52px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 20px;
    transition: opacity .1s;
    &:hover {
        opacity: 0.7;
    }
`;
