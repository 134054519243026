const CustomSelectArrowIcon = ({ color }: { color: string }) => (
  <svg
    width={10}
    height={7}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m5 4.281 3.3-3.3.943.943L5 6.167.758 1.924 1.7.981l3.3 3.3Z"
      fill={color}
    />
  </svg>
)

export default CustomSelectArrowIcon
