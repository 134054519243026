import { Box, useTheme } from '@mui/material';
import {
  ContractIcon,
  CopyIcon,
  HyperlinkIcon,
  InstallmentsIcon,
} from 'app/components/Icons';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  ContractsDetailsHeader,
  ContractsDetailsSection,
  ContractsDetailsHeaderButtons,
  Container,
  TabsArea,
} from './styles';
import { Heading3, Heading4, Spinner, TextM } from 'celcoin-design-system';
import { DetailsTab } from './components/DetailsTab';
import { ButtonPrimary } from 'app/components';
import useContract from 'modules/charges/hooks/useContract';
import { Contract } from 'modules/charges/interfaces/contracts';
import { useAuthContext } from 'modules/auth/context';
import { TProducts } from 'modules/products/context';
import { useProductsService } from 'modules/products/services/hooks';
import { InstallmentsTab } from './components/InstallmentsTab';
import { CustomTabs } from 'app/components/CustomTabs';

interface IContractsDetails {
  contractId: string;
  route: string;
}

const ContractsDetails = ({ contractId, route }: IContractsDetails) => {
  const theme = useTheme();
  const { showSnackbarCopy, showSnackbarCopyLink } = useSnackbar();
  const [currentTabValue, setCurrentTabValue] = useState(0);
  const { getContracts, getContractsLoading } = useContract();
  const { getProduct, getProductLoading } = useProductsService();
  const { userInfo } = useAuthContext();
  const [contract, updateContract] = useState<Contract>();
  const [product, updateProduct] = useState<TProducts>();
  const navigate = useNavigate();
  const locationRouter = useLocation();
  const params = useParams();

  const tabItems = [
    {
      title: 'Detalhes',
      icon: <ContractIcon color={theme.palette.brand.primary.base as string} />,
      route: '/details',
    },
    {
      title: 'Parcelas',
      icon: (
        <InstallmentsIcon color={theme.palette.brand.primary.base as string} />
      ),
      route: '/installments',
    },
  ];

  useEffect(() => {
    if (!params.tabRoute) {
      navigate(`${locationRouter.pathname}/details`);
    }
    getContracts(userInfo?.fundings[0] as string, {
      contract_id: contractId,
    }).then((contractResponse) => {
      if (contractResponse && contractResponse.content) {
        const contractObject = contractResponse.content[0] as Contract;
        updateContract(contractObject);
        getProduct(contractObject.group_id).then((productResponse) => {
          updateProduct(productResponse?.data);
        });
      }
    });
  }, [contractId]);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(location.href);
    showSnackbarCopyLink();
  }, [location]);

  const handleCopyId = useCallback(() => {
    navigator.clipboard.writeText(`${contractId}`);
    showSnackbarCopy();
  }, [contractId]);

  const contractsDetailsContentValue = (currentRoute: string) => {
    const tabItemsContent: { [key: string]: JSX.Element } = {
      ['details']: <DetailsTab contract={contract as Contract} />,
      ['installments']: (
        <InstallmentsTab
          contract={contract as Contract}
          product={product as TProducts}
        />
      ),
    };
    return tabItemsContent[currentRoute];
  };

  return (
    <Container>
      <ContractsDetailsSection>
        <ContractsDetailsHeader>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Heading3 fontWeight="Sb">{`ID contrato: ${contractId}`}</Heading3>
              <Heading4 fontWeight="Md" style={{ marginTop: '5px' }}>
                {product?.name}
              </Heading4>
            </div>
            <ContractsDetailsHeaderButtons>
              <ButtonPrimary
                style={{ backgroundColor: 'transparent' }}
                typeVariant="outline"
                btnIcon={
                  <CopyIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                }
                iconPosition="left"
                onClick={handleCopyId}
              >
                <TextM
                  weight="Semibold"
                  style={{
                    marginLeft: '11px',
                    color: theme.palette.brand.primary.base,
                  }}
                >
                  Copiar ID
                </TextM>
              </ButtonPrimary>
              <ButtonPrimary
                style={{ backgroundColor: 'transparent' }}
                typeVariant="outline"
                btnIcon={
                  <HyperlinkIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                }
                iconPosition="left"
                onClick={handleCopyLink}
              >
                <TextM
                  weight="Semibold"
                  style={{
                    marginLeft: '11px',
                    color: theme.palette.brand.primary.base,
                  }}
                >
                  Copiar Link
                </TextM>
              </ButtonPrimary>
            </ContractsDetailsHeaderButtons>
          </div>
          <TabsArea>
            <CustomTabs
              value={currentTabValue}
              route={route}
              items={tabItems}
              handleChange={(value) => setCurrentTabValue(value)}
              showBorder={false}
            />
          </TabsArea>
        </ContractsDetailsHeader>
        <Box>
          {contract &&
            product &&
            contractsDetailsContentValue(params.tabRoute || 'details')}
          {(getProductLoading || getContractsLoading) && <Spinner />}
        </Box>
      </ContractsDetailsSection>
    </Container>
  );
};

export default ContractsDetails;
