import { BaseHttpService } from './baseHttpService';

export enum EVariableRelation {
  BUSINESS = 'BUSINESS',
  PERSON = 'PERSON',
  APPLICATION = 'APPLICATION',
}

export enum EVariableType {
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
}

export type TVariable = {
  id: string;
  description: string;
  name: string;
  display_name: string;
  relation: EVariableRelation;
  type: EVariableType;
  created_at?: string;
};

export const VariableTypeNames = {
  [EVariableType.BOOLEAN]: 'Booleano',
  [EVariableType.DATE]: 'Data',
  [EVariableType.NUMBER]: 'Número',
  [EVariableType.TEXT]: 'Texto',
};
export class VariablesHttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;
  getVariables = async (): Promise<TVariable[] | null> => {
    const res = await this.get(`${this.BASE_URL}/issuer/variables`);
    return res.data as TVariable[];
  };

  getPersonVariables = async (): Promise<TVariable[] | undefined> => {
    const variables = await this.getVariables();
    return variables?.filter(
      (variable) => variable.relation === EVariableRelation.PERSON,
    );
  };

  getBusinessVariables = async (): Promise<TVariable[] | undefined> => {
    const variables = await this.getVariables();
    return variables?.filter(
      (variable) => variable.relation === EVariableRelation.BUSINESS,
    );
  };

  getApplicationVariables = async (): Promise<TVariable[] | undefined> => {
    const variables = await this.getVariables();
    return variables?.filter(
      (variable) => variable.relation === EVariableRelation.APPLICATION,
    );
  };
}
