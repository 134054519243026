import { useTheme } from '@mui/material';
import { TCustomSelectOption } from 'app/components/CustomTableSelect/CustomTableSelect';
import { useEffect, useState } from 'react';
import { CustomCircularIndicator } from '../../../../../../app/components';
import { TRegisterBusinessDataRequest } from '../../../../../customer/context';
import { useCustomerService } from '../../../../../customer/services';
import { ProductSideSheet } from '../../../../components/ProductSideSheet';
import { TQualification } from '../../../../context';
import BusinessDetailsSideSheetAbout from './components/BusinessDetailsSideSheetAbout';
import BusinessDetailsSideSheetBusiness from './components/BusinessDetailsSideSheetBusiness';

export interface IBusinessDetails {
  handleClose: () => void;
  handleUpdateQualificationStatus: (
    borrowerId: string,
    qualificationId: string,
    identifier: string,
  ) => void;
  statusList: TCustomSelectOption[];
  isStatusLoading: boolean;
  isOpen: boolean;
  qualification: TQualification;
}

const BusinessDetails = ({
  handleClose,
  handleUpdateQualificationStatus,
  isOpen,
  qualification,
  statusList,
  isStatusLoading,
}: IBusinessDetails) => {
  const theme = useTheme();
  const { getBusiness, creatBusinessLoading } = useCustomerService();
  const [business, updateBusiness] =
    useState<TRegisterBusinessDataRequest | null>(null);

  useEffect(() => {
    if (isOpen) {
      const {
        employer: { id },
      } = qualification;
      getBusiness(id).then((b) => updateBusiness(b));
    }
  }, [isOpen]);

  const onClose = () => {
    handleClose();
  };

  return (
    <ProductSideSheet
      open={isOpen}
      handleClose={onClose}
      title={business ? business.legal_name : '-'}
    >
      {qualification && (
        <BusinessDetailsSideSheetBusiness
          isStatusLoading={isStatusLoading}
          statusList={statusList}
          handleUpdateQualificationStatus={handleUpdateQualificationStatus}
          qualification={qualification}
        />
      )}
      {business && <BusinessDetailsSideSheetAbout business={business} />}
      {creatBusinessLoading && (
        <CustomCircularIndicator
          sx={{ color: theme.palette.brand.primary.base }}
        />
      )}
    </ProductSideSheet>
  );
};

export default BusinessDetails;
