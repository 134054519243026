import { DeleteIcon } from '../Icons';
import { DeleteButtonContainer } from './styles';

export interface IDeleteButtonButton {
  handleClick: () => void;
}

const DeleteButton = ({ handleClick }: IDeleteButtonButton) => {
  return (
    <DeleteButtonContainer onClick={handleClick}>
      <DeleteIcon />
    </DeleteButtonContainer>
  );
};

export default DeleteButton;
