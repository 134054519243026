import { useEffect, useState } from 'react';
import { useBreadcrumbsContext } from '../../../../app/context';
import { ApplicationDetailsContent } from './components/ApplicationDetailsContent';
import { ApplicationDetailsContainer } from './styles';
import { useParams } from 'react-router-dom';
import { useApplicationsService } from 'modules/products/services/hooks';
import { TApplicationResponse } from 'modules/products/services/hooks/useApplicationsService';
import { HandCoinIcon } from 'app/components/Icons';

const ApplicationDetails = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const { getApplication } = useApplicationsService();
  const [applicationData, updateApplicationData] =
    useState<TApplicationResponse | null>(null);
  const params = useParams();

  useEffect(() => {
    const applicationId = params.id;
    if (applicationId) {
      getApplication(applicationId).then((application) => {
        updateApplicationData(application);
      });
    }
  }, [params]);

  useEffect(() => {
    setItems(
      [
        {
          to: `/products/detail/${applicationData?.product.id}/applications?page=0`,
          label: 'Solicitações',
        },
        {
          to: `/products/application/${applicationData?.id}/details`,
          label: 'Detalhes',
        },
      ],
      <HandCoinIcon color="#424242" />,
    );

    return () => resetBreadcrumbs();
  }, [applicationData]);

  return (
    <ApplicationDetailsContainer>
      {applicationData && (
        <ApplicationDetailsContent
          applicationData={applicationData}
          updateApplicationData={updateApplicationData}
        />
      )}
    </ApplicationDetailsContainer>
  );
};

export default ApplicationDetails;
