const AdditionalFields = ({ color }: { color: string }) => (
  <svg
    width="29"
    height="26"
    viewBox="0 0 29 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.666992 4.00016C0.666992 1.97512 2.30861 0.333496 4.33366 0.333496H23.667C25.692 0.333496 27.3337 1.97512 27.3337 4.00016V12.0796C26.7413 11.5111 26.0684 11.026 25.3337 10.6431V4.00016C25.3337 3.07969 24.5875 2.3335 23.667 2.3335H4.33366C3.41318 2.3335 2.66699 3.07969 2.66699 4.00016V18.0002C2.66699 18.9206 3.41318 19.6668 4.33366 19.6668H12.7689C12.8766 20.3644 13.0676 21.0346 13.3312 21.6668H4.33366C2.30861 21.6668 0.666992 20.0252 0.666992 18.0002V4.00016ZM28.667 18.3335C28.667 14.2834 25.3837 11.0002 21.3337 11.0002C17.2836 11.0002 14.0003 14.2834 14.0003 18.3335C14.0003 22.3836 17.2836 25.6668 21.3337 25.6668C25.3837 25.6668 28.667 22.3836 28.667 18.3335ZM22.0012 19.0002L22.0018 22.3382C22.0018 22.7064 21.7033 23.0049 21.3351 23.0049C20.9669 23.0049 20.6685 22.7064 20.6685 22.3382L20.6679 19.0002H17.3285C16.9603 19.0002 16.6618 18.7017 16.6618 18.3335C16.6618 17.9653 16.9603 17.6668 17.3285 17.6668H20.6676L20.667 14.3325C20.667 13.9643 20.9655 13.6659 21.3337 13.6659C21.7018 13.6659 22.0003 13.9643 22.0003 14.3325L22.0009 17.6668H25.3377C25.7059 17.6668 26.0043 17.9653 26.0043 18.3335C26.0043 18.7017 25.7059 19.0002 25.3377 19.0002H22.0012Z"
      fill={color}
    />
  </svg>
);

export default AdditionalFields;
