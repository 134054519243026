import { useState } from 'react';
import { AccessHttpService } from './api/accessHttpService';
import { UserAccess } from '../interfaces/usersAccess';
import {
  AccountPermissionProperties,
  EAccountPermissions,
} from 'modules/auth/context/permify/permify.interfaces';
import { useAuthContext } from 'modules/auth/context';

export const useAccessService = () => {
  const { userInfo } = useAuthContext();
  const [isCreatingUserLoading, toggleCreatingUserLoading] = useState(false);
  const [isGetUserListLoading, toggleGetUserListLoading] = useState(false);
  const [isUserExistsLoading, toggleUserExistsLoading] = useState(false);
  const [isGetUserLoading, toggleGetUserLoading] = useState(false);
  const [isResetUserLoading, toggleResetUserLoading] = useState(false);
  const [isResendUserLoading, toggleResendUserLoading] = useState(false);
  const [isUpdateUserLoading, toggleUpdateUserLoading] = useState(false);
  const [isActivateUserLoading, toggleActivateUserLoading] = useState(false);
  const [isDeactivateUserLoading, toggleDeactivateUserLoading] =
    useState(false);

  const createUserAccess = async (userAccess: UserAccess) => {
    try {
      toggleCreatingUserLoading(true);
      const userAccessService = new AccessHttpService();

      await userAccessService.postUserAccess({
        email: userAccess.email,
        fullName: userAccess.full_name,
        permissions: userAccess.permissions,
        phone: userAccess.phone,
        taxpayerId: userAccess.taxpayer_id,
        permission_properties: userAccess.permission_properties ?? null,
        authentication: {
          EMPLOYER: userInfo!.employer_id,
          FUNDING: userInfo!.fundings[0],
          ISSUER: userInfo!.issuer_id,
          ORIGINATOR: userInfo!.originator_id,
        },
      });

      return true;
    } catch (e) {
      return false;
    } finally {
      toggleCreatingUserLoading(false);
    }
  };

  const getUserAccessList = async () => {
    try {
      toggleGetUserListLoading(true);
      const userAccessService = new AccessHttpService();

      const userAccessList = await userAccessService.getUserAccessList();

      return userAccessList.data as UserAccess[];
    } catch (e) {
      return [];
    } finally {
      toggleGetUserListLoading(false);
    }
  };

  const getUserAccess = async (userId: string): Promise<UserAccess | false> => {
    try {
      toggleGetUserLoading(true);
      const userAccessService = new AccessHttpService();

      const userAccess = await userAccessService.getUserAccess(userId);
      return userAccess.data as UserAccess;
    } catch (e) {
      return false;
    } finally {
      toggleGetUserLoading(false);
    }
  };

  const updateUserAccess = async (
    userId: string,
    permissions: EAccountPermissions[],
    permissionProperties: AccountPermissionProperties | null,
  ): Promise<boolean> => {
    try {
      toggleUpdateUserLoading(true);
      const userAccessService = new AccessHttpService();

      await userAccessService.updateUserAccess(
        userId,
        permissions,
        permissionProperties,
      );
      return true;
    } catch (e) {
      return false;
    } finally {
      toggleUpdateUserLoading(false);
    }
  };

  const existsUserAccessCheck = async (user: {
    email?: string;
    taxpayerId: string;
  }): Promise<boolean> => {
    try {
      toggleUserExistsLoading(true);
      const userAccessService = new AccessHttpService();
      const authentication = {
        EMPLOYER: userInfo!.employer_id,
        FUNDING: userInfo!.fundings[0],
        ISSUER: userInfo!.issuer_id,
        ORIGINATOR: userInfo!.originator_id,
      };
      const { data } = await userAccessService.existsUserAccess({
        authentication,
        ...user,
      });

      return data.exists;
    } catch (e) {
      return false;
    } finally {
      toggleUserExistsLoading(false);
    }
  };

  const resetUserAccess = async (userId: string): Promise<boolean> => {
    try {
      toggleResetUserLoading(true);
      const userAccessService = new AccessHttpService();
      await userAccessService.resetUserAccess(userId);
      return true;
    } catch (e) {
      return false;
    } finally {
      toggleResetUserLoading(false);
    }
  };

  const resendUserAccess = async (userId: string): Promise<boolean> => {
    try {
      toggleResendUserLoading(true);
      const userAccessService = new AccessHttpService();
      await userAccessService.resendUserAccess(userId);
      return true;
    } catch (e) {
      return false;
    } finally {
      toggleResendUserLoading(false);
    }
  };

  const activateUserAccess = async (user: {
    email: string;
  }): Promise<boolean> => {
    try {
      toggleActivateUserLoading(true);
      const userAccessService = new AccessHttpService();
      await userAccessService.activateUserAccess(user);
      return true;
    } catch (e) {
      return false;
    } finally {
      toggleActivateUserLoading(false);
    }
  };

  const deactivateUserAccess = async (user: {
    email: string;
  }): Promise<boolean> => {
    try {
      toggleDeactivateUserLoading(true);
      const userAccessService = new AccessHttpService();
      await userAccessService.deactivateUserAccess(user);
      return true;
    } catch (e) {
      return false;
    } finally {
      toggleDeactivateUserLoading(false);
    }
  };

  return {
    createUserAccess,
    isCreatingUserLoading,
    getUserAccessList,
    isGetUserListLoading,
    existsUserAccessCheck,
    isUserExistsLoading,
    getUserAccess,
    isGetUserLoading,
    resetUserAccess,
    isResetUserLoading,
    resendUserAccess,
    isResendUserLoading,
    activateUserAccess,
    isActivateUserLoading,
    deactivateUserAccess,
    isDeactivateUserLoading,
    updateUserAccess,
    isUpdateUserLoading,
  };
};
