import styled from '@emotion/styled';

export const SearchBarContainer = styled.div`
  background-color: ${({ theme }) => theme.palette?.brand.neutral.base};
  height: 50px;
  border-radius: 10px;
  padding: 12px 21px;
  display: flex;
  box-shadow: 0px 4px 10px rgba(15, 33, 43, 0.05);
  background-color: #FDFDFD;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      margin: 0;
  }
`;

export const IconArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Input = styled.input`
  font-family: 'Inter';
  width: 100%;
  height: 100%;
  border: 0;
  margin-left: 9px;
  color: ${({ theme }) => theme.palette?.brand.secondary.base};
  font-weight: 400;
  font-size: 16px;

  
  &::placeholder {
    font-family: 'Inter';
    color: ${({ theme }) => theme.palette?.brand.secondary.base};
    font-weight: 400;
    font-size: 16px;
  }
`;
