import styled from '@emotion/styled';
import { TMessageType } from './toastEventManager';

export const ToastContainerWrapper = styled.div`
  position: fixed;
  z-index: 9999999;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
`;

export interface IToastMessageWrapperProps {
  messageType: TMessageType;
}

export const ToastMessageWrapper = styled.div<IToastMessageWrapperProps>`
  min-width: 400px;
  background-color: ${({ messageType, theme }) => {
    switch (messageType) {
      case 'success':
        return '#00CC21';
      case 'info':
        return '#2179E1';
      case 'alert':
        return '#F97800';
      case 'error':
        return '#F50404';
      case 'link':
        return theme.palette?.brand.primary.base as string;
      case 'copy':
        return theme.palette?.brand.primary.base as string;
      default:
        return 'red';
    }
  }};
  margin: 10px;
  border: 1px solid;
  border-radius: 10px;
  overflow: hidden;
  align-items: center;
  border-color: ${({ messageType, theme }) => {
    switch (messageType) {
      case 'success':
        return '#00CC21';
      case 'info':
        return '#2179E1';
      case 'alert':
        return '#F97800';
      case 'error':
        return '#F50404';
      case 'link':
        return theme.palette?.brand.primary.base as string;
      case 'copy':
        return theme.palette?.brand.primary.base as string;
      default:
        return 'red';
    }
  }};
  box-shadow: ${({ messageType }) => {
    switch (messageType) {
      case 'success':
        return '0px 20px 24px -8px rgba(0, 204, 33, 0.08)';
      case 'info':
        return '0px 20px 24px -8px rgba(33, 121, 225, 0.08)';
      case 'alert':
        return '0px 20px 24px -8px rgba(249, 120, 0, 0.08)';
      case 'error':
        return '0px 20px 24px -8px rgba(245, 4, 4, 0.08)';
      case 'link':
        return '0px 20px 24px -8px rgba(128, 109, 251, 0.08)';
      case 'copy':
        return '0px 20px 24px -8px rgba(128, 109, 251, 0.08)';
      default:
        return 'red';
    }
  }};
`;

export const ToastMessageContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  background-color: #fff;
  padding: 12px;
  max-width: 600px;
  min-height: 50px;
  
  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .close-btn {
    margin-left: 8px;
    cursor: pointer;
  }
`;
