import { Divider, useTheme } from '@mui/material';
import { ButtonPrimary, TextL, TextM, TextS } from 'app/components';
import { formatCPF, formatPhone } from 'app/helpers';
import { CustomRadioInput } from 'celcoin-design-system';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import { ProductSideSheet } from 'modules/products/components/ProductSideSheet';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ApplicationRequestContext } from '../ApplicationRequest';
import { ListBox, ListBoxItem } from '../components';
import {
  InfoArea,
  SubscribersAddNewSideSheetListItemAction,
  SubscribersAddNewSideSheetListItemInfo,
  SubscribersAddNewSideSheetWrapper,
} from './styles';

interface ISubscribersAddNewSideSheetProps {
  handleClose: () => void;
  isOpen: boolean;
}

type ItemPersonType = TRegisterPersonDataRequest;
type ItemBusinessType = TRegisterBusinessDataRequest;
type TBorrowerType = 'PERSON' | 'BUSINESS';
export enum ESubscriberType {
  COSIGNERS = 'co_signers',
  GUARANTORS = 'guarantors',
  INVESTORS = 'investors',
}

export type TSubscriber = {
  subscriber: ItemPersonType & ItemBusinessType;
  subscriberType: ESubscriberType;
  subscriberPersonType: TBorrowerType;
};

const BorrowerInfoDivider = () => {
  return (
    <Divider
      orientation="vertical"
      sx={{
        backgroundColor: '#E6E5EA',
        height: '21px',
        width: '2px',
        margin: '0px 24px',
      }}
    />
  );
};

const SubscribersAddNewSideSheet = ({
  isOpen,
  handleClose,
}: ISubscribersAddNewSideSheetProps) => {
  const theme = useTheme();
  const { getPersonList, personListLoading } = usePersonService();
  const { creatBusinessLoading } = useCustomerService();
  const { product, subscribers, updateSubscribers } = useContext(
    ApplicationRequestContext,
  );
  const [isLoading, setIsLoading] = useState(false);
  const currentPage = useRef<number>(0);
  const searchParameter = useRef<string>('');
  const searchValue = useRef<string>('');
  const [personBorrowerList, setPersonBorrowerList] = useState<
    TRegisterPersonDataRequest[]
  >([]);
  const [businessBorrowerList, setBusinessBorrowerList] = useState<
    TRegisterBusinessDataRequest[]
  >([]);
  const [selectedItem, updateSelectedItem] = useState<
    (ItemPersonType & ItemBusinessType) | null
  >(null);
  const [hasMoreItems, updateHasMoreItems] = useState<boolean>(false);
  const [subscriberType, updateSubscriberType] = useState<ESubscriberType>(
    ESubscriberType.COSIGNERS,
  );
  const borrowerType: TBorrowerType = product?.borrower_type as TBorrowerType;

  const onSearch = (field: string, value: string) => {
    searchParameter.current = field;
    searchValue.current = value;
    currentPage.current = 0;
    setPersonBorrowerList([]);
    setBusinessBorrowerList([]);

    if (field === '') {
      handleGetPerson(0);
      return;
    }
    handleGetPerson(0, field, value);
    return;

    // if (product?.borrower_type === 'BUSINESS') {
    //   if (field === '') {
    //     handleGetBusiness(0);
    //     return;
    //   }
    //   handleGetBusiness(0, field, value);
    // }
  };

  const handleGetPerson = useCallback(
    async (
      page: number,
      searchParameterName?: string,
      searchParameterValue?: string,
    ) => {
      setIsLoading(true);
      const res = await getPersonList(
        page,
        searchParameterName,
        searchParameterValue,
      );
      updateHasMoreItems(!!res?.has_next);
      setPersonBorrowerList((state) =>
        state.concat(
          res?.content.filter((item) => {
            const index = state.findIndex((el) => el.id === item.id);
            return index < 0;
          }) || [],
        ),
      );
      currentPage.current = page;
      setIsLoading(false);
    },
    [currentPage],
  );

  // const handleGetBusiness = useCallback(
  //   async (
  //     page: number,
  //     searchParameterName?: string,
  //     searchParameterValue?: string,
  //   ) => {
  //     setIsLoading(true);
  //     const res = await getBusinessList(
  //       page,
  //       searchParameterName,
  //       searchParameterValue,
  //     );
  //     updateHasMoreItems(!!res?.has_next);
  //     setBusinessBorrowerList((state) =>
  //       state.concat(
  //         res?.content.filter((item) => {
  //           const index = state.findIndex((el) => el.id === item.id);
  //           return index < 0;
  //         }) || [],
  //       ),
  //     );
  //     currentPage.current = page;
  //     setIsLoading(false);
  //   },
  //   [currentPage],
  // );

  const handleAddNewSubscriber = useCallback(() => {
    if (selectedItem) {
      updateSubscribers(
        (subscribers || []).concat([
          {
            subscriber: selectedItem,
            subscriberType,
            subscriberPersonType: 'PERSON',
            // subscriberPersonType: borrowerType,
          },
        ]),
      );
      setTimeout(() => {
        updateSelectedItem(() => null);
        updateSubscriberType(ESubscriberType.COSIGNERS);
      });
    }
  }, [subscribers, selectedItem, borrowerType, subscriberType]);

  useEffect(() => {
    updateSelectedItem(null);
  }, []);

  return (
    <ProductSideSheet
      open={isOpen}
      isBigger={true}
      handleClose={handleClose}
      title="Adicionar na lista"
    >
      <SubscribersAddNewSideSheetWrapper>
        <ListBox
          noGutters={true}
          fullHeight={true}
          hasMoreItems={
            hasMoreItems ||
            isLoading ||
            personListLoading ||
            creatBusinessLoading
          }
          filterItems={[
            { name: 'Nome', parameterName: 'full_name' },
            {
              name: 'CPF',
              parameterName: 'taxpayer_id',
            },
            { name: 'Email', parameterName: 'email_address' },
          ]}
          items={
            personBorrowerList.length > 0
              ? personBorrowerList.filter((item) => {
                  const inx = subscribers?.findIndex(
                    ({ subscriber }) => subscriber.id === item.id,
                  );
                  return typeof inx === 'undefined' || inx === -1;
                })
              : businessBorrowerList.filter((item) => {
                  const inx = subscribers?.findIndex(
                    ({ subscriber }) => subscriber.id === item.id,
                  );
                  return typeof inx === 'undefined' || inx === -1;
                })
          }
          onSearch={onSearch}
          isLoading={isLoading || personListLoading || creatBusinessLoading}
          handleRequest={() =>
            handleGetPerson(
              currentPage.current + 1,
              searchParameter.current ? searchParameter.current : undefined,
              searchValue.current ? searchValue.current : undefined,
            )
          }
        >
          {(i) => {
            const item = i as ItemPersonType & ItemBusinessType;
            return (
              <ListBoxItem
                className={
                  selectedItem?.id === item.id ? 'selected-outline' : ''
                }
                onClick={() => updateSelectedItem(() => item)}
                key={item.taxpayer_id}
              >
                <SubscribersAddNewSideSheetListItemInfo>
                  <TextL
                    style={{
                      color:
                        selectedItem?.id === item.taxpayer_id
                          ? theme.palette.brand.primary.base
                          : theme.palette.brand.secondary.base,
                    }}
                    weight="semibold"
                  >
                    {/* {borrowerType === 'PERSON'
                      ? item.full_name
                      : item.legal_name} */}
                    {item?.full_name}
                  </TextL>
                  <InfoArea>
                    <TextS>
                      {/* {borrowerType === 'PERSON'
                        ? formatCPF(item.taxpayer_id || '')
                        : formatCNPJ(item.taxpayer_id)} */}
                      {formatCPF(item?.taxpayer_id || '')}
                    </TextS>
                    <BorrowerInfoDivider />
                    <TextS>
                      {formatPhone(
                        item.phone.area_code + item.phone.number || '',
                      )}
                    </TextS>
                    <BorrowerInfoDivider />
                    <TextS>{item.email_address}</TextS>
                  </InfoArea>
                </SubscribersAddNewSideSheetListItemInfo>
                {selectedItem?.id === item.id && (
                  <SubscribersAddNewSideSheetListItemAction>
                    <div className="relationship-area">
                      <TextL weight="semibold">Função</TextL>
                      <div className="relationship-area-items">
                        <div
                          className="relationship-area-item"
                          onClick={() =>
                            updateSubscriberType(ESubscriberType.COSIGNERS)
                          }
                        >
                          <CustomRadioInput
                            isChecked={
                              subscriberType === ESubscriberType.COSIGNERS
                            }
                          />
                          <TextL>Anuente</TextL>
                        </div>
                        <div
                          className="relationship-area-item"
                          onClick={() =>
                            updateSubscriberType(ESubscriberType.GUARANTORS)
                          }
                        >
                          <CustomRadioInput
                            isChecked={
                              subscriberType === ESubscriberType.GUARANTORS
                            }
                          />
                          <TextL>Avalista</TextL>
                        </div>
                        {product?.type_product_emission ===
                          'COMMERCIAL_PAPER' && (
                          <div
                            className="relationship-area-item"
                            onClick={() =>
                              updateSubscriberType(ESubscriberType.INVESTORS)
                            }
                          >
                            <CustomRadioInput
                              isChecked={
                                subscriberType === ESubscriberType.INVESTORS
                              }
                            />
                            <TextL>Investidor</TextL>
                          </div>
                        )}
                      </div>
                    </div>
                    <Divider />
                    <div className="buttons-area">
                      <ButtonPrimary
                        style={{ maxWidth: '30%' }}
                        typeVariant="ghost"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          updateSelectedItem(() => null);
                        }}
                      >
                        <TextM
                          weight="Semibold"
                          style={{ color: theme.palette.brand.primary.base }}
                        >
                          Cancelar
                        </TextM>
                      </ButtonPrimary>
                      <ButtonPrimary
                        style={{ maxWidth: '70%' }}
                        typeVariant="outline"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleAddNewSubscriber();
                        }}
                      >
                        <TextM
                          weight="Semibold"
                          style={{ color: theme.palette.brand.primary.base }}
                        >
                          Adicionar
                        </TextM>
                      </ButtonPrimary>
                    </div>
                  </SubscribersAddNewSideSheetListItemAction>
                )}
              </ListBoxItem>
            );
          }}
        </ListBox>
      </SubscribersAddNewSideSheetWrapper>
    </ProductSideSheet>
  );
};

export default SubscribersAddNewSideSheet;
