import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useBreadcrumbsContext } from 'app/context';
import { useEffect } from 'react';
import { BalancePageContent } from './components';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';

const BalancePage = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const { userInfo } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !userInfo?.permissions?.includes(EAccountPermissions.DISBURSEMENT_ADMIN)
    ) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setItems(
      [
        { to: '/disbursement/balance', label: 'Desembolsos' },
        { to: '/disbursement/balance', label: 'Saldo e Conciliação' },
      ],
      <CurrencyExchangeIcon />,
    );

    return () => resetBreadcrumbs();
  }, []);

  if (!userInfo?.permissions?.includes(EAccountPermissions.DISBURSEMENT_ADMIN))
    return null;

  return <BalancePageContent />;
};

export default BalancePage;
