import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer';
import celcoinHeroImg from './celcoinReceiptHeroImg.png';
import { normalizeCpfCnpj } from 'app/utils/normalizer';
import { IInternalTransferReceiptPDF } from './interfaces';

Font.register({
  family: 'Urbanist',
  fonts: [
    {
      src: `/Urbanist.ttf`,
    },
    {
      src: `/Urbanist-SemiBold.ttf`,
      fontWeight: 'semibold',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: 'Urbanist',
    display: 'flex',
    justifyContent: 'space-between',
  },
  hero: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#806DFB',
  },
  heroImg: {
    width: '183px',
    height: 'auto',
  },
  title: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'semibold',
    color: '#27176c',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 12,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: '#27176c',
    opacity: 0.2,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'semibold',
    marginBottom: 10,
  },
  sectionText: {
    fontSize: 10,
    display: 'flex',
    maxWidth: '100%',
  },
  footer: {
    backgroundColor: '#E0E0E0',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 10,
  },
  footerTitle: {
    fontSize: 10,
    fontWeight: 'semibold',
    color: '#2C2C2C',
  },
  footerText: {
    fontSize: 8,
    fontWeight: 'semibold',
    color: '#616161',
  },
});

interface InternalTransferReceiptPDF {
  date: string;
  identifier: string;
  debitAccountTaxpayerId: string;
  debitAccountHolderName: string;
  debitAccountBank: string;
  debitAccountBranch: string;
  debitAccountAccountNumber: string;
  creditAccountTaxpayerId: string;
  creditAccountHolderName: string;
  creditAccountBank: string;
  creditAccountBranch: string;
  creditAccountAccountNumber: string;
  paymentDate: string;
  value: string;
}

export const Divider = () => {
  return (
    <View>
      <View style={{ ...styles.divider, marginTop: 10, marginBottom: 10 }} />
    </View>
  );
};

export const SectionTitle = ({ title }: { title?: string }) => {
  return <Text style={styles.sectionTitle}>{title || '-'}</Text>;
};

export const Line = ({ title, value }: { title: string; value?: string }) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 6,
      }}
    >
      <View style={{ width: '30%', maxWidth: '30%' }}>
        <Text style={{ ...styles.sectionText }}>{title}</Text>
      </View>
      <View style={{ width: '70%' }}>
        <Text
          style={{
            ...styles.sectionText,
            color: '#27176c',
            textTransform: 'uppercase',
          }}
        >
          {value || '-'}
        </Text>
      </View>
    </View>
  );
};

const InternalTransferReceiptPDF = ({
  data,
}: {
  data: IInternalTransferReceiptPDF | null;
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View>
        <View style={styles.hero}>
          <Image source={celcoinHeroImg} style={styles.heroImg} />
        </View>
        <View style={{ marginTop: 20 }}>
          <Text style={styles.title}>Comprovante de Transferência</Text>
          <Text style={styles.subtitle}>{data?.paymentDate}</Text>
        </View>
        <View
          style={{
            marginTop: 10,
            textAlign: 'center',
          }}
        >
          <Text style={styles.sectionText}>Valor:</Text>
          <SectionTitle title={`R$ ${data?.value}`} />
        </View>
        <Divider />
        <View>
          <SectionTitle title="Conta de origem" />
          <Line
            title="Titular"
            value={data?.debitAccountHolderName.toUpperCase()}
          />
          <Line
            title="CNPJ:"
            value={`${
              data?.debitAccountTaxpayerId
                ? normalizeCpfCnpj(data?.debitAccountTaxpayerId || '')
                : '-'
            }`}
          />
          <Line title="Código do Banco:" value={data?.debitAccountBank} />
          <Line title="Agência:" value={data?.debitAccountBranch} />
          <Line title="Conta:" value={data?.debitAccountAccountNumber} />
        </View>

        <Divider />

        <View>
          <SectionTitle title="Conta de destino" />
          <Line title="Titular:" value={data?.creditAccountHolderName} />
          <Line
            title="CPF/CNPJ:"
            value={`${
              data?.creditAccountTaxpayerId
                ? normalizeCpfCnpj(data?.creditAccountTaxpayerId || '')
                : '-'
            }`}
          />
          <Line title="Código do Banco:" value={data?.creditAccountBank} />
          <Line title="Agência:" value={data?.debitAccountBranch} />
          <Line title="Conta:" value={data?.creditAccountAccountNumber} />
        </View>
      </View>
      <View style={styles.footer}>
        <View style={{ textAlign: 'center', width: '100%' }}>
          <Text style={styles.footerTitle}>Código de identificação</Text>
          <Text style={styles.footerText}>{data?.identifier}</Text>
        </View>
        <View style={{ textAlign: 'center', width: '100%', marginTop: 20 }}>
          <Text style={styles.footerTitle}>
            Celcoin Instituição de Pagamento S.A -
          </Text>
          <Text style={styles.footerText}>13.935.893/0001-09</Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default InternalTransferReceiptPDF;
