import styled from '@emotion/styled';

export const AuthFormContainerWrapper = styled.div`
  width: 100%;
  max-width: 592px;
  height: 100% ;
  box-shadow: 0px 8px 20px rgba(15, 33, 43, 0.15);
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 65px;

  @media screen and (max-width: 426px) {
    padding: 20px;
  }
`;

export const LogoArea = styled.div`
  width: 184px;
  height: 53px;
`;
