import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import { ButtonDefault, ButtonPrimary } from '../../../../../app/components';
import { StepContent } from '../../../components';
import {
  StepContentButtonsArea,
  StepContentInput,
  StepContentInputArea,
} from './styles';
import { NaturalPersonHandlerStepperContext } from '../NaturalPersonHandler';
import { useProductsService } from '../../../../products/services/hooks';
import { useProductsContext } from '../../../../products';
import { usePerson } from '../../../hooks';
import { personActionType, usePersonContext } from '../../../context';
import { EPersonFunctions } from '../../../context/PersonProvider/person.interfaces';
import { CustomSelect } from 'celcoin-design-system';
import { ArrowBack } from '@mui/icons-material';

const ProductStep = () => {
  const { hasMultiplesFundings, onForward, onBack } = useContext(
    NaturalPersonHandlerStepperContext,
  );
  const { getProducts } = useProductsService();
  const { dispatch: personDispatch } = usePersonContext();
  const {
    state: { content },
  } = useProductsContext();
  const {
    state: { product, func },
  } = usePersonContext();
  const { setProductData, setFunctionType } = usePerson();

  const formik = useFormik({
    initialValues: {
      product: product.name
        ? { label: product.name, value: product.id, product }
        : undefined,
      function: func.label ? func : undefined,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setProductData({ ...values.product?.product });
      setFunctionType(
        values.function as { label: string; value: EPersonFunctions },
      );
      onForward();
    },
  });

  useEffect(() => {
    personDispatch({
      type: personActionType.RESET_STATE,
    });
  }, []);

  useEffect(() => {
    getProducts();
  }, []);
  return (
    <StepContent
      title="Produto e Função"
      description="Selecione o produto, função e tipo a qual este cadastro será relacionado."
    >
      <StepContentInputArea>
        <StepContentInput>
          <CustomSelect
            labelValue="Produto"
            name="product"
            placeholder="Selecione o produto"
            value={formik.values.product}
            handleInputChange={(v) => {
              formik.handleChange(v);
            }}
            options={content.map((prd) => {
              return {
                label: prd.name,
                value: prd.id,
                product: prd,
              };
            })}
            validationError={
              formik.touched.product ? formik.errors.product : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomSelect
            labelValue="Função"
            name="function"
            placeholder="Selecione a função"
            value={
              formik.values.function as {
                label: string;
                value: EPersonFunctions;
              }
            }
            handleInputChange={(v) => {
              formik.handleChange(v);
            }}
            options={[
              { value: EPersonFunctions.BORROWER, label: 'Tomador' },
              { value: EPersonFunctions.GUARANTOR, label: 'Avalista' },
              { value: EPersonFunctions.CO_SIGNER, label: 'Anuente' },
            ]}
            validationError={
              formik.touched.function ? formik.errors.function : ''
            }
          />
        </StepContentInput>
      </StepContentInputArea>
      <StepContentButtonsArea>
        {hasMultiplesFundings && (
          <ButtonDefault margin={true} onClick={() => onBack()}>
            <ArrowBack />
          </ButtonDefault>
        )}
        <ButtonPrimary onClick={() => formik.submitForm()}>
          Avançar
        </ButtonPrimary>
      </StepContentButtonsArea>
    </StepContent>
  );
};

export default ProductStep;
