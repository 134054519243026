import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { useState } from 'react';
import {
  EViewBoxColumnType,
  EditWrapper,
  Heading3,
  ViewBox,
} from '../../../../../../app/components';
import { TRegisterPersonDataRequest } from '../../../../context';
import NaturalPersonEditDocumentSection from './NaturalPersonEditDocumentSection';
import { NaturalPersonViewDocumentSectionContainer } from './styles';
import { useTheme } from '@mui/material';
import { DocumentTypeObject } from 'modules/customer/context/PersonProvider/person.constants';

type INaturalPersonViewDocumentSectionProps = {
  personData: TRegisterPersonDataRequest;
};

const NaturalPersonViewDocumentSection = ({
  personData,
}: INaturalPersonViewDocumentSectionProps) => {
  const theme = useTheme();
  const [isEditOpen, setIsEditOpen] = useState(false);

  return (
    <>
      <NaturalPersonViewDocumentSectionContainer>
        <Heading3
          id="identification"
          style={{ color: theme.palette.brand.secondary.base as string }}
        >
          Documento de identificação
        </Heading3>
        <EditWrapper
          handleClick={() => setIsEditOpen(true)}
          permission={EAccountPermissions.UPDATE_PERSON_BUSINESS}
        >
          <ViewBox
            values={
              {
                ...personData,
              } as unknown as {
                [key: string]: string | number;
              }
            }
            textAlign="right"
            columns={[
              {
                label: 'Tipo de documento',
                path: 'id_document.type',
                enum: DocumentTypeObject,
                type: EViewBoxColumnType.ENUM,
              },
              {
                label: 'Número do documento',
                path: 'id_document.number',
              },
              {
                label: 'Orgão emissor ',
                path: 'id_document.issuer',
              },
              {
                label: 'Data de emissão',
                path: 'id_document.issue_date',
                type: EViewBoxColumnType.DATE,
              },
            ]}
          />
        </EditWrapper>
      </NaturalPersonViewDocumentSectionContainer>
      <NaturalPersonEditDocumentSection
        isOpen={isEditOpen}
        handleClose={() => setIsEditOpen(false)}
        personData={personData}
      />
    </>
  );
};

export default NaturalPersonViewDocumentSection;
