import styled from '@emotion/styled';

export interface ICustomSpinnerContent {
  color: string;
  size?: number;
}

export const CustomSpinnerContent = styled.div<ICustomSpinnerContent>`
  .loader,
  .loader:after {
    border-radius: 50%;
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
  }
  .loader {
    display: flex;
    font-size: 0px;
    position: relative;
    border-top: 6px solid rgb(128, 109, 251, 0.15);
    border-right: 6px solid rgb(128, 109, 251, 0.15);
    border-bottom: 6px solid rgb(128, 109, 251, 0.15);
    border-left: 6px solid ${({ color }) => color};
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

`;
