import { useTheme } from '@mui/material';
import { ButtonPrimary, CloseIcon, Heading4, TextL } from 'app/components';
import { AttentionRed } from 'app/components/Icons/svgs';
import { BaseModal } from 'celcoin-design-system';
import { BodyArea, ConfirmationModalContent, HeaderArea } from './styles';

export interface IConfirmationModalProps {
  isOpen: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
}

const ConfirmationModal = ({
  isOpen,
  handleClose,
  handleConfirm,
}: IConfirmationModalProps) => {
  const theme = useTheme();

  return (
    <BaseModal open={isOpen}>
      <ConfirmationModalContent>
        <HeaderArea>
          <div onClick={handleClose} style={{ cursor: 'pointer' }}>
            <CloseIcon color={theme.palette.brand.secondary.base as string} />
          </div>
        </HeaderArea>
        <BodyArea>
          <img src={AttentionRed} alt="icon" />
          <Heading4
            fontWeight="B"
            style={{
              textAlign: 'center',
              color: theme.palette.brand.secondary.base,
            }}
          >
            Deseja remover essa pessoa como representante legal?
          </Heading4>
          <ButtonPrimary onClick={handleClose}>Cancelar</ButtonPrimary>
          <TextL
            weight="semibold"
            style={{ color: theme.palette.brand.error[900], cursor: 'pointer' }}
            onClick={handleConfirm}
          >
            Remover representante
          </TextL>
        </BodyArea>
      </ConfirmationModalContent>
    </BaseModal>
  );
};

export default ConfirmationModal;
