import { useTheme } from '@mui/material';
import React, { HTMLAttributes } from 'react';
import { Heading4Container } from './styles';

interface IHeading3Props extends HTMLAttributes<HTMLHeadingElement> {
  fontWeight?: 'XB' | 'B' | 'Sb' | 'Md' | 'Rg';
}

const Heading4 = ({
  children,
  fontWeight = 'Rg',
  ...props
}: IHeading3Props) => {
  const theme = useTheme();
  return (
    <Heading4Container
      {...props}
      style={{
        ...props.style,
        fontWeight: theme.typography?.fontWeight?.[fontWeight] || 400,
      }}
    >
      {children}
    </Heading4Container>
  );
};

export default Heading4;
