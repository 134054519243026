import { useTheme } from '@mui/material';
import { CalculatorIco } from '../../Icons';
import { SimulationButtonContainer } from './styles';

export interface ISimulationButton {
  handleClick: () => void;
}

const SimulationButton = ({ handleClick }: ISimulationButton) => {
  const theme = useTheme();
  return (
    <SimulationButtonContainer onClick={handleClick}>
      <CalculatorIco color={theme.palette.brand.primary.base as string} />
    </SimulationButtonContainer>
  );
};

export default SimulationButton;
