import { useState } from 'react';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { DebtCollectionsHttpService } from '../services/api/chargeHttpService';
import {
  InstallmentFilters,
  InstallmentsList,
} from '../interfaces/installments';

const useInstallments = () => {
  const [getInstallmentsLoading, setGetInstallmentsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const getInstallments = async (
    fundingId: string,
    parameters?: InstallmentFilters,
  ): Promise<InstallmentsList | undefined> => {
    setGetInstallmentsLoading(true);
    try {
      const debtCollectionsHttpService = new DebtCollectionsHttpService();
      const res = await debtCollectionsHttpService.getInstallments(
        fundingId,
        parameters ?? ({} as InstallmentFilters),
      );
      return res.data;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar os dados de cobranças');
    } finally {
      setGetInstallmentsLoading(false);
    }
  };

  return {
    getInstallmentsLoading,
    getInstallments,
  };
};

export default useInstallments;
