import { BoxSection, Heading3 } from 'app/components';
import { EViewBoxColumnType, ViewBox } from 'app/components/ViewBox';
import { UserAccess } from 'modules/management/interfaces/usersAccess';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { Spacing } from './styles';
import UsersAccessDetailsEditSideSheet from './UsersAccessDetailsEditSideSheet';
import { useAccessService } from 'modules/management/services/useAccessService';

interface IUsersAccessDetailsTabProps {
  userAccess: UserAccess;
  updateUserAccess: (newUserAccess: UserAccess) => Promise<void>;
}

const UsersAccessDetailsTab = ({
  userAccess,
  updateUserAccess,
}: IUsersAccessDetailsTabProps) => {
  const [isUserAccessEditOpen, toggleUserAccessEditOpen] = useState(false);
  const { getUserAccess } = useAccessService();
  const [createdByUser, setCreatedByUser] = useState<UserAccess>(
    {} as UserAccess,
  );
  // const [portalData, updatePortalData] = useState<Portal>();
  const { showSnackbarCopy } = useSnackbar();

  useEffect(() => {
    if (typeof userAccess.created_by === 'string')
      getUserAccess(userAccess.created_by).then((createdByUserResponse) => {
        if (createdByUserResponse) setCreatedByUser(createdByUserResponse);
      });
  }, []);

  const handleCopy = useCallback((value: string) => {
    navigator.clipboard.writeText(value);
    showSnackbarCopy();
  }, []);

  return (
    <BoxSection>
      <Heading3>Usuário</Heading3>
      <ViewBox
        style={{
          maxWidth: '90%',
        }}
        size={1}
        values={
          userAccess as unknown as {
            [key: string]: string | number;
          }
        }
        columns={[
          {
            path: 'full_name',
            label: 'Nome',
            full: true,
          },
          {
            path: 'email',
            label: 'E-mail',
            full: true,
            type: EViewBoxColumnType.COPY,
            onCopy: handleCopy,
          },
          {
            path: 'taxpayer_id',
            label: 'CPF',
            full: true,
            type: EViewBoxColumnType.CPF,
          },
          {
            path: 'phone',
            label: 'Telefone',
            full: true,
            type: EViewBoxColumnType.PHONE_STRING,
          },
        ]}
      />

      {/* {portalData && (
        <>
          <Spacing />
          <Heading3>Portal</Heading3>
          <ViewBox
            size={1}
            style={{
              maxWidth: '90%',
            }}
            values={
              portalData as unknown as {
                [key: string]: string | number;
              }
            }
            columns={[
              {
                path: 'name',
                label: 'Apelido',
                full: true,
              },
              {
                path: 'dns',
                label: 'Endereço',
                full: true,
                type: EViewBoxColumnType.URL,
              },
              {
                path: 'id',
                label: 'ID portal',
                full: true,
                type: EViewBoxColumnType.COPY,
                onCopy: handleCopy,
              },
            ]}
          />
        </>
      )} */}

      <Spacing />
      <Heading3>Registro</Heading3>
      <ViewBox
        size={1}
        style={{
          maxWidth: '90%',
        }}
        values={
          {
            ...userAccess,
            created_by: {
              id: createdByUser.id,
              name: createdByUser.full_name || '-',
            },
          } as unknown as {
            [key: string]: string | number;
          }
        }
        columns={[
          {
            path: 'created_by.name',
            label: 'Criado por',
            full: true,
          },
          {
            path: 'created_by.id',
            label: 'ID criador',
            full: true,
            type: EViewBoxColumnType.COPY,
            onCopy: handleCopy,
          },
          {
            path: 'created_at',
            label: 'Data da criação',
            full: true,
            type: EViewBoxColumnType.DATETIME,
          },
          {
            path: 'deactivated_at',
            label: 'Data da última desativação',
            full: true,
            type: EViewBoxColumnType.DATETIME,
          },
        ]}
      />
      <UsersAccessDetailsEditSideSheet
        handleClose={() => toggleUserAccessEditOpen(false)}
        isOpen={isUserAccessEditOpen}
        userAccess={userAccess}
        updateUserAccess={updateUserAccess}
      />
    </BoxSection>
  );
};

export default UsersAccessDetailsTab;
