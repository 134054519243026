import styled from '@emotion/styled';

export const ClipboardContainer = styled.div`
  width: 100%;
  padding: 10px 8px;
  background-color: ${({ theme }) => theme.palette?.brand.neutral.base};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  p {
    font-size: clamp(9px, 1.8vw, 16px) ;
  }

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      p {
        font-size: clamp(9px, 1.8vw, 12px) ;
      }
  }

`;

export const ClipboardButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &:active {
    transform: scale(1.2);
  }
`;
