import styled from '@emotion/styled';

export const NoItemsRegisteredContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  height: 64px;
  width: 100%;
  max-width: 273px;
  border-radius: 80px;
  overflow: hidden;
  margin-top: 80px;
`;
