const InstallmentsPaidIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 19.25C8.26942 19.25 6.57769 18.7368 5.13876 17.7754C3.69983 16.8139 2.57832 15.4473 1.91606 13.8485C1.25379 12.2496 1.08051 10.4903 1.41813 8.79296C1.75575 7.09563 2.58911 5.53653 3.81282 4.31282C5.03653 3.08911 6.59563 2.25575 8.29296 1.91813C9.9903 1.58051 11.7496 1.75379 13.3485 2.41606C14.9473 3.07832 16.3139 4.19983 17.2754 5.63876C18.2368 7.07769 18.75 8.76942 18.75 10.5C18.7475 12.8199 17.8248 15.044 16.1844 16.6844C14.544 18.3248 12.3199 19.2475 10 19.25Z"
      fill="#00CC21"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6047 7.7649C12.7206 7.6475 12.8758 7.58254 13.0369 7.58401C13.1981 7.58548 13.3522 7.65326 13.4662 7.77276C13.5801 7.89225 13.6447 8.0539 13.6461 8.22289C13.6475 8.39188 13.5856 8.55468 13.4736 8.67624L13.4715 8.67861L9.13363 13.2278C9.07659 13.2878 9.00866 13.3356 8.93408 13.368C8.85941 13.4006 8.77937 13.4173 8.69853 13.4173C8.53585 13.4173 8.37975 13.3496 8.26458 13.2291L6.52698 11.4068C6.41504 11.2853 6.35312 11.1225 6.35452 10.9535C6.35592 10.7845 6.42055 10.6228 6.53449 10.5033C6.64843 10.3838 6.80257 10.3161 6.9637 10.3146C7.12484 10.3131 7.28008 10.3781 7.39598 10.4955L7.39824 10.4978L8.69849 11.8614L12.6047 7.7649Z"
      fill="#FDFDFD"
    />
  </svg>
);

export default InstallmentsPaidIcon;
