import { ISimulatorContext } from 'modules/simulation/interfaces/context.interfaces';
import React, { createContext, useContext, useReducer } from 'react';
import { simulatorInitialState, simulatorReducer } from './simulatorReducer';

export const SimulatorContext = createContext<ISimulatorContext | undefined>(
  undefined,
);

export interface ISimulatorProviderProps {
  children?: React.ReactNode;
}

export const SimulatorProvider = ({ children }: ISimulatorProviderProps) => {
  const [state, dispatch] = useReducer(simulatorReducer, simulatorInitialState);

  const value = { state, dispatch };

  return (
    <SimulatorContext.Provider value={value}>
      {children}
    </SimulatorContext.Provider>
  );
};

export const useSimulatorContext = () => {
  const context = useContext(SimulatorContext);

  if (context === undefined) {
    throw new Error(
      'useSimulatorContext must be used within a SimulatorProvider',
    );
  }
  return context;
};
