import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { Divider, Tooltip, useTheme } from '@mui/material';
import { ButtonPrimary } from '../../ButtonPrimary';
import { Tabs } from '../../Tabs';
import { Heading3, Heading4, TextM } from '../../typography';
import {
  ButtonsArea,
  CreditSimulatorContentWrapper,
  CreditSimulatorInputPreview,
  InputArea,
  InputContent,
  InputsGrid,
  InputsWrapper,
  PreviewArea,
  PreviewItem,
  PreviewItems,
  TabArea,
  ValidationDetails,
  ValidationQuestionMark,
} from './styles';
import {
  formatBRLCurrencyMaskInput,
  getDigitsFromValue,
} from '../../../utils/currency';
import { useFormik } from 'formik';
import {
  creditSimulatorValidation,
  formatDecimalField,
} from '../../../helpers';
import {
  formatCurrency,
  formatToFixed,
  normalizeAddDigits,
} from '../../../utils/normalizer';
import { formatDateFromBackend } from '../../../helpers/dateHelpers';
import useCreditSimulator from '../../../hooks/useCreditSimulator';
import { TProducts } from 'modules/products/context';
import {
  simulatorActionTypes,
  useSimulatorContext,
} from 'modules/simulation/context';
import {
  AMORTIZATION_TYPES,
  FINANCED_IOF,
  INTEREST_COMPOUNDING_TYPES,
  IOFTYPES_TRANSLATED_EN_PT,
  SCHEDULE_TYPES_TRANSLATED_EN_PT,
} from 'app/helpers/simulatiorHelper';
import {
  CustomCurrencyInput,
  CalendarInput,
  CustomInput,
  TextL,
} from 'celcoin-design-system';
import { ButtonDefault } from 'app/components/ButtonDefault';
import { ArrowBack, InfoOutlined } from '@mui/icons-material';
import {
  ApplicationRequestContext,
  TacValueType,
} from 'modules/products/pages/ApplicationRequest/ApplicationRequest';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { Spinner } from 'app/components/Spinner';
import {
  TPreviewSimulationData,
  TPreviewSimulationValidation,
} from 'modules/simulation/interfaces/context.interfaces';
import { Theme } from '@emotion/react';
import { getValidDisbursementDate } from '../helpers';
import { TQualification } from 'modules/customer/interfaces/qualifications';
import { differenceInDays } from 'date-fns';
import { CustomSelect } from 'app/components/CustomSelect';
import { useAppContext } from 'app/context/AppContextProvider';
import {
  DisbursementDateInput,
  MultipleDisbursementDateContainer,
  MultipleDisbursementDatesSelector,
} from '../CreditSimulatorModal/styles';

export interface ICreditSimulatorContent {
  mode: 'COMPONENT' | 'MODAL';
  qualification?: TQualification | null;
  product?: TProducts;
  simulationData?: TSimulatorParametersData | null;
  simulationPreviewData?: TPreviewSimulationData | null;
  onDefineSimulation?: (
    previewSimulationData: TPreviewSimulationData,
    simulationRequest: TSimulatorParametersData,
    previewValidation: TPreviewSimulationValidation,
    tabIndex: string,
  ) => void;
  onRenderPreviewButtonsArea?: (
    previewSimulationData: TPreviewSimulationData,
  ) => JSX.Element;
}

const PreviewDivider = ({ theme }: { theme: Theme }) => (
  <Divider
    sx={{
      backgroundColor: theme.palette?.brand.primary.base,
      marginTop: '12px',
      opacity: 0.16,
    }}
  />
);

export type TReactChangeInput = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

const IOFTYPES = [
  { value: 'PERSON', label: 'PF' },
  { value: 'BUSINESS', label: 'PJ' },
  { value: 'BUSINESS_SIMPLE', label: 'PJ Simples' },
  { value: 'ZERO', label: 'Zerado' },
];
const SCHEDULE_TYPES = [
  { value: 'DAILY', label: 'Diário' },
  { value: 'WEEKLY', label: 'Semanal' },
  { value: 'BIWEEKLY', label: 'Quinzenal' },
  { value: 'MONTHLY', label: 'Mensal' },
];
const TABS_CONTENT = ['Solicitado', 'Total devido', 'Da parcela', 'Financiado'];

export type TSimulatorParametersData = {
  amount: string;
  numPayments: string;
  tacType: { value: string; label: string };
  tacAmount: string;
  tacRate?: string;
  interestRate: string;
  financeFee: string;
  iofType: { value: string; label: string };
  scheduleType: { value: string; label: string };
  amortizationType: { value: string; label: string };
  interestCompoundingType: { value: string; label: string };
  financedIof: { value: string; label: string };
  firstPaymentDate: string;
  disbursementDate: string;
  amountKey?: string;
};

const CreditSimulatorComponent = ({
  mode,
  product,
  qualification,
  simulationData,
  simulationPreviewData,
  onDefineSimulation,
}: ICreditSimulatorContent) => {
  const theme = useTheme();
  const { simulate, simulationLoading } = useCreditSimulator();
  const {
    state: { simulatorParametersData },
    dispatch,
  } = useSimulatorContext();
  const { onBack, onForward } = useContext(ApplicationRequestContext);
  const { showSnackbarCopy } = useSnackbar();
  const [isValidationOpen, toggleValidationOpen] = useState<
    keyof TPreviewSimulationValidation | null
  >(null);
  const { feature_flags } = useAppContext();
  const [previewSimulationValidation, setPreviewSimulationValidation] =
    useState<TPreviewSimulationValidation>({
      firstPaymentDate: null,
      interestRate: null,
      numPeriods: null,
      paymentAmount: null,
      tacAmount: null,
      requested_amount: null,
    });

  const [previewSimulationData, setPreviewSimulationData] =
    useState<TPreviewSimulationData>(
      simulationPreviewData ?? {
        numPeriods: null,
        paymentAmount: null,
        disbursementAmount: null,
        interestRate: null,
        monthlyEffectiveInterestRate: null,
        tacAmount: null,
        iofAmount: null,
        iofBaseRate: null,
        firstPaymentDate: null,
        lastPaymentDate: null,
        disbursement_date: null,
        totalAmountOwed: null,
        financed_amount: null,
        requested_amount: null,
        finance_fee: null,
      },
    );
  const [tabIndex, setTabIndex] = useState(
    simulatorParametersData?.amountKey || '0',
  );

  const isDiscountRateVisible = useMemo(() => {
    return feature_flags?.DISCOUNT_RATE_ENABLED
      ? feature_flags?.DISCOUNT_RATE_ENABLED?.isActive
      : false;
  }, [feature_flags]);

  const handleCopyPreview = useCallback(
    (previewSimulation: TPreviewSimulationData) => {
      navigator.clipboard.writeText(
        `Quant. parcelas: ${
          previewSimulation?.numPeriods ? previewSimulation?.numPeriods : '-'
        }\nValor da parcela: ${
          previewSimulation?.paymentAmount
            ? formatCurrency(previewSimulation?.paymentAmount)
            : '-'
        }\nValor liberado: ${
          previewSimulation?.requested_amount
            ? formatCurrency(previewSimulation?.requested_amount || 0.0)
            : '-'
        }\nValor financiado: ${
          previewSimulation?.financed_amount
            ? formatCurrency(previewSimulation?.financed_amount || 0.0)
            : '-'
        }\nJuros: ${
          previewSimulation?.interestRate
            ? `${formatToFixed(previewSimulation?.interestRate * 100, 2)
                .toString()
                .replace('.', ',')}% a.m.`
            : '-'
        }\nCET: ${
          typeof previewSimulation?.monthlyEffectiveInterestRate === 'number'
            ? `${formatToFixed(
                previewSimulation?.monthlyEffectiveInterestRate * 100,
                2,
              )
                .toString()
                .replace('.', ',')}% a.m.`
            : '-'
        }\nTC: ${
          previewSimulation?.tacAmount
            ? formatCurrency(previewSimulation?.tacAmount)
            : '-'
        }\nIOF: ${
          previewSimulation?.iofAmount
            ? formatCurrency(previewSimulation?.iofAmount)
            : '-'
        }\nOutros custos: ${
          previewSimulation?.finance_fee
            ? formatCurrency(previewSimulation?.finance_fee)
            : '-'
        }\nPrimeiro vencimento: ${
          previewSimulation?.firstPaymentDate
            ? formatDateFromBackend(
                previewSimulation?.firstPaymentDate,
              ).toLocaleDateString('pt-br')
            : '-'
        }\nÚltimo vencimento: ${
          previewSimulation?.lastPaymentDate
            ? formatDateFromBackend(
                previewSimulation?.lastPaymentDate,
              ).toLocaleDateString('pt-br')
            : '-'
        }\nSaldo Total: ${
          previewSimulation?.totalAmountOwed
            ? formatCurrency(previewSimulation?.totalAmountOwed)
            : '-'
        }`,
      );
      showSnackbarCopy();
    },
    [],
  );

  const handleAmountChange = useCallback(
    (evt: TReactChangeInput) => {
      formik.setFieldValue(
        'amount',
        formatBRLCurrencyMaskInput(
          evt.target.value || '',
          product?.type_product_emission === 'COMMERCIAL_PAPER',
        ),
      );
    },
    [product],
  );

  const handleTacAmountChange = (evt: TReactChangeInput) => {
    if (formik.values.tacType.value === TacValueType.PERCENTAGE) {
      formik.setFieldValue('tacRate', formatDecimalField(evt.target.value));
      return;
    }
    formik.setFieldValue(
      'tacAmount',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  };

  const handleDiscountRate = (evt: TReactChangeInput) => {
    formik.setFieldValue('discountRate', formatDecimalField(evt.target.value));
  };

  const handleNumPayments = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue('numPayments', getDigitsFromValue(evt.target.value));
  }, []);

  const handleFinanceFee = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue(
      'financeFee',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  const handleInterestRate = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue('interestRate', formatDecimalField(evt.target.value));
  }, []);

  const handleTacValueType = () => {
    return [
      //VALOR ABSOLUTO must be the first element
      {
        label: 'VALOR ABSOLUTO',
        value: TacValueType.MONETARY,
      },
      {
        label: 'VALOR PERCENTUAL',
        value: TacValueType.PERCENTAGE,
      },
    ];
  };

  const formik = useFormik({
    initialValues: {
      amount: simulatorParametersData
        ? formatCurrency(simulatorParametersData?.amount, false) || '0,00'
        : '0,00',
      numPayments: simulatorParametersData
        ? String(simulatorParametersData.numPayments)
        : '1',
      tacType:
        simulatorParametersData && simulatorParametersData.tacType
          ? simulatorParametersData.tacType
          : handleTacValueType()[0],
      tacAmount: simulatorParametersData
        ? formatCurrency(simulatorParametersData.tacAmount, false) || '0,00'
        : '0,00',
      tacRate:
        simulatorParametersData && simulatorParametersData.tacRate
          ? String(
              formatToFixed(simulatorParametersData.tacRate * 100, 2),
            ).replace('.', ',') || '0'
          : '0',
      interestRate: simulatorParametersData
        ? String(
            formatToFixed(simulatorParametersData.interestRate * 100, 2),
          ).replace('.', ',')
        : '1',
      discountRate:
        simulatorParametersData && simulatorParametersData.discountRate
          ? String(
              formatToFixed(simulatorParametersData.discountRate * 100, 2),
            ).replace('.', ',') || '0'
          : '0',
      financeFee: simulatorParametersData
        ? formatCurrency(simulatorParametersData?.financeFee, false) || '0,00'
        : '0,00',
      iofType:
        simulatorParametersData && simulatorParametersData.iofType
          ? simulatorParametersData.iofType
          : IOFTYPES[0],
      scheduleType:
        mode === 'COMPONENT' && typeof product === 'object'
          ? {
              label: product.schedule_type as string,
              value: product.schedule_type as string,
            }
          : SCHEDULE_TYPES[0],
      interestCompoundingType:
        mode === 'COMPONENT' && typeof product === 'object'
          ? {
              label: product.interest_compounding_type as string,
              value: product.interest_compounding_type as string,
            }
          : INTEREST_COMPOUNDING_TYPES[0],
      amortizationType:
        mode === 'COMPONENT' && typeof product === 'object'
          ? {
              label: product.amortization_type as string,
              value: product.amortization_type as string,
            }
          : AMORTIZATION_TYPES[0],
      financedIof:
        mode === 'COMPONENT' && typeof product === 'object'
          ? {
              label: String(product.financed_iof),
              value: String(product.financed_iof),
            }
          : FINANCED_IOF[0],
      firstPaymentDate: simulatorParametersData
        ? formatDateFromBackend(
            simulatorParametersData.firstPaymentDate as string,
          ).toISOString()
        : new Date(
            new Date().setMonth(new Date().getMonth() + 1),
          ).toISOString(),
      disbursementDate:
        simulationData && simulationData.disbursementDate
          ? formatDateFromBackend(
              new Date(simulationData.disbursementDate).toISOString() as string,
            ).toISOString()
          : simulatorParametersData && simulatorParametersData.disbursementDate
          ? formatDateFromBackend(
              simulatorParametersData.disbursementDate as string,
            ).toISOString()
          : getValidDisbursementDate(product?.multiple_disbursements_dates),
    },
    validationSchema: creditSimulatorValidation(
      product?.multiple_disbursements_dates,
    ),
    enableReinitialize: false,
    onSubmit: async (values) => {
      const res = await simulate(values, String(tabIndex), product?.id);
      const data = res?.data;

      const simulationDataRequest: TPreviewSimulationData = {
        numPeriods: data?.num_periods,
        paymentAmount: data?.payment_amount,
        requested_amount: data?.requested_amount,
        interestRate: data?.interest_rate,
        monthlyEffectiveInterestRate: data?.monthly_effective_interest_rate,
        tacAmount: data?.tac_amount,
        iofAmount: data?.iof_amount,
        iofBaseRate: data?.iof_base_rate,
        firstPaymentDate: data?.first_payment_date,
        lastPaymentDate: data?.last_payment_date,
        totalAmountOwed: data?.total_amount_owed,
        financed_amount: data?.financed_amount,
        disbursement_date: data?.disbursement_date,
        finance_fee: data?.finance_fee,
        schedule: data?.schedule,
      };
      setPreviewSimulationData(simulationDataRequest);
      const validation = updatePreviewValidation(simulationDataRequest);
      if (typeof onDefineSimulation === 'function') {
        onDefineSimulation(
          simulationDataRequest,
          values,
          validation,
          String(tabIndex),
        );
      }
    },
  });

  const handleSimulator = useCallback(() => {
    setPreviewSimulationData({
      numPeriods: null,
      paymentAmount: null,
      disbursementAmount: null,
      interestRate: null,
      monthlyEffectiveInterestRate: null,
      tacAmount: null,
      iofAmount: null,
      iofBaseRate: null,
      firstPaymentDate: null,
      lastPaymentDate: null,
      totalAmountOwed: null,
      financed_amount: null,
      requested_amount: null,
      disbursement_date: null,
      finance_fee: null,
    });
    formik.submitForm();
  }, []);

  const resetPreview = useCallback(() => {
    setPreviewSimulationData({
      numPeriods: null,
      paymentAmount: null,
      disbursementAmount: null,
      interestRate: null,
      monthlyEffectiveInterestRate: null,
      tacAmount: null,
      iofAmount: null,
      iofBaseRate: null,
      firstPaymentDate: null,
      lastPaymentDate: null,
      totalAmountOwed: null,
      financed_amount: null,
      disbursement_date: null,
      requested_amount: null,
      finance_fee: null,
    });
  }, []);

  const handleResetForm = useCallback(() => {
    formik.resetForm();
    resetPreview();
    dispatch({
      type: simulatorActionTypes.RESET_SIMULATOR_DATA,
    });
  }, []);

  const handleChangeTabIndex = useCallback(
    (value: number) => {
      if (formik.getFieldMeta('amount').value !== '0,00') {
        formik.setFieldValue('amount', '0,00');
        resetPreview();
      }
      setTabIndex(String(value));
      formik.setFieldValue('tacType', handleTacValueType()[0]);
      formik.setFieldValue('discountRate', '0');
    },
    [formik],
  );

  const handleIOFTypes = () => {
    return product?.iof_zero
      ? IOFTYPES.filter((i) => i.value === 'ZERO')
      : !product?.iof_zero && product?.borrower_type === 'PERSON'
      ? IOFTYPES.filter((i) => i.value === 'PERSON')
      : !product?.iof_zero && product?.borrower_type === 'BUSINESS'
      ? IOFTYPES.filter((i) => i.value.includes('BUSINESS'))
      : IOFTYPES.filter((i) => i.value.includes('BUSINESS'));
  };

  useEffect(() => {
    if (product) {
      formik.setFieldValue('scheduleType', {
        label: product.schedule_type as string,
        value: product.schedule_type as string,
      });
      formik.setFieldValue('iofType', {
        label: product.iof_zero
          ? IOFTYPES_TRANSLATED_EN_PT['ZERO']
          : !product.iof_zero && product.borrower_type === 'PERSON'
          ? IOFTYPES_TRANSLATED_EN_PT['PERSON']
          : !product.iof_zero && product.borrower_type === 'BUSINESS'
          ? IOFTYPES_TRANSLATED_EN_PT['BUSINESS']
          : IOFTYPES_TRANSLATED_EN_PT['BUSINESS_SIMPLE'],
        value: product.iof_zero
          ? 'ZERO'
          : !product.iof_zero && product.borrower_type === 'PERSON'
          ? 'PERSON'
          : !product.iof_zero && product.borrower_type === 'BUSINESS'
          ? 'BUSINESS'
          : 'BUSINESS_SIMPLE',
      });
    }
  }, [product]);

  const updatePreviewValidation = useCallback(
    (previewData: TPreviewSimulationData) => {
      const previewValidation: TPreviewSimulationValidation = {
        firstPaymentDate: null,
        interestRate: null,
        numPeriods: null,
        paymentAmount: null,
        requested_amount: null,
        tacAmount: null,
      };
      if (qualification && qualification.condition) {
        // Valida primeiro dia de pagamento
        if (previewData.firstPaymentDate && qualification.condition.due_day) {
          const day = new Date(previewData.firstPaymentDate).getDate();
          if (
            qualification.condition.due_day &&
            day + 1 !== qualification.condition.due_day
          ) {
            previewValidation.firstPaymentDate = `A condição exige dia ${qualification.condition.due_day} para pagamento`;
          }
        }

        // Valida carência mínima e máxima
        if (
          previewData.firstPaymentDate &&
          !previewValidation.firstPaymentDate
        ) {
          const differenceDays = differenceInDays(
            new Date(previewData.firstPaymentDate),
            new Date(),
          );

          if (
            qualification.condition.min_grace_period &&
            qualification.condition.min_grace_period > differenceDays
          ) {
            previewValidation.firstPaymentDate = `Carência mínima = ${qualification.condition.min_grace_period} dias`;
          }

          if (
            qualification.condition.max_grace_period &&
            qualification.condition.max_grace_period < differenceDays
          ) {
            previewValidation.firstPaymentDate = `${
              previewValidation.firstPaymentDate !== null
                ? `${previewValidation.firstPaymentDate}, `
                : ''
            }Carência máxima = ${
              qualification.condition.max_grace_period
            } dias`;
          }
        }

        // Valida quantidade de parcelas
        if (
          typeof previewData.numPeriods === 'number' &&
          qualification.condition.min_payment_number &&
          qualification.condition.max_payment_number
        ) {
          if (
            previewData.numPeriods <
              qualification.condition.min_payment_number ||
            previewData.numPeriods > qualification.condition.max_payment_number
          ) {
            previewValidation.numPeriods = `Prazo mínimo = ${qualification.condition.min_payment_number}, Prazo máximo = ${qualification.condition.max_payment_number}`;
          }
        }

        // Valida valor solicitado minimo
        if (
          typeof previewData.requested_amount === 'number' &&
          qualification.condition.min_requested_amount
        ) {
          if (
            previewData.requested_amount <
            qualification.condition.min_requested_amount
          ) {
            previewValidation.requested_amount = `Valor mínimo = ${formatCurrency(
              qualification.condition.min_requested_amount,
            )}`;
          }
        }

        // Valida valor solicitado máximo
        if (
          typeof previewData.requested_amount === 'number' &&
          qualification.condition?.line_of_credit?.credit_limit
        ) {
          if (
            previewData.requested_amount >
            qualification.condition.line_of_credit.credit_limit
          ) {
            previewValidation.requested_amount = `${
              previewValidation.requested_amount !== null
                ? `${previewValidation.requested_amount}, `
                : ''
            }Valor máximo = ${formatCurrency(
              qualification.condition.line_of_credit.credit_limit,
            )}`;
          }
        }

        // Valida valor de parcela
        if (
          typeof previewData.paymentAmount === 'number' &&
          qualification.condition?.line_of_credit?.max_payment_amount
        ) {
          if (
            previewData.paymentAmount >
            qualification.condition.line_of_credit.max_payment_amount
          ) {
            previewValidation.paymentAmount = `Valor máximo = ${formatCurrency(
              qualification.condition.line_of_credit.max_payment_amount,
            )}`;
          }
        }

        // Valida TC
        if (
          typeof previewData.tacAmount === 'number' &&
          qualification.condition?.tac
        ) {
          if (previewData.tacAmount !== qualification.condition.tac) {
            previewValidation.tacAmount = `TC = ${formatCurrency(
              qualification.condition.tac,
            )}`;
          }
        }

        // Valida taxa de juros
        if (
          qualification.condition.schedule_based_conditions.length > 0 &&
          typeof previewData.interestRate === 'number' &&
          typeof previewData.numPeriods === 'number'
        ) {
          const scheduleBasedCondition =
            qualification.condition.schedule_based_conditions
              .sort((a, b) => (a.upper_limit > b.upper_limit ? 1 : -1))
              .filter(
                (schedule) =>
                  (previewData.numPeriods as number) < schedule.upper_limit,
              );
          if (
            scheduleBasedCondition.length > 0 &&
            scheduleBasedCondition[0].interest_rate !== null &&
            scheduleBasedCondition[0].interest_rate !== previewData.interestRate
          ) {
            previewValidation.interestRate = `Taxa de juros = ${
              scheduleBasedCondition[0].interest_rate * 100
            }%`;
          }
        }
        setPreviewSimulationValidation(previewValidation);
      }
      return previewValidation;
    },
    [qualification],
  );

  return (
    <>
      {mode === 'COMPONENT' && (
        <TabArea mode={mode}>
          <Heading4
            style={{
              color: theme.palette.brand.secondary.base,
              minWidth: '172px',
            }}
          >
            Definir por valor:
          </Heading4>
          <Tabs
            content={TABS_CONTENT}
            selectedIndex={Number(tabIndex)}
            style={{ fontSize: '16px' }}
            handleClick={handleChangeTabIndex}
          />
        </TabArea>
      )}
      <CreditSimulatorContentWrapper>
        <CreditSimulatorInputPreview>
          <InputArea mode={mode}>
            <InputContent mode={mode}>
              <InputsWrapper mode={mode}>
                <Heading4
                  style={{
                    color: theme.palette.brand.secondary.base,
                    minWidth: '172px',
                  }}
                >
                  Condições:
                </Heading4>

                <InputsGrid mode={mode}>
                  <CustomCurrencyInput
                    labelValue={`Valor ${TABS_CONTENT[Number(tabIndex)]}`}
                    value={formik.values.amount}
                    handleInputChange={handleAmountChange}
                    name="amount"
                    validationError={
                      formik.touched.amount ? formik.errors.amount : ''
                    }
                  />
                  <CustomInput
                    labelValue="Prazo (parcelas)"
                    value={formik.values.numPayments}
                    handleInputChange={handleNumPayments}
                    name="numPayments"
                    validationError={
                      formik.touched.numPayments
                        ? formik.errors.numPayments
                        : ''
                    }
                  />
                  {tabIndex === '0' && (
                    <CustomSelect
                      labelValue="Tipo da taxa de cadastro"
                      value={formik.values.tacType}
                      options={handleTacValueType()}
                      handleInputChange={formik.handleChange}
                      name="tacType"
                      placeholder="Selecionar"
                      isClearable={false}
                      tooltip={`Valor Absoluto: Valor monetário cobrado na taxa de cadastro; 
                        \r\nValor Percentual: Valor cobrado na taxa de cadastro como porcentagem do valor solicitado`}
                    />
                  )}

                  {formik.values.tacType.value === TacValueType.MONETARY ? (
                    <CustomCurrencyInput
                      labelValue="Taxa de cadastro (absoluto)"
                      value={formik.values.tacAmount}
                      handleInputChange={handleTacAmountChange}
                      name="tacAmount"
                      validationError={
                        formik.touched.tacAmount ? formik.errors.tacAmount : ''
                      }
                    />
                  ) : (
                    <CustomInput
                      labelValue="Taxa de cadastro (%)"
                      value={formik.values.tacRate}
                      handleInputChange={handleTacAmountChange}
                      name="tacRate"
                      validationError={
                        formik.touched.tacRate ? formik.errors.tacRate : ''
                      }
                    />
                  )}
                  {isDiscountRateVisible && (
                    <CustomInput
                      labelValue="Taxa de desconto (%)"
                      value={formik.values.discountRate}
                      handleInputChange={handleDiscountRate}
                      validationError={
                        formik.touched.discountRate
                          ? formik.errors.discountRate
                          : ''
                      }
                    />
                  )}
                  <CustomInput
                    labelValue="Taxa de juros (% a.m.)"
                    value={formik.values.interestRate}
                    handleInputChange={handleInterestRate}
                    validationError={
                      formik.touched.interestRate
                        ? formik.errors.interestRate
                        : ''
                    }
                  />
                  <CustomCurrencyInput
                    labelValue="Outros custos (absoluto)"
                    value={formik.values.financeFee}
                    handleInputChange={handleFinanceFee}
                    validationError={
                      formik.touched.financeFee ? formik.errors.financeFee : ''
                    }
                  />
                  <CustomSelect
                    labelValue="Tipo de IOF"
                    value={formik.values.iofType}
                    options={handleIOFTypes()}
                    handleInputChange={formik.handleChange}
                    name="iofType"
                    placeholder="Selecionar"
                    isClearable={false}
                    validationError={
                      formik.touched.iofType?.value
                        ? formik.errors.iofType?.value
                        : ''
                    }
                  />
                  {mode === 'MODAL' && (
                    <CustomInput
                      labelValue="Periodicidade dos venc."
                      value={
                        SCHEDULE_TYPES_TRANSLATED_EN_PT[
                          formik.values.scheduleType.value
                        ]
                      }
                      handleInputChange={formik.handleChange}
                      name="scheduleType"
                      placeholder="Selecionar"
                      readOnly
                      validationError={
                        formik.touched.iofType?.value
                          ? formik.errors.iofType?.value
                          : ''
                      }
                    />
                  )}
                  {mode === 'MODAL' && (
                    <CustomSelect
                      labelValue="Tipo Amort."
                      options={AMORTIZATION_TYPES}
                      value={formik.values.amortizationType}
                      handleInputChange={formik.handleChange}
                      name="amortizationType"
                      placeholder="Selecionar"
                    />
                  )}
                  <CalendarInput
                    name="foundation_date"
                    labelValue="Data do 1º pagamento"
                    placeholder="dd/mm/aaaa"
                    value={formik.values.firstPaymentDate}
                    onChange={(e) =>
                      formik.handleChange({
                        currentTarget: {
                          value: e,
                          name: 'firstPaymentDate',
                        },
                      })
                    }
                    validationError={
                      formik.touched.firstPaymentDate
                        ? formik.errors.firstPaymentDate
                        : ''
                    }
                  />
                  {product?.multiple_disbursements_dates ? (
                    <MultipleDisbursementDateContainer>
                      <div
                        style={{
                          display: 'flex',
                          gap: '0.8rem',
                          alignItems: 'center',
                        }}
                      >
                        <TextM
                          weight="Bold"
                          color={theme.palette.brand.black.base as string}
                        >
                          Operação com múltiplas datas de desembolso
                        </TextM>
                        <Tooltip
                          placement="bottom-start"
                          title={
                            <TextM
                              style={{ color: theme.palette.common.white }}
                            >
                              A operação será desembolsada de acordo com a data
                              de assinatura da operação, que poderá ser entre a
                              data de início e data fim do desembolso.
                            </TextM>
                          }
                        >
                          <InfoOutlined
                            style={{
                              fontSize: '1.2rem',
                              color: theme.palette.brand.gray[900],
                            }}
                          />
                        </Tooltip>
                      </div>
                      <MultipleDisbursementDatesSelector>
                        <DisbursementDateInput
                          disabled={true}
                          isMultiplesDisbursementDate={true}
                        >
                          <CalendarInput
                            name="disbursement_date_start"
                            labelValue="Data de início do desembolso"
                            placeholder="dd/mm/aaaa"
                            value={new Date().toISOString()}
                            //eslint-disable-next-line
                            onChange={() => {}}
                          />
                        </DisbursementDateInput>
                        <DisbursementDateInput
                          isMultiplesDisbursementDate={true}
                        >
                          <CalendarInput
                            name="foundation_date"
                            labelValue="Data fim do desembolso"
                            placeholder="dd/mm/aaaa"
                            value={formik.values.disbursementDate}
                            onChange={(e) =>
                              formik.handleChange({
                                currentTarget: {
                                  value: e,
                                  name: 'disbursementDate',
                                },
                              })
                            }
                            validationError={
                              formik.touched.disbursementDate
                                ? formik.errors.disbursementDate
                                : ''
                            }
                          />
                        </DisbursementDateInput>
                      </MultipleDisbursementDatesSelector>
                    </MultipleDisbursementDateContainer>
                  ) : (
                    <DisbursementDateInput>
                      <CalendarInput
                        name="foundation_date"
                        labelValue="Data de liberação do recurso"
                        placeholder="dd/mm/aaaa"
                        value={formik.values.disbursementDate}
                        onChange={(e) =>
                          formik.handleChange({
                            currentTarget: {
                              value: e,
                              name: 'disbursementDate',
                            },
                          })
                        }
                        validationError={
                          formik.touched.disbursementDate
                            ? formik.errors.disbursementDate
                            : ''
                        }
                      />
                    </DisbursementDateInput>
                  )}
                </InputsGrid>
              </InputsWrapper>
            </InputContent>
          </InputArea>
          <PreviewArea mode={mode}>
            {simulationLoading ? (
              <Spinner />
            ) : previewSimulationData?.requested_amount ? (
              <>
                {mode === 'COMPONENT' ? (
                  <Heading4
                    style={{
                      color: theme.palette.brand.secondary.base,
                      minWidth: '172px',
                    }}
                  >
                    Resultado:
                  </Heading4>
                ) : (
                  <Heading3
                    style={{ color: theme.palette.brand.secondary.base }}
                  >
                    Resultado
                  </Heading3>
                )}

                <PreviewItems mode={mode}>
                  <>
                    <PreviewItem>
                      <TextM
                        weight="Semibold"
                        style={{
                          color:
                            previewSimulationValidation.numPeriods !== null
                              ? theme.palette.brand.error[900]
                              : theme.palette.brand.secondary.base,
                          display: 'flex',
                        }}
                      >
                        Quant. parcelas
                        {previewSimulationValidation.numPeriods !== null && (
                          <ValidationQuestionMark
                            onClick={() => toggleValidationOpen('numPeriods')}
                          >
                            ?
                          </ValidationQuestionMark>
                        )}
                      </TextM>
                      <TextM
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        {previewSimulationData?.numPeriods &&
                        previewSimulationData?.numPeriods > 0
                          ? previewSimulationData?.numPeriods
                          : '-'}
                      </TextM>
                      {isValidationOpen === 'numPeriods' && (
                        <ValidationDetails
                          onClick={() => toggleValidationOpen(null)}
                        >
                          {previewSimulationValidation.numPeriods}
                        </ValidationDetails>
                      )}
                    </PreviewItem>
                    <PreviewDivider theme={theme} />
                  </>
                  <>
                    <PreviewItem>
                      <TextM
                        weight="Semibold"
                        style={{
                          color:
                            previewSimulationValidation.paymentAmount !== null
                              ? theme.palette.brand.error[900]
                              : theme.palette.brand.secondary.base,
                          display: 'flex',
                        }}
                      >
                        Valor da parcela
                        {previewSimulationValidation.paymentAmount !== null && (
                          <ValidationQuestionMark
                            onClick={() =>
                              toggleValidationOpen('paymentAmount')
                            }
                          >
                            ?
                          </ValidationQuestionMark>
                        )}
                      </TextM>
                      <TextM
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        {previewSimulationData?.paymentAmount
                          ? formatCurrency(previewSimulationData?.paymentAmount)
                          : '-'}
                      </TextM>
                      {isValidationOpen === 'paymentAmount' && (
                        <ValidationDetails
                          onClick={() => toggleValidationOpen(null)}
                        >
                          {previewSimulationValidation.paymentAmount}
                        </ValidationDetails>
                      )}
                    </PreviewItem>
                    <PreviewDivider theme={theme} />
                  </>
                  <>
                    <PreviewItem>
                      <TextM
                        weight="Semibold"
                        style={{
                          color:
                            previewSimulationValidation.requested_amount !==
                            null
                              ? theme.palette.brand.error[900]
                              : theme.palette.brand.secondary.base,
                          display: 'flex',
                        }}
                      >
                        Valor liberado
                        {previewSimulationValidation.requested_amount !==
                          null && (
                          <ValidationQuestionMark
                            onClick={() =>
                              toggleValidationOpen('requested_amount')
                            }
                          >
                            ?
                          </ValidationQuestionMark>
                        )}
                      </TextM>
                      <TextM
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        {previewSimulationData?.requested_amount
                          ? formatCurrency(
                              previewSimulationData?.requested_amount,
                            )
                          : '-'}
                      </TextM>
                      {isValidationOpen === 'requested_amount' && (
                        <ValidationDetails
                          onClick={() => toggleValidationOpen(null)}
                        >
                          {previewSimulationValidation.requested_amount}
                        </ValidationDetails>
                      )}
                    </PreviewItem>
                    <PreviewDivider theme={theme} />
                  </>
                  <>
                    <PreviewItem>
                      <TextM
                        weight="Semibold"
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        Valor financiado
                      </TextM>
                      <TextM
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        {previewSimulationData?.financed_amount
                          ? formatCurrency(
                              previewSimulationData?.financed_amount,
                            )
                          : '-'}
                      </TextM>
                    </PreviewItem>
                    <PreviewDivider theme={theme} />
                  </>
                  <>
                    <PreviewItem>
                      <TextM
                        weight="Semibold"
                        style={{
                          color:
                            previewSimulationValidation.interestRate !== null
                              ? theme.palette.brand.error[900]
                              : theme.palette.brand.secondary.base,
                          display: 'flex',
                        }}
                      >
                        Juros
                        {previewSimulationValidation.interestRate !== null && (
                          <ValidationQuestionMark
                            onClick={() => toggleValidationOpen('interestRate')}
                          >
                            ?
                          </ValidationQuestionMark>
                        )}
                      </TextM>
                      <TextM
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        {previewSimulationData?.interestRate
                          ? `${normalizeAddDigits(
                              previewSimulationData?.interestRate * 100,
                              2,
                            )?.replace('.', ',')}% a.m.`
                          : '-'}
                      </TextM>
                      {isValidationOpen === 'interestRate' && (
                        <ValidationDetails
                          onClick={() => toggleValidationOpen(null)}
                        >
                          {previewSimulationValidation.interestRate}
                        </ValidationDetails>
                      )}
                    </PreviewItem>
                    <PreviewDivider theme={theme} />
                  </>
                  <>
                    <PreviewItem>
                      <TextM
                        weight="Semibold"
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        CET
                      </TextM>
                      <TextM
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        {typeof previewSimulationData?.monthlyEffectiveInterestRate ===
                        'number'
                          ? `${normalizeAddDigits(
                              previewSimulationData?.monthlyEffectiveInterestRate *
                                100,
                              2,
                            ).replace('.', ',')}% a.m.`
                          : '-'}
                      </TextM>
                    </PreviewItem>
                    <PreviewDivider theme={theme} />
                  </>
                  <>
                    <PreviewItem>
                      <TextM
                        weight="Semibold"
                        style={{
                          color:
                            previewSimulationValidation.tacAmount !== null
                              ? theme.palette.brand.error[900]
                              : theme.palette.brand.secondary.base,
                          display: 'flex',
                        }}
                      >
                        TC
                        {previewSimulationValidation.tacAmount !== null && (
                          <ValidationQuestionMark
                            onClick={() => toggleValidationOpen('tacAmount')}
                          >
                            ?
                          </ValidationQuestionMark>
                        )}
                      </TextM>
                      <TextM
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        {previewSimulationData?.tacAmount
                          ? formatCurrency(previewSimulationData?.tacAmount)
                          : '-'}
                      </TextM>
                      {isValidationOpen === 'tacAmount' && (
                        <ValidationDetails
                          onClick={() => toggleValidationOpen(null)}
                        >
                          {previewSimulationValidation.tacAmount}
                        </ValidationDetails>
                      )}
                    </PreviewItem>
                    <PreviewDivider theme={theme} />
                  </>
                  <>
                    <PreviewItem>
                      <TextM
                        weight="Semibold"
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        IOF
                      </TextM>
                      <TextM
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        {previewSimulationData?.iofAmount
                          ? formatCurrency(previewSimulationData?.iofAmount)
                          : '-'}
                      </TextM>
                    </PreviewItem>
                    <PreviewDivider theme={theme} />
                  </>
                  <>
                    <PreviewItem>
                      <TextM
                        weight="Semibold"
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        Outros custos
                      </TextM>
                      <TextM
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        {previewSimulationData?.finance_fee
                          ? formatCurrency(previewSimulationData?.finance_fee)
                          : '-'}
                      </TextM>
                    </PreviewItem>
                    <PreviewDivider theme={theme} />
                  </>
                  <>
                    <PreviewItem>
                      <TextM
                        weight="Semibold"
                        style={{
                          color:
                            previewSimulationValidation.firstPaymentDate !==
                            null
                              ? theme.palette.brand.error[900]
                              : theme.palette.brand.secondary.base,
                          display: 'flex',
                        }}
                      >
                        Primeiro vencimento
                        {previewSimulationValidation.firstPaymentDate !==
                          null && (
                          <ValidationQuestionMark
                            onClick={() =>
                              toggleValidationOpen('firstPaymentDate')
                            }
                          >
                            ?
                          </ValidationQuestionMark>
                        )}
                      </TextM>
                      <TextM
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        {previewSimulationData?.firstPaymentDate
                          ? formatDateFromBackend(
                              previewSimulationData?.firstPaymentDate,
                            ).toLocaleDateString('pt-br')
                          : '-'}
                      </TextM>
                      {isValidationOpen === 'firstPaymentDate' && (
                        <ValidationDetails
                          onClick={() => toggleValidationOpen(null)}
                        >
                          {previewSimulationValidation.firstPaymentDate}
                        </ValidationDetails>
                      )}
                    </PreviewItem>
                    <PreviewDivider theme={theme} />
                  </>
                  <>
                    <PreviewItem>
                      <TextM
                        weight="Semibold"
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        Último vencimento
                      </TextM>
                      <TextM
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        {previewSimulationData?.lastPaymentDate
                          ? formatDateFromBackend(
                              previewSimulationData?.lastPaymentDate,
                            ).toLocaleDateString('pt-br')
                          : '-'}
                      </TextM>
                    </PreviewItem>
                    <PreviewDivider theme={theme} />
                  </>
                  <>
                    <PreviewItem>
                      <TextM
                        weight="Semibold"
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        Saldo Total
                      </TextM>
                      <TextM
                        style={{ color: theme.palette.brand.secondary.base }}
                      >
                        {previewSimulationData?.totalAmountOwed
                          ? formatCurrency(
                              previewSimulationData?.totalAmountOwed,
                            )
                          : '-'}
                      </TextM>
                    </PreviewItem>
                    <PreviewDivider theme={theme} />
                  </>
                </PreviewItems>
              </>
            ) : (
              <Heading4 style={{ color: theme.palette.brand.secondary.base }}>
                Nenhum resultado calculado.
              </Heading4>
            )}
          </PreviewArea>
        </CreditSimulatorInputPreview>
        <ButtonsArea>
          <ButtonDefault
            style={{
              maxWidth: '200px',
            }}
            onClick={handleResetForm}
          >
            <TextL style={{ color: theme.palette.brand.primary.base }}>
              Limpar condições
            </TextL>
          </ButtonDefault>

          {simulationLoading ? (
            <Spinner />
          ) : (
            <div className="btn-preview">
              <ButtonDefault onClick={onBack}>
                <ArrowBack />
              </ButtonDefault>
              {previewSimulationData &&
                previewSimulationData.requested_amount && (
                  <>
                    <ButtonDefault
                      onClick={() => handleCopyPreview(previewSimulationData)}
                    >
                      <TextL
                        style={{
                          color: theme.palette.brand.primary.base,
                          minWidth: '200px',
                        }}
                      >
                        Copiar resultados
                      </TextL>
                    </ButtonDefault>
                    <ButtonDefault
                      style={{ color: theme.palette.brand.primary.base }}
                      onClick={handleSimulator}
                    >
                      <TextL
                        style={{ color: theme.palette.brand.primary.base }}
                      >
                        Recalcular
                      </TextL>
                    </ButtonDefault>
                  </>
                )}
              <ButtonPrimary
                disabled={
                  Object.values(previewSimulationValidation).filter(
                    (v) => v !== null,
                  ).length > 0 && product?.conditions_required
                }
                onClick={
                  !previewSimulationData?.requested_amount
                    ? handleSimulator
                    : onForward
                }
              >
                <TextL style={{ color: '#FDFDFD' }}>
                  {!previewSimulationData?.requested_amount
                    ? 'Calcular'
                    : 'Avançar'}
                </TextL>
              </ButtonPrimary>
            </div>
          )}
        </ButtonsArea>
      </CreditSimulatorContentWrapper>
    </>
  );
};

export default CreditSimulatorComponent;
