import styled from '@emotion/styled';

export const NaturalPersonViewScrTabPopOver = styled.div`
  padding: 20px 20px;
`;

export const TableWrapper = styled.div`
  margin-top: 40px;
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 24px;
`;

export const TableSidesheetWrapper = styled.div`
  max-height: 90vh;
  overflow-y: auto;
`;

export const LegalPersonViewScrDetailsHeader = styled.div`
  background-color: ${({ theme }) => theme.palette?.brand.background.base};
  padding: 32px 48px;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
  border-radius: 16px 16px 0px 0px;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      padding: 20px;
  };
`;

export const LegalPersonViewScrListContent = styled.div`
  padding: 32px 48px;
  border: 2px solid #E0E0E0;
  border-radius: 0px 0px 16px 16px;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      padding: 20px;
  };
`;
