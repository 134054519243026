import styled from '@emotion/styled';

export const AuthItemContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  
`;

export const Bullet = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${({ theme }) => theme.palette?.brand.primary.base};
  border-radius: 3px;
`;
