import { IBusinessCustomVariablesValues } from 'app/interfaces/customVariablesInterfaces';
import { TProducts } from '../../../products/context';
import { customerActionType } from './customerActionType';

export enum ETaxRegime {
  SIMPLE = 'SIMPLE',
  REAL_PROFIT = 'REAL_PROFIT',
  PRESUMED_PROFIT = 'PRESUMED_PROFIT',
}

export enum EFunctions {
  BORROWER = 'BORROWER',
  GUARANTOR = 'GUARANTOR',
  CONSENTING = 'CONSENTING',
  EMPLOYER = 'EMPLOYER',
}

export enum TFile {
  BALANCE_SHEETS = 'BALANCE_SHEETS',
  INCOME_STATEMENT = 'INCOME_STATEMENT',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  BYLAWS = 'BYLAWS',
  INCORPORATION_CERTIFICATE = 'INCORPORATION_CERTIFICATE',
  FEDERAL_REGISTER_CERTIFICATE = 'FEDERAL_REGISTER_CERTIFICATE',
  INVOICE = 'INVOICE',
  CERTIFICATE = 'CERTIFICATE',
  LETTER_OF_ATTORNEY = 'LETTER_OF_ATTORNEY',
  CONTRACT = 'CONTRACT',
  OTHER = 'OTHER',
}

export enum EIncorporationType {
  MEI = 'MEI',
  EIRELI = 'EIRELI',
  SA = 'SA',
  LTDA = 'LTDA',
  SS = 'SS',
}

export type TAddress = {
  country_code: {
    label: string;
    value: string;
  };
  street_name: string;
  street_number: string;
  district: string;
  postal_code: string;
  city: {
    label: string;
    value: string;
  };
  state_code: {
    label: string;
    value: string;
  };
  extra_info: string;
  references?: string;
};

export type TRegisterBusinessData = {
  taxpayer_id: string;
  legal_name: string;
  name?: string;
  productId?: string;
  role?: EFunctions;
  phone: {
    country_code: string;
    area_code: string;
    number: string;
  };
  email_address: string;
  tax_regime?: {
    label: string;
    value: string;
  };
  foundation_date: string;
  address: TAddress;
  external_bank_account?: IBankAccount;
  pix?: IPixKey;
  hasQualificationRequest?: boolean;
  fundingId?: string;
  customVariables?: IBusinessCustomVariablesValues[];
  monthly_revenue?: number;
  share_capital?: number;
  billing_address?: TAddress;
  incorporation_type?: {
    label: string;
    value: EIncorporationType | undefined;
  };
  industry_classification?: string;
};

export enum IBankType {
  CURRENT = 'CURRENT',
  SAVING = 'SAVING',
}

export type IBankAccount = {
  bank_code?: string;
  ispb_code: string;
  bank_account: string;
  bank_account_digit: string;
  bank_branch: string;
  bank_account_type: string;
};

export enum PixType {
  TAXPAYER_ID = 'TAXPAYER_ID',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ALEATORY_KEY = 'ALEATORY_KEY',
  EMAIL = 'EMAIL',
}

export type IPixKey = {
  key: string;
  key_type: PixType;
};

export type TQualificationRequest = {
  product?: {
    id: string;
  };
  funding?: {
    id: string;
  };
  condition?: Condition;
  role?: string;
};

export interface Condition {
  due_day?: number;
  exp_date?: string;
  line_of_credit?: LineOfCredit;
  max_grace_period?: number;
  max_payment_number?: number;
  min_grace_period?: number;
  min_payment_number?: number;
  min_requested_amount?: number;
  schedule_based_conditions?: ScheduleBasedCondition[];
  tac?: number;
}

export interface LineOfCredit {
  credit_limit?: number;
  max_payment_amount?: number;
}

export interface Rating {
  identifier?: string;
  display_name?: string;
}

export interface ScheduleBasedCondition {
  interest_rate?: number;
  upper_limit?: number;
}

export type TRegisterBusinessDataRequest = {
  taxpayer_id: string;
  legal_name: string;
  name?: string;
  id?: string;
  phone: {
    country_code: string;
    area_code: string;
    number: string;
  };
  email_address: string;
  foundation_date: string;
  address: {
    country_code: string;
    street_name: string;
    street_number: string;
    district: string;
    postal_code: string;
    city: string;
    state_code: string;
    extra_info: string;
  };
  external_bank_account?: IBankAccount;
  pix?: IPixKey;
  tax_regime: string;
  created_at?: string;
  qualification_request?: TQualificationRequest;
  custom_variables?: { [key: string]: string | null | undefined };
  role?: EFunctions;
  monthly_income_revenue?: number | null;
  income_range?: {
    income_range?: string;
    updated_at?: string;
  } | null;
  incorporation_type?: EIncorporationType;
  industry_classification?: string;
  monthly_revenue?: number;
  share_capital?: number;
  billing_address?: {
    country_code: string;
    street_name: string;
    street_number: string;
    district: string;
    postal_code: string;
    city: string;
    state_code: string;
    extra_info: string;
  };
};

export type TAddressRequest = {
  country_code: string;
  street_name: string;
  street_number: string;
  district: string;
  postal_code: string;
  city: string;
  state_code: string;
  extra_info: string;
};

export type TCreateBusinessDataRequest = {
  taxpayer_id: string;
  legal_name: string;
  name?: string;
  id?: string;
  phone: {
    country_code: string;
    area_code: string;
    number: string;
  };
  email_address: string;
  foundation_date: string;
  address: TAddressRequest;
  external_bank_account?: IBankAccount;
  pix?: IPixKey;
  tax_regime: string;
  created_at?: string;
  qualification_request?: TQualificationRequest;
  custom_variables?: {
    values: {
      [key: string]: string | boolean | number | null;
    } | null;
  };
  role?: EFunctions;
  share_capital?: number;
  monthly_revenue?: number;
  billing_address?: TAddressRequest;
  incorporation_type?: EIncorporationType;
  industry_classification?: string;
};

export interface ICustomerState {
  registerData: TRegisterBusinessData;
  product: TProducts;
  func: { label?: string; value?: EFunctions };
}

export interface IBusinessList {
  content: ({ id: string } & TRegisterBusinessDataRequest)[];
  has_next?: boolean;
  total_elements?: number;
  total_pages?: number;
}

export type IDispatch = (action: ICustomerAction) => void;

export interface ICustomerContext {
  state: ICustomerState;
  dispatch: IDispatch;
}

export type ICustomerAction =
  | {
      type: customerActionType.SET_REGISTER_BUSINESS_DATA;
      payload: Partial<TRegisterBusinessData>;
    }
  | {
      type: customerActionType.RESET_STATE;
    }
  | {
      type: customerActionType.SET_PRODUCT_DATA;
      payload: Partial<TProducts>;
    }
  | {
      type: customerActionType.SET_FUNCTION_TYPE;
      payload: Partial<{ label: string; value: EFunctions }>;
    };
