import { Box, useTheme } from '@mui/material';
import {
  AdditionalFields,
  CalendarIcon,
  CopyIcon,
  HandCoinIcon,
  HyperlinkIcon,
  PencilIcon,
} from 'app/components/Icons';
import AttentionCircularIcon, {
  AttentionCircularRedIcon,
} from 'app/components/Icons/AttentionCircularIcon';
import { useSnackbar } from 'app/hooks/useSnackbar';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import { useApplicationsService } from 'modules/products/services/hooks';
import { TApplicationResponse } from 'modules/products/services/hooks/useApplicationsService';
import { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ButtonPrimary,
  ConfirmModal,
  Heading3,
  TextM,
} from '../../../../../../app/components';
import { ApplicationDetailsTab } from '../ApplicationDetailsTab';
import { ApplicationSignaturesTab } from '../ApplicationSignaturesTab';
import { ApplicationScheduleTab } from '../ApplicationScheduleTab';
import {
  ApplicationDetailsHeader,
  ApplicationDetailsSection,
  ApplicationDetailsHeaderButtons,
  Container,
  TabsArea,
  ApplicationDetailsHeaderButtonsWrapper,
} from './styles';
import { routingPath } from 'app/routes';
import { ApplicationCustomVariablesTab } from '../ApplicationCustomVariablesTab';
import { DifferenceOutlined, PriceCheck } from '@mui/icons-material';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { usePermify } from 'modules/auth/context';
import { CustomTabs } from 'app/components/CustomTabs';

interface IApplicationDetailsContent {
  applicationData: TApplicationResponse;
  updateApplicationData: (newApplicationValue: TApplicationResponse) => void;
}

export interface IPerson {
  id: string;
  full_name: string;
  taxpayer_id: string;
}

export interface TApplicationSignatureParsed {
  id: string;
  ip_address: string;
  user_agent: string;
  signed_at: string;
  person: IPerson;
  collect_sign_link: string | null;
  created_at: string;
  updated_at: string;
}

export interface IApplicationSignatures {
  id: string;
  function: string;
  type?: string; //BUSINESS | PERSON
}

export interface IRequiredSignatures {
  id: string;
  function: string;
  type?: string; //BUSINESS | PERSON
  personData?: TRegisterPersonDataRequest | null;
  businessData?: TRegisterBusinessDataRequest | null;
  signatureData?: TApplicationSignatureParsed;
}

export type TRouterLocation = {
  state: {
    redirect: boolean | null | undefined;
  };
};

const ApplicationDetailsContent = ({
  applicationData,
  updateApplicationData,
}: IApplicationDetailsContent) => {
  const theme = useTheme();
  const { showSnackbar, showSnackbarCopy, showSnackbarCopyLink } =
    useSnackbar();
  const [currentTabValue, setCurrentTabValue] = useState(0);
  const navigate = useNavigate();
  const { isProfileAuthorized } = usePermify();
  const locationRouter = useLocation();
  const params = useParams();
  const [
    commercialPaperStatusUpdateModalOpen,
    updateCommercialPaperStatusUpdateModalOpen,
  ] = useState(false);

  const tabItems = [
    {
      title: 'Detalhes',
      icon: <HandCoinIcon color={theme.palette.brand.primary.base as string} />,
      route: '/details',
    },
    {
      title: 'Assinaturas',
      icon: <PencilIcon color={theme.palette.brand.primary.base as string} />,
      route: '/signatures',
    },
    {
      title: 'Parcelas',
      icon: <CalendarIcon color={theme.palette.brand.primary.base as string} />,
      route: '/schedule',
    },
    {
      title: 'Campos adicionais',
      icon: (
        <AdditionalFields color={theme.palette.brand.primary.base as string} />
      ),
      route: '/custom-variables',
    },
  ];

  const {
    cancelApplicationLoading,
    cancelApplication,
    commercialPaperStatusUpdate,
    commercialPaperStatusUpdateLoading,
  } = useApplicationsService();
  const [
    removeApplicationConfirmVisible,
    toggleRemoveApplicationConfirmVisible,
  ] = useState(false);

  const handleCopyId = useCallback(() => {
    navigator.clipboard.writeText(applicationData.id);
    showSnackbarCopy();
  }, [applicationData]);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(location.href);
    showSnackbarCopyLink();
  }, [applicationData, location]);

  const handleCopyApplication = useCallback(() => {
    navigate(`/${routingPath.products.applicationRequest}`, {
      state: {
        application: applicationData,
      },
    });
  }, [applicationData]);

  const onRemoveApplication = useCallback(() => {
    cancelApplication(applicationData.id).then(() => {
      toggleRemoveApplicationConfirmVisible(false);
      navigate(
        `/products/detail/${applicationData?.product.id}/applications?page=0`,
      );
    });
  }, [applicationData]);

  const handleCommercialPaperStatusUpdate = useCallback(() => {
    commercialPaperStatusUpdate(applicationData.id).then(() => {
      showSnackbar('Desembolso executado com sucesso!', 'success');
      updateCommercialPaperStatusUpdateModalOpen(false);
    });
  }, [applicationData]);

  useEffect(() => {
    try {
      if (JSON.parse(localStorage.getItem('redirect') || 'false')) {
        setCurrentTabValue(1);
        showSnackbar('Solicitação criada.', 'success');
        localStorage.removeItem('redirect');
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (!params.tabRoute) {
      navigate(`${locationRouter.pathname}/details`);
    }
  }, []);

  const applicationDetailsTabsValueContent = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        return (
          <ApplicationDetailsTab
            application={applicationData}
            updateApplicationData={updateApplicationData}
          />
        );
      case 1:
        return (
          <ApplicationSignaturesTab
            applicationData={applicationData}
            updateApplicationData={updateApplicationData}
          />
        );
      case 2:
        return <ApplicationScheduleTab applicationData={applicationData} />;
      case 3:
        return (
          <ApplicationCustomVariablesTab
            applicationData={applicationData}
            updateApplicationData={updateApplicationData}
          />
        );
    }
    return null;
  };

  const canCommercialPaperStatusUpdate =
    applicationData?.status === 'AWAITING_APPROVAL_DISBURSEMENT' &&
    typeof applicationData?.managed_account_for_issuer_disbursement !==
      'undefined' &&
    applicationData?.managed_account_for_issuer_disbursement !== null;

  return (
    <Container>
      <ApplicationDetailsSection>
        <ApplicationDetailsHeader>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Heading3 fontWeight="Sb">
              {`ID solicitação: ${applicationData?.id}`}
            </Heading3>
            <ApplicationDetailsHeaderButtonsWrapper>
              <ApplicationDetailsHeaderButtons>
                {isProfileAuthorized(
                  EAccountPermissions.CREATE_APPLICATION,
                ) && (
                  <ButtonPrimary
                    style={{ backgroundColor: 'transparent' }}
                    typeVariant="outline"
                    btnIcon={
                      <DifferenceOutlined
                        style={{
                          color: theme.palette.brand.primary.base as string,
                        }}
                      />
                    }
                    iconPosition="left"
                    onClick={handleCopyApplication}
                  >
                    <TextM
                      weight="Semibold"
                      style={{
                        marginLeft: '11px',
                        color: theme.palette.brand.primary.base,
                      }}
                    >
                      Clonar
                    </TextM>
                  </ButtonPrimary>
                )}
                <ButtonPrimary
                  style={{ backgroundColor: 'transparent' }}
                  typeVariant="outline"
                  btnIcon={
                    <CopyIcon
                      color={theme.palette.brand.primary.base as string}
                    />
                  }
                  iconPosition="left"
                  onClick={handleCopyId}
                >
                  <TextM
                    weight="Semibold"
                    style={{
                      marginLeft: '11px',
                      color: theme.palette.brand.primary.base,
                    }}
                  >
                    Copiar ID
                  </TextM>
                </ButtonPrimary>
                <ButtonPrimary
                  style={{ backgroundColor: 'transparent' }}
                  typeVariant="outline"
                  btnIcon={
                    <HyperlinkIcon
                      color={theme.palette.brand.primary.base as string}
                    />
                  }
                  iconPosition="left"
                  onClick={handleCopyLink}
                >
                  <TextM
                    weight="Semibold"
                    style={{
                      marginLeft: '11px',
                      color: theme.palette.brand.primary.base,
                    }}
                  >
                    Copiar Link
                  </TextM>
                </ButtonPrimary>
              </ApplicationDetailsHeaderButtons>
              {canCommercialPaperStatusUpdate && (
                <ApplicationDetailsHeaderButtons>
                  <ButtonPrimary
                    style={{ backgroundColor: 'transparent' }}
                    typeVariant="outline"
                    btnIcon={
                      <PriceCheck
                        style={{
                          color: theme.palette.brand.primary.base as string,
                        }}
                      />
                    }
                    iconPosition="left"
                    onClick={() =>
                      updateCommercialPaperStatusUpdateModalOpen(true)
                    }
                  >
                    <TextM
                      weight="Semibold"
                      style={{
                        marginLeft: '11px',
                        color: theme.palette.brand.primary.base,
                      }}
                    >
                      Executar Desembolso
                    </TextM>
                  </ButtonPrimary>
                </ApplicationDetailsHeaderButtons>
              )}
            </ApplicationDetailsHeaderButtonsWrapper>
          </div>
          <TabsArea>
            <CustomTabs
              value={currentTabValue}
              route={routingPath.products.applicationDetails}
              items={tabItems}
              variant="scrollable"
              handleChange={(value) => setCurrentTabValue(value)}
              showBorder={false}
            />
          </TabsArea>
        </ApplicationDetailsHeader>
        <Box>{applicationDetailsTabsValueContent(currentTabValue)}</Box>
      </ApplicationDetailsSection>
      <ConfirmModal
        isOpen={removeApplicationConfirmVisible !== false}
        icon={<AttentionCircularRedIcon />}
        title="Essa ação é irreversível, você tem certeza?"
        color="danger"
        btnConfirmText="Excluir solicitação"
        isLoading={cancelApplicationLoading}
        handleConfirm={onRemoveApplication}
        handleClose={() => toggleRemoveApplicationConfirmVisible(false)}
        handleCancel={() => toggleRemoveApplicationConfirmVisible(false)}
      >
        Esta solicitação não poderá ser mais utilizada para pagamentos ou
        recebimentos. Créditos já solicitados ou concedidos não serão alterados.
      </ConfirmModal>
      <ConfirmModal
        isOpen={commercialPaperStatusUpdateModalOpen}
        icon={<AttentionCircularIcon />}
        title="Confirmar execução de desembolso"
        color="primary"
        btnConfirmText="Confirmar"
        isLoading={commercialPaperStatusUpdateLoading}
        handleConfirm={handleCommercialPaperStatusUpdate}
        handleClose={() => updateCommercialPaperStatusUpdateModalOpen(false)}
        handleCancel={() => updateCommercialPaperStatusUpdateModalOpen(false)}
      >
        Tem certeza que deseja executar o desembolso?
      </ConfirmModal>
    </Container>
  );
};

export default ApplicationDetailsContent;
